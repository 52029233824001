import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
import { GraphqlContext } from '../pages/api/graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Dimension: any
  Email: any
  HexColor: any
  JSON: any
  Number: any
  Quality: any
  Strategy_Number: any
  Strategy_Time: any
  Time: any
  jsonb: any
  timestamptz: any
  uuid: any
}

export type Aml = {
  __typename?: 'AML'
  /** EDD record of the AML */
  edd?: Maybe<AmleddRecord>
  /** NS record of the AML */
  ns?: Maybe<AmlnsRecord>
  /** RA record of the AML */
  ra?: Maybe<AmlraRecord>
  /** results of user's risk assessment of the AML */
  raResults?: Maybe<Array<AmlUserAssessmentResult>>
  /** status of the AML */
  status: AmlStatus
}

export type AmlAssessmentItem = {
  __typename?: 'AMLAssessmentItem'
  /** description of the assessment item */
  description: Scalars['String']
  /** unique identifier for the assessment item */
  id: Scalars['String']
  /** item categories of the assessment item */
  itemCategories: Array<AmlItemCategory>
  /** weights of the assessment item */
  weights: Scalars['String']
}

export type AmleddRecord = {
  __typename?: 'AMLEDDRecord'
  /** creation date of the EDD record */
  createdAt: Scalars['Time']
  /** the first audit's identifier of the EDD record */
  firstAuditorID?: Maybe<Scalars['ID']>
  /** unique identifier for the EDD record */
  id: Scalars['ID']
  /** the second audit's identifier of the EDD record */
  secondAuditorID?: Maybe<Scalars['ID']>
  /** status of the EDD record */
  status: EddStatus
  /** updated date of the EDD record */
  updatedAt: Scalars['Time']
}

export type AmlItemCategory = {
  __typename?: 'AMLItemCategory'
  /** allow multiple selection of the item category */
  allowMultipleSelection: Scalars['Boolean']
  /** description of the item category */
  description: Scalars['String']
  /** unique identifier for the item category */
  id: Scalars['String']
  /** risk factors of the item category */
  riskFactors: Array<AmlRiskFactor>
}

export type AmlnsRecord = {
  __typename?: 'AMLNSRecord'
  /** creation date of the NS record */
  createdAt: Scalars['Time']
  /** the first audit's identifier of the NS record */
  firstAuditorID?: Maybe<Scalars['ID']>
  /** unique identifier for the NS record */
  id: Scalars['ID']
  /** is system audited of the NS record */
  isSystemAudited: Scalars['Boolean']
  /** the second audit's identifier of the NS record */
  secondAuditorID?: Maybe<Scalars['ID']>
  /** status of the NS record */
  status: NsStatus
  /** updated date of the NS record */
  updatedAt: Scalars['Time']
}

export type AmlraRecord = {
  __typename?: 'AMLRARecord'
  /** creation date of the RA record */
  createdAt: Scalars['Time']
  /** the first audit's identifier of the RA record */
  firstAuditorID?: Maybe<Scalars['ID']>
  /** unique identifier for the RA record */
  id: Scalars['ID']
  /** is system audited of the RA record */
  isSystemAudited: Scalars['Boolean']
  /** the second audit's identifier of the RA record */
  secondAuditorID?: Maybe<Scalars['ID']>
  /** status of the RA record */
  status: RaStatus
  /** updated date of the RA record */
  updatedAt: Scalars['Time']
  /** the identifier of user's assessment result for the RA record */
  userAssessmentResultID?: Maybe<Scalars['String']>
}

export type AmlRatingCriteria = {
  __typename?: 'AMLRatingCriteria'
  /** high threshold of the rating criteria */
  high: Scalars['Int']
  /** medium threshold of the rating criteria */
  medium: Scalars['Int']
}

export type AmlRiskAssessmentForm = {
  __typename?: 'AMLRiskAssessmentForm'
  /** assessment items for the risk assessment form */
  assessmentItems: Array<AmlAssessmentItem>
  /** creation date of the risk assessment form */
  createdAt: Scalars['Time']
  /** criteria for the risk assessment form */
  criteria: AmlRatingCriteria
  /** unique identifier for the risk assessment form */
  id: Scalars['String']
  /** updated date of the risk assessment form */
  updatedAt: Scalars['Time']
  /** version of the risk assessment form */
  version: Scalars['String']
}

export type AmlRiskFactor = {
  __typename?: 'AMLRiskFactor'
  /** description of the risk factor */
  description: Scalars['String']
  /** unique identifier for the risk factor */
  id: Scalars['String']
  /** is selected of the risk factor */
  isSelected: Scalars['Boolean']
  /** score of the risk factor */
  score: Scalars['Int']
  /** severity of the risk factor */
  severity: Scalars['String']
}

export enum AmlStatus {
  AmlStatusPending = 'AMLStatusPending',
  AmlStatusRejected = 'AMLStatusRejected',
  AmlStatusUnverified = 'AMLStatusUnverified',
  AmlStatusVerified = 'AMLStatusVerified',
}

export type AmlUserAssessmentResult = {
  __typename?: 'AMLUserAssessmentResult'
  /** assessor's id of the user assessment result */
  assessorID: Scalars['String']
  /** creation date of the user assessment result */
  createdAt: Scalars['Time']
  /** risk assessment form of the user assessment result */
  form: AmlRiskAssessmentForm
  /** unique identifier for the user assessment result */
  id: Scalars['String']
  /** assessment score of the user assessment result */
  score: Scalars['String']
  /** assessment status of the user assessment result */
  status: Scalars['String']
  /** updated date of the user assessment result */
  updatedAt: Scalars['Time']
  /** user's id of the user assessment result */
  userID: Scalars['String']
}

export type AmlVerification = {
  __typename?: 'AMLVerification'
  createdAt: Scalars['Time']
  eddStatus: EddStatus
  id: Scalars['ID']
  nsStatus: NsStatus
  raStatus: RaStatus
  status: AmlStatus
  updatedAt: Scalars['Time']
}

export type ApiKey = {
  __typename?: 'APIKey'
  createdAt: Scalars['Time']
  id: Scalars['ID']
  key: Scalars['String']
  name: Scalars['String']
  permissions: Array<CasbinRole>
  secret?: Maybe<Scalars['String']>
}

export type AcceptFiatWithdrawalInput = {
  userID: Scalars['ID']
  withdrawalID: Scalars['ID']
}

export type AcceptFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AcceptFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotification = Entry & {
  __typename?: 'ActivityNotification'
  contentfulMetadata: ContentfulMetadata
  desc?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<ActivityNotificationLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationDescArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ActivityNotificationCollection = {
  __typename?: 'ActivityNotificationCollection'
  items: Array<Maybe<ActivityNotification>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ActivityNotificationFilter = {
  AND?: InputMaybe<Array<InputMaybe<ActivityNotificationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ActivityNotificationFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  desc?: InputMaybe<Scalars['String']>
  desc_contains?: InputMaybe<Scalars['String']>
  desc_exists?: InputMaybe<Scalars['Boolean']>
  desc_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  desc_not?: InputMaybe<Scalars['String']>
  desc_not_contains?: InputMaybe<Scalars['String']>
  desc_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ActivityNotificationLinkingCollections = {
  __typename?: 'ActivityNotificationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ActivityNotificationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ActivityNotificationOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Address = {
  __typename?: 'Address'
  chain: Chain
  currency: Currency
  value: Scalars['String']
}

export type Admin = {
  __typename?: 'Admin'
  apiKeys: AdminApiKeyResponse
  depositReport: AdminDepositReportResponse
  deposits: AdminDepositResponse
  /** holiday: get legal holiday */
  holiday: AdminHolidayResponse
  ipUsers: AdminIpUserResponse
  kgiReadyUsers: Array<Maybe<User>>
  ledgers: AdminLedgerResponse
  onHoldFiatWithdrawals: AdminOnHoldFiatWithdrawalResponse
  onHoldKGIVerification: AdminOnHoldKgiVerificationResponse
  /** get the latest risk assessment form */
  theLatestAMLRiskAssessmentForm: AmlRiskAssessmentForm
  tradeReport: AdminTradingReportResponse
  trades: AdminTradeResponse
  users?: Maybe<AdminUserResponse>
  verifiers: Array<Maybe<User>>
  /** voucherRecords: 取得兌換碼列表 */
  voucherRecords: AdminVoucherRecordsResponse
  withdrawalReport: AdminWithdrawalReportResponse
  withdrawals: AdminWithdrawalResponse
}

export type AdminApiKeysArgs = {
  filter?: InputMaybe<AdminApiKeyFilter>
  pagination: PaginationQuery
}

export type AdminDepositReportArgs = {
  filter?: InputMaybe<AdminDepositFilter>
}

export type AdminDepositsArgs = {
  filter?: InputMaybe<AdminDepositFilter>
  pagination: PaginationQuery
}

export type AdminHolidayArgs = {
  filter?: InputMaybe<AdminHolidayFilter>
  pagination: PaginationQuery
}

export type AdminIpUsersArgs = {
  input: AdminIpUserFilter
}

export type AdminLedgersArgs = {
  filter?: InputMaybe<AdminLedgerFilter>
  pagination: PaginationQuery
}

export type AdminTradeReportArgs = {
  input: AdminTradeFilter
}

export type AdminTradesArgs = {
  filter?: InputMaybe<AdminTradeFilter>
  pagination: PaginationQuery
}

export type AdminUsersArgs = {
  filter?: InputMaybe<AdminUserFilter>
  pagination: PaginationQuery
}

export type AdminVerifiersArgs = {
  filter: AdminVerifierFilter
}

export type AdminVoucherRecordsArgs = {
  filter?: InputMaybe<AdminVoucherFilter>
  pagination: PaginationQuery
}

export type AdminWithdrawalReportArgs = {
  filter?: InputMaybe<AdminWithdrawalFilter>
}

export type AdminWithdrawalsArgs = {
  filter?: InputMaybe<AdminWithdrawalFilter>
  pagination: PaginationQuery
}

export type AdminApiKeyFilter = {
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminApiKeyResponse = BaseResponse & {
  __typename?: 'AdminAPIKeyResponse'
  data?: Maybe<Array<ApiKey>>
  result: Scalars['Boolean']
}

export type AdminActivateMerchantInput = {
  apiCode: Scalars['String']
  apiSecret: Scalars['String']
  merchantID: Scalars['Int']
  orderPrefix: Scalars['String']
  refreshCode: Scalars['String']
  userID: Scalars['ID']
}

export type AdminActivateMerchantResponse = BaseResponse & {
  __typename?: 'AdminActivateMerchantResponse'
  result: Scalars['Boolean']
}

export type AdminAddAuditNoteInput = {
  note: Scalars['String']
  userID: Scalars['ID']
}

export type AdminAddAuditNoteResponse = BaseResponse & {
  __typename?: 'AdminAddAuditNoteResponse'
  result: Scalars['Boolean']
}

export type AdminApproveVirtualAccountVerificationInput = {
  userID: Scalars['ID']
}

export type AdminApproveVirtualAccountVerificationResponse = BaseResponse & {
  __typename?: 'AdminApproveVirtualAccountVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminAssignKycVerifierInput = {
  level: KycLevel
  userID: Scalars['ID']
  verifierID: Scalars['ID']
}

export type AdminAssignKycVerifierResponse = BaseResponse & {
  __typename?: 'AdminAssignKYCVerifierResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminAuditWithdrawalInput = {
  status: WithdrawalStatus
  withdrawalID: Scalars['ID']
}

export type AdminAuditWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminAuditWithdrawalResponse'
  data?: Maybe<BaseWithdrawal>
  result: Scalars['Boolean']
}

export type AdminChangeVirtualAccountLimitInput = {
  dailyLimit: Scalars['Int']
  monthlyLimit: Scalars['Int']
  txLimit: Scalars['Int']
  vAccount: Scalars['String']
}

export type AdminChangeVirtualAccountLimitResponse = BaseResponse & {
  __typename?: 'AdminChangeVirtualAccountLimitResponse'
  result: Scalars['Boolean']
}

export type AdminCheckDepositInfoInput = {
  endDate: Scalars['Time']
  startDate: Scalars['Time']
  vAccount: Scalars['String']
}

export type AdminCheckDepositInfoResponse = BaseResponse & {
  __typename?: 'AdminCheckDepositInfoResponse'
  result: Scalars['Boolean']
}

export type AdminCheckFileUploadInput = {
  filename: Scalars['String']
}

export type AdminCheckUploadResultMResponse = BaseResponse & {
  __typename?: 'AdminCheckUploadResultMResponse'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultP01Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultP01Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultP02Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultP02Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultP05Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultP05Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultPg1Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultPG1Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultResponse = BaseResponse & {
  __typename?: 'AdminCheckUploadResultResponse'
  result: Scalars['Boolean']
}

export type AdminCreateApiKeyInput = {
  name: Scalars['String']
  permissions?: InputMaybe<Array<CasbinRole>>
  userID: Scalars['ID']
}

export type AdminCreateApiKeyResponse = BaseResponse & {
  __typename?: 'AdminCreateAPIKeyResponse'
  data?: Maybe<ApiKey>
  result: Scalars['Boolean']
}

export type AdminCreateOAuthClientInput = {
  name: Scalars['String']
  redirectURI?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminCreateOAuthClientResponse = BaseResponse & {
  __typename?: 'AdminCreateOAuthClientResponse'
  clientID: Scalars['ID']
  result: Scalars['Boolean']
}

export type AdminCreateRolesInput = {
  roles: Array<UserRole>
  userID: Scalars['ID']
}

export type AdminCreateRolesResponse = BaseResponse & {
  __typename?: 'AdminCreateRolesResponse'
  result: Scalars['Boolean']
}

export type AdminCreateUserInput = {
  email: Scalars['String']
  phone: Scalars['String']
}

export type AdminCreateUserResponse = BaseResponse & {
  __typename?: 'AdminCreateUserResponse'
  result: Scalars['Boolean']
  userID: Scalars['ID']
}

export type AdminDvBanInput = {
  userID: Scalars['ID']
}

export type AdminDvBanResponse = BaseResponse & {
  __typename?: 'AdminDVBanResponse'
  result: Scalars['Boolean']
}

export type AdminDvUpdateInput = {
  userID: Scalars['ID']
  vipLevel: VipLevel
}

export type AdminDvUpdateResponse = BaseResponse & {
  __typename?: 'AdminDVUpdateResponse'
  result: Scalars['Boolean']
}

export type AdminDeleteApiKeyInput = {
  key: Scalars['String']
}

export type AdminDeleteApiKeyResponse = BaseResponse & {
  __typename?: 'AdminDeleteAPIKeyResponse'
  result: Scalars['Boolean']
}

export type AdminDeleteRolesInput = {
  roles: Array<UserRole>
  userID: Scalars['ID']
}

export type AdminDeleteRolesResponse = BaseResponse & {
  __typename?: 'AdminDeleteRolesResponse'
  result: Scalars['Boolean']
}

export type AdminDepositFilter = {
  currency?: InputMaybe<Array<Currency>>
  depositID?: InputMaybe<Scalars['ID']>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<DepositStatus>>
  type?: InputMaybe<Array<DepositType>>
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminDepositReportResponse = BaseResponse & {
  __typename?: 'AdminDepositReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type AdminDepositResponse = BaseResponse & {
  __typename?: 'AdminDepositResponse'
  data?: Maybe<Array<Deposit>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminDisable2FaInput = {
  userID: Scalars['ID']
}

export type AdminDisable2FaResponse = BaseResponse & {
  __typename?: 'AdminDisable2FAResponse'
  result: Scalars['Boolean']
}

export type AdminDisableUserInput = {
  userID: Scalars['ID']
}

export type AdminDisableUserResponse = BaseResponse & {
  __typename?: 'AdminDisableUserResponse'
  result: Scalars['Boolean']
}

export type AdminEddAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminEddAuditResponse = BaseResponse & {
  __typename?: 'AdminEDDAuditResponse'
  record: AmleddRecord
  result: Scalars['Boolean']
}

export type AdminEnableUserInput = {
  userID: Scalars['ID']
}

export type AdminEnableUserResponse = BaseResponse & {
  __typename?: 'AdminEnableUserResponse'
  result: Scalars['Boolean']
}

export type AdminHolidayAddInput = {
  category?: InputMaybe<Scalars['String']>
  date: Scalars['Time']
  name?: InputMaybe<Scalars['String']>
}

export type AdminHolidayAddResponse = BaseResponse & {
  __typename?: 'AdminHolidayAddResponse'
  result: Scalars['Boolean']
}

export type AdminHolidayFilter = {
  end: Scalars['Time']
  /** start & end are in YYYYMMDD */
  start: Scalars['Time']
}

export type AdminHolidayResponse = BaseResponse & {
  __typename?: 'AdminHolidayResponse'
  meta: MetaPagination
  records: Array<Holiday>
  result: Scalars['Boolean']
}

export type AdminIpUserFilter = {
  ipAddress: Scalars['String']
}

export type AdminIpUserResponse = BaseResponse & {
  __typename?: 'AdminIPUserResponse'
  data?: Maybe<Array<User>>
  result: Scalars['Boolean']
}

export type AdminLedgerFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  ledgerID?: InputMaybe<Scalars['ID']>
  startDate?: InputMaybe<Scalars['Time']>
  type?: InputMaybe<Array<WalletOperationType>>
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminLedgerResponse = BaseResponse & {
  __typename?: 'AdminLedgerResponse'
  data?: Maybe<Array<Ledger>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminMutation = {
  __typename?: 'AdminMutation'
  adminActivateMerchant: AdminActivateMerchantResponse
  adminAddAuditNote: AdminAddAuditNoteResponse
  /** approve the result of enhanced due diligence */
  adminApproveEDDAudit: AdminEddAuditResponse
  /** approve the result of name screening */
  adminApproveNSAudit: AdminNsAuditResponse
  /** approve the result of risk assessment */
  adminApproveRAAudit: AdminRaAuditResponse
  adminApproveVirtualAccountVerification: AdminApproveVirtualAccountVerificationResponse
  adminAssignKYCVerifier: AdminAssignKycVerifierResponse
  adminAuditWithdrawal: AdminAuditWithdrawalResponse
  adminChangeVirtualAccountLimit: AdminChangeVirtualAccountLimitResponse
  adminCheckDepositInfo: AdminCheckDepositInfoResponse
  adminCheckUploadResult: AdminCheckUploadResultResponse
  adminCheckUploadResultM: AdminCheckUploadResultMResponse
  adminCheckUploadResultP01: AdminCheckUploadResultP01Response
  adminCheckUploadResultP02: AdminCheckUploadResultP02Response
  adminCheckUploadResultP05: AdminCheckUploadResultP05Response
  adminCheckUploadResultPG1: AdminCheckUploadResultPg1Response
  adminCreateAPIKey: AdminCreateApiKeyResponse
  /** adminCreateOAuthClient: 新增OAuth Client */
  adminCreateOAuthClient: AdminCreateOAuthClientResponse
  adminCreateRoles: AdminCreateRolesResponse
  /** adminCreateUser: 建立user */
  adminCreateUser: AdminCreateUserResponse
  /** adminDVBan: 將入金檢核檔限制調降成0 */
  adminDVBan: AdminDvBanResponse
  /** adminDVUpdate: 升級入金檢核檔額度 */
  adminDVUpdate: AdminDvUpdateResponse
  adminDeleteAPIKey: AdminDeleteApiKeyResponse
  adminDeleteRoles: AdminDeleteRolesResponse
  adminDisable2FA: AdminDisable2FaResponse
  adminDisableUser: AdminDisableUserResponse
  adminEnableUser: AdminEnableUserResponse
  /** adminHolidayAdd: 新增假日(非工作日) */
  adminHolidayAdd: AdminHolidayAddResponse
  adminReauditKYC: AdminReauditKycResponse
  adminRecoverKGIStatus?: Maybe<AdminRecoverKgiStatusResponse>
  adminRefreshMerchant: AdminRefreshMerchantResponse
  /** reject the result of enhanced due diligence */
  adminRejectEDDAudit: AdminEddAuditResponse
  adminRejectFiatWithdrawal: AdminRejectFiatWithdrawalResponse
  adminRejectKGIVerification: AdminRejectKgiVerificationResponse
  /** reject the result of name screening */
  adminRejectNSAudit: AdminNsAuditResponse
  /** reject the result of risk assessment */
  adminRejectRAAudit: AdminRaAuditResponse
  adminRejectVirtualAccountVerication: AdminRejectVirtualAccountVerificationResponse
  adminResendAMLWithdrawInfoP01: AdminResendAmlWithdrawInfoP01Response
  adminResendAMLWithdrawInfoP02: AdminResendAmlWithdrawInfoP02Response
  adminResendFiatWithdrawal: AdminResendFiatWithdrawalResponse
  adminResendKGIVerification: AdminResendKgiVerificationResponse
  adminResendWithdrawInfoP01: AdminResendWithdrawInfoP01Response
  adminResendWithdrawInfoP02: AdminResendWithdrawInfoP02Response
  /** reset the result of enhanced due diligence */
  adminResetEDDAudit: AdminEddAuditResponse
  adminResetKYCVerification: AdminResetKycVerificationResponse
  /** reset the result of name screening */
  adminResetNSAudit: AdminNsAuditResponse
  adminResetPhone: AdminResetPhoneResponse
  /** reset the result of risk assessment */
  adminResetRAAudit: AdminRaAuditResponse
  adminSendBGWithdrawInfo: AdminSendBgWithdrawInfoResponse
  adminSendInvestorsSettlement: AdminSendInvestorsSettlementResponse
  adminSendMemberInfo: AdminSendMemberInfoResponse
  adminSendWithdrawInfoP01: AdminSendWithdrawInfoP01Response
  adminSendWithdrawInfoP02: AdminSendWithdrawInfoP02Response
  adminSetOldUser: AdminSetOlderUserResponse
  adminSetTradePermission: AdminSetTradePermissionResponse
  /** deprecated: use adminAuditNS, adminAuditRA and adminAuditEDD instead */
  adminSubmitAMLVerification: AdminSubmitAmlVerificationResponse
  /** submit the result of enhanced due diligence */
  adminSubmitEDDAudit: AdminEddAuditResponse
  adminSubmitKYCOneVerification: AdminSubmitKycOneVerificationResponse
  adminSubmitKYCTwoVerification: AdminSubmitKycTwoVerificationResponse
  /** submit the result of name screening */
  adminSubmitNSAudit: AdminNsAuditResponse
  /** submit the result of risk assessment */
  adminSubmitRAAudit: AdminSubmitRaAuditResponse
  /** trigger the system to audit AML */
  adminSystemAuditAML: AdminSystemAuditAmlResponse
  adminUpdateCoinInfo: AdminUpdateCoinInfoResponse
  adminUpdateMarketInfo: AdminUpdateMarketInfoResponse
  /** adminUpdateOAuthClient: 修改OAuth Client */
  adminUpdateOAuthClient: AdminUpdateOAuthClientResponse
  adminUpdateQuotationInfo: AdminUpdateQuotationInfoResponse
  adminUploadSuspiciousTransaction: AdminUploadSuspiciousTransactionResponse
  adminUploadVerificationFile: AdminUploadVerificationFileResponse
  /** adminVIPUpgrade: VIP等級調整 */
  adminVIPUpgrade: AdminVipUpdateResponse
  adminVerifyKYCIdentity: AdminVerifyKycIdentityResponse
  /** adminVoucherCreate: 新增兌換碼 */
  adminVoucherCreate: VoucherRecord
  /** adminVoucherEdit: 編輯兌換碼 */
  adminVoucherEdit: VoucherRecord
}

export type AdminMutationAdminActivateMerchantArgs = {
  input: AdminActivateMerchantInput
}

export type AdminMutationAdminAddAuditNoteArgs = {
  input: AdminAddAuditNoteInput
}

export type AdminMutationAdminApproveEddAuditArgs = {
  input: AdminEddAuditInput
}

export type AdminMutationAdminApproveNsAuditArgs = {
  input: AdminNsAuditInput
}

export type AdminMutationAdminApproveRaAuditArgs = {
  input: AdminRaAuditInput
}

export type AdminMutationAdminApproveVirtualAccountVerificationArgs = {
  input: AdminApproveVirtualAccountVerificationInput
}

export type AdminMutationAdminAssignKycVerifierArgs = {
  input: AdminAssignKycVerifierInput
}

export type AdminMutationAdminAuditWithdrawalArgs = {
  input: AdminAuditWithdrawalInput
}

export type AdminMutationAdminChangeVirtualAccountLimitArgs = {
  input: AdminChangeVirtualAccountLimitInput
}

export type AdminMutationAdminCheckDepositInfoArgs = {
  input?: InputMaybe<AdminCheckDepositInfoInput>
}

export type AdminMutationAdminCheckUploadResultArgs = {
  input?: InputMaybe<AdminCheckFileUploadInput>
}

export type AdminMutationAdminCreateApiKeyArgs = {
  input: AdminCreateApiKeyInput
}

export type AdminMutationAdminCreateOAuthClientArgs = {
  input: AdminCreateOAuthClientInput
}

export type AdminMutationAdminCreateRolesArgs = {
  input: AdminCreateRolesInput
}

export type AdminMutationAdminCreateUserArgs = {
  input: AdminCreateUserInput
}

export type AdminMutationAdminDvBanArgs = {
  input: AdminDvBanInput
}

export type AdminMutationAdminDvUpdateArgs = {
  input: AdminDvUpdateInput
}

export type AdminMutationAdminDeleteApiKeyArgs = {
  input: AdminDeleteApiKeyInput
}

export type AdminMutationAdminDeleteRolesArgs = {
  input: AdminDeleteRolesInput
}

export type AdminMutationAdminDisable2FaArgs = {
  input: AdminDisable2FaInput
}

export type AdminMutationAdminDisableUserArgs = {
  input: AdminDisableUserInput
}

export type AdminMutationAdminEnableUserArgs = {
  input: AdminEnableUserInput
}

export type AdminMutationAdminHolidayAddArgs = {
  input: AdminHolidayAddInput
}

export type AdminMutationAdminReauditKycArgs = {
  input: AdminReauditKycInput
}

export type AdminMutationAdminRefreshMerchantArgs = {
  input: AdminRefreshMerchantInput
}

export type AdminMutationAdminRejectEddAuditArgs = {
  input: AdminEddAuditInput
}

export type AdminMutationAdminRejectFiatWithdrawalArgs = {
  input: AdminRejectFiatWithdrawalInput
}

export type AdminMutationAdminRejectKgiVerificationArgs = {
  input: AdminRejectKgiVerificationInput
}

export type AdminMutationAdminRejectNsAuditArgs = {
  input: AdminNsAuditInput
}

export type AdminMutationAdminRejectRaAuditArgs = {
  input: AdminRaAuditInput
}

export type AdminMutationAdminRejectVirtualAccountVericationArgs = {
  input: AdminRejectVirtualAccountVerificationInput
}

export type AdminMutationAdminResendFiatWithdrawalArgs = {
  input: AdminResendFiatWithdrawalInput
}

export type AdminMutationAdminResendKgiVerificationArgs = {
  input: AdminResendKgiVerificationInput
}

export type AdminMutationAdminResendWithdrawInfoP01Args = {
  input?: InputMaybe<AdminResendWithdrawInfoP01Input>
}

export type AdminMutationAdminResendWithdrawInfoP02Args = {
  input?: InputMaybe<AdminResendWithdrawInfoP02Input>
}

export type AdminMutationAdminResetEddAuditArgs = {
  input: AdminEddAuditInput
}

export type AdminMutationAdminResetKycVerificationArgs = {
  input: AdminResetKycVerificationInput
}

export type AdminMutationAdminResetNsAuditArgs = {
  input: AdminNsAuditInput
}

export type AdminMutationAdminResetPhoneArgs = {
  input: AdminResetPhoneInput
}

export type AdminMutationAdminResetRaAuditArgs = {
  input: AdminRaAuditInput
}

export type AdminMutationAdminSendBgWithdrawInfoArgs = {
  input: AdminSendBgWithdrawInfoInput
}

export type AdminMutationAdminSetOldUserArgs = {
  input: AdminSetOldUserInput
}

export type AdminMutationAdminSetTradePermissionArgs = {
  input: AdminSetTradePermissionInput
}

export type AdminMutationAdminSubmitAmlVerificationArgs = {
  input: AdminSubmitAmlVerificationInput
}

export type AdminMutationAdminSubmitEddAuditArgs = {
  input: AdminSubmitEddAuditInput
}

export type AdminMutationAdminSubmitKycOneVerificationArgs = {
  input: AdminSubmitKycOneVerificationInput
}

export type AdminMutationAdminSubmitKycTwoVerificationArgs = {
  input: AdminSubmitKycTwoVerificationInput
}

export type AdminMutationAdminSubmitNsAuditArgs = {
  input: AdminSubmitNsAuditInput
}

export type AdminMutationAdminSubmitRaAuditArgs = {
  input: AdminSubmitRaAuditInput
}

export type AdminMutationAdminSystemAuditAmlArgs = {
  input: AdminSystemAuditAmlInput
}

export type AdminMutationAdminUpdateOAuthClientArgs = {
  input: AdminUpdateOAuthClientInput
}

export type AdminMutationAdminUploadSuspiciousTransactionArgs = {
  input: AdminUploadSuspiciousTransactionInput
}

export type AdminMutationAdminVipUpgradeArgs = {
  input: AdminVipUpdateInput
}

export type AdminMutationAdminVerifyKycIdentityArgs = {
  input: AdminVerifyKycIdentityInput
}

export type AdminMutationAdminVoucherCreateArgs = {
  input: AdminVoucherCreateInput
}

export type AdminMutationAdminVoucherEditArgs = {
  input: AdminVoucherEditInput
}

export type AdminNsAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminNsAuditResponse = BaseResponse & {
  __typename?: 'AdminNSAuditResponse'
  record: AmlnsRecord
  result: Scalars['Boolean']
}

export type AdminOnHoldFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminOnHoldFiatWithdrawalResponse'
  data?: Maybe<Array<OnHoldKgiVerification>>
  result: Scalars['Boolean']
}

export type AdminOnHoldKgiVerificationResponse = BaseResponse & {
  __typename?: 'AdminOnHoldKGIVerificationResponse'
  data?: Maybe<Array<OnHoldKgiVerification>>
  result: Scalars['Boolean']
}

export enum AdminP05Type {
  Bg = 'BG',
  Nc = 'NC',
}

export type AdminRaAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminRaAuditResponse = BaseResponse & {
  __typename?: 'AdminRAAuditResponse'
  record: AmlraRecord
  result: Scalars['Boolean']
}

export type AdminReauditKycInput = {
  level: KycLevel
  userID: Scalars['ID']
}

export type AdminReauditKycResponse = BaseResponse & {
  __typename?: 'AdminReauditKYCResponse'
  result: Scalars['Boolean']
}

export type AdminRecoverKgiStatusResponse = BaseResponse & {
  __typename?: 'AdminRecoverKGIStatusResponse'
  result: Scalars['Boolean']
}

export type AdminRefreshMerchantInput = {
  userID: Scalars['ID']
}

export type AdminRefreshMerchantResponse = BaseResponse & {
  __typename?: 'AdminRefreshMerchantResponse'
  result: Scalars['Boolean']
}

export type AdminRejectFiatWithdrawalInput = {
  reason: Scalars['String']
  refund: Scalars['Boolean']
  withdrawalID: Scalars['ID']
}

export type AdminRejectFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminRejectFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

export type AdminRejectKgiVerificationInput = {
  reason: Scalars['String']
  userID: Scalars['ID']
}

export type AdminRejectKgiVerificationResponse = BaseResponse & {
  __typename?: 'AdminRejectKGIVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminRejectVirtualAccountVerificationInput = {
  reason: Scalars['String']
  userID: Scalars['ID']
}

export type AdminRejectVirtualAccountVerificationResponse = BaseResponse & {
  __typename?: 'AdminRejectVirtualAccountVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminResendAmlWithdrawInfoP01Response = BaseResponse & {
  __typename?: 'AdminResendAMLWithdrawInfoP01Response'
  result: Scalars['Boolean']
}

export type AdminResendAmlWithdrawInfoP02Response = BaseResponse & {
  __typename?: 'AdminResendAMLWithdrawInfoP02Response'
  result: Scalars['Boolean']
}

export type AdminResendFiatWithdrawalInput = {
  withdrawalID: Scalars['ID']
}

export type AdminResendFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminResendFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

export type AdminResendKgiVerificationInput = {
  userID: Scalars['ID']
}

export type AdminResendKgiVerificationResponse = BaseResponse & {
  __typename?: 'AdminResendKGIVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP01Input = {
  isAMLResend: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP01Response = BaseResponse & {
  __typename?: 'AdminResendWithdrawInfoP01Response'
  result: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP02Input = {
  isAMLResend: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP02Response = BaseResponse & {
  __typename?: 'AdminResendWithdrawInfoP02Response'
  result: Scalars['Boolean']
}

export type AdminResetKycVerificationInput = {
  level: KycLevel
  userID: Scalars['ID']
}

export type AdminResetKycVerificationResponse = BaseResponse & {
  __typename?: 'AdminResetKYCVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminResetPhoneInput = {
  userID: Scalars['ID']
}

export type AdminResetPhoneResponse = BaseResponse & {
  __typename?: 'AdminResetPhoneResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSendBgWithdrawInfoInput = {
  amount: Scalars['Int']
  type?: InputMaybe<AdminP05Type>
}

export type AdminSendBgWithdrawInfoResponse = BaseResponse & {
  __typename?: 'AdminSendBGWithdrawInfoResponse'
  result: Scalars['Boolean']
}

export type AdminSendInvestorsSettlementResponse = BaseResponse & {
  __typename?: 'AdminSendInvestorsSettlementResponse'
  result: Scalars['Boolean']
}

export type AdminSendMemberInfoResponse = BaseResponse & {
  __typename?: 'AdminSendMemberInfoResponse'
  result: Scalars['Boolean']
}

export type AdminSendWithdrawInfoP01Response = BaseResponse & {
  __typename?: 'AdminSendWithdrawInfoP01Response'
  result: Scalars['Boolean']
}

export type AdminSendWithdrawInfoP02Response = BaseResponse & {
  __typename?: 'AdminSendWithdrawInfoP02Response'
  result: Scalars['Boolean']
}

export type AdminSetOldUserInput = {
  setOldUser: Scalars['Boolean']
  userID: Scalars['ID']
}

export type AdminSetOlderUserResponse = BaseResponse & {
  __typename?: 'AdminSetOlderUserResponse'
  result: Scalars['Boolean']
}

export type AdminSetTradePermissionInput = {
  enable: Scalars['Boolean']
  userID: Scalars['ID']
}

export type AdminSetTradePermissionResponse = BaseResponse & {
  __typename?: 'AdminSetTradePermissionResponse'
  result: Scalars['Boolean']
}

export type AdminSubmitAmlVerificationInput = {
  eddStatus: EddStatus
  nsStatus: NsStatus
  raStatus: RaStatus
  userID: Scalars['ID']
}

export type AdminSubmitAmlVerificationResponse = BaseResponse & {
  __typename?: 'AdminSubmitAMLVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSubmitEddAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  status: EddStatus
  userID: Scalars['ID']
}

export type AdminSubmitKycOneVerificationInput = {
  addressPhotoVerificationResult: PhotoVerificationResultInput
  idBackPhotoVerificationResult: PhotoVerificationResultInput
  idFrontPhotoVerificationResult: PhotoVerificationResultInput
  secondIDPhotoVerificationResult: PhotoVerificationResultInput
  selfiePhotoVerificationResult: PhotoVerificationResultInput
  userID: Scalars['ID']
}

export type AdminSubmitKycOneVerificationResponse = BaseResponse & {
  __typename?: 'AdminSubmitKYCOneVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSubmitKycTwoVerificationInput = {
  bankPhotoVerificationResult: PhotoVerificationResultInput
  userID: Scalars['ID']
}

export type AdminSubmitKycTwoVerificationResponse = BaseResponse & {
  __typename?: 'AdminSubmitKYCTwoVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSubmitNsAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  status: NsStatus
  userID: Scalars['ID']
}

export type AdminSubmitRaAuditInput = {
  /** identifiers of selected factors */
  factorIDs: Array<Scalars['String']>
  /** identifier of risk assessment form */
  formID: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminSubmitRaAuditResponse = BaseResponse & {
  __typename?: 'AdminSubmitRAAuditResponse'
  record: AmlraRecord
  result: Scalars['Boolean']
  userAssessmentResult: AmlUserAssessmentResult
}

export type AdminSystemAuditAmlInput = {
  userID: Scalars['ID']
}

export type AdminSystemAuditAmlResponse = BaseResponse & {
  __typename?: 'AdminSystemAuditAMLResponse'
  result: Scalars['Boolean']
}

export type AdminTradeFilter = {
  endDate?: InputMaybe<Scalars['Time']>
  markets?: InputMaybe<Array<Market>>
  side?: InputMaybe<OrderSide>
  startDate?: InputMaybe<Scalars['Time']>
  tradeID?: InputMaybe<Scalars['ID']>
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminTradeResponse = BaseResponse & {
  __typename?: 'AdminTradeResponse'
  data?: Maybe<Array<Trade>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminTradingReportResponse = BaseResponse & {
  __typename?: 'AdminTradingReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type AdminUpdateCoinInfoResponse = BaseResponse & {
  __typename?: 'AdminUpdateCoinInfoResponse'
  result: Scalars['Boolean']
}

export type AdminUpdateMarketInfoResponse = BaseResponse & {
  __typename?: 'AdminUpdateMarketInfoResponse'
  result: Scalars['Boolean']
}

export type AdminUpdateOAuthClientInput = {
  clientID: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  redirectURI?: InputMaybe<Scalars['String']>
}

export type AdminUpdateOAuthClientResponse = BaseResponse & {
  __typename?: 'AdminUpdateOAuthClientResponse'
  result: Scalars['Boolean']
}

export type AdminUpdateQuotationInfoResponse = BaseResponse & {
  __typename?: 'AdminUpdateQuotationInfoResponse'
  result: Scalars['Boolean']
}

export type AdminUploadSuspiciousTransactionInput = {
  baseDate: Scalars['Time']
}

export type AdminUploadSuspiciousTransactionResponse = BaseResponse & {
  __typename?: 'AdminUploadSuspiciousTransactionResponse'
  result: Scalars['Boolean']
}

export type AdminUploadVerificationFileResponse = BaseResponse & {
  __typename?: 'AdminUploadVerificationFileResponse'
  result: Scalars['Boolean']
}

export type AdminUserFilter = {
  email?: InputMaybe<Scalars['String']>
  kycStatus?: InputMaybe<KycStatus>
  name?: InputMaybe<Scalars['String']>
  updatedAfter?: InputMaybe<Scalars['Time']>
  updatedBefore?: InputMaybe<Scalars['Time']>
  userID?: InputMaybe<Scalars['ID']>
  verifierID?: InputMaybe<Scalars['ID']>
}

export type AdminUserResponse = BaseResponse & {
  __typename?: 'AdminUserResponse'
  data?: Maybe<Array<User>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminVipUpdateInput = {
  userID: Scalars['ID']
  vipLevel: VipLevel
}

export type AdminVipUpdateResponse = BaseResponse & {
  __typename?: 'AdminVIPUpdateResponse'
  result: Scalars['Boolean']
}

export type AdminVerifierFilter = {
  roles: Array<UserRole>
}

export type AdminVerifyKycIdentityInput = {
  userID: Scalars['ID']
}

export type AdminVerifyKycIdentityResponse = BaseResponse & {
  __typename?: 'AdminVerifyKYCIdentityResponse'
  result: Scalars['Boolean']
}

export type AdminVoucherCreateInput = {
  /** code: 兌換碼，最大長度 8，為空將由系統自動產出 */
  code?: InputMaybe<Scalars['String']>
  /** description: 兌換碼說明，最大長度 100 */
  description?: InputMaybe<Scalars['String']>
  /** expiredDate: 兌換碼到期日期，空代表無限制 */
  expiredDate?: InputMaybe<Scalars['Time']>
  /** maxUsageTimes: 兌換碼最大可用次數 */
  maxUsageTimes: Scalars['Int']
  /** name: 兌換碼名稱，最大長度 20 */
  name: Scalars['String']
  /** point: 兌換點數 */
  point: Scalars['Int']
  /** type: 兌換碼類型，最大長度 8 */
  type?: InputMaybe<Scalars['String']>
}

export type AdminVoucherEditInput = {
  /** code: 兌換碼 */
  code: Scalars['String']
  /** description: 兌換碼說明，最大長度 100 */
  description?: InputMaybe<Scalars['String']>
  /** isDisabled: 兌換碼是否被停用，true:停用 false:可用 */
  isIsDisabled?: InputMaybe<Scalars['Boolean']>
  /** name: 兌換碼名稱，最大長度 20 */
  name?: InputMaybe<Scalars['String']>
  /** type: 兌換碼類型，最大長度 8 */
  type?: InputMaybe<Scalars['String']>
}

export type AdminVoucherFilter = {
  code?: InputMaybe<Scalars['String']>
  expiredDate?: InputMaybe<Scalars['Time']>
  name?: InputMaybe<Scalars['String']>
  skipExpired?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<Scalars['String']>
}

export type AdminVoucherRecordsResponse = BaseResponse & {
  __typename?: 'AdminVoucherRecordsResponse'
  meta: MetaPagination
  records: Array<VoucherRecord>
  result: Scalars['Boolean']
}

export type AdminWithdrawalFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<WithdrawalStatus>>
  type?: InputMaybe<Array<WithdrawalType>>
  userID?: InputMaybe<Scalars['ID']>
  withdrawalID?: InputMaybe<Scalars['ID']>
}

export type AdminWithdrawalReportResponse = BaseResponse & {
  __typename?: 'AdminWithdrawalReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type AdminWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminWithdrawalResponse'
  data?: Maybe<Array<Withdrawal>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type ApproveKycLv2Input = {
  userID: Scalars['ID']
}

export type ApproveKycLv2Response = BaseResponse & {
  __typename?: 'ApproveKYCLv2Response'
  result: Scalars['Boolean']
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset'
  contentType?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  linkedFrom?: Maybe<AssetLinkingCollections>
  size?: Maybe<Scalars['Int']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
  transform?: InputMaybe<ImageTransformOptions>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AssetCollection = {
  __typename?: 'AssetCollection'
  items: Array<Maybe<Asset>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  contentType?: InputMaybe<Scalars['String']>
  contentType_contains?: InputMaybe<Scalars['String']>
  contentType_exists?: InputMaybe<Scalars['Boolean']>
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentType_not?: InputMaybe<Scalars['String']>
  contentType_not_contains?: InputMaybe<Scalars['String']>
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName?: InputMaybe<Scalars['String']>
  fileName_contains?: InputMaybe<Scalars['String']>
  fileName_exists?: InputMaybe<Scalars['Boolean']>
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName_not?: InputMaybe<Scalars['String']>
  fileName_not_contains?: InputMaybe<Scalars['String']>
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  height?: InputMaybe<Scalars['Int']>
  height_exists?: InputMaybe<Scalars['Boolean']>
  height_gt?: InputMaybe<Scalars['Int']>
  height_gte?: InputMaybe<Scalars['Int']>
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  height_lt?: InputMaybe<Scalars['Int']>
  height_lte?: InputMaybe<Scalars['Int']>
  height_not?: InputMaybe<Scalars['Int']>
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size?: InputMaybe<Scalars['Int']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_gt?: InputMaybe<Scalars['Int']>
  size_gte?: InputMaybe<Scalars['Int']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size_lt?: InputMaybe<Scalars['Int']>
  size_lte?: InputMaybe<Scalars['Int']>
  size_not?: InputMaybe<Scalars['Int']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  width?: InputMaybe<Scalars['Int']>
  width_exists?: InputMaybe<Scalars['Boolean']>
  width_gt?: InputMaybe<Scalars['Int']>
  width_gte?: InputMaybe<Scalars['Int']>
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  width_lt?: InputMaybe<Scalars['Int']>
  width_lte?: InputMaybe<Scalars['Int']>
  width_not?: InputMaybe<Scalars['Int']>
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections'
  displayItemCollection?: Maybe<DisplayItemCollection>
  entryCollection?: Maybe<EntryCollection>
  heroSliderCollection?: Maybe<HeroSliderCollection>
  popUpCollection?: Maybe<PopUpCollection>
}

export type AssetLinkingCollectionsDisplayItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsDisplayItemCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsHeroSliderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsHeroSliderCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPopUpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsPopUpCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AssetLinkingCollectionsDisplayItemCollectionOrder {
  EnableSpecialSharingBackgroundsModeAsc = 'enableSpecialSharingBackgroundsMode_ASC',
  EnableSpecialSharingBackgroundsModeDesc = 'enableSpecialSharingBackgroundsMode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsHeroSliderCollectionOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsPopUpCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  CallToActionButtonLinkAsc = 'callToActionButtonLink_ASC',
  CallToActionButtonLinkDesc = 'callToActionButtonLink_DESC',
  CallToActionButtonTextAsc = 'callToActionButtonText_ASC',
  CallToActionButtonTextDesc = 'callToActionButtonText_DESC',
  CallToActionButtonVariantAsc = 'callToActionButtonVariant_ASC',
  CallToActionButtonVariantDesc = 'callToActionButtonVariant_DESC',
  CloseButtonTextAsc = 'closeButtonText_ASC',
  CloseButtonTextDesc = 'closeButtonText_DESC',
  CloseButtonVariantAsc = 'closeButtonVariant_ASC',
  CloseButtonVariantDesc = 'closeButtonVariant_DESC',
  DisplayEnvironmentAsc = 'displayEnvironment_ASC',
  DisplayEnvironmentDesc = 'displayEnvironment_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

export type Attributes = {
  __typename?: 'Attributes'
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type Auth2Fa = {
  __typename?: 'Auth2FA'
  qrCode: Scalars['String']
  secret: Scalars['String']
  token2FA: Scalars['String']
}

export type AuthBind2FaResponse = BaseResponse & {
  __typename?: 'AuthBind2FAResponse'
  data: Auth2Fa
  result: Scalars['Boolean']
}

export type AuthConfirm2FaInput = {
  code2FA: Scalars['String']
  token2FA: Scalars['String']
}

export type AuthConfirm2FaResponse = BaseResponse & {
  __typename?: 'AuthConfirm2FAResponse'
  data?: Maybe<Confirm2FaData>
  result: Scalars['Boolean']
}

export type AuthMutation = {
  __typename?: 'AuthMutation'
  authBind2FA: AuthBind2FaResponse
  authConfirm2FA: AuthConfirm2FaResponse
  authUnbind2FA: AuthUnbind2FaResponse
}

export type AuthMutationAuthConfirm2FaArgs = {
  input: AuthConfirm2FaInput
}

export type AuthMutationAuthUnbind2FaArgs = {
  input: AuthUnbind2FaInput
}

export type AuthUnbind2FaInput = {
  code2FA: Scalars['String']
}

export type AuthUnbind2FaResponse = BaseResponse & {
  __typename?: 'AuthUnbind2FAResponse'
  result: Scalars['Boolean']
}

export type Balance = {
  __typename?: 'Balance'
  available: Scalars['Number']
  currency: Currency
  frozen: Scalars['Number']
  total: Scalars['Number']
  type: CurrencyType
}

export type Bank = {
  __typename?: 'Bank'
  branches: Array<Maybe<BankBranch>>
  code: Scalars['String']
  name: Scalars['String']
}

export type BankAccount = {
  __typename?: 'BankAccount'
  account: Scalars['String']
  accountHolder: Scalars['String']
  bankCode: Scalars['String']
  bankName: Scalars['String']
  branchCode?: Maybe<Scalars['String']>
  branchName?: Maybe<Scalars['String']>
  swiftCode?: Maybe<Scalars['String']>
}

export type BankBranch = {
  __typename?: 'BankBranch'
  code: Scalars['String']
  name: Scalars['String']
}

export type BankInfo = {
  __typename?: 'BankInfo'
  account: Scalars['String']
  accountHolder: Scalars['String']
  bankCode: Scalars['String']
  branchCode: Scalars['String']
  createdAt: Scalars['Time']
  id: Scalars['ID']
  updatedAt: Scalars['Time']
}

export type BankInfoInput = {
  account: Scalars['String']
  bankCode: Scalars['String']
  bankPhotoResourceID: Scalars['ID']
  branchCode: Scalars['String']
  holder: Scalars['String']
}

export type BaseDeposit = {
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  id: Scalars['ID']
  status: DepositStatus
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type BaseResponse = {
  result: Scalars['Boolean']
}

export type BaseWithdrawal = {
  amount?: Maybe<Scalars['Number']>
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency?: Maybe<Currency>
  fee?: Maybe<Scalars['Number']>
  feeCurrency?: Maybe<Currency>
  id: Scalars['ID']
  status?: Maybe<WithdrawalStatus>
  type?: Maybe<WithdrawalType>
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type BasicInfo = {
  __typename?: 'BasicInfo'
  address?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  gender?: Maybe<Gender>
  id: Scalars['ID']
  industry?: Maybe<Industry>
  jobTitle?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt: Scalars['Time']
}

export type BasicInfoInput = {
  address?: InputMaybe<Scalars['String']>
  birthday?: InputMaybe<Scalars['Time']>
  gender?: InputMaybe<Gender>
  industry?: InputMaybe<Industry>
  jobTitle?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export enum CasbinRole {
  RoleAdmin = 'RoleAdmin',
  RoleCmplManager = 'RoleCMPLManager',
  RoleCmplMember = 'RoleCMPLMember',
  RoleCsManager = 'RoleCSManager',
  RoleCsMember = 'RoleCSMember',
  RoleDeveloper = 'RoleDeveloper',
  RoleFinManager = 'RoleFINManager',
  RoleFinMember = 'RoleFINMember',
  RoleMktManager = 'RoleMKTManager',
  RoleMktMember = 'RoleMKTMember',
  RoleRcManager = 'RoleRCManager',
  RoleRcMember = 'RoleRCMember',
  RoleServiceFaas = 'RoleServiceFAAS',
  RoleServiceFaasMerchant = 'RoleServiceFAASMerchant',
  RoleServiceKyc = 'RoleServiceKYC',
  RoleServiceMine = 'RoleServiceMine',
  RoleUserBabyWhale = 'RoleUserBabyWhale',
  RoleUserBigWhale = 'RoleUserBigWhale',
  RoleUserGiantWhale = 'RoleUserGiantWhale',
  RoleUserLv1 = 'RoleUserLV1',
  RoleUserLv2 = 'RoleUserLV2',
  RoleUserLittleWhale = 'RoleUserLittleWhale',
  RoleUserNew = 'RoleUserNew',
  RoleUserPublic = 'RoleUserPublic',
  RoleUserVip = 'RoleUserVIP',
  RoleUserVvip = 'RoleUserVVIP',
}

export type CefiDepositAddress = {
  __typename?: 'CefiDepositAddress'
  address: Scalars['String']
  chain: Chain
}

export type CefiDepositAddressInput = {
  chain: Chain
  currency: Scalars['String']
  exchange: Exchange
  wallet?: InputMaybe<WalletType>
}

export type CefiWallet = {
  __typename?: 'CefiWallet'
  exchange: Scalars['String']
  walletBalances: Array<WalletBalances>
}

export type CefiWalletFilterInput = {
  exchange: Exchange
  hideTinyUSDValue?: InputMaybe<Scalars['Int']>
}

export enum Chain {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Tron = 'Tron',
}

export type ChainFilter = {
  chain?: InputMaybe<Array<Chain>>
}

export type ChartInput = {
  endTime: Scalars['Time']
  granularity: Granularity
  market: Market
  priceType: PriceType
  startTime?: InputMaybe<Scalars['Time']>
}

export type ChartResponse = BaseResponse & {
  __typename?: 'ChartResponse'
  data?: Maybe<Array<MarketPrice>>
  result: Scalars['Boolean']
}

export type CloseUniswapv3PositionInput = {
  positionId: Scalars['String']
  txHash: Scalars['String']
}

export type CoinPrice = {
  __typename?: 'CoinPrice'
  symbol: Scalars['String']
  usdtPrice: Scalars['String']
}

export type CommonAddress = {
  __typename?: 'CommonAddress'
  address: Address
  id: Scalars['ID']
  name: Scalars['String']
}

export type CommonAddressFilter = {
  chains?: InputMaybe<Array<Chain>>
  currencies?: InputMaybe<Array<Currency>>
}

export type CommonAddressResponse = BaseResponse & {
  __typename?: 'CommonAddressResponse'
  data?: Maybe<Array<CommonAddress>>
  result: Scalars['Boolean']
}

export type Confirm2FaData =
  | CryptoWithdrawal
  | ExternalApiKeyWithdrawal
  | FaasPayment
  | FiatWithdrawal
  | InternalTransferOut
  | LoginInfo

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata'
  tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>
  tags_exists?: InputMaybe<Scalars['Boolean']>
}

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type Country = {
  __typename?: 'Country'
  callingCode: Scalars['String']
  code: Scalars['String']
}

export type CreateAiCompletionRecordInput = {
  expectedReturn: Scalars['String']
  goal: Scalars['String']
  investmentAmount: Scalars['String']
  investmentPeriod: Scalars['String']
  items: Array<CreateAiCompletionRecordItemInput>
  promptVersion?: InputMaybe<PromptVersion>
  riskToleranceLevel: RiskToleranceLevelName
}

export type CreateAiCompletionRecordItemInput = {
  desc: Scalars['String']
  highestAnnualReturn: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
}

export type CreateUniswapv3PositionInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type CreateUniswapv3ToolInput = {
  chain: EvmChain
  description?: InputMaybe<Scalars['String']>
  feeTier: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  outOfLowerRange: OutOfRangeInfoInput
  outOfUpperRange: OutOfRangeInfoInput
  tickLowerDiff: Scalars['Float']
  tickUpperDiff: Scalars['Float']
  token0Address: Scalars['String']
  token1Address: Scalars['String']
}

export type Creator = {
  __typename?: 'Creator'
  address: Scalars['String']
  id: Scalars['String']
}

export type CryptoAsset = {
  __typename?: 'CryptoAsset'
  asset?: Maybe<PaymentMethod>
}

export type CryptoAssetAssetArgs = {
  currency?: InputMaybe<Currency>
}

export type CryptoDeposit = BaseDeposit & {
  __typename?: 'CryptoDeposit'
  amount: Scalars['Number']
  chain?: Maybe<Chain>
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fromAddress?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: DepositStatus
  toAddress: Scalars['String']
  txID?: Maybe<Scalars['String']>
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type CryptoWithdrawal = BaseWithdrawal & {
  __typename?: 'CryptoWithdrawal'
  amount: Scalars['Number']
  chain: Chain
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fee: Scalars['Number']
  feeCurrency: Currency
  fromAddress?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isDeduction: Scalars['Boolean']
  status: WithdrawalStatus
  toAddress: Scalars['String']
  txID?: Maybe<Scalars['String']>
  type: WithdrawalType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export enum Currency {
  Btc = 'BTC',
  Eth = 'ETH',
  Matic = 'MATIC',
  Trx = 'TRX',
  Twd = 'TWD',
  Usdt = 'USDT',
}

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount'
  amount: Scalars['String']
  currency: Scalars['String']
}

export type CurrencyBalance = {
  __typename?: 'CurrencyBalance'
  available: Scalars['String']
  total: Scalars['String']
}

export type CurrencyBalances = {
  __typename?: 'CurrencyBalances'
  balance: CurrencyBalance
  currency: Scalars['String']
}

export type CurrencyFilter = {
  currency?: InputMaybe<Array<Currency>>
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo'
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
}

export enum CurrencyType {
  CurrencyTypeCrypto = 'CurrencyTypeCrypto',
  CurrencyTypeFiat = 'CurrencyTypeFiat',
  CurrencyTypeStable = 'CurrencyTypeStable',
}

export type DefiTokensBalanceAmountInput = {
  chain: EvmChain
  chainId: Scalars['Int']
  tokenAddresses: Array<Scalars['String']>
}

export type DefiWallet = {
  __typename?: 'DefiWallet'
  balance: Scalars['String']
  balanceComputed?: Maybe<Scalars['String']>
  balanceComputedWithUnit?: Maybe<Scalars['String']>
  chain: EvmChain
  currencyInfo: CurrencyInfo
  id: Scalars['String']
}

export type DefiWalletFilterInput = {
  address: Scalars['String']
}

export type DefiWalletsFilterInput = {
  chains?: InputMaybe<Array<EvmChain>>
}

export type Deposit = CryptoDeposit | FiatDeposit | InternalTransferIn

export type DepositFilter = {
  currency?: InputMaybe<Array<Currency>>
  depositID?: InputMaybe<Scalars['ID']>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<DepositStatus>>
  type?: InputMaybe<Array<DepositType>>
}

export type DepositResponse = BaseResponse & {
  __typename?: 'DepositResponse'
  data?: Maybe<Array<Deposit>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export enum DepositStatus {
  DepositStatusAmlScreenIgnored = 'DepositStatusAMLScreenIgnored',
  DepositStatusBlocklistIgnored = 'DepositStatusBlocklistIgnored',
  DepositStatusCompleted = 'DepositStatusCompleted',
  DepositStatusRejected = 'DepositStatusRejected',
  DepositStatusWaitingConfirmation = 'DepositStatusWaitingConfirmation',
}

export enum DepositType {
  DepositTypeCrypto = 'DepositTypeCrypto',
  DepositTypeFiat = 'DepositTypeFiat',
  DepositTypeInternalTransfer = 'DepositTypeInternalTransfer',
  DepositTypeManualDeposit = 'DepositTypeManualDeposit',
  DepositTypeStakeReward = 'DepositTypeStakeReward',
}

export type DevMutation = {
  __typename?: 'DevMutation'
  acceptFiatWithdrawal: AcceptFiatWithdrawalResponse
  approveKYCLv2: ApproveKycLv2Response
  rejectFiatWithdrawal: RejectFiatWithdrawalResponse
}

export type DevMutationAcceptFiatWithdrawalArgs = {
  input: AcceptFiatWithdrawalInput
}

export type DevMutationApproveKycLv2Args = {
  input: ApproveKycLv2Input
}

export type DevMutationRejectFiatWithdrawalArgs = {
  input: RejectFiatWithdrawalInput
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItem = Entry & {
  __typename?: 'DisplayItem'
  contentfulMetadata: ContentfulMetadata
  defaultSharingBackground?: Maybe<Asset>
  enableSpecialSharingBackgroundsMode?: Maybe<Scalars['Boolean']>
  forUsersCollection?: Maybe<DisplayItemForUsersCollection>
  linkedFrom?: Maybe<DisplayItemLinkingCollections>
  purpose?: Maybe<Scalars['String']>
  specialSharingBackgroundsCollection?: Maybe<AssetCollection>
  sys: Sys
  title?: Maybe<Scalars['String']>
  userProfileImage?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemDefaultSharingBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemEnableSpecialSharingBackgroundsModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemForUsersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<DisplayItemForUsersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SpecialUserFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemPurposeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemSpecialSharingBackgroundsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemUserProfileImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type DisplayItemCollection = {
  __typename?: 'DisplayItemCollection'
  items: Array<Maybe<DisplayItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type DisplayItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<DisplayItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<DisplayItemFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  defaultSharingBackground_exists?: InputMaybe<Scalars['Boolean']>
  enableSpecialSharingBackgroundsMode?: InputMaybe<Scalars['Boolean']>
  enableSpecialSharingBackgroundsMode_exists?: InputMaybe<Scalars['Boolean']>
  enableSpecialSharingBackgroundsMode_not?: InputMaybe<Scalars['Boolean']>
  forUsers?: InputMaybe<CfSpecialUserNestedFilter>
  forUsersCollection_exists?: InputMaybe<Scalars['Boolean']>
  purpose?: InputMaybe<Scalars['String']>
  purpose_contains?: InputMaybe<Scalars['String']>
  purpose_exists?: InputMaybe<Scalars['Boolean']>
  purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  purpose_not?: InputMaybe<Scalars['String']>
  purpose_not_contains?: InputMaybe<Scalars['String']>
  purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  specialSharingBackgroundsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  userProfileImage_exists?: InputMaybe<Scalars['Boolean']>
}

export type DisplayItemForUsersCollection = {
  __typename?: 'DisplayItemForUsersCollection'
  items: Array<Maybe<SpecialUser>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DisplayItemForUsersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type DisplayItemLinkingCollections = {
  __typename?: 'DisplayItemLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type DisplayItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum DisplayItemOrder {
  EnableSpecialSharingBackgroundsModeAsc = 'enableSpecialSharingBackgroundsMode_ASC',
  EnableSpecialSharingBackgroundsModeDesc = 'enableSpecialSharingBackgroundsMode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum EddStatus {
  EddStatusAccepted = 'EDDStatusAccepted',
  EddStatusPending = 'EDDStatusPending',
  EddStatusRejected = 'EDDStatusRejected',
  EddStatusUnverified = 'EDDStatusUnverified',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata
  sys: Sys
}

export type EntryCollection = {
  __typename?: 'EntryCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum EvmChain {
  Arbitrum = 'Arbitrum',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  Optimism = 'Optimism',
  OptimismGoerli = 'OptimismGoerli',
  Polygon = 'Polygon',
  Unknown = 'Unknown',
}

export enum Exchange {
  Bitgin = 'BITGIN',
  Binance = 'Binance',
  Bitfinex = 'Bitfinex',
  Convex = 'Convex',
  Ftx = 'FTX',
  Max = 'MAX',
  Tradingview = 'Tradingview',
  Uniswap = 'Uniswap',
  Unknown = 'Unknown',
}

export type ExternalApiKey = {
  __typename?: 'ExternalAPIKey'
  createdAt?: Maybe<Scalars['String']>
  exchange: Exchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  user?: Maybe<UserInfo>
  userId?: Maybe<Scalars['String']>
}

export type ExternalApiKeyCreateInput = {
  exchange: Exchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
}

export type ExternalApiKeyDeleteInput = {
  id: Scalars['String']
}

export type ExternalApiKeyWithdrawal = {
  __typename?: 'ExternalAPIKeyWithdrawal'
  id: Scalars['ID']
}

export type ExternalApiKeysFilter = {
  exchange?: InputMaybe<Exchange>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export enum ExternalWallet {
  WalletBfxExchange = 'WalletBFXExchange',
  WalletBfxFunding = 'WalletBFXFunding',
  WalletBfxMargin = 'WalletBFXMargin',
  WalletBncSaving = 'WalletBNCSaving',
  /** Default to Spot if exchange is not supporting different wallet types */
  WalletSpot = 'WalletSpot',
}

export type FaasPayment = {
  __typename?: 'FAASPayment'
  createdAt: Scalars['Time']
  cryptoWithdrawal?: Maybe<CryptoWithdrawal>
  id: Scalars['ID']
  internalTransferOut?: Maybe<InternalTransferOut>
  merchantID: Scalars['ID']
  updatedAt: Scalars['Time']
}

export type FaasPaymentFilter = {
  currencies?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  merchantID?: InputMaybe<Scalars['ID']>
  orderID?: InputMaybe<Scalars['String']>
  paymentID?: InputMaybe<Scalars['ID']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<WithdrawalStatus>>
  type?: InputMaybe<Array<WithdrawalType>>
}

export type FaasPaymentResponse = BaseResponse & {
  __typename?: 'FAASPaymentResponse'
  data?: Maybe<Array<FaasPayment>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type FiatDeposit = BaseDeposit & {
  __typename?: 'FiatDeposit'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  id: Scalars['ID']
  status: DepositStatus
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type FiatDepositInfo = {
  __typename?: 'FiatDepositInfo'
  dailyAccumulated: Scalars['Number']
  monthlyAccumulated: Scalars['Number']
  userBindBank: BankAccount
  virtualBank: BankAccount
}

export type FiatWithdrawal = BaseWithdrawal & {
  __typename?: 'FiatWithdrawal'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fee: Scalars['Number']
  feeCurrency: Currency
  id: Scalars['ID']
  status: WithdrawalStatus
  toBankAccount: BankInfo
  toBankAccountID: Scalars['ID']
  type: WithdrawalType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type FrontendVersion = {
  __typename?: 'FrontendVersion'
  commitHash: Scalars['String']
  smartContracts: Array<SmartContract>
  version: Scalars['String']
}

export enum Gender {
  GenderFemale = 'GenderFemale',
  GenderMale = 'GenderMale',
}

export type GimCoolPayPaymentInfo = {
  __typename?: 'GimCoolPayPaymentInfo'
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount?: Maybe<Scalars['String']>
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatAmount?: Maybe<Scalars['String']>
  fiatType: GimCoolPayPaymentSupportingFiatType
  paymentHref: Scalars['String']
  price?: Maybe<GimCoolPayPriceResponse>
  walletAddress: Scalars['String']
  walletAddressTag?: Maybe<Scalars['String']>
}

export type GimCoolPayPaymentInfoInput = {
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount: Scalars['String']
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatType: GimCoolPayPaymentSupportingFiatType
  isSandBox?: InputMaybe<Scalars['Boolean']>
}

export enum GimCoolPayPaymentSupportingBlockchain {
  Eth = 'ETH',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingCoinType {
  Eth = 'ETH',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingFiatType {
  Usd = 'USD',
  Unknown = 'Unknown',
}

export type GimCoolPayPriceResponse = {
  __typename?: 'GimCoolPayPriceResponse'
  prices?: Maybe<Array<GimCoolPayReadyToPurchasePrice>>
  spotPrice: Scalars['String']
}

export type GimCoolPayReadyToPurchasePrice = {
  __typename?: 'GimCoolPayReadyToPurchasePrice'
  coinAmount: Scalars['String']
  coinCode: Scalars['String']
  feeAmount: Scalars['String']
  fiatAmount: Scalars['String']
  fiatCode: Scalars['String']
  networkFee: Scalars['String']
  paymentMethodId: Scalars['String']
  spotPriceFee: Scalars['String']
  spotPriceIncludingFee: Scalars['String']
  type: Scalars['String']
}

export enum Granularity {
  Granularity1d = 'Granularity1d',
  Granularity1h = 'Granularity1h',
  Granularity1m = 'Granularity1m',
  Granularity1w = 'Granularity1w',
  Granularity4h = 'Granularity4h',
  Granularity5m = 'Granularity5m',
  Granularity12h = 'Granularity12h',
  Granularity15m = 'Granularity15m',
  Granularity30m = 'Granularity30m',
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSlider = Entry & {
  __typename?: 'HeroSlider'
  contentfulMetadata: ContentfulMetadata
  image?: Maybe<Asset>
  link?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<HeroSliderLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type HeroSliderCollection = {
  __typename?: 'HeroSliderCollection'
  items: Array<Maybe<HeroSlider>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HeroSliderFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeroSliderFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HeroSliderFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HeroSliderLinkingCollections = {
  __typename?: 'HeroSliderLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type HeroSliderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum HeroSliderOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Holiday = {
  __typename?: 'Holiday'
  category?: Maybe<Scalars['String']>
  /** date is in YYYY-MM-DD */
  date: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type IdentityInfo = {
  __typename?: 'IdentityInfo'
  barcodeNumber?: Maybe<Scalars['String']>
  createdAt: Scalars['Time']
  expirationDate?: Maybe<Scalars['Time']>
  id: Scalars['ID']
  idNumber?: Maybe<Scalars['String']>
  idType?: Maybe<IdentityType>
  issuanceDate?: Maybe<Scalars['Time']>
  issuanceLocation?: Maybe<Scalars['String']>
  issuanceType?: Maybe<IdentityIssuanceType>
  nationality?: Maybe<Scalars['String']>
  updatedAt: Scalars['Time']
  verifyStatus?: Maybe<IdentityVerifyStatus>
}

export type IdentityInfoInput = {
  addressPhotoResourceID?: InputMaybe<Scalars['ID']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  expirationDate?: InputMaybe<Scalars['Time']>
  idBackPhotoResourceID?: InputMaybe<Scalars['ID']>
  idFrontPhotoResourceID?: InputMaybe<Scalars['ID']>
  idNumber?: InputMaybe<Scalars['String']>
  idType?: InputMaybe<IdentityType>
  issuanceDate?: InputMaybe<Scalars['Time']>
  issuanceLocation?: InputMaybe<Scalars['String']>
  issuanceType?: InputMaybe<IdentityIssuanceType>
  nationality?: InputMaybe<Scalars['String']>
  secondIDPhotoResourceID?: InputMaybe<Scalars['ID']>
  selfiePhotoResourceID?: InputMaybe<Scalars['ID']>
}

export enum IdentityIssuanceType {
  IdentityIssuanceTypeInitial = 'IdentityIssuanceTypeInitial',
  IdentityIssuanceTypeReissue = 'IdentityIssuanceTypeReissue',
  IdentityIssuanceTypeReplacement = 'IdentityIssuanceTypeReplacement',
}

export enum IdentityType {
  IdentityTypeArc = 'IdentityTypeARC',
  IdentityTypeIdCard = 'IdentityTypeIDCard',
}

export enum IdentityVerifyStatus {
  IdentityVerifyStatusFailed = 'IdentityVerifyStatusFailed',
  IdentityVerifyStatusSuccess = 'IdentityVerifyStatusSuccess',
  IdentityVerifyStatusUnverified = 'IdentityVerifyStatusUnverified',
  IdentityVerifyStatusZ21Disable = 'IdentityVerifyStatusZ21Disable',
  IdentityVerifyStatusZ21Missing = 'IdentityVerifyStatusZ21Missing',
  IdentityVerifyStatusZ21Suspend = 'IdentityVerifyStatusZ21Suspend',
  IdentityVerifyStatusZ21SystemUnavailable = 'IdentityVerifyStatusZ21SystemUnavailable',
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>
}

export enum Industry {
  IndustryCategoryA = 'IndustryCategoryA',
  IndustryCategoryAa = 'IndustryCategoryAA',
  IndustryCategoryAb = 'IndustryCategoryAB',
  IndustryCategoryAc = 'IndustryCategoryAC',
  IndustryCategoryAd = 'IndustryCategoryAD',
  IndustryCategoryAe = 'IndustryCategoryAE',
  IndustryCategoryAf = 'IndustryCategoryAF',
  IndustryCategoryAg = 'IndustryCategoryAG',
  IndustryCategoryB = 'IndustryCategoryB',
  IndustryCategoryC = 'IndustryCategoryC',
  IndustryCategoryD = 'IndustryCategoryD',
  IndustryCategoryE = 'IndustryCategoryE',
  IndustryCategoryF = 'IndustryCategoryF',
  IndustryCategoryG = 'IndustryCategoryG',
  IndustryCategoryH = 'IndustryCategoryH',
  IndustryCategoryI = 'IndustryCategoryI',
  IndustryCategoryJ = 'IndustryCategoryJ',
  IndustryCategoryK = 'IndustryCategoryK',
  IndustryCategoryL = 'IndustryCategoryL',
  IndustryCategoryM = 'IndustryCategoryM',
  IndustryCategoryN = 'IndustryCategoryN',
  IndustryCategoryO = 'IndustryCategoryO',
  IndustryCategoryP = 'IndustryCategoryP',
  IndustryCategoryQ = 'IndustryCategoryQ',
  IndustryCategoryR = 'IndustryCategoryR',
  IndustryCategoryS = 'IndustryCategoryS',
  IndustryCategoryT = 'IndustryCategoryT',
  IndustryCategoryU = 'IndustryCategoryU',
  IndustryCategoryV = 'IndustryCategoryV',
  IndustryCategoryW = 'IndustryCategoryW',
  IndustryCategoryX = 'IndustryCategoryX',
  IndustryCategoryY = 'IndustryCategoryY',
  IndustryCategoryZ = 'IndustryCategoryZ',
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export type InternalTransferIn = BaseDeposit & {
  __typename?: 'InternalTransferIn'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fromUserID: Scalars['ID']
  id: Scalars['ID']
  status: DepositStatus
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type InternalTransferOut = BaseWithdrawal & {
  __typename?: 'InternalTransferOut'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fee: Scalars['Number']
  feeCurrency: Currency
  id: Scalars['ID']
  status: WithdrawalStatus
  toAddress: Scalars['String']
  toUserID: Scalars['ID']
  type: WithdrawalType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type Kyc = {
  __typename?: 'KYC'
  /** deprecated in the future */
  aml?: Maybe<AmlVerification>
  lv1?: Maybe<KycLevelOne>
  lv2?: Maybe<KycLevelTwo>
}

export type KycConfirmSubmissionInput = {
  level: KycLevel
}

export type KycConfirmSubmissionResponse = BaseResponse & {
  __typename?: 'KYCConfirmSubmissionResponse'
  result: Scalars['Boolean']
}

export enum KycLevel {
  KycLevelOne = 'KYCLevelOne',
  KycLevelTwo = 'KYCLevelTwo',
}

export type KycLevelOne = {
  __typename?: 'KYCLevelOne'
  addressPhoto?: Maybe<KycPhoto>
  basicInfo?: Maybe<BasicInfo>
  createdAt: Scalars['Time']
  id: Scalars['ID']
  idBackPhoto?: Maybe<KycPhoto>
  idFrontPhoto?: Maybe<KycPhoto>
  identityInfo?: Maybe<IdentityInfo>
  isReSubmitted: Scalars['Boolean']
  /** deprecated in the future */
  questions?: Maybe<QuestionInfo>
  secondIDPhoto?: Maybe<KycPhoto>
  selfiePhoto?: Maybe<KycPhoto>
  status: KycStatus
  updatedAt: Scalars['Time']
  verifier?: Maybe<User>
}

export type KycLevelTwo = {
  __typename?: 'KYCLevelTwo'
  bankInfo?: Maybe<BankInfo>
  bankPhoto?: Maybe<KycPhoto>
  createdAt: Scalars['Time']
  id: Scalars['ID']
  isReSubmitted: Scalars['Boolean']
  status: KycStatus
  updatedAt: Scalars['Time']
  verifier?: Maybe<User>
}

export type KycMutation = {
  __typename?: 'KYCMutation'
  kycConfirmSubmission: KycConfirmSubmissionResponse
  kycSubmitLevelOne: KycSubmitLevelOneResponse
  kycSubmitLevelTwo: KycSubmitLevelTwoResponse
}

export type KycMutationKycConfirmSubmissionArgs = {
  input: KycConfirmSubmissionInput
}

export type KycMutationKycSubmitLevelOneArgs = {
  input: KycSubmitLevelOneInput
}

export type KycMutationKycSubmitLevelTwoArgs = {
  input: KycSubmitLevelTwoInput
}

export type KycPhoto = {
  __typename?: 'KYCPhoto'
  createdAt: Scalars['Time']
  firstVerification?: Maybe<KycPhotoVerification>
  id: Scalars['ID']
  rejectedReason?: Maybe<Scalars['String']>
  resourceURL?: Maybe<Scalars['String']>
  secondVerification?: Maybe<KycPhotoVerification>
  status: KycPhotoStatus
  updatedAt: Scalars['Time']
}

export enum KycPhotoStatus {
  Rejected = 'Rejected',
  Verified = 'Verified',
  Verifying = 'Verifying',
}

export type KycPhotoVerification = {
  __typename?: 'KYCPhotoVerification'
  createdAt: Scalars['Time']
  id: Scalars['ID']
  isVerified?: Maybe<Scalars['Boolean']>
  rejectedReason?: Maybe<Scalars['String']>
  updatedAt: Scalars['Time']
  verifier: User
}

export enum KycStatus {
  KycStatusAmlVerifying = 'KYCStatusAMLVerifying',
  KycStatusFirstVerifying = 'KYCStatusFirstVerifying',
  KycStatusKgiVerifying = 'KYCStatusKGIVerifying',
  KycStatusPreparing = 'KYCStatusPreparing',
  KycStatusRejected = 'KYCStatusRejected',
  KycStatusSecondVerifying = 'KYCStatusSecondVerifying',
  KycStatusVerified = 'KYCStatusVerified',
}

export type KycSubmitLevelOneInput = {
  basicInfo?: InputMaybe<BasicInfoInput>
  identityInfo?: InputMaybe<IdentityInfoInput>
  questionnaire?: InputMaybe<QuestionnaireInput>
}

export type KycSubmitLevelOneResponse = BaseResponse & {
  __typename?: 'KYCSubmitLevelOneResponse'
  result: Scalars['Boolean']
}

export type KycSubmitLevelTwoInput = {
  bankInfo?: InputMaybe<BankInfoInput>
}

export type KycSubmitLevelTwoResponse = BaseResponse & {
  __typename?: 'KYCSubmitLevelTwoResponse'
  result: Scalars['Boolean']
}

export type KgiUploadResponse = {
  __typename?: 'KgiUploadResponse'
  Code: Scalars['String']
  Desc: Scalars['String']
  Filename: Scalars['String']
  Status: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessage = Entry & {
  __typename?: 'LandingPageCustomMessage'
  contentfulMetadata: ContentfulMetadata
  customMessage?: Maybe<LandingPageCustomMessageCustomMessage>
  displayEnvironment?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['DateTime']>
  linkedFrom?: Maybe<LandingPageCustomMessageLinkingCollections>
  startTime?: Maybe<Scalars['DateTime']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageCustomMessageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageDisplayEnvironmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageEndTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageStartTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type LandingPageCustomMessageCollection = {
  __typename?: 'LandingPageCustomMessageCollection'
  items: Array<Maybe<LandingPageCustomMessage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type LandingPageCustomMessageCustomMessage = {
  __typename?: 'LandingPageCustomMessageCustomMessage'
  json: Scalars['JSON']
  links: LandingPageCustomMessageCustomMessageLinks
}

export type LandingPageCustomMessageCustomMessageAssets = {
  __typename?: 'LandingPageCustomMessageCustomMessageAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type LandingPageCustomMessageCustomMessageEntries = {
  __typename?: 'LandingPageCustomMessageCustomMessageEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type LandingPageCustomMessageCustomMessageLinks = {
  __typename?: 'LandingPageCustomMessageCustomMessageLinks'
  assets: LandingPageCustomMessageCustomMessageAssets
  entries: LandingPageCustomMessageCustomMessageEntries
  resources: LandingPageCustomMessageCustomMessageResources
}

export type LandingPageCustomMessageCustomMessageResources = {
  __typename?: 'LandingPageCustomMessageCustomMessageResources'
  block: Array<ResourceLink>
}

export type LandingPageCustomMessageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LandingPageCustomMessageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<LandingPageCustomMessageFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customMessage_contains?: InputMaybe<Scalars['String']>
  customMessage_exists?: InputMaybe<Scalars['Boolean']>
  customMessage_not_contains?: InputMaybe<Scalars['String']>
  displayEnvironment?: InputMaybe<Scalars['String']>
  displayEnvironment_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_exists?: InputMaybe<Scalars['Boolean']>
  displayEnvironment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayEnvironment_not?: InputMaybe<Scalars['String']>
  displayEnvironment_not_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  endTime?: InputMaybe<Scalars['DateTime']>
  endTime_exists?: InputMaybe<Scalars['Boolean']>
  endTime_gt?: InputMaybe<Scalars['DateTime']>
  endTime_gte?: InputMaybe<Scalars['DateTime']>
  endTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  endTime_lt?: InputMaybe<Scalars['DateTime']>
  endTime_lte?: InputMaybe<Scalars['DateTime']>
  endTime_not?: InputMaybe<Scalars['DateTime']>
  endTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_exists?: InputMaybe<Scalars['Boolean']>
  startTime_gt?: InputMaybe<Scalars['DateTime']>
  startTime_gte?: InputMaybe<Scalars['DateTime']>
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime_lt?: InputMaybe<Scalars['DateTime']>
  startTime_lte?: InputMaybe<Scalars['DateTime']>
  startTime_not?: InputMaybe<Scalars['DateTime']>
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
}

export type LandingPageCustomMessageLinkingCollections = {
  __typename?: 'LandingPageCustomMessageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type LandingPageCustomMessageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum LandingPageCustomMessageOrder {
  DisplayEnvironmentAsc = 'displayEnvironment_ASC',
  DisplayEnvironmentDesc = 'displayEnvironment_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Ledger = {
  __typename?: 'Ledger'
  amount: Scalars['Number']
  balance: Scalars['Number']
  createdAt: Scalars['Time']
  currency: Currency
  id: Scalars['ID']
  ledgerEntry: LedgerEntry
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type LedgerEntry =
  | CryptoDeposit
  | CryptoWithdrawal
  | FiatDeposit
  | FiatWithdrawal
  | InternalTransferIn
  | InternalTransferOut
  | Trade

export type LedgerFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  ledgerID?: InputMaybe<Scalars['ID']>
  startDate?: InputMaybe<Scalars['Time']>
  type?: InputMaybe<Array<WalletOperationType>>
}

export type LedgerResponse = BaseResponse & {
  __typename?: 'LedgerResponse'
  data?: Maybe<Array<Ledger>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type LoginInfo = {
  __typename?: 'LoginInfo'
  accessToken: Scalars['String']
  expiresIn: Scalars['Int']
  refreshToken?: Maybe<Scalars['String']>
}

export type LoginResponse = LoginInfo | Response2Fa

export enum Market {
  Btctwd = 'BTCTWD',
  Ethtwd = 'ETHTWD',
  Usdttwd = 'USDTTWD',
}

export type MarketInfo = {
  __typename?: 'MarketInfo'
  feeRate: Scalars['Number']
  isTradable: Scalars['Boolean']
  market: Market
  maxSize: Scalars['Number']
  minSize: Scalars['Number']
  priceIncrement: Scalars['Number']
  sizeIncrement: Scalars['Number']
}

export type MarketPrice = {
  __typename?: 'MarketPrice'
  ask: Scalars['Number']
  bid: Scalars['Number']
  market: Market
  time: Scalars['Time']
  type: PriceType
}

export type Me = {
  __typename?: 'Me'
  id: Scalars['ID']
  information: User
}

export type MetaPagination = {
  __typename?: 'MetaPagination'
  total: Scalars['Int']
}

export type Metric = {
  __typename?: 'Metric'
  createdAt: Scalars['String']
  id: Scalars['String']
  positionManagersInfo?: Maybe<Array<PositionManagerInfo>>
  positions?: Maybe<Array<MetricPosition>>
}

export type MetricPosition = {
  __typename?: 'MetricPosition'
  amountUsd: Scalars['String']
  chain: EvmChain
  createdAt: Scalars['String']
  feeUsd: Scalars['String']
  id: Scalars['String']
  positionId: Scalars['Int']
  positionIdTotalCount: Scalars['Int']
  positionIsRunning: Scalars['Boolean']
  positionManagerContractAddress: Scalars['String']
  positionOwnerAddress: Scalars['String']
  strategyId: Scalars['String']
  strategyProvider: Scalars['String']
  sumUsd: Scalars['String']
}

export enum MobileOs {
  MobileOsAndroid = 'MobileOSAndroid',
  MobileOsIos = 'MobileOSIos',
  MobileOsWindowsPhone = 'MobileOSWindowsPhone',
}

export type Mutation = {
  __typename?: 'Mutation'
  closeUniswapv3Position?: Maybe<Scalars['String']>
  createAICompletionRecord: AICompletionRecords
  /** Reyield Backend will monitor this position with the txHash (either pending or confirmed) */
  createUniswapv3Position?: Maybe<Scalars['String']>
  createUniswapv3Tool?: Maybe<Scalars['String']>
  externalAPIKeyCreate: ExternalApiKey
  externalAPIKeyDelete: ExternalApiKey
  generateMetrics: Scalars['Boolean']
  strategyStart: StrategyStartResponse
  strategyStop: StrategyStopResponse
  strategyUpdate: StrategyUpdateResponse
}

export type MutationCloseUniswapv3PositionArgs = {
  input: CloseUniswapv3PositionInput
}

export type MutationCreateAiCompletionRecordArgs = {
  input: CreateAiCompletionRecordInput
}

export type MutationCreateUniswapv3PositionArgs = {
  input: CreateUniswapv3PositionInput
}

export type MutationCreateUniswapv3ToolArgs = {
  input: CreateUniswapv3ToolInput
}

export type MutationExternalApiKeyCreateArgs = {
  input: ExternalApiKeyCreateInput
}

export type MutationExternalApiKeyDeleteArgs = {
  input: ExternalApiKeyDeleteInput
}

export type MutationStrategyStartArgs = {
  input: StrategyStartInput
}

export type MutationStrategyStopArgs = {
  input: StrategyStopInput
}

export type MutationStrategyUpdateArgs = {
  input: StrategyUpdateInput
}

export type NftCollections = {
  __typename?: 'NFTCollections'
  attributes?: Maybe<Array<Maybe<Attributes>>>
  contract: Scalars['String']
  description: Scalars['String']
  imageUrl: Scalars['String']
  name: Scalars['String']
  tokenId: Scalars['String']
}

export type NftMerkleTreeHexProof = {
  __typename?: 'NFTMerkleTreeHexProof'
  hexProof?: Maybe<Array<Scalars['String']>>
}

export type NftWhitelistInfoByPhrase = {
  __typename?: 'NFTWhitelistInfoByPhrase'
  id: Scalars['String']
  isWhitelisted: Scalars['Boolean']
  phrase: Nft_Round
  userAddress: Scalars['String']
}

export enum Nft_Round {
  Airdrop = 'AIRDROP',
  AllowWhitelistMint = 'ALLOW_WHITELIST_MINT',
  GuaranteeWhitelistMint = 'GUARANTEE_WHITELIST_MINT',
  SoldOut = 'SOLD_OUT',
  WhitelistCheck = 'WHITELIST_CHECK',
}

export enum NsStatus {
  NsStatusFake = 'NSStatusFake',
  NsStatusNotFound = 'NSStatusNotFound',
  NsStatusPending = 'NSStatusPending',
  NsStatusReal = 'NSStatusReal',
  NsStatusUnverified = 'NSStatusUnverified',
}

export type Notification = {
  __typename?: 'Notification'
  notificationCount: NotificationCountResponse
  notificationList: NotificationListResponse
}

export type NotificationNotificationCountArgs = {
  filter?: InputMaybe<NotificationCountFilter>
}

export type NotificationNotificationListArgs = {
  filter?: InputMaybe<NotificationListFilter>
  pagination?: InputMaybe<PaginationQuery>
}

export type NotificationCount = {
  __typename?: 'NotificationCount'
  num: Scalars['Int']
}

export type NotificationCountFilter = {
  isRead?: InputMaybe<Scalars['Boolean']>
  productName?: InputMaybe<ProductName>
}

export type NotificationCountResponse = BaseResponse & {
  __typename?: 'NotificationCountResponse'
  data: NotificationCount
  result: Scalars['Boolean']
}

export type NotificationInfo = {
  __typename?: 'NotificationInfo'
  id: Scalars['ID']
  isRead: Scalars['Boolean']
  link?: Maybe<Scalars['String']>
  message: Scalars['String']
  productName: ProductName
  timestamp: Scalars['Time']
  title: Scalars['String']
}

export type NotificationListFilter = {
  productName?: InputMaybe<ProductName>
}

export type NotificationListResponse = BaseResponse & {
  __typename?: 'NotificationListResponse'
  data: Array<NotificationInfo>
  result: Scalars['Boolean']
}

export type NotificationMutation = {
  __typename?: 'NotificationMutation'
  notificationRead: NotificationReadResponse
}

export type NotificationMutationNotificationReadArgs = {
  input?: InputMaybe<NotificationReadInput>
}

export enum NotificationProvider {
  /** NotificationProviderAPNs: Apple Push Notification Service */
  NotificationProviderApNs = 'NotificationProviderAPNs',
  /** NotificationProviderFCM: Firebase Cloud Message */
  NotificationProviderFcm = 'NotificationProviderFCM',
  /** NotificationProviderSNS:  Amazon Simple Notification Service */
  NotificationProviderSns = 'NotificationProviderSNS',
}

export type NotificationReadFilter = {
  notificationIDs?: InputMaybe<Array<Scalars['ID']>>
  productName?: InputMaybe<ProductName>
}

export type NotificationReadInput = {
  filter?: InputMaybe<NotificationReadFilter>
}

export type NotificationReadResponse = BaseResponse & {
  __typename?: 'NotificationReadResponse'
  result: Scalars['Boolean']
}

export type OnHoldKgiVerification = {
  __typename?: 'OnHoldKGIVerification'
  filename: Scalars['String']
  id: Scalars['ID']
  status: Scalars['String']
  txID: Scalars['String']
  userID: Scalars['ID']
}

export enum OrderSide {
  OrderSideBuy = 'OrderSideBuy',
  OrderSideSell = 'OrderSideSell',
  OrderSideUnknown = 'OrderSideUnknown',
}

export type OutOfRangeInfoInput = {
  earnSpendRate: Scalars['Int']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type PaginationInput = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type PaginationQuery = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export enum PaymentMethod {
  PaymentMethodBitcoin = 'PaymentMethodBitcoin',
  PaymentMethodErc20 = 'PaymentMethodERC20',
  PaymentMethodEthereum = 'PaymentMethodEthereum',
  PaymentMethodSwift = 'PaymentMethodSWIFT',
  PaymentMethodTrc20 = 'PaymentMethodTRC20',
}

export type PhotoVerificationResultInput = {
  rejectedReason?: InputMaybe<Scalars['String']>
  verified: Scalars['Boolean']
}

export type Point = {
  __typename?: 'Point'
  ledgers: PointLedgerRecordResponse
  quantity: Scalars['Int']
}

export type PointLedgersArgs = {
  filter?: InputMaybe<PointLedgerFilter>
  pagination: PaginationQuery
}

export type PointLedgerFilter = {
  productID?: InputMaybe<Scalars['ID']>
}

export type PointLedgerRecord = {
  __typename?: 'PointLedgerRecord'
  description: Scalars['String']
  id: Scalars['ID']
  points: Scalars['Int']
  /** 產品ID, 例如策略, 就帶入StrategyID */
  productID?: Maybe<Scalars['ID']>
  time: Scalars['Time']
  total: Scalars['Int']
  type: PointType
}

export type PointLedgerRecordResponse = {
  __typename?: 'PointLedgerRecordResponse'
  data?: Maybe<Array<Maybe<PointLedgerRecord>>>
  result: Scalars['Boolean']
}

export type PointMutation = {
  __typename?: 'PointMutation'
  /** pointPurchase: 購買點數 */
  pointPurchase: PointPurchaseResponse
}

export type PointMutationPointPurchaseArgs = {
  input: PointPurchaseInput
}

export type PointPurchaseInput = {
  /** number 購買的數量 */
  number: Scalars['Int']
}

export type PointPurchaseResponse = BaseResponse & {
  __typename?: 'PointPurchaseResponse'
  /** number 實際購買的數量 */
  number: Scalars['Int']
  /** price 實際每點花費的 USDT */
  price: Scalars['Number']
  result: Scalars['Boolean']
  /** total 實際總花費 */
  total: Scalars['Number']
}

export enum PointType {
  PointTypeCreate = 'PointTypeCreate',
  PointTypeDeductStrategy = 'PointTypeDeductStrategy',
  PointTypeFillCash = 'PointTypeFillCash',
  PointTypeFillPurchase = 'PointTypeFillPurchase',
  PointTypeFillReferral = 'PointTypeFillReferral',
  PointTypeFillReferrer = 'PointTypeFillReferrer',
  PointTypeFillTrade = 'PointTypeFillTrade',
  PointTypeFillVoucher = 'PointTypeFillVoucher',
  PointTypeRepayStrategyDebt = 'PointTypeRepayStrategyDebt',
}

export enum PoolsWithArgumentsFlag {
  /** This will use default value to filter */
  Default = 'Default',
  /** This will include selected Tokens (Ex: BTC, ETH ...) */
  Generic = 'Generic',
  /** This will include stable tokens (Ex: USDT, USDC ...) */
  Stables = 'Stables',
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUp = Entry & {
  __typename?: 'PopUp'
  backgroundColor?: Maybe<Scalars['String']>
  callToActionButtonLink?: Maybe<Scalars['String']>
  callToActionButtonText?: Maybe<Scalars['String']>
  callToActionButtonVariant?: Maybe<Scalars['String']>
  closeButtonText?: Maybe<Scalars['String']>
  closeButtonVariant?: Maybe<Scalars['String']>
  contentArea?: Maybe<PopUpContentArea>
  contentfulMetadata: ContentfulMetadata
  displayEnvironment?: Maybe<Scalars['String']>
  displayMode?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['DateTime']>
  imageDesktop?: Maybe<Asset>
  imageModile?: Maybe<Asset>
  linkedFrom?: Maybe<PopUpLinkingCollections>
  priority?: Maybe<Scalars['Int']>
  startTime?: Maybe<Scalars['DateTime']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCallToActionButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCallToActionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCallToActionButtonVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCloseButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCloseButtonVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpContentAreaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpDisplayEnvironmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpDisplayModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpEndTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpImageDesktopArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpImageModileArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpPriorityArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpStartTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PopUpCollection = {
  __typename?: 'PopUpCollection'
  items: Array<Maybe<PopUp>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PopUpContentArea = {
  __typename?: 'PopUpContentArea'
  json: Scalars['JSON']
  links: PopUpContentAreaLinks
}

export type PopUpContentAreaAssets = {
  __typename?: 'PopUpContentAreaAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PopUpContentAreaEntries = {
  __typename?: 'PopUpContentAreaEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PopUpContentAreaLinks = {
  __typename?: 'PopUpContentAreaLinks'
  assets: PopUpContentAreaAssets
  entries: PopUpContentAreaEntries
  resources: PopUpContentAreaResources
}

export type PopUpContentAreaResources = {
  __typename?: 'PopUpContentAreaResources'
  block: Array<ResourceLink>
}

export type PopUpFilter = {
  AND?: InputMaybe<Array<InputMaybe<PopUpFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PopUpFilter>>>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonLink?: InputMaybe<Scalars['String']>
  callToActionButtonLink_contains?: InputMaybe<Scalars['String']>
  callToActionButtonLink_exists?: InputMaybe<Scalars['Boolean']>
  callToActionButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonLink_not?: InputMaybe<Scalars['String']>
  callToActionButtonLink_not_contains?: InputMaybe<Scalars['String']>
  callToActionButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonText?: InputMaybe<Scalars['String']>
  callToActionButtonText_contains?: InputMaybe<Scalars['String']>
  callToActionButtonText_exists?: InputMaybe<Scalars['Boolean']>
  callToActionButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonText_not?: InputMaybe<Scalars['String']>
  callToActionButtonText_not_contains?: InputMaybe<Scalars['String']>
  callToActionButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonVariant?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_contains?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_exists?: InputMaybe<Scalars['Boolean']>
  callToActionButtonVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonVariant_not?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_not_contains?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonText?: InputMaybe<Scalars['String']>
  closeButtonText_contains?: InputMaybe<Scalars['String']>
  closeButtonText_exists?: InputMaybe<Scalars['Boolean']>
  closeButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonText_not?: InputMaybe<Scalars['String']>
  closeButtonText_not_contains?: InputMaybe<Scalars['String']>
  closeButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonVariant?: InputMaybe<Scalars['String']>
  closeButtonVariant_contains?: InputMaybe<Scalars['String']>
  closeButtonVariant_exists?: InputMaybe<Scalars['Boolean']>
  closeButtonVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonVariant_not?: InputMaybe<Scalars['String']>
  closeButtonVariant_not_contains?: InputMaybe<Scalars['String']>
  closeButtonVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentArea_contains?: InputMaybe<Scalars['String']>
  contentArea_exists?: InputMaybe<Scalars['Boolean']>
  contentArea_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayEnvironment?: InputMaybe<Scalars['String']>
  displayEnvironment_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_exists?: InputMaybe<Scalars['Boolean']>
  displayEnvironment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayEnvironment_not?: InputMaybe<Scalars['String']>
  displayEnvironment_not_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode?: InputMaybe<Scalars['String']>
  displayMode_contains?: InputMaybe<Scalars['String']>
  displayMode_exists?: InputMaybe<Scalars['Boolean']>
  displayMode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode_not?: InputMaybe<Scalars['String']>
  displayMode_not_contains?: InputMaybe<Scalars['String']>
  displayMode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  endTime?: InputMaybe<Scalars['DateTime']>
  endTime_exists?: InputMaybe<Scalars['Boolean']>
  endTime_gt?: InputMaybe<Scalars['DateTime']>
  endTime_gte?: InputMaybe<Scalars['DateTime']>
  endTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  endTime_lt?: InputMaybe<Scalars['DateTime']>
  endTime_lte?: InputMaybe<Scalars['DateTime']>
  endTime_not?: InputMaybe<Scalars['DateTime']>
  endTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  imageDesktop_exists?: InputMaybe<Scalars['Boolean']>
  imageModile_exists?: InputMaybe<Scalars['Boolean']>
  priority?: InputMaybe<Scalars['Int']>
  priority_exists?: InputMaybe<Scalars['Boolean']>
  priority_gt?: InputMaybe<Scalars['Int']>
  priority_gte?: InputMaybe<Scalars['Int']>
  priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  priority_lt?: InputMaybe<Scalars['Int']>
  priority_lte?: InputMaybe<Scalars['Int']>
  priority_not?: InputMaybe<Scalars['Int']>
  priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_exists?: InputMaybe<Scalars['Boolean']>
  startTime_gt?: InputMaybe<Scalars['DateTime']>
  startTime_gte?: InputMaybe<Scalars['DateTime']>
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime_lt?: InputMaybe<Scalars['DateTime']>
  startTime_lte?: InputMaybe<Scalars['DateTime']>
  startTime_not?: InputMaybe<Scalars['DateTime']>
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PopUpLinkingCollections = {
  __typename?: 'PopUpLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PopUpLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PopUpOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  CallToActionButtonLinkAsc = 'callToActionButtonLink_ASC',
  CallToActionButtonLinkDesc = 'callToActionButtonLink_DESC',
  CallToActionButtonTextAsc = 'callToActionButtonText_ASC',
  CallToActionButtonTextDesc = 'callToActionButtonText_DESC',
  CallToActionButtonVariantAsc = 'callToActionButtonVariant_ASC',
  CallToActionButtonVariantDesc = 'callToActionButtonVariant_DESC',
  CloseButtonTextAsc = 'closeButtonText_ASC',
  CloseButtonTextDesc = 'closeButtonText_DESC',
  CloseButtonVariantAsc = 'closeButtonVariant_ASC',
  CloseButtonVariantDesc = 'closeButtonVariant_DESC',
  DisplayEnvironmentAsc = 'displayEnvironment_ASC',
  DisplayEnvironmentDesc = 'displayEnvironment_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PositionManagerInfo = {
  __typename?: 'PositionManagerInfo'
  chain: EvmChain
  count: Scalars['Int']
  id: Scalars['String']
}

export enum PriceType {
  PriceTypeDiscounted = 'PriceTypeDiscounted',
  PriceTypeNormal = 'PriceTypeNormal',
}

export enum ProductName {
  Bgsp = 'BGSP',
  Bgx = 'BGX',
}

export enum PromptVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export type Public = {
  __typename?: 'Public'
  banks: Array<Maybe<Bank>>
  chart: ChartResponse
  countries: Array<Maybe<Country>>
  cryptoAsset?: Maybe<CryptoAsset>
  keyOwnerID: Scalars['ID']
  marketInfo: Array<MarketInfo>
  marketPrice: Array<Maybe<MarketPrice>>
  /** empty questionnaire information */
  questionnaire: Questionnaire
  version: VersionInfo
}

export type PublicBanksArgs = {
  bankCode?: InputMaybe<Scalars['String']>
}

export type PublicChartArgs = {
  input: ChartInput
}

export type PublicKeyOwnerIdArgs = {
  key: Scalars['String']
}

export type PublicMarketInfoArgs = {
  markets: Array<Market>
}

export type PublicMarketPriceArgs = {
  markets: Array<Market>
  types: Array<PriceType>
}

export type PublicQuestionnaireArgs = {
  purpose: QuestionnairePurpose
  version?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  coinPrices?: Maybe<Array<CoinPrice>>
  me: UserInfo
  metric?: Maybe<Array<Metric>>
  poolsWithArguments?: Maybe<Array<UniswapPoolInfo>>
  rateHistorical?: Maybe<Array<RateHistorical>>
  strategies: Array<StrategyInfo>
  tools: Array<ToolInfo>
  uniswapQuote?: Maybe<UniswapQuote>
  uniswapTokens?: Maybe<Array<UniswapToolToken>>
  uniswapV3Backtest?: Maybe<UniswapV3BacktestResult>
  version: FrontendVersion
  wallet?: Maybe<DefiWallet>
}

export type QueryPoolsWithArgumentsArgs = {
  chain: EvmChain
  first?: InputMaybe<Scalars['Int']>
  flag?: InputMaybe<PoolsWithArgumentsFlag>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Scalars['String']>
  poolIds?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Int']>
  token0s?: InputMaybe<Array<Scalars['String']>>
  token1s?: InputMaybe<Array<Scalars['String']>>
}

export type QueryRateHistoricalArgs = {
  filter: RateHistoricalFilterInput
}

export type QueryStrategiesArgs = {
  filter?: InputMaybe<StrategyFilterInput>
}

export type QueryUniswapQuoteArgs = {
  input: UniswapQuoteInput
  source?: InputMaybe<QuoteDataSource>
}

export type QueryUniswapTokensArgs = {
  chain: EvmChain
}

export type QueryUniswapV3BacktestArgs = {
  compound: Scalars['Boolean']
  days: Scalars['Int']
  diff: Scalars['Float']
  investmentAmount: Scalars['Float']
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
}

export type QueryWalletArgs = {
  input?: InputMaybe<DefiWalletFilterInput>
}

export type Question = {
  __typename?: 'Question'
  /** question description */
  description: Scalars['String']
  /** unique key for this question */
  id: Scalars['ID']
  /** question options */
  options: Array<QuestionOption>
  /** question type */
  type: QuestionType
}

export enum QuestionAnnualIncome {
  QuestionAnnualIncomeBetween1MAnd2M = 'QuestionAnnualIncomeBetween1MAnd2M',
  QuestionAnnualIncomeBetween500KAnd1M = 'QuestionAnnualIncomeBetween500KAnd1M',
  QuestionAnnualIncomeLessThan500k = 'QuestionAnnualIncomeLessThan500k',
  QuestionAnnualIncomeMoreThan2M = 'QuestionAnnualIncomeMoreThan2M',
}

export type QuestionInfo = {
  __typename?: 'QuestionInfo'
  annualIncome: QuestionAnnualIncome
  anotherNationality?: Maybe<Scalars['String']>
  createdAt: Scalars['Time']
  id: Scalars['ID']
  period: QuestionPeriod
  purpose: QuestionPurpose
  updatedAt: Scalars['Time']
}

export type QuestionInput = {
  /** option ids from QuestionDetail */
  answers: Array<QuestionResultInput>
  /** question id from Questionnaire */
  questionID: Scalars['ID']
}

export type QuestionOption = {
  __typename?: 'QuestionOption'
  /** description of this option */
  description: Scalars['String']
  /** if true, remark is required, if false, remark should be empty string */
  hasRemark: Scalars['Boolean']
  /** key of this option */
  id: Scalars['ID']
  /** selected status of this option */
  isSelected: Scalars['Boolean']
  /** string value of this option */
  remark: Scalars['String']
}

export enum QuestionPeriod {
  QuestionPeriodBetweenOneAndThreeYears = 'QuestionPeriodBetweenOneAndThreeYears',
  QuestionPeriodFirstTime = 'QuestionPeriodFirstTime',
  QuestionPeriodLessThanOneYear = 'QuestionPeriodLessThanOneYear',
  QuestionPeriodMoreThanThreeYears = 'QuestionPeriodMoreThanThreeYears',
}

export enum QuestionPurpose {
  QuestionPurposeInvestment = 'QuestionPurposeInvestment',
  QuestionPurposePayment = 'QuestionPurposePayment',
}

export type QuestionResultInput = {
  /** uuid from option */
  optionID: Scalars['ID']
  /** remark string */
  remark?: InputMaybe<Scalars['String']>
}

export enum QuestionType {
  QuestionTypeMultipleSelection = 'QuestionTypeMultipleSelection',
  QuestionTypeSingleSelection = 'QuestionTypeSingleSelection',
}

export type Questionnaire = {
  __typename?: 'Questionnaire'
  /** created time of this questionnaire */
  createdAt: Scalars['Time']
  /** unique key for this questionnaire */
  id: Scalars['ID']
  /** purpose of this questionnaire, e.g. KYC, AML, etc. */
  purpose: QuestionnairePurpose
  /** questions */
  questions: Array<Question>
  /** updated time of this questionnaire */
  updatedAt?: Maybe<Scalars['Time']>
  /** version of this questionnaire */
  version: Scalars['String']
}

export type QuestionnaireInput = {
  /** unique key for this questionnaire */
  id: Scalars['ID']
  /** questions inputs */
  questions: Array<QuestionInput>
}

export enum QuestionnairePurpose {
  QuestionnairePurposeKycaml = 'QuestionnairePurposeKYCAML',
}

export type Quotation = {
  __typename?: 'Quotation'
  baseAmount: Scalars['Number']
  createdAt: Scalars['Time']
  expiredAt: Scalars['Time']
  fee: Scalars['Number']
  feeCurrency: Currency
  feeRate: Scalars['Number']
  id: Scalars['ID']
  market: Market
  price: Scalars['Number']
  quoteAmount: Scalars['Number']
  side: OrderSide
}

export enum QuoteDataSource {
  Blockchain = 'blockchain',
  Thegraph = 'thegraph',
}

export enum RaStatus {
  RaStatusForbidden = 'RAStatusForbidden',
  RaStatusHighRisk = 'RAStatusHighRisk',
  RaStatusLowRisk = 'RAStatusLowRisk',
  RaStatusMediumRisk = 'RAStatusMediumRisk',
  /** deprecated */
  RaStatusNormalRisk = 'RAStatusNormalRisk',
  RaStatusUnverified = 'RAStatusUnverified',
}

export type RateHistorical = {
  __typename?: 'RateHistorical'
  rate: Scalars['String']
  time: Scalars['String']
}

export type RateHistoricalFilterInput = {
  period: Scalars['String']
  sid?: InputMaybe<Scalars['String']>
}

export enum RecordsType {
  Revenue = 'revenue',
  Unknown = 'unknown',
}

export type RejectFiatWithdrawalInput = {
  userID: Scalars['ID']
  withdrawalID: Scalars['ID']
}

export type RejectFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'RejectFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type Release = Entry & {
  __typename?: 'Release'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ReleaseLinkingCollections>
  notesCollection?: Maybe<ReleaseNotesCollection>
  releaseDate?: Maybe<Scalars['DateTime']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseNotesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ReleaseNotesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ReleaseNoteFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseReleaseDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ReleaseCollection = {
  __typename?: 'ReleaseCollection'
  items: Array<Maybe<Release>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ReleaseFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReleaseFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ReleaseFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  notes?: InputMaybe<CfReleaseNoteNestedFilter>
  notesCollection_exists?: InputMaybe<Scalars['Boolean']>
  releaseDate?: InputMaybe<Scalars['DateTime']>
  releaseDate_exists?: InputMaybe<Scalars['Boolean']>
  releaseDate_gt?: InputMaybe<Scalars['DateTime']>
  releaseDate_gte?: InputMaybe<Scalars['DateTime']>
  releaseDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  releaseDate_lt?: InputMaybe<Scalars['DateTime']>
  releaseDate_lte?: InputMaybe<Scalars['DateTime']>
  releaseDate_not?: InputMaybe<Scalars['DateTime']>
  releaseDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ReleaseLinkingCollections = {
  __typename?: 'ReleaseLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ReleaseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNote = Entry & {
  __typename?: 'ReleaseNote'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ReleaseNoteLinkingCollections>
  platform?: Maybe<Scalars['String']>
  releaseNote?: Maybe<ReleaseNoteReleaseNote>
  sys: Sys
  version?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNoteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNotePlatformArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNoteReleaseNoteArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNoteVersionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ReleaseNoteCollection = {
  __typename?: 'ReleaseNoteCollection'
  items: Array<Maybe<ReleaseNote>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ReleaseNoteFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReleaseNoteFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ReleaseNoteFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  platform?: InputMaybe<Scalars['String']>
  platform_contains?: InputMaybe<Scalars['String']>
  platform_exists?: InputMaybe<Scalars['Boolean']>
  platform_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  platform_not?: InputMaybe<Scalars['String']>
  platform_not_contains?: InputMaybe<Scalars['String']>
  platform_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  releaseNote_contains?: InputMaybe<Scalars['String']>
  releaseNote_exists?: InputMaybe<Scalars['Boolean']>
  releaseNote_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  version?: InputMaybe<Scalars['String']>
  version_contains?: InputMaybe<Scalars['String']>
  version_exists?: InputMaybe<Scalars['Boolean']>
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  version_not?: InputMaybe<Scalars['String']>
  version_not_contains?: InputMaybe<Scalars['String']>
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ReleaseNoteLinkingCollections = {
  __typename?: 'ReleaseNoteLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  releaseCollection?: Maybe<ReleaseCollection>
}

export type ReleaseNoteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ReleaseNoteLinkingCollectionsReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ReleaseNoteLinkingCollectionsReleaseCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ReleaseNoteLinkingCollectionsReleaseCollectionOrder {
  ReleaseDateAsc = 'releaseDate_ASC',
  ReleaseDateDesc = 'releaseDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ReleaseNoteOrder {
  PlatformAsc = 'platform_ASC',
  PlatformDesc = 'platform_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

export type ReleaseNoteReleaseNote = {
  __typename?: 'ReleaseNoteReleaseNote'
  json: Scalars['JSON']
  links: ReleaseNoteReleaseNoteLinks
}

export type ReleaseNoteReleaseNoteAssets = {
  __typename?: 'ReleaseNoteReleaseNoteAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ReleaseNoteReleaseNoteEntries = {
  __typename?: 'ReleaseNoteReleaseNoteEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ReleaseNoteReleaseNoteLinks = {
  __typename?: 'ReleaseNoteReleaseNoteLinks'
  assets: ReleaseNoteReleaseNoteAssets
  entries: ReleaseNoteReleaseNoteEntries
  resources: ReleaseNoteReleaseNoteResources
}

export type ReleaseNoteReleaseNoteResources = {
  __typename?: 'ReleaseNoteReleaseNoteResources'
  block: Array<ResourceLink>
}

export type ReleaseNotesCollection = {
  __typename?: 'ReleaseNotesCollection'
  items: Array<Maybe<ReleaseNote>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ReleaseNotesCollectionOrder {
  PlatformAsc = 'platform_ASC',
  PlatformDesc = 'platform_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

export enum ReleaseOrder {
  ReleaseDateAsc = 'releaseDate_ASC',
  ReleaseDateDesc = 'releaseDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ResourceConfirmUploadInput = {
  eTag: Scalars['String']
  objKey: Scalars['String']
}

export type ResourceConfirmUploadResponse = BaseResponse & {
  __typename?: 'ResourceConfirmUploadResponse'
  id: Scalars['ID']
  result: Scalars['Boolean']
}

export type ResourceLink = {
  __typename?: 'ResourceLink'
  sys: ResourceSys
}

export type ResourceMutation = {
  __typename?: 'ResourceMutation'
  resourceConfirmUpload: ResourceConfirmUploadResponse
  resourceRequestUploadURL: ResourceRequestUploadUrlResponse
}

export type ResourceMutationResourceConfirmUploadArgs = {
  input: ResourceConfirmUploadInput
}

export type ResourceMutationResourceRequestUploadUrlArgs = {
  input: ResourceRequestUploadUrlInput
}

export type ResourceRequestUploadUrlInput = {
  fileExtension: Scalars['String']
}

export type ResourceRequestUploadUrlResponse = BaseResponse & {
  __typename?: 'ResourceRequestUploadURLResponse'
  result: Scalars['Boolean']
  url: Scalars['String']
}

export type ResourceSys = {
  __typename?: 'ResourceSys'
  linkType: Scalars['String']
  type: Scalars['String']
  urn: Scalars['String']
}

export type Response2Fa = {
  __typename?: 'Response2FA'
  token2FA: Scalars['String']
}

export type ReyieldAttributes = {
  __typename?: 'ReyieldAttributes'
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ReyieldBalance = {
  __typename?: 'ReyieldBalance'
  available: Scalars['String']
  total: Scalars['String']
}

export type ReyieldCefiDepositAddress = {
  __typename?: 'ReyieldCefiDepositAddress'
  address: Scalars['String']
  chain: ReyieldChain
}

export type ReyieldCefiDepositAddressInput = {
  chain: ReyieldChain
  currency: Scalars['String']
  exchange: ReyieldExchange
  wallet?: InputMaybe<ReyieldWalletType>
}

export type ReyieldCefiWallet = {
  __typename?: 'ReyieldCefiWallet'
  exchange: Scalars['String']
  walletBalances: Array<ReyieldWalletBalances>
}

export type ReyieldCefiWalletFilterInput = {
  exchange: ReyieldExchange
  hideTinyUSDValue?: InputMaybe<Scalars['Int']>
}

export enum ReyieldChain {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Tron = 'Tron',
}

export type ReyieldCloseUniswapv3PositionInput = {
  positionId: Scalars['String']
  txHash: Scalars['String']
}

export type ReyieldCoinPrice = {
  __typename?: 'ReyieldCoinPrice'
  symbol: Scalars['String']
  usdtPrice: Scalars['String']
}

export type ReyieldCreateAiCompletionRecordInput = {
  expectedReturn: Scalars['String']
  goal: Scalars['String']
  investmentAmount: Scalars['String']
  investmentPeriod: Scalars['String']
  items: Array<ReyieldCreateAiCompletionRecordItemInput>
  promptVersion?: InputMaybe<ReyieldPromptVersion>
  riskToleranceLevel: ReyieldRiskToleranceLevelName
}

export type ReyieldCreateAiCompletionRecordItemInput = {
  desc: Scalars['String']
  highestAnnualReturn: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
}

export type ReyieldCreateUniswapv3PositionInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type ReyieldCreateUniswapv3ToolInput = {
  chain: ReyieldEvmChain
  description?: InputMaybe<Scalars['String']>
  feeTier: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  outOfLowerRange: ReyieldOutOfRangeInfoInput
  outOfUpperRange: ReyieldOutOfRangeInfoInput
  tickLowerDiff: Scalars['Float']
  tickUpperDiff: Scalars['Float']
  token0Address: Scalars['String']
  token1Address: Scalars['String']
}

export type ReyieldCreator = {
  __typename?: 'ReyieldCreator'
  address: Scalars['String']
  id: Scalars['String']
}

export type ReyieldCurrencyBalances = {
  __typename?: 'ReyieldCurrencyBalances'
  balance: ReyieldBalance
  currency: Scalars['String']
}

export type ReyieldCurrencyInfo = {
  __typename?: 'ReyieldCurrencyInfo'
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type ReyieldDefiTokensBalanceAmountInput = {
  chain: ReyieldEvmChain
  chainId: Scalars['Int']
  tokenAddresses: Array<Scalars['String']>
}

export type ReyieldDefiWallet = {
  __typename?: 'ReyieldDefiWallet'
  balance: Scalars['String']
  balanceComputed?: Maybe<Scalars['String']>
  balanceComputedWithUnit?: Maybe<Scalars['String']>
  chain: ReyieldEvmChain
  currencyInfo: ReyieldCurrencyInfo
  id: Scalars['String']
}

export type ReyieldDefiWalletFilterInput = {
  address: Scalars['String']
}

export type ReyieldDefiWalletsFilterInput = {
  chains?: InputMaybe<Array<ReyieldEvmChain>>
}

export enum ReyieldEvmChain {
  Arbitrum = 'Arbitrum',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  Optimism = 'Optimism',
  OptimismGoerli = 'OptimismGoerli',
  Polygon = 'Polygon',
  Unknown = 'Unknown',
}

export enum ReyieldExchange {
  Bitfinex = 'bitfinex',
  Tradingview = 'tradingview',
  Uniswap = 'uniswap',
  Unknown = 'unknown',
}

export type ReyieldExternalApiKey = {
  __typename?: 'ReyieldExternalAPIKey'
  createdAt?: Maybe<Scalars['String']>
  exchange: ReyieldExchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  user?: Maybe<ReyieldUserInfo>
  userId?: Maybe<Scalars['String']>
}

export type ReyieldExternalApiKeyCreateInput = {
  exchange: ReyieldExchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
}

export type ReyieldExternalApiKeyDeleteInput = {
  id: Scalars['String']
}

export type ReyieldExternalApiKeysFilter = {
  exchange?: InputMaybe<ReyieldExchange>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export type ReyieldFrontendVersion = {
  __typename?: 'ReyieldFrontendVersion'
  commitHash: Scalars['String']
  smartContracts: Array<ReyieldSmartContract>
  version: Scalars['String']
}

export type ReyieldGimCoolPayPaymentInfo = {
  __typename?: 'ReyieldGimCoolPayPaymentInfo'
  blockchain: ReyieldGimCoolPayPaymentSupportingBlockchain
  coinAmount?: Maybe<Scalars['String']>
  coinType: ReyieldGimCoolPayPaymentSupportingCoinType
  fiatAmount?: Maybe<Scalars['String']>
  fiatType: ReyieldGimCoolPayPaymentSupportingFiatType
  paymentHref: Scalars['String']
  price?: Maybe<ReyieldGimCoolPayPriceResponse>
  walletAddress: Scalars['String']
  walletAddressTag?: Maybe<Scalars['String']>
}

export type ReyieldGimCoolPayPaymentInfoInput = {
  blockchain: ReyieldGimCoolPayPaymentSupportingBlockchain
  coinAmount: Scalars['String']
  coinType: ReyieldGimCoolPayPaymentSupportingCoinType
  fiatType: ReyieldGimCoolPayPaymentSupportingFiatType
  isSandBox?: InputMaybe<Scalars['Boolean']>
}

export enum ReyieldGimCoolPayPaymentSupportingBlockchain {
  Eth = 'ETH',
  Unknown = 'Unknown',
}

export enum ReyieldGimCoolPayPaymentSupportingCoinType {
  Eth = 'ETH',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Unknown = 'Unknown',
}

export enum ReyieldGimCoolPayPaymentSupportingFiatType {
  Usd = 'USD',
  Unknown = 'Unknown',
}

export type ReyieldGimCoolPayPriceResponse = {
  __typename?: 'ReyieldGimCoolPayPriceResponse'
  prices?: Maybe<Array<ReyieldGimCoolPayReadyToPurchasePrice>>
  spotPrice: Scalars['String']
}

export type ReyieldGimCoolPayReadyToPurchasePrice = {
  __typename?: 'ReyieldGimCoolPayReadyToPurchasePrice'
  coinAmount: Scalars['String']
  coinCode: Scalars['String']
  feeAmount: Scalars['String']
  fiatAmount: Scalars['String']
  fiatCode: Scalars['String']
  networkFee: Scalars['String']
  paymentMethodId: Scalars['String']
  spotPriceFee: Scalars['String']
  spotPriceIncludingFee: Scalars['String']
  type: Scalars['String']
}

export type ReyieldLicense = {
  __typename?: 'ReyieldLicense'
  numberOfLicense: Scalars['Int']
  numberOfUsedLicense: Scalars['Int']
}

export type ReyieldNftCollections = {
  __typename?: 'ReyieldNFTCollections'
  attributes?: Maybe<Array<Maybe<ReyieldAttributes>>>
  contract: Scalars['String']
  description: Scalars['String']
  imageUrl: Scalars['String']
  name: Scalars['String']
  tokenId: Scalars['String']
}

export type ReyieldNftMerkleTreeHexProof = {
  __typename?: 'ReyieldNFTMerkleTreeHexProof'
  hexProof?: Maybe<Array<Scalars['String']>>
}

export type ReyieldNftWhitelistInfoByPhrase = {
  __typename?: 'ReyieldNFTWhitelistInfoByPhrase'
  id: Scalars['String']
  isWhitelisted: Scalars['Boolean']
  phrase: ReyieldNft_Round
  userAddress: Scalars['String']
}

export enum ReyieldNft_Round {
  Airdrop = 'AIRDROP',
  AllowWhitelistMint = 'ALLOW_WHITELIST_MINT',
  GuaranteeWhitelistMint = 'GUARANTEE_WHITELIST_MINT',
  SoldOut = 'SOLD_OUT',
  WhitelistCheck = 'WHITELIST_CHECK',
}

export type ReyieldOutOfRangeInfoInput = {
  earnSpendRate: Scalars['Int']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export enum ReyieldPoolsWithArgumentsFlag {
  /** This will use default value to filter */
  Default = 'Default',
  /** This will include selected Tokens (Ex: BTC, ETH ...) */
  Generic = 'Generic',
  /** This will include stable tokens (Ex: USDT, USDC ...) */
  Stables = 'Stables',
}

export enum ReyieldPromptVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export enum ReyieldQuoteDataSource {
  Blockchain = 'blockchain',
  Thegraph = 'thegraph',
}

export type ReyieldRateHistorical = {
  __typename?: 'ReyieldRateHistorical'
  rate: Scalars['String']
  time: Scalars['String']
}

export type ReyieldRateHistoricalFilterInput = {
  period: Scalars['String']
  sid?: InputMaybe<Scalars['String']>
}

export enum ReyieldRecordsType {
  Revenue = 'revenue',
  Unknown = 'unknown',
}

export type ReyieldReyieldLicense = {
  __typename?: 'ReyieldReyieldLicense'
  numberOfLicense: Scalars['Int']
  numberOfUsedLicense: Scalars['Int']
}

export enum ReyieldRiskLevel {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'medium_high',
  MediumLow = 'medium_low',
  Unknown = 'unknown',
}

export enum ReyieldRiskToleranceLevelName {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  ExtremelyAggressive = 'ExtremelyAggressive',
  ExtremelyConservative = 'ExtremelyConservative',
  Moderate = 'Moderate',
  VeryConservative = 'VeryConservative',
}

export type ReyieldSmartContract = {
  __typename?: 'ReyieldSmartContract'
  address: Scalars['String']
  chain: ReyieldEvmChain
  name: Scalars['String']
}

export type ReyieldStrategyFilterInput = {
  riskLevel?: InputMaybe<ReyieldRiskLevel>
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<ReyieldStrategyType>
}

export type ReyieldStrategyInfo = {
  __typename?: 'ReyieldStrategyInfo'
  currency: Scalars['String']
  description: Scalars['String']
  earningCurrency: Scalars['String']
  exchanges: Array<ReyieldExchange>
  expectedAPY: Scalars['String']
  id: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  parameters: Array<ReyieldStrategyParameters>
  riskLevel: ReyieldRiskLevel
  sid: Scalars['String']
  stage: ReyieldStrategyInfoStage
  suggestionPeriod: Scalars['String']
  target: Scalars['String']
  type: ReyieldStrategyType
}

export enum ReyieldStrategyInfoStage {
  Active = 'Active',
  Beta = 'Beta',
  Mock = 'Mock',
}

export type ReyieldStrategyInfos = {
  __typename?: 'ReyieldStrategyInfos'
  currency: Scalars['String']
  earningCurrency: Scalars['String']
  exchange?: Maybe<ReyieldExchange>
  minEnableAmount: Scalars['String']
  name: Scalars['String']
}

export enum ReyieldStrategyParameterType {
  Bool = 'bool',
  Num = 'num',
  Str = 'str',
  Unknown = 'unknown',
}

export type ReyieldStrategyParameters = {
  __typename?: 'ReyieldStrategyParameters'
  defaultValue?: Maybe<Scalars['String']>
  description: Scalars['String']
  key: Scalars['String']
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: ReyieldStrategyParameterType
  updatable: Scalars['Boolean']
}

export type ReyieldStrategyPerformance = {
  __typename?: 'ReyieldStrategyPerformance'
  amount: Scalars['String']
  apy: Scalars['String']
  currency: Scalars['String']
  ror: Scalars['String']
}

export type ReyieldStrategyProgress = {
  __typename?: 'ReyieldStrategyProgress'
  createdAt: Scalars['String']
  id: Scalars['ID']
  index: Scalars['Int']
  status: ReyieldStrategyProgressStatus
  steps: Array<Maybe<ReyieldStrategyProgressStep>>
  type: ReyieldStrategyProgressType
  updatedAt: Scalars['String']
}

export enum ReyieldStrategyProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type ReyieldStrategyProgressStep = {
  __typename?: 'ReyieldStrategyProgressStep'
  status: ReyieldStrategyProgressStepStatus
  type: ReyieldStrategyProgressStepType
}

export enum ReyieldStrategyProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum ReyieldStrategyProgressStepType {
  CexDepositCheck = 'cex_deposit_check',
  CexSwap = 'cex_swap',
  CexWalletsTransfer = 'cex_wallets_transfer',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export enum ReyieldStrategyProgressType {
  StrategyFinalize = 'strategy_finalize',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export type ReyieldStrategyStartInput = {
  parameters: Array<ReyieldStrategyStartParamenters>
  sid: Scalars['String']
}

export type ReyieldStrategyStartParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type ReyieldStrategyStartResponse = {
  __typename?: 'ReyieldStrategyStartResponse'
  strategy: ReyieldUserStrategyInfo
}

export enum ReyieldStrategyStatus {
  Error = 'error',
  Preparing = 'preparing',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Unknown = 'unknown',
  WaitForUserAction = 'wait_for_user_action',
}

export type ReyieldStrategyStopInput = {
  isForce?: InputMaybe<Scalars['Boolean']>
  strategyID: Scalars['String']
}

export type ReyieldStrategyStopResponse = {
  __typename?: 'ReyieldStrategyStopResponse'
  strategy: ReyieldUserStrategyInfo
}

export enum ReyieldStrategyType {
  Arbitrage = 'arbitrage',
  Defi = 'defi',
  Hedging = 'hedging',
  Lending = 'lending',
  Unknown = 'unknown',
}

export type ReyieldStrategyUpdateInput = {
  parameters: Array<ReyieldStrategyUpdateParamenters>
  strategyID: Scalars['String']
}

export type ReyieldStrategyUpdateParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type ReyieldStrategyUpdateResponse = {
  __typename?: 'ReyieldStrategyUpdateResponse'
  strategy: ReyieldUserStrategyInfo
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type ReyieldString_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type ReyieldTokenBalance = {
  __typename?: 'ReyieldTokenBalance'
  address: Scalars['String']
  amount: Scalars['String']
  amountReadable: Scalars['String']
  chain: ReyieldEvmChain
  decimals: Scalars['Int']
  id: Scalars['String']
  symbol: Scalars['String']
}

export type ReyieldTokenDisplayInfo = {
  __typename?: 'ReyieldTokenDisplayInfo'
  address: Scalars['String']
  chain: ReyieldEvmChain
  id: Scalars['String']
  imageURL: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type ReyieldToolInfo = ReyieldStrategyInfo | ReyieldUniswapToolInfo

export type ReyieldTotalProfits = {
  __typename?: 'ReyieldTotalProfits'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
}

export type ReyieldUniswapOnchainPositionAmountInfo = {
  __typename?: 'ReyieldUniswapOnchainPositionAmountInfo'
  /** this data is from thegraph */
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Amount: Scalars['String']
  token0AmountUsdValue: Scalars['String']
  /** this data is from thegraph */
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Amount: Scalars['String']
  token1AmountUsdValue: Scalars['String']
}

export type ReyieldUniswapPoolInfo = {
  __typename?: 'ReyieldUniswapPoolInfo'
  averageTradingVolumeUSD?: Maybe<Scalars['String']>
  chain: ReyieldEvmChain
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  collectedFeesUSD: Scalars['String']
  createdAtBlockNumber: Scalars['String']
  createdAtTimestamp: Scalars['String']
  feeGrowthGlobal0X128: Scalars['String']
  feeGrowthGlobal1X128: Scalars['String']
  feeTier: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  /** this part is from smart contract developed by reyield */
  isPoolActiveForReyield?: Maybe<Scalars['Boolean']>
  liquidity: Scalars['String']
  liquidityProviderCount: Scalars['String']
  observationIndex: Scalars['String']
  positions?: Maybe<Array<ReyieldUniswapPoolPosition>>
  sqrtPrice: Scalars['String']
  tick?: Maybe<Scalars['String']>
  ticks?: Maybe<Array<ReyieldUniswapPoolTick>>
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Price: Scalars['String']
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Price: Scalars['String']
  totalValueLockedETH: Scalars['String']
  totalValueLockedToken0: Scalars['String']
  totalValueLockedToken1: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volumeToken0: Scalars['String']
  volumeToken1: Scalars['String']
  volumeUSD: Scalars['String']
}

export type ReyieldUniswapPoolInfoAverageTradingVolumeUsdArgs = {
  numberOfDays: Scalars['Int']
}

export type ReyieldUniswapPoolPosition = {
  __typename?: 'ReyieldUniswapPoolPosition'
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  depositedToken0: Scalars['String']
  depositedToken1: Scalars['String']
  feeGrowthInside0LastX128: Scalars['String']
  feeGrowthInside1LastX128: Scalars['String']
  id: Scalars['ID']
  liquidity: Scalars['String']
  tickLowerFeeGrowthOutside0X128: Scalars['String']
  tickLowerFeeGrowthOutside1X128: Scalars['String']
  tickLowerTickIdx: Scalars['String']
  tickUpperFeeGrowthOutside0X128: Scalars['String']
  tickUpperFeeGrowthOutside1X128: Scalars['String']
  tickUpperTickIdx: Scalars['String']
  transactionTimestamp: Scalars['String']
}

export type ReyieldUniswapPoolTick = {
  __typename?: 'ReyieldUniswapPoolTick'
  id: Scalars['String']
  liquidityNet: Scalars['String']
  price0: Scalars['String']
  price1: Scalars['String']
  tickIdx: Scalars['String']
}

export type ReyieldUniswapQuote = {
  __typename?: 'ReyieldUniswapQuote'
  chain: ReyieldEvmChain
  fromToken: ReyieldUniswapToolToken
  fromTokenAddress: Scalars['String']
  id: Scalars['String']
  inputAmount: Scalars['String']
  inputReadableAmount: Scalars['String']
  outputAmount: Scalars['String']
  outputReadableAmount: Scalars['String']
  toToken: ReyieldUniswapToolToken
  toTokenAddress: Scalars['String']
}

export type ReyieldUniswapQuoteInput = {
  chain: ReyieldEvmChain
  fromTokenAddress: Scalars['String']
  inputReadableAmount: Scalars['String']
  toTokenAddress: Scalars['String']
}

export type ReyieldUniswapToolFeeTier = {
  __typename?: 'ReyieldUniswapToolFeeTier'
  feeForContract: Scalars['String']
  feeForDisplay: Scalars['String']
  id: Scalars['String']
}

export type ReyieldUniswapToolInfo = {
  __typename?: 'ReyieldUniswapToolInfo'
  chain: ReyieldEvmChain
  createdAt: Scalars['String']
  creator?: Maybe<ReyieldCreator>
  creatorAddress: Scalars['String']
  creatorId: Scalars['String']
  description: Scalars['String']
  expectedAPY: Scalars['String']
  feeTier: ReyieldUniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  performanceFee: Scalars['String']
  poolAddress: Scalars['String']
  /** this data is from thegraph */
  poolInfo?: Maybe<ReyieldUniswapPoolInfo>
  riskLevel: ReyieldRiskLevel
  tags?: Maybe<Array<ReyieldUniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickLowerDiffForContract: Scalars['String']
  tickUpperDiff: Scalars['String']
  tickUpperDiffForContract?: Maybe<Scalars['String']>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
}

export enum ReyieldUniswapToolPositionStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum ReyieldUniswapToolStatus {
  Created = 'CREATED',
  Deprecated = 'DEPRECATED',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type ReyieldUniswapToolTag = {
  __typename?: 'ReyieldUniswapToolTag'
  id: Scalars['String']
  name: Scalars['String']
  type: ReyieldUniswapToolTagType
}

export enum ReyieldUniswapToolTagType {
  Chain = 'chain',
  Custom = 'custom',
  IsOfficial = 'isOfficial',
  Uniswapv3 = 'uniswapv3',
  Unknown = 'unknown',
}

export type ReyieldUniswapToolToken = {
  __typename?: 'ReyieldUniswapToolToken'
  chain: ReyieldEvmChain
  decimals: Scalars['String']
  derivedETH: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  poolCount: Scalars['String']
  priceChart?: Maybe<ReyieldUniswapToolTokenPriceChart>
  symbol: Scalars['String']
  totalSupply: Scalars['String']
  totalValueLocked: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volume: Scalars['String']
  volumeUSD: Scalars['String']
}

export type ReyieldUniswapToolTokenPriceChart = {
  __typename?: 'ReyieldUniswapToolTokenPriceChart'
  currentPriceUSD?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  prices?: Maybe<Array<ReyieldUniswapToolTokenPriceChartPrice>>
}

export type ReyieldUniswapToolTokenPriceChartPrice = {
  __typename?: 'ReyieldUniswapToolTokenPriceChartPrice'
  timestamp: Scalars['String']
  value: Scalars['String']
}

export enum ReyieldUniswapV3BacktestPeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type ReyieldUniswapV3BacktestResult = {
  __typename?: 'ReyieldUniswapV3BacktestResult'
  activeLiquidityPercent: Scalars['Float']
  annualReturn: Scalars['Float']
  compound: Scalars['Boolean']
  createdAt: Scalars['String']
  dailyReturn: Scalars['Float']
  dataPointsCount: Scalars['String']
  days: Scalars['Int']
  diff: Scalars['Float']
  fees?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  impermanentLoss: Scalars['Float']
  investmentAmount: Scalars['Float']
  maxDrawdown?: Maybe<Scalars['Float']>
  period: ReyieldUniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: ReyieldEvmChain
  totalReturn?: Maybe<Scalars['Float']>
  volatility?: Maybe<Scalars['Float']>
}

export type ReyieldUserAiCompletionRecordsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ReyieldUserInfo = {
  __typename?: 'ReyieldUserInfo'
  aICompletionRecords?: Maybe<Array<ReyieldaICompletionRecords>>
  cefiDepositAddress?: Maybe<ReyieldCefiDepositAddress>
  cefiWallets?: Maybe<Array<ReyieldCefiWallet>>
  defiTokensBalanceAmount?: Maybe<Array<Maybe<ReyieldTokenBalance>>>
  defiWallets?: Maybe<Array<ReyieldDefiWallet>>
  externalAPIKeys?: Maybe<Array<ReyieldExternalApiKey>>
  gimCoolPayPaymentInfo?: Maybe<ReyieldGimCoolPayPaymentInfo>
  id: Scalars['String']
  nftCollections?: Maybe<Array<ReyieldNftCollections>>
  nftMerkleTreeHexProof?: Maybe<ReyieldNftMerkleTreeHexProof>
  nftWhitelistInfoByPhrases?: Maybe<Array<ReyieldNftWhitelistInfoByPhrase>>
  ownedToolsSetting?: Maybe<Array<ReyieldUserUniswapToolSettingInfo>>
  reyieldLicense?: Maybe<ReyieldReyieldLicense>
  strategies?: Maybe<Array<ReyieldUserStrategyInfo>>
  strategyPerformance?: Maybe<ReyieldUserStrategyPerformance>
  strategyProfit?: Maybe<ReyieldUserStrategyProfit>
  strategyProgress?: Maybe<ReyieldStrategyProgress>
  strategyRecords?: Maybe<Array<ReyieldUserStrategyRecord>>
  tools?: Maybe<Array<ReyieldUserToolInfo>>
}

export type ReyieldUserInfoAiCompletionRecordsArgs = {
  filter?: InputMaybe<ReyieldUserAiCompletionRecordsFilterInput>
}

export type ReyieldUserInfoCefiDepositAddressArgs = {
  filter?: InputMaybe<ReyieldCefiDepositAddressInput>
}

export type ReyieldUserInfoCefiWalletsArgs = {
  filter?: InputMaybe<ReyieldCefiWalletFilterInput>
}

export type ReyieldUserInfoDefiTokensBalanceAmountArgs = {
  input: ReyieldDefiTokensBalanceAmountInput
}

export type ReyieldUserInfoDefiWalletsArgs = {
  filter?: InputMaybe<ReyieldDefiWalletsFilterInput>
}

export type ReyieldUserInfoExternalApiKeysArgs = {
  filter?: InputMaybe<ReyieldExternalApiKeysFilter>
}

export type ReyieldUserInfoGimCoolPayPaymentInfoArgs = {
  input?: InputMaybe<ReyieldGimCoolPayPaymentInfoInput>
}

export type ReyieldUserInfoStrategiesArgs = {
  filter?: InputMaybe<ReyieldUserStrategyFilterInput>
}

export type ReyieldUserInfoStrategyPerformanceArgs = {
  strategyID: Scalars['String']
}

export type ReyieldUserInfoStrategyProfitArgs = {
  filter?: InputMaybe<ReyieldUserStrategyProfitFilterInput>
}

export type ReyieldUserInfoStrategyProgressArgs = {
  strategyID: Scalars['String']
}

export type ReyieldUserInfoStrategyRecordsArgs = {
  filter?: InputMaybe<ReyieldUserStrategyRecordsFilterInput>
}

export type ReyieldUserStrategyFilterInput = {
  sid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Array<InputMaybe<ReyieldStrategyStatus>>>
  strategyID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ReyieldUserStrategyInfo = {
  __typename?: 'ReyieldUserStrategyInfo'
  ceFiStatus: ReyieldStrategyStatus
  closedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['String']
  infos?: Maybe<ReyieldStrategyInfos>
  parameters?: Maybe<Array<ReyieldUserStrategyParameters>>
  performance?: Maybe<ReyieldUserStrategyPerformance>
  profits?: Maybe<ReyieldUserStrategyProfit>
  sid: Scalars['String']
  updatedAt: Scalars['String']
}

export type ReyieldUserStrategyParameters = {
  __typename?: 'ReyieldUserStrategyParameters'
  key: Scalars['String']
  value: Scalars['String']
}

export type ReyieldUserStrategyPerformance = {
  __typename?: 'ReyieldUserStrategyPerformance'
  duration?: Maybe<Scalars['Int']>
  initialValue?: Maybe<Scalars['String']>
  performances?: Maybe<Array<Maybe<ReyieldStrategyPerformance>>>
}

export type ReyieldUserStrategyProfit = {
  __typename?: 'ReyieldUserStrategyProfit'
  sid?: Maybe<Scalars['String']>
  totalProfit?: Maybe<Array<Maybe<ReyieldTotalProfits>>>
  type?: Maybe<ReyieldStrategyType>
}

export type ReyieldUserStrategyProfitFilterInput = {
  sid: Array<InputMaybe<Scalars['String']>>
  type?: InputMaybe<Array<InputMaybe<ReyieldStrategyType>>>
}

export type ReyieldUserStrategyRecord = {
  __typename?: 'ReyieldUserStrategyRecord'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<ReyieldRecordsType>
}

export type ReyieldUserStrategyRecordsFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  strategyID: Scalars['String']
  type?: InputMaybe<ReyieldRecordsType>
}

export type ReyieldUserToolInfo = ReyieldUserStrategyInfo | ReyieldUserUniswapPositionInfo

export type ReyieldUserUniswapOnchainPositionInfo = {
  __typename?: 'ReyieldUserUniswapOnchainPositionInfo'
  apy: Scalars['String']
  chain: ReyieldEvmChain
  closedPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  totalInvestAmountUsdValue?: Maybe<Scalars['String']>
}

export type ReyieldUserUniswapPositionInfo = {
  __typename?: 'ReyieldUserUniswapPositionInfo'
  apy: Scalars['String']
  chain: ReyieldEvmChain
  closedAt?: Maybe<Scalars['String']>
  closedPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  createdAt: Scalars['String']
  feeTier: ReyieldUniswapToolFeeTier
  id: Scalars['String']
  /** backend will cache the data for n minutes */
  onchainPositionInfo?: Maybe<ReyieldUserUniswapOnchainPositionInfo>
  poolAddress: Scalars['String']
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  progresses?: Maybe<Array<ReyieldUserUniswapv3PositionProgress>>
  publicToolInfo?: Maybe<ReyieldUniswapToolInfo>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  status: ReyieldUniswapToolPositionStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  toolDescription: Scalars['String']
  toolId: Scalars['String']
  toolIsOfficial: Scalars['Boolean']
  toolName: Scalars['String']
  toolPerformanceFeeRatio?: Maybe<Scalars['String']>
  /** if the user is the creator of the tool, then the toolSetting will be returned */
  toolSetting?: Maybe<ReyieldUserUniswapToolSettingInfo>
  totalInvestAmountUsdValue: Scalars['String']
  updatedAt: Scalars['String']
  userAddress: Scalars['String']
}

export type ReyieldUserUniswapPositionInfoOnchainPositionInfoArgs = {
  withoutCache?: InputMaybe<Scalars['Boolean']>
}

export type ReyieldUserUniswapToolOutOfRangeInfo = {
  __typename?: 'ReyieldUserUniswapToolOutOfRangeInfo'
  earnSpendRate: Scalars['String']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type ReyieldUserUniswapToolSettingInfo = {
  __typename?: 'ReyieldUserUniswapToolSettingInfo'
  chain: ReyieldEvmChain
  closedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  feeTier: ReyieldUniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  name: Scalars['String']
  outOfLowerRange: ReyieldUserUniswapToolOutOfRangeInfo
  outOfUpperRange: ReyieldUserUniswapToolOutOfRangeInfo
  performanceFeeRatio?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  /** User will have only one position for each tool */
  position?: Maybe<ReyieldUserUniswapPositionInfo>
  publicToolInfo?: Maybe<ReyieldUniswapToolInfo>
  status: ReyieldUniswapToolStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  totalUserUsed: Scalars['Int']
  updatedAt?: Maybe<Scalars['String']>
}

export type ReyieldUserUniswapv3PositionProgress = {
  __typename?: 'ReyieldUserUniswapv3PositionProgress'
  createdAt: Scalars['String']
  id: Scalars['String']
  index: Scalars['Int']
  status: ReyieldUserUniswapv3PositionProgressStatus
  steps: Array<ReyieldUserUniswapv3PositionProgressStep>
  type: ReyieldUserUniswapv3PositionProgressType
  updatedAt: Scalars['String']
}

export enum ReyieldUserUniswapv3PositionProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type ReyieldUserUniswapv3PositionProgressStep = {
  __typename?: 'ReyieldUserUniswapv3PositionProgressStep'
  status: ReyieldUserUniswapv3PositionProgressStepStatus
  type: ReyieldUserUniswapv3PositionProgressStepType
}

export enum ReyieldUserUniswapv3PositionProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum ReyieldUserUniswapv3PositionProgressStepType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export enum ReyieldUserUniswapv3PositionProgressType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export type ReyieldWalletBalances = {
  __typename?: 'ReyieldWalletBalances'
  currencyBalances: Array<ReyieldCurrencyBalances>
  wallet: Scalars['String']
}

export enum ReyieldWalletType {
  BfxExchange = 'bfx_exchange',
  BfxFunding = 'bfx_funding',
  BfxMargin = 'bfx_margin',
  Spot = 'spot',
  Unknown = 'unknown',
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecords = {
  __typename?: 'ReyieldaICompletionRecords'
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['String']
  meta?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  promptMutationInput?: Maybe<Scalars['jsonb']>
  promptMutationName?: Maybe<Scalars['String']>
  /** An array relationship */
  suggestions: Array<ReyieldsuggestStrategyToolWithAllocations>
  /** An aggregate relationship */
  suggestions_aggregate: ReyieldsuggestStrategyToolWithAllocations_Aggregate
  updatedAt: Scalars['timestamptz']
  userAddress?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsPromptMutationInputArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsSuggestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>
}

/** column ordering options */
export enum Reyieldorder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations'
  aICompletionRecordId: Scalars['String']
  allocation: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Scalars['String']
  highestAnnualReturn: Scalars['String']
  id: Scalars['uuid']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations_Aggregate = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_aggregate'
  aggregate?: Maybe<ReyieldsuggestStrategyToolWithAllocations_Aggregate_Fields>
  nodes: Array<ReyieldsuggestStrategyToolWithAllocations>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations_Aggregate_Fields = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<ReyieldsuggestStrategyToolWithAllocations_Max_Fields>
  min?: Maybe<ReyieldsuggestStrategyToolWithAllocations_Min_Fields>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "suggestStrategyToolWithAllocations". All fields are combined with a logical 'AND'. */
export type ReyieldsuggestStrategyToolWithAllocations_Bool_Exp = {
  _and?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>>
  _not?: InputMaybe<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>
  _or?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>>
  aICompletionRecordId?: InputMaybe<ReyieldString_Comparison_Exp>
  allocation?: InputMaybe<ReyieldString_Comparison_Exp>
  createdAt?: InputMaybe<Reyieldtimestamptz_Comparison_Exp>
  description?: InputMaybe<ReyieldString_Comparison_Exp>
  highestAnnualReturn?: InputMaybe<ReyieldString_Comparison_Exp>
  id?: InputMaybe<Reyielduuid_Comparison_Exp>
  maximumLoss?: InputMaybe<ReyieldString_Comparison_Exp>
  name?: InputMaybe<ReyieldString_Comparison_Exp>
  sid?: InputMaybe<ReyieldString_Comparison_Exp>
  systemInvestAmountSuggestion?: InputMaybe<ReyieldString_Comparison_Exp>
  systemInvestCurrencySuggestion?: InputMaybe<ReyieldString_Comparison_Exp>
  updatedAt?: InputMaybe<Reyieldtimestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type ReyieldsuggestStrategyToolWithAllocations_Max_Fields = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_max_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type ReyieldsuggestStrategyToolWithAllocations_Min_Fields = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_min_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** Ordering options when selecting data from "suggestStrategyToolWithAllocations". */
export type ReyieldsuggestStrategyToolWithAllocations_Order_By = {
  aICompletionRecordId?: InputMaybe<Reyieldorder_By>
  allocation?: InputMaybe<Reyieldorder_By>
  createdAt?: InputMaybe<Reyieldorder_By>
  description?: InputMaybe<Reyieldorder_By>
  highestAnnualReturn?: InputMaybe<Reyieldorder_By>
  id?: InputMaybe<Reyieldorder_By>
  maximumLoss?: InputMaybe<Reyieldorder_By>
  name?: InputMaybe<Reyieldorder_By>
  sid?: InputMaybe<Reyieldorder_By>
  systemInvestAmountSuggestion?: InputMaybe<Reyieldorder_By>
  systemInvestCurrencySuggestion?: InputMaybe<Reyieldorder_By>
  updatedAt?: InputMaybe<Reyieldorder_By>
}

/** select columns of table "suggestStrategyToolWithAllocations" */
export enum ReyieldsuggestStrategyToolWithAllocations_Select_Column {
  /** column name */
  AICompletionRecordId = 'aICompletionRecordId',
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HighestAnnualReturn = 'highestAnnualReturn',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumLoss = 'maximumLoss',
  /** column name */
  Name = 'name',
  /** column name */
  Sid = 'sid',
  /** column name */
  SystemInvestAmountSuggestion = 'systemInvestAmountSuggestion',
  /** column name */
  SystemInvestCurrencySuggestion = 'systemInvestCurrencySuggestion',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Reyieldtimestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Reyielduuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export enum RiskLevel {
  RiskLevelHigh = 'RiskLevelHigh',
  RiskLevelLow = 'RiskLevelLow',
  RiskLevelMedium = 'RiskLevelMedium',
  RiskLevelMediumHigh = 'RiskLevelMediumHigh',
  RiskLevelMediumLow = 'RiskLevelMediumLow',
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'medium_high',
  MediumLow = 'medium_low',
  Unknown = 'unknown',
}

export enum RiskToleranceLevelName {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  ExtremelyAggressive = 'ExtremelyAggressive',
  ExtremelyConservative = 'ExtremelyConservative',
  Moderate = 'Moderate',
  VeryConservative = 'VeryConservative',
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type Section = Entry & {
  __typename?: 'Section'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SectionLinkingCollections>
  sectionContentsCollection?: Maybe<SectionSectionContentsCollection>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type SectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type SectionSectionContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionSectionContentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionContentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type SectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionCollection = {
  __typename?: 'SectionCollection'
  items: Array<Maybe<Section>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContent = Entry & {
  __typename?: 'SectionContent'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<SectionContentDescription>
  externalLink?: Maybe<Scalars['String']>
  externalLinkDisplayText?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionContentLinkingCollections>
  subTitle?: Maybe<Scalars['String']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentExternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentExternalLinkDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionContentCollection = {
  __typename?: 'SectionContentCollection'
  items: Array<Maybe<SectionContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionContentDescription = {
  __typename?: 'SectionContentDescription'
  json: Scalars['JSON']
  links: SectionContentDescriptionLinks
}

export type SectionContentDescriptionAssets = {
  __typename?: 'SectionContentDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type SectionContentDescriptionEntries = {
  __typename?: 'SectionContentDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type SectionContentDescriptionLinks = {
  __typename?: 'SectionContentDescriptionLinks'
  assets: SectionContentDescriptionAssets
  entries: SectionContentDescriptionEntries
  resources: SectionContentDescriptionResources
}

export type SectionContentDescriptionResources = {
  __typename?: 'SectionContentDescriptionResources'
  block: Array<ResourceLink>
}

export type SectionContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  externalLink?: InputMaybe<Scalars['String']>
  externalLinkDisplayText?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  externalLinkDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLinkDisplayText_not?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle?: InputMaybe<Scalars['String']>
  subTitle_contains?: InputMaybe<Scalars['String']>
  subTitle_exists?: InputMaybe<Scalars['Boolean']>
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle_not?: InputMaybe<Scalars['String']>
  subTitle_not_contains?: InputMaybe<Scalars['String']>
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionContentLinkingCollections = {
  __typename?: 'SectionContentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionCollection?: Maybe<SectionCollection>
}

export type SectionContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionContentLinkingCollectionsSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionContentLinkingCollectionsSectionCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionContentLinkingCollectionsSectionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionContentOrder {
  ExternalLinkDisplayTextAsc = 'externalLinkDisplayText_ASC',
  ExternalLinkDisplayTextDesc = 'externalLinkDisplayText_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  SubTitleAsc = 'subTitle_ASC',
  SubTitleDesc = 'subTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sectionContents?: InputMaybe<CfSectionContentNestedFilter>
  sectionContentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionLinkingCollections = {
  __typename?: 'SectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  strategyMetaCollection?: Maybe<StrategyMetaCollection>
}

export type SectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionLinkingCollectionsStrategyMetaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionLinkingCollectionsStrategyMetaCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionLinkingCollectionsStrategyMetaCollectionOrder {
  SidAsc = 'sid_ASC',
  SidDesc = 'sid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionSectionContentsCollection = {
  __typename?: 'SectionSectionContentsCollection'
  items: Array<Maybe<SectionContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionSectionContentsCollectionOrder {
  ExternalLinkDisplayTextAsc = 'externalLinkDisplayText_ASC',
  ExternalLinkDisplayTextDesc = 'externalLinkDisplayText_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  SubTitleAsc = 'subTitle_ASC',
  SubTitleDesc = 'subTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SmartContract = {
  __typename?: 'SmartContract'
  address: Scalars['String']
  chain: EvmChain
  name: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/specialUser) */
export type SpecialUser = Entry & {
  __typename?: 'SpecialUser'
  contentfulMetadata: ContentfulMetadata
  email?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SpecialUserLinkingCollections>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/specialUser) */
export type SpecialUserEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/specialUser) */
export type SpecialUserLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SpecialUserCollection = {
  __typename?: 'SpecialUserCollection'
  items: Array<Maybe<SpecialUser>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SpecialUserFilter = {
  AND?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SpecialUserFilter_Remote_Rel_Bitginspecial = {
  AND?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SpecialUserLinkingCollections = {
  __typename?: 'SpecialUserLinkingCollections'
  displayItemCollection?: Maybe<DisplayItemCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type SpecialUserLinkingCollectionsDisplayItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SpecialUserLinkingCollectionsDisplayItemCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SpecialUserLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SpecialUserLinkingCollectionsDisplayItemCollectionOrder {
  EnableSpecialSharingBackgroundsModeAsc = 'enableSpecialSharingBackgroundsMode_ASC',
  EnableSpecialSharingBackgroundsModeDesc = 'enableSpecialSharingBackgroundsMode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SpecialUserOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StrategyFilterInput = {
  riskLevel?: InputMaybe<RiskLevel>
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<StrategyType>
}

export type StrategyInfo = {
  __typename?: 'StrategyInfo'
  currency: Scalars['String']
  description: Scalars['String']
  earningCurrency: Scalars['String']
  exchanges: Array<Exchange>
  expectedAPY: Scalars['String']
  id: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  parameters: Array<StrategyParameters>
  riskLevel: RiskLevel
  sid: Scalars['String']
  stage: StrategyInfoStage
  suggestionPeriod: Scalars['String']
  target: Scalars['String']
  type: StrategyType
}

export enum StrategyInfoStage {
  Active = 'Active',
  Beta = 'Beta',
  Mock = 'Mock',
}

export type StrategyInfos = {
  __typename?: 'StrategyInfos'
  currency: Scalars['String']
  earningCurrency: Scalars['String']
  exchange?: Maybe<Exchange>
  minEnableAmount: Scalars['String']
  name: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMeta = Entry & {
  __typename?: 'StrategyMeta'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<StrategyMetaLinkingCollections>
  sectionsCollection?: Maybe<StrategyMetaSectionsCollection>
  sid?: Maybe<Scalars['String']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMetaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMetaSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<StrategyMetaSectionsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMetaSidArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type StrategyMetaCollection = {
  __typename?: 'StrategyMetaCollection'
  items: Array<Maybe<StrategyMeta>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type StrategyMetaFilter = {
  AND?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sections?: InputMaybe<CfSectionNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sid?: InputMaybe<Scalars['String']>
  sid_contains?: InputMaybe<Scalars['String']>
  sid_exists?: InputMaybe<Scalars['Boolean']>
  sid_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sid_not?: InputMaybe<Scalars['String']>
  sid_not_contains?: InputMaybe<Scalars['String']>
  sid_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo = {
  AND?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sections?: InputMaybe<CfSectionNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sid_contains?: InputMaybe<Scalars['String']>
  sid_exists?: InputMaybe<Scalars['Boolean']>
  sid_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sid_not?: InputMaybe<Scalars['String']>
  sid_not_contains?: InputMaybe<Scalars['String']>
  sid_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type StrategyMetaLinkingCollections = {
  __typename?: 'StrategyMetaLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type StrategyMetaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum StrategyMetaOrder {
  SidAsc = 'sid_ASC',
  SidDesc = 'sid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StrategyMetaSectionsCollection = {
  __typename?: 'StrategyMetaSectionsCollection'
  items: Array<Maybe<Section>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum StrategyMetaSectionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum StrategyParameterType {
  Bool = 'bool',
  Num = 'num',
  Str = 'str',
  Unknown = 'unknown',
}

export type StrategyParameters = {
  __typename?: 'StrategyParameters'
  defaultValue?: Maybe<Scalars['String']>
  description: Scalars['String']
  key: Scalars['String']
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: StrategyParameterType
  updatable: Scalars['Boolean']
}

export type StrategyPerformance = {
  __typename?: 'StrategyPerformance'
  amount: Scalars['String']
  apy: Scalars['String']
  currency: Scalars['String']
  ror: Scalars['String']
}

export type StrategyProgress = {
  __typename?: 'StrategyProgress'
  createdAt: Scalars['String']
  id: Scalars['ID']
  index: Scalars['Int']
  status: StrategyProgressStatus
  steps: Array<Maybe<StrategyProgressStep>>
  type: StrategyProgressType
  updatedAt: Scalars['String']
}

export enum StrategyProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type StrategyProgressStep = {
  __typename?: 'StrategyProgressStep'
  status: StrategyProgressStepStatus
  type: StrategyProgressStepType
}

export enum StrategyProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum StrategyProgressStepType {
  CexDepositCheck = 'cex_deposit_check',
  CexSwap = 'cex_swap',
  CexWalletsTransfer = 'cex_wallets_transfer',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export enum StrategyProgressType {
  StrategyFinalize = 'strategy_finalize',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export type StrategyStartInput = {
  parameters: Array<StrategyStartParamenters>
  sid: Scalars['String']
}

export type StrategyStartParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyStartResponse = {
  __typename?: 'StrategyStartResponse'
  strategy: UserStrategyInfo
}

export enum StrategyStatus {
  StrategyStatusError = 'StrategyStatusError',
  StrategyStatusObserving = 'StrategyStatusObserving',
  StrategyStatusPreparing = 'StrategyStatusPreparing',
  StrategyStatusRunning = 'StrategyStatusRunning',
  StrategyStatusStopped = 'StrategyStatusStopped',
  StrategyStatusStopping = 'StrategyStatusStopping',
  StrategyStatusUnknown = 'StrategyStatusUnknown',
  StrategyStatusWaitForUserAction = 'StrategyStatusWaitForUserAction',
  Error = 'error',
  Preparing = 'preparing',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Unknown = 'unknown',
  WaitForUserAction = 'wait_for_user_action',
}

export type StrategyStopInput = {
  isForce?: InputMaybe<Scalars['Boolean']>
  strategyID: Scalars['String']
}

export type StrategyStopResponse = {
  __typename?: 'StrategyStopResponse'
  strategy: UserStrategyInfo
}

export enum StrategyType {
  /** 搬磚 */
  StrategyTypeArbitrage = 'StrategyTypeArbitrage',
  /** DeFi */
  StrategyTypeDeFi = 'StrategyTypeDeFi',
  /** 期現套利 */
  StrategyTypeHedging = 'StrategyTypeHedging',
  /** 放貸 */
  StrategyTypeLending = 'StrategyTypeLending',
  StrategyTypeUnknown = 'StrategyTypeUnknown',
  Arbitrage = 'arbitrage',
  Defi = 'defi',
  Hedging = 'hedging',
  Lending = 'lending',
  Unknown = 'unknown',
}

export type StrategyUpdateInput = {
  parameters: Array<StrategyUpdateParamenters>
  strategyID: Scalars['String']
}

export type StrategyUpdateParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyUpdateResponse = {
  __typename?: 'StrategyUpdateResponse'
  strategy: UserStrategyInfo
}

/** Action */
export type Strategy_Action = {
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiApproveSwapReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIApproveSwapReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiDeductTxnFee = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIDeductTxnFee'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiDispatchUsdtReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIDispatchUSDTReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvAddLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVAddLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvApproveConvexBooster = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVApproveConvexBooster'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvApproveCurve3Pool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVApproveCurve3Pool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvClaimReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVClaimReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvDepositAll = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVDepositAll'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvRemoveLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVRemoveLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvWithdrawAndUnwrap = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimimusdtApproveCurve3Pool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIMUSDTApproveCurve3Pool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiRebalanceTxnFeeEstimation = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIRebalanceTxnFeeEstimation'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiStakePool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIStakePool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiSwapEthToUsdt = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFISwapETHToUSDT'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiSwapReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFISwapReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiTransferUserLpBalanceToPool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFITransferUserLPBalanceToPool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUnStakePool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUnStakePool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterAddLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterClaimReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterRmLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterSwapEthToUsdt = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterSwapReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdateUserRecordStatus = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdateUserRecordStatus'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalDepositCheck = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalDepositCheck'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalSwap = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalSwap'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalWalletTransfer = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalWalletTransfer'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalWithdrawal = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalWithdrawal'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionInternalDepositCheck = Strategy_Action & {
  __typename?: 'Strategy_ActionInternalDepositCheck'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionInternalTrade = Strategy_Action & {
  __typename?: 'Strategy_ActionInternalTrade'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionInternalWithdrawal = Strategy_Action & {
  __typename?: 'Strategy_ActionInternalWithdrawal'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionLog = {
  __typename?: 'Strategy_ActionLog'
  action: Scalars['String']
  createdAt: Scalars['Strategy_Time']
  description: Scalars['String']
  id: Scalars['ID']
}

export type Strategy_ActionLogFilter = {
  actions?: InputMaybe<Array<Scalars['String']>>
  workflowIds?: InputMaybe<Array<Scalars['ID']>>
}

export type Strategy_ActionStrategyBatchStart = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyBatchStart'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyBatchStop = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyBatchStop'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyBatchUpdate = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyBatchUpdate'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyStart = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyStart'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyStop = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyStop'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyUpdate = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyUpdate'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferFromDefiWalletPostProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferFromDEFIWalletPostProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferFromDefiWalletPreProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferFromDEFIWalletPreProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferFromDefiWalletProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferFromDEFIWalletProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferToDefiWalletPostProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferToDEFIWalletPostProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferToDefiWalletPreProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferToDEFIWalletPreProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferToDefiWalletProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferToDEFIWalletProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionWatchTxnStatus = Strategy_Action & {
  __typename?: 'Strategy_ActionWatchTxnStatus'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_Admin = {
  __typename?: 'Strategy_Admin'
  externalAPIKeys: Array<Strategy_ExternalApiKey>
  workflows: Array<Strategy_Workflow>
}

export type Strategy_AdminExternalApiKeysArgs = {
  filter?: InputMaybe<Strategy_AdminExternalApiKeyFilter>
}

export type Strategy_AdminWorkflowsArgs = {
  filter?: InputMaybe<Strategy_AdminWorkflowFilter>
  pagination?: InputMaybe<PaginationQuery>
}

export type Strategy_AdminExternalApiKeyFilter = {
  exchange?: InputMaybe<Exchange>
  externalAPIKeyID?: InputMaybe<Scalars['ID']>
  isRevoked?: InputMaybe<Scalars['Boolean']>
  userID?: InputMaybe<Scalars['ID']>
}

export type Strategy_AdminMutation = {
  __typename?: 'Strategy_AdminMutation'
  defiNormalFlow: Strategy_Workflow
  defiRebalanceFlow: Strategy_Workflow
  defiWithdrawExpressFlow: Strategy_Workflow
  workflowRetry: Scalars['Boolean']
}

export type Strategy_AdminMutationDefiNormalFlowArgs = {
  input: Strategy_DefiNormalFlowInput
}

export type Strategy_AdminMutationDefiRebalanceFlowArgs = {
  input: Strategy_DefiRebalanceFlowInput
}

export type Strategy_AdminMutationDefiWithdrawExpressFlowArgs = {
  input: Strategy_DefiWithdrawExpressFlowInput
}

export type Strategy_AdminMutationWorkflowRetryArgs = {
  input: Strategy_AdminWorkflowRetryInput
}

export type Strategy_AdminWorkflowFilter = {
  id?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Array<InputMaybe<Strategy_WorkflowStatus>>>
  type?: InputMaybe<Array<InputMaybe<Strategy_WorkflowType>>>
  userID?: InputMaybe<Scalars['ID']>
}

export type Strategy_AdminWorkflowRetryInput = {
  id: Scalars['ID']
  renewActions?: InputMaybe<Array<InputMaybe<Strategy_RenewAction>>>
  startIndex?: InputMaybe<Scalars['Int']>
}

export type Strategy_ArbitrageState = {
  __typename?: 'Strategy_ArbitrageState'
  /** 已完成數量 */
  executedAmount: Scalars['Strategy_Number']
  /** USDT數量 */
  totalAmount: Scalars['Strategy_Number']
  /** 最後更新時間 */
  updatedAt: Scalars['Strategy_Time']
}

export type Strategy_Balance = {
  __typename?: 'Strategy_Balance'
  availTWDValue: Scalars['Strategy_Number']
  availUSDValue: Scalars['Strategy_Number']
  available: Scalars['Strategy_Number']
  currency: Scalars['String']
  total: Scalars['Strategy_Number']
  totalTWDValue: Scalars['Strategy_Number']
  totalUSDValue: Scalars['Strategy_Number']
  walletType: ExternalWallet
}

export type Strategy_CurrencyAmount = {
  __typename?: 'Strategy_CurrencyAmount'
  /** 數量 */
  amount: Scalars['Strategy_Number']
  /** 幣種 */
  currency: Scalars['String']
}

export type Strategy_DefiNormalFlowInput = {
  lpToken: Strategy_Token
  testTxHash?: InputMaybe<Scalars['String']>
}

export type Strategy_DefiRebalanceFlowInput = {
  lpToken: Strategy_Token
}

export type Strategy_DefiWithdrawExpressFlowInput = {
  lpToken: Strategy_Token
  testTxHash?: InputMaybe<Scalars['String']>
}

export type Strategy_DailyProfit = {
  __typename?: 'Strategy_DailyProfit'
  /** 當天收益 */
  amount: Scalars['Strategy_Number']
  /** 幣種 */
  currency: Scalars['String']
  /** 日期 */
  time: Scalars['Strategy_Time']
}

export type Strategy_DebtRecord = {
  __typename?: 'Strategy_DebtRecord'
  amount: Scalars['Int']
  id: Scalars['ID']
  time: Scalars['Strategy_Time']
}

export type Strategy_EstimatePointsConsumingInput = {
  parameters: Array<Strategy_StrategyParameterInput>
  sid: Scalars['String']
}

export type Strategy_EstimatePointsConsumingResult = {
  __typename?: 'Strategy_EstimatePointsConsumingResult'
  points: Scalars['Int']
  sid: Scalars['String']
}

export type Strategy_EstimateStrategyRequiredAmountResponse = {
  __typename?: 'Strategy_EstimateStrategyRequiredAmountResponse'
  currency: Currency
  lackingAmount: Scalars['Strategy_Number']
}

export type Strategy_ExchangeAsset = {
  __typename?: 'Strategy_ExchangeAsset'
  balances: Array<Strategy_Balance>
  exchange: Exchange
  netAvailBalanceInTWD: Scalars['Strategy_Number']
  netAvailBalanceInUSD: Scalars['Strategy_Number']
  netBalanceInTWD: Scalars['Strategy_Number']
  netBalanceInUSD: Scalars['Strategy_Number']
}

export type Strategy_ExchangeBalancesInput = {
  externalAPIKeyID: Scalars['ID']
}

export type Strategy_ExchangeWithdrawalAuthorizationInput = {
  externalAPIKeyIDs?: InputMaybe<Array<Scalars['ID']>>
}

export type Strategy_ExchangeWithdrawalAuthorizationResponse = {
  __typename?: 'Strategy_ExchangeWithdrawalAuthorizationResponse'
  exchange: Exchange
  is2FARequired: Scalars['Boolean']
  isWithdrawalPasswordRequired: Scalars['Boolean']
}

export type Strategy_ExchangesNetAssetChart = {
  __typename?: 'Strategy_ExchangesNetAssetChart'
  data: Array<Strategy_ExchangesNetAssetChartData>
  exchange: Exchange
}

export type Strategy_ExchangesNetAssetChartData = {
  __typename?: 'Strategy_ExchangesNetAssetChartData'
  netAssetTWDValue: Scalars['Strategy_Number']
  netAssetUSDValue: Scalars['Strategy_Number']
  time: Scalars['Strategy_Time']
}

export type Strategy_ExchangesNetAssetChartInput = {
  endTime: Scalars['Strategy_Time']
  exchanges: Array<Exchange>
  granularity: Granularity
  startTime?: InputMaybe<Scalars['Strategy_Time']>
}

export type Strategy_ExternalApiKey = {
  __typename?: 'Strategy_ExternalAPIKey'
  createdAt: Scalars['Strategy_Time']
  exchange: Exchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  /** format in first 3 char + ********** + last 3 char */
  secret: Scalars['String']
  subaccount?: Maybe<Scalars['String']>
  userID: Scalars['ID']
}

export type Strategy_ExternalApiKeyCreateInput = {
  exchange: Exchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
  subaccount?: InputMaybe<Scalars['String']>
}

export type Strategy_ExternalApiKeyDeleteInput = {
  id: Scalars['ID']
}

export type Strategy_ExternalApiKeyDepositInput = {
  amount: Scalars['Strategy_Number']
  /** code2FA only for ftx */
  chain?: InputMaybe<Chain>
  /** password only for ftx */
  code2FA?: InputMaybe<Scalars['String']>
  /** when currency is twd, chain not required */
  currency: Currency
  externalAPIKeyID: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
}

export type Strategy_ExternalApiKeyFilter = {
  exchange?: InputMaybe<Exchange>
  externalAPIKeyID?: InputMaybe<Scalars['ID']>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export type Strategy_ExternalApiKeyMutation = {
  __typename?: 'Strategy_ExternalAPIKeyMutation'
  externalAPIKeyCreate: Strategy_ExternalApiKey
  externalAPIKeyDelete: Scalars['Boolean']
  externalAPIKeyDeposit: Scalars['Boolean']
  externalAPIKeySwap: Scalars['Boolean']
  /** externalAPIKeyTransferBetweenWallets only for bitfinex so far */
  externalAPIKeyTransferBetweenWallets: Scalars['Boolean']
  /** deposit asset to this api key wallet */
  externalAPIKeyWithdrawal: Scalars['String']
  externalAPIKeyWithdrawalConfirm: Strategy_ExternalApiKeyWithdrawal
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyCreateArgs = {
  input: Strategy_ExternalApiKeyCreateInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyDeleteArgs = {
  input: Strategy_ExternalApiKeyDeleteInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyDepositArgs = {
  input: Strategy_ExternalApiKeyDepositInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeySwapArgs = {
  input: Strategy_ExternalApiKeySwapInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyTransferBetweenWalletsArgs = {
  input: Strategy_ExternalApiKeyTransferBetweenWalletsInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyWithdrawalArgs = {
  input: Strategy_ExternalApiKeyWithdrawalInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyWithdrawalConfirmArgs = {
  input: Strategy_ExternalApiKeyWithdrawalConfirmInput
}

export type Strategy_ExternalApiKeySwapInput = {
  externalAPIKeyID: Scalars['ID']
  fromAmount: Scalars['Strategy_Number']
  fromCurrency: Scalars['String']
  toCurrency: Scalars['String']
}

export type Strategy_ExternalApiKeyTransferBetweenWalletsInput = {
  amount: Scalars['Strategy_Number']
  currency: Scalars['String']
  externalAPIKeyID: Scalars['ID']
  fromWallet: Scalars['String']
  toWallet: Scalars['String']
}

export type Strategy_ExternalApiKeyWithdrawal = {
  __typename?: 'Strategy_ExternalAPIKeyWithdrawal'
  amount: Scalars['Strategy_Number']
  createdAt: Scalars['Strategy_Time']
  currency: Currency
  exchange: Exchange
  id: Scalars['ID']
  toAddress: Scalars['String']
  userID: Scalars['ID']
  withdrawalID: Scalars['ID']
}

export type Strategy_ExternalApiKeyWithdrawalConfirmInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type Strategy_ExternalApiKeyWithdrawalInput = {
  amount: Scalars['Strategy_Number']
  currency: Currency
  externalAPIKeyID: Scalars['ID']
  isDeduction: Scalars['Boolean']
}

export type Strategy_GenericFlowMutation = {
  __typename?: 'Strategy_GenericFlowMutation'
  withdrawalCryptoStart: Strategy_Workflow
  withdrawalFiatStart: Strategy_Workflow
  withdrawalRetry: Strategy_Workflow
}

export type Strategy_GenericFlowMutationWithdrawalCryptoStartArgs = {
  input: Strategy_WithdrawalCryptoStartInput
}

export type Strategy_GenericFlowMutationWithdrawalFiatStartArgs = {
  input: Strategy_WithdrawalFiatStartInput
}

export type Strategy_GenericFlowMutationWithdrawalRetryArgs = {
  input: Strategy_WithdrawalRetryInput
}

export type Strategy_LendingOffer = {
  __typename?: 'Strategy_LendingOffer'
  /** 貸款數量 */
  amount: Scalars['Strategy_Number']
  /** 建立時間 */
  createdAt: Scalars['Strategy_Time']
  /** 放貸單號 */
  id: Scalars['String']
  /** 貸款天數 */
  period: Scalars['Int']
  /** 借出在哪個交易對 */
  positionPair: Scalars['String']
  /** 貸款日利率 */
  rate: Scalars['Strategy_Number']
}

export type Strategy_LendingState = {
  __typename?: 'Strategy_LendingState'
  /** 已借出 */
  activeOffers: Array<Strategy_LendingOffer>
  /** 掛單中 */
  openOffers: Array<Strategy_LendingOffer>
  /** 最後更新時間 */
  updatedAt: Scalars['Strategy_Time']
}

export type Strategy_Performance = {
  __typename?: 'Strategy_Performance'
  /** 數量 */
  amount: Scalars['Strategy_Number']
  /** 年化報酬率 */
  apy: Scalars['Strategy_Number']
  /** 幣種 */
  currency: Scalars['String']
  /** 累計收益率 */
  ror: Scalars['Strategy_Number']
}

export type Strategy_ProfitInputFilter = {
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<StrategyType>
}

export type Strategy_Public = {
  __typename?: 'Strategy_Public'
  strategies: Array<Strategy_StrategyInfo>
}

export type Strategy_PublicStrategiesArgs = {
  filter?: InputMaybe<Strategy_StrategyFilter>
}

export type Strategy_RenewAction = {
  /** params is the json string of action's runtime parameters after updated by admin */
  params: Scalars['String']
  type: Strategy_WorkflowAction
}

export type Strategy_Strategy = {
  __typename?: 'Strategy_Strategy'
  closedAt?: Maybe<Scalars['Strategy_Time']>
  createdAt: Scalars['Strategy_Time']
  /** 策略負債 */
  debt: Strategy_StrategyDebt
  displayInfo?: Maybe<StrategyMetaCollection>
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['ID']
  info: Strategy_StrategyInfo
  parameters: Array<Strategy_StrategyParameter>
  performance: Strategy_StrategyPerformance
  principal: Strategy_CurrencyAmount
  sid: Scalars['String']
  state?: Maybe<Strategy_StrategyState>
  status: StrategyStatus
  updatedAt: Scalars['Strategy_Time']
  workflows: Array<Strategy_Workflow>
  yieldRecords: Array<Maybe<Strategy_YieldRecord>>
}

export type Strategy_StrategyDisplayInfoArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<StrategyMetaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo>
}

export type Strategy_StrategyYieldRecordsArgs = {
  pagination?: InputMaybe<PaginationQuery>
}

export type Strategy_StrategyCloseInput = {
  id: Scalars['ID']
}

export type Strategy_StrategyDeFiStartInput = {
  parameters: Array<Strategy_StrategyParameterInput>
  sid: Scalars['String']
}

export type Strategy_StrategyDebt = {
  __typename?: 'Strategy_StrategyDebt'
  /** 總共欠繳點數 */
  quantity: Scalars['Int']
  /** 欠繳紀錄 */
  records: Array<Maybe<Strategy_DebtRecord>>
}

export type Strategy_StrategyDescription = {
  __typename?: 'Strategy_StrategyDescription'
  key: Scalars['String']
  value: Scalars['String']
}

export type Strategy_StrategyFilter = {
  riskLevel?: InputMaybe<RiskLevel>
  sid?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<StrategyStatus>>
  strategyID?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<StrategyType>
}

export type Strategy_StrategyInfo = {
  __typename?: 'Strategy_StrategyInfo'
  /** 預估關單所需礦工費 */
  closeGasFee: Strategy_CurrencyAmount
  /** 投入資金的幣種 */
  currency: Scalars['String']
  /** 策略介紹 */
  descriptionDetail: Scalars['String']
  /** deprecated... */
  descriptionList: Array<Strategy_StrategyDescription>
  displayInfo?: Maybe<StrategyMetaCollection>
  /** 利潤的幣種 */
  earningCurrency: Scalars['String']
  /** 策略運行的交易所 */
  exchanges: Array<Exchange>
  /** 預期年化 */
  expectedAPY: Scalars['String']
  /** deprecated... */
  feePerHourAmount: Scalars['String']
  /** 策略名稱 */
  name: Scalars['String']
  /** 開單次數 */
  numberOfApplication: Scalars['Int']
  /** 預估開單所需礦工費 */
  openGasFee: Strategy_CurrencyAmount
  /** 參數列表 */
  parameterDescriptions: Array<Strategy_StrategyParameterDesc>
  /** deprecated... */
  pointPerHourAmount: Scalars['String']
  /** 總共替用戶賺取利潤 */
  profits: Array<Strategy_CurrencyAmount>
  /** 風險等級 */
  riskLevel: RiskLevel
  /** 策略代碼 */
  sid: Scalars['String']
  /** 建議執行時間 */
  suggestionPeriod: Scalars['String']
  /** 策略標的 */
  target: Scalars['String']
  /** 策略類型 */
  type: StrategyType
}

export type Strategy_StrategyInfoDisplayInfoArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<StrategyMetaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo>
}

export type Strategy_StrategyMutation = {
  __typename?: 'Strategy_StrategyMutation'
  strategyClose: Strategy_Strategy
  strategyDeFiStart: Strategy_Strategy
  strategyRetry: Scalars['Boolean']
  strategyStart: Strategy_Strategy
  strategyStop: Strategy_Strategy
  strategyUpdate: Strategy_Strategy
}

export type Strategy_StrategyMutationStrategyCloseArgs = {
  input: Strategy_StrategyCloseInput
}

export type Strategy_StrategyMutationStrategyDeFiStartArgs = {
  input: Strategy_StrategyDeFiStartInput
}

export type Strategy_StrategyMutationStrategyRetryArgs = {
  input: Strategy_StrategyRetryInput
}

export type Strategy_StrategyMutationStrategyStartArgs = {
  input: Strategy_StrategyStartInput
}

export type Strategy_StrategyMutationStrategyStopArgs = {
  input: Strategy_StrategyStopInput
}

export type Strategy_StrategyMutationStrategyUpdateArgs = {
  input: Strategy_StrategyUpdateInput
}

export type Strategy_StrategyParameter = {
  __typename?: 'Strategy_StrategyParameter'
  key: Scalars['String']
  value: Scalars['String']
}

export type Strategy_StrategyParameterDesc = {
  __typename?: 'Strategy_StrategyParameterDesc'
  defaultValue: Scalars['String']
  description: Scalars['String']
  key: Scalars['String']
  maxValue: Scalars['String']
  minValue: Scalars['String']
  name: Scalars['String']
  type: Strategy_StrategyParameterType
  updatable: Scalars['Boolean']
}

export type Strategy_StrategyParameterInput = {
  key: Scalars['String']
  value: Scalars['String']
}

export enum Strategy_StrategyParameterType {
  StrategyParameterTypeBool = 'StrategyParameterTypeBool',
  StrategyParameterTypeNum = 'StrategyParameterTypeNum',
  StrategyParameterTypeStr = 'StrategyParameterTypeStr',
}

export type Strategy_StrategyPerformance = {
  __typename?: 'Strategy_StrategyPerformance'
  /** in seconds */
  duration: Scalars['Int']
  /** 投入本金, 放貸是最大額度 */
  initialValue: Scalars['Strategy_Number']
  /** 收益績效，以幣種計算 */
  performances: Array<Strategy_Performance>
}

export type Strategy_StrategyRetryInput = {
  id: Scalars['ID']
}

export type Strategy_StrategyStartInput = {
  exAPIKeyIDs: Array<Scalars['ID']>
  parameters: Array<Strategy_StrategyParameterInput>
  sid: Scalars['String']
}

/** add other strategy state type in future */
export type Strategy_StrategyState = Strategy_ArbitrageState | Strategy_LendingState

export type Strategy_StrategyStopInput = {
  /** id for running strategy */
  id: Scalars['ID']
  /** 是否強制停止 */
  isForce?: InputMaybe<Scalars['Boolean']>
}

export type Strategy_StrategyUpdateInput = {
  /** id for running strategy */
  id: Scalars['ID']
  parameters: Array<Strategy_StrategyParameterInput>
}

export enum Strategy_Token {
  Crv = 'CRV',
  Cvx = 'CVX',
  Eth = 'ETH',
  Mim3Crv = 'MIM3CRV',
  Usdt = 'USDT',
  Weth = 'WETH',
}

export type Strategy_User = {
  __typename?: 'Strategy_User'
  actionLogs: Array<Strategy_ActionLog>
  /** 下個月進行中策略 + 負債 = 評估總點數是否足夠使用 */
  estimatePointIsEnough: Scalars['Boolean']
  estimatePointsConsuming: Array<Strategy_EstimatePointsConsumingResult>
  estimateStrategyRequiredAmount: Strategy_EstimateStrategyRequiredAmountResponse
  exchangeBalances: Array<Strategy_Balance>
  /** 出金是否需要 2FA or password */
  exchangeWithdrawalAuthorization?: Maybe<
    Array<Maybe<Strategy_ExchangeWithdrawalAuthorizationResponse>>
  >
  exchangesAssets: Array<Strategy_ExchangeAsset>
  exchangesNetAssetChart: Array<Strategy_ExchangesNetAssetChart>
  externalAPIKeys: Array<Strategy_ExternalApiKey>
  profits: Array<Maybe<Strategy_UserProfit>>
  strategies: Array<Strategy_Strategy>
  workflows: Array<Strategy_Workflow>
}

export type Strategy_UserActionLogsArgs = {
  filter?: InputMaybe<Strategy_ActionLogFilter>
  pagination: PaginationQuery
}

export type Strategy_UserEstimatePointsConsumingArgs = {
  input: Array<Strategy_EstimatePointsConsumingInput>
}

export type Strategy_UserEstimateStrategyRequiredAmountArgs = {
  input: Strategy_StrategyStartInput
}

export type Strategy_UserExchangeBalancesArgs = {
  input: Strategy_ExchangeBalancesInput
}

export type Strategy_UserExchangeWithdrawalAuthorizationArgs = {
  input: Strategy_ExchangeWithdrawalAuthorizationInput
}

export type Strategy_UserExchangesNetAssetChartArgs = {
  input: Strategy_ExchangesNetAssetChartInput
}

export type Strategy_UserExternalApiKeysArgs = {
  filter?: InputMaybe<Strategy_ExternalApiKeyFilter>
}

export type Strategy_UserProfitsArgs = {
  filter?: InputMaybe<Strategy_ProfitInputFilter>
}

export type Strategy_UserStrategiesArgs = {
  filter?: InputMaybe<Strategy_StrategyFilter>
  pagination: PaginationQuery
}

export type Strategy_UserWorkflowsArgs = {
  filter?: InputMaybe<Strategy_WorkflowFilter>
  pagination: PaginationQuery
}

export type Strategy_UserMutation = {
  __typename?: 'Strategy_UserMutation'
  externalAPIKey?: Maybe<Strategy_ExternalApiKeyMutation>
  genericFlow?: Maybe<Strategy_GenericFlowMutation>
  /** 償還所有策略所欠繳的點數 by 策略建立時間 */
  repayStrategyDebt: Scalars['Boolean']
  strategy?: Maybe<Strategy_StrategyMutation>
}

export type Strategy_UserProfit = {
  __typename?: 'Strategy_UserProfit'
  /** 最近14天的紀錄，如果當天沒收益紀錄則補零 */
  dailyProfits: Array<Strategy_DailyProfit>
  /** 策略ID */
  sid: Scalars['String']
  /** 總收益 */
  totalProfits: Array<Strategy_CurrencyAmount>
  /** 策略類型 */
  type: StrategyType
}

export type Strategy_WithdrawalCryptoStartInput = {
  exAPIKeyID: Scalars['ID']
  fromAmount: Scalars['Strategy_Number']
  fromCurrency: Scalars['String']
  fromExchange: Exchange
  fromWallet?: InputMaybe<ExternalWallet>
  password?: InputMaybe<Scalars['String']>
  toCurrency: Currency
  twoFactorCode?: InputMaybe<Scalars['String']>
  viaChain: Chain
  viaCurrency: Currency
}

export type Strategy_WithdrawalFiatStartInput = {
  amount: Scalars['Strategy_Number']
  currency: Currency
  exAPIKeyID: Scalars['ID']
  exchange: Exchange
  password?: InputMaybe<Scalars['String']>
  twoFactorCode?: InputMaybe<Scalars['String']>
}

export type Strategy_WithdrawalRetryInput = {
  password?: InputMaybe<Scalars['String']>
  twoFactorCode?: InputMaybe<Scalars['String']>
  workflowID: Scalars['ID']
}

export type Strategy_Workflow = {
  __typename?: 'Strategy_Workflow'
  actions: Array<Strategy_Action>
  createdAt: Scalars['Strategy_Time']
  id: Scalars['ID']
  index: Scalars['Int']
  status: Strategy_WorkflowStatus
  type: Strategy_WorkflowType
  updatedAt: Scalars['Strategy_Time']
}

export enum Strategy_WorkflowAction {
  WadefiApproveSwapReward = 'WADEFIApproveSwapReward',
  WadefiDeductTxnFee = 'WADEFIDeductTxnFee',
  WadefiDispatchUsdtReward = 'WADEFIDispatchUSDTReward',
  Wadefimim3CrvAddLiquidity = 'WADEFIMIM3CRVAddLiquidity',
  Wadefimim3CrvApproveConvexBooster = 'WADEFIMIM3CRVApproveConvexBooster',
  Wadefimim3CrvApproveCurve3Pool = 'WADEFIMIM3CRVApproveCurve3Pool',
  Wadefimim3CrvClaimReward = 'WADEFIMIM3CRVClaimReward',
  Wadefimim3CrvDepositAll = 'WADEFIMIM3CRVDepositAll',
  Wadefimim3CrvRemoveLiquidity = 'WADEFIMIM3CRVRemoveLiquidity',
  Wadefimim3CrvWithdrawAndUnwrap = 'WADEFIMIM3CRVWithdrawAndUnwrap',
  WadefiRebalanceTxnFeeEstimation = 'WADEFIRebalanceTxnFeeEstimation',
  WadefiStakePool = 'WADEFIStakePool',
  WadefiSwapEthToUsdt = 'WADEFISwapETHToUSDT',
  WadefiSwapReward = 'WADEFISwapReward',
  WadefiTransferUserLpBalanceToPool = 'WADEFITransferUserLPBalanceToPool',
  WadefiusdtApproveCurve3Pool = 'WADEFIUSDTApproveCurve3Pool',
  WadefiUnStakePool = 'WADEFIUnStakePool',
  WadefiUpdatePoolBalanceAfterAddLiquidity = 'WADEFIUpdatePoolBalanceAfterAddLiquidity',
  WadefiUpdatePoolBalanceAfterClaimReward = 'WADEFIUpdatePoolBalanceAfterClaimReward',
  WadefiUpdatePoolBalanceAfterRmLiquidity = 'WADEFIUpdatePoolBalanceAfterRmLiquidity',
  WadefiUpdatePoolBalanceAfterSwapEthToUsdt = 'WADEFIUpdatePoolBalanceAfterSwapETHToUSDT',
  WadefiUpdatePoolBalanceAfterSwapReward = 'WADEFIUpdatePoolBalanceAfterSwapReward',
  WadefiUpdateUserRecordStatus = 'WADEFIUpdateUserRecordStatus',
  WadefiWatchTxnStatus = 'WADEFIWatchTxnStatus',
  WaExternalDepositCheck = 'WAExternalDepositCheck',
  WaExternalSwap = 'WAExternalSwap',
  WaExternalWalletTransfer = 'WAExternalWalletTransfer',
  WaExternalWithdrawal = 'WAExternalWithdrawal',
  WaInternalDepositCheck = 'WAInternalDepositCheck',
  WaInternalTrade = 'WAInternalTrade',
  WaInternalWithdrawal = 'WAInternalWithdrawal',
  WaStrategyBatchStart = 'WAStrategyBatchStart',
  WaStrategyBatchStop = 'WAStrategyBatchStop',
  WaStrategyBatchUpdate = 'WAStrategyBatchUpdate',
  WaStrategyStart = 'WAStrategyStart',
  WaStrategyStop = 'WAStrategyStop',
  WaStrategyUpdate = 'WAStrategyUpdate',
  WaTransferFromDefiWalletPostProcessing = 'WATransferFromDEFIWalletPostProcessing',
  WaTransferFromDefiWalletPreProcessing = 'WATransferFromDEFIWalletPreProcessing',
  WaTransferFromDefiWalletProcessing = 'WATransferFromDEFIWalletProcessing',
  WaTransferToDefiWalletPostProcessing = 'WATransferToDEFIWalletPostProcessing',
  WaTransferToDefiWalletPreProcessing = 'WATransferToDEFIWalletPreProcessing',
  WaTransferToDefiWalletProcessing = 'WATransferToDEFIWalletProcessing',
  WaUnknown = 'WAUnknown',
}

export enum Strategy_WorkflowActionStatus {
  WaStatusFailed = 'WAStatusFailed',
  WaStatusProcessing = 'WAStatusProcessing',
  WaStatusSuccess = 'WAStatusSuccess',
  WaStatusUnknown = 'WAStatusUnknown',
  WaStatusWaiting = 'WAStatusWaiting',
}

export type Strategy_WorkflowFilter = {
  id?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Array<InputMaybe<Strategy_WorkflowStatus>>>
  type?: InputMaybe<Array<InputMaybe<Strategy_WorkflowType>>>
}

export enum Strategy_WorkflowStatus {
  WorkflowStatusCancelled = 'WorkflowStatusCancelled',
  WorkflowStatusFailed = 'WorkflowStatusFailed',
  WorkflowStatusProcessing = 'WorkflowStatusProcessing',
  WorkflowStatusSuccess = 'WorkflowStatusSuccess',
  WorkflowStatusUnknown = 'WorkflowStatusUnknown',
}

export enum Strategy_WorkflowType {
  WorkflowTypeDefiNormal = 'WorkflowTypeDEFINormal',
  WorkflowTypeDefiRebalance = 'WorkflowTypeDEFIRebalance',
  WorkflowTypeDefiTest = 'WorkflowTypeDEFITest',
  WorkflowTypeDefiWithdrawExpress = 'WorkflowTypeDEFIWithdrawExpress',
  WorkflowTypeStrategyStart = 'WorkflowTypeStrategyStart',
  WorkflowTypeStrategyStop = 'WorkflowTypeStrategyStop',
  WorkflowTypeStrategyUpdate = 'WorkflowTypeStrategyUpdate',
  WorkflowTypeUnknown = 'WorkflowTypeUnknown',
  WorkflowTypeWithdrawFlowCrypto = 'WorkflowTypeWithdrawFlowCrypto',
  WorkflowTypeWithdrawFlowFiat = 'WorkflowTypeWithdrawFlowFiat',
}

export type Strategy_YieldRecord = {
  __typename?: 'Strategy_YieldRecord'
  amount: Scalars['Strategy_Number']
  currency: Scalars['String']
  id: Scalars['ID']
  rate: Scalars['Strategy_Number']
  time: Scalars['Strategy_Time']
  type: Scalars['String']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type Sys = {
  __typename?: 'Sys'
  environmentId: Scalars['String']
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedVersion?: Maybe<Scalars['Int']>
  spaceId: Scalars['String']
}

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']>
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>
  publishedAt_not?: InputMaybe<Scalars['DateTime']>
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedVersion?: InputMaybe<Scalars['Float']>
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>
  publishedVersion_gt?: InputMaybe<Scalars['Float']>
  publishedVersion_gte?: InputMaybe<Scalars['Float']>
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  publishedVersion_lt?: InputMaybe<Scalars['Float']>
  publishedVersion_lte?: InputMaybe<Scalars['Float']>
  publishedVersion_not?: InputMaybe<Scalars['Float']>
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

export type TokenBalance = {
  __typename?: 'TokenBalance'
  address: Scalars['String']
  amount: Scalars['String']
  amountReadable: Scalars['String']
  chain: EvmChain
  decimals: Scalars['Int']
  id: Scalars['String']
  symbol: Scalars['String']
}

export type TokenDisplayInfo = {
  __typename?: 'TokenDisplayInfo'
  address: Scalars['String']
  chain: EvmChain
  id: Scalars['String']
  imageURL: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type ToolInfo = StrategyInfo | UniswapToolInfo

export type TotalProfits = {
  __typename?: 'TotalProfits'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
}

export type Trade = {
  __typename?: 'Trade'
  createdAt: Scalars['Time']
  fee: Scalars['Number']
  feeCurrency: Currency
  feeRate: Scalars['Number']
  id: Scalars['ID']
  market: Market
  price: Scalars['Number']
  quoteBaseCurrency: Currency
  quoteOrderCurrency: Currency
  side: OrderSide
  size: Scalars['Number']
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type TradeFilter = {
  endDate?: InputMaybe<Scalars['Time']>
  id?: InputMaybe<Scalars['ID']>
  markets?: InputMaybe<Array<Market>>
  side?: InputMaybe<OrderSide>
  startDate?: InputMaybe<Scalars['Time']>
}

export type TradeResponse = BaseResponse & {
  __typename?: 'TradeResponse'
  data?: Maybe<Array<Trade>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type Trading = {
  __typename?: 'Trading'
  history: TradeResponse
  report: TradingReportResponse
}

export type TradingHistoryArgs = {
  filter?: InputMaybe<TradeFilter>
  pagination: PaginationQuery
}

export type TradingReportArgs = {
  input: TradeFilter
}

export type TradingConfirmQuotationResponse = BaseResponse & {
  __typename?: 'TradingConfirmQuotationResponse'
  result: Scalars['Boolean']
}

export type TradingMutation = {
  __typename?: 'TradingMutation'
  confirmQuotation: TradingConfirmQuotationResponse
  getQuotation: TradingQuotationResponse
}

export type TradingMutationConfirmQuotationArgs = {
  clientID?: InputMaybe<Scalars['ID']>
  quotationId: Scalars['ID']
}

export type TradingMutationGetQuotationArgs = {
  input: TradingQuotationInput
}

export type TradingQuotationInput = {
  /** input base amount or quote amount */
  baseAmount?: InputMaybe<Scalars['Number']>
  market: Market
  quoteAmount?: InputMaybe<Scalars['Number']>
  side: OrderSide
}

export type TradingQuotationResponse = BaseResponse & {
  __typename?: 'TradingQuotationResponse'
  data?: Maybe<Quotation>
  result: Scalars['Boolean']
}

export type TradingReportResponse = BaseResponse & {
  __typename?: 'TradingReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type UniswapOnchainPositionAmountInfo = {
  __typename?: 'UniswapOnchainPositionAmountInfo'
  /** this data is from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Amount: Scalars['String']
  token0AmountUsdValue: Scalars['String']
  /** this data is from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Amount: Scalars['String']
  token1AmountUsdValue: Scalars['String']
}

export type UniswapPoolInfo = {
  __typename?: 'UniswapPoolInfo'
  averageTradingVolumeUSD?: Maybe<Scalars['String']>
  chain: EvmChain
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  collectedFeesUSD: Scalars['String']
  createdAtBlockNumber: Scalars['String']
  createdAtTimestamp: Scalars['String']
  feeGrowthGlobal0X128: Scalars['String']
  feeGrowthGlobal1X128: Scalars['String']
  feeTier: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  /** this part is from smart contract developed by reyield */
  isPoolActiveForReyield?: Maybe<Scalars['Boolean']>
  liquidity: Scalars['String']
  liquidityProviderCount: Scalars['String']
  observationIndex: Scalars['String']
  positions?: Maybe<Array<UniswapPoolPosition>>
  sqrtPrice: Scalars['String']
  tick?: Maybe<Scalars['String']>
  ticks?: Maybe<Array<UniswapPoolTick>>
  token0?: Maybe<UniswapToolToken>
  token0Price: Scalars['String']
  token1?: Maybe<UniswapToolToken>
  token1Price: Scalars['String']
  totalValueLockedETH: Scalars['String']
  totalValueLockedToken0: Scalars['String']
  totalValueLockedToken1: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volumeToken0: Scalars['String']
  volumeToken1: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapPoolInfoAverageTradingVolumeUsdArgs = {
  numberOfDays: Scalars['Int']
}

export type UniswapPoolPosition = {
  __typename?: 'UniswapPoolPosition'
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  depositedToken0: Scalars['String']
  depositedToken1: Scalars['String']
  feeGrowthInside0LastX128: Scalars['String']
  feeGrowthInside1LastX128: Scalars['String']
  id: Scalars['ID']
  liquidity: Scalars['String']
  tickLowerFeeGrowthOutside0X128: Scalars['String']
  tickLowerFeeGrowthOutside1X128: Scalars['String']
  tickLowerTickIdx: Scalars['String']
  tickUpperFeeGrowthOutside0X128: Scalars['String']
  tickUpperFeeGrowthOutside1X128: Scalars['String']
  tickUpperTickIdx: Scalars['String']
  transactionTimestamp: Scalars['String']
}

export type UniswapPoolTick = {
  __typename?: 'UniswapPoolTick'
  id: Scalars['String']
  liquidityNet: Scalars['String']
  price0: Scalars['String']
  price1: Scalars['String']
  tickIdx: Scalars['String']
}

export type UniswapQuote = {
  __typename?: 'UniswapQuote'
  chain: EvmChain
  fromToken: UniswapToolToken
  fromTokenAddress: Scalars['String']
  id: Scalars['String']
  inputAmount: Scalars['String']
  inputReadableAmount: Scalars['String']
  outputAmount: Scalars['String']
  outputReadableAmount: Scalars['String']
  toToken: UniswapToolToken
  toTokenAddress: Scalars['String']
}

export type UniswapQuoteInput = {
  chain: EvmChain
  fromTokenAddress: Scalars['String']
  inputReadableAmount: Scalars['String']
  toTokenAddress: Scalars['String']
}

export type UniswapToolFeeTier = {
  __typename?: 'UniswapToolFeeTier'
  feeForContract: Scalars['String']
  feeForDisplay: Scalars['String']
  id: Scalars['String']
}

export type UniswapToolInfo = {
  __typename?: 'UniswapToolInfo'
  chain: EvmChain
  createdAt: Scalars['String']
  creator?: Maybe<Creator>
  creatorAddress: Scalars['String']
  creatorId: Scalars['String']
  description: Scalars['String']
  expectedAPY: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  performanceFee: Scalars['String']
  poolAddress: Scalars['String']
  /** this data is from thegraph */
  poolInfo?: Maybe<UniswapPoolInfo>
  riskLevel: RiskLevel
  tags?: Maybe<Array<UniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickLowerDiffForContract: Scalars['String']
  tickUpperDiff: Scalars['String']
  tickUpperDiffForContract?: Maybe<Scalars['String']>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
}

export enum UniswapToolPositionStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum UniswapToolStatus {
  Created = 'CREATED',
  Deprecated = 'DEPRECATED',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type UniswapToolTag = {
  __typename?: 'UniswapToolTag'
  id: Scalars['String']
  name: Scalars['String']
  type: UniswapToolTagType
}

export enum UniswapToolTagType {
  Chain = 'chain',
  Custom = 'custom',
  IsOfficial = 'isOfficial',
  Uniswapv3 = 'uniswapv3',
  Unknown = 'unknown',
}

export type UniswapToolToken = {
  __typename?: 'UniswapToolToken'
  chain: EvmChain
  decimals: Scalars['String']
  derivedETH: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  poolCount: Scalars['String']
  priceChart?: Maybe<UniswapToolTokenPriceChart>
  symbol: Scalars['String']
  totalSupply: Scalars['String']
  totalValueLocked: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volume: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapToolTokenPriceChart = {
  __typename?: 'UniswapToolTokenPriceChart'
  currentPriceUSD?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  prices?: Maybe<Array<UniswapToolTokenPriceChartPrice>>
}

export type UniswapToolTokenPriceChartPrice = {
  __typename?: 'UniswapToolTokenPriceChartPrice'
  timestamp: Scalars['String']
  value: Scalars['String']
}

export enum UniswapV3BacktestPeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type UniswapV3BacktestResult = {
  __typename?: 'UniswapV3BacktestResult'
  activeLiquidityPercent: Scalars['Float']
  annualReturn: Scalars['Float']
  compound: Scalars['Boolean']
  createdAt: Scalars['String']
  dailyReturn: Scalars['Float']
  dataPointsCount: Scalars['String']
  days: Scalars['Int']
  diff: Scalars['Float']
  fees?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  impermanentLoss: Scalars['Float']
  investmentAmount: Scalars['Float']
  maxDrawdown?: Maybe<Scalars['Float']>
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
  totalReturn?: Maybe<Scalars['Float']>
  volatility?: Maybe<Scalars['Float']>
}

export type User = {
  __typename?: 'User'
  actionLogs: UserActionLogResponse
  agreements?: Maybe<Array<Scalars['String']>>
  amlInfo?: Maybe<Aml>
  createdAt: Scalars['Time']
  email: Scalars['Email']
  id: Scalars['ID']
  is2FAEnabled: Scalars['Boolean']
  isDisabled: Scalars['Boolean']
  isOldUser: Scalars['Boolean']
  kycInfo?: Maybe<Kyc>
  loginRecords: UserLoginRecordsResponse
  notifications?: Maybe<Notification>
  /** oauthClient: 取得該使用者授權過oauth的client */
  oauthClients: UserOAuthClientResponse
  phone?: Maybe<Scalars['String']>
  point?: Maybe<Point>
  preference: UserPreference
  /** the questionnaires filled out by this user */
  questionnaires: Array<Questionnaire>
  referralCodes: Array<Scalars['String']>
  /** referralCount: 取得該用戶的推薦好友數 */
  referralCount: Scalars['Int']
  /** return referrer name */
  referrer?: Maybe<Scalars['String']>
  roles: Array<UserRole>
  scopes: Array<UserScope>
  special?: Maybe<SpecialUserCollection>
  trading?: Maybe<Trading>
  updatedAt: Scalars['Time']
  vipLevel: VipLevel
  /** voucher: 兌換碼 */
  voucher?: Maybe<Voucher>
  wallet?: Maybe<Wallet>
}

export type UserActionLogsArgs = {
  pagination: PaginationQuery
}

export type UserLoginRecordsArgs = {
  pagination: PaginationQuery
}

export type UserOauthClientsArgs = {
  filter?: InputMaybe<UserOAuthClientFilter>
  pagination?: InputMaybe<PaginationQuery>
}

export type UserQuestionnairesArgs = {
  purpose?: InputMaybe<QuestionnairePurpose>
  version?: InputMaybe<Scalars['String']>
}

export type UserSpecialArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SpecialUserOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SpecialUserFilter_Remote_Rel_Bitginspecial>
}

export type UserAiCompletionRecordsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserActionLog = {
  __typename?: 'UserActionLog'
  action: Scalars['String']
  description: Scalars['String']
  id: Scalars['ID']
  operator: Scalars['String']
  timestamp: Scalars['Time']
}

export type UserActionLogResponse = BaseResponse & {
  __typename?: 'UserActionLogResponse'
  logs: Array<UserActionLog>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type UserAgreeInput = {
  agreementVersion: Scalars['String']
}

export type UserAgreeResponse = BaseResponse & {
  __typename?: 'UserAgreeResponse'
  result: Scalars['Boolean']
}

export type UserBindPhoneConfirmationInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type UserBindPhoneConfirmationResponse = BaseResponse & {
  __typename?: 'UserBindPhoneConfirmationResponse'
  result: Scalars['Boolean']
}

export type UserBindPhoneInput = {
  countryCode: Scalars['String']
  /** E.164 format */
  phone: Scalars['String']
}

export type UserBindPhoneResponse = BaseResponse & {
  __typename?: 'UserBindPhoneResponse'
  id: Scalars['ID']
  result: Scalars['Boolean']
}

export type UserCancelOAuthInput = {
  clientID: Scalars['ID']
}

export type UserCancelOAuthResponse = BaseResponse & {
  __typename?: 'UserCancelOAuthResponse'
  result: Scalars['Boolean']
}

export type UserChangePasswordInput = {
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

export type UserChangePasswordResponse = BaseResponse & {
  __typename?: 'UserChangePasswordResponse'
  result: Scalars['Boolean']
}

export type UserCustomizeReferralInput = {
  referralCode: Scalars['String']
}

export type UserCustomizeReferralResponse = BaseResponse & {
  __typename?: 'UserCustomizeReferralResponse'
  data: UserReferral
  result: Scalars['Boolean']
}

export type UserDeleteBankAccountResponse = BaseResponse & {
  __typename?: 'UserDeleteBankAccountResponse'
  result: Scalars['Boolean']
}

export type UserDeviceTokenInput = {
  /** deviceToken: device token. we can get this value by call provider's api. ex: FCM onRegister function. */
  deviceToken: Scalars['String']
  /** model: mobile's model. ex: iphone14, pixel4...etc */
  model: Scalars['String']
  /** os: mobile os. ex: android, ios...etc */
  os: MobileOs
  /** provider: push notification provider. ex FCM, APNs, SNS...etc */
  provider: NotificationProvider
}

export type UserDeviceTokenResponse = BaseResponse & {
  __typename?: 'UserDeviceTokenResponse'
  result: Scalars['Boolean']
}

export type UserFillReferrerCodeInput = {
  referrerCode: Scalars['String']
}

export type UserFillReferrerCodeResponse = BaseResponse & {
  __typename?: 'UserFillReferrerCodeResponse'
  result: Scalars['Boolean']
}

export type UserForgotPasswordConfirmInput = {
  code: Scalars['String']
  email: Scalars['String']
  newPassword: Scalars['String']
}

export type UserForgotPasswordConfirmResponse = BaseResponse & {
  __typename?: 'UserForgotPasswordConfirmResponse'
  result: Scalars['Boolean']
}

export type UserForgotPasswordInput = {
  email: Scalars['String']
}

export type UserForgotPasswordResponse = BaseResponse & {
  __typename?: 'UserForgotPasswordResponse'
  result: Scalars['Boolean']
}

export type UserInfo = {
  __typename?: 'UserInfo'
  aICompletionRecords?: Maybe<Array<AICompletionRecords>>
  cefiDepositAddress?: Maybe<CefiDepositAddress>
  cefiWallets?: Maybe<Array<CefiWallet>>
  defiTokensBalanceAmount?: Maybe<Array<Maybe<TokenBalance>>>
  defiWallets?: Maybe<Array<DefiWallet>>
  externalAPIKeys?: Maybe<Array<ExternalApiKey>>
  gimCoolPayPaymentInfo?: Maybe<GimCoolPayPaymentInfo>
  id: Scalars['String']
  nftCollections?: Maybe<Array<NftCollections>>
  nftMerkleTreeHexProof?: Maybe<NftMerkleTreeHexProof>
  nftWhitelistInfoByPhrases?: Maybe<Array<NftWhitelistInfoByPhrase>>
  ownedToolsSetting?: Maybe<Array<UserUniswapToolSettingInfo>>
  reyieldLicense?: Maybe<ReyieldLicense>
  strategies?: Maybe<Array<UserStrategyInfo>>
  strategyPerformance?: Maybe<UserStrategyPerformance>
  strategyProfit?: Maybe<UserStrategyProfit>
  strategyProgress?: Maybe<StrategyProgress>
  strategyRecords?: Maybe<Array<UserStrategyRecord>>
  tools?: Maybe<Array<UserToolInfo>>
}

export type UserInfoAiCompletionRecordsArgs = {
  filter?: InputMaybe<UserAiCompletionRecordsFilterInput>
}

export type UserInfoCefiDepositAddressArgs = {
  filter?: InputMaybe<CefiDepositAddressInput>
}

export type UserInfoCefiWalletsArgs = {
  filter?: InputMaybe<CefiWalletFilterInput>
}

export type UserInfoDefiTokensBalanceAmountArgs = {
  input: DefiTokensBalanceAmountInput
}

export type UserInfoDefiWalletsArgs = {
  filter?: InputMaybe<DefiWalletsFilterInput>
}

export type UserInfoExternalApiKeysArgs = {
  filter?: InputMaybe<ExternalApiKeysFilter>
}

export type UserInfoGimCoolPayPaymentInfoArgs = {
  input?: InputMaybe<GimCoolPayPaymentInfoInput>
}

export type UserInfoStrategiesArgs = {
  filter?: InputMaybe<UserStrategyFilterInput>
}

export type UserInfoStrategyPerformanceArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyProfitArgs = {
  filter?: InputMaybe<UserStrategyProfitFilterInput>
}

export type UserInfoStrategyProgressArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyRecordsArgs = {
  filter?: InputMaybe<UserStrategyRecordsFilterInput>
}

export type UserLoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type UserLoginRecord = {
  __typename?: 'UserLoginRecord'
  browser: Scalars['String']
  device: Scalars['String']
  ipAddress: Scalars['String']
  ipCountry: Scalars['String']
  os: Scalars['String']
  timestamp: Scalars['Time']
  userAgent: Scalars['String']
}

export type UserLoginRecordsResponse = BaseResponse & {
  __typename?: 'UserLoginRecordsResponse'
  meta: MetaPagination
  records: Array<UserLoginRecord>
  result: Scalars['Boolean']
}

export type UserLoginResponse = BaseResponse & {
  __typename?: 'UserLoginResponse'
  data?: Maybe<LoginResponse>
  result: Scalars['Boolean']
}

export type UserMutation = {
  __typename?: 'UserMutation'
  userAgree: UserAgreeResponse
  userBindPhone: UserBindPhoneResponse
  userBindPhoneConfirmation: UserBindPhoneConfirmationResponse
  userCancelOAuth: UserCancelOAuthResponse
  userChangePassword: UserChangePasswordResponse
  userCustomizeReferral: UserCustomizeReferralResponse
  userDeleteBankAccount: UserDeleteBankAccountResponse
  userFillReferrerCode: UserFillReferrerCodeResponse
  userForgotPassword: UserForgotPasswordResponse
  userForgotPasswordConfirm: UserForgotPasswordConfirmResponse
  userLogin: UserLoginResponse
  userRefreshToken: UserRefreshTokenResponse
  userRegister: UserRegisterResponse
  userRegisterConfirm: UserRegisterConfirmResponse
  /**
   * userRegisterDeviceToken: record user's device token. plz call this api every time when:
   * - login success
   * - app launch
   */
  userRegisterDeviceToken: UserDeviceTokenResponse
  userRequestAccountDisable: UserRequestAccountDisableResponse
  userResendForgotPassword: UserResendForgotPasswordResponse
  userResendRegister: UserResendRegisterResponse
  userSetPreference: UserPreferenceResponse
  userSubmitQuestionnaire: Questionnaire
}

export type UserMutationUserAgreeArgs = {
  input: UserAgreeInput
}

export type UserMutationUserBindPhoneArgs = {
  input: UserBindPhoneInput
}

export type UserMutationUserBindPhoneConfirmationArgs = {
  input: UserBindPhoneConfirmationInput
}

export type UserMutationUserCancelOAuthArgs = {
  input: UserCancelOAuthInput
}

export type UserMutationUserChangePasswordArgs = {
  input: UserChangePasswordInput
}

export type UserMutationUserCustomizeReferralArgs = {
  input: UserCustomizeReferralInput
}

export type UserMutationUserFillReferrerCodeArgs = {
  input: UserFillReferrerCodeInput
}

export type UserMutationUserForgotPasswordArgs = {
  input: UserForgotPasswordInput
}

export type UserMutationUserForgotPasswordConfirmArgs = {
  input: UserForgotPasswordConfirmInput
}

export type UserMutationUserLoginArgs = {
  input: UserLoginInput
}

export type UserMutationUserRefreshTokenArgs = {
  input: UserRefreshTokenInput
}

export type UserMutationUserRegisterArgs = {
  input: UserRegisterInput
}

export type UserMutationUserRegisterConfirmArgs = {
  input: UserRegisterConfirmInput
}

export type UserMutationUserRegisterDeviceTokenArgs = {
  input: UserDeviceTokenInput
}

export type UserMutationUserResendForgotPasswordArgs = {
  input: UserResendForgotPasswordInput
}

export type UserMutationUserResendRegisterArgs = {
  input: UserResendRegisterInput
}

export type UserMutationUserSetPreferenceArgs = {
  input?: InputMaybe<UserSetPreferenceInput>
}

export type UserMutationUserSubmitQuestionnaireArgs = {
  input: QuestionnaireInput
}

export type UserOAuthClient = {
  __typename?: 'UserOAuthClient'
  clientID: Scalars['ID']
  isAuthorized: Scalars['Boolean']
  name: Scalars['String']
  updatedAt: Scalars['Time']
}

export type UserOAuthClientFilter = {
  isAuthorized?: InputMaybe<Scalars['Boolean']>
}

export type UserOAuthClientResponse = BaseResponse & {
  __typename?: 'UserOAuthClientResponse'
  clients: Array<UserOAuthClient>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type UserPreference = {
  __typename?: 'UserPreference'
  currency?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
}

export type UserPreferenceResponse = BaseResponse & {
  __typename?: 'UserPreferenceResponse'
  data?: Maybe<UserPreference>
  result: Scalars['Boolean']
}

export type UserReferral = {
  __typename?: 'UserReferral'
  code?: Maybe<Scalars['String']>
}

export type UserRefreshTokenInput = {
  email: Scalars['String']
  refreshToken: Scalars['String']
}

export type UserRefreshTokenResponse = BaseResponse & {
  __typename?: 'UserRefreshTokenResponse'
  data?: Maybe<LoginInfo>
  result: Scalars['Boolean']
}

export type UserRegisterConfirmInput = {
  code: Scalars['String']
  email: Scalars['String']
}

export type UserRegisterConfirmResponse = BaseResponse & {
  __typename?: 'UserRegisterConfirmResponse'
  result: Scalars['Boolean']
}

export type UserRegisterInput = {
  email: Scalars['Email']
  password: Scalars['String']
  referralCode?: InputMaybe<Scalars['String']>
}

export type UserRegisterResponse = BaseResponse & {
  __typename?: 'UserRegisterResponse'
  result: Scalars['Boolean']
}

export type UserRequestAccountDisableResponse = BaseResponse & {
  __typename?: 'UserRequestAccountDisableResponse'
  result: Scalars['Boolean']
}

export type UserResendForgotPasswordInput = {
  email: Scalars['String']
}

export type UserResendForgotPasswordResponse = BaseResponse & {
  __typename?: 'UserResendForgotPasswordResponse'
  result: Scalars['Boolean']
}

export type UserResendRegisterInput = {
  email: Scalars['String']
}

export type UserResendRegisterResponse = BaseResponse & {
  __typename?: 'UserResendRegisterResponse'
  result: Scalars['Boolean']
}

export enum UserRole {
  RoleAccounting = 'RoleAccounting',
  RoleCsManager = 'RoleCSManager',
  RoleCsMember = 'RoleCSMember',
  RoleCompliance = 'RoleCompliance',
  RoleDeveloper = 'RoleDeveloper',
  RoleMarketing = 'RoleMarketing',
  RoleRiskControl = 'RoleRiskControl',
  RoleSuperAdmin = 'RoleSuperAdmin',
}

export enum UserScope {
  ScopePublic = 'ScopePublic',
  ScopeUserAccountRead = 'ScopeUserAccountRead',
  ScopeUserAccountWrite = 'ScopeUserAccountWrite',
  ScopeUserBalanceRead = 'ScopeUserBalanceRead',
  ScopeUserCryptoDeposit = 'ScopeUserCryptoDeposit',
  ScopeUserCryptoInternalTransfer = 'ScopeUserCryptoInternalTransfer',
  ScopeUserCryptoWithdrawal = 'ScopeUserCryptoWithdrawal',
  ScopeUserFiatDeposit = 'ScopeUserFiatDeposit',
  ScopeUserFiatWithdrawal = 'ScopeUserFiatWithdrawal',
  ScopeUserKycRead = 'ScopeUserKYCRead',
  ScopeUserKycWrite = 'ScopeUserKYCWrite',
  ScopeUserLedgerRead = 'ScopeUserLedgerRead',
  ScopeUserPointWrite = 'ScopeUserPointWrite',
  ScopeUserTradeRead = 'ScopeUserTradeRead',
  ScopeUserTradeWrite = 'ScopeUserTradeWrite',
  ScopeUserWalletRead = 'ScopeUserWalletRead',
  ScopeUserWalletWrite = 'ScopeUserWalletWrite',
}

export type UserSetPreferenceInput = {
  currency?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
}

export type UserStrategyFilterInput = {
  sid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Array<InputMaybe<StrategyStatus>>>
  strategyID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UserStrategyInfo = {
  __typename?: 'UserStrategyInfo'
  ceFiStatus: StrategyStatus
  closedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['String']
  infos?: Maybe<StrategyInfos>
  parameters?: Maybe<Array<UserStrategyParameters>>
  performance?: Maybe<UserStrategyPerformance>
  profits?: Maybe<UserStrategyProfit>
  sid: Scalars['String']
  updatedAt: Scalars['String']
}

export type UserStrategyParameters = {
  __typename?: 'UserStrategyParameters'
  key: Scalars['String']
  value: Scalars['String']
}

export type UserStrategyPerformance = {
  __typename?: 'UserStrategyPerformance'
  duration?: Maybe<Scalars['Int']>
  initialValue?: Maybe<Scalars['String']>
  performances?: Maybe<Array<Maybe<StrategyPerformance>>>
}

export type UserStrategyProfit = {
  __typename?: 'UserStrategyProfit'
  sid?: Maybe<Scalars['String']>
  totalProfit?: Maybe<Array<Maybe<TotalProfits>>>
  type?: Maybe<StrategyType>
}

export type UserStrategyProfitFilterInput = {
  sid: Array<InputMaybe<Scalars['String']>>
  type?: InputMaybe<Array<InputMaybe<StrategyType>>>
}

export type UserStrategyRecord = {
  __typename?: 'UserStrategyRecord'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<RecordsType>
}

export type UserStrategyRecordsFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  strategyID: Scalars['String']
  type?: InputMaybe<RecordsType>
}

export type UserToolInfo = UserStrategyInfo | UserUniswapPositionInfo

export type UserUniswapOnchainPositionInfo = {
  __typename?: 'UserUniswapOnchainPositionInfo'
  apy: Scalars['String']
  chain: EvmChain
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalInvestAmountUsdValue?: Maybe<Scalars['String']>
}

export type UserUniswapPositionInfo = {
  __typename?: 'UserUniswapPositionInfo'
  apy: Scalars['String']
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  createdAt: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  /** backend will cache the data for n minutes */
  onchainPositionInfo?: Maybe<UserUniswapOnchainPositionInfo>
  poolAddress: Scalars['String']
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  progresses?: Maybe<Array<UserUniswapv3PositionProgress>>
  publicToolInfo?: Maybe<UniswapToolInfo>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  status: UniswapToolPositionStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  toolDescription: Scalars['String']
  toolId: Scalars['String']
  toolIsOfficial: Scalars['Boolean']
  toolName: Scalars['String']
  toolPerformanceFeeRatio?: Maybe<Scalars['String']>
  /** if the user is the creator of the tool, then the toolSetting will be returned */
  toolSetting?: Maybe<UserUniswapToolSettingInfo>
  totalInvestAmountUsdValue: Scalars['String']
  updatedAt: Scalars['String']
  userAddress: Scalars['String']
}

export type UserUniswapPositionInfoOnchainPositionInfoArgs = {
  withoutCache?: InputMaybe<Scalars['Boolean']>
}

export type UserUniswapToolOutOfRangeInfo = {
  __typename?: 'UserUniswapToolOutOfRangeInfo'
  earnSpendRate: Scalars['String']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type UserUniswapToolSettingInfo = {
  __typename?: 'UserUniswapToolSettingInfo'
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  name: Scalars['String']
  outOfLowerRange: UserUniswapToolOutOfRangeInfo
  outOfUpperRange: UserUniswapToolOutOfRangeInfo
  performanceFeeRatio?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  /** User will have only one position for each tool */
  position?: Maybe<UserUniswapPositionInfo>
  publicToolInfo?: Maybe<UniswapToolInfo>
  status: UniswapToolStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalUserUsed: Scalars['Int']
  updatedAt?: Maybe<Scalars['String']>
}

export type UserUniswapv3PositionProgress = {
  __typename?: 'UserUniswapv3PositionProgress'
  createdAt: Scalars['String']
  id: Scalars['String']
  index: Scalars['Int']
  status: UserUniswapv3PositionProgressStatus
  steps: Array<UserUniswapv3PositionProgressStep>
  type: UserUniswapv3PositionProgressType
  updatedAt: Scalars['String']
}

export enum UserUniswapv3PositionProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type UserUniswapv3PositionProgressStep = {
  __typename?: 'UserUniswapv3PositionProgressStep'
  status: UserUniswapv3PositionProgressStepStatus
  type: UserUniswapv3PositionProgressStepType
}

export enum UserUniswapv3PositionProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum UserUniswapv3PositionProgressStepType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export enum UserUniswapv3PositionProgressType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export enum VipLevel {
  VipLevel0 = 'VIPLevel0',
  VipLevel1 = 'VIPLevel1',
  VipLevel2 = 'VIPLevel2',
  VipLevel3 = 'VIPLevel3',
  VipLevel4 = 'VIPLevel4',
  VipLevel5 = 'VIPLevel5',
}

export type VersionInfo = {
  __typename?: 'VersionInfo'
  buildTime: Scalars['String']
  goVersion: Scalars['String']
  pbVersion: Scalars['String']
  revision: Scalars['String']
  version: Scalars['String']
}

export type Voucher = {
  __typename?: 'Voucher'
  /** isVoucherUsed: 確認該用戶是否已使用該兌換碼 */
  isVoucherUsed: Scalars['Boolean']
}

export type VoucherIsVoucherUsedArgs = {
  code: Scalars['String']
}

export type VoucherMutation = {
  __typename?: 'VoucherMutation'
  /** voucherUse: 使用兌換碼 */
  voucherUse: VoucherUseResponse
}

export type VoucherMutationVoucherUseArgs = {
  input: VoucherUseInput
}

export type VoucherRecord = {
  __typename?: 'VoucherRecord'
  /** code: 兌換碼，最大長度 8，unique */
  code: Scalars['String']
  /** description: 兌換碼說明，最大長度 100 */
  description?: Maybe<Scalars['String']>
  /** expiredDate: 兌換碼到期日期，空代表無限制 */
  expiredDate?: Maybe<Scalars['Time']>
  /** isDisabled: 兌換碼是否被停用，true:停用 false:可用 */
  isDisabled: Scalars['Boolean']
  /** maxUsageTimes: 兌換碼最大可用次數 */
  maxUsageTimes: Scalars['Int']
  /** name: 兌換碼名稱，最大長度 20 */
  name: Scalars['String']
  /** point: 兌換點數 */
  point: Scalars['Int']
  /** type: 兌換碼類型，最大長度 8 */
  type: Scalars['String']
  /** usedTimes: 兌換碼已使用次數 */
  usedTimes: Scalars['Int']
}

export type VoucherUseInput = {
  /** code: 兌換碼 */
  code: Scalars['String']
}

export type VoucherUseResponse = BaseResponse & {
  __typename?: 'VoucherUseResponse'
  point: Scalars['Int']
  result: Scalars['Boolean']
  type: Scalars['String']
}

export type Wallet = {
  __typename?: 'Wallet'
  balances?: Maybe<Array<Balance>>
  commonAddresses: CommonAddressResponse
  depositCryptoInfo?: Maybe<Array<Address>>
  depositFiatInfo: FiatDepositInfo
  deposits: DepositResponse
  faasPayments: FaasPaymentResponse
  isInternalTransfer: Scalars['Boolean']
  ledgers: LedgerResponse
  userID: Scalars['ID']
  withdrawInfo: WithdrawInfo
  withdrawals: WithdrawalResponse
}

export type WalletBalancesArgs = {
  filter?: InputMaybe<CurrencyFilter>
}

export type WalletCommonAddressesArgs = {
  filter?: InputMaybe<CommonAddressFilter>
  pagination: PaginationQuery
}

export type WalletDepositCryptoInfoArgs = {
  filter: ChainFilter
}

export type WalletDepositsArgs = {
  filter?: InputMaybe<DepositFilter>
  pagination: PaginationQuery
}

export type WalletFaasPaymentsArgs = {
  filter?: InputMaybe<FaasPaymentFilter>
  pagination: PaginationQuery
}

export type WalletIsInternalTransferArgs = {
  address: Scalars['String']
}

export type WalletLedgersArgs = {
  filter?: InputMaybe<LedgerFilter>
  pagination: PaginationQuery
}

export type WalletWithdrawInfoArgs = {
  filter: WithdrawInfoFilter
}

export type WalletWithdrawalsArgs = {
  filter?: InputMaybe<WithdrawalFilter>
  pagination: PaginationQuery
}

export type WalletAddressInput = {
  address: Scalars['String']
  chain: Chain
  currency: Currency
}

export type WalletBalances = {
  __typename?: 'WalletBalances'
  currencyBalances: Array<CurrencyBalances>
  wallet: Scalars['String']
}

export type WalletCreateCommonAddress = {
  address: WalletAddressInput
  name: Scalars['String']
}

export type WalletCreateCommonAddressResponse = BaseResponse & {
  __typename?: 'WalletCreateCommonAddressResponse'
  result: Scalars['Boolean']
}

export type WalletCreateCryptoAddressInput = {
  chain: Chain
  currency: Currency
}

export type WalletCreateCryptoAddressResponse = BaseResponse & {
  __typename?: 'WalletCreateCryptoAddressResponse'
  data?: Maybe<Address>
  result: Scalars['Boolean']
}

export type WalletCryptoWithdrawInput = {
  address: WalletAddressInput
  amount: Scalars['Number']
  deduct: Scalars['Boolean']
}

export type WalletCryptoWithdrawResponse = BaseResponse & {
  __typename?: 'WalletCryptoWithdrawResponse'
  data?: Maybe<WithdrawCryptoResponse>
  result: Scalars['Boolean']
}

export type WalletDeleteCommonAddress = {
  id: Scalars['ID']
}

export type WalletDeleteCommonAddressResponse = BaseResponse & {
  __typename?: 'WalletDeleteCommonAddressResponse'
  result: Scalars['Boolean']
}

export type WalletDepositResponse = BaseResponse & {
  __typename?: 'WalletDepositResponse'
  data?: Maybe<Array<DepositResponse>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type WalletFaasPaymentConfirmInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type WalletFaasPaymentConfirmResponse = BaseResponse & {
  __typename?: 'WalletFAASPaymentConfirmResponse'
  data: FaasPayment
  result: Scalars['Boolean']
}

export type WalletFiatWithdrawInput = {
  amount: Scalars['Number']
}

export type WalletFiatWithdrawResponse = BaseResponse & {
  __typename?: 'WalletFiatWithdrawResponse'
  data?: Maybe<WithdrawFiatResponse>
  result: Scalars['Boolean']
}

export type WalletMutation = {
  __typename?: 'WalletMutation'
  walletCreateCommonAddress: WalletCreateCommonAddressResponse
  walletCreateCryptoAddress: WalletCreateCryptoAddressResponse
  walletCryptoWithdraw: WalletCryptoWithdrawResponse
  walletDeleteCommonAddress: WalletDeleteCommonAddressResponse
  walletFAASPaymentConfirm: WalletFaasPaymentConfirmResponse
  walletFiatWithdraw: WalletFiatWithdrawResponse
  walletWithdrawConfirm: WalletWithdrawConfirmResponse
}

export type WalletMutationWalletCreateCommonAddressArgs = {
  input: WalletCreateCommonAddress
}

export type WalletMutationWalletCreateCryptoAddressArgs = {
  input: WalletCreateCryptoAddressInput
}

export type WalletMutationWalletCryptoWithdrawArgs = {
  input: WalletCryptoWithdrawInput
}

export type WalletMutationWalletDeleteCommonAddressArgs = {
  input: WalletDeleteCommonAddress
}

export type WalletMutationWalletFaasPaymentConfirmArgs = {
  input: WalletFaasPaymentConfirmInput
}

export type WalletMutationWalletFiatWithdrawArgs = {
  input: WalletFiatWithdrawInput
}

export type WalletMutationWalletWithdrawConfirmArgs = {
  input: WalletWithdrawConfirmInput
}

export enum WalletOperationType {
  WalletOperationTypeCryptoDeposit = 'WalletOperationTypeCryptoDeposit',
  WalletOperationTypeCryptoWithdraw = 'WalletOperationTypeCryptoWithdraw',
  WalletOperationTypeFiatDeposit = 'WalletOperationTypeFiatDeposit',
  WalletOperationTypeFiatWithdraw = 'WalletOperationTypeFiatWithdraw',
  WalletOperationTypeInternalTransferIn = 'WalletOperationTypeInternalTransferIn',
  WalletOperationTypeInternalTransferOut = 'WalletOperationTypeInternalTransferOut',
  WalletOperationTypeTrade = 'WalletOperationTypeTrade',
}

export enum WalletType {
  BfxExchange = 'bfx_exchange',
  BfxFunding = 'bfx_funding',
  BfxMargin = 'bfx_margin',
  Spot = 'spot',
  Unknown = 'unknown',
}

export type WalletWithdrawConfirmInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type WalletWithdrawConfirmResponse = BaseResponse & {
  __typename?: 'WalletWithdrawConfirmResponse'
  data: Withdrawal
  result: Scalars['Boolean']
}

export type WalletWithdrawalResponse = BaseResponse & {
  __typename?: 'WalletWithdrawalResponse'
  data?: Maybe<Array<WithdrawalResponse>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type WithdrawCryptoResponse = CryptoWithdrawal | InternalTransferOut | Response2Fa

export type WithdrawFiatResponse = FiatWithdrawal | Response2Fa

export type WithdrawInfo = {
  __typename?: 'WithdrawInfo'
  dailyAccumulated: Scalars['Number']
  dailyMaxLimit: Scalars['Number']
  fee: Scalars['Number']
  maxLimit: Scalars['Number']
  minLimit: Scalars['Number']
  monthlyAccumulated: Scalars['Number']
  monthlyMaxLimit: Scalars['Number']
}

export type WithdrawInfoFilter = {
  chain?: InputMaybe<Chain>
  currency: Currency
}

export type Withdrawal = CryptoWithdrawal | FiatWithdrawal | InternalTransferOut

export type WithdrawalFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<WithdrawalStatus>>
  type?: InputMaybe<Array<WithdrawalType>>
  withdrawalID?: InputMaybe<Scalars['ID']>
}

export type WithdrawalResponse = BaseResponse & {
  __typename?: 'WithdrawalResponse'
  data?: Maybe<Array<Withdrawal>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export enum WithdrawalStatus {
  WithdrawalStatusApproved = 'WithdrawalStatusApproved',
  WithdrawalStatusCancel = 'WithdrawalStatusCancel',
  WithdrawalStatusCompleted = 'WithdrawalStatusCompleted',
  WithdrawalStatusFailed = 'WithdrawalStatusFailed',
  WithdrawalStatusPending = 'WithdrawalStatusPending',
  WithdrawalStatusRejected = 'WithdrawalStatusRejected',
  WithdrawalStatusSent = 'WithdrawalStatusSent',
  WithdrawalStatusUnknown = 'WithdrawalStatusUnknown',
  WithdrawalStatusWaitingApproval = 'WithdrawalStatusWaitingApproval',
}

export enum WithdrawalType {
  WithdrawalTypeCrypto = 'WithdrawalTypeCrypto',
  WithdrawalTypeFiatKgi = 'WithdrawalTypeFiatKGI',
  WithdrawalTypeInternalTransfer = 'WithdrawalTypeInternalTransfer',
  WithdrawalTypeManualWithdrawal = 'WithdrawalTypeManualWithdrawal',
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecords = {
  __typename?: 'aICompletionRecords'
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['String']
  meta?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  promptMutationInput?: Maybe<Scalars['jsonb']>
  promptMutationName?: Maybe<Scalars['String']>
  /** An array relationship */
  suggestions: Array<SuggestStrategyToolWithAllocations>
  /** An aggregate relationship */
  suggestions_aggregate: SuggestStrategyToolWithAllocations_Aggregate
  updatedAt: Scalars['timestamptz']
  userAddress?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsPromptMutationInputArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

export type CfReleaseNoteNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfReleaseNoteNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfReleaseNoteNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  platform?: InputMaybe<Scalars['String']>
  platform_contains?: InputMaybe<Scalars['String']>
  platform_exists?: InputMaybe<Scalars['Boolean']>
  platform_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  platform_not?: InputMaybe<Scalars['String']>
  platform_not_contains?: InputMaybe<Scalars['String']>
  platform_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  releaseNote_contains?: InputMaybe<Scalars['String']>
  releaseNote_exists?: InputMaybe<Scalars['Boolean']>
  releaseNote_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  version?: InputMaybe<Scalars['String']>
  version_contains?: InputMaybe<Scalars['String']>
  version_exists?: InputMaybe<Scalars['Boolean']>
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  version_not?: InputMaybe<Scalars['String']>
  version_not_contains?: InputMaybe<Scalars['String']>
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionContentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionContentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionContentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  externalLink?: InputMaybe<Scalars['String']>
  externalLinkDisplayText?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  externalLinkDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLinkDisplayText_not?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle?: InputMaybe<Scalars['String']>
  subTitle_contains?: InputMaybe<Scalars['String']>
  subTitle_exists?: InputMaybe<Scalars['Boolean']>
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle_not?: InputMaybe<Scalars['String']>
  subTitle_not_contains?: InputMaybe<Scalars['String']>
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sectionContentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSpecialUserNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSpecialUserNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSpecialUserNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

export type FrontendQuery = {
  __typename?: 'frontendQuery'
  version: FrontendVersion
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** Need admin permissions */
  admin?: Maybe<AdminMutation>
  auth?: Maybe<AuthMutation>
  dev?: Maybe<DevMutation>
  kyc?: Maybe<KycMutation>
  notification?: Maybe<NotificationMutation>
  point?: Maybe<PointMutation>
  resource?: Maybe<ResourceMutation>
  reyield?: Maybe<ReyieldMutation>
  strategy?: Maybe<StrategyMutation>
  trading?: Maybe<TradingMutation>
  user?: Maybe<UserMutation>
  voucher?: Maybe<VoucherMutation>
  wallet?: Maybe<WalletMutation>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type ReyieldMutation = {
  __typename?: 'reyieldMutation'
  closeUniswapv3Position?: Maybe<Scalars['String']>
  createAICompletionRecord: ReyieldaICompletionRecords
  /** Reyield Backend will monitor this position with the txHash (either pending or confirmed) */
  createUniswapv3Position?: Maybe<Scalars['String']>
  createUniswapv3Tool?: Maybe<Scalars['String']>
  externalAPIKeyCreate: ReyieldExternalApiKey
  externalAPIKeyDelete: ReyieldExternalApiKey
  strategyStart: ReyieldStrategyStartResponse
  strategyStop: ReyieldStrategyStopResponse
  strategyUpdate: ReyieldStrategyUpdateResponse
}

export type ReyieldMutationCloseUniswapv3PositionArgs = {
  input: ReyieldCloseUniswapv3PositionInput
}

export type ReyieldMutationCreateAiCompletionRecordArgs = {
  input: ReyieldCreateAiCompletionRecordInput
}

export type ReyieldMutationCreateUniswapv3PositionArgs = {
  input: ReyieldCreateUniswapv3PositionInput
}

export type ReyieldMutationCreateUniswapv3ToolArgs = {
  input: ReyieldCreateUniswapv3ToolInput
}

export type ReyieldMutationExternalApiKeyCreateArgs = {
  input: ReyieldExternalApiKeyCreateInput
}

export type ReyieldMutationExternalApiKeyDeleteArgs = {
  input: ReyieldExternalApiKeyDeleteInput
}

export type ReyieldMutationStrategyStartArgs = {
  input: ReyieldStrategyStartInput
}

export type ReyieldMutationStrategyStopArgs = {
  input: ReyieldStrategyStopInput
}

export type ReyieldMutationStrategyUpdateArgs = {
  input: ReyieldStrategyUpdateInput
}

export type ReyieldQuery = {
  __typename?: 'reyieldQuery'
  coinPrices?: Maybe<Array<ReyieldCoinPrice>>
  me: ReyieldUserInfo
  poolsWithArguments?: Maybe<Array<ReyieldUniswapPoolInfo>>
  rateHistorical?: Maybe<Array<ReyieldRateHistorical>>
  strategies: Array<ReyieldStrategyInfo>
  tools: Array<ReyieldToolInfo>
  uniswapQuote?: Maybe<ReyieldUniswapQuote>
  uniswapTokens?: Maybe<Array<ReyieldUniswapToolToken>>
  uniswapV3Backtest?: Maybe<ReyieldUniswapV3BacktestResult>
  version: ReyieldFrontendVersion
  wallet?: Maybe<ReyieldDefiWallet>
}

export type ReyieldQueryPoolsWithArgumentsArgs = {
  chain: ReyieldEvmChain
  first?: InputMaybe<Scalars['Int']>
  flag?: InputMaybe<ReyieldPoolsWithArgumentsFlag>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Scalars['String']>
  poolIds?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Int']>
  token0s?: InputMaybe<Array<Scalars['String']>>
  token1s?: InputMaybe<Array<Scalars['String']>>
}

export type ReyieldQueryRateHistoricalArgs = {
  filter: ReyieldRateHistoricalFilterInput
}

export type ReyieldQueryStrategiesArgs = {
  filter?: InputMaybe<ReyieldStrategyFilterInput>
}

export type ReyieldQueryUniswapQuoteArgs = {
  input: ReyieldUniswapQuoteInput
  source?: InputMaybe<ReyieldQuoteDataSource>
}

export type ReyieldQueryUniswapTokensArgs = {
  chain: ReyieldEvmChain
}

export type ReyieldQueryUniswapV3BacktestArgs = {
  compound: Scalars['Boolean']
  days: Scalars['Int']
  diff: Scalars['Float']
  investmentAmount: Scalars['Float']
  period: ReyieldUniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: ReyieldEvmChain
}

export type ReyieldQueryWalletArgs = {
  input?: InputMaybe<ReyieldDefiWalletFilterInput>
}

export type StrategyMutation = {
  __typename?: 'strategyMutation'
  admin?: Maybe<Strategy_AdminMutation>
  user?: Maybe<Strategy_UserMutation>
}

export type StrategyQuery = {
  __typename?: 'strategyQuery'
  admin?: Maybe<Strategy_Admin>
  public?: Maybe<Strategy_Public>
  user?: Maybe<Strategy_User>
}

/** columns and relationships of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations = {
  __typename?: 'suggestStrategyToolWithAllocations'
  aICompletionRecordId: Scalars['String']
  allocation: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Scalars['String']
  highestAnnualReturn: Scalars['String']
  id: Scalars['uuid']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate'
  aggregate?: Maybe<SuggestStrategyToolWithAllocations_Aggregate_Fields>
  nodes: Array<SuggestStrategyToolWithAllocations>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp = {
  count?: InputMaybe<SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<SuggestStrategyToolWithAllocations_Max_Fields>
  min?: Maybe<SuggestStrategyToolWithAllocations_Min_Fields>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<SuggestStrategyToolWithAllocations_Max_Order_By>
  min?: InputMaybe<SuggestStrategyToolWithAllocations_Min_Order_By>
}

/** Boolean expression to filter rows from the table "suggestStrategyToolWithAllocations". All fields are combined with a logical 'AND'. */
export type SuggestStrategyToolWithAllocations_Bool_Exp = {
  _and?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  _not?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  _or?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  aICompletionRecordId?: InputMaybe<String_Comparison_Exp>
  allocation?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  highestAnnualReturn?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  maximumLoss?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  sid?: InputMaybe<String_Comparison_Exp>
  systemInvestAmountSuggestion?: InputMaybe<String_Comparison_Exp>
  systemInvestCurrencySuggestion?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type SuggestStrategyToolWithAllocations_Max_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_max_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Max_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type SuggestStrategyToolWithAllocations_Min_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_min_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Min_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "suggestStrategyToolWithAllocations". */
export type SuggestStrategyToolWithAllocations_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** select columns of table "suggestStrategyToolWithAllocations" */
export enum SuggestStrategyToolWithAllocations_Select_Column {
  /** column name */
  AICompletionRecordId = 'aICompletionRecordId',
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HighestAnnualReturn = 'highestAnnualReturn',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumLoss = 'maximumLoss',
  /** column name */
  Name = 'name',
  /** column name */
  Sid = 'sid',
  /** column name */
  SystemInvestAmountSuggestion = 'systemInvestAmountSuggestion',
  /** column name */
  SystemInvestCurrencySuggestion = 'systemInvestCurrencySuggestion',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input = {
  aICompletionRecordId?: InputMaybe<Scalars['String']>
  allocation?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  highestAnnualReturn?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  maximumLoss?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  sid?: InputMaybe<Scalars['String']>
  systemInvestAmountSuggestion?: InputMaybe<Scalars['String']>
  systemInvestCurrencySuggestion?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes = {
  Confirm2FAData:
    | CryptoWithdrawal
    | ExternalApiKeyWithdrawal
    | FaasPayment
    | FiatWithdrawal
    | InternalTransferOut
    | LoginInfo
  Deposit: CryptoDeposit | FiatDeposit | InternalTransferIn
  LedgerEntry:
    | CryptoDeposit
    | CryptoWithdrawal
    | FiatDeposit
    | FiatWithdrawal
    | InternalTransferIn
    | InternalTransferOut
    | Trade
  LoginResponse: LoginInfo | Response2Fa
  ReyieldToolInfo: ReyieldStrategyInfo | ReyieldUniswapToolInfo
  ReyieldUserToolInfo: ReyieldUserStrategyInfo | ReyieldUserUniswapPositionInfo
  Strategy_StrategyState: Strategy_ArbitrageState | Strategy_LendingState
  ToolInfo: StrategyInfo | UniswapToolInfo
  UserToolInfo: UserStrategyInfo | UserUniswapPositionInfo
  WithdrawCryptoResponse: CryptoWithdrawal | InternalTransferOut | Response2Fa
  WithdrawFiatResponse: FiatWithdrawal | Response2Fa
  Withdrawal: CryptoWithdrawal | FiatWithdrawal | InternalTransferOut
}

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AML: ResolverTypeWrapper<Aml>
  AMLAssessmentItem: ResolverTypeWrapper<AmlAssessmentItem>
  AMLEDDRecord: ResolverTypeWrapper<AmleddRecord>
  AMLItemCategory: ResolverTypeWrapper<AmlItemCategory>
  AMLNSRecord: ResolverTypeWrapper<AmlnsRecord>
  AMLRARecord: ResolverTypeWrapper<AmlraRecord>
  AMLRatingCriteria: ResolverTypeWrapper<AmlRatingCriteria>
  AMLRiskAssessmentForm: ResolverTypeWrapper<AmlRiskAssessmentForm>
  AMLRiskFactor: ResolverTypeWrapper<AmlRiskFactor>
  AMLStatus: AmlStatus
  AMLUserAssessmentResult: ResolverTypeWrapper<AmlUserAssessmentResult>
  AMLVerification: ResolverTypeWrapper<AmlVerification>
  APIKey: ResolverTypeWrapper<ApiKey>
  AcceptFiatWithdrawalInput: AcceptFiatWithdrawalInput
  AcceptFiatWithdrawalResponse: ResolverTypeWrapper<AcceptFiatWithdrawalResponse>
  ActivityNotification: ResolverTypeWrapper<ActivityNotification>
  ActivityNotificationCollection: ResolverTypeWrapper<ActivityNotificationCollection>
  ActivityNotificationFilter: ActivityNotificationFilter
  ActivityNotificationLinkingCollections: ResolverTypeWrapper<ActivityNotificationLinkingCollections>
  ActivityNotificationOrder: ActivityNotificationOrder
  Address: ResolverTypeWrapper<Address>
  Admin: ResolverTypeWrapper<Admin>
  AdminAPIKeyFilter: AdminApiKeyFilter
  AdminAPIKeyResponse: ResolverTypeWrapper<AdminApiKeyResponse>
  AdminActivateMerchantInput: AdminActivateMerchantInput
  AdminActivateMerchantResponse: ResolverTypeWrapper<AdminActivateMerchantResponse>
  AdminAddAuditNoteInput: AdminAddAuditNoteInput
  AdminAddAuditNoteResponse: ResolverTypeWrapper<AdminAddAuditNoteResponse>
  AdminApproveVirtualAccountVerificationInput: AdminApproveVirtualAccountVerificationInput
  AdminApproveVirtualAccountVerificationResponse: ResolverTypeWrapper<AdminApproveVirtualAccountVerificationResponse>
  AdminAssignKYCVerifierInput: AdminAssignKycVerifierInput
  AdminAssignKYCVerifierResponse: ResolverTypeWrapper<AdminAssignKycVerifierResponse>
  AdminAuditWithdrawalInput: AdminAuditWithdrawalInput
  AdminAuditWithdrawalResponse: ResolverTypeWrapper<AdminAuditWithdrawalResponse>
  AdminChangeVirtualAccountLimitInput: AdminChangeVirtualAccountLimitInput
  AdminChangeVirtualAccountLimitResponse: ResolverTypeWrapper<AdminChangeVirtualAccountLimitResponse>
  AdminCheckDepositInfoInput: AdminCheckDepositInfoInput
  AdminCheckDepositInfoResponse: ResolverTypeWrapper<AdminCheckDepositInfoResponse>
  AdminCheckFileUploadInput: AdminCheckFileUploadInput
  AdminCheckUploadResultMResponse: ResolverTypeWrapper<AdminCheckUploadResultMResponse>
  AdminCheckUploadResultP01Response: ResolverTypeWrapper<AdminCheckUploadResultP01Response>
  AdminCheckUploadResultP02Response: ResolverTypeWrapper<AdminCheckUploadResultP02Response>
  AdminCheckUploadResultP05Response: ResolverTypeWrapper<AdminCheckUploadResultP05Response>
  AdminCheckUploadResultPG1Response: ResolverTypeWrapper<AdminCheckUploadResultPg1Response>
  AdminCheckUploadResultResponse: ResolverTypeWrapper<AdminCheckUploadResultResponse>
  AdminCreateAPIKeyInput: AdminCreateApiKeyInput
  AdminCreateAPIKeyResponse: ResolverTypeWrapper<AdminCreateApiKeyResponse>
  AdminCreateOAuthClientInput: AdminCreateOAuthClientInput
  AdminCreateOAuthClientResponse: ResolverTypeWrapper<AdminCreateOAuthClientResponse>
  AdminCreateRolesInput: AdminCreateRolesInput
  AdminCreateRolesResponse: ResolverTypeWrapper<AdminCreateRolesResponse>
  AdminCreateUserInput: AdminCreateUserInput
  AdminCreateUserResponse: ResolverTypeWrapper<AdminCreateUserResponse>
  AdminDVBanInput: AdminDvBanInput
  AdminDVBanResponse: ResolverTypeWrapper<AdminDvBanResponse>
  AdminDVUpdateInput: AdminDvUpdateInput
  AdminDVUpdateResponse: ResolverTypeWrapper<AdminDvUpdateResponse>
  AdminDeleteAPIKeyInput: AdminDeleteApiKeyInput
  AdminDeleteAPIKeyResponse: ResolverTypeWrapper<AdminDeleteApiKeyResponse>
  AdminDeleteRolesInput: AdminDeleteRolesInput
  AdminDeleteRolesResponse: ResolverTypeWrapper<AdminDeleteRolesResponse>
  AdminDepositFilter: AdminDepositFilter
  AdminDepositReportResponse: ResolverTypeWrapper<AdminDepositReportResponse>
  AdminDepositResponse: ResolverTypeWrapper<
    Omit<AdminDepositResponse, 'data'> & { data?: Maybe<Array<ResolversTypes['Deposit']>> }
  >
  AdminDisable2FAInput: AdminDisable2FaInput
  AdminDisable2FAResponse: ResolverTypeWrapper<AdminDisable2FaResponse>
  AdminDisableUserInput: AdminDisableUserInput
  AdminDisableUserResponse: ResolverTypeWrapper<AdminDisableUserResponse>
  AdminEDDAuditInput: AdminEddAuditInput
  AdminEDDAuditResponse: ResolverTypeWrapper<AdminEddAuditResponse>
  AdminEnableUserInput: AdminEnableUserInput
  AdminEnableUserResponse: ResolverTypeWrapper<AdminEnableUserResponse>
  AdminHolidayAddInput: AdminHolidayAddInput
  AdminHolidayAddResponse: ResolverTypeWrapper<AdminHolidayAddResponse>
  AdminHolidayFilter: AdminHolidayFilter
  AdminHolidayResponse: ResolverTypeWrapper<AdminHolidayResponse>
  AdminIPUserFilter: AdminIpUserFilter
  AdminIPUserResponse: ResolverTypeWrapper<AdminIpUserResponse>
  AdminLedgerFilter: AdminLedgerFilter
  AdminLedgerResponse: ResolverTypeWrapper<AdminLedgerResponse>
  AdminMutation: ResolverTypeWrapper<AdminMutation>
  AdminNSAuditInput: AdminNsAuditInput
  AdminNSAuditResponse: ResolverTypeWrapper<AdminNsAuditResponse>
  AdminOnHoldFiatWithdrawalResponse: ResolverTypeWrapper<AdminOnHoldFiatWithdrawalResponse>
  AdminOnHoldKGIVerificationResponse: ResolverTypeWrapper<AdminOnHoldKgiVerificationResponse>
  AdminP05Type: AdminP05Type
  AdminRAAuditInput: AdminRaAuditInput
  AdminRAAuditResponse: ResolverTypeWrapper<AdminRaAuditResponse>
  AdminReauditKYCInput: AdminReauditKycInput
  AdminReauditKYCResponse: ResolverTypeWrapper<AdminReauditKycResponse>
  AdminRecoverKGIStatusResponse: ResolverTypeWrapper<AdminRecoverKgiStatusResponse>
  AdminRefreshMerchantInput: AdminRefreshMerchantInput
  AdminRefreshMerchantResponse: ResolverTypeWrapper<AdminRefreshMerchantResponse>
  AdminRejectFiatWithdrawalInput: AdminRejectFiatWithdrawalInput
  AdminRejectFiatWithdrawalResponse: ResolverTypeWrapper<AdminRejectFiatWithdrawalResponse>
  AdminRejectKGIVerificationInput: AdminRejectKgiVerificationInput
  AdminRejectKGIVerificationResponse: ResolverTypeWrapper<AdminRejectKgiVerificationResponse>
  AdminRejectVirtualAccountVerificationInput: AdminRejectVirtualAccountVerificationInput
  AdminRejectVirtualAccountVerificationResponse: ResolverTypeWrapper<AdminRejectVirtualAccountVerificationResponse>
  AdminResendAMLWithdrawInfoP01Response: ResolverTypeWrapper<AdminResendAmlWithdrawInfoP01Response>
  AdminResendAMLWithdrawInfoP02Response: ResolverTypeWrapper<AdminResendAmlWithdrawInfoP02Response>
  AdminResendFiatWithdrawalInput: AdminResendFiatWithdrawalInput
  AdminResendFiatWithdrawalResponse: ResolverTypeWrapper<AdminResendFiatWithdrawalResponse>
  AdminResendKGIVerificationInput: AdminResendKgiVerificationInput
  AdminResendKGIVerificationResponse: ResolverTypeWrapper<AdminResendKgiVerificationResponse>
  AdminResendWithdrawInfoP01Input: AdminResendWithdrawInfoP01Input
  AdminResendWithdrawInfoP01Response: ResolverTypeWrapper<AdminResendWithdrawInfoP01Response>
  AdminResendWithdrawInfoP02Input: AdminResendWithdrawInfoP02Input
  AdminResendWithdrawInfoP02Response: ResolverTypeWrapper<AdminResendWithdrawInfoP02Response>
  AdminResetKYCVerificationInput: AdminResetKycVerificationInput
  AdminResetKYCVerificationResponse: ResolverTypeWrapper<AdminResetKycVerificationResponse>
  AdminResetPhoneInput: AdminResetPhoneInput
  AdminResetPhoneResponse: ResolverTypeWrapper<AdminResetPhoneResponse>
  AdminSendBGWithdrawInfoInput: AdminSendBgWithdrawInfoInput
  AdminSendBGWithdrawInfoResponse: ResolverTypeWrapper<AdminSendBgWithdrawInfoResponse>
  AdminSendInvestorsSettlementResponse: ResolverTypeWrapper<AdminSendInvestorsSettlementResponse>
  AdminSendMemberInfoResponse: ResolverTypeWrapper<AdminSendMemberInfoResponse>
  AdminSendWithdrawInfoP01Response: ResolverTypeWrapper<AdminSendWithdrawInfoP01Response>
  AdminSendWithdrawInfoP02Response: ResolverTypeWrapper<AdminSendWithdrawInfoP02Response>
  AdminSetOldUserInput: AdminSetOldUserInput
  AdminSetOlderUserResponse: ResolverTypeWrapper<AdminSetOlderUserResponse>
  AdminSetTradePermissionInput: AdminSetTradePermissionInput
  AdminSetTradePermissionResponse: ResolverTypeWrapper<AdminSetTradePermissionResponse>
  AdminSubmitAMLVerificationInput: AdminSubmitAmlVerificationInput
  AdminSubmitAMLVerificationResponse: ResolverTypeWrapper<AdminSubmitAmlVerificationResponse>
  AdminSubmitEDDAuditInput: AdminSubmitEddAuditInput
  AdminSubmitKYCOneVerificationInput: AdminSubmitKycOneVerificationInput
  AdminSubmitKYCOneVerificationResponse: ResolverTypeWrapper<AdminSubmitKycOneVerificationResponse>
  AdminSubmitKYCTwoVerificationInput: AdminSubmitKycTwoVerificationInput
  AdminSubmitKYCTwoVerificationResponse: ResolverTypeWrapper<AdminSubmitKycTwoVerificationResponse>
  AdminSubmitNSAuditInput: AdminSubmitNsAuditInput
  AdminSubmitRAAuditInput: AdminSubmitRaAuditInput
  AdminSubmitRAAuditResponse: ResolverTypeWrapper<AdminSubmitRaAuditResponse>
  AdminSystemAuditAMLInput: AdminSystemAuditAmlInput
  AdminSystemAuditAMLResponse: ResolverTypeWrapper<AdminSystemAuditAmlResponse>
  AdminTradeFilter: AdminTradeFilter
  AdminTradeResponse: ResolverTypeWrapper<AdminTradeResponse>
  AdminTradingReportResponse: ResolverTypeWrapper<AdminTradingReportResponse>
  AdminUpdateCoinInfoResponse: ResolverTypeWrapper<AdminUpdateCoinInfoResponse>
  AdminUpdateMarketInfoResponse: ResolverTypeWrapper<AdminUpdateMarketInfoResponse>
  AdminUpdateOAuthClientInput: AdminUpdateOAuthClientInput
  AdminUpdateOAuthClientResponse: ResolverTypeWrapper<AdminUpdateOAuthClientResponse>
  AdminUpdateQuotationInfoResponse: ResolverTypeWrapper<AdminUpdateQuotationInfoResponse>
  AdminUploadSuspiciousTransactionInput: AdminUploadSuspiciousTransactionInput
  AdminUploadSuspiciousTransactionResponse: ResolverTypeWrapper<AdminUploadSuspiciousTransactionResponse>
  AdminUploadVerificationFileResponse: ResolverTypeWrapper<AdminUploadVerificationFileResponse>
  AdminUserFilter: AdminUserFilter
  AdminUserResponse: ResolverTypeWrapper<AdminUserResponse>
  AdminVIPUpdateInput: AdminVipUpdateInput
  AdminVIPUpdateResponse: ResolverTypeWrapper<AdminVipUpdateResponse>
  AdminVerifierFilter: AdminVerifierFilter
  AdminVerifyKYCIdentityInput: AdminVerifyKycIdentityInput
  AdminVerifyKYCIdentityResponse: ResolverTypeWrapper<AdminVerifyKycIdentityResponse>
  AdminVoucherCreateInput: AdminVoucherCreateInput
  AdminVoucherEditInput: AdminVoucherEditInput
  AdminVoucherFilter: AdminVoucherFilter
  AdminVoucherRecordsResponse: ResolverTypeWrapper<AdminVoucherRecordsResponse>
  AdminWithdrawalFilter: AdminWithdrawalFilter
  AdminWithdrawalReportResponse: ResolverTypeWrapper<AdminWithdrawalReportResponse>
  AdminWithdrawalResponse: ResolverTypeWrapper<
    Omit<AdminWithdrawalResponse, 'data'> & { data?: Maybe<Array<ResolversTypes['Withdrawal']>> }
  >
  ApproveKYCLv2Input: ApproveKycLv2Input
  ApproveKYCLv2Response: ResolverTypeWrapper<ApproveKycLv2Response>
  Asset: ResolverTypeWrapper<Asset>
  AssetCollection: ResolverTypeWrapper<AssetCollection>
  AssetFilter: AssetFilter
  AssetLinkingCollections: ResolverTypeWrapper<AssetLinkingCollections>
  AssetLinkingCollectionsDisplayItemCollectionOrder: AssetLinkingCollectionsDisplayItemCollectionOrder
  AssetLinkingCollectionsHeroSliderCollectionOrder: AssetLinkingCollectionsHeroSliderCollectionOrder
  AssetLinkingCollectionsPopUpCollectionOrder: AssetLinkingCollectionsPopUpCollectionOrder
  AssetOrder: AssetOrder
  Attributes: ResolverTypeWrapper<Attributes>
  Auth2FA: ResolverTypeWrapper<Auth2Fa>
  AuthBind2FAResponse: ResolverTypeWrapper<AuthBind2FaResponse>
  AuthConfirm2FAInput: AuthConfirm2FaInput
  AuthConfirm2FAResponse: ResolverTypeWrapper<
    Omit<AuthConfirm2FaResponse, 'data'> & { data?: Maybe<ResolversTypes['Confirm2FAData']> }
  >
  AuthMutation: ResolverTypeWrapper<AuthMutation>
  AuthUnbind2FAInput: AuthUnbind2FaInput
  AuthUnbind2FAResponse: ResolverTypeWrapper<AuthUnbind2FaResponse>
  Balance: ResolverTypeWrapper<Balance>
  Bank: ResolverTypeWrapper<Bank>
  BankAccount: ResolverTypeWrapper<BankAccount>
  BankBranch: ResolverTypeWrapper<BankBranch>
  BankInfo: ResolverTypeWrapper<BankInfo>
  BankInfoInput: BankInfoInput
  BaseDeposit:
    | ResolversTypes['CryptoDeposit']
    | ResolversTypes['FiatDeposit']
    | ResolversTypes['InternalTransferIn']
  BaseResponse:
    | ResolversTypes['AcceptFiatWithdrawalResponse']
    | ResolversTypes['AdminAPIKeyResponse']
    | ResolversTypes['AdminActivateMerchantResponse']
    | ResolversTypes['AdminAddAuditNoteResponse']
    | ResolversTypes['AdminApproveVirtualAccountVerificationResponse']
    | ResolversTypes['AdminAssignKYCVerifierResponse']
    | ResolversTypes['AdminAuditWithdrawalResponse']
    | ResolversTypes['AdminChangeVirtualAccountLimitResponse']
    | ResolversTypes['AdminCheckDepositInfoResponse']
    | ResolversTypes['AdminCheckUploadResultMResponse']
    | ResolversTypes['AdminCheckUploadResultP01Response']
    | ResolversTypes['AdminCheckUploadResultP02Response']
    | ResolversTypes['AdminCheckUploadResultP05Response']
    | ResolversTypes['AdminCheckUploadResultPG1Response']
    | ResolversTypes['AdminCheckUploadResultResponse']
    | ResolversTypes['AdminCreateAPIKeyResponse']
    | ResolversTypes['AdminCreateOAuthClientResponse']
    | ResolversTypes['AdminCreateRolesResponse']
    | ResolversTypes['AdminCreateUserResponse']
    | ResolversTypes['AdminDVBanResponse']
    | ResolversTypes['AdminDVUpdateResponse']
    | ResolversTypes['AdminDeleteAPIKeyResponse']
    | ResolversTypes['AdminDeleteRolesResponse']
    | ResolversTypes['AdminDepositReportResponse']
    | ResolversTypes['AdminDepositResponse']
    | ResolversTypes['AdminDisable2FAResponse']
    | ResolversTypes['AdminDisableUserResponse']
    | ResolversTypes['AdminEDDAuditResponse']
    | ResolversTypes['AdminEnableUserResponse']
    | ResolversTypes['AdminHolidayAddResponse']
    | ResolversTypes['AdminHolidayResponse']
    | ResolversTypes['AdminIPUserResponse']
    | ResolversTypes['AdminLedgerResponse']
    | ResolversTypes['AdminNSAuditResponse']
    | ResolversTypes['AdminOnHoldFiatWithdrawalResponse']
    | ResolversTypes['AdminOnHoldKGIVerificationResponse']
    | ResolversTypes['AdminRAAuditResponse']
    | ResolversTypes['AdminReauditKYCResponse']
    | ResolversTypes['AdminRecoverKGIStatusResponse']
    | ResolversTypes['AdminRefreshMerchantResponse']
    | ResolversTypes['AdminRejectFiatWithdrawalResponse']
    | ResolversTypes['AdminRejectKGIVerificationResponse']
    | ResolversTypes['AdminRejectVirtualAccountVerificationResponse']
    | ResolversTypes['AdminResendAMLWithdrawInfoP01Response']
    | ResolversTypes['AdminResendAMLWithdrawInfoP02Response']
    | ResolversTypes['AdminResendFiatWithdrawalResponse']
    | ResolversTypes['AdminResendKGIVerificationResponse']
    | ResolversTypes['AdminResendWithdrawInfoP01Response']
    | ResolversTypes['AdminResendWithdrawInfoP02Response']
    | ResolversTypes['AdminResetKYCVerificationResponse']
    | ResolversTypes['AdminResetPhoneResponse']
    | ResolversTypes['AdminSendBGWithdrawInfoResponse']
    | ResolversTypes['AdminSendInvestorsSettlementResponse']
    | ResolversTypes['AdminSendMemberInfoResponse']
    | ResolversTypes['AdminSendWithdrawInfoP01Response']
    | ResolversTypes['AdminSendWithdrawInfoP02Response']
    | ResolversTypes['AdminSetOlderUserResponse']
    | ResolversTypes['AdminSetTradePermissionResponse']
    | ResolversTypes['AdminSubmitAMLVerificationResponse']
    | ResolversTypes['AdminSubmitKYCOneVerificationResponse']
    | ResolversTypes['AdminSubmitKYCTwoVerificationResponse']
    | ResolversTypes['AdminSubmitRAAuditResponse']
    | ResolversTypes['AdminSystemAuditAMLResponse']
    | ResolversTypes['AdminTradeResponse']
    | ResolversTypes['AdminTradingReportResponse']
    | ResolversTypes['AdminUpdateCoinInfoResponse']
    | ResolversTypes['AdminUpdateMarketInfoResponse']
    | ResolversTypes['AdminUpdateOAuthClientResponse']
    | ResolversTypes['AdminUpdateQuotationInfoResponse']
    | ResolversTypes['AdminUploadSuspiciousTransactionResponse']
    | ResolversTypes['AdminUploadVerificationFileResponse']
    | ResolversTypes['AdminUserResponse']
    | ResolversTypes['AdminVIPUpdateResponse']
    | ResolversTypes['AdminVerifyKYCIdentityResponse']
    | ResolversTypes['AdminVoucherRecordsResponse']
    | ResolversTypes['AdminWithdrawalReportResponse']
    | ResolversTypes['AdminWithdrawalResponse']
    | ResolversTypes['ApproveKYCLv2Response']
    | ResolversTypes['AuthBind2FAResponse']
    | ResolversTypes['AuthConfirm2FAResponse']
    | ResolversTypes['AuthUnbind2FAResponse']
    | ResolversTypes['ChartResponse']
    | ResolversTypes['CommonAddressResponse']
    | ResolversTypes['DepositResponse']
    | ResolversTypes['FAASPaymentResponse']
    | ResolversTypes['KYCConfirmSubmissionResponse']
    | ResolversTypes['KYCSubmitLevelOneResponse']
    | ResolversTypes['KYCSubmitLevelTwoResponse']
    | ResolversTypes['LedgerResponse']
    | ResolversTypes['NotificationCountResponse']
    | ResolversTypes['NotificationListResponse']
    | ResolversTypes['NotificationReadResponse']
    | ResolversTypes['PointPurchaseResponse']
    | ResolversTypes['RejectFiatWithdrawalResponse']
    | ResolversTypes['ResourceConfirmUploadResponse']
    | ResolversTypes['ResourceRequestUploadURLResponse']
    | ResolversTypes['TradeResponse']
    | ResolversTypes['TradingConfirmQuotationResponse']
    | ResolversTypes['TradingQuotationResponse']
    | ResolversTypes['TradingReportResponse']
    | ResolversTypes['UserActionLogResponse']
    | ResolversTypes['UserAgreeResponse']
    | ResolversTypes['UserBindPhoneConfirmationResponse']
    | ResolversTypes['UserBindPhoneResponse']
    | ResolversTypes['UserCancelOAuthResponse']
    | ResolversTypes['UserChangePasswordResponse']
    | ResolversTypes['UserCustomizeReferralResponse']
    | ResolversTypes['UserDeleteBankAccountResponse']
    | ResolversTypes['UserDeviceTokenResponse']
    | ResolversTypes['UserFillReferrerCodeResponse']
    | ResolversTypes['UserForgotPasswordConfirmResponse']
    | ResolversTypes['UserForgotPasswordResponse']
    | ResolversTypes['UserLoginRecordsResponse']
    | ResolversTypes['UserLoginResponse']
    | ResolversTypes['UserOAuthClientResponse']
    | ResolversTypes['UserPreferenceResponse']
    | ResolversTypes['UserRefreshTokenResponse']
    | ResolversTypes['UserRegisterConfirmResponse']
    | ResolversTypes['UserRegisterResponse']
    | ResolversTypes['UserRequestAccountDisableResponse']
    | ResolversTypes['UserResendForgotPasswordResponse']
    | ResolversTypes['UserResendRegisterResponse']
    | ResolversTypes['VoucherUseResponse']
    | ResolversTypes['WalletCreateCommonAddressResponse']
    | ResolversTypes['WalletCreateCryptoAddressResponse']
    | ResolversTypes['WalletCryptoWithdrawResponse']
    | ResolversTypes['WalletDeleteCommonAddressResponse']
    | ResolversTypes['WalletDepositResponse']
    | ResolversTypes['WalletFAASPaymentConfirmResponse']
    | ResolversTypes['WalletFiatWithdrawResponse']
    | ResolversTypes['WalletWithdrawConfirmResponse']
    | ResolversTypes['WalletWithdrawalResponse']
    | ResolversTypes['WithdrawalResponse']
  BaseWithdrawal:
    | ResolversTypes['CryptoWithdrawal']
    | ResolversTypes['FiatWithdrawal']
    | ResolversTypes['InternalTransferOut']
  BasicInfo: ResolverTypeWrapper<BasicInfo>
  BasicInfoInput: BasicInfoInput
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  CasbinRole: CasbinRole
  CefiDepositAddress: ResolverTypeWrapper<CefiDepositAddress>
  CefiDepositAddressInput: CefiDepositAddressInput
  CefiWallet: ResolverTypeWrapper<CefiWallet>
  CefiWalletFilterInput: CefiWalletFilterInput
  Chain: Chain
  ChainFilter: ChainFilter
  ChartInput: ChartInput
  ChartResponse: ResolverTypeWrapper<ChartResponse>
  CloseUniswapv3PositionInput: CloseUniswapv3PositionInput
  CoinPrice: ResolverTypeWrapper<CoinPrice>
  CommonAddress: ResolverTypeWrapper<CommonAddress>
  CommonAddressFilter: CommonAddressFilter
  CommonAddressResponse: ResolverTypeWrapper<CommonAddressResponse>
  Confirm2FAData: ResolverTypeWrapper<ResolversUnionTypes['Confirm2FAData']>
  ContentfulMetadata: ResolverTypeWrapper<ContentfulMetadata>
  ContentfulMetadataFilter: ContentfulMetadataFilter
  ContentfulMetadataTagsFilter: ContentfulMetadataTagsFilter
  ContentfulTag: ResolverTypeWrapper<ContentfulTag>
  Country: ResolverTypeWrapper<Country>
  CreateAICompletionRecordInput: CreateAiCompletionRecordInput
  CreateAICompletionRecordItemInput: CreateAiCompletionRecordItemInput
  CreateUniswapv3PositionInput: CreateUniswapv3PositionInput
  CreateUniswapv3ToolInput: CreateUniswapv3ToolInput
  Creator: ResolverTypeWrapper<Creator>
  CryptoAsset: ResolverTypeWrapper<CryptoAsset>
  CryptoDeposit: ResolverTypeWrapper<CryptoDeposit>
  CryptoWithdrawal: ResolverTypeWrapper<CryptoWithdrawal>
  Currency: Currency
  CurrencyAmount: ResolverTypeWrapper<CurrencyAmount>
  CurrencyBalance: ResolverTypeWrapper<CurrencyBalance>
  CurrencyBalances: ResolverTypeWrapper<CurrencyBalances>
  CurrencyFilter: CurrencyFilter
  CurrencyInfo: ResolverTypeWrapper<CurrencyInfo>
  CurrencyType: CurrencyType
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>
  DefiTokensBalanceAmountInput: DefiTokensBalanceAmountInput
  DefiWallet: ResolverTypeWrapper<DefiWallet>
  DefiWalletFilterInput: DefiWalletFilterInput
  DefiWalletsFilterInput: DefiWalletsFilterInput
  Deposit: ResolverTypeWrapper<ResolversUnionTypes['Deposit']>
  DepositFilter: DepositFilter
  DepositResponse: ResolverTypeWrapper<
    Omit<DepositResponse, 'data'> & { data?: Maybe<Array<ResolversTypes['Deposit']>> }
  >
  DepositStatus: DepositStatus
  DepositType: DepositType
  DevMutation: ResolverTypeWrapper<DevMutation>
  Dimension: ResolverTypeWrapper<Scalars['Dimension']>
  DisplayItem: ResolverTypeWrapper<DisplayItem>
  DisplayItemCollection: ResolverTypeWrapper<DisplayItemCollection>
  DisplayItemFilter: DisplayItemFilter
  DisplayItemForUsersCollection: ResolverTypeWrapper<DisplayItemForUsersCollection>
  DisplayItemForUsersCollectionOrder: DisplayItemForUsersCollectionOrder
  DisplayItemLinkingCollections: ResolverTypeWrapper<DisplayItemLinkingCollections>
  DisplayItemOrder: DisplayItemOrder
  EDDStatus: EddStatus
  Email: ResolverTypeWrapper<Scalars['Email']>
  Entry:
    | ResolversTypes['ActivityNotification']
    | ResolversTypes['DisplayItem']
    | ResolversTypes['HeroSlider']
    | ResolversTypes['LandingPageCustomMessage']
    | ResolversTypes['PopUp']
    | ResolversTypes['Release']
    | ResolversTypes['ReleaseNote']
    | ResolversTypes['Section']
    | ResolversTypes['SectionContent']
    | ResolversTypes['SpecialUser']
    | ResolversTypes['StrategyMeta']
  EntryCollection: ResolverTypeWrapper<EntryCollection>
  EntryFilter: EntryFilter
  EntryOrder: EntryOrder
  EvmChain: EvmChain
  Exchange: Exchange
  ExternalAPIKey: ResolverTypeWrapper<ExternalApiKey>
  ExternalAPIKeyCreateInput: ExternalApiKeyCreateInput
  ExternalAPIKeyDeleteInput: ExternalApiKeyDeleteInput
  ExternalAPIKeyWithdrawal: ResolverTypeWrapper<ExternalApiKeyWithdrawal>
  ExternalAPIKeysFilter: ExternalApiKeysFilter
  ExternalWallet: ExternalWallet
  FAASPayment: ResolverTypeWrapper<FaasPayment>
  FAASPaymentFilter: FaasPaymentFilter
  FAASPaymentResponse: ResolverTypeWrapper<FaasPaymentResponse>
  FiatDeposit: ResolverTypeWrapper<FiatDeposit>
  FiatDepositInfo: ResolverTypeWrapper<FiatDepositInfo>
  FiatWithdrawal: ResolverTypeWrapper<FiatWithdrawal>
  Float: ResolverTypeWrapper<Scalars['Float']>
  FrontendVersion: ResolverTypeWrapper<FrontendVersion>
  Gender: Gender
  GimCoolPayPaymentInfo: ResolverTypeWrapper<GimCoolPayPaymentInfo>
  GimCoolPayPaymentInfoInput: GimCoolPayPaymentInfoInput
  GimCoolPayPaymentSupportingBlockchain: GimCoolPayPaymentSupportingBlockchain
  GimCoolPayPaymentSupportingCoinType: GimCoolPayPaymentSupportingCoinType
  GimCoolPayPaymentSupportingFiatType: GimCoolPayPaymentSupportingFiatType
  GimCoolPayPriceResponse: ResolverTypeWrapper<GimCoolPayPriceResponse>
  GimCoolPayReadyToPurchasePrice: ResolverTypeWrapper<GimCoolPayReadyToPurchasePrice>
  Granularity: Granularity
  HeroSlider: ResolverTypeWrapper<HeroSlider>
  HeroSliderCollection: ResolverTypeWrapper<HeroSliderCollection>
  HeroSliderFilter: HeroSliderFilter
  HeroSliderLinkingCollections: ResolverTypeWrapper<HeroSliderLinkingCollections>
  HeroSliderOrder: HeroSliderOrder
  HexColor: ResolverTypeWrapper<Scalars['HexColor']>
  Holiday: ResolverTypeWrapper<Holiday>
  ID: ResolverTypeWrapper<Scalars['ID']>
  IdentityInfo: ResolverTypeWrapper<IdentityInfo>
  IdentityInfoInput: IdentityInfoInput
  IdentityIssuanceType: IdentityIssuanceType
  IdentityType: IdentityType
  IdentityVerifyStatus: IdentityVerifyStatus
  ImageFormat: ImageFormat
  ImageResizeFocus: ImageResizeFocus
  ImageResizeStrategy: ImageResizeStrategy
  ImageTransformOptions: ImageTransformOptions
  Industry: Industry
  Int: ResolverTypeWrapper<Scalars['Int']>
  Int_comparison_exp: Int_Comparison_Exp
  InternalTransferIn: ResolverTypeWrapper<InternalTransferIn>
  InternalTransferOut: ResolverTypeWrapper<InternalTransferOut>
  JSON: ResolverTypeWrapper<Scalars['JSON']>
  KYC: ResolverTypeWrapper<Kyc>
  KYCConfirmSubmissionInput: KycConfirmSubmissionInput
  KYCConfirmSubmissionResponse: ResolverTypeWrapper<KycConfirmSubmissionResponse>
  KYCLevel: KycLevel
  KYCLevelOne: ResolverTypeWrapper<KycLevelOne>
  KYCLevelTwo: ResolverTypeWrapper<KycLevelTwo>
  KYCMutation: ResolverTypeWrapper<KycMutation>
  KYCPhoto: ResolverTypeWrapper<KycPhoto>
  KYCPhotoStatus: KycPhotoStatus
  KYCPhotoVerification: ResolverTypeWrapper<KycPhotoVerification>
  KYCStatus: KycStatus
  KYCSubmitLevelOneInput: KycSubmitLevelOneInput
  KYCSubmitLevelOneResponse: ResolverTypeWrapper<KycSubmitLevelOneResponse>
  KYCSubmitLevelTwoInput: KycSubmitLevelTwoInput
  KYCSubmitLevelTwoResponse: ResolverTypeWrapper<KycSubmitLevelTwoResponse>
  KgiUploadResponse: ResolverTypeWrapper<KgiUploadResponse>
  LandingPageCustomMessage: ResolverTypeWrapper<LandingPageCustomMessage>
  LandingPageCustomMessageCollection: ResolverTypeWrapper<LandingPageCustomMessageCollection>
  LandingPageCustomMessageCustomMessage: ResolverTypeWrapper<LandingPageCustomMessageCustomMessage>
  LandingPageCustomMessageCustomMessageAssets: ResolverTypeWrapper<LandingPageCustomMessageCustomMessageAssets>
  LandingPageCustomMessageCustomMessageEntries: ResolverTypeWrapper<LandingPageCustomMessageCustomMessageEntries>
  LandingPageCustomMessageCustomMessageLinks: ResolverTypeWrapper<LandingPageCustomMessageCustomMessageLinks>
  LandingPageCustomMessageCustomMessageResources: ResolverTypeWrapper<LandingPageCustomMessageCustomMessageResources>
  LandingPageCustomMessageFilter: LandingPageCustomMessageFilter
  LandingPageCustomMessageLinkingCollections: ResolverTypeWrapper<LandingPageCustomMessageLinkingCollections>
  LandingPageCustomMessageOrder: LandingPageCustomMessageOrder
  Ledger: ResolverTypeWrapper<
    Omit<Ledger, 'ledgerEntry'> & { ledgerEntry: ResolversTypes['LedgerEntry'] }
  >
  LedgerEntry: ResolverTypeWrapper<ResolversUnionTypes['LedgerEntry']>
  LedgerFilter: LedgerFilter
  LedgerResponse: ResolverTypeWrapper<LedgerResponse>
  LoginInfo: ResolverTypeWrapper<LoginInfo>
  LoginResponse: ResolverTypeWrapper<ResolversUnionTypes['LoginResponse']>
  Market: Market
  MarketInfo: ResolverTypeWrapper<MarketInfo>
  MarketPrice: ResolverTypeWrapper<MarketPrice>
  Me: ResolverTypeWrapper<Me>
  MetaPagination: ResolverTypeWrapper<MetaPagination>
  Metric: ResolverTypeWrapper<Metric>
  MetricPosition: ResolverTypeWrapper<MetricPosition>
  MobileOS: MobileOs
  Mutation: ResolverTypeWrapper<{}>
  NFTCollections: ResolverTypeWrapper<NftCollections>
  NFTMerkleTreeHexProof: ResolverTypeWrapper<NftMerkleTreeHexProof>
  NFTWhitelistInfoByPhrase: ResolverTypeWrapper<NftWhitelistInfoByPhrase>
  NFT_ROUND: Nft_Round
  NSStatus: NsStatus
  Notification: ResolverTypeWrapper<Notification>
  NotificationCount: ResolverTypeWrapper<NotificationCount>
  NotificationCountFilter: NotificationCountFilter
  NotificationCountResponse: ResolverTypeWrapper<NotificationCountResponse>
  NotificationInfo: ResolverTypeWrapper<NotificationInfo>
  NotificationListFilter: NotificationListFilter
  NotificationListResponse: ResolverTypeWrapper<NotificationListResponse>
  NotificationMutation: ResolverTypeWrapper<NotificationMutation>
  NotificationProvider: NotificationProvider
  NotificationReadFilter: NotificationReadFilter
  NotificationReadInput: NotificationReadInput
  NotificationReadResponse: ResolverTypeWrapper<NotificationReadResponse>
  Number: ResolverTypeWrapper<Scalars['Number']>
  OnHoldKGIVerification: ResolverTypeWrapper<OnHoldKgiVerification>
  OrderSide: OrderSide
  OutOfRangeInfoInput: OutOfRangeInfoInput
  PaginationInput: PaginationInput
  PaginationQuery: PaginationQuery
  PaymentMethod: PaymentMethod
  PhotoVerificationResultInput: PhotoVerificationResultInput
  Point: ResolverTypeWrapper<Point>
  PointLedgerFilter: PointLedgerFilter
  PointLedgerRecord: ResolverTypeWrapper<PointLedgerRecord>
  PointLedgerRecordResponse: ResolverTypeWrapper<PointLedgerRecordResponse>
  PointMutation: ResolverTypeWrapper<PointMutation>
  PointPurchaseInput: PointPurchaseInput
  PointPurchaseResponse: ResolverTypeWrapper<PointPurchaseResponse>
  PointType: PointType
  PoolsWithArgumentsFlag: PoolsWithArgumentsFlag
  PopUp: ResolverTypeWrapper<PopUp>
  PopUpCollection: ResolverTypeWrapper<PopUpCollection>
  PopUpContentArea: ResolverTypeWrapper<PopUpContentArea>
  PopUpContentAreaAssets: ResolverTypeWrapper<PopUpContentAreaAssets>
  PopUpContentAreaEntries: ResolverTypeWrapper<PopUpContentAreaEntries>
  PopUpContentAreaLinks: ResolverTypeWrapper<PopUpContentAreaLinks>
  PopUpContentAreaResources: ResolverTypeWrapper<PopUpContentAreaResources>
  PopUpFilter: PopUpFilter
  PopUpLinkingCollections: ResolverTypeWrapper<PopUpLinkingCollections>
  PopUpOrder: PopUpOrder
  PositionManagerInfo: ResolverTypeWrapper<PositionManagerInfo>
  PriceType: PriceType
  ProductName: ProductName
  PromptVersion: PromptVersion
  Public: ResolverTypeWrapper<Public>
  Quality: ResolverTypeWrapper<Scalars['Quality']>
  Query: ResolverTypeWrapper<{}>
  Question: ResolverTypeWrapper<Question>
  QuestionAnnualIncome: QuestionAnnualIncome
  QuestionInfo: ResolverTypeWrapper<QuestionInfo>
  QuestionInput: QuestionInput
  QuestionOption: ResolverTypeWrapper<QuestionOption>
  QuestionPeriod: QuestionPeriod
  QuestionPurpose: QuestionPurpose
  QuestionResultInput: QuestionResultInput
  QuestionType: QuestionType
  Questionnaire: ResolverTypeWrapper<Questionnaire>
  QuestionnaireInput: QuestionnaireInput
  QuestionnairePurpose: QuestionnairePurpose
  Quotation: ResolverTypeWrapper<Quotation>
  QuoteDataSource: QuoteDataSource
  RAStatus: RaStatus
  RateHistorical: ResolverTypeWrapper<RateHistorical>
  RateHistoricalFilterInput: RateHistoricalFilterInput
  RecordsType: RecordsType
  RejectFiatWithdrawalInput: RejectFiatWithdrawalInput
  RejectFiatWithdrawalResponse: ResolverTypeWrapper<RejectFiatWithdrawalResponse>
  Release: ResolverTypeWrapper<Release>
  ReleaseCollection: ResolverTypeWrapper<ReleaseCollection>
  ReleaseFilter: ReleaseFilter
  ReleaseLinkingCollections: ResolverTypeWrapper<ReleaseLinkingCollections>
  ReleaseNote: ResolverTypeWrapper<ReleaseNote>
  ReleaseNoteCollection: ResolverTypeWrapper<ReleaseNoteCollection>
  ReleaseNoteFilter: ReleaseNoteFilter
  ReleaseNoteLinkingCollections: ResolverTypeWrapper<ReleaseNoteLinkingCollections>
  ReleaseNoteLinkingCollectionsReleaseCollectionOrder: ReleaseNoteLinkingCollectionsReleaseCollectionOrder
  ReleaseNoteOrder: ReleaseNoteOrder
  ReleaseNoteReleaseNote: ResolverTypeWrapper<ReleaseNoteReleaseNote>
  ReleaseNoteReleaseNoteAssets: ResolverTypeWrapper<ReleaseNoteReleaseNoteAssets>
  ReleaseNoteReleaseNoteEntries: ResolverTypeWrapper<ReleaseNoteReleaseNoteEntries>
  ReleaseNoteReleaseNoteLinks: ResolverTypeWrapper<ReleaseNoteReleaseNoteLinks>
  ReleaseNoteReleaseNoteResources: ResolverTypeWrapper<ReleaseNoteReleaseNoteResources>
  ReleaseNotesCollection: ResolverTypeWrapper<ReleaseNotesCollection>
  ReleaseNotesCollectionOrder: ReleaseNotesCollectionOrder
  ReleaseOrder: ReleaseOrder
  ResourceConfirmUploadInput: ResourceConfirmUploadInput
  ResourceConfirmUploadResponse: ResolverTypeWrapper<ResourceConfirmUploadResponse>
  ResourceLink: ResolverTypeWrapper<ResourceLink>
  ResourceMutation: ResolverTypeWrapper<ResourceMutation>
  ResourceRequestUploadURLInput: ResourceRequestUploadUrlInput
  ResourceRequestUploadURLResponse: ResolverTypeWrapper<ResourceRequestUploadUrlResponse>
  ResourceSys: ResolverTypeWrapper<ResourceSys>
  Response2FA: ResolverTypeWrapper<Response2Fa>
  ReyieldAttributes: ResolverTypeWrapper<ReyieldAttributes>
  ReyieldBalance: ResolverTypeWrapper<ReyieldBalance>
  ReyieldCefiDepositAddress: ResolverTypeWrapper<ReyieldCefiDepositAddress>
  ReyieldCefiDepositAddressInput: ReyieldCefiDepositAddressInput
  ReyieldCefiWallet: ResolverTypeWrapper<ReyieldCefiWallet>
  ReyieldCefiWalletFilterInput: ReyieldCefiWalletFilterInput
  ReyieldChain: ReyieldChain
  ReyieldCloseUniswapv3PositionInput: ReyieldCloseUniswapv3PositionInput
  ReyieldCoinPrice: ResolverTypeWrapper<ReyieldCoinPrice>
  ReyieldCreateAICompletionRecordInput: ReyieldCreateAiCompletionRecordInput
  ReyieldCreateAICompletionRecordItemInput: ReyieldCreateAiCompletionRecordItemInput
  ReyieldCreateUniswapv3PositionInput: ReyieldCreateUniswapv3PositionInput
  ReyieldCreateUniswapv3ToolInput: ReyieldCreateUniswapv3ToolInput
  ReyieldCreator: ResolverTypeWrapper<ReyieldCreator>
  ReyieldCurrencyBalances: ResolverTypeWrapper<ReyieldCurrencyBalances>
  ReyieldCurrencyInfo: ResolverTypeWrapper<ReyieldCurrencyInfo>
  ReyieldDefiTokensBalanceAmountInput: ReyieldDefiTokensBalanceAmountInput
  ReyieldDefiWallet: ResolverTypeWrapper<ReyieldDefiWallet>
  ReyieldDefiWalletFilterInput: ReyieldDefiWalletFilterInput
  ReyieldDefiWalletsFilterInput: ReyieldDefiWalletsFilterInput
  ReyieldEvmChain: ReyieldEvmChain
  ReyieldExchange: ReyieldExchange
  ReyieldExternalAPIKey: ResolverTypeWrapper<ReyieldExternalApiKey>
  ReyieldExternalAPIKeyCreateInput: ReyieldExternalApiKeyCreateInput
  ReyieldExternalAPIKeyDeleteInput: ReyieldExternalApiKeyDeleteInput
  ReyieldExternalAPIKeysFilter: ReyieldExternalApiKeysFilter
  ReyieldFrontendVersion: ResolverTypeWrapper<ReyieldFrontendVersion>
  ReyieldGimCoolPayPaymentInfo: ResolverTypeWrapper<ReyieldGimCoolPayPaymentInfo>
  ReyieldGimCoolPayPaymentInfoInput: ReyieldGimCoolPayPaymentInfoInput
  ReyieldGimCoolPayPaymentSupportingBlockchain: ReyieldGimCoolPayPaymentSupportingBlockchain
  ReyieldGimCoolPayPaymentSupportingCoinType: ReyieldGimCoolPayPaymentSupportingCoinType
  ReyieldGimCoolPayPaymentSupportingFiatType: ReyieldGimCoolPayPaymentSupportingFiatType
  ReyieldGimCoolPayPriceResponse: ResolverTypeWrapper<ReyieldGimCoolPayPriceResponse>
  ReyieldGimCoolPayReadyToPurchasePrice: ResolverTypeWrapper<ReyieldGimCoolPayReadyToPurchasePrice>
  ReyieldLicense: ResolverTypeWrapper<ReyieldLicense>
  ReyieldNFTCollections: ResolverTypeWrapper<ReyieldNftCollections>
  ReyieldNFTMerkleTreeHexProof: ResolverTypeWrapper<ReyieldNftMerkleTreeHexProof>
  ReyieldNFTWhitelistInfoByPhrase: ResolverTypeWrapper<ReyieldNftWhitelistInfoByPhrase>
  ReyieldNFT_ROUND: ReyieldNft_Round
  ReyieldOutOfRangeInfoInput: ReyieldOutOfRangeInfoInput
  ReyieldPoolsWithArgumentsFlag: ReyieldPoolsWithArgumentsFlag
  ReyieldPromptVersion: ReyieldPromptVersion
  ReyieldQuoteDataSource: ReyieldQuoteDataSource
  ReyieldRateHistorical: ResolverTypeWrapper<ReyieldRateHistorical>
  ReyieldRateHistoricalFilterInput: ReyieldRateHistoricalFilterInput
  ReyieldRecordsType: ReyieldRecordsType
  ReyieldReyieldLicense: ResolverTypeWrapper<ReyieldReyieldLicense>
  ReyieldRiskLevel: ReyieldRiskLevel
  ReyieldRiskToleranceLevelName: ReyieldRiskToleranceLevelName
  ReyieldSmartContract: ResolverTypeWrapper<ReyieldSmartContract>
  ReyieldStrategyFilterInput: ReyieldStrategyFilterInput
  ReyieldStrategyInfo: ResolverTypeWrapper<ReyieldStrategyInfo>
  ReyieldStrategyInfoStage: ReyieldStrategyInfoStage
  ReyieldStrategyInfos: ResolverTypeWrapper<ReyieldStrategyInfos>
  ReyieldStrategyParameterType: ReyieldStrategyParameterType
  ReyieldStrategyParameters: ResolverTypeWrapper<ReyieldStrategyParameters>
  ReyieldStrategyPerformance: ResolverTypeWrapper<ReyieldStrategyPerformance>
  ReyieldStrategyProgress: ResolverTypeWrapper<ReyieldStrategyProgress>
  ReyieldStrategyProgressStatus: ReyieldStrategyProgressStatus
  ReyieldStrategyProgressStep: ResolverTypeWrapper<ReyieldStrategyProgressStep>
  ReyieldStrategyProgressStepStatus: ReyieldStrategyProgressStepStatus
  ReyieldStrategyProgressStepType: ReyieldStrategyProgressStepType
  ReyieldStrategyProgressType: ReyieldStrategyProgressType
  ReyieldStrategyStartInput: ReyieldStrategyStartInput
  ReyieldStrategyStartParamenters: ReyieldStrategyStartParamenters
  ReyieldStrategyStartResponse: ResolverTypeWrapper<ReyieldStrategyStartResponse>
  ReyieldStrategyStatus: ReyieldStrategyStatus
  ReyieldStrategyStopInput: ReyieldStrategyStopInput
  ReyieldStrategyStopResponse: ResolverTypeWrapper<ReyieldStrategyStopResponse>
  ReyieldStrategyType: ReyieldStrategyType
  ReyieldStrategyUpdateInput: ReyieldStrategyUpdateInput
  ReyieldStrategyUpdateParamenters: ReyieldStrategyUpdateParamenters
  ReyieldStrategyUpdateResponse: ResolverTypeWrapper<ReyieldStrategyUpdateResponse>
  ReyieldString_comparison_exp: ReyieldString_Comparison_Exp
  ReyieldTokenBalance: ResolverTypeWrapper<ReyieldTokenBalance>
  ReyieldTokenDisplayInfo: ResolverTypeWrapper<ReyieldTokenDisplayInfo>
  ReyieldToolInfo: ResolverTypeWrapper<ResolversUnionTypes['ReyieldToolInfo']>
  ReyieldTotalProfits: ResolverTypeWrapper<ReyieldTotalProfits>
  ReyieldUniswapOnchainPositionAmountInfo: ResolverTypeWrapper<ReyieldUniswapOnchainPositionAmountInfo>
  ReyieldUniswapPoolInfo: ResolverTypeWrapper<ReyieldUniswapPoolInfo>
  ReyieldUniswapPoolPosition: ResolverTypeWrapper<ReyieldUniswapPoolPosition>
  ReyieldUniswapPoolTick: ResolverTypeWrapper<ReyieldUniswapPoolTick>
  ReyieldUniswapQuote: ResolverTypeWrapper<ReyieldUniswapQuote>
  ReyieldUniswapQuoteInput: ReyieldUniswapQuoteInput
  ReyieldUniswapToolFeeTier: ResolverTypeWrapper<ReyieldUniswapToolFeeTier>
  ReyieldUniswapToolInfo: ResolverTypeWrapper<ReyieldUniswapToolInfo>
  ReyieldUniswapToolPositionStatus: ReyieldUniswapToolPositionStatus
  ReyieldUniswapToolStatus: ReyieldUniswapToolStatus
  ReyieldUniswapToolTag: ResolverTypeWrapper<ReyieldUniswapToolTag>
  ReyieldUniswapToolTagType: ReyieldUniswapToolTagType
  ReyieldUniswapToolToken: ResolverTypeWrapper<ReyieldUniswapToolToken>
  ReyieldUniswapToolTokenPriceChart: ResolverTypeWrapper<ReyieldUniswapToolTokenPriceChart>
  ReyieldUniswapToolTokenPriceChartPrice: ResolverTypeWrapper<ReyieldUniswapToolTokenPriceChartPrice>
  ReyieldUniswapV3BacktestPeriod: ReyieldUniswapV3BacktestPeriod
  ReyieldUniswapV3BacktestResult: ResolverTypeWrapper<ReyieldUniswapV3BacktestResult>
  ReyieldUserAICompletionRecordsFilterInput: ReyieldUserAiCompletionRecordsFilterInput
  ReyieldUserInfo: ResolverTypeWrapper<
    Omit<ReyieldUserInfo, 'tools'> & { tools?: Maybe<Array<ResolversTypes['ReyieldUserToolInfo']>> }
  >
  ReyieldUserStrategyFilterInput: ReyieldUserStrategyFilterInput
  ReyieldUserStrategyInfo: ResolverTypeWrapper<ReyieldUserStrategyInfo>
  ReyieldUserStrategyParameters: ResolverTypeWrapper<ReyieldUserStrategyParameters>
  ReyieldUserStrategyPerformance: ResolverTypeWrapper<ReyieldUserStrategyPerformance>
  ReyieldUserStrategyProfit: ResolverTypeWrapper<ReyieldUserStrategyProfit>
  ReyieldUserStrategyProfitFilterInput: ReyieldUserStrategyProfitFilterInput
  ReyieldUserStrategyRecord: ResolverTypeWrapper<ReyieldUserStrategyRecord>
  ReyieldUserStrategyRecordsFilterInput: ReyieldUserStrategyRecordsFilterInput
  ReyieldUserToolInfo: ResolverTypeWrapper<ResolversUnionTypes['ReyieldUserToolInfo']>
  ReyieldUserUniswapOnchainPositionInfo: ResolverTypeWrapper<ReyieldUserUniswapOnchainPositionInfo>
  ReyieldUserUniswapPositionInfo: ResolverTypeWrapper<ReyieldUserUniswapPositionInfo>
  ReyieldUserUniswapToolOutOfRangeInfo: ResolverTypeWrapper<ReyieldUserUniswapToolOutOfRangeInfo>
  ReyieldUserUniswapToolSettingInfo: ResolverTypeWrapper<ReyieldUserUniswapToolSettingInfo>
  ReyieldUserUniswapv3PositionProgress: ResolverTypeWrapper<ReyieldUserUniswapv3PositionProgress>
  ReyieldUserUniswapv3PositionProgressStatus: ReyieldUserUniswapv3PositionProgressStatus
  ReyieldUserUniswapv3PositionProgressStep: ResolverTypeWrapper<ReyieldUserUniswapv3PositionProgressStep>
  ReyieldUserUniswapv3PositionProgressStepStatus: ReyieldUserUniswapv3PositionProgressStepStatus
  ReyieldUserUniswapv3PositionProgressStepType: ReyieldUserUniswapv3PositionProgressStepType
  ReyieldUserUniswapv3PositionProgressType: ReyieldUserUniswapv3PositionProgressType
  ReyieldWalletBalances: ResolverTypeWrapper<ReyieldWalletBalances>
  ReyieldWalletType: ReyieldWalletType
  ReyieldaICompletionRecords: ResolverTypeWrapper<ReyieldaICompletionRecords>
  Reyieldorder_by: Reyieldorder_By
  ReyieldsuggestStrategyToolWithAllocations: ResolverTypeWrapper<ReyieldsuggestStrategyToolWithAllocations>
  ReyieldsuggestStrategyToolWithAllocations_aggregate: ResolverTypeWrapper<ReyieldsuggestStrategyToolWithAllocations_Aggregate>
  ReyieldsuggestStrategyToolWithAllocations_aggregate_fields: ResolverTypeWrapper<ReyieldsuggestStrategyToolWithAllocations_Aggregate_Fields>
  ReyieldsuggestStrategyToolWithAllocations_bool_exp: ReyieldsuggestStrategyToolWithAllocations_Bool_Exp
  ReyieldsuggestStrategyToolWithAllocations_max_fields: ResolverTypeWrapper<ReyieldsuggestStrategyToolWithAllocations_Max_Fields>
  ReyieldsuggestStrategyToolWithAllocations_min_fields: ResolverTypeWrapper<ReyieldsuggestStrategyToolWithAllocations_Min_Fields>
  ReyieldsuggestStrategyToolWithAllocations_order_by: ReyieldsuggestStrategyToolWithAllocations_Order_By
  ReyieldsuggestStrategyToolWithAllocations_select_column: ReyieldsuggestStrategyToolWithAllocations_Select_Column
  Reyieldtimestamptz_comparison_exp: Reyieldtimestamptz_Comparison_Exp
  Reyielduuid_comparison_exp: Reyielduuid_Comparison_Exp
  RiskLevel: RiskLevel
  RiskToleranceLevelName: RiskToleranceLevelName
  Section: ResolverTypeWrapper<Section>
  SectionCollection: ResolverTypeWrapper<SectionCollection>
  SectionContent: ResolverTypeWrapper<SectionContent>
  SectionContentCollection: ResolverTypeWrapper<SectionContentCollection>
  SectionContentDescription: ResolverTypeWrapper<SectionContentDescription>
  SectionContentDescriptionAssets: ResolverTypeWrapper<SectionContentDescriptionAssets>
  SectionContentDescriptionEntries: ResolverTypeWrapper<SectionContentDescriptionEntries>
  SectionContentDescriptionLinks: ResolverTypeWrapper<SectionContentDescriptionLinks>
  SectionContentDescriptionResources: ResolverTypeWrapper<SectionContentDescriptionResources>
  SectionContentFilter: SectionContentFilter
  SectionContentLinkingCollections: ResolverTypeWrapper<SectionContentLinkingCollections>
  SectionContentLinkingCollectionsSectionCollectionOrder: SectionContentLinkingCollectionsSectionCollectionOrder
  SectionContentOrder: SectionContentOrder
  SectionFilter: SectionFilter
  SectionLinkingCollections: ResolverTypeWrapper<SectionLinkingCollections>
  SectionLinkingCollectionsStrategyMetaCollectionOrder: SectionLinkingCollectionsStrategyMetaCollectionOrder
  SectionOrder: SectionOrder
  SectionSectionContentsCollection: ResolverTypeWrapper<SectionSectionContentsCollection>
  SectionSectionContentsCollectionOrder: SectionSectionContentsCollectionOrder
  SmartContract: ResolverTypeWrapper<SmartContract>
  SpecialUser: ResolverTypeWrapper<SpecialUser>
  SpecialUserCollection: ResolverTypeWrapper<SpecialUserCollection>
  SpecialUserFilter: SpecialUserFilter
  SpecialUserFilter_remote_rel_bitginspecial: SpecialUserFilter_Remote_Rel_Bitginspecial
  SpecialUserLinkingCollections: ResolverTypeWrapper<SpecialUserLinkingCollections>
  SpecialUserLinkingCollectionsDisplayItemCollectionOrder: SpecialUserLinkingCollectionsDisplayItemCollectionOrder
  SpecialUserOrder: SpecialUserOrder
  StrategyFilterInput: StrategyFilterInput
  StrategyInfo: ResolverTypeWrapper<StrategyInfo>
  StrategyInfoStage: StrategyInfoStage
  StrategyInfos: ResolverTypeWrapper<StrategyInfos>
  StrategyMeta: ResolverTypeWrapper<StrategyMeta>
  StrategyMetaCollection: ResolverTypeWrapper<StrategyMetaCollection>
  StrategyMetaFilter: StrategyMetaFilter
  StrategyMetaFilter_remote_rel_bitgin_strategydisplayInfo: StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo
  StrategyMetaLinkingCollections: ResolverTypeWrapper<StrategyMetaLinkingCollections>
  StrategyMetaOrder: StrategyMetaOrder
  StrategyMetaSectionsCollection: ResolverTypeWrapper<StrategyMetaSectionsCollection>
  StrategyMetaSectionsCollectionOrder: StrategyMetaSectionsCollectionOrder
  StrategyParameterType: StrategyParameterType
  StrategyParameters: ResolverTypeWrapper<StrategyParameters>
  StrategyPerformance: ResolverTypeWrapper<StrategyPerformance>
  StrategyProgress: ResolverTypeWrapper<StrategyProgress>
  StrategyProgressStatus: StrategyProgressStatus
  StrategyProgressStep: ResolverTypeWrapper<StrategyProgressStep>
  StrategyProgressStepStatus: StrategyProgressStepStatus
  StrategyProgressStepType: StrategyProgressStepType
  StrategyProgressType: StrategyProgressType
  StrategyStartInput: StrategyStartInput
  StrategyStartParamenters: StrategyStartParamenters
  StrategyStartResponse: ResolverTypeWrapper<StrategyStartResponse>
  StrategyStatus: StrategyStatus
  StrategyStopInput: StrategyStopInput
  StrategyStopResponse: ResolverTypeWrapper<StrategyStopResponse>
  StrategyType: StrategyType
  StrategyUpdateInput: StrategyUpdateInput
  StrategyUpdateParamenters: StrategyUpdateParamenters
  StrategyUpdateResponse: ResolverTypeWrapper<StrategyUpdateResponse>
  Strategy_Action:
    | ResolversTypes['Strategy_ActionDEFIApproveSwapReward']
    | ResolversTypes['Strategy_ActionDEFIDeductTxnFee']
    | ResolversTypes['Strategy_ActionDEFIDispatchUSDTReward']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVAddLiquidity']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVApproveConvexBooster']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVApproveCurve3Pool']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVClaimReward']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVDepositAll']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVRemoveLiquidity']
    | ResolversTypes['Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap']
    | ResolversTypes['Strategy_ActionDEFIMIMUSDTApproveCurve3Pool']
    | ResolversTypes['Strategy_ActionDEFIRebalanceTxnFeeEstimation']
    | ResolversTypes['Strategy_ActionDEFIStakePool']
    | ResolversTypes['Strategy_ActionDEFISwapETHToUSDT']
    | ResolversTypes['Strategy_ActionDEFISwapReward']
    | ResolversTypes['Strategy_ActionDEFITransferUserLPBalanceToPool']
    | ResolversTypes['Strategy_ActionDEFIUnStakePool']
    | ResolversTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity']
    | ResolversTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward']
    | ResolversTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity']
    | ResolversTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT']
    | ResolversTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward']
    | ResolversTypes['Strategy_ActionDEFIUpdateUserRecordStatus']
    | ResolversTypes['Strategy_ActionExternalDepositCheck']
    | ResolversTypes['Strategy_ActionExternalSwap']
    | ResolversTypes['Strategy_ActionExternalWalletTransfer']
    | ResolversTypes['Strategy_ActionExternalWithdrawal']
    | ResolversTypes['Strategy_ActionInternalDepositCheck']
    | ResolversTypes['Strategy_ActionInternalTrade']
    | ResolversTypes['Strategy_ActionInternalWithdrawal']
    | ResolversTypes['Strategy_ActionStrategyBatchStart']
    | ResolversTypes['Strategy_ActionStrategyBatchStop']
    | ResolversTypes['Strategy_ActionStrategyBatchUpdate']
    | ResolversTypes['Strategy_ActionStrategyStart']
    | ResolversTypes['Strategy_ActionStrategyStop']
    | ResolversTypes['Strategy_ActionStrategyUpdate']
    | ResolversTypes['Strategy_ActionTransferFromDEFIWalletPostProcessing']
    | ResolversTypes['Strategy_ActionTransferFromDEFIWalletPreProcessing']
    | ResolversTypes['Strategy_ActionTransferFromDEFIWalletProcessing']
    | ResolversTypes['Strategy_ActionTransferToDEFIWalletPostProcessing']
    | ResolversTypes['Strategy_ActionTransferToDEFIWalletPreProcessing']
    | ResolversTypes['Strategy_ActionTransferToDEFIWalletProcessing']
    | ResolversTypes['Strategy_ActionWatchTxnStatus']
  Strategy_ActionDEFIApproveSwapReward: ResolverTypeWrapper<Strategy_ActionDefiApproveSwapReward>
  Strategy_ActionDEFIDeductTxnFee: ResolverTypeWrapper<Strategy_ActionDefiDeductTxnFee>
  Strategy_ActionDEFIDispatchUSDTReward: ResolverTypeWrapper<Strategy_ActionDefiDispatchUsdtReward>
  Strategy_ActionDEFIMIM3CRVAddLiquidity: ResolverTypeWrapper<Strategy_ActionDefimim3CrvAddLiquidity>
  Strategy_ActionDEFIMIM3CRVApproveConvexBooster: ResolverTypeWrapper<Strategy_ActionDefimim3CrvApproveConvexBooster>
  Strategy_ActionDEFIMIM3CRVApproveCurve3Pool: ResolverTypeWrapper<Strategy_ActionDefimim3CrvApproveCurve3Pool>
  Strategy_ActionDEFIMIM3CRVClaimReward: ResolverTypeWrapper<Strategy_ActionDefimim3CrvClaimReward>
  Strategy_ActionDEFIMIM3CRVDepositAll: ResolverTypeWrapper<Strategy_ActionDefimim3CrvDepositAll>
  Strategy_ActionDEFIMIM3CRVRemoveLiquidity: ResolverTypeWrapper<Strategy_ActionDefimim3CrvRemoveLiquidity>
  Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap: ResolverTypeWrapper<Strategy_ActionDefimim3CrvWithdrawAndUnwrap>
  Strategy_ActionDEFIMIMUSDTApproveCurve3Pool: ResolverTypeWrapper<Strategy_ActionDefimimusdtApproveCurve3Pool>
  Strategy_ActionDEFIRebalanceTxnFeeEstimation: ResolverTypeWrapper<Strategy_ActionDefiRebalanceTxnFeeEstimation>
  Strategy_ActionDEFIStakePool: ResolverTypeWrapper<Strategy_ActionDefiStakePool>
  Strategy_ActionDEFISwapETHToUSDT: ResolverTypeWrapper<Strategy_ActionDefiSwapEthToUsdt>
  Strategy_ActionDEFISwapReward: ResolverTypeWrapper<Strategy_ActionDefiSwapReward>
  Strategy_ActionDEFITransferUserLPBalanceToPool: ResolverTypeWrapper<Strategy_ActionDefiTransferUserLpBalanceToPool>
  Strategy_ActionDEFIUnStakePool: ResolverTypeWrapper<Strategy_ActionDefiUnStakePool>
  Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity: ResolverTypeWrapper<Strategy_ActionDefiUpdatePoolBalanceAfterAddLiquidity>
  Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward: ResolverTypeWrapper<Strategy_ActionDefiUpdatePoolBalanceAfterClaimReward>
  Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity: ResolverTypeWrapper<Strategy_ActionDefiUpdatePoolBalanceAfterRmLiquidity>
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT: ResolverTypeWrapper<Strategy_ActionDefiUpdatePoolBalanceAfterSwapEthToUsdt>
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward: ResolverTypeWrapper<Strategy_ActionDefiUpdatePoolBalanceAfterSwapReward>
  Strategy_ActionDEFIUpdateUserRecordStatus: ResolverTypeWrapper<Strategy_ActionDefiUpdateUserRecordStatus>
  Strategy_ActionExternalDepositCheck: ResolverTypeWrapper<Strategy_ActionExternalDepositCheck>
  Strategy_ActionExternalSwap: ResolverTypeWrapper<Strategy_ActionExternalSwap>
  Strategy_ActionExternalWalletTransfer: ResolverTypeWrapper<Strategy_ActionExternalWalletTransfer>
  Strategy_ActionExternalWithdrawal: ResolverTypeWrapper<Strategy_ActionExternalWithdrawal>
  Strategy_ActionInternalDepositCheck: ResolverTypeWrapper<Strategy_ActionInternalDepositCheck>
  Strategy_ActionInternalTrade: ResolverTypeWrapper<Strategy_ActionInternalTrade>
  Strategy_ActionInternalWithdrawal: ResolverTypeWrapper<Strategy_ActionInternalWithdrawal>
  Strategy_ActionLog: ResolverTypeWrapper<Strategy_ActionLog>
  Strategy_ActionLogFilter: Strategy_ActionLogFilter
  Strategy_ActionStrategyBatchStart: ResolverTypeWrapper<Strategy_ActionStrategyBatchStart>
  Strategy_ActionStrategyBatchStop: ResolverTypeWrapper<Strategy_ActionStrategyBatchStop>
  Strategy_ActionStrategyBatchUpdate: ResolverTypeWrapper<Strategy_ActionStrategyBatchUpdate>
  Strategy_ActionStrategyStart: ResolverTypeWrapper<Strategy_ActionStrategyStart>
  Strategy_ActionStrategyStop: ResolverTypeWrapper<Strategy_ActionStrategyStop>
  Strategy_ActionStrategyUpdate: ResolverTypeWrapper<Strategy_ActionStrategyUpdate>
  Strategy_ActionTransferFromDEFIWalletPostProcessing: ResolverTypeWrapper<Strategy_ActionTransferFromDefiWalletPostProcessing>
  Strategy_ActionTransferFromDEFIWalletPreProcessing: ResolverTypeWrapper<Strategy_ActionTransferFromDefiWalletPreProcessing>
  Strategy_ActionTransferFromDEFIWalletProcessing: ResolverTypeWrapper<Strategy_ActionTransferFromDefiWalletProcessing>
  Strategy_ActionTransferToDEFIWalletPostProcessing: ResolverTypeWrapper<Strategy_ActionTransferToDefiWalletPostProcessing>
  Strategy_ActionTransferToDEFIWalletPreProcessing: ResolverTypeWrapper<Strategy_ActionTransferToDefiWalletPreProcessing>
  Strategy_ActionTransferToDEFIWalletProcessing: ResolverTypeWrapper<Strategy_ActionTransferToDefiWalletProcessing>
  Strategy_ActionWatchTxnStatus: ResolverTypeWrapper<Strategy_ActionWatchTxnStatus>
  Strategy_Admin: ResolverTypeWrapper<Strategy_Admin>
  Strategy_AdminExternalAPIKeyFilter: Strategy_AdminExternalApiKeyFilter
  Strategy_AdminMutation: ResolverTypeWrapper<Strategy_AdminMutation>
  Strategy_AdminWorkflowFilter: Strategy_AdminWorkflowFilter
  Strategy_AdminWorkflowRetryInput: Strategy_AdminWorkflowRetryInput
  Strategy_ArbitrageState: ResolverTypeWrapper<Strategy_ArbitrageState>
  Strategy_Balance: ResolverTypeWrapper<Strategy_Balance>
  Strategy_CurrencyAmount: ResolverTypeWrapper<Strategy_CurrencyAmount>
  Strategy_DEFINormalFlowInput: Strategy_DefiNormalFlowInput
  Strategy_DEFIRebalanceFlowInput: Strategy_DefiRebalanceFlowInput
  Strategy_DEFIWithdrawExpressFlowInput: Strategy_DefiWithdrawExpressFlowInput
  Strategy_DailyProfit: ResolverTypeWrapper<Strategy_DailyProfit>
  Strategy_DebtRecord: ResolverTypeWrapper<Strategy_DebtRecord>
  Strategy_EstimatePointsConsumingInput: Strategy_EstimatePointsConsumingInput
  Strategy_EstimatePointsConsumingResult: ResolverTypeWrapper<Strategy_EstimatePointsConsumingResult>
  Strategy_EstimateStrategyRequiredAmountResponse: ResolverTypeWrapper<Strategy_EstimateStrategyRequiredAmountResponse>
  Strategy_ExchangeAsset: ResolverTypeWrapper<Strategy_ExchangeAsset>
  Strategy_ExchangeBalancesInput: Strategy_ExchangeBalancesInput
  Strategy_ExchangeWithdrawalAuthorizationInput: Strategy_ExchangeWithdrawalAuthorizationInput
  Strategy_ExchangeWithdrawalAuthorizationResponse: ResolverTypeWrapper<Strategy_ExchangeWithdrawalAuthorizationResponse>
  Strategy_ExchangesNetAssetChart: ResolverTypeWrapper<Strategy_ExchangesNetAssetChart>
  Strategy_ExchangesNetAssetChartData: ResolverTypeWrapper<Strategy_ExchangesNetAssetChartData>
  Strategy_ExchangesNetAssetChartInput: Strategy_ExchangesNetAssetChartInput
  Strategy_ExternalAPIKey: ResolverTypeWrapper<Strategy_ExternalApiKey>
  Strategy_ExternalAPIKeyCreateInput: Strategy_ExternalApiKeyCreateInput
  Strategy_ExternalAPIKeyDeleteInput: Strategy_ExternalApiKeyDeleteInput
  Strategy_ExternalAPIKeyDepositInput: Strategy_ExternalApiKeyDepositInput
  Strategy_ExternalAPIKeyFilter: Strategy_ExternalApiKeyFilter
  Strategy_ExternalAPIKeyMutation: ResolverTypeWrapper<Strategy_ExternalApiKeyMutation>
  Strategy_ExternalAPIKeySwapInput: Strategy_ExternalApiKeySwapInput
  Strategy_ExternalAPIKeyTransferBetweenWalletsInput: Strategy_ExternalApiKeyTransferBetweenWalletsInput
  Strategy_ExternalAPIKeyWithdrawal: ResolverTypeWrapper<Strategy_ExternalApiKeyWithdrawal>
  Strategy_ExternalAPIKeyWithdrawalConfirmInput: Strategy_ExternalApiKeyWithdrawalConfirmInput
  Strategy_ExternalAPIKeyWithdrawalInput: Strategy_ExternalApiKeyWithdrawalInput
  Strategy_GenericFlowMutation: ResolverTypeWrapper<Strategy_GenericFlowMutation>
  Strategy_LendingOffer: ResolverTypeWrapper<Strategy_LendingOffer>
  Strategy_LendingState: ResolverTypeWrapper<Strategy_LendingState>
  Strategy_Number: ResolverTypeWrapper<Scalars['Strategy_Number']>
  Strategy_Performance: ResolverTypeWrapper<Strategy_Performance>
  Strategy_ProfitInputFilter: Strategy_ProfitInputFilter
  Strategy_Public: ResolverTypeWrapper<Strategy_Public>
  Strategy_RenewAction: Strategy_RenewAction
  Strategy_Strategy: ResolverTypeWrapper<
    Omit<Strategy_Strategy, 'state'> & { state?: Maybe<ResolversTypes['Strategy_StrategyState']> }
  >
  Strategy_StrategyCloseInput: Strategy_StrategyCloseInput
  Strategy_StrategyDeFiStartInput: Strategy_StrategyDeFiStartInput
  Strategy_StrategyDebt: ResolverTypeWrapper<Strategy_StrategyDebt>
  Strategy_StrategyDescription: ResolverTypeWrapper<Strategy_StrategyDescription>
  Strategy_StrategyFilter: Strategy_StrategyFilter
  Strategy_StrategyInfo: ResolverTypeWrapper<Strategy_StrategyInfo>
  Strategy_StrategyMutation: ResolverTypeWrapper<Strategy_StrategyMutation>
  Strategy_StrategyParameter: ResolverTypeWrapper<Strategy_StrategyParameter>
  Strategy_StrategyParameterDesc: ResolverTypeWrapper<Strategy_StrategyParameterDesc>
  Strategy_StrategyParameterInput: Strategy_StrategyParameterInput
  Strategy_StrategyParameterType: Strategy_StrategyParameterType
  Strategy_StrategyPerformance: ResolverTypeWrapper<Strategy_StrategyPerformance>
  Strategy_StrategyRetryInput: Strategy_StrategyRetryInput
  Strategy_StrategyStartInput: Strategy_StrategyStartInput
  Strategy_StrategyState: ResolverTypeWrapper<ResolversUnionTypes['Strategy_StrategyState']>
  Strategy_StrategyStopInput: Strategy_StrategyStopInput
  Strategy_StrategyUpdateInput: Strategy_StrategyUpdateInput
  Strategy_Time: ResolverTypeWrapper<Scalars['Strategy_Time']>
  Strategy_Token: Strategy_Token
  Strategy_User: ResolverTypeWrapper<Strategy_User>
  Strategy_UserMutation: ResolverTypeWrapper<Strategy_UserMutation>
  Strategy_UserProfit: ResolverTypeWrapper<Strategy_UserProfit>
  Strategy_WithdrawalCryptoStartInput: Strategy_WithdrawalCryptoStartInput
  Strategy_WithdrawalFiatStartInput: Strategy_WithdrawalFiatStartInput
  Strategy_WithdrawalRetryInput: Strategy_WithdrawalRetryInput
  Strategy_Workflow: ResolverTypeWrapper<Strategy_Workflow>
  Strategy_WorkflowAction: Strategy_WorkflowAction
  Strategy_WorkflowActionStatus: Strategy_WorkflowActionStatus
  Strategy_WorkflowFilter: Strategy_WorkflowFilter
  Strategy_WorkflowStatus: Strategy_WorkflowStatus
  Strategy_WorkflowType: Strategy_WorkflowType
  Strategy_YieldRecord: ResolverTypeWrapper<Strategy_YieldRecord>
  String: ResolverTypeWrapper<Scalars['String']>
  String_comparison_exp: String_Comparison_Exp
  Sys: ResolverTypeWrapper<Sys>
  SysFilter: SysFilter
  Time: ResolverTypeWrapper<Scalars['Time']>
  TokenBalance: ResolverTypeWrapper<TokenBalance>
  TokenDisplayInfo: ResolverTypeWrapper<TokenDisplayInfo>
  ToolInfo: ResolverTypeWrapper<ResolversUnionTypes['ToolInfo']>
  TotalProfits: ResolverTypeWrapper<TotalProfits>
  Trade: ResolverTypeWrapper<Trade>
  TradeFilter: TradeFilter
  TradeResponse: ResolverTypeWrapper<TradeResponse>
  Trading: ResolverTypeWrapper<Trading>
  TradingConfirmQuotationResponse: ResolverTypeWrapper<TradingConfirmQuotationResponse>
  TradingMutation: ResolverTypeWrapper<TradingMutation>
  TradingQuotationInput: TradingQuotationInput
  TradingQuotationResponse: ResolverTypeWrapper<TradingQuotationResponse>
  TradingReportResponse: ResolverTypeWrapper<TradingReportResponse>
  UniswapOnchainPositionAmountInfo: ResolverTypeWrapper<UniswapOnchainPositionAmountInfo>
  UniswapPoolInfo: ResolverTypeWrapper<UniswapPoolInfo>
  UniswapPoolPosition: ResolverTypeWrapper<UniswapPoolPosition>
  UniswapPoolTick: ResolverTypeWrapper<UniswapPoolTick>
  UniswapQuote: ResolverTypeWrapper<UniswapQuote>
  UniswapQuoteInput: UniswapQuoteInput
  UniswapToolFeeTier: ResolverTypeWrapper<UniswapToolFeeTier>
  UniswapToolInfo: ResolverTypeWrapper<UniswapToolInfo>
  UniswapToolPositionStatus: UniswapToolPositionStatus
  UniswapToolStatus: UniswapToolStatus
  UniswapToolTag: ResolverTypeWrapper<UniswapToolTag>
  UniswapToolTagType: UniswapToolTagType
  UniswapToolToken: ResolverTypeWrapper<UniswapToolToken>
  UniswapToolTokenPriceChart: ResolverTypeWrapper<UniswapToolTokenPriceChart>
  UniswapToolTokenPriceChartPrice: ResolverTypeWrapper<UniswapToolTokenPriceChartPrice>
  UniswapV3BacktestPeriod: UniswapV3BacktestPeriod
  UniswapV3BacktestResult: ResolverTypeWrapper<UniswapV3BacktestResult>
  User: ResolverTypeWrapper<User>
  UserAICompletionRecordsFilterInput: UserAiCompletionRecordsFilterInput
  UserActionLog: ResolverTypeWrapper<UserActionLog>
  UserActionLogResponse: ResolverTypeWrapper<UserActionLogResponse>
  UserAgreeInput: UserAgreeInput
  UserAgreeResponse: ResolverTypeWrapper<UserAgreeResponse>
  UserBindPhoneConfirmationInput: UserBindPhoneConfirmationInput
  UserBindPhoneConfirmationResponse: ResolverTypeWrapper<UserBindPhoneConfirmationResponse>
  UserBindPhoneInput: UserBindPhoneInput
  UserBindPhoneResponse: ResolverTypeWrapper<UserBindPhoneResponse>
  UserCancelOAuthInput: UserCancelOAuthInput
  UserCancelOAuthResponse: ResolverTypeWrapper<UserCancelOAuthResponse>
  UserChangePasswordInput: UserChangePasswordInput
  UserChangePasswordResponse: ResolverTypeWrapper<UserChangePasswordResponse>
  UserCustomizeReferralInput: UserCustomizeReferralInput
  UserCustomizeReferralResponse: ResolverTypeWrapper<UserCustomizeReferralResponse>
  UserDeleteBankAccountResponse: ResolverTypeWrapper<UserDeleteBankAccountResponse>
  UserDeviceTokenInput: UserDeviceTokenInput
  UserDeviceTokenResponse: ResolverTypeWrapper<UserDeviceTokenResponse>
  UserFillReferrerCodeInput: UserFillReferrerCodeInput
  UserFillReferrerCodeResponse: ResolverTypeWrapper<UserFillReferrerCodeResponse>
  UserForgotPasswordConfirmInput: UserForgotPasswordConfirmInput
  UserForgotPasswordConfirmResponse: ResolverTypeWrapper<UserForgotPasswordConfirmResponse>
  UserForgotPasswordInput: UserForgotPasswordInput
  UserForgotPasswordResponse: ResolverTypeWrapper<UserForgotPasswordResponse>
  UserInfo: ResolverTypeWrapper<
    Omit<UserInfo, 'tools'> & { tools?: Maybe<Array<ResolversTypes['UserToolInfo']>> }
  >
  UserLoginInput: UserLoginInput
  UserLoginRecord: ResolverTypeWrapper<UserLoginRecord>
  UserLoginRecordsResponse: ResolverTypeWrapper<UserLoginRecordsResponse>
  UserLoginResponse: ResolverTypeWrapper<
    Omit<UserLoginResponse, 'data'> & { data?: Maybe<ResolversTypes['LoginResponse']> }
  >
  UserMutation: ResolverTypeWrapper<UserMutation>
  UserOAuthClient: ResolverTypeWrapper<UserOAuthClient>
  UserOAuthClientFilter: UserOAuthClientFilter
  UserOAuthClientResponse: ResolverTypeWrapper<UserOAuthClientResponse>
  UserPreference: ResolverTypeWrapper<UserPreference>
  UserPreferenceResponse: ResolverTypeWrapper<UserPreferenceResponse>
  UserReferral: ResolverTypeWrapper<UserReferral>
  UserRefreshTokenInput: UserRefreshTokenInput
  UserRefreshTokenResponse: ResolverTypeWrapper<UserRefreshTokenResponse>
  UserRegisterConfirmInput: UserRegisterConfirmInput
  UserRegisterConfirmResponse: ResolverTypeWrapper<UserRegisterConfirmResponse>
  UserRegisterInput: UserRegisterInput
  UserRegisterResponse: ResolverTypeWrapper<UserRegisterResponse>
  UserRequestAccountDisableResponse: ResolverTypeWrapper<UserRequestAccountDisableResponse>
  UserResendForgotPasswordInput: UserResendForgotPasswordInput
  UserResendForgotPasswordResponse: ResolverTypeWrapper<UserResendForgotPasswordResponse>
  UserResendRegisterInput: UserResendRegisterInput
  UserResendRegisterResponse: ResolverTypeWrapper<UserResendRegisterResponse>
  UserRole: UserRole
  UserScope: UserScope
  UserSetPreferenceInput: UserSetPreferenceInput
  UserStrategyFilterInput: UserStrategyFilterInput
  UserStrategyInfo: ResolverTypeWrapper<UserStrategyInfo>
  UserStrategyParameters: ResolverTypeWrapper<UserStrategyParameters>
  UserStrategyPerformance: ResolverTypeWrapper<UserStrategyPerformance>
  UserStrategyProfit: ResolverTypeWrapper<UserStrategyProfit>
  UserStrategyProfitFilterInput: UserStrategyProfitFilterInput
  UserStrategyRecord: ResolverTypeWrapper<UserStrategyRecord>
  UserStrategyRecordsFilterInput: UserStrategyRecordsFilterInput
  UserToolInfo: ResolverTypeWrapper<ResolversUnionTypes['UserToolInfo']>
  UserUniswapOnchainPositionInfo: ResolverTypeWrapper<UserUniswapOnchainPositionInfo>
  UserUniswapPositionInfo: ResolverTypeWrapper<UserUniswapPositionInfo>
  UserUniswapToolOutOfRangeInfo: ResolverTypeWrapper<UserUniswapToolOutOfRangeInfo>
  UserUniswapToolSettingInfo: ResolverTypeWrapper<UserUniswapToolSettingInfo>
  UserUniswapv3PositionProgress: ResolverTypeWrapper<UserUniswapv3PositionProgress>
  UserUniswapv3PositionProgressStatus: UserUniswapv3PositionProgressStatus
  UserUniswapv3PositionProgressStep: ResolverTypeWrapper<UserUniswapv3PositionProgressStep>
  UserUniswapv3PositionProgressStepStatus: UserUniswapv3PositionProgressStepStatus
  UserUniswapv3PositionProgressStepType: UserUniswapv3PositionProgressStepType
  UserUniswapv3PositionProgressType: UserUniswapv3PositionProgressType
  VIPLevel: VipLevel
  VersionInfo: ResolverTypeWrapper<VersionInfo>
  Voucher: ResolverTypeWrapper<Voucher>
  VoucherMutation: ResolverTypeWrapper<VoucherMutation>
  VoucherRecord: ResolverTypeWrapper<VoucherRecord>
  VoucherUseInput: VoucherUseInput
  VoucherUseResponse: ResolverTypeWrapper<VoucherUseResponse>
  Wallet: ResolverTypeWrapper<Wallet>
  WalletAddressInput: WalletAddressInput
  WalletBalances: ResolverTypeWrapper<WalletBalances>
  WalletCreateCommonAddress: WalletCreateCommonAddress
  WalletCreateCommonAddressResponse: ResolverTypeWrapper<WalletCreateCommonAddressResponse>
  WalletCreateCryptoAddressInput: WalletCreateCryptoAddressInput
  WalletCreateCryptoAddressResponse: ResolverTypeWrapper<WalletCreateCryptoAddressResponse>
  WalletCryptoWithdrawInput: WalletCryptoWithdrawInput
  WalletCryptoWithdrawResponse: ResolverTypeWrapper<
    Omit<WalletCryptoWithdrawResponse, 'data'> & {
      data?: Maybe<ResolversTypes['WithdrawCryptoResponse']>
    }
  >
  WalletDeleteCommonAddress: WalletDeleteCommonAddress
  WalletDeleteCommonAddressResponse: ResolverTypeWrapper<WalletDeleteCommonAddressResponse>
  WalletDepositResponse: ResolverTypeWrapper<WalletDepositResponse>
  WalletFAASPaymentConfirmInput: WalletFaasPaymentConfirmInput
  WalletFAASPaymentConfirmResponse: ResolverTypeWrapper<WalletFaasPaymentConfirmResponse>
  WalletFiatWithdrawInput: WalletFiatWithdrawInput
  WalletFiatWithdrawResponse: ResolverTypeWrapper<
    Omit<WalletFiatWithdrawResponse, 'data'> & {
      data?: Maybe<ResolversTypes['WithdrawFiatResponse']>
    }
  >
  WalletMutation: ResolverTypeWrapper<WalletMutation>
  WalletOperationType: WalletOperationType
  WalletType: WalletType
  WalletWithdrawConfirmInput: WalletWithdrawConfirmInput
  WalletWithdrawConfirmResponse: ResolverTypeWrapper<
    Omit<WalletWithdrawConfirmResponse, 'data'> & { data: ResolversTypes['Withdrawal'] }
  >
  WalletWithdrawalResponse: ResolverTypeWrapper<WalletWithdrawalResponse>
  WithdrawCryptoResponse: ResolverTypeWrapper<ResolversUnionTypes['WithdrawCryptoResponse']>
  WithdrawFiatResponse: ResolverTypeWrapper<ResolversUnionTypes['WithdrawFiatResponse']>
  WithdrawInfo: ResolverTypeWrapper<WithdrawInfo>
  WithdrawInfoFilter: WithdrawInfoFilter
  Withdrawal: ResolverTypeWrapper<ResolversUnionTypes['Withdrawal']>
  WithdrawalFilter: WithdrawalFilter
  WithdrawalResponse: ResolverTypeWrapper<
    Omit<WithdrawalResponse, 'data'> & { data?: Maybe<Array<ResolversTypes['Withdrawal']>> }
  >
  WithdrawalStatus: WithdrawalStatus
  WithdrawalType: WithdrawalType
  aICompletionRecords: ResolverTypeWrapper<AICompletionRecords>
  cfReleaseNoteNestedFilter: CfReleaseNoteNestedFilter
  cfSectionContentNestedFilter: CfSectionContentNestedFilter
  cfSectionNestedFilter: CfSectionNestedFilter
  cfSpecialUserNestedFilter: CfSpecialUserNestedFilter
  cursor_ordering: Cursor_Ordering
  frontendQuery: ResolverTypeWrapper<FrontendQuery>
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>
  mutation_root: ResolverTypeWrapper<Mutation_Root>
  order_by: Order_By
  reyieldMutation: ResolverTypeWrapper<ReyieldMutation>
  reyieldQuery: ResolverTypeWrapper<
    Omit<ReyieldQuery, 'tools'> & { tools: Array<ResolversTypes['ReyieldToolInfo']> }
  >
  strategyMutation: ResolverTypeWrapper<StrategyMutation>
  strategyQuery: ResolverTypeWrapper<StrategyQuery>
  suggestStrategyToolWithAllocations: ResolverTypeWrapper<SuggestStrategyToolWithAllocations>
  suggestStrategyToolWithAllocations_aggregate: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Aggregate>
  suggestStrategyToolWithAllocations_aggregate_bool_exp: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp
  suggestStrategyToolWithAllocations_aggregate_bool_exp_count: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count
  suggestStrategyToolWithAllocations_aggregate_fields: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Aggregate_Fields>
  suggestStrategyToolWithAllocations_aggregate_order_by: SuggestStrategyToolWithAllocations_Aggregate_Order_By
  suggestStrategyToolWithAllocations_bool_exp: SuggestStrategyToolWithAllocations_Bool_Exp
  suggestStrategyToolWithAllocations_max_fields: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Max_Fields>
  suggestStrategyToolWithAllocations_max_order_by: SuggestStrategyToolWithAllocations_Max_Order_By
  suggestStrategyToolWithAllocations_min_fields: ResolverTypeWrapper<SuggestStrategyToolWithAllocations_Min_Fields>
  suggestStrategyToolWithAllocations_min_order_by: SuggestStrategyToolWithAllocations_Min_Order_By
  suggestStrategyToolWithAllocations_order_by: SuggestStrategyToolWithAllocations_Order_By
  suggestStrategyToolWithAllocations_select_column: SuggestStrategyToolWithAllocations_Select_Column
  suggestStrategyToolWithAllocations_stream_cursor_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Input
  suggestStrategyToolWithAllocations_stream_cursor_value_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp
  uuid: ResolverTypeWrapper<Scalars['uuid']>
  uuid_comparison_exp: Uuid_Comparison_Exp
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AML: Aml
  AMLAssessmentItem: AmlAssessmentItem
  AMLEDDRecord: AmleddRecord
  AMLItemCategory: AmlItemCategory
  AMLNSRecord: AmlnsRecord
  AMLRARecord: AmlraRecord
  AMLRatingCriteria: AmlRatingCriteria
  AMLRiskAssessmentForm: AmlRiskAssessmentForm
  AMLRiskFactor: AmlRiskFactor
  AMLUserAssessmentResult: AmlUserAssessmentResult
  AMLVerification: AmlVerification
  APIKey: ApiKey
  AcceptFiatWithdrawalInput: AcceptFiatWithdrawalInput
  AcceptFiatWithdrawalResponse: AcceptFiatWithdrawalResponse
  ActivityNotification: ActivityNotification
  ActivityNotificationCollection: ActivityNotificationCollection
  ActivityNotificationFilter: ActivityNotificationFilter
  ActivityNotificationLinkingCollections: ActivityNotificationLinkingCollections
  Address: Address
  Admin: Admin
  AdminAPIKeyFilter: AdminApiKeyFilter
  AdminAPIKeyResponse: AdminApiKeyResponse
  AdminActivateMerchantInput: AdminActivateMerchantInput
  AdminActivateMerchantResponse: AdminActivateMerchantResponse
  AdminAddAuditNoteInput: AdminAddAuditNoteInput
  AdminAddAuditNoteResponse: AdminAddAuditNoteResponse
  AdminApproveVirtualAccountVerificationInput: AdminApproveVirtualAccountVerificationInput
  AdminApproveVirtualAccountVerificationResponse: AdminApproveVirtualAccountVerificationResponse
  AdminAssignKYCVerifierInput: AdminAssignKycVerifierInput
  AdminAssignKYCVerifierResponse: AdminAssignKycVerifierResponse
  AdminAuditWithdrawalInput: AdminAuditWithdrawalInput
  AdminAuditWithdrawalResponse: AdminAuditWithdrawalResponse
  AdminChangeVirtualAccountLimitInput: AdminChangeVirtualAccountLimitInput
  AdminChangeVirtualAccountLimitResponse: AdminChangeVirtualAccountLimitResponse
  AdminCheckDepositInfoInput: AdminCheckDepositInfoInput
  AdminCheckDepositInfoResponse: AdminCheckDepositInfoResponse
  AdminCheckFileUploadInput: AdminCheckFileUploadInput
  AdminCheckUploadResultMResponse: AdminCheckUploadResultMResponse
  AdminCheckUploadResultP01Response: AdminCheckUploadResultP01Response
  AdminCheckUploadResultP02Response: AdminCheckUploadResultP02Response
  AdminCheckUploadResultP05Response: AdminCheckUploadResultP05Response
  AdminCheckUploadResultPG1Response: AdminCheckUploadResultPg1Response
  AdminCheckUploadResultResponse: AdminCheckUploadResultResponse
  AdminCreateAPIKeyInput: AdminCreateApiKeyInput
  AdminCreateAPIKeyResponse: AdminCreateApiKeyResponse
  AdminCreateOAuthClientInput: AdminCreateOAuthClientInput
  AdminCreateOAuthClientResponse: AdminCreateOAuthClientResponse
  AdminCreateRolesInput: AdminCreateRolesInput
  AdminCreateRolesResponse: AdminCreateRolesResponse
  AdminCreateUserInput: AdminCreateUserInput
  AdminCreateUserResponse: AdminCreateUserResponse
  AdminDVBanInput: AdminDvBanInput
  AdminDVBanResponse: AdminDvBanResponse
  AdminDVUpdateInput: AdminDvUpdateInput
  AdminDVUpdateResponse: AdminDvUpdateResponse
  AdminDeleteAPIKeyInput: AdminDeleteApiKeyInput
  AdminDeleteAPIKeyResponse: AdminDeleteApiKeyResponse
  AdminDeleteRolesInput: AdminDeleteRolesInput
  AdminDeleteRolesResponse: AdminDeleteRolesResponse
  AdminDepositFilter: AdminDepositFilter
  AdminDepositReportResponse: AdminDepositReportResponse
  AdminDepositResponse: Omit<AdminDepositResponse, 'data'> & {
    data?: Maybe<Array<ResolversParentTypes['Deposit']>>
  }
  AdminDisable2FAInput: AdminDisable2FaInput
  AdminDisable2FAResponse: AdminDisable2FaResponse
  AdminDisableUserInput: AdminDisableUserInput
  AdminDisableUserResponse: AdminDisableUserResponse
  AdminEDDAuditInput: AdminEddAuditInput
  AdminEDDAuditResponse: AdminEddAuditResponse
  AdminEnableUserInput: AdminEnableUserInput
  AdminEnableUserResponse: AdminEnableUserResponse
  AdminHolidayAddInput: AdminHolidayAddInput
  AdminHolidayAddResponse: AdminHolidayAddResponse
  AdminHolidayFilter: AdminHolidayFilter
  AdminHolidayResponse: AdminHolidayResponse
  AdminIPUserFilter: AdminIpUserFilter
  AdminIPUserResponse: AdminIpUserResponse
  AdminLedgerFilter: AdminLedgerFilter
  AdminLedgerResponse: AdminLedgerResponse
  AdminMutation: AdminMutation
  AdminNSAuditInput: AdminNsAuditInput
  AdminNSAuditResponse: AdminNsAuditResponse
  AdminOnHoldFiatWithdrawalResponse: AdminOnHoldFiatWithdrawalResponse
  AdminOnHoldKGIVerificationResponse: AdminOnHoldKgiVerificationResponse
  AdminRAAuditInput: AdminRaAuditInput
  AdminRAAuditResponse: AdminRaAuditResponse
  AdminReauditKYCInput: AdminReauditKycInput
  AdminReauditKYCResponse: AdminReauditKycResponse
  AdminRecoverKGIStatusResponse: AdminRecoverKgiStatusResponse
  AdminRefreshMerchantInput: AdminRefreshMerchantInput
  AdminRefreshMerchantResponse: AdminRefreshMerchantResponse
  AdminRejectFiatWithdrawalInput: AdminRejectFiatWithdrawalInput
  AdminRejectFiatWithdrawalResponse: AdminRejectFiatWithdrawalResponse
  AdminRejectKGIVerificationInput: AdminRejectKgiVerificationInput
  AdminRejectKGIVerificationResponse: AdminRejectKgiVerificationResponse
  AdminRejectVirtualAccountVerificationInput: AdminRejectVirtualAccountVerificationInput
  AdminRejectVirtualAccountVerificationResponse: AdminRejectVirtualAccountVerificationResponse
  AdminResendAMLWithdrawInfoP01Response: AdminResendAmlWithdrawInfoP01Response
  AdminResendAMLWithdrawInfoP02Response: AdminResendAmlWithdrawInfoP02Response
  AdminResendFiatWithdrawalInput: AdminResendFiatWithdrawalInput
  AdminResendFiatWithdrawalResponse: AdminResendFiatWithdrawalResponse
  AdminResendKGIVerificationInput: AdminResendKgiVerificationInput
  AdminResendKGIVerificationResponse: AdminResendKgiVerificationResponse
  AdminResendWithdrawInfoP01Input: AdminResendWithdrawInfoP01Input
  AdminResendWithdrawInfoP01Response: AdminResendWithdrawInfoP01Response
  AdminResendWithdrawInfoP02Input: AdminResendWithdrawInfoP02Input
  AdminResendWithdrawInfoP02Response: AdminResendWithdrawInfoP02Response
  AdminResetKYCVerificationInput: AdminResetKycVerificationInput
  AdminResetKYCVerificationResponse: AdminResetKycVerificationResponse
  AdminResetPhoneInput: AdminResetPhoneInput
  AdminResetPhoneResponse: AdminResetPhoneResponse
  AdminSendBGWithdrawInfoInput: AdminSendBgWithdrawInfoInput
  AdminSendBGWithdrawInfoResponse: AdminSendBgWithdrawInfoResponse
  AdminSendInvestorsSettlementResponse: AdminSendInvestorsSettlementResponse
  AdminSendMemberInfoResponse: AdminSendMemberInfoResponse
  AdminSendWithdrawInfoP01Response: AdminSendWithdrawInfoP01Response
  AdminSendWithdrawInfoP02Response: AdminSendWithdrawInfoP02Response
  AdminSetOldUserInput: AdminSetOldUserInput
  AdminSetOlderUserResponse: AdminSetOlderUserResponse
  AdminSetTradePermissionInput: AdminSetTradePermissionInput
  AdminSetTradePermissionResponse: AdminSetTradePermissionResponse
  AdminSubmitAMLVerificationInput: AdminSubmitAmlVerificationInput
  AdminSubmitAMLVerificationResponse: AdminSubmitAmlVerificationResponse
  AdminSubmitEDDAuditInput: AdminSubmitEddAuditInput
  AdminSubmitKYCOneVerificationInput: AdminSubmitKycOneVerificationInput
  AdminSubmitKYCOneVerificationResponse: AdminSubmitKycOneVerificationResponse
  AdminSubmitKYCTwoVerificationInput: AdminSubmitKycTwoVerificationInput
  AdminSubmitKYCTwoVerificationResponse: AdminSubmitKycTwoVerificationResponse
  AdminSubmitNSAuditInput: AdminSubmitNsAuditInput
  AdminSubmitRAAuditInput: AdminSubmitRaAuditInput
  AdminSubmitRAAuditResponse: AdminSubmitRaAuditResponse
  AdminSystemAuditAMLInput: AdminSystemAuditAmlInput
  AdminSystemAuditAMLResponse: AdminSystemAuditAmlResponse
  AdminTradeFilter: AdminTradeFilter
  AdminTradeResponse: AdminTradeResponse
  AdminTradingReportResponse: AdminTradingReportResponse
  AdminUpdateCoinInfoResponse: AdminUpdateCoinInfoResponse
  AdminUpdateMarketInfoResponse: AdminUpdateMarketInfoResponse
  AdminUpdateOAuthClientInput: AdminUpdateOAuthClientInput
  AdminUpdateOAuthClientResponse: AdminUpdateOAuthClientResponse
  AdminUpdateQuotationInfoResponse: AdminUpdateQuotationInfoResponse
  AdminUploadSuspiciousTransactionInput: AdminUploadSuspiciousTransactionInput
  AdminUploadSuspiciousTransactionResponse: AdminUploadSuspiciousTransactionResponse
  AdminUploadVerificationFileResponse: AdminUploadVerificationFileResponse
  AdminUserFilter: AdminUserFilter
  AdminUserResponse: AdminUserResponse
  AdminVIPUpdateInput: AdminVipUpdateInput
  AdminVIPUpdateResponse: AdminVipUpdateResponse
  AdminVerifierFilter: AdminVerifierFilter
  AdminVerifyKYCIdentityInput: AdminVerifyKycIdentityInput
  AdminVerifyKYCIdentityResponse: AdminVerifyKycIdentityResponse
  AdminVoucherCreateInput: AdminVoucherCreateInput
  AdminVoucherEditInput: AdminVoucherEditInput
  AdminVoucherFilter: AdminVoucherFilter
  AdminVoucherRecordsResponse: AdminVoucherRecordsResponse
  AdminWithdrawalFilter: AdminWithdrawalFilter
  AdminWithdrawalReportResponse: AdminWithdrawalReportResponse
  AdminWithdrawalResponse: Omit<AdminWithdrawalResponse, 'data'> & {
    data?: Maybe<Array<ResolversParentTypes['Withdrawal']>>
  }
  ApproveKYCLv2Input: ApproveKycLv2Input
  ApproveKYCLv2Response: ApproveKycLv2Response
  Asset: Asset
  AssetCollection: AssetCollection
  AssetFilter: AssetFilter
  AssetLinkingCollections: AssetLinkingCollections
  Attributes: Attributes
  Auth2FA: Auth2Fa
  AuthBind2FAResponse: AuthBind2FaResponse
  AuthConfirm2FAInput: AuthConfirm2FaInput
  AuthConfirm2FAResponse: Omit<AuthConfirm2FaResponse, 'data'> & {
    data?: Maybe<ResolversParentTypes['Confirm2FAData']>
  }
  AuthMutation: AuthMutation
  AuthUnbind2FAInput: AuthUnbind2FaInput
  AuthUnbind2FAResponse: AuthUnbind2FaResponse
  Balance: Balance
  Bank: Bank
  BankAccount: BankAccount
  BankBranch: BankBranch
  BankInfo: BankInfo
  BankInfoInput: BankInfoInput
  BaseDeposit:
    | ResolversParentTypes['CryptoDeposit']
    | ResolversParentTypes['FiatDeposit']
    | ResolversParentTypes['InternalTransferIn']
  BaseResponse:
    | ResolversParentTypes['AcceptFiatWithdrawalResponse']
    | ResolversParentTypes['AdminAPIKeyResponse']
    | ResolversParentTypes['AdminActivateMerchantResponse']
    | ResolversParentTypes['AdminAddAuditNoteResponse']
    | ResolversParentTypes['AdminApproveVirtualAccountVerificationResponse']
    | ResolversParentTypes['AdminAssignKYCVerifierResponse']
    | ResolversParentTypes['AdminAuditWithdrawalResponse']
    | ResolversParentTypes['AdminChangeVirtualAccountLimitResponse']
    | ResolversParentTypes['AdminCheckDepositInfoResponse']
    | ResolversParentTypes['AdminCheckUploadResultMResponse']
    | ResolversParentTypes['AdminCheckUploadResultP01Response']
    | ResolversParentTypes['AdminCheckUploadResultP02Response']
    | ResolversParentTypes['AdminCheckUploadResultP05Response']
    | ResolversParentTypes['AdminCheckUploadResultPG1Response']
    | ResolversParentTypes['AdminCheckUploadResultResponse']
    | ResolversParentTypes['AdminCreateAPIKeyResponse']
    | ResolversParentTypes['AdminCreateOAuthClientResponse']
    | ResolversParentTypes['AdminCreateRolesResponse']
    | ResolversParentTypes['AdminCreateUserResponse']
    | ResolversParentTypes['AdminDVBanResponse']
    | ResolversParentTypes['AdminDVUpdateResponse']
    | ResolversParentTypes['AdminDeleteAPIKeyResponse']
    | ResolversParentTypes['AdminDeleteRolesResponse']
    | ResolversParentTypes['AdminDepositReportResponse']
    | ResolversParentTypes['AdminDepositResponse']
    | ResolversParentTypes['AdminDisable2FAResponse']
    | ResolversParentTypes['AdminDisableUserResponse']
    | ResolversParentTypes['AdminEDDAuditResponse']
    | ResolversParentTypes['AdminEnableUserResponse']
    | ResolversParentTypes['AdminHolidayAddResponse']
    | ResolversParentTypes['AdminHolidayResponse']
    | ResolversParentTypes['AdminIPUserResponse']
    | ResolversParentTypes['AdminLedgerResponse']
    | ResolversParentTypes['AdminNSAuditResponse']
    | ResolversParentTypes['AdminOnHoldFiatWithdrawalResponse']
    | ResolversParentTypes['AdminOnHoldKGIVerificationResponse']
    | ResolversParentTypes['AdminRAAuditResponse']
    | ResolversParentTypes['AdminReauditKYCResponse']
    | ResolversParentTypes['AdminRecoverKGIStatusResponse']
    | ResolversParentTypes['AdminRefreshMerchantResponse']
    | ResolversParentTypes['AdminRejectFiatWithdrawalResponse']
    | ResolversParentTypes['AdminRejectKGIVerificationResponse']
    | ResolversParentTypes['AdminRejectVirtualAccountVerificationResponse']
    | ResolversParentTypes['AdminResendAMLWithdrawInfoP01Response']
    | ResolversParentTypes['AdminResendAMLWithdrawInfoP02Response']
    | ResolversParentTypes['AdminResendFiatWithdrawalResponse']
    | ResolversParentTypes['AdminResendKGIVerificationResponse']
    | ResolversParentTypes['AdminResendWithdrawInfoP01Response']
    | ResolversParentTypes['AdminResendWithdrawInfoP02Response']
    | ResolversParentTypes['AdminResetKYCVerificationResponse']
    | ResolversParentTypes['AdminResetPhoneResponse']
    | ResolversParentTypes['AdminSendBGWithdrawInfoResponse']
    | ResolversParentTypes['AdminSendInvestorsSettlementResponse']
    | ResolversParentTypes['AdminSendMemberInfoResponse']
    | ResolversParentTypes['AdminSendWithdrawInfoP01Response']
    | ResolversParentTypes['AdminSendWithdrawInfoP02Response']
    | ResolversParentTypes['AdminSetOlderUserResponse']
    | ResolversParentTypes['AdminSetTradePermissionResponse']
    | ResolversParentTypes['AdminSubmitAMLVerificationResponse']
    | ResolversParentTypes['AdminSubmitKYCOneVerificationResponse']
    | ResolversParentTypes['AdminSubmitKYCTwoVerificationResponse']
    | ResolversParentTypes['AdminSubmitRAAuditResponse']
    | ResolversParentTypes['AdminSystemAuditAMLResponse']
    | ResolversParentTypes['AdminTradeResponse']
    | ResolversParentTypes['AdminTradingReportResponse']
    | ResolversParentTypes['AdminUpdateCoinInfoResponse']
    | ResolversParentTypes['AdminUpdateMarketInfoResponse']
    | ResolversParentTypes['AdminUpdateOAuthClientResponse']
    | ResolversParentTypes['AdminUpdateQuotationInfoResponse']
    | ResolversParentTypes['AdminUploadSuspiciousTransactionResponse']
    | ResolversParentTypes['AdminUploadVerificationFileResponse']
    | ResolversParentTypes['AdminUserResponse']
    | ResolversParentTypes['AdminVIPUpdateResponse']
    | ResolversParentTypes['AdminVerifyKYCIdentityResponse']
    | ResolversParentTypes['AdminVoucherRecordsResponse']
    | ResolversParentTypes['AdminWithdrawalReportResponse']
    | ResolversParentTypes['AdminWithdrawalResponse']
    | ResolversParentTypes['ApproveKYCLv2Response']
    | ResolversParentTypes['AuthBind2FAResponse']
    | ResolversParentTypes['AuthConfirm2FAResponse']
    | ResolversParentTypes['AuthUnbind2FAResponse']
    | ResolversParentTypes['ChartResponse']
    | ResolversParentTypes['CommonAddressResponse']
    | ResolversParentTypes['DepositResponse']
    | ResolversParentTypes['FAASPaymentResponse']
    | ResolversParentTypes['KYCConfirmSubmissionResponse']
    | ResolversParentTypes['KYCSubmitLevelOneResponse']
    | ResolversParentTypes['KYCSubmitLevelTwoResponse']
    | ResolversParentTypes['LedgerResponse']
    | ResolversParentTypes['NotificationCountResponse']
    | ResolversParentTypes['NotificationListResponse']
    | ResolversParentTypes['NotificationReadResponse']
    | ResolversParentTypes['PointPurchaseResponse']
    | ResolversParentTypes['RejectFiatWithdrawalResponse']
    | ResolversParentTypes['ResourceConfirmUploadResponse']
    | ResolversParentTypes['ResourceRequestUploadURLResponse']
    | ResolversParentTypes['TradeResponse']
    | ResolversParentTypes['TradingConfirmQuotationResponse']
    | ResolversParentTypes['TradingQuotationResponse']
    | ResolversParentTypes['TradingReportResponse']
    | ResolversParentTypes['UserActionLogResponse']
    | ResolversParentTypes['UserAgreeResponse']
    | ResolversParentTypes['UserBindPhoneConfirmationResponse']
    | ResolversParentTypes['UserBindPhoneResponse']
    | ResolversParentTypes['UserCancelOAuthResponse']
    | ResolversParentTypes['UserChangePasswordResponse']
    | ResolversParentTypes['UserCustomizeReferralResponse']
    | ResolversParentTypes['UserDeleteBankAccountResponse']
    | ResolversParentTypes['UserDeviceTokenResponse']
    | ResolversParentTypes['UserFillReferrerCodeResponse']
    | ResolversParentTypes['UserForgotPasswordConfirmResponse']
    | ResolversParentTypes['UserForgotPasswordResponse']
    | ResolversParentTypes['UserLoginRecordsResponse']
    | ResolversParentTypes['UserLoginResponse']
    | ResolversParentTypes['UserOAuthClientResponse']
    | ResolversParentTypes['UserPreferenceResponse']
    | ResolversParentTypes['UserRefreshTokenResponse']
    | ResolversParentTypes['UserRegisterConfirmResponse']
    | ResolversParentTypes['UserRegisterResponse']
    | ResolversParentTypes['UserRequestAccountDisableResponse']
    | ResolversParentTypes['UserResendForgotPasswordResponse']
    | ResolversParentTypes['UserResendRegisterResponse']
    | ResolversParentTypes['VoucherUseResponse']
    | ResolversParentTypes['WalletCreateCommonAddressResponse']
    | ResolversParentTypes['WalletCreateCryptoAddressResponse']
    | ResolversParentTypes['WalletCryptoWithdrawResponse']
    | ResolversParentTypes['WalletDeleteCommonAddressResponse']
    | ResolversParentTypes['WalletDepositResponse']
    | ResolversParentTypes['WalletFAASPaymentConfirmResponse']
    | ResolversParentTypes['WalletFiatWithdrawResponse']
    | ResolversParentTypes['WalletWithdrawConfirmResponse']
    | ResolversParentTypes['WalletWithdrawalResponse']
    | ResolversParentTypes['WithdrawalResponse']
  BaseWithdrawal:
    | ResolversParentTypes['CryptoWithdrawal']
    | ResolversParentTypes['FiatWithdrawal']
    | ResolversParentTypes['InternalTransferOut']
  BasicInfo: BasicInfo
  BasicInfoInput: BasicInfoInput
  Boolean: Scalars['Boolean']
  CefiDepositAddress: CefiDepositAddress
  CefiDepositAddressInput: CefiDepositAddressInput
  CefiWallet: CefiWallet
  CefiWalletFilterInput: CefiWalletFilterInput
  ChainFilter: ChainFilter
  ChartInput: ChartInput
  ChartResponse: ChartResponse
  CloseUniswapv3PositionInput: CloseUniswapv3PositionInput
  CoinPrice: CoinPrice
  CommonAddress: CommonAddress
  CommonAddressFilter: CommonAddressFilter
  CommonAddressResponse: CommonAddressResponse
  Confirm2FAData: ResolversUnionTypes['Confirm2FAData']
  ContentfulMetadata: ContentfulMetadata
  ContentfulMetadataFilter: ContentfulMetadataFilter
  ContentfulMetadataTagsFilter: ContentfulMetadataTagsFilter
  ContentfulTag: ContentfulTag
  Country: Country
  CreateAICompletionRecordInput: CreateAiCompletionRecordInput
  CreateAICompletionRecordItemInput: CreateAiCompletionRecordItemInput
  CreateUniswapv3PositionInput: CreateUniswapv3PositionInput
  CreateUniswapv3ToolInput: CreateUniswapv3ToolInput
  Creator: Creator
  CryptoAsset: CryptoAsset
  CryptoDeposit: CryptoDeposit
  CryptoWithdrawal: CryptoWithdrawal
  CurrencyAmount: CurrencyAmount
  CurrencyBalance: CurrencyBalance
  CurrencyBalances: CurrencyBalances
  CurrencyFilter: CurrencyFilter
  CurrencyInfo: CurrencyInfo
  DateTime: Scalars['DateTime']
  DefiTokensBalanceAmountInput: DefiTokensBalanceAmountInput
  DefiWallet: DefiWallet
  DefiWalletFilterInput: DefiWalletFilterInput
  DefiWalletsFilterInput: DefiWalletsFilterInput
  Deposit: ResolversUnionTypes['Deposit']
  DepositFilter: DepositFilter
  DepositResponse: Omit<DepositResponse, 'data'> & {
    data?: Maybe<Array<ResolversParentTypes['Deposit']>>
  }
  DevMutation: DevMutation
  Dimension: Scalars['Dimension']
  DisplayItem: DisplayItem
  DisplayItemCollection: DisplayItemCollection
  DisplayItemFilter: DisplayItemFilter
  DisplayItemForUsersCollection: DisplayItemForUsersCollection
  DisplayItemLinkingCollections: DisplayItemLinkingCollections
  Email: Scalars['Email']
  Entry:
    | ResolversParentTypes['ActivityNotification']
    | ResolversParentTypes['DisplayItem']
    | ResolversParentTypes['HeroSlider']
    | ResolversParentTypes['LandingPageCustomMessage']
    | ResolversParentTypes['PopUp']
    | ResolversParentTypes['Release']
    | ResolversParentTypes['ReleaseNote']
    | ResolversParentTypes['Section']
    | ResolversParentTypes['SectionContent']
    | ResolversParentTypes['SpecialUser']
    | ResolversParentTypes['StrategyMeta']
  EntryCollection: EntryCollection
  EntryFilter: EntryFilter
  ExternalAPIKey: ExternalApiKey
  ExternalAPIKeyCreateInput: ExternalApiKeyCreateInput
  ExternalAPIKeyDeleteInput: ExternalApiKeyDeleteInput
  ExternalAPIKeyWithdrawal: ExternalApiKeyWithdrawal
  ExternalAPIKeysFilter: ExternalApiKeysFilter
  FAASPayment: FaasPayment
  FAASPaymentFilter: FaasPaymentFilter
  FAASPaymentResponse: FaasPaymentResponse
  FiatDeposit: FiatDeposit
  FiatDepositInfo: FiatDepositInfo
  FiatWithdrawal: FiatWithdrawal
  Float: Scalars['Float']
  FrontendVersion: FrontendVersion
  GimCoolPayPaymentInfo: GimCoolPayPaymentInfo
  GimCoolPayPaymentInfoInput: GimCoolPayPaymentInfoInput
  GimCoolPayPriceResponse: GimCoolPayPriceResponse
  GimCoolPayReadyToPurchasePrice: GimCoolPayReadyToPurchasePrice
  HeroSlider: HeroSlider
  HeroSliderCollection: HeroSliderCollection
  HeroSliderFilter: HeroSliderFilter
  HeroSliderLinkingCollections: HeroSliderLinkingCollections
  HexColor: Scalars['HexColor']
  Holiday: Holiday
  ID: Scalars['ID']
  IdentityInfo: IdentityInfo
  IdentityInfoInput: IdentityInfoInput
  ImageTransformOptions: ImageTransformOptions
  Int: Scalars['Int']
  Int_comparison_exp: Int_Comparison_Exp
  InternalTransferIn: InternalTransferIn
  InternalTransferOut: InternalTransferOut
  JSON: Scalars['JSON']
  KYC: Kyc
  KYCConfirmSubmissionInput: KycConfirmSubmissionInput
  KYCConfirmSubmissionResponse: KycConfirmSubmissionResponse
  KYCLevelOne: KycLevelOne
  KYCLevelTwo: KycLevelTwo
  KYCMutation: KycMutation
  KYCPhoto: KycPhoto
  KYCPhotoVerification: KycPhotoVerification
  KYCSubmitLevelOneInput: KycSubmitLevelOneInput
  KYCSubmitLevelOneResponse: KycSubmitLevelOneResponse
  KYCSubmitLevelTwoInput: KycSubmitLevelTwoInput
  KYCSubmitLevelTwoResponse: KycSubmitLevelTwoResponse
  KgiUploadResponse: KgiUploadResponse
  LandingPageCustomMessage: LandingPageCustomMessage
  LandingPageCustomMessageCollection: LandingPageCustomMessageCollection
  LandingPageCustomMessageCustomMessage: LandingPageCustomMessageCustomMessage
  LandingPageCustomMessageCustomMessageAssets: LandingPageCustomMessageCustomMessageAssets
  LandingPageCustomMessageCustomMessageEntries: LandingPageCustomMessageCustomMessageEntries
  LandingPageCustomMessageCustomMessageLinks: LandingPageCustomMessageCustomMessageLinks
  LandingPageCustomMessageCustomMessageResources: LandingPageCustomMessageCustomMessageResources
  LandingPageCustomMessageFilter: LandingPageCustomMessageFilter
  LandingPageCustomMessageLinkingCollections: LandingPageCustomMessageLinkingCollections
  Ledger: Omit<Ledger, 'ledgerEntry'> & { ledgerEntry: ResolversParentTypes['LedgerEntry'] }
  LedgerEntry: ResolversUnionTypes['LedgerEntry']
  LedgerFilter: LedgerFilter
  LedgerResponse: LedgerResponse
  LoginInfo: LoginInfo
  LoginResponse: ResolversUnionTypes['LoginResponse']
  MarketInfo: MarketInfo
  MarketPrice: MarketPrice
  Me: Me
  MetaPagination: MetaPagination
  Metric: Metric
  MetricPosition: MetricPosition
  Mutation: {}
  NFTCollections: NftCollections
  NFTMerkleTreeHexProof: NftMerkleTreeHexProof
  NFTWhitelistInfoByPhrase: NftWhitelistInfoByPhrase
  Notification: Notification
  NotificationCount: NotificationCount
  NotificationCountFilter: NotificationCountFilter
  NotificationCountResponse: NotificationCountResponse
  NotificationInfo: NotificationInfo
  NotificationListFilter: NotificationListFilter
  NotificationListResponse: NotificationListResponse
  NotificationMutation: NotificationMutation
  NotificationReadFilter: NotificationReadFilter
  NotificationReadInput: NotificationReadInput
  NotificationReadResponse: NotificationReadResponse
  Number: Scalars['Number']
  OnHoldKGIVerification: OnHoldKgiVerification
  OutOfRangeInfoInput: OutOfRangeInfoInput
  PaginationInput: PaginationInput
  PaginationQuery: PaginationQuery
  PhotoVerificationResultInput: PhotoVerificationResultInput
  Point: Point
  PointLedgerFilter: PointLedgerFilter
  PointLedgerRecord: PointLedgerRecord
  PointLedgerRecordResponse: PointLedgerRecordResponse
  PointMutation: PointMutation
  PointPurchaseInput: PointPurchaseInput
  PointPurchaseResponse: PointPurchaseResponse
  PopUp: PopUp
  PopUpCollection: PopUpCollection
  PopUpContentArea: PopUpContentArea
  PopUpContentAreaAssets: PopUpContentAreaAssets
  PopUpContentAreaEntries: PopUpContentAreaEntries
  PopUpContentAreaLinks: PopUpContentAreaLinks
  PopUpContentAreaResources: PopUpContentAreaResources
  PopUpFilter: PopUpFilter
  PopUpLinkingCollections: PopUpLinkingCollections
  PositionManagerInfo: PositionManagerInfo
  Public: Public
  Quality: Scalars['Quality']
  Query: {}
  Question: Question
  QuestionInfo: QuestionInfo
  QuestionInput: QuestionInput
  QuestionOption: QuestionOption
  QuestionResultInput: QuestionResultInput
  Questionnaire: Questionnaire
  QuestionnaireInput: QuestionnaireInput
  Quotation: Quotation
  RateHistorical: RateHistorical
  RateHistoricalFilterInput: RateHistoricalFilterInput
  RejectFiatWithdrawalInput: RejectFiatWithdrawalInput
  RejectFiatWithdrawalResponse: RejectFiatWithdrawalResponse
  Release: Release
  ReleaseCollection: ReleaseCollection
  ReleaseFilter: ReleaseFilter
  ReleaseLinkingCollections: ReleaseLinkingCollections
  ReleaseNote: ReleaseNote
  ReleaseNoteCollection: ReleaseNoteCollection
  ReleaseNoteFilter: ReleaseNoteFilter
  ReleaseNoteLinkingCollections: ReleaseNoteLinkingCollections
  ReleaseNoteReleaseNote: ReleaseNoteReleaseNote
  ReleaseNoteReleaseNoteAssets: ReleaseNoteReleaseNoteAssets
  ReleaseNoteReleaseNoteEntries: ReleaseNoteReleaseNoteEntries
  ReleaseNoteReleaseNoteLinks: ReleaseNoteReleaseNoteLinks
  ReleaseNoteReleaseNoteResources: ReleaseNoteReleaseNoteResources
  ReleaseNotesCollection: ReleaseNotesCollection
  ResourceConfirmUploadInput: ResourceConfirmUploadInput
  ResourceConfirmUploadResponse: ResourceConfirmUploadResponse
  ResourceLink: ResourceLink
  ResourceMutation: ResourceMutation
  ResourceRequestUploadURLInput: ResourceRequestUploadUrlInput
  ResourceRequestUploadURLResponse: ResourceRequestUploadUrlResponse
  ResourceSys: ResourceSys
  Response2FA: Response2Fa
  ReyieldAttributes: ReyieldAttributes
  ReyieldBalance: ReyieldBalance
  ReyieldCefiDepositAddress: ReyieldCefiDepositAddress
  ReyieldCefiDepositAddressInput: ReyieldCefiDepositAddressInput
  ReyieldCefiWallet: ReyieldCefiWallet
  ReyieldCefiWalletFilterInput: ReyieldCefiWalletFilterInput
  ReyieldCloseUniswapv3PositionInput: ReyieldCloseUniswapv3PositionInput
  ReyieldCoinPrice: ReyieldCoinPrice
  ReyieldCreateAICompletionRecordInput: ReyieldCreateAiCompletionRecordInput
  ReyieldCreateAICompletionRecordItemInput: ReyieldCreateAiCompletionRecordItemInput
  ReyieldCreateUniswapv3PositionInput: ReyieldCreateUniswapv3PositionInput
  ReyieldCreateUniswapv3ToolInput: ReyieldCreateUniswapv3ToolInput
  ReyieldCreator: ReyieldCreator
  ReyieldCurrencyBalances: ReyieldCurrencyBalances
  ReyieldCurrencyInfo: ReyieldCurrencyInfo
  ReyieldDefiTokensBalanceAmountInput: ReyieldDefiTokensBalanceAmountInput
  ReyieldDefiWallet: ReyieldDefiWallet
  ReyieldDefiWalletFilterInput: ReyieldDefiWalletFilterInput
  ReyieldDefiWalletsFilterInput: ReyieldDefiWalletsFilterInput
  ReyieldExternalAPIKey: ReyieldExternalApiKey
  ReyieldExternalAPIKeyCreateInput: ReyieldExternalApiKeyCreateInput
  ReyieldExternalAPIKeyDeleteInput: ReyieldExternalApiKeyDeleteInput
  ReyieldExternalAPIKeysFilter: ReyieldExternalApiKeysFilter
  ReyieldFrontendVersion: ReyieldFrontendVersion
  ReyieldGimCoolPayPaymentInfo: ReyieldGimCoolPayPaymentInfo
  ReyieldGimCoolPayPaymentInfoInput: ReyieldGimCoolPayPaymentInfoInput
  ReyieldGimCoolPayPriceResponse: ReyieldGimCoolPayPriceResponse
  ReyieldGimCoolPayReadyToPurchasePrice: ReyieldGimCoolPayReadyToPurchasePrice
  ReyieldLicense: ReyieldLicense
  ReyieldNFTCollections: ReyieldNftCollections
  ReyieldNFTMerkleTreeHexProof: ReyieldNftMerkleTreeHexProof
  ReyieldNFTWhitelistInfoByPhrase: ReyieldNftWhitelistInfoByPhrase
  ReyieldOutOfRangeInfoInput: ReyieldOutOfRangeInfoInput
  ReyieldRateHistorical: ReyieldRateHistorical
  ReyieldRateHistoricalFilterInput: ReyieldRateHistoricalFilterInput
  ReyieldReyieldLicense: ReyieldReyieldLicense
  ReyieldSmartContract: ReyieldSmartContract
  ReyieldStrategyFilterInput: ReyieldStrategyFilterInput
  ReyieldStrategyInfo: ReyieldStrategyInfo
  ReyieldStrategyInfos: ReyieldStrategyInfos
  ReyieldStrategyParameters: ReyieldStrategyParameters
  ReyieldStrategyPerformance: ReyieldStrategyPerformance
  ReyieldStrategyProgress: ReyieldStrategyProgress
  ReyieldStrategyProgressStep: ReyieldStrategyProgressStep
  ReyieldStrategyStartInput: ReyieldStrategyStartInput
  ReyieldStrategyStartParamenters: ReyieldStrategyStartParamenters
  ReyieldStrategyStartResponse: ReyieldStrategyStartResponse
  ReyieldStrategyStopInput: ReyieldStrategyStopInput
  ReyieldStrategyStopResponse: ReyieldStrategyStopResponse
  ReyieldStrategyUpdateInput: ReyieldStrategyUpdateInput
  ReyieldStrategyUpdateParamenters: ReyieldStrategyUpdateParamenters
  ReyieldStrategyUpdateResponse: ReyieldStrategyUpdateResponse
  ReyieldString_comparison_exp: ReyieldString_Comparison_Exp
  ReyieldTokenBalance: ReyieldTokenBalance
  ReyieldTokenDisplayInfo: ReyieldTokenDisplayInfo
  ReyieldToolInfo: ResolversUnionTypes['ReyieldToolInfo']
  ReyieldTotalProfits: ReyieldTotalProfits
  ReyieldUniswapOnchainPositionAmountInfo: ReyieldUniswapOnchainPositionAmountInfo
  ReyieldUniswapPoolInfo: ReyieldUniswapPoolInfo
  ReyieldUniswapPoolPosition: ReyieldUniswapPoolPosition
  ReyieldUniswapPoolTick: ReyieldUniswapPoolTick
  ReyieldUniswapQuote: ReyieldUniswapQuote
  ReyieldUniswapQuoteInput: ReyieldUniswapQuoteInput
  ReyieldUniswapToolFeeTier: ReyieldUniswapToolFeeTier
  ReyieldUniswapToolInfo: ReyieldUniswapToolInfo
  ReyieldUniswapToolTag: ReyieldUniswapToolTag
  ReyieldUniswapToolToken: ReyieldUniswapToolToken
  ReyieldUniswapToolTokenPriceChart: ReyieldUniswapToolTokenPriceChart
  ReyieldUniswapToolTokenPriceChartPrice: ReyieldUniswapToolTokenPriceChartPrice
  ReyieldUniswapV3BacktestResult: ReyieldUniswapV3BacktestResult
  ReyieldUserAICompletionRecordsFilterInput: ReyieldUserAiCompletionRecordsFilterInput
  ReyieldUserInfo: Omit<ReyieldUserInfo, 'tools'> & {
    tools?: Maybe<Array<ResolversParentTypes['ReyieldUserToolInfo']>>
  }
  ReyieldUserStrategyFilterInput: ReyieldUserStrategyFilterInput
  ReyieldUserStrategyInfo: ReyieldUserStrategyInfo
  ReyieldUserStrategyParameters: ReyieldUserStrategyParameters
  ReyieldUserStrategyPerformance: ReyieldUserStrategyPerformance
  ReyieldUserStrategyProfit: ReyieldUserStrategyProfit
  ReyieldUserStrategyProfitFilterInput: ReyieldUserStrategyProfitFilterInput
  ReyieldUserStrategyRecord: ReyieldUserStrategyRecord
  ReyieldUserStrategyRecordsFilterInput: ReyieldUserStrategyRecordsFilterInput
  ReyieldUserToolInfo: ResolversUnionTypes['ReyieldUserToolInfo']
  ReyieldUserUniswapOnchainPositionInfo: ReyieldUserUniswapOnchainPositionInfo
  ReyieldUserUniswapPositionInfo: ReyieldUserUniswapPositionInfo
  ReyieldUserUniswapToolOutOfRangeInfo: ReyieldUserUniswapToolOutOfRangeInfo
  ReyieldUserUniswapToolSettingInfo: ReyieldUserUniswapToolSettingInfo
  ReyieldUserUniswapv3PositionProgress: ReyieldUserUniswapv3PositionProgress
  ReyieldUserUniswapv3PositionProgressStep: ReyieldUserUniswapv3PositionProgressStep
  ReyieldWalletBalances: ReyieldWalletBalances
  ReyieldaICompletionRecords: ReyieldaICompletionRecords
  ReyieldsuggestStrategyToolWithAllocations: ReyieldsuggestStrategyToolWithAllocations
  ReyieldsuggestStrategyToolWithAllocations_aggregate: ReyieldsuggestStrategyToolWithAllocations_Aggregate
  ReyieldsuggestStrategyToolWithAllocations_aggregate_fields: ReyieldsuggestStrategyToolWithAllocations_Aggregate_Fields
  ReyieldsuggestStrategyToolWithAllocations_bool_exp: ReyieldsuggestStrategyToolWithAllocations_Bool_Exp
  ReyieldsuggestStrategyToolWithAllocations_max_fields: ReyieldsuggestStrategyToolWithAllocations_Max_Fields
  ReyieldsuggestStrategyToolWithAllocations_min_fields: ReyieldsuggestStrategyToolWithAllocations_Min_Fields
  ReyieldsuggestStrategyToolWithAllocations_order_by: ReyieldsuggestStrategyToolWithAllocations_Order_By
  Reyieldtimestamptz_comparison_exp: Reyieldtimestamptz_Comparison_Exp
  Reyielduuid_comparison_exp: Reyielduuid_Comparison_Exp
  Section: Section
  SectionCollection: SectionCollection
  SectionContent: SectionContent
  SectionContentCollection: SectionContentCollection
  SectionContentDescription: SectionContentDescription
  SectionContentDescriptionAssets: SectionContentDescriptionAssets
  SectionContentDescriptionEntries: SectionContentDescriptionEntries
  SectionContentDescriptionLinks: SectionContentDescriptionLinks
  SectionContentDescriptionResources: SectionContentDescriptionResources
  SectionContentFilter: SectionContentFilter
  SectionContentLinkingCollections: SectionContentLinkingCollections
  SectionFilter: SectionFilter
  SectionLinkingCollections: SectionLinkingCollections
  SectionSectionContentsCollection: SectionSectionContentsCollection
  SmartContract: SmartContract
  SpecialUser: SpecialUser
  SpecialUserCollection: SpecialUserCollection
  SpecialUserFilter: SpecialUserFilter
  SpecialUserFilter_remote_rel_bitginspecial: SpecialUserFilter_Remote_Rel_Bitginspecial
  SpecialUserLinkingCollections: SpecialUserLinkingCollections
  StrategyFilterInput: StrategyFilterInput
  StrategyInfo: StrategyInfo
  StrategyInfos: StrategyInfos
  StrategyMeta: StrategyMeta
  StrategyMetaCollection: StrategyMetaCollection
  StrategyMetaFilter: StrategyMetaFilter
  StrategyMetaFilter_remote_rel_bitgin_strategydisplayInfo: StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo
  StrategyMetaLinkingCollections: StrategyMetaLinkingCollections
  StrategyMetaSectionsCollection: StrategyMetaSectionsCollection
  StrategyParameters: StrategyParameters
  StrategyPerformance: StrategyPerformance
  StrategyProgress: StrategyProgress
  StrategyProgressStep: StrategyProgressStep
  StrategyStartInput: StrategyStartInput
  StrategyStartParamenters: StrategyStartParamenters
  StrategyStartResponse: StrategyStartResponse
  StrategyStopInput: StrategyStopInput
  StrategyStopResponse: StrategyStopResponse
  StrategyUpdateInput: StrategyUpdateInput
  StrategyUpdateParamenters: StrategyUpdateParamenters
  StrategyUpdateResponse: StrategyUpdateResponse
  Strategy_Action:
    | ResolversParentTypes['Strategy_ActionDEFIApproveSwapReward']
    | ResolversParentTypes['Strategy_ActionDEFIDeductTxnFee']
    | ResolversParentTypes['Strategy_ActionDEFIDispatchUSDTReward']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVAddLiquidity']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVApproveConvexBooster']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVApproveCurve3Pool']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVClaimReward']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVDepositAll']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVRemoveLiquidity']
    | ResolversParentTypes['Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap']
    | ResolversParentTypes['Strategy_ActionDEFIMIMUSDTApproveCurve3Pool']
    | ResolversParentTypes['Strategy_ActionDEFIRebalanceTxnFeeEstimation']
    | ResolversParentTypes['Strategy_ActionDEFIStakePool']
    | ResolversParentTypes['Strategy_ActionDEFISwapETHToUSDT']
    | ResolversParentTypes['Strategy_ActionDEFISwapReward']
    | ResolversParentTypes['Strategy_ActionDEFITransferUserLPBalanceToPool']
    | ResolversParentTypes['Strategy_ActionDEFIUnStakePool']
    | ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity']
    | ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward']
    | ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity']
    | ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT']
    | ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward']
    | ResolversParentTypes['Strategy_ActionDEFIUpdateUserRecordStatus']
    | ResolversParentTypes['Strategy_ActionExternalDepositCheck']
    | ResolversParentTypes['Strategy_ActionExternalSwap']
    | ResolversParentTypes['Strategy_ActionExternalWalletTransfer']
    | ResolversParentTypes['Strategy_ActionExternalWithdrawal']
    | ResolversParentTypes['Strategy_ActionInternalDepositCheck']
    | ResolversParentTypes['Strategy_ActionInternalTrade']
    | ResolversParentTypes['Strategy_ActionInternalWithdrawal']
    | ResolversParentTypes['Strategy_ActionStrategyBatchStart']
    | ResolversParentTypes['Strategy_ActionStrategyBatchStop']
    | ResolversParentTypes['Strategy_ActionStrategyBatchUpdate']
    | ResolversParentTypes['Strategy_ActionStrategyStart']
    | ResolversParentTypes['Strategy_ActionStrategyStop']
    | ResolversParentTypes['Strategy_ActionStrategyUpdate']
    | ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletPostProcessing']
    | ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletPreProcessing']
    | ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletProcessing']
    | ResolversParentTypes['Strategy_ActionTransferToDEFIWalletPostProcessing']
    | ResolversParentTypes['Strategy_ActionTransferToDEFIWalletPreProcessing']
    | ResolversParentTypes['Strategy_ActionTransferToDEFIWalletProcessing']
    | ResolversParentTypes['Strategy_ActionWatchTxnStatus']
  Strategy_ActionDEFIApproveSwapReward: Strategy_ActionDefiApproveSwapReward
  Strategy_ActionDEFIDeductTxnFee: Strategy_ActionDefiDeductTxnFee
  Strategy_ActionDEFIDispatchUSDTReward: Strategy_ActionDefiDispatchUsdtReward
  Strategy_ActionDEFIMIM3CRVAddLiquidity: Strategy_ActionDefimim3CrvAddLiquidity
  Strategy_ActionDEFIMIM3CRVApproveConvexBooster: Strategy_ActionDefimim3CrvApproveConvexBooster
  Strategy_ActionDEFIMIM3CRVApproveCurve3Pool: Strategy_ActionDefimim3CrvApproveCurve3Pool
  Strategy_ActionDEFIMIM3CRVClaimReward: Strategy_ActionDefimim3CrvClaimReward
  Strategy_ActionDEFIMIM3CRVDepositAll: Strategy_ActionDefimim3CrvDepositAll
  Strategy_ActionDEFIMIM3CRVRemoveLiquidity: Strategy_ActionDefimim3CrvRemoveLiquidity
  Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap: Strategy_ActionDefimim3CrvWithdrawAndUnwrap
  Strategy_ActionDEFIMIMUSDTApproveCurve3Pool: Strategy_ActionDefimimusdtApproveCurve3Pool
  Strategy_ActionDEFIRebalanceTxnFeeEstimation: Strategy_ActionDefiRebalanceTxnFeeEstimation
  Strategy_ActionDEFIStakePool: Strategy_ActionDefiStakePool
  Strategy_ActionDEFISwapETHToUSDT: Strategy_ActionDefiSwapEthToUsdt
  Strategy_ActionDEFISwapReward: Strategy_ActionDefiSwapReward
  Strategy_ActionDEFITransferUserLPBalanceToPool: Strategy_ActionDefiTransferUserLpBalanceToPool
  Strategy_ActionDEFIUnStakePool: Strategy_ActionDefiUnStakePool
  Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity: Strategy_ActionDefiUpdatePoolBalanceAfterAddLiquidity
  Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward: Strategy_ActionDefiUpdatePoolBalanceAfterClaimReward
  Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity: Strategy_ActionDefiUpdatePoolBalanceAfterRmLiquidity
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT: Strategy_ActionDefiUpdatePoolBalanceAfterSwapEthToUsdt
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward: Strategy_ActionDefiUpdatePoolBalanceAfterSwapReward
  Strategy_ActionDEFIUpdateUserRecordStatus: Strategy_ActionDefiUpdateUserRecordStatus
  Strategy_ActionExternalDepositCheck: Strategy_ActionExternalDepositCheck
  Strategy_ActionExternalSwap: Strategy_ActionExternalSwap
  Strategy_ActionExternalWalletTransfer: Strategy_ActionExternalWalletTransfer
  Strategy_ActionExternalWithdrawal: Strategy_ActionExternalWithdrawal
  Strategy_ActionInternalDepositCheck: Strategy_ActionInternalDepositCheck
  Strategy_ActionInternalTrade: Strategy_ActionInternalTrade
  Strategy_ActionInternalWithdrawal: Strategy_ActionInternalWithdrawal
  Strategy_ActionLog: Strategy_ActionLog
  Strategy_ActionLogFilter: Strategy_ActionLogFilter
  Strategy_ActionStrategyBatchStart: Strategy_ActionStrategyBatchStart
  Strategy_ActionStrategyBatchStop: Strategy_ActionStrategyBatchStop
  Strategy_ActionStrategyBatchUpdate: Strategy_ActionStrategyBatchUpdate
  Strategy_ActionStrategyStart: Strategy_ActionStrategyStart
  Strategy_ActionStrategyStop: Strategy_ActionStrategyStop
  Strategy_ActionStrategyUpdate: Strategy_ActionStrategyUpdate
  Strategy_ActionTransferFromDEFIWalletPostProcessing: Strategy_ActionTransferFromDefiWalletPostProcessing
  Strategy_ActionTransferFromDEFIWalletPreProcessing: Strategy_ActionTransferFromDefiWalletPreProcessing
  Strategy_ActionTransferFromDEFIWalletProcessing: Strategy_ActionTransferFromDefiWalletProcessing
  Strategy_ActionTransferToDEFIWalletPostProcessing: Strategy_ActionTransferToDefiWalletPostProcessing
  Strategy_ActionTransferToDEFIWalletPreProcessing: Strategy_ActionTransferToDefiWalletPreProcessing
  Strategy_ActionTransferToDEFIWalletProcessing: Strategy_ActionTransferToDefiWalletProcessing
  Strategy_ActionWatchTxnStatus: Strategy_ActionWatchTxnStatus
  Strategy_Admin: Strategy_Admin
  Strategy_AdminExternalAPIKeyFilter: Strategy_AdminExternalApiKeyFilter
  Strategy_AdminMutation: Strategy_AdminMutation
  Strategy_AdminWorkflowFilter: Strategy_AdminWorkflowFilter
  Strategy_AdminWorkflowRetryInput: Strategy_AdminWorkflowRetryInput
  Strategy_ArbitrageState: Strategy_ArbitrageState
  Strategy_Balance: Strategy_Balance
  Strategy_CurrencyAmount: Strategy_CurrencyAmount
  Strategy_DEFINormalFlowInput: Strategy_DefiNormalFlowInput
  Strategy_DEFIRebalanceFlowInput: Strategy_DefiRebalanceFlowInput
  Strategy_DEFIWithdrawExpressFlowInput: Strategy_DefiWithdrawExpressFlowInput
  Strategy_DailyProfit: Strategy_DailyProfit
  Strategy_DebtRecord: Strategy_DebtRecord
  Strategy_EstimatePointsConsumingInput: Strategy_EstimatePointsConsumingInput
  Strategy_EstimatePointsConsumingResult: Strategy_EstimatePointsConsumingResult
  Strategy_EstimateStrategyRequiredAmountResponse: Strategy_EstimateStrategyRequiredAmountResponse
  Strategy_ExchangeAsset: Strategy_ExchangeAsset
  Strategy_ExchangeBalancesInput: Strategy_ExchangeBalancesInput
  Strategy_ExchangeWithdrawalAuthorizationInput: Strategy_ExchangeWithdrawalAuthorizationInput
  Strategy_ExchangeWithdrawalAuthorizationResponse: Strategy_ExchangeWithdrawalAuthorizationResponse
  Strategy_ExchangesNetAssetChart: Strategy_ExchangesNetAssetChart
  Strategy_ExchangesNetAssetChartData: Strategy_ExchangesNetAssetChartData
  Strategy_ExchangesNetAssetChartInput: Strategy_ExchangesNetAssetChartInput
  Strategy_ExternalAPIKey: Strategy_ExternalApiKey
  Strategy_ExternalAPIKeyCreateInput: Strategy_ExternalApiKeyCreateInput
  Strategy_ExternalAPIKeyDeleteInput: Strategy_ExternalApiKeyDeleteInput
  Strategy_ExternalAPIKeyDepositInput: Strategy_ExternalApiKeyDepositInput
  Strategy_ExternalAPIKeyFilter: Strategy_ExternalApiKeyFilter
  Strategy_ExternalAPIKeyMutation: Strategy_ExternalApiKeyMutation
  Strategy_ExternalAPIKeySwapInput: Strategy_ExternalApiKeySwapInput
  Strategy_ExternalAPIKeyTransferBetweenWalletsInput: Strategy_ExternalApiKeyTransferBetweenWalletsInput
  Strategy_ExternalAPIKeyWithdrawal: Strategy_ExternalApiKeyWithdrawal
  Strategy_ExternalAPIKeyWithdrawalConfirmInput: Strategy_ExternalApiKeyWithdrawalConfirmInput
  Strategy_ExternalAPIKeyWithdrawalInput: Strategy_ExternalApiKeyWithdrawalInput
  Strategy_GenericFlowMutation: Strategy_GenericFlowMutation
  Strategy_LendingOffer: Strategy_LendingOffer
  Strategy_LendingState: Strategy_LendingState
  Strategy_Number: Scalars['Strategy_Number']
  Strategy_Performance: Strategy_Performance
  Strategy_ProfitInputFilter: Strategy_ProfitInputFilter
  Strategy_Public: Strategy_Public
  Strategy_RenewAction: Strategy_RenewAction
  Strategy_Strategy: Omit<Strategy_Strategy, 'state'> & {
    state?: Maybe<ResolversParentTypes['Strategy_StrategyState']>
  }
  Strategy_StrategyCloseInput: Strategy_StrategyCloseInput
  Strategy_StrategyDeFiStartInput: Strategy_StrategyDeFiStartInput
  Strategy_StrategyDebt: Strategy_StrategyDebt
  Strategy_StrategyDescription: Strategy_StrategyDescription
  Strategy_StrategyFilter: Strategy_StrategyFilter
  Strategy_StrategyInfo: Strategy_StrategyInfo
  Strategy_StrategyMutation: Strategy_StrategyMutation
  Strategy_StrategyParameter: Strategy_StrategyParameter
  Strategy_StrategyParameterDesc: Strategy_StrategyParameterDesc
  Strategy_StrategyParameterInput: Strategy_StrategyParameterInput
  Strategy_StrategyPerformance: Strategy_StrategyPerformance
  Strategy_StrategyRetryInput: Strategy_StrategyRetryInput
  Strategy_StrategyStartInput: Strategy_StrategyStartInput
  Strategy_StrategyState: ResolversUnionTypes['Strategy_StrategyState']
  Strategy_StrategyStopInput: Strategy_StrategyStopInput
  Strategy_StrategyUpdateInput: Strategy_StrategyUpdateInput
  Strategy_Time: Scalars['Strategy_Time']
  Strategy_User: Strategy_User
  Strategy_UserMutation: Strategy_UserMutation
  Strategy_UserProfit: Strategy_UserProfit
  Strategy_WithdrawalCryptoStartInput: Strategy_WithdrawalCryptoStartInput
  Strategy_WithdrawalFiatStartInput: Strategy_WithdrawalFiatStartInput
  Strategy_WithdrawalRetryInput: Strategy_WithdrawalRetryInput
  Strategy_Workflow: Strategy_Workflow
  Strategy_WorkflowFilter: Strategy_WorkflowFilter
  Strategy_YieldRecord: Strategy_YieldRecord
  String: Scalars['String']
  String_comparison_exp: String_Comparison_Exp
  Sys: Sys
  SysFilter: SysFilter
  Time: Scalars['Time']
  TokenBalance: TokenBalance
  TokenDisplayInfo: TokenDisplayInfo
  ToolInfo: ResolversUnionTypes['ToolInfo']
  TotalProfits: TotalProfits
  Trade: Trade
  TradeFilter: TradeFilter
  TradeResponse: TradeResponse
  Trading: Trading
  TradingConfirmQuotationResponse: TradingConfirmQuotationResponse
  TradingMutation: TradingMutation
  TradingQuotationInput: TradingQuotationInput
  TradingQuotationResponse: TradingQuotationResponse
  TradingReportResponse: TradingReportResponse
  UniswapOnchainPositionAmountInfo: UniswapOnchainPositionAmountInfo
  UniswapPoolInfo: UniswapPoolInfo
  UniswapPoolPosition: UniswapPoolPosition
  UniswapPoolTick: UniswapPoolTick
  UniswapQuote: UniswapQuote
  UniswapQuoteInput: UniswapQuoteInput
  UniswapToolFeeTier: UniswapToolFeeTier
  UniswapToolInfo: UniswapToolInfo
  UniswapToolTag: UniswapToolTag
  UniswapToolToken: UniswapToolToken
  UniswapToolTokenPriceChart: UniswapToolTokenPriceChart
  UniswapToolTokenPriceChartPrice: UniswapToolTokenPriceChartPrice
  UniswapV3BacktestResult: UniswapV3BacktestResult
  User: User
  UserAICompletionRecordsFilterInput: UserAiCompletionRecordsFilterInput
  UserActionLog: UserActionLog
  UserActionLogResponse: UserActionLogResponse
  UserAgreeInput: UserAgreeInput
  UserAgreeResponse: UserAgreeResponse
  UserBindPhoneConfirmationInput: UserBindPhoneConfirmationInput
  UserBindPhoneConfirmationResponse: UserBindPhoneConfirmationResponse
  UserBindPhoneInput: UserBindPhoneInput
  UserBindPhoneResponse: UserBindPhoneResponse
  UserCancelOAuthInput: UserCancelOAuthInput
  UserCancelOAuthResponse: UserCancelOAuthResponse
  UserChangePasswordInput: UserChangePasswordInput
  UserChangePasswordResponse: UserChangePasswordResponse
  UserCustomizeReferralInput: UserCustomizeReferralInput
  UserCustomizeReferralResponse: UserCustomizeReferralResponse
  UserDeleteBankAccountResponse: UserDeleteBankAccountResponse
  UserDeviceTokenInput: UserDeviceTokenInput
  UserDeviceTokenResponse: UserDeviceTokenResponse
  UserFillReferrerCodeInput: UserFillReferrerCodeInput
  UserFillReferrerCodeResponse: UserFillReferrerCodeResponse
  UserForgotPasswordConfirmInput: UserForgotPasswordConfirmInput
  UserForgotPasswordConfirmResponse: UserForgotPasswordConfirmResponse
  UserForgotPasswordInput: UserForgotPasswordInput
  UserForgotPasswordResponse: UserForgotPasswordResponse
  UserInfo: Omit<UserInfo, 'tools'> & { tools?: Maybe<Array<ResolversParentTypes['UserToolInfo']>> }
  UserLoginInput: UserLoginInput
  UserLoginRecord: UserLoginRecord
  UserLoginRecordsResponse: UserLoginRecordsResponse
  UserLoginResponse: Omit<UserLoginResponse, 'data'> & {
    data?: Maybe<ResolversParentTypes['LoginResponse']>
  }
  UserMutation: UserMutation
  UserOAuthClient: UserOAuthClient
  UserOAuthClientFilter: UserOAuthClientFilter
  UserOAuthClientResponse: UserOAuthClientResponse
  UserPreference: UserPreference
  UserPreferenceResponse: UserPreferenceResponse
  UserReferral: UserReferral
  UserRefreshTokenInput: UserRefreshTokenInput
  UserRefreshTokenResponse: UserRefreshTokenResponse
  UserRegisterConfirmInput: UserRegisterConfirmInput
  UserRegisterConfirmResponse: UserRegisterConfirmResponse
  UserRegisterInput: UserRegisterInput
  UserRegisterResponse: UserRegisterResponse
  UserRequestAccountDisableResponse: UserRequestAccountDisableResponse
  UserResendForgotPasswordInput: UserResendForgotPasswordInput
  UserResendForgotPasswordResponse: UserResendForgotPasswordResponse
  UserResendRegisterInput: UserResendRegisterInput
  UserResendRegisterResponse: UserResendRegisterResponse
  UserSetPreferenceInput: UserSetPreferenceInput
  UserStrategyFilterInput: UserStrategyFilterInput
  UserStrategyInfo: UserStrategyInfo
  UserStrategyParameters: UserStrategyParameters
  UserStrategyPerformance: UserStrategyPerformance
  UserStrategyProfit: UserStrategyProfit
  UserStrategyProfitFilterInput: UserStrategyProfitFilterInput
  UserStrategyRecord: UserStrategyRecord
  UserStrategyRecordsFilterInput: UserStrategyRecordsFilterInput
  UserToolInfo: ResolversUnionTypes['UserToolInfo']
  UserUniswapOnchainPositionInfo: UserUniswapOnchainPositionInfo
  UserUniswapPositionInfo: UserUniswapPositionInfo
  UserUniswapToolOutOfRangeInfo: UserUniswapToolOutOfRangeInfo
  UserUniswapToolSettingInfo: UserUniswapToolSettingInfo
  UserUniswapv3PositionProgress: UserUniswapv3PositionProgress
  UserUniswapv3PositionProgressStep: UserUniswapv3PositionProgressStep
  VersionInfo: VersionInfo
  Voucher: Voucher
  VoucherMutation: VoucherMutation
  VoucherRecord: VoucherRecord
  VoucherUseInput: VoucherUseInput
  VoucherUseResponse: VoucherUseResponse
  Wallet: Wallet
  WalletAddressInput: WalletAddressInput
  WalletBalances: WalletBalances
  WalletCreateCommonAddress: WalletCreateCommonAddress
  WalletCreateCommonAddressResponse: WalletCreateCommonAddressResponse
  WalletCreateCryptoAddressInput: WalletCreateCryptoAddressInput
  WalletCreateCryptoAddressResponse: WalletCreateCryptoAddressResponse
  WalletCryptoWithdrawInput: WalletCryptoWithdrawInput
  WalletCryptoWithdrawResponse: Omit<WalletCryptoWithdrawResponse, 'data'> & {
    data?: Maybe<ResolversParentTypes['WithdrawCryptoResponse']>
  }
  WalletDeleteCommonAddress: WalletDeleteCommonAddress
  WalletDeleteCommonAddressResponse: WalletDeleteCommonAddressResponse
  WalletDepositResponse: WalletDepositResponse
  WalletFAASPaymentConfirmInput: WalletFaasPaymentConfirmInput
  WalletFAASPaymentConfirmResponse: WalletFaasPaymentConfirmResponse
  WalletFiatWithdrawInput: WalletFiatWithdrawInput
  WalletFiatWithdrawResponse: Omit<WalletFiatWithdrawResponse, 'data'> & {
    data?: Maybe<ResolversParentTypes['WithdrawFiatResponse']>
  }
  WalletMutation: WalletMutation
  WalletWithdrawConfirmInput: WalletWithdrawConfirmInput
  WalletWithdrawConfirmResponse: Omit<WalletWithdrawConfirmResponse, 'data'> & {
    data: ResolversParentTypes['Withdrawal']
  }
  WalletWithdrawalResponse: WalletWithdrawalResponse
  WithdrawCryptoResponse: ResolversUnionTypes['WithdrawCryptoResponse']
  WithdrawFiatResponse: ResolversUnionTypes['WithdrawFiatResponse']
  WithdrawInfo: WithdrawInfo
  WithdrawInfoFilter: WithdrawInfoFilter
  Withdrawal: ResolversUnionTypes['Withdrawal']
  WithdrawalFilter: WithdrawalFilter
  WithdrawalResponse: Omit<WithdrawalResponse, 'data'> & {
    data?: Maybe<Array<ResolversParentTypes['Withdrawal']>>
  }
  aICompletionRecords: AICompletionRecords
  cfReleaseNoteNestedFilter: CfReleaseNoteNestedFilter
  cfSectionContentNestedFilter: CfSectionContentNestedFilter
  cfSectionNestedFilter: CfSectionNestedFilter
  cfSpecialUserNestedFilter: CfSpecialUserNestedFilter
  frontendQuery: FrontendQuery
  jsonb: Scalars['jsonb']
  mutation_root: Mutation_Root
  reyieldMutation: ReyieldMutation
  reyieldQuery: Omit<ReyieldQuery, 'tools'> & {
    tools: Array<ResolversParentTypes['ReyieldToolInfo']>
  }
  strategyMutation: StrategyMutation
  strategyQuery: StrategyQuery
  suggestStrategyToolWithAllocations: SuggestStrategyToolWithAllocations
  suggestStrategyToolWithAllocations_aggregate: SuggestStrategyToolWithAllocations_Aggregate
  suggestStrategyToolWithAllocations_aggregate_bool_exp: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp
  suggestStrategyToolWithAllocations_aggregate_bool_exp_count: SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count
  suggestStrategyToolWithAllocations_aggregate_fields: SuggestStrategyToolWithAllocations_Aggregate_Fields
  suggestStrategyToolWithAllocations_aggregate_order_by: SuggestStrategyToolWithAllocations_Aggregate_Order_By
  suggestStrategyToolWithAllocations_bool_exp: SuggestStrategyToolWithAllocations_Bool_Exp
  suggestStrategyToolWithAllocations_max_fields: SuggestStrategyToolWithAllocations_Max_Fields
  suggestStrategyToolWithAllocations_max_order_by: SuggestStrategyToolWithAllocations_Max_Order_By
  suggestStrategyToolWithAllocations_min_fields: SuggestStrategyToolWithAllocations_Min_Fields
  suggestStrategyToolWithAllocations_min_order_by: SuggestStrategyToolWithAllocations_Min_Order_By
  suggestStrategyToolWithAllocations_order_by: SuggestStrategyToolWithAllocations_Order_By
  suggestStrategyToolWithAllocations_stream_cursor_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Input
  suggestStrategyToolWithAllocations_stream_cursor_value_input: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  timestamptz: Scalars['timestamptz']
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp
  uuid: Scalars['uuid']
  uuid_comparison_exp: Uuid_Comparison_Exp
}

export type AmlResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AML'] = ResolversParentTypes['AML']
> = {
  edd?: Resolver<Maybe<ResolversTypes['AMLEDDRecord']>, ParentType, ContextType>
  ns?: Resolver<Maybe<ResolversTypes['AMLNSRecord']>, ParentType, ContextType>
  ra?: Resolver<Maybe<ResolversTypes['AMLRARecord']>, ParentType, ContextType>
  raResults?: Resolver<
    Maybe<Array<ResolversTypes['AMLUserAssessmentResult']>>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['AMLStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlAssessmentItemResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLAssessmentItem'] = ResolversParentTypes['AMLAssessmentItem']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  itemCategories?: Resolver<Array<ResolversTypes['AMLItemCategory']>, ParentType, ContextType>
  weights?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmleddRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLEDDRecord'] = ResolversParentTypes['AMLEDDRecord']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  firstAuditorID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  secondAuditorID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['EDDStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlItemCategoryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLItemCategory'] = ResolversParentTypes['AMLItemCategory']
> = {
  allowMultipleSelection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  riskFactors?: Resolver<Array<ResolversTypes['AMLRiskFactor']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlnsRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLNSRecord'] = ResolversParentTypes['AMLNSRecord']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  firstAuditorID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSystemAudited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  secondAuditorID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['NSStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlraRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLRARecord'] = ResolversParentTypes['AMLRARecord']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  firstAuditorID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSystemAudited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  secondAuditorID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['RAStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userAssessmentResultID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlRatingCriteriaResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLRatingCriteria'] = ResolversParentTypes['AMLRatingCriteria']
> = {
  high?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  medium?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlRiskAssessmentFormResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLRiskAssessmentForm'] = ResolversParentTypes['AMLRiskAssessmentForm']
> = {
  assessmentItems?: Resolver<Array<ResolversTypes['AMLAssessmentItem']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  criteria?: Resolver<ResolversTypes['AMLRatingCriteria'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlRiskFactorResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLRiskFactor'] = ResolversParentTypes['AMLRiskFactor']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isSelected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  severity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlUserAssessmentResultResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLUserAssessmentResult'] = ResolversParentTypes['AMLUserAssessmentResult']
> = {
  assessorID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  form?: Resolver<ResolversTypes['AMLRiskAssessmentForm'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  score?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AmlVerificationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AMLVerification'] = ResolversParentTypes['AMLVerification']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  eddStatus?: Resolver<ResolversTypes['EDDStatus'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  nsStatus?: Resolver<ResolversTypes['NSStatus'], ParentType, ContextType>
  raStatus?: Resolver<ResolversTypes['RAStatus'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['AMLStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApiKeyResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['APIKey'] = ResolversParentTypes['APIKey']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  permissions?: Resolver<Array<ResolversTypes['CasbinRole']>, ParentType, ContextType>
  secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AcceptFiatWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AcceptFiatWithdrawalResponse'] = ResolversParentTypes['AcceptFiatWithdrawalResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityNotificationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ActivityNotification'] = ResolversParentTypes['ActivityNotification']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  desc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ActivityNotificationDescArgs>
  >
  link?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ActivityNotificationLinkArgs>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['ActivityNotificationLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<ActivityNotificationLinkedFromArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ActivityNotificationTitleArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityNotificationCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ActivityNotificationCollection'] = ResolversParentTypes['ActivityNotificationCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['ActivityNotification']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityNotificationLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ActivityNotificationLinkingCollections'] = ResolversParentTypes['ActivityNotificationLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<ActivityNotificationLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']
> = {
  chain?: Resolver<ResolversTypes['Chain'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']
> = {
  apiKeys?: Resolver<
    ResolversTypes['AdminAPIKeyResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminApiKeysArgs, 'pagination'>
  >
  depositReport?: Resolver<
    ResolversTypes['AdminDepositReportResponse'],
    ParentType,
    ContextType,
    Partial<AdminDepositReportArgs>
  >
  deposits?: Resolver<
    ResolversTypes['AdminDepositResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminDepositsArgs, 'pagination'>
  >
  holiday?: Resolver<
    ResolversTypes['AdminHolidayResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminHolidayArgs, 'pagination'>
  >
  ipUsers?: Resolver<
    ResolversTypes['AdminIPUserResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminIpUsersArgs, 'input'>
  >
  kgiReadyUsers?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>
  ledgers?: Resolver<
    ResolversTypes['AdminLedgerResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminLedgersArgs, 'pagination'>
  >
  onHoldFiatWithdrawals?: Resolver<
    ResolversTypes['AdminOnHoldFiatWithdrawalResponse'],
    ParentType,
    ContextType
  >
  onHoldKGIVerification?: Resolver<
    ResolversTypes['AdminOnHoldKGIVerificationResponse'],
    ParentType,
    ContextType
  >
  theLatestAMLRiskAssessmentForm?: Resolver<
    ResolversTypes['AMLRiskAssessmentForm'],
    ParentType,
    ContextType
  >
  tradeReport?: Resolver<
    ResolversTypes['AdminTradingReportResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminTradeReportArgs, 'input'>
  >
  trades?: Resolver<
    ResolversTypes['AdminTradeResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminTradesArgs, 'pagination'>
  >
  users?: Resolver<
    Maybe<ResolversTypes['AdminUserResponse']>,
    ParentType,
    ContextType,
    RequireFields<AdminUsersArgs, 'pagination'>
  >
  verifiers?: Resolver<
    Array<Maybe<ResolversTypes['User']>>,
    ParentType,
    ContextType,
    RequireFields<AdminVerifiersArgs, 'filter'>
  >
  voucherRecords?: Resolver<
    ResolversTypes['AdminVoucherRecordsResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminVoucherRecordsArgs, 'pagination'>
  >
  withdrawalReport?: Resolver<
    ResolversTypes['AdminWithdrawalReportResponse'],
    ParentType,
    ContextType,
    Partial<AdminWithdrawalReportArgs>
  >
  withdrawals?: Resolver<
    ResolversTypes['AdminWithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminWithdrawalsArgs, 'pagination'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminApiKeyResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminAPIKeyResponse'] = ResolversParentTypes['AdminAPIKeyResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['APIKey']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminActivateMerchantResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminActivateMerchantResponse'] = ResolversParentTypes['AdminActivateMerchantResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminAddAuditNoteResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminAddAuditNoteResponse'] = ResolversParentTypes['AdminAddAuditNoteResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminApproveVirtualAccountVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminApproveVirtualAccountVerificationResponse'] = ResolversParentTypes['AdminApproveVirtualAccountVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminAssignKycVerifierResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminAssignKYCVerifierResponse'] = ResolversParentTypes['AdminAssignKYCVerifierResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminAuditWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminAuditWithdrawalResponse'] = ResolversParentTypes['AdminAuditWithdrawalResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['BaseWithdrawal']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminChangeVirtualAccountLimitResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminChangeVirtualAccountLimitResponse'] = ResolversParentTypes['AdminChangeVirtualAccountLimitResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckDepositInfoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckDepositInfoResponse'] = ResolversParentTypes['AdminCheckDepositInfoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckUploadResultMResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckUploadResultMResponse'] = ResolversParentTypes['AdminCheckUploadResultMResponse']
> = {
  response?: Resolver<Maybe<Array<ResolversTypes['KgiUploadResponse']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckUploadResultP01ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckUploadResultP01Response'] = ResolversParentTypes['AdminCheckUploadResultP01Response']
> = {
  response?: Resolver<Maybe<Array<ResolversTypes['KgiUploadResponse']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckUploadResultP02ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckUploadResultP02Response'] = ResolversParentTypes['AdminCheckUploadResultP02Response']
> = {
  response?: Resolver<Maybe<Array<ResolversTypes['KgiUploadResponse']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckUploadResultP05ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckUploadResultP05Response'] = ResolversParentTypes['AdminCheckUploadResultP05Response']
> = {
  response?: Resolver<Maybe<Array<ResolversTypes['KgiUploadResponse']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckUploadResultPg1ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckUploadResultPG1Response'] = ResolversParentTypes['AdminCheckUploadResultPG1Response']
> = {
  response?: Resolver<Maybe<Array<ResolversTypes['KgiUploadResponse']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCheckUploadResultResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCheckUploadResultResponse'] = ResolversParentTypes['AdminCheckUploadResultResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCreateApiKeyResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCreateAPIKeyResponse'] = ResolversParentTypes['AdminCreateAPIKeyResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['APIKey']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCreateOAuthClientResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCreateOAuthClientResponse'] = ResolversParentTypes['AdminCreateOAuthClientResponse']
> = {
  clientID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCreateRolesResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCreateRolesResponse'] = ResolversParentTypes['AdminCreateRolesResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminCreateUserResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminCreateUserResponse'] = ResolversParentTypes['AdminCreateUserResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDvBanResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDVBanResponse'] = ResolversParentTypes['AdminDVBanResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDvUpdateResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDVUpdateResponse'] = ResolversParentTypes['AdminDVUpdateResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDeleteApiKeyResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDeleteAPIKeyResponse'] = ResolversParentTypes['AdminDeleteAPIKeyResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDeleteRolesResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDeleteRolesResponse'] = ResolversParentTypes['AdminDeleteRolesResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDepositReportResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDepositReportResponse'] = ResolversParentTypes['AdminDepositReportResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDepositResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDepositResponse'] = ResolversParentTypes['AdminDepositResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Deposit']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDisable2FaResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDisable2FAResponse'] = ResolversParentTypes['AdminDisable2FAResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminDisableUserResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminDisableUserResponse'] = ResolversParentTypes['AdminDisableUserResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminEddAuditResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminEDDAuditResponse'] = ResolversParentTypes['AdminEDDAuditResponse']
> = {
  record?: Resolver<ResolversTypes['AMLEDDRecord'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminEnableUserResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminEnableUserResponse'] = ResolversParentTypes['AdminEnableUserResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminHolidayAddResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminHolidayAddResponse'] = ResolversParentTypes['AdminHolidayAddResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminHolidayResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminHolidayResponse'] = ResolversParentTypes['AdminHolidayResponse']
> = {
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  records?: Resolver<Array<ResolversTypes['Holiday']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminIpUserResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminIPUserResponse'] = ResolversParentTypes['AdminIPUserResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminLedgerResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminLedgerResponse'] = ResolversParentTypes['AdminLedgerResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Ledger']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminMutation'] = ResolversParentTypes['AdminMutation']
> = {
  adminActivateMerchant?: Resolver<
    ResolversTypes['AdminActivateMerchantResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminActivateMerchantArgs, 'input'>
  >
  adminAddAuditNote?: Resolver<
    ResolversTypes['AdminAddAuditNoteResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminAddAuditNoteArgs, 'input'>
  >
  adminApproveEDDAudit?: Resolver<
    ResolversTypes['AdminEDDAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminApproveEddAuditArgs, 'input'>
  >
  adminApproveNSAudit?: Resolver<
    ResolversTypes['AdminNSAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminApproveNsAuditArgs, 'input'>
  >
  adminApproveRAAudit?: Resolver<
    ResolversTypes['AdminRAAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminApproveRaAuditArgs, 'input'>
  >
  adminApproveVirtualAccountVerification?: Resolver<
    ResolversTypes['AdminApproveVirtualAccountVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminApproveVirtualAccountVerificationArgs, 'input'>
  >
  adminAssignKYCVerifier?: Resolver<
    ResolversTypes['AdminAssignKYCVerifierResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminAssignKycVerifierArgs, 'input'>
  >
  adminAuditWithdrawal?: Resolver<
    ResolversTypes['AdminAuditWithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminAuditWithdrawalArgs, 'input'>
  >
  adminChangeVirtualAccountLimit?: Resolver<
    ResolversTypes['AdminChangeVirtualAccountLimitResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminChangeVirtualAccountLimitArgs, 'input'>
  >
  adminCheckDepositInfo?: Resolver<
    ResolversTypes['AdminCheckDepositInfoResponse'],
    ParentType,
    ContextType,
    Partial<AdminMutationAdminCheckDepositInfoArgs>
  >
  adminCheckUploadResult?: Resolver<
    ResolversTypes['AdminCheckUploadResultResponse'],
    ParentType,
    ContextType,
    Partial<AdminMutationAdminCheckUploadResultArgs>
  >
  adminCheckUploadResultM?: Resolver<
    ResolversTypes['AdminCheckUploadResultMResponse'],
    ParentType,
    ContextType
  >
  adminCheckUploadResultP01?: Resolver<
    ResolversTypes['AdminCheckUploadResultP01Response'],
    ParentType,
    ContextType
  >
  adminCheckUploadResultP02?: Resolver<
    ResolversTypes['AdminCheckUploadResultP02Response'],
    ParentType,
    ContextType
  >
  adminCheckUploadResultP05?: Resolver<
    ResolversTypes['AdminCheckUploadResultP05Response'],
    ParentType,
    ContextType
  >
  adminCheckUploadResultPG1?: Resolver<
    ResolversTypes['AdminCheckUploadResultPG1Response'],
    ParentType,
    ContextType
  >
  adminCreateAPIKey?: Resolver<
    ResolversTypes['AdminCreateAPIKeyResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminCreateApiKeyArgs, 'input'>
  >
  adminCreateOAuthClient?: Resolver<
    ResolversTypes['AdminCreateOAuthClientResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminCreateOAuthClientArgs, 'input'>
  >
  adminCreateRoles?: Resolver<
    ResolversTypes['AdminCreateRolesResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminCreateRolesArgs, 'input'>
  >
  adminCreateUser?: Resolver<
    ResolversTypes['AdminCreateUserResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminCreateUserArgs, 'input'>
  >
  adminDVBan?: Resolver<
    ResolversTypes['AdminDVBanResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminDvBanArgs, 'input'>
  >
  adminDVUpdate?: Resolver<
    ResolversTypes['AdminDVUpdateResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminDvUpdateArgs, 'input'>
  >
  adminDeleteAPIKey?: Resolver<
    ResolversTypes['AdminDeleteAPIKeyResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminDeleteApiKeyArgs, 'input'>
  >
  adminDeleteRoles?: Resolver<
    ResolversTypes['AdminDeleteRolesResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminDeleteRolesArgs, 'input'>
  >
  adminDisable2FA?: Resolver<
    ResolversTypes['AdminDisable2FAResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminDisable2FaArgs, 'input'>
  >
  adminDisableUser?: Resolver<
    ResolversTypes['AdminDisableUserResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminDisableUserArgs, 'input'>
  >
  adminEnableUser?: Resolver<
    ResolversTypes['AdminEnableUserResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminEnableUserArgs, 'input'>
  >
  adminHolidayAdd?: Resolver<
    ResolversTypes['AdminHolidayAddResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminHolidayAddArgs, 'input'>
  >
  adminReauditKYC?: Resolver<
    ResolversTypes['AdminReauditKYCResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminReauditKycArgs, 'input'>
  >
  adminRecoverKGIStatus?: Resolver<
    Maybe<ResolversTypes['AdminRecoverKGIStatusResponse']>,
    ParentType,
    ContextType
  >
  adminRefreshMerchant?: Resolver<
    ResolversTypes['AdminRefreshMerchantResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRefreshMerchantArgs, 'input'>
  >
  adminRejectEDDAudit?: Resolver<
    ResolversTypes['AdminEDDAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRejectEddAuditArgs, 'input'>
  >
  adminRejectFiatWithdrawal?: Resolver<
    ResolversTypes['AdminRejectFiatWithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRejectFiatWithdrawalArgs, 'input'>
  >
  adminRejectKGIVerification?: Resolver<
    ResolversTypes['AdminRejectKGIVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRejectKgiVerificationArgs, 'input'>
  >
  adminRejectNSAudit?: Resolver<
    ResolversTypes['AdminNSAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRejectNsAuditArgs, 'input'>
  >
  adminRejectRAAudit?: Resolver<
    ResolversTypes['AdminRAAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRejectRaAuditArgs, 'input'>
  >
  adminRejectVirtualAccountVerication?: Resolver<
    ResolversTypes['AdminRejectVirtualAccountVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminRejectVirtualAccountVericationArgs, 'input'>
  >
  adminResendAMLWithdrawInfoP01?: Resolver<
    ResolversTypes['AdminResendAMLWithdrawInfoP01Response'],
    ParentType,
    ContextType
  >
  adminResendAMLWithdrawInfoP02?: Resolver<
    ResolversTypes['AdminResendAMLWithdrawInfoP02Response'],
    ParentType,
    ContextType
  >
  adminResendFiatWithdrawal?: Resolver<
    ResolversTypes['AdminResendFiatWithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResendFiatWithdrawalArgs, 'input'>
  >
  adminResendKGIVerification?: Resolver<
    ResolversTypes['AdminResendKGIVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResendKgiVerificationArgs, 'input'>
  >
  adminResendWithdrawInfoP01?: Resolver<
    ResolversTypes['AdminResendWithdrawInfoP01Response'],
    ParentType,
    ContextType,
    Partial<AdminMutationAdminResendWithdrawInfoP01Args>
  >
  adminResendWithdrawInfoP02?: Resolver<
    ResolversTypes['AdminResendWithdrawInfoP02Response'],
    ParentType,
    ContextType,
    Partial<AdminMutationAdminResendWithdrawInfoP02Args>
  >
  adminResetEDDAudit?: Resolver<
    ResolversTypes['AdminEDDAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResetEddAuditArgs, 'input'>
  >
  adminResetKYCVerification?: Resolver<
    ResolversTypes['AdminResetKYCVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResetKycVerificationArgs, 'input'>
  >
  adminResetNSAudit?: Resolver<
    ResolversTypes['AdminNSAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResetNsAuditArgs, 'input'>
  >
  adminResetPhone?: Resolver<
    ResolversTypes['AdminResetPhoneResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResetPhoneArgs, 'input'>
  >
  adminResetRAAudit?: Resolver<
    ResolversTypes['AdminRAAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminResetRaAuditArgs, 'input'>
  >
  adminSendBGWithdrawInfo?: Resolver<
    ResolversTypes['AdminSendBGWithdrawInfoResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSendBgWithdrawInfoArgs, 'input'>
  >
  adminSendInvestorsSettlement?: Resolver<
    ResolversTypes['AdminSendInvestorsSettlementResponse'],
    ParentType,
    ContextType
  >
  adminSendMemberInfo?: Resolver<
    ResolversTypes['AdminSendMemberInfoResponse'],
    ParentType,
    ContextType
  >
  adminSendWithdrawInfoP01?: Resolver<
    ResolversTypes['AdminSendWithdrawInfoP01Response'],
    ParentType,
    ContextType
  >
  adminSendWithdrawInfoP02?: Resolver<
    ResolversTypes['AdminSendWithdrawInfoP02Response'],
    ParentType,
    ContextType
  >
  adminSetOldUser?: Resolver<
    ResolversTypes['AdminSetOlderUserResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSetOldUserArgs, 'input'>
  >
  adminSetTradePermission?: Resolver<
    ResolversTypes['AdminSetTradePermissionResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSetTradePermissionArgs, 'input'>
  >
  adminSubmitAMLVerification?: Resolver<
    ResolversTypes['AdminSubmitAMLVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSubmitAmlVerificationArgs, 'input'>
  >
  adminSubmitEDDAudit?: Resolver<
    ResolversTypes['AdminEDDAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSubmitEddAuditArgs, 'input'>
  >
  adminSubmitKYCOneVerification?: Resolver<
    ResolversTypes['AdminSubmitKYCOneVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSubmitKycOneVerificationArgs, 'input'>
  >
  adminSubmitKYCTwoVerification?: Resolver<
    ResolversTypes['AdminSubmitKYCTwoVerificationResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSubmitKycTwoVerificationArgs, 'input'>
  >
  adminSubmitNSAudit?: Resolver<
    ResolversTypes['AdminNSAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSubmitNsAuditArgs, 'input'>
  >
  adminSubmitRAAudit?: Resolver<
    ResolversTypes['AdminSubmitRAAuditResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSubmitRaAuditArgs, 'input'>
  >
  adminSystemAuditAML?: Resolver<
    ResolversTypes['AdminSystemAuditAMLResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminSystemAuditAmlArgs, 'input'>
  >
  adminUpdateCoinInfo?: Resolver<
    ResolversTypes['AdminUpdateCoinInfoResponse'],
    ParentType,
    ContextType
  >
  adminUpdateMarketInfo?: Resolver<
    ResolversTypes['AdminUpdateMarketInfoResponse'],
    ParentType,
    ContextType
  >
  adminUpdateOAuthClient?: Resolver<
    ResolversTypes['AdminUpdateOAuthClientResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminUpdateOAuthClientArgs, 'input'>
  >
  adminUpdateQuotationInfo?: Resolver<
    ResolversTypes['AdminUpdateQuotationInfoResponse'],
    ParentType,
    ContextType
  >
  adminUploadSuspiciousTransaction?: Resolver<
    ResolversTypes['AdminUploadSuspiciousTransactionResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminUploadSuspiciousTransactionArgs, 'input'>
  >
  adminUploadVerificationFile?: Resolver<
    ResolversTypes['AdminUploadVerificationFileResponse'],
    ParentType,
    ContextType
  >
  adminVIPUpgrade?: Resolver<
    ResolversTypes['AdminVIPUpdateResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminVipUpgradeArgs, 'input'>
  >
  adminVerifyKYCIdentity?: Resolver<
    ResolversTypes['AdminVerifyKYCIdentityResponse'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminVerifyKycIdentityArgs, 'input'>
  >
  adminVoucherCreate?: Resolver<
    ResolversTypes['VoucherRecord'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminVoucherCreateArgs, 'input'>
  >
  adminVoucherEdit?: Resolver<
    ResolversTypes['VoucherRecord'],
    ParentType,
    ContextType,
    RequireFields<AdminMutationAdminVoucherEditArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminNsAuditResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminNSAuditResponse'] = ResolversParentTypes['AdminNSAuditResponse']
> = {
  record?: Resolver<ResolversTypes['AMLNSRecord'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminOnHoldFiatWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminOnHoldFiatWithdrawalResponse'] = ResolversParentTypes['AdminOnHoldFiatWithdrawalResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['OnHoldKGIVerification']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminOnHoldKgiVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminOnHoldKGIVerificationResponse'] = ResolversParentTypes['AdminOnHoldKGIVerificationResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['OnHoldKGIVerification']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminRaAuditResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminRAAuditResponse'] = ResolversParentTypes['AdminRAAuditResponse']
> = {
  record?: Resolver<ResolversTypes['AMLRARecord'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminReauditKycResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminReauditKYCResponse'] = ResolversParentTypes['AdminReauditKYCResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminRecoverKgiStatusResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminRecoverKGIStatusResponse'] = ResolversParentTypes['AdminRecoverKGIStatusResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminRefreshMerchantResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminRefreshMerchantResponse'] = ResolversParentTypes['AdminRefreshMerchantResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminRejectFiatWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminRejectFiatWithdrawalResponse'] = ResolversParentTypes['AdminRejectFiatWithdrawalResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminRejectKgiVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminRejectKGIVerificationResponse'] = ResolversParentTypes['AdminRejectKGIVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminRejectVirtualAccountVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminRejectVirtualAccountVerificationResponse'] = ResolversParentTypes['AdminRejectVirtualAccountVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResendAmlWithdrawInfoP01ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResendAMLWithdrawInfoP01Response'] = ResolversParentTypes['AdminResendAMLWithdrawInfoP01Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResendAmlWithdrawInfoP02ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResendAMLWithdrawInfoP02Response'] = ResolversParentTypes['AdminResendAMLWithdrawInfoP02Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResendFiatWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResendFiatWithdrawalResponse'] = ResolversParentTypes['AdminResendFiatWithdrawalResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResendKgiVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResendKGIVerificationResponse'] = ResolversParentTypes['AdminResendKGIVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResendWithdrawInfoP01ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResendWithdrawInfoP01Response'] = ResolversParentTypes['AdminResendWithdrawInfoP01Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResendWithdrawInfoP02ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResendWithdrawInfoP02Response'] = ResolversParentTypes['AdminResendWithdrawInfoP02Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResetKycVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResetKYCVerificationResponse'] = ResolversParentTypes['AdminResetKYCVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminResetPhoneResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminResetPhoneResponse'] = ResolversParentTypes['AdminResetPhoneResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSendBgWithdrawInfoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSendBGWithdrawInfoResponse'] = ResolversParentTypes['AdminSendBGWithdrawInfoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSendInvestorsSettlementResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSendInvestorsSettlementResponse'] = ResolversParentTypes['AdminSendInvestorsSettlementResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSendMemberInfoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSendMemberInfoResponse'] = ResolversParentTypes['AdminSendMemberInfoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSendWithdrawInfoP01ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSendWithdrawInfoP01Response'] = ResolversParentTypes['AdminSendWithdrawInfoP01Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSendWithdrawInfoP02ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSendWithdrawInfoP02Response'] = ResolversParentTypes['AdminSendWithdrawInfoP02Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSetOlderUserResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSetOlderUserResponse'] = ResolversParentTypes['AdminSetOlderUserResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSetTradePermissionResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSetTradePermissionResponse'] = ResolversParentTypes['AdminSetTradePermissionResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSubmitAmlVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSubmitAMLVerificationResponse'] = ResolversParentTypes['AdminSubmitAMLVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSubmitKycOneVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSubmitKYCOneVerificationResponse'] = ResolversParentTypes['AdminSubmitKYCOneVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSubmitKycTwoVerificationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSubmitKYCTwoVerificationResponse'] = ResolversParentTypes['AdminSubmitKYCTwoVerificationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSubmitRaAuditResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSubmitRAAuditResponse'] = ResolversParentTypes['AdminSubmitRAAuditResponse']
> = {
  record?: Resolver<ResolversTypes['AMLRARecord'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  userAssessmentResult?: Resolver<
    ResolversTypes['AMLUserAssessmentResult'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminSystemAuditAmlResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminSystemAuditAMLResponse'] = ResolversParentTypes['AdminSystemAuditAMLResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminTradeResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminTradeResponse'] = ResolversParentTypes['AdminTradeResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Trade']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminTradingReportResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminTradingReportResponse'] = ResolversParentTypes['AdminTradingReportResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUpdateCoinInfoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUpdateCoinInfoResponse'] = ResolversParentTypes['AdminUpdateCoinInfoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUpdateMarketInfoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUpdateMarketInfoResponse'] = ResolversParentTypes['AdminUpdateMarketInfoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUpdateOAuthClientResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUpdateOAuthClientResponse'] = ResolversParentTypes['AdminUpdateOAuthClientResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUpdateQuotationInfoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUpdateQuotationInfoResponse'] = ResolversParentTypes['AdminUpdateQuotationInfoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUploadSuspiciousTransactionResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUploadSuspiciousTransactionResponse'] = ResolversParentTypes['AdminUploadSuspiciousTransactionResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUploadVerificationFileResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUploadVerificationFileResponse'] = ResolversParentTypes['AdminUploadVerificationFileResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminUserResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminUserResponse'] = ResolversParentTypes['AdminUserResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminVipUpdateResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminVIPUpdateResponse'] = ResolversParentTypes['AdminVIPUpdateResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminVerifyKycIdentityResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminVerifyKYCIdentityResponse'] = ResolversParentTypes['AdminVerifyKYCIdentityResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminVoucherRecordsResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminVoucherRecordsResponse'] = ResolversParentTypes['AdminVoucherRecordsResponse']
> = {
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  records?: Resolver<Array<ResolversTypes['VoucherRecord']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminWithdrawalReportResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminWithdrawalReportResponse'] = ResolversParentTypes['AdminWithdrawalReportResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdminWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AdminWithdrawalResponse'] = ResolversParentTypes['AdminWithdrawalResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Withdrawal']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApproveKycLv2ResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ApproveKYCLv2Response'] = ResolversParentTypes['ApproveKYCLv2Response']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssetResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']
> = {
  contentType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<AssetContentTypeArgs>
  >
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<AssetDescriptionArgs>
  >
  fileName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<AssetFileNameArgs>
  >
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, Partial<AssetHeightArgs>>
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['AssetLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<AssetLinkedFromArgs>
  >
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, Partial<AssetSizeArgs>>
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<AssetTitleArgs>
  >
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<AssetUrlArgs>>
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, Partial<AssetWidthArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssetCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AssetCollection'] = ResolversParentTypes['AssetCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssetLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AssetLinkingCollections'] = ResolversParentTypes['AssetLinkingCollections']
> = {
  displayItemCollection?: Resolver<
    Maybe<ResolversTypes['DisplayItemCollection']>,
    ParentType,
    ContextType,
    RequireFields<AssetLinkingCollectionsDisplayItemCollectionArgs, 'limit' | 'skip'>
  >
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<AssetLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  heroSliderCollection?: Resolver<
    Maybe<ResolversTypes['HeroSliderCollection']>,
    ParentType,
    ContextType,
    RequireFields<AssetLinkingCollectionsHeroSliderCollectionArgs, 'limit' | 'skip'>
  >
  popUpCollection?: Resolver<
    Maybe<ResolversTypes['PopUpCollection']>,
    ParentType,
    ContextType,
    RequireFields<AssetLinkingCollectionsPopUpCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttributesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Attributes'] = ResolversParentTypes['Attributes']
> = {
  traitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Auth2FaResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Auth2FA'] = ResolversParentTypes['Auth2FA']
> = {
  qrCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token2FA?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthBind2FaResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AuthBind2FAResponse'] = ResolversParentTypes['AuthBind2FAResponse']
> = {
  data?: Resolver<ResolversTypes['Auth2FA'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthConfirm2FaResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AuthConfirm2FAResponse'] = ResolversParentTypes['AuthConfirm2FAResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['Confirm2FAData']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AuthMutation'] = ResolversParentTypes['AuthMutation']
> = {
  authBind2FA?: Resolver<ResolversTypes['AuthBind2FAResponse'], ParentType, ContextType>
  authConfirm2FA?: Resolver<
    ResolversTypes['AuthConfirm2FAResponse'],
    ParentType,
    ContextType,
    RequireFields<AuthMutationAuthConfirm2FaArgs, 'input'>
  >
  authUnbind2FA?: Resolver<
    ResolversTypes['AuthUnbind2FAResponse'],
    ParentType,
    ContextType,
    RequireFields<AuthMutationAuthUnbind2FaArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthUnbind2FaResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['AuthUnbind2FAResponse'] = ResolversParentTypes['AuthUnbind2FAResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Balance'] = ResolversParentTypes['Balance']
> = {
  available?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  frozen?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CurrencyType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BankResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']
> = {
  branches?: Resolver<Array<Maybe<ResolversTypes['BankBranch']>>, ParentType, ContextType>
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BankAccountResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BankAccount'] = ResolversParentTypes['BankAccount']
> = {
  account?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountHolder?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bankCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  branchCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  branchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  swiftCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BankBranchResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BankBranch'] = ResolversParentTypes['BankBranch']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BankInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BankInfo'] = ResolversParentTypes['BankInfo']
> = {
  account?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountHolder?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bankCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  branchCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BaseDepositResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BaseDeposit'] = ResolversParentTypes['BaseDeposit']
> = {
  __resolveType: TypeResolveFn<
    'CryptoDeposit' | 'FiatDeposit' | 'InternalTransferIn',
    ParentType,
    ContextType
  >
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['DepositStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['DepositType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
}

export type BaseResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BaseResponse'] = ResolversParentTypes['BaseResponse']
> = {
  __resolveType: TypeResolveFn<
    | 'AcceptFiatWithdrawalResponse'
    | 'AdminAPIKeyResponse'
    | 'AdminActivateMerchantResponse'
    | 'AdminAddAuditNoteResponse'
    | 'AdminApproveVirtualAccountVerificationResponse'
    | 'AdminAssignKYCVerifierResponse'
    | 'AdminAuditWithdrawalResponse'
    | 'AdminChangeVirtualAccountLimitResponse'
    | 'AdminCheckDepositInfoResponse'
    | 'AdminCheckUploadResultMResponse'
    | 'AdminCheckUploadResultP01Response'
    | 'AdminCheckUploadResultP02Response'
    | 'AdminCheckUploadResultP05Response'
    | 'AdminCheckUploadResultPG1Response'
    | 'AdminCheckUploadResultResponse'
    | 'AdminCreateAPIKeyResponse'
    | 'AdminCreateOAuthClientResponse'
    | 'AdminCreateRolesResponse'
    | 'AdminCreateUserResponse'
    | 'AdminDVBanResponse'
    | 'AdminDVUpdateResponse'
    | 'AdminDeleteAPIKeyResponse'
    | 'AdminDeleteRolesResponse'
    | 'AdminDepositReportResponse'
    | 'AdminDepositResponse'
    | 'AdminDisable2FAResponse'
    | 'AdminDisableUserResponse'
    | 'AdminEDDAuditResponse'
    | 'AdminEnableUserResponse'
    | 'AdminHolidayAddResponse'
    | 'AdminHolidayResponse'
    | 'AdminIPUserResponse'
    | 'AdminLedgerResponse'
    | 'AdminNSAuditResponse'
    | 'AdminOnHoldFiatWithdrawalResponse'
    | 'AdminOnHoldKGIVerificationResponse'
    | 'AdminRAAuditResponse'
    | 'AdminReauditKYCResponse'
    | 'AdminRecoverKGIStatusResponse'
    | 'AdminRefreshMerchantResponse'
    | 'AdminRejectFiatWithdrawalResponse'
    | 'AdminRejectKGIVerificationResponse'
    | 'AdminRejectVirtualAccountVerificationResponse'
    | 'AdminResendAMLWithdrawInfoP01Response'
    | 'AdminResendAMLWithdrawInfoP02Response'
    | 'AdminResendFiatWithdrawalResponse'
    | 'AdminResendKGIVerificationResponse'
    | 'AdminResendWithdrawInfoP01Response'
    | 'AdminResendWithdrawInfoP02Response'
    | 'AdminResetKYCVerificationResponse'
    | 'AdminResetPhoneResponse'
    | 'AdminSendBGWithdrawInfoResponse'
    | 'AdminSendInvestorsSettlementResponse'
    | 'AdminSendMemberInfoResponse'
    | 'AdminSendWithdrawInfoP01Response'
    | 'AdminSendWithdrawInfoP02Response'
    | 'AdminSetOlderUserResponse'
    | 'AdminSetTradePermissionResponse'
    | 'AdminSubmitAMLVerificationResponse'
    | 'AdminSubmitKYCOneVerificationResponse'
    | 'AdminSubmitKYCTwoVerificationResponse'
    | 'AdminSubmitRAAuditResponse'
    | 'AdminSystemAuditAMLResponse'
    | 'AdminTradeResponse'
    | 'AdminTradingReportResponse'
    | 'AdminUpdateCoinInfoResponse'
    | 'AdminUpdateMarketInfoResponse'
    | 'AdminUpdateOAuthClientResponse'
    | 'AdminUpdateQuotationInfoResponse'
    | 'AdminUploadSuspiciousTransactionResponse'
    | 'AdminUploadVerificationFileResponse'
    | 'AdminUserResponse'
    | 'AdminVIPUpdateResponse'
    | 'AdminVerifyKYCIdentityResponse'
    | 'AdminVoucherRecordsResponse'
    | 'AdminWithdrawalReportResponse'
    | 'AdminWithdrawalResponse'
    | 'ApproveKYCLv2Response'
    | 'AuthBind2FAResponse'
    | 'AuthConfirm2FAResponse'
    | 'AuthUnbind2FAResponse'
    | 'ChartResponse'
    | 'CommonAddressResponse'
    | 'DepositResponse'
    | 'FAASPaymentResponse'
    | 'KYCConfirmSubmissionResponse'
    | 'KYCSubmitLevelOneResponse'
    | 'KYCSubmitLevelTwoResponse'
    | 'LedgerResponse'
    | 'NotificationCountResponse'
    | 'NotificationListResponse'
    | 'NotificationReadResponse'
    | 'PointPurchaseResponse'
    | 'RejectFiatWithdrawalResponse'
    | 'ResourceConfirmUploadResponse'
    | 'ResourceRequestUploadURLResponse'
    | 'TradeResponse'
    | 'TradingConfirmQuotationResponse'
    | 'TradingQuotationResponse'
    | 'TradingReportResponse'
    | 'UserActionLogResponse'
    | 'UserAgreeResponse'
    | 'UserBindPhoneConfirmationResponse'
    | 'UserBindPhoneResponse'
    | 'UserCancelOAuthResponse'
    | 'UserChangePasswordResponse'
    | 'UserCustomizeReferralResponse'
    | 'UserDeleteBankAccountResponse'
    | 'UserDeviceTokenResponse'
    | 'UserFillReferrerCodeResponse'
    | 'UserForgotPasswordConfirmResponse'
    | 'UserForgotPasswordResponse'
    | 'UserLoginRecordsResponse'
    | 'UserLoginResponse'
    | 'UserOAuthClientResponse'
    | 'UserPreferenceResponse'
    | 'UserRefreshTokenResponse'
    | 'UserRegisterConfirmResponse'
    | 'UserRegisterResponse'
    | 'UserRequestAccountDisableResponse'
    | 'UserResendForgotPasswordResponse'
    | 'UserResendRegisterResponse'
    | 'VoucherUseResponse'
    | 'WalletCreateCommonAddressResponse'
    | 'WalletCreateCryptoAddressResponse'
    | 'WalletCryptoWithdrawResponse'
    | 'WalletDeleteCommonAddressResponse'
    | 'WalletDepositResponse'
    | 'WalletFAASPaymentConfirmResponse'
    | 'WalletFiatWithdrawResponse'
    | 'WalletWithdrawConfirmResponse'
    | 'WalletWithdrawalResponse'
    | 'WithdrawalResponse',
    ParentType,
    ContextType
  >
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
}

export type BaseWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BaseWithdrawal'] = ResolversParentTypes['BaseWithdrawal']
> = {
  __resolveType: TypeResolveFn<
    'CryptoWithdrawal' | 'FiatWithdrawal' | 'InternalTransferOut',
    ParentType,
    ContextType
  >
  amount?: Resolver<Maybe<ResolversTypes['Number']>, ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>
  fee?: Resolver<Maybe<ResolversTypes['Number']>, ParentType, ContextType>
  feeCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['WithdrawalStatus']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['WithdrawalType']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
}

export type BasicInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['BasicInfo'] = ResolversParentTypes['BasicInfo']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  birthday?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  industry?: Resolver<Maybe<ResolversTypes['Industry']>, ParentType, ContextType>
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CefiDepositAddressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CefiDepositAddress'] = ResolversParentTypes['CefiDepositAddress']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['Chain'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CefiWalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CefiWallet'] = ResolversParentTypes['CefiWallet']
> = {
  exchange?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletBalances?: Resolver<Array<ResolversTypes['WalletBalances']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChartResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ChartResponse'] = ResolversParentTypes['ChartResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['MarketPrice']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CoinPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CoinPrice'] = ResolversParentTypes['CoinPrice']
> = {
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  usdtPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CommonAddressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CommonAddress'] = ResolversParentTypes['CommonAddress']
> = {
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CommonAddressResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CommonAddressResponse'] = ResolversParentTypes['CommonAddressResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['CommonAddress']>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Confirm2FaDataResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Confirm2FAData'] = ResolversParentTypes['Confirm2FAData']
> = {
  __resolveType: TypeResolveFn<
    | 'CryptoWithdrawal'
    | 'ExternalAPIKeyWithdrawal'
    | 'FAASPayment'
    | 'FiatWithdrawal'
    | 'InternalTransferOut'
    | 'LoginInfo',
    ParentType,
    ContextType
  >
}

export type ContentfulMetadataResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ContentfulMetadata'] = ResolversParentTypes['ContentfulMetadata']
> = {
  tags?: Resolver<Array<Maybe<ResolversTypes['ContentfulTag']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentfulTagResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ContentfulTag'] = ResolversParentTypes['ContentfulTag']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CountryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']
> = {
  callingCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatorResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Creator'] = ResolversParentTypes['Creator']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CryptoAssetResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CryptoAsset'] = ResolversParentTypes['CryptoAsset']
> = {
  asset?: Resolver<
    Maybe<ResolversTypes['PaymentMethod']>,
    ParentType,
    ContextType,
    Partial<CryptoAssetAssetArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CryptoDepositResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CryptoDeposit'] = ResolversParentTypes['CryptoDeposit']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  chain?: Resolver<Maybe<ResolversTypes['Chain']>, ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  fromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['DepositStatus'], ParentType, ContextType>
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['DepositType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CryptoWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CryptoWithdrawal'] = ResolversParentTypes['CryptoWithdrawal']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['Chain'], ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  fee?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  feeCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  fromAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeduction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['WithdrawalStatus'], ParentType, ContextType>
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['WithdrawalType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyAmountResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyAmount'] = ResolversParentTypes['CurrencyAmount']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyBalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyBalance'] = ResolversParentTypes['CurrencyBalance']
> = {
  available?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyBalancesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyBalances'] = ResolversParentTypes['CurrencyBalances']
> = {
  balance?: Resolver<ResolversTypes['CurrencyBalance'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrencyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['CurrencyInfo'] = ResolversParentTypes['CurrencyInfo']
> = {
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type DefiWalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DefiWallet'] = ResolversParentTypes['DefiWallet']
> = {
  balance?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balanceComputed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  balanceComputedWithUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  currencyInfo?: Resolver<ResolversTypes['CurrencyInfo'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DepositResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Deposit'] = ResolversParentTypes['Deposit']
> = {
  __resolveType: TypeResolveFn<
    'CryptoDeposit' | 'FiatDeposit' | 'InternalTransferIn',
    ParentType,
    ContextType
  >
}

export type DepositResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DepositResponse'] = ResolversParentTypes['DepositResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Deposit']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DevMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DevMutation'] = ResolversParentTypes['DevMutation']
> = {
  acceptFiatWithdrawal?: Resolver<
    ResolversTypes['AcceptFiatWithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<DevMutationAcceptFiatWithdrawalArgs, 'input'>
  >
  approveKYCLv2?: Resolver<
    ResolversTypes['ApproveKYCLv2Response'],
    ParentType,
    ContextType,
    RequireFields<DevMutationApproveKycLv2Args, 'input'>
  >
  rejectFiatWithdrawal?: Resolver<
    ResolversTypes['RejectFiatWithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<DevMutationRejectFiatWithdrawalArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DimensionScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Dimension'], any> {
  name: 'Dimension'
}

export type DisplayItemResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DisplayItem'] = ResolversParentTypes['DisplayItem']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  defaultSharingBackground?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<DisplayItemDefaultSharingBackgroundArgs>
  >
  enableSpecialSharingBackgroundsMode?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<DisplayItemEnableSpecialSharingBackgroundsModeArgs>
  >
  forUsersCollection?: Resolver<
    Maybe<ResolversTypes['DisplayItemForUsersCollection']>,
    ParentType,
    ContextType,
    RequireFields<DisplayItemForUsersCollectionArgs, 'limit' | 'skip'>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['DisplayItemLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<DisplayItemLinkedFromArgs>
  >
  purpose?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<DisplayItemPurposeArgs>
  >
  specialSharingBackgroundsCollection?: Resolver<
    Maybe<ResolversTypes['AssetCollection']>,
    ParentType,
    ContextType,
    RequireFields<DisplayItemSpecialSharingBackgroundsCollectionArgs, 'limit' | 'skip'>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<DisplayItemTitleArgs>
  >
  userProfileImage?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<DisplayItemUserProfileImageArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DisplayItemCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DisplayItemCollection'] = ResolversParentTypes['DisplayItemCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['DisplayItem']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DisplayItemForUsersCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DisplayItemForUsersCollection'] = ResolversParentTypes['DisplayItemForUsersCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['SpecialUser']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DisplayItemLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['DisplayItemLinkingCollections'] = ResolversParentTypes['DisplayItemLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<DisplayItemLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface EmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Email'], any> {
  name: 'Email'
}

export type EntryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Entry'] = ResolversParentTypes['Entry']
> = {
  __resolveType: TypeResolveFn<
    | 'ActivityNotification'
    | 'DisplayItem'
    | 'HeroSlider'
    | 'LandingPageCustomMessage'
    | 'PopUp'
    | 'Release'
    | 'ReleaseNote'
    | 'Section'
    | 'SectionContent'
    | 'SpecialUser'
    | 'StrategyMeta',
    ParentType,
    ContextType
  >
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
}

export type EntryCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['EntryCollection'] = ResolversParentTypes['EntryCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalApiKeyResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ExternalAPIKey'] = ResolversParentTypes['ExternalAPIKey']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isRevoked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['UserInfo']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalApiKeyWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ExternalAPIKeyWithdrawal'] = ResolversParentTypes['ExternalAPIKeyWithdrawal']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FaasPaymentResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FAASPayment'] = ResolversParentTypes['FAASPayment']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  cryptoWithdrawal?: Resolver<Maybe<ResolversTypes['CryptoWithdrawal']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  internalTransferOut?: Resolver<
    Maybe<ResolversTypes['InternalTransferOut']>,
    ParentType,
    ContextType
  >
  merchantID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FaasPaymentResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FAASPaymentResponse'] = ResolversParentTypes['FAASPaymentResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['FAASPayment']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FiatDepositResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FiatDeposit'] = ResolversParentTypes['FiatDeposit']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['DepositStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['DepositType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FiatDepositInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FiatDepositInfo'] = ResolversParentTypes['FiatDepositInfo']
> = {
  dailyAccumulated?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  monthlyAccumulated?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  userBindBank?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType>
  virtualBank?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FiatWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FiatWithdrawal'] = ResolversParentTypes['FiatWithdrawal']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  fee?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  feeCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['WithdrawalStatus'], ParentType, ContextType>
  toBankAccount?: Resolver<ResolversTypes['BankInfo'], ParentType, ContextType>
  toBankAccountID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['WithdrawalType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FrontendVersionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['FrontendVersion'] = ResolversParentTypes['FrontendVersion']
> = {
  commitHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  smartContracts?: Resolver<Array<ResolversTypes['SmartContract']>, ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GimCoolPayPaymentInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['GimCoolPayPaymentInfo'] = ResolversParentTypes['GimCoolPayPaymentInfo']
> = {
  blockchain?: Resolver<
    ResolversTypes['GimCoolPayPaymentSupportingBlockchain'],
    ParentType,
    ContextType
  >
  coinAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coinType?: Resolver<
    ResolversTypes['GimCoolPayPaymentSupportingCoinType'],
    ParentType,
    ContextType
  >
  fiatAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fiatType?: Resolver<
    ResolversTypes['GimCoolPayPaymentSupportingFiatType'],
    ParentType,
    ContextType
  >
  paymentHref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['GimCoolPayPriceResponse']>, ParentType, ContextType>
  walletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletAddressTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GimCoolPayPriceResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['GimCoolPayPriceResponse'] = ResolversParentTypes['GimCoolPayPriceResponse']
> = {
  prices?: Resolver<
    Maybe<Array<ResolversTypes['GimCoolPayReadyToPurchasePrice']>>,
    ParentType,
    ContextType
  >
  spotPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GimCoolPayReadyToPurchasePriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['GimCoolPayReadyToPurchasePrice'] = ResolversParentTypes['GimCoolPayReadyToPurchasePrice']
> = {
  coinAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  coinCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fiatAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fiatCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  networkFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentMethodId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spotPriceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spotPriceIncludingFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroSliderResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['HeroSlider'] = ResolversParentTypes['HeroSlider']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  image?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<HeroSliderImageArgs>
  >
  link?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<HeroSliderLinkArgs>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['HeroSliderLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<HeroSliderLinkedFromArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<HeroSliderTitleArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroSliderCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['HeroSliderCollection'] = ResolversParentTypes['HeroSliderCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['HeroSlider']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroSliderLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['HeroSliderLinkingCollections'] = ResolversParentTypes['HeroSliderLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<HeroSliderLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface HexColorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['HexColor'], any> {
  name: 'HexColor'
}

export type HolidayResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Holiday'] = ResolversParentTypes['Holiday']
> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IdentityInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['IdentityInfo'] = ResolversParentTypes['IdentityInfo']
> = {
  barcodeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idType?: Resolver<Maybe<ResolversTypes['IdentityType']>, ParentType, ContextType>
  issuanceDate?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  issuanceLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  issuanceType?: Resolver<Maybe<ResolversTypes['IdentityIssuanceType']>, ParentType, ContextType>
  nationality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  verifyStatus?: Resolver<Maybe<ResolversTypes['IdentityVerifyStatus']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InternalTransferInResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['InternalTransferIn'] = ResolversParentTypes['InternalTransferIn']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  fromUserID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['DepositStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['DepositType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InternalTransferOutResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['InternalTransferOut'] = ResolversParentTypes['InternalTransferOut']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  completedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  fee?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  feeCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['WithdrawalStatus'], ParentType, ContextType>
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toUserID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['WithdrawalType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type KycResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYC'] = ResolversParentTypes['KYC']
> = {
  aml?: Resolver<Maybe<ResolversTypes['AMLVerification']>, ParentType, ContextType>
  lv1?: Resolver<Maybe<ResolversTypes['KYCLevelOne']>, ParentType, ContextType>
  lv2?: Resolver<Maybe<ResolversTypes['KYCLevelTwo']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycConfirmSubmissionResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCConfirmSubmissionResponse'] = ResolversParentTypes['KYCConfirmSubmissionResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycLevelOneResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCLevelOne'] = ResolversParentTypes['KYCLevelOne']
> = {
  addressPhoto?: Resolver<Maybe<ResolversTypes['KYCPhoto']>, ParentType, ContextType>
  basicInfo?: Resolver<Maybe<ResolversTypes['BasicInfo']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  idBackPhoto?: Resolver<Maybe<ResolversTypes['KYCPhoto']>, ParentType, ContextType>
  idFrontPhoto?: Resolver<Maybe<ResolversTypes['KYCPhoto']>, ParentType, ContextType>
  identityInfo?: Resolver<Maybe<ResolversTypes['IdentityInfo']>, ParentType, ContextType>
  isReSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  questions?: Resolver<Maybe<ResolversTypes['QuestionInfo']>, ParentType, ContextType>
  secondIDPhoto?: Resolver<Maybe<ResolversTypes['KYCPhoto']>, ParentType, ContextType>
  selfiePhoto?: Resolver<Maybe<ResolversTypes['KYCPhoto']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['KYCStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  verifier?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycLevelTwoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCLevelTwo'] = ResolversParentTypes['KYCLevelTwo']
> = {
  bankInfo?: Resolver<Maybe<ResolversTypes['BankInfo']>, ParentType, ContextType>
  bankPhoto?: Resolver<Maybe<ResolversTypes['KYCPhoto']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isReSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['KYCStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  verifier?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCMutation'] = ResolversParentTypes['KYCMutation']
> = {
  kycConfirmSubmission?: Resolver<
    ResolversTypes['KYCConfirmSubmissionResponse'],
    ParentType,
    ContextType,
    RequireFields<KycMutationKycConfirmSubmissionArgs, 'input'>
  >
  kycSubmitLevelOne?: Resolver<
    ResolversTypes['KYCSubmitLevelOneResponse'],
    ParentType,
    ContextType,
    RequireFields<KycMutationKycSubmitLevelOneArgs, 'input'>
  >
  kycSubmitLevelTwo?: Resolver<
    ResolversTypes['KYCSubmitLevelTwoResponse'],
    ParentType,
    ContextType,
    RequireFields<KycMutationKycSubmitLevelTwoArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycPhotoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCPhoto'] = ResolversParentTypes['KYCPhoto']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  firstVerification?: Resolver<
    Maybe<ResolversTypes['KYCPhotoVerification']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rejectedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  resourceURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  secondVerification?: Resolver<
    Maybe<ResolversTypes['KYCPhotoVerification']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['KYCPhotoStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycPhotoVerificationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCPhotoVerification'] = ResolversParentTypes['KYCPhotoVerification']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isVerified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  rejectedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  verifier?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycSubmitLevelOneResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCSubmitLevelOneResponse'] = ResolversParentTypes['KYCSubmitLevelOneResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KycSubmitLevelTwoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KYCSubmitLevelTwoResponse'] = ResolversParentTypes['KYCSubmitLevelTwoResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KgiUploadResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['KgiUploadResponse'] = ResolversParentTypes['KgiUploadResponse']
> = {
  Code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  Desc?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  Filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  Status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessage'] = ResolversParentTypes['LandingPageCustomMessage']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  customMessage?: Resolver<
    Maybe<ResolversTypes['LandingPageCustomMessageCustomMessage']>,
    ParentType,
    ContextType,
    Partial<LandingPageCustomMessageCustomMessageArgs>
  >
  displayEnvironment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<LandingPageCustomMessageDisplayEnvironmentArgs>
  >
  email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<LandingPageCustomMessageEmailArgs>
  >
  endTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    Partial<LandingPageCustomMessageEndTimeArgs>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['LandingPageCustomMessageLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<LandingPageCustomMessageLinkedFromArgs>
  >
  startTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    Partial<LandingPageCustomMessageStartTimeArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageCollection'] = ResolversParentTypes['LandingPageCustomMessageCollection']
> = {
  items?: Resolver<
    Array<Maybe<ResolversTypes['LandingPageCustomMessage']>>,
    ParentType,
    ContextType
  >
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageCustomMessageResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageCustomMessage'] = ResolversParentTypes['LandingPageCustomMessageCustomMessage']
> = {
  json?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  links?: Resolver<
    ResolversTypes['LandingPageCustomMessageCustomMessageLinks'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageCustomMessageAssetsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageCustomMessageAssets'] = ResolversParentTypes['LandingPageCustomMessageCustomMessageAssets']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageCustomMessageEntriesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageCustomMessageEntries'] = ResolversParentTypes['LandingPageCustomMessageCustomMessageEntries']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  inline?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageCustomMessageLinksResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageCustomMessageLinks'] = ResolversParentTypes['LandingPageCustomMessageCustomMessageLinks']
> = {
  assets?: Resolver<
    ResolversTypes['LandingPageCustomMessageCustomMessageAssets'],
    ParentType,
    ContextType
  >
  entries?: Resolver<
    ResolversTypes['LandingPageCustomMessageCustomMessageEntries'],
    ParentType,
    ContextType
  >
  resources?: Resolver<
    ResolversTypes['LandingPageCustomMessageCustomMessageResources'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageCustomMessageResourcesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageCustomMessageResources'] = ResolversParentTypes['LandingPageCustomMessageCustomMessageResources']
> = {
  block?: Resolver<Array<ResolversTypes['ResourceLink']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LandingPageCustomMessageLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LandingPageCustomMessageLinkingCollections'] = ResolversParentTypes['LandingPageCustomMessageLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<LandingPageCustomMessageLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LedgerResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Ledger'] = ResolversParentTypes['Ledger']
> = {
  amount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  balance?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ledgerEntry?: Resolver<ResolversTypes['LedgerEntry'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LedgerEntryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LedgerEntry'] = ResolversParentTypes['LedgerEntry']
> = {
  __resolveType: TypeResolveFn<
    | 'CryptoDeposit'
    | 'CryptoWithdrawal'
    | 'FiatDeposit'
    | 'FiatWithdrawal'
    | 'InternalTransferIn'
    | 'InternalTransferOut'
    | 'Trade',
    ParentType,
    ContextType
  >
}

export type LedgerResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LedgerResponse'] = ResolversParentTypes['LedgerResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Ledger']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoginInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LoginInfo'] = ResolversParentTypes['LoginInfo']
> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  expiresIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoginResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']
> = {
  __resolveType: TypeResolveFn<'LoginInfo' | 'Response2FA', ParentType, ContextType>
}

export type MarketInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['MarketInfo'] = ResolversParentTypes['MarketInfo']
> = {
  feeRate?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  isTradable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  market?: Resolver<ResolversTypes['Market'], ParentType, ContextType>
  maxSize?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  minSize?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  priceIncrement?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  sizeIncrement?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['MarketPrice'] = ResolversParentTypes['MarketPrice']
> = {
  ask?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  bid?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  market?: Resolver<ResolversTypes['Market'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['PriceType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MeResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Me'] = ResolversParentTypes['Me']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  information?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetaPaginationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['MetaPagination'] = ResolversParentTypes['MetaPagination']
> = {
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetricResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Metric'] = ResolversParentTypes['Metric']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionManagersInfo?: Resolver<
    Maybe<Array<ResolversTypes['PositionManagerInfo']>>,
    ParentType,
    ContextType
  >
  positions?: Resolver<Maybe<Array<ResolversTypes['MetricPosition']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetricPositionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['MetricPosition'] = ResolversParentTypes['MetricPosition']
> = {
  amountUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  positionIdTotalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  positionIsRunning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  positionManagerContractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionOwnerAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  strategyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  strategyProvider?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sumUsd?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  closeUniswapv3Position?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCloseUniswapv3PositionArgs, 'input'>
  >
  createAICompletionRecord?: Resolver<
    ResolversTypes['aICompletionRecords'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAiCompletionRecordArgs, 'input'>
  >
  createUniswapv3Position?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUniswapv3PositionArgs, 'input'>
  >
  createUniswapv3Tool?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUniswapv3ToolArgs, 'input'>
  >
  externalAPIKeyCreate?: Resolver<
    ResolversTypes['ExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<MutationExternalApiKeyCreateArgs, 'input'>
  >
  externalAPIKeyDelete?: Resolver<
    ResolversTypes['ExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<MutationExternalApiKeyDeleteArgs, 'input'>
  >
  generateMetrics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  strategyStart?: Resolver<
    ResolversTypes['StrategyStartResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStrategyStartArgs, 'input'>
  >
  strategyStop?: Resolver<
    ResolversTypes['StrategyStopResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStrategyStopArgs, 'input'>
  >
  strategyUpdate?: Resolver<
    ResolversTypes['StrategyUpdateResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStrategyUpdateArgs, 'input'>
  >
}

export type NftCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NFTCollections'] = ResolversParentTypes['NFTCollections']
> = {
  attributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attributes']>>>, ParentType, ContextType>
  contract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NftMerkleTreeHexProofResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NFTMerkleTreeHexProof'] = ResolversParentTypes['NFTMerkleTreeHexProof']
> = {
  hexProof?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NftWhitelistInfoByPhraseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NFTWhitelistInfoByPhrase'] = ResolversParentTypes['NFTWhitelistInfoByPhrase']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isWhitelisted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  phrase?: Resolver<ResolversTypes['NFT_ROUND'], ParentType, ContextType>
  userAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']
> = {
  notificationCount?: Resolver<
    ResolversTypes['NotificationCountResponse'],
    ParentType,
    ContextType,
    Partial<NotificationNotificationCountArgs>
  >
  notificationList?: Resolver<
    ResolversTypes['NotificationListResponse'],
    ParentType,
    ContextType,
    Partial<NotificationNotificationListArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationCountResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NotificationCount'] = ResolversParentTypes['NotificationCount']
> = {
  num?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationCountResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NotificationCountResponse'] = ResolversParentTypes['NotificationCountResponse']
> = {
  data?: Resolver<ResolversTypes['NotificationCount'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NotificationInfo'] = ResolversParentTypes['NotificationInfo']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  productName?: Resolver<ResolversTypes['ProductName'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationListResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NotificationListResponse'] = ResolversParentTypes['NotificationListResponse']
> = {
  data?: Resolver<Array<ResolversTypes['NotificationInfo']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NotificationMutation'] = ResolversParentTypes['NotificationMutation']
> = {
  notificationRead?: Resolver<
    ResolversTypes['NotificationReadResponse'],
    ParentType,
    ContextType,
    Partial<NotificationMutationNotificationReadArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationReadResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['NotificationReadResponse'] = ResolversParentTypes['NotificationReadResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface NumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Number'], any> {
  name: 'Number'
}

export type OnHoldKgiVerificationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['OnHoldKGIVerification'] = ResolversParentTypes['OnHoldKGIVerification']
> = {
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PointResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Point'] = ResolversParentTypes['Point']
> = {
  ledgers?: Resolver<
    ResolversTypes['PointLedgerRecordResponse'],
    ParentType,
    ContextType,
    RequireFields<PointLedgersArgs, 'pagination'>
  >
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PointLedgerRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PointLedgerRecord'] = ResolversParentTypes['PointLedgerRecord']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  productID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  time?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['PointType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PointLedgerRecordResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PointLedgerRecordResponse'] = ResolversParentTypes['PointLedgerRecordResponse']
> = {
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['PointLedgerRecord']>>>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PointMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PointMutation'] = ResolversParentTypes['PointMutation']
> = {
  pointPurchase?: Resolver<
    ResolversTypes['PointPurchaseResponse'],
    ParentType,
    ContextType,
    RequireFields<PointMutationPointPurchaseArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PointPurchaseResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PointPurchaseResponse'] = ResolversParentTypes['PointPurchaseResponse']
> = {
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  price?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUp'] = ResolversParentTypes['PopUp']
> = {
  backgroundColor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpBackgroundColorArgs>
  >
  callToActionButtonLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpCallToActionButtonLinkArgs>
  >
  callToActionButtonText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpCallToActionButtonTextArgs>
  >
  callToActionButtonVariant?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpCallToActionButtonVariantArgs>
  >
  closeButtonText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpCloseButtonTextArgs>
  >
  closeButtonVariant?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpCloseButtonVariantArgs>
  >
  contentArea?: Resolver<
    Maybe<ResolversTypes['PopUpContentArea']>,
    ParentType,
    ContextType,
    Partial<PopUpContentAreaArgs>
  >
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  displayEnvironment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpDisplayEnvironmentArgs>
  >
  displayMode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpDisplayModeArgs>
  >
  endTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    Partial<PopUpEndTimeArgs>
  >
  imageDesktop?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PopUpImageDesktopArgs>
  >
  imageModile?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PopUpImageModileArgs>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['PopUpLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<PopUpLinkedFromArgs>
  >
  priority?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<PopUpPriorityArgs>
  >
  startTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    Partial<PopUpStartTimeArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<PopUpTitleArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpCollection'] = ResolversParentTypes['PopUpCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['PopUp']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpContentAreaResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpContentArea'] = ResolversParentTypes['PopUpContentArea']
> = {
  json?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  links?: Resolver<ResolversTypes['PopUpContentAreaLinks'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpContentAreaAssetsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpContentAreaAssets'] = ResolversParentTypes['PopUpContentAreaAssets']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpContentAreaEntriesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpContentAreaEntries'] = ResolversParentTypes['PopUpContentAreaEntries']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  inline?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpContentAreaLinksResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpContentAreaLinks'] = ResolversParentTypes['PopUpContentAreaLinks']
> = {
  assets?: Resolver<ResolversTypes['PopUpContentAreaAssets'], ParentType, ContextType>
  entries?: Resolver<ResolversTypes['PopUpContentAreaEntries'], ParentType, ContextType>
  resources?: Resolver<ResolversTypes['PopUpContentAreaResources'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpContentAreaResourcesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpContentAreaResources'] = ResolversParentTypes['PopUpContentAreaResources']
> = {
  block?: Resolver<Array<ResolversTypes['ResourceLink']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopUpLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PopUpLinkingCollections'] = ResolversParentTypes['PopUpLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<PopUpLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PositionManagerInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['PositionManagerInfo'] = ResolversParentTypes['PositionManagerInfo']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublicResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Public'] = ResolversParentTypes['Public']
> = {
  banks?: Resolver<
    Array<Maybe<ResolversTypes['Bank']>>,
    ParentType,
    ContextType,
    Partial<PublicBanksArgs>
  >
  chart?: Resolver<
    ResolversTypes['ChartResponse'],
    ParentType,
    ContextType,
    RequireFields<PublicChartArgs, 'input'>
  >
  countries?: Resolver<Array<Maybe<ResolversTypes['Country']>>, ParentType, ContextType>
  cryptoAsset?: Resolver<Maybe<ResolversTypes['CryptoAsset']>, ParentType, ContextType>
  keyOwnerID?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<PublicKeyOwnerIdArgs, 'key'>
  >
  marketInfo?: Resolver<
    Array<ResolversTypes['MarketInfo']>,
    ParentType,
    ContextType,
    RequireFields<PublicMarketInfoArgs, 'markets'>
  >
  marketPrice?: Resolver<
    Array<Maybe<ResolversTypes['MarketPrice']>>,
    ParentType,
    ContextType,
    RequireFields<PublicMarketPriceArgs, 'markets' | 'types'>
  >
  questionnaire?: Resolver<
    ResolversTypes['Questionnaire'],
    ParentType,
    ContextType,
    RequireFields<PublicQuestionnaireArgs, 'purpose'>
  >
  version?: Resolver<ResolversTypes['VersionInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface QualityScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Quality'], any> {
  name: 'Quality'
}

export type QueryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  coinPrices?: Resolver<Maybe<Array<ResolversTypes['CoinPrice']>>, ParentType, ContextType>
  me?: Resolver<ResolversTypes['UserInfo'], ParentType, ContextType>
  metric?: Resolver<Maybe<Array<ResolversTypes['Metric']>>, ParentType, ContextType>
  poolsWithArguments?: Resolver<
    Maybe<Array<ResolversTypes['UniswapPoolInfo']>>,
    ParentType,
    ContextType,
    RequireFields<QueryPoolsWithArgumentsArgs, 'chain'>
  >
  rateHistorical?: Resolver<
    Maybe<Array<ResolversTypes['RateHistorical']>>,
    ParentType,
    ContextType,
    RequireFields<QueryRateHistoricalArgs, 'filter'>
  >
  strategies?: Resolver<
    Array<ResolversTypes['StrategyInfo']>,
    ParentType,
    ContextType,
    Partial<QueryStrategiesArgs>
  >
  tools?: Resolver<Array<ResolversTypes['ToolInfo']>, ParentType, ContextType>
  uniswapQuote?: Resolver<
    Maybe<ResolversTypes['UniswapQuote']>,
    ParentType,
    ContextType,
    RequireFields<QueryUniswapQuoteArgs, 'input'>
  >
  uniswapTokens?: Resolver<
    Maybe<Array<ResolversTypes['UniswapToolToken']>>,
    ParentType,
    ContextType,
    RequireFields<QueryUniswapTokensArgs, 'chain'>
  >
  uniswapV3Backtest?: Resolver<
    Maybe<ResolversTypes['UniswapV3BacktestResult']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryUniswapV3BacktestArgs,
      'compound' | 'days' | 'diff' | 'investmentAmount' | 'period' | 'poolID' | 'protocol'
    >
  >
  version?: Resolver<ResolversTypes['FrontendVersion'], ParentType, ContextType>
  wallet?: Resolver<
    Maybe<ResolversTypes['DefiWallet']>,
    ParentType,
    ContextType,
    Partial<QueryWalletArgs>
  >
}

export type QuestionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  options?: Resolver<Array<ResolversTypes['QuestionOption']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['QuestionInfo'] = ResolversParentTypes['QuestionInfo']
> = {
  annualIncome?: Resolver<ResolversTypes['QuestionAnnualIncome'], ParentType, ContextType>
  anotherNationality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  period?: Resolver<ResolversTypes['QuestionPeriod'], ParentType, ContextType>
  purpose?: Resolver<ResolversTypes['QuestionPurpose'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionOptionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['QuestionOption'] = ResolversParentTypes['QuestionOption']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hasRemark?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSelected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  remark?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Questionnaire'] = ResolversParentTypes['Questionnaire']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  purpose?: Resolver<ResolversTypes['QuestionnairePurpose'], ParentType, ContextType>
  questions?: Resolver<Array<ResolversTypes['Question']>, ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuotationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Quotation'] = ResolversParentTypes['Quotation']
> = {
  baseAmount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  expiredAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  fee?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  feeCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  feeRate?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  market?: Resolver<ResolversTypes['Market'], ParentType, ContextType>
  price?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  quoteAmount?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  side?: Resolver<ResolversTypes['OrderSide'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RateHistoricalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['RateHistorical'] = ResolversParentTypes['RateHistorical']
> = {
  rate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RejectFiatWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['RejectFiatWithdrawalResponse'] = ResolversParentTypes['RejectFiatWithdrawalResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Release'] = ResolversParentTypes['Release']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['ReleaseLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<ReleaseLinkedFromArgs>
  >
  notesCollection?: Resolver<
    Maybe<ResolversTypes['ReleaseNotesCollection']>,
    ParentType,
    ContextType,
    RequireFields<ReleaseNotesCollectionArgs, 'limit' | 'skip'>
  >
  releaseDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    Partial<ReleaseReleaseDateArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ReleaseTitleArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseCollection'] = ResolversParentTypes['ReleaseCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Release']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseLinkingCollections'] = ResolversParentTypes['ReleaseLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<ReleaseLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNote'] = ResolversParentTypes['ReleaseNote']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['ReleaseNoteLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<ReleaseNoteLinkedFromArgs>
  >
  platform?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ReleaseNotePlatformArgs>
  >
  releaseNote?: Resolver<
    Maybe<ResolversTypes['ReleaseNoteReleaseNote']>,
    ParentType,
    ContextType,
    Partial<ReleaseNoteReleaseNoteArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  version?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ReleaseNoteVersionArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteCollection'] = ResolversParentTypes['ReleaseNoteCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['ReleaseNote']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteLinkingCollections'] = ResolversParentTypes['ReleaseNoteLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<ReleaseNoteLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  releaseCollection?: Resolver<
    Maybe<ResolversTypes['ReleaseCollection']>,
    ParentType,
    ContextType,
    RequireFields<ReleaseNoteLinkingCollectionsReleaseCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteReleaseNoteResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteReleaseNote'] = ResolversParentTypes['ReleaseNoteReleaseNote']
> = {
  json?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  links?: Resolver<ResolversTypes['ReleaseNoteReleaseNoteLinks'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteReleaseNoteAssetsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteReleaseNoteAssets'] = ResolversParentTypes['ReleaseNoteReleaseNoteAssets']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteReleaseNoteEntriesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteReleaseNoteEntries'] = ResolversParentTypes['ReleaseNoteReleaseNoteEntries']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  inline?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteReleaseNoteLinksResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteReleaseNoteLinks'] = ResolversParentTypes['ReleaseNoteReleaseNoteLinks']
> = {
  assets?: Resolver<ResolversTypes['ReleaseNoteReleaseNoteAssets'], ParentType, ContextType>
  entries?: Resolver<ResolversTypes['ReleaseNoteReleaseNoteEntries'], ParentType, ContextType>
  resources?: Resolver<ResolversTypes['ReleaseNoteReleaseNoteResources'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNoteReleaseNoteResourcesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNoteReleaseNoteResources'] = ResolversParentTypes['ReleaseNoteReleaseNoteResources']
> = {
  block?: Resolver<Array<ResolversTypes['ResourceLink']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReleaseNotesCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReleaseNotesCollection'] = ResolversParentTypes['ReleaseNotesCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['ReleaseNote']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResourceConfirmUploadResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ResourceConfirmUploadResponse'] = ResolversParentTypes['ResourceConfirmUploadResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResourceLinkResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ResourceLink'] = ResolversParentTypes['ResourceLink']
> = {
  sys?: Resolver<ResolversTypes['ResourceSys'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResourceMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ResourceMutation'] = ResolversParentTypes['ResourceMutation']
> = {
  resourceConfirmUpload?: Resolver<
    ResolversTypes['ResourceConfirmUploadResponse'],
    ParentType,
    ContextType,
    RequireFields<ResourceMutationResourceConfirmUploadArgs, 'input'>
  >
  resourceRequestUploadURL?: Resolver<
    ResolversTypes['ResourceRequestUploadURLResponse'],
    ParentType,
    ContextType,
    RequireFields<ResourceMutationResourceRequestUploadUrlArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResourceRequestUploadUrlResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ResourceRequestUploadURLResponse'] = ResolversParentTypes['ResourceRequestUploadURLResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResourceSysResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ResourceSys'] = ResolversParentTypes['ResourceSys']
> = {
  linkType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  urn?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Response2FaResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Response2FA'] = ResolversParentTypes['Response2FA']
> = {
  token2FA?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldAttributesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldAttributes'] = ResolversParentTypes['ReyieldAttributes']
> = {
  traitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldBalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldBalance'] = ResolversParentTypes['ReyieldBalance']
> = {
  available?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldCefiDepositAddressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldCefiDepositAddress'] = ResolversParentTypes['ReyieldCefiDepositAddress']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldChain'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldCefiWalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldCefiWallet'] = ResolversParentTypes['ReyieldCefiWallet']
> = {
  exchange?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletBalances?: Resolver<Array<ResolversTypes['ReyieldWalletBalances']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldCoinPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldCoinPrice'] = ResolversParentTypes['ReyieldCoinPrice']
> = {
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  usdtPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldCreatorResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldCreator'] = ResolversParentTypes['ReyieldCreator']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldCurrencyBalancesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldCurrencyBalances'] = ResolversParentTypes['ReyieldCurrencyBalances']
> = {
  balance?: Resolver<ResolversTypes['ReyieldBalance'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldCurrencyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldCurrencyInfo'] = ResolversParentTypes['ReyieldCurrencyInfo']
> = {
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldDefiWalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldDefiWallet'] = ResolversParentTypes['ReyieldDefiWallet']
> = {
  balance?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balanceComputed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  balanceComputedWithUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  currencyInfo?: Resolver<ResolversTypes['ReyieldCurrencyInfo'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldExternalApiKeyResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldExternalAPIKey'] = ResolversParentTypes['ReyieldExternalAPIKey']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  exchange?: Resolver<ResolversTypes['ReyieldExchange'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isRevoked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['ReyieldUserInfo']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldFrontendVersionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldFrontendVersion'] = ResolversParentTypes['ReyieldFrontendVersion']
> = {
  commitHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  smartContracts?: Resolver<Array<ResolversTypes['ReyieldSmartContract']>, ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldGimCoolPayPaymentInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldGimCoolPayPaymentInfo'] = ResolversParentTypes['ReyieldGimCoolPayPaymentInfo']
> = {
  blockchain?: Resolver<
    ResolversTypes['ReyieldGimCoolPayPaymentSupportingBlockchain'],
    ParentType,
    ContextType
  >
  coinAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coinType?: Resolver<
    ResolversTypes['ReyieldGimCoolPayPaymentSupportingCoinType'],
    ParentType,
    ContextType
  >
  fiatAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fiatType?: Resolver<
    ResolversTypes['ReyieldGimCoolPayPaymentSupportingFiatType'],
    ParentType,
    ContextType
  >
  paymentHref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['ReyieldGimCoolPayPriceResponse']>, ParentType, ContextType>
  walletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletAddressTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldGimCoolPayPriceResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldGimCoolPayPriceResponse'] = ResolversParentTypes['ReyieldGimCoolPayPriceResponse']
> = {
  prices?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldGimCoolPayReadyToPurchasePrice']>>,
    ParentType,
    ContextType
  >
  spotPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldGimCoolPayReadyToPurchasePriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldGimCoolPayReadyToPurchasePrice'] = ResolversParentTypes['ReyieldGimCoolPayReadyToPurchasePrice']
> = {
  coinAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  coinCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fiatAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fiatCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  networkFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentMethodId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spotPriceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spotPriceIncludingFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldLicenseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldLicense'] = ResolversParentTypes['ReyieldLicense']
> = {
  numberOfLicense?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  numberOfUsedLicense?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldNftCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldNFTCollections'] = ResolversParentTypes['ReyieldNFTCollections']
> = {
  attributes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReyieldAttributes']>>>,
    ParentType,
    ContextType
  >
  contract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldNftMerkleTreeHexProofResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldNFTMerkleTreeHexProof'] = ResolversParentTypes['ReyieldNFTMerkleTreeHexProof']
> = {
  hexProof?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldNftWhitelistInfoByPhraseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldNFTWhitelistInfoByPhrase'] = ResolversParentTypes['ReyieldNFTWhitelistInfoByPhrase']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isWhitelisted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  phrase?: Resolver<ResolversTypes['ReyieldNFT_ROUND'], ParentType, ContextType>
  userAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldRateHistoricalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldRateHistorical'] = ResolversParentTypes['ReyieldRateHistorical']
> = {
  rate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldReyieldLicenseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldReyieldLicense'] = ResolversParentTypes['ReyieldReyieldLicense']
> = {
  numberOfLicense?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  numberOfUsedLicense?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldSmartContractResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldSmartContract'] = ResolversParentTypes['ReyieldSmartContract']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyInfo'] = ResolversParentTypes['ReyieldStrategyInfo']
> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchanges?: Resolver<Array<ResolversTypes['ReyieldExchange']>, ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numOfApply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  parameters?: Resolver<Array<ResolversTypes['ReyieldStrategyParameters']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['ReyieldRiskLevel'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stage?: Resolver<ResolversTypes['ReyieldStrategyInfoStage'], ParentType, ContextType>
  suggestionPeriod?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ReyieldStrategyType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyInfosResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyInfos'] = ResolversParentTypes['ReyieldStrategyInfos']
> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchange?: Resolver<Maybe<ResolversTypes['ReyieldExchange']>, ParentType, ContextType>
  minEnableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyParametersResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyParameters'] = ResolversParentTypes['ReyieldStrategyParameters']
> = {
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  minValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ReyieldStrategyParameterType'], ParentType, ContextType>
  updatable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyPerformance'] = ResolversParentTypes['ReyieldStrategyPerformance']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ror?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyProgressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyProgress'] = ResolversParentTypes['ReyieldStrategyProgress']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ReyieldStrategyProgressStatus'], ParentType, ContextType>
  steps?: Resolver<
    Array<Maybe<ResolversTypes['ReyieldStrategyProgressStep']>>,
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['ReyieldStrategyProgressType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyProgressStepResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyProgressStep'] = ResolversParentTypes['ReyieldStrategyProgressStep']
> = {
  status?: Resolver<ResolversTypes['ReyieldStrategyProgressStepStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ReyieldStrategyProgressStepType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyStartResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyStartResponse'] = ResolversParentTypes['ReyieldStrategyStartResponse']
> = {
  strategy?: Resolver<ResolversTypes['ReyieldUserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyStopResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyStopResponse'] = ResolversParentTypes['ReyieldStrategyStopResponse']
> = {
  strategy?: Resolver<ResolversTypes['ReyieldUserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldStrategyUpdateResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldStrategyUpdateResponse'] = ResolversParentTypes['ReyieldStrategyUpdateResponse']
> = {
  strategy?: Resolver<ResolversTypes['ReyieldUserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldTokenBalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldTokenBalance'] = ResolversParentTypes['ReyieldTokenBalance']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amountReadable?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldTokenDisplayInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldTokenDisplayInfo'] = ResolversParentTypes['ReyieldTokenDisplayInfo']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldToolInfo'] = ResolversParentTypes['ReyieldToolInfo']
> = {
  __resolveType: TypeResolveFn<
    'ReyieldStrategyInfo' | 'ReyieldUniswapToolInfo',
    ParentType,
    ContextType
  >
}

export type ReyieldTotalProfitsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldTotalProfits'] = ResolversParentTypes['ReyieldTotalProfits']
> = {
  amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapOnchainPositionAmountInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapOnchainPositionAmountInfo'] = ResolversParentTypes['ReyieldUniswapOnchainPositionAmountInfo']
> = {
  token0?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token0Amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0AmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token1Amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1AmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapPoolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapPoolInfo'] = ResolversParentTypes['ReyieldUniswapPoolInfo']
> = {
  averageTradingVolumeUSD?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldUniswapPoolInfoAverageTradingVolumeUsdArgs, 'numberOfDays'>
  >
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  collectedFeesToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtBlockNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthGlobal0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthGlobal1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPoolActiveForReyield?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  liquidity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  liquidityProviderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  observationIndex?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positions?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUniswapPoolPosition']>>,
    ParentType,
    ContextType
  >
  sqrtPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ticks?: Resolver<Maybe<Array<ResolversTypes['ReyieldUniswapPoolTick']>>, ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token0Price?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token1Price?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedETH?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSDUntracked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  untrackedVolumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapPoolPositionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapPoolPosition'] = ResolversParentTypes['ReyieldUniswapPoolPosition']
> = {
  collectedFeesToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  depositedToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  depositedToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthInside0LastX128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthInside1LastX128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  liquidity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerFeeGrowthOutside0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerFeeGrowthOutside1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerTickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperFeeGrowthOutside0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperFeeGrowthOutside1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperTickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapPoolTickResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapPoolTick'] = ResolversParentTypes['ReyieldUniswapPoolTick']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  liquidityNet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapQuoteResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapQuote'] = ResolversParentTypes['ReyieldUniswapQuote']
> = {
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  fromToken?: Resolver<ResolversTypes['ReyieldUniswapToolToken'], ParentType, ContextType>
  fromTokenAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  inputAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  inputReadableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outputAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outputReadableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toToken?: Resolver<ResolversTypes['ReyieldUniswapToolToken'], ParentType, ContextType>
  toTokenAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapToolFeeTierResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapToolFeeTier'] = ResolversParentTypes['ReyieldUniswapToolFeeTier']
> = {
  feeForContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeForDisplay?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapToolInfo'] = ResolversParentTypes['ReyieldUniswapToolInfo']
> = {
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creator?: Resolver<Maybe<ResolversTypes['ReyieldCreator']>, ParentType, ContextType>
  creatorAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['ReyieldUniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numOfApply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  performanceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolInfo?: Resolver<Maybe<ResolversTypes['ReyieldUniswapPoolInfo']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['ReyieldRiskLevel'], ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['ReyieldUniswapToolTag']>>, ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerDiffForContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiffForContract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapToolTagResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapToolTag'] = ResolversParentTypes['ReyieldUniswapToolTag']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ReyieldUniswapToolTagType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapToolTokenResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapToolToken'] = ResolversParentTypes['ReyieldUniswapToolToken']
> = {
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  derivedETH?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  priceChart?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapToolTokenPriceChart']>,
    ParentType,
    ContextType
  >
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalSupply?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLocked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSDUntracked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  untrackedVolumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volume?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapToolTokenPriceChartResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapToolTokenPriceChart'] = ResolversParentTypes['ReyieldUniswapToolTokenPriceChart']
> = {
  currentPriceUSD?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  prices?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUniswapToolTokenPriceChartPrice']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapToolTokenPriceChartPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapToolTokenPriceChartPrice'] = ResolversParentTypes['ReyieldUniswapToolTokenPriceChartPrice']
> = {
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUniswapV3BacktestResultResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUniswapV3BacktestResult'] = ResolversParentTypes['ReyieldUniswapV3BacktestResult']
> = {
  activeLiquidityPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  annualReturn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  compound?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dailyReturn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  dataPointsCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  days?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  diff?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  fees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  impermanentLoss?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  investmentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  maxDrawdown?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  period?: Resolver<ResolversTypes['ReyieldUniswapV3BacktestPeriod'], ParentType, ContextType>
  poolID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  protocol?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  totalReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  volatility?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserInfo'] = ResolversParentTypes['ReyieldUserInfo']
> = {
  aICompletionRecords?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldaICompletionRecords']>>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoAiCompletionRecordsArgs>
  >
  cefiDepositAddress?: Resolver<
    Maybe<ResolversTypes['ReyieldCefiDepositAddress']>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoCefiDepositAddressArgs>
  >
  cefiWallets?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldCefiWallet']>>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoCefiWalletsArgs>
  >
  defiTokensBalanceAmount?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReyieldTokenBalance']>>>,
    ParentType,
    ContextType,
    RequireFields<ReyieldUserInfoDefiTokensBalanceAmountArgs, 'input'>
  >
  defiWallets?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldDefiWallet']>>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoDefiWalletsArgs>
  >
  externalAPIKeys?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldExternalAPIKey']>>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoExternalApiKeysArgs>
  >
  gimCoolPayPaymentInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldGimCoolPayPaymentInfo']>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoGimCoolPayPaymentInfoArgs>
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nftCollections?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldNFTCollections']>>,
    ParentType,
    ContextType
  >
  nftMerkleTreeHexProof?: Resolver<
    Maybe<ResolversTypes['ReyieldNFTMerkleTreeHexProof']>,
    ParentType,
    ContextType
  >
  nftWhitelistInfoByPhrases?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldNFTWhitelistInfoByPhrase']>>,
    ParentType,
    ContextType
  >
  ownedToolsSetting?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUserUniswapToolSettingInfo']>>,
    ParentType,
    ContextType
  >
  reyieldLicense?: Resolver<Maybe<ResolversTypes['ReyieldReyieldLicense']>, ParentType, ContextType>
  strategies?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUserStrategyInfo']>>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoStrategiesArgs>
  >
  strategyPerformance?: Resolver<
    Maybe<ResolversTypes['ReyieldUserStrategyPerformance']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldUserInfoStrategyPerformanceArgs, 'strategyID'>
  >
  strategyProfit?: Resolver<
    Maybe<ResolversTypes['ReyieldUserStrategyProfit']>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoStrategyProfitArgs>
  >
  strategyProgress?: Resolver<
    Maybe<ResolversTypes['ReyieldStrategyProgress']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldUserInfoStrategyProgressArgs, 'strategyID'>
  >
  strategyRecords?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUserStrategyRecord']>>,
    ParentType,
    ContextType,
    Partial<ReyieldUserInfoStrategyRecordsArgs>
  >
  tools?: Resolver<Maybe<Array<ResolversTypes['ReyieldUserToolInfo']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserStrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserStrategyInfo'] = ResolversParentTypes['ReyieldUserStrategyInfo']
> = {
  ceFiStatus?: Resolver<ResolversTypes['ReyieldStrategyStatus'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  errMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  infos?: Resolver<Maybe<ResolversTypes['ReyieldStrategyInfos']>, ParentType, ContextType>
  parameters?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUserStrategyParameters']>>,
    ParentType,
    ContextType
  >
  performance?: Resolver<
    Maybe<ResolversTypes['ReyieldUserStrategyPerformance']>,
    ParentType,
    ContextType
  >
  profits?: Resolver<Maybe<ResolversTypes['ReyieldUserStrategyProfit']>, ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserStrategyParametersResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserStrategyParameters'] = ResolversParentTypes['ReyieldUserStrategyParameters']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserStrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserStrategyPerformance'] = ResolversParentTypes['ReyieldUserStrategyPerformance']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  initialValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  performances?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReyieldStrategyPerformance']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserStrategyProfitResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserStrategyProfit'] = ResolversParentTypes['ReyieldUserStrategyProfit']
> = {
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalProfit?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReyieldTotalProfits']>>>,
    ParentType,
    ContextType
  >
  type?: Resolver<Maybe<ResolversTypes['ReyieldStrategyType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserStrategyRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserStrategyRecord'] = ResolversParentTypes['ReyieldUserStrategyRecord']
> = {
  amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ReyieldRecordsType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserToolInfo'] = ResolversParentTypes['ReyieldUserToolInfo']
> = {
  __resolveType: TypeResolveFn<
    'ReyieldUserStrategyInfo' | 'ReyieldUserUniswapPositionInfo',
    ParentType,
    ContextType
  >
}

export type ReyieldUserUniswapOnchainPositionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserUniswapOnchainPositionInfo'] = ResolversParentTypes['ReyieldUserUniswapOnchainPositionInfo']
> = {
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  closedPositionAmount?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  positionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionIdFromSmartContractPositionManager?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  roi?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  runningPositionAmount?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  totalInvestAmountUsdValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserUniswapPositionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserUniswapPositionInfo'] = ResolversParentTypes['ReyieldUserUniswapPositionInfo']
> = {
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  closedPositionAmount?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['ReyieldUniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onchainPositionInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldUserUniswapOnchainPositionInfo']>,
    ParentType,
    ContextType,
    Partial<ReyieldUserUniswapPositionInfoOnchainPositionInfoArgs>
  >
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionIdFromSmartContractPositionManager?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  progresses?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUserUniswapv3PositionProgress']>>,
    ParentType,
    ContextType
  >
  publicToolInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapToolInfo']>,
    ParentType,
    ContextType
  >
  roi?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  runningPositionAmount?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['ReyieldUniswapToolPositionStatus'], ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  token1?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  toolDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolIsOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  toolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolPerformanceFeeRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toolSetting?: Resolver<
    Maybe<ResolversTypes['ReyieldUserUniswapToolSettingInfo']>,
    ParentType,
    ContextType
  >
  totalInvestAmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserUniswapToolOutOfRangeInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserUniswapToolOutOfRangeInfo'] = ResolversParentTypes['ReyieldUserUniswapToolOutOfRangeInfo']
> = {
  earnSpendRate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxRebalanceTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  standbyInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  standbyMaxInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserUniswapToolSettingInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserUniswapToolSettingInfo'] = ResolversParentTypes['ReyieldUserUniswapToolSettingInfo']
> = {
  chain?: Resolver<ResolversTypes['ReyieldEvmChain'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['ReyieldUniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outOfLowerRange?: Resolver<
    ResolversTypes['ReyieldUserUniswapToolOutOfRangeInfo'],
    ParentType,
    ContextType
  >
  outOfUpperRange?: Resolver<
    ResolversTypes['ReyieldUserUniswapToolOutOfRangeInfo'],
    ParentType,
    ContextType
  >
  performanceFeeRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  position?: Resolver<
    Maybe<ResolversTypes['ReyieldUserUniswapPositionInfo']>,
    ParentType,
    ContextType
  >
  publicToolInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapToolInfo']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['ReyieldUniswapToolStatus'], ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  token1?: Resolver<Maybe<ResolversTypes['ReyieldUniswapToolToken']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<
    Maybe<ResolversTypes['ReyieldTokenDisplayInfo']>,
    ParentType,
    ContextType
  >
  totalUserUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserUniswapv3PositionProgressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserUniswapv3PositionProgress'] = ResolversParentTypes['ReyieldUserUniswapv3PositionProgress']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['ReyieldUserUniswapv3PositionProgressStatus'],
    ParentType,
    ContextType
  >
  steps?: Resolver<
    Array<ResolversTypes['ReyieldUserUniswapv3PositionProgressStep']>,
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['ReyieldUserUniswapv3PositionProgressType'],
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldUserUniswapv3PositionProgressStepResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldUserUniswapv3PositionProgressStep'] = ResolversParentTypes['ReyieldUserUniswapv3PositionProgressStep']
> = {
  status?: Resolver<
    ResolversTypes['ReyieldUserUniswapv3PositionProgressStepStatus'],
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['ReyieldUserUniswapv3PositionProgressStepType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldWalletBalancesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldWalletBalances'] = ResolversParentTypes['ReyieldWalletBalances']
> = {
  currencyBalances?: Resolver<
    Array<ResolversTypes['ReyieldCurrencyBalances']>,
    ParentType,
    ContextType
  >
  wallet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldaICompletionRecordsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldaICompletionRecords'] = ResolversParentTypes['ReyieldaICompletionRecords']
> = {
  completedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  meta?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<ReyieldaICompletionRecordsMetaArgs>
  >
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  promptMutationInput?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<ReyieldaICompletionRecordsPromptMutationInputArgs>
  >
  promptMutationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  suggestions?: Resolver<
    Array<ResolversTypes['ReyieldsuggestStrategyToolWithAllocations']>,
    ParentType,
    ContextType,
    Partial<ReyieldaICompletionRecordsSuggestionsArgs>
  >
  suggestions_aggregate?: Resolver<
    ResolversTypes['ReyieldsuggestStrategyToolWithAllocations_aggregate'],
    ParentType,
    ContextType,
    Partial<ReyieldaICompletionRecordsSuggestions_AggregateArgs>
  >
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  userAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldsuggestStrategyToolWithAllocationsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations'] = ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations']
> = {
  aICompletionRecordId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  allocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  highestAnnualReturn?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldsuggestStrategyToolWithAllocations_AggregateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_aggregate'] = ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ReyieldsuggestStrategyToolWithAllocations_aggregate_fields']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['ReyieldsuggestStrategyToolWithAllocations']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldsuggestStrategyToolWithAllocations_Aggregate_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_aggregate_fields'] = ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_aggregate_fields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<ReyieldsuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs>
  >
  max?: Resolver<
    Maybe<ResolversTypes['ReyieldsuggestStrategyToolWithAllocations_max_fields']>,
    ParentType,
    ContextType
  >
  min?: Resolver<
    Maybe<ResolversTypes['ReyieldsuggestStrategyToolWithAllocations_min_fields']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldsuggestStrategyToolWithAllocations_Max_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_max_fields'] = ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_max_fields']
> = {
  aICompletionRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highestAnnualReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>
  maximumLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldsuggestStrategyToolWithAllocations_Min_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_min_fields'] = ResolversParentTypes['ReyieldsuggestStrategyToolWithAllocations_min_fields']
> = {
  aICompletionRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highestAnnualReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>
  maximumLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['SectionLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<SectionLinkedFromArgs>
  >
  sectionContentsCollection?: Resolver<
    Maybe<ResolversTypes['SectionSectionContentsCollection']>,
    ParentType,
    ContextType,
    RequireFields<SectionSectionContentsCollectionArgs, 'limit' | 'skip'>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<SectionTitleArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionCollection'] = ResolversParentTypes['SectionCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Section']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContent'] = ResolversParentTypes['SectionContent']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['SectionContentDescription']>,
    ParentType,
    ContextType,
    Partial<SectionContentDescriptionArgs>
  >
  externalLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<SectionContentExternalLinkArgs>
  >
  externalLinkDisplayText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<SectionContentExternalLinkDisplayTextArgs>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['SectionContentLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<SectionContentLinkedFromArgs>
  >
  subTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<SectionContentSubTitleArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  title?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<SectionContentTitleArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentCollection'] = ResolversParentTypes['SectionContentCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['SectionContent']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentDescriptionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentDescription'] = ResolversParentTypes['SectionContentDescription']
> = {
  json?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  links?: Resolver<ResolversTypes['SectionContentDescriptionLinks'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentDescriptionAssetsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentDescriptionAssets'] = ResolversParentTypes['SectionContentDescriptionAssets']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Asset']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentDescriptionEntriesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentDescriptionEntries'] = ResolversParentTypes['SectionContentDescriptionEntries']
> = {
  block?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  hyperlink?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  inline?: Resolver<Array<Maybe<ResolversTypes['Entry']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentDescriptionLinksResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentDescriptionLinks'] = ResolversParentTypes['SectionContentDescriptionLinks']
> = {
  assets?: Resolver<ResolversTypes['SectionContentDescriptionAssets'], ParentType, ContextType>
  entries?: Resolver<ResolversTypes['SectionContentDescriptionEntries'], ParentType, ContextType>
  resources?: Resolver<
    ResolversTypes['SectionContentDescriptionResources'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentDescriptionResourcesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentDescriptionResources'] = ResolversParentTypes['SectionContentDescriptionResources']
> = {
  block?: Resolver<Array<ResolversTypes['ResourceLink']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionContentLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionContentLinkingCollections'] = ResolversParentTypes['SectionContentLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<SectionContentLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  sectionCollection?: Resolver<
    Maybe<ResolversTypes['SectionCollection']>,
    ParentType,
    ContextType,
    RequireFields<SectionContentLinkingCollectionsSectionCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionLinkingCollections'] = ResolversParentTypes['SectionLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<SectionLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  strategyMetaCollection?: Resolver<
    Maybe<ResolversTypes['StrategyMetaCollection']>,
    ParentType,
    ContextType,
    RequireFields<SectionLinkingCollectionsStrategyMetaCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionSectionContentsCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SectionSectionContentsCollection'] = ResolversParentTypes['SectionSectionContentsCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['SectionContent']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SmartContractResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SmartContract'] = ResolversParentTypes['SmartContract']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SpecialUserResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SpecialUser'] = ResolversParentTypes['SpecialUser']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  email?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<SpecialUserEmailArgs>
  >
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['SpecialUserLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<SpecialUserLinkedFromArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SpecialUserCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SpecialUserCollection'] = ResolversParentTypes['SpecialUserCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['SpecialUser']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SpecialUserLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['SpecialUserLinkingCollections'] = ResolversParentTypes['SpecialUserLinkingCollections']
> = {
  displayItemCollection?: Resolver<
    Maybe<ResolversTypes['DisplayItemCollection']>,
    ParentType,
    ContextType,
    RequireFields<SpecialUserLinkingCollectionsDisplayItemCollectionArgs, 'limit' | 'skip'>
  >
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<SpecialUserLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyInfo'] = ResolversParentTypes['StrategyInfo']
> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchanges?: Resolver<Array<ResolversTypes['Exchange']>, ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numOfApply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  parameters?: Resolver<Array<ResolversTypes['StrategyParameters']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['RiskLevel'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stage?: Resolver<ResolversTypes['StrategyInfoStage'], ParentType, ContextType>
  suggestionPeriod?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyInfosResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyInfos'] = ResolversParentTypes['StrategyInfos']
> = {
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchange?: Resolver<Maybe<ResolversTypes['Exchange']>, ParentType, ContextType>
  minEnableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyMetaResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyMeta'] = ResolversParentTypes['StrategyMeta']
> = {
  contentfulMetadata?: Resolver<ResolversTypes['ContentfulMetadata'], ParentType, ContextType>
  linkedFrom?: Resolver<
    Maybe<ResolversTypes['StrategyMetaLinkingCollections']>,
    ParentType,
    ContextType,
    Partial<StrategyMetaLinkedFromArgs>
  >
  sectionsCollection?: Resolver<
    Maybe<ResolversTypes['StrategyMetaSectionsCollection']>,
    ParentType,
    ContextType,
    RequireFields<StrategyMetaSectionsCollectionArgs, 'limit' | 'skip'>
  >
  sid?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<StrategyMetaSidArgs>
  >
  sys?: Resolver<ResolversTypes['Sys'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyMetaCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyMetaCollection'] = ResolversParentTypes['StrategyMetaCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['StrategyMeta']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyMetaLinkingCollectionsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyMetaLinkingCollections'] = ResolversParentTypes['StrategyMetaLinkingCollections']
> = {
  entryCollection?: Resolver<
    Maybe<ResolversTypes['EntryCollection']>,
    ParentType,
    ContextType,
    RequireFields<StrategyMetaLinkingCollectionsEntryCollectionArgs, 'limit' | 'skip'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyMetaSectionsCollectionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyMetaSectionsCollection'] = ResolversParentTypes['StrategyMetaSectionsCollection']
> = {
  items?: Resolver<Array<Maybe<ResolversTypes['Section']>>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  skip?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyParametersResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyParameters'] = ResolversParentTypes['StrategyParameters']
> = {
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  minValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyParameterType'], ParentType, ContextType>
  updatable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyPerformance'] = ResolversParentTypes['StrategyPerformance']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ror?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyProgressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyProgress'] = ResolversParentTypes['StrategyProgress']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['StrategyProgressStatus'], ParentType, ContextType>
  steps?: Resolver<Array<Maybe<ResolversTypes['StrategyProgressStep']>>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyProgressType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyProgressStepResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyProgressStep'] = ResolversParentTypes['StrategyProgressStep']
> = {
  status?: Resolver<ResolversTypes['StrategyProgressStepStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyProgressStepType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyStartResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyStartResponse'] = ResolversParentTypes['StrategyStartResponse']
> = {
  strategy?: Resolver<ResolversTypes['UserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyStopResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyStopResponse'] = ResolversParentTypes['StrategyStopResponse']
> = {
  strategy?: Resolver<ResolversTypes['UserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyUpdateResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['StrategyUpdateResponse'] = ResolversParentTypes['StrategyUpdateResponse']
> = {
  strategy?: Resolver<ResolversTypes['UserStrategyInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Action'] = ResolversParentTypes['Strategy_Action']
> = {
  __resolveType: TypeResolveFn<
    | 'Strategy_ActionDEFIApproveSwapReward'
    | 'Strategy_ActionDEFIDeductTxnFee'
    | 'Strategy_ActionDEFIDispatchUSDTReward'
    | 'Strategy_ActionDEFIMIM3CRVAddLiquidity'
    | 'Strategy_ActionDEFIMIM3CRVApproveConvexBooster'
    | 'Strategy_ActionDEFIMIM3CRVApproveCurve3Pool'
    | 'Strategy_ActionDEFIMIM3CRVClaimReward'
    | 'Strategy_ActionDEFIMIM3CRVDepositAll'
    | 'Strategy_ActionDEFIMIM3CRVRemoveLiquidity'
    | 'Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap'
    | 'Strategy_ActionDEFIMIMUSDTApproveCurve3Pool'
    | 'Strategy_ActionDEFIRebalanceTxnFeeEstimation'
    | 'Strategy_ActionDEFIStakePool'
    | 'Strategy_ActionDEFISwapETHToUSDT'
    | 'Strategy_ActionDEFISwapReward'
    | 'Strategy_ActionDEFITransferUserLPBalanceToPool'
    | 'Strategy_ActionDEFIUnStakePool'
    | 'Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity'
    | 'Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward'
    | 'Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity'
    | 'Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT'
    | 'Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward'
    | 'Strategy_ActionDEFIUpdateUserRecordStatus'
    | 'Strategy_ActionExternalDepositCheck'
    | 'Strategy_ActionExternalSwap'
    | 'Strategy_ActionExternalWalletTransfer'
    | 'Strategy_ActionExternalWithdrawal'
    | 'Strategy_ActionInternalDepositCheck'
    | 'Strategy_ActionInternalTrade'
    | 'Strategy_ActionInternalWithdrawal'
    | 'Strategy_ActionStrategyBatchStart'
    | 'Strategy_ActionStrategyBatchStop'
    | 'Strategy_ActionStrategyBatchUpdate'
    | 'Strategy_ActionStrategyStart'
    | 'Strategy_ActionStrategyStop'
    | 'Strategy_ActionStrategyUpdate'
    | 'Strategy_ActionTransferFromDEFIWalletPostProcessing'
    | 'Strategy_ActionTransferFromDEFIWalletPreProcessing'
    | 'Strategy_ActionTransferFromDEFIWalletProcessing'
    | 'Strategy_ActionTransferToDEFIWalletPostProcessing'
    | 'Strategy_ActionTransferToDEFIWalletPreProcessing'
    | 'Strategy_ActionTransferToDEFIWalletProcessing'
    | 'Strategy_ActionWatchTxnStatus',
    ParentType,
    ContextType
  >
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
}

export type Strategy_ActionDefiApproveSwapRewardResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIApproveSwapReward'] = ResolversParentTypes['Strategy_ActionDEFIApproveSwapReward']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiDeductTxnFeeResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIDeductTxnFee'] = ResolversParentTypes['Strategy_ActionDEFIDeductTxnFee']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiDispatchUsdtRewardResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIDispatchUSDTReward'] = ResolversParentTypes['Strategy_ActionDEFIDispatchUSDTReward']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvAddLiquidityResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVAddLiquidity'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVAddLiquidity']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvApproveConvexBoosterResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVApproveConvexBooster'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVApproveConvexBooster']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvApproveCurve3PoolResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVApproveCurve3Pool'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVApproveCurve3Pool']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvClaimRewardResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVClaimReward'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVClaimReward']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvDepositAllResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVDepositAll'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVDepositAll']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvRemoveLiquidityResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVRemoveLiquidity'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVRemoveLiquidity']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimim3CrvWithdrawAndUnwrapResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap'] = ResolversParentTypes['Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefimimusdtApproveCurve3PoolResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIMIMUSDTApproveCurve3Pool'] = ResolversParentTypes['Strategy_ActionDEFIMIMUSDTApproveCurve3Pool']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiRebalanceTxnFeeEstimationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIRebalanceTxnFeeEstimation'] = ResolversParentTypes['Strategy_ActionDEFIRebalanceTxnFeeEstimation']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiStakePoolResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIStakePool'] = ResolversParentTypes['Strategy_ActionDEFIStakePool']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiSwapEthToUsdtResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFISwapETHToUSDT'] = ResolversParentTypes['Strategy_ActionDEFISwapETHToUSDT']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiSwapRewardResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFISwapReward'] = ResolversParentTypes['Strategy_ActionDEFISwapReward']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiTransferUserLpBalanceToPoolResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFITransferUserLPBalanceToPool'] = ResolversParentTypes['Strategy_ActionDEFITransferUserLPBalanceToPool']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUnStakePoolResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUnStakePool'] = ResolversParentTypes['Strategy_ActionDEFIUnStakePool']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterAddLiquidityResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity'] = ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterClaimRewardResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward'] = ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterRmLiquidityResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity'] = ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterSwapEthToUsdtResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT'] = ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterSwapRewardResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward'] = ResolversParentTypes['Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionDefiUpdateUserRecordStatusResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionDEFIUpdateUserRecordStatus'] = ResolversParentTypes['Strategy_ActionDEFIUpdateUserRecordStatus']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionExternalDepositCheckResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionExternalDepositCheck'] = ResolversParentTypes['Strategy_ActionExternalDepositCheck']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionExternalSwapResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionExternalSwap'] = ResolversParentTypes['Strategy_ActionExternalSwap']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionExternalWalletTransferResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionExternalWalletTransfer'] = ResolversParentTypes['Strategy_ActionExternalWalletTransfer']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionExternalWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionExternalWithdrawal'] = ResolversParentTypes['Strategy_ActionExternalWithdrawal']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionInternalDepositCheckResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionInternalDepositCheck'] = ResolversParentTypes['Strategy_ActionInternalDepositCheck']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionInternalTradeResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionInternalTrade'] = ResolversParentTypes['Strategy_ActionInternalTrade']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionInternalWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionInternalWithdrawal'] = ResolversParentTypes['Strategy_ActionInternalWithdrawal']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionLogResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionLog'] = ResolversParentTypes['Strategy_ActionLog']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionStrategyBatchStartResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionStrategyBatchStart'] = ResolversParentTypes['Strategy_ActionStrategyBatchStart']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionStrategyBatchStopResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionStrategyBatchStop'] = ResolversParentTypes['Strategy_ActionStrategyBatchStop']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionStrategyBatchUpdateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionStrategyBatchUpdate'] = ResolversParentTypes['Strategy_ActionStrategyBatchUpdate']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionStrategyStartResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionStrategyStart'] = ResolversParentTypes['Strategy_ActionStrategyStart']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionStrategyStopResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionStrategyStop'] = ResolversParentTypes['Strategy_ActionStrategyStop']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionStrategyUpdateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionStrategyUpdate'] = ResolversParentTypes['Strategy_ActionStrategyUpdate']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionTransferFromDefiWalletPostProcessingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletPostProcessing'] = ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletPostProcessing']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionTransferFromDefiWalletPreProcessingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletPreProcessing'] = ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletPreProcessing']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionTransferFromDefiWalletProcessingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletProcessing'] = ResolversParentTypes['Strategy_ActionTransferFromDEFIWalletProcessing']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionTransferToDefiWalletPostProcessingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionTransferToDEFIWalletPostProcessing'] = ResolversParentTypes['Strategy_ActionTransferToDEFIWalletPostProcessing']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionTransferToDefiWalletPreProcessingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionTransferToDEFIWalletPreProcessing'] = ResolversParentTypes['Strategy_ActionTransferToDEFIWalletPreProcessing']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionTransferToDefiWalletProcessingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionTransferToDEFIWalletProcessing'] = ResolversParentTypes['Strategy_ActionTransferToDEFIWalletProcessing']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ActionWatchTxnStatusResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ActionWatchTxnStatus'] = ResolversParentTypes['Strategy_ActionWatchTxnStatus']
> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowActionStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_AdminResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Admin'] = ResolversParentTypes['Strategy_Admin']
> = {
  externalAPIKeys?: Resolver<
    Array<ResolversTypes['Strategy_ExternalAPIKey']>,
    ParentType,
    ContextType,
    Partial<Strategy_AdminExternalApiKeysArgs>
  >
  workflows?: Resolver<
    Array<ResolversTypes['Strategy_Workflow']>,
    ParentType,
    ContextType,
    Partial<Strategy_AdminWorkflowsArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_AdminMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_AdminMutation'] = ResolversParentTypes['Strategy_AdminMutation']
> = {
  defiNormalFlow?: Resolver<
    ResolversTypes['Strategy_Workflow'],
    ParentType,
    ContextType,
    RequireFields<Strategy_AdminMutationDefiNormalFlowArgs, 'input'>
  >
  defiRebalanceFlow?: Resolver<
    ResolversTypes['Strategy_Workflow'],
    ParentType,
    ContextType,
    RequireFields<Strategy_AdminMutationDefiRebalanceFlowArgs, 'input'>
  >
  defiWithdrawExpressFlow?: Resolver<
    ResolversTypes['Strategy_Workflow'],
    ParentType,
    ContextType,
    RequireFields<Strategy_AdminMutationDefiWithdrawExpressFlowArgs, 'input'>
  >
  workflowRetry?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<Strategy_AdminMutationWorkflowRetryArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ArbitrageStateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ArbitrageState'] = ResolversParentTypes['Strategy_ArbitrageState']
> = {
  executedAmount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  totalAmount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_BalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Balance'] = ResolversParentTypes['Strategy_Balance']
> = {
  availTWDValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  availUSDValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  available?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  totalTWDValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  totalUSDValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  walletType?: Resolver<ResolversTypes['ExternalWallet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_CurrencyAmountResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_CurrencyAmount'] = ResolversParentTypes['Strategy_CurrencyAmount']
> = {
  amount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_DailyProfitResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_DailyProfit'] = ResolversParentTypes['Strategy_DailyProfit']
> = {
  amount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_DebtRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_DebtRecord'] = ResolversParentTypes['Strategy_DebtRecord']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_EstimatePointsConsumingResultResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_EstimatePointsConsumingResult'] = ResolversParentTypes['Strategy_EstimatePointsConsumingResult']
> = {
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_EstimateStrategyRequiredAmountResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_EstimateStrategyRequiredAmountResponse'] = ResolversParentTypes['Strategy_EstimateStrategyRequiredAmountResponse']
> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  lackingAmount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExchangeAssetResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExchangeAsset'] = ResolversParentTypes['Strategy_ExchangeAsset']
> = {
  balances?: Resolver<Array<ResolversTypes['Strategy_Balance']>, ParentType, ContextType>
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  netAvailBalanceInTWD?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  netAvailBalanceInUSD?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  netBalanceInTWD?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  netBalanceInUSD?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExchangeWithdrawalAuthorizationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExchangeWithdrawalAuthorizationResponse'] = ResolversParentTypes['Strategy_ExchangeWithdrawalAuthorizationResponse']
> = {
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  is2FARequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isWithdrawalPasswordRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExchangesNetAssetChartResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExchangesNetAssetChart'] = ResolversParentTypes['Strategy_ExchangesNetAssetChart']
> = {
  data?: Resolver<
    Array<ResolversTypes['Strategy_ExchangesNetAssetChartData']>,
    ParentType,
    ContextType
  >
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExchangesNetAssetChartDataResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExchangesNetAssetChartData'] = ResolversParentTypes['Strategy_ExchangesNetAssetChartData']
> = {
  netAssetTWDValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  netAssetUSDValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExternalApiKeyResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExternalAPIKey'] = ResolversParentTypes['Strategy_ExternalAPIKey']
> = {
  createdAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isRevoked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subaccount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExternalApiKeyMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExternalAPIKeyMutation'] = ResolversParentTypes['Strategy_ExternalAPIKeyMutation']
> = {
  externalAPIKeyCreate?: Resolver<
    ResolversTypes['Strategy_ExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeyCreateArgs, 'input'>
  >
  externalAPIKeyDelete?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeyDeleteArgs, 'input'>
  >
  externalAPIKeyDeposit?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeyDepositArgs, 'input'>
  >
  externalAPIKeySwap?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeySwapArgs, 'input'>
  >
  externalAPIKeyTransferBetweenWallets?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeyTransferBetweenWalletsArgs, 'input'>
  >
  externalAPIKeyWithdrawal?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeyWithdrawalArgs, 'input'>
  >
  externalAPIKeyWithdrawalConfirm?: Resolver<
    ResolversTypes['Strategy_ExternalAPIKeyWithdrawal'],
    ParentType,
    ContextType,
    RequireFields<Strategy_ExternalApiKeyMutationExternalApiKeyWithdrawalConfirmArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_ExternalApiKeyWithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_ExternalAPIKeyWithdrawal'] = ResolversParentTypes['Strategy_ExternalAPIKeyWithdrawal']
> = {
  amount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  exchange?: Resolver<ResolversTypes['Exchange'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  withdrawalID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_GenericFlowMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_GenericFlowMutation'] = ResolversParentTypes['Strategy_GenericFlowMutation']
> = {
  withdrawalCryptoStart?: Resolver<
    ResolversTypes['Strategy_Workflow'],
    ParentType,
    ContextType,
    RequireFields<Strategy_GenericFlowMutationWithdrawalCryptoStartArgs, 'input'>
  >
  withdrawalFiatStart?: Resolver<
    ResolversTypes['Strategy_Workflow'],
    ParentType,
    ContextType,
    RequireFields<Strategy_GenericFlowMutationWithdrawalFiatStartArgs, 'input'>
  >
  withdrawalRetry?: Resolver<
    ResolversTypes['Strategy_Workflow'],
    ParentType,
    ContextType,
    RequireFields<Strategy_GenericFlowMutationWithdrawalRetryArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_LendingOfferResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_LendingOffer'] = ResolversParentTypes['Strategy_LendingOffer']
> = {
  amount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  period?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  positionPair?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_LendingStateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_LendingState'] = ResolversParentTypes['Strategy_LendingState']
> = {
  activeOffers?: Resolver<Array<ResolversTypes['Strategy_LendingOffer']>, ParentType, ContextType>
  openOffers?: Resolver<Array<ResolversTypes['Strategy_LendingOffer']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface Strategy_NumberScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Strategy_Number'], any> {
  name: 'Strategy_Number'
}

export type Strategy_PerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Performance'] = ResolversParentTypes['Strategy_Performance']
> = {
  amount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  apy?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ror?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_PublicResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Public'] = ResolversParentTypes['Strategy_Public']
> = {
  strategies?: Resolver<
    Array<ResolversTypes['Strategy_StrategyInfo']>,
    ParentType,
    ContextType,
    Partial<Strategy_PublicStrategiesArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Strategy'] = ResolversParentTypes['Strategy_Strategy']
> = {
  closedAt?: Resolver<Maybe<ResolversTypes['Strategy_Time']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  debt?: Resolver<ResolversTypes['Strategy_StrategyDebt'], ParentType, ContextType>
  displayInfo?: Resolver<
    Maybe<ResolversTypes['StrategyMetaCollection']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyDisplayInfoArgs, 'limit' | 'skip'>
  >
  errMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  info?: Resolver<ResolversTypes['Strategy_StrategyInfo'], ParentType, ContextType>
  parameters?: Resolver<
    Array<ResolversTypes['Strategy_StrategyParameter']>,
    ParentType,
    ContextType
  >
  performance?: Resolver<ResolversTypes['Strategy_StrategyPerformance'], ParentType, ContextType>
  principal?: Resolver<ResolversTypes['Strategy_CurrencyAmount'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['Strategy_StrategyState']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['StrategyStatus'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  workflows?: Resolver<Array<ResolversTypes['Strategy_Workflow']>, ParentType, ContextType>
  yieldRecords?: Resolver<
    Array<Maybe<ResolversTypes['Strategy_YieldRecord']>>,
    ParentType,
    ContextType,
    Partial<Strategy_StrategyYieldRecordsArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyDebtResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyDebt'] = ResolversParentTypes['Strategy_StrategyDebt']
> = {
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  records?: Resolver<Array<Maybe<ResolversTypes['Strategy_DebtRecord']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyDescriptionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyDescription'] = ResolversParentTypes['Strategy_StrategyDescription']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyInfo'] = ResolversParentTypes['Strategy_StrategyInfo']
> = {
  closeGasFee?: Resolver<ResolversTypes['Strategy_CurrencyAmount'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  descriptionDetail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  descriptionList?: Resolver<
    Array<ResolversTypes['Strategy_StrategyDescription']>,
    ParentType,
    ContextType
  >
  displayInfo?: Resolver<
    Maybe<ResolversTypes['StrategyMetaCollection']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyInfoDisplayInfoArgs, 'limit' | 'skip'>
  >
  earningCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  exchanges?: Resolver<Array<ResolversTypes['Exchange']>, ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feePerHourAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numberOfApplication?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  openGasFee?: Resolver<ResolversTypes['Strategy_CurrencyAmount'], ParentType, ContextType>
  parameterDescriptions?: Resolver<
    Array<ResolversTypes['Strategy_StrategyParameterDesc']>,
    ParentType,
    ContextType
  >
  pointPerHourAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  profits?: Resolver<Array<ResolversTypes['Strategy_CurrencyAmount']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['RiskLevel'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  suggestionPeriod?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyMutation'] = ResolversParentTypes['Strategy_StrategyMutation']
> = {
  strategyClose?: Resolver<
    ResolversTypes['Strategy_Strategy'],
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyMutationStrategyCloseArgs, 'input'>
  >
  strategyDeFiStart?: Resolver<
    ResolversTypes['Strategy_Strategy'],
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyMutationStrategyDeFiStartArgs, 'input'>
  >
  strategyRetry?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyMutationStrategyRetryArgs, 'input'>
  >
  strategyStart?: Resolver<
    ResolversTypes['Strategy_Strategy'],
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyMutationStrategyStartArgs, 'input'>
  >
  strategyStop?: Resolver<
    ResolversTypes['Strategy_Strategy'],
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyMutationStrategyStopArgs, 'input'>
  >
  strategyUpdate?: Resolver<
    ResolversTypes['Strategy_Strategy'],
    ParentType,
    ContextType,
    RequireFields<Strategy_StrategyMutationStrategyUpdateArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyParameterResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyParameter'] = ResolversParentTypes['Strategy_StrategyParameter']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyParameterDescResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyParameterDesc'] = ResolversParentTypes['Strategy_StrategyParameterDesc']
> = {
  defaultValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  minValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_StrategyParameterType'], ParentType, ContextType>
  updatable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyPerformance'] = ResolversParentTypes['Strategy_StrategyPerformance']
> = {
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  initialValue?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  performances?: Resolver<Array<ResolversTypes['Strategy_Performance']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_StrategyStateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_StrategyState'] = ResolversParentTypes['Strategy_StrategyState']
> = {
  __resolveType: TypeResolveFn<
    'Strategy_ArbitrageState' | 'Strategy_LendingState',
    ParentType,
    ContextType
  >
}

export interface Strategy_TimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Strategy_Time'], any> {
  name: 'Strategy_Time'
}

export type Strategy_UserResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_User'] = ResolversParentTypes['Strategy_User']
> = {
  actionLogs?: Resolver<
    Array<ResolversTypes['Strategy_ActionLog']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserActionLogsArgs, 'pagination'>
  >
  estimatePointIsEnough?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  estimatePointsConsuming?: Resolver<
    Array<ResolversTypes['Strategy_EstimatePointsConsumingResult']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserEstimatePointsConsumingArgs, 'input'>
  >
  estimateStrategyRequiredAmount?: Resolver<
    ResolversTypes['Strategy_EstimateStrategyRequiredAmountResponse'],
    ParentType,
    ContextType,
    RequireFields<Strategy_UserEstimateStrategyRequiredAmountArgs, 'input'>
  >
  exchangeBalances?: Resolver<
    Array<ResolversTypes['Strategy_Balance']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserExchangeBalancesArgs, 'input'>
  >
  exchangeWithdrawalAuthorization?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Strategy_ExchangeWithdrawalAuthorizationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserExchangeWithdrawalAuthorizationArgs, 'input'>
  >
  exchangesAssets?: Resolver<
    Array<ResolversTypes['Strategy_ExchangeAsset']>,
    ParentType,
    ContextType
  >
  exchangesNetAssetChart?: Resolver<
    Array<ResolversTypes['Strategy_ExchangesNetAssetChart']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserExchangesNetAssetChartArgs, 'input'>
  >
  externalAPIKeys?: Resolver<
    Array<ResolversTypes['Strategy_ExternalAPIKey']>,
    ParentType,
    ContextType,
    Partial<Strategy_UserExternalApiKeysArgs>
  >
  profits?: Resolver<
    Array<Maybe<ResolversTypes['Strategy_UserProfit']>>,
    ParentType,
    ContextType,
    Partial<Strategy_UserProfitsArgs>
  >
  strategies?: Resolver<
    Array<ResolversTypes['Strategy_Strategy']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserStrategiesArgs, 'pagination'>
  >
  workflows?: Resolver<
    Array<ResolversTypes['Strategy_Workflow']>,
    ParentType,
    ContextType,
    RequireFields<Strategy_UserWorkflowsArgs, 'pagination'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_UserMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_UserMutation'] = ResolversParentTypes['Strategy_UserMutation']
> = {
  externalAPIKey?: Resolver<
    Maybe<ResolversTypes['Strategy_ExternalAPIKeyMutation']>,
    ParentType,
    ContextType
  >
  genericFlow?: Resolver<
    Maybe<ResolversTypes['Strategy_GenericFlowMutation']>,
    ParentType,
    ContextType
  >
  repayStrategyDebt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  strategy?: Resolver<Maybe<ResolversTypes['Strategy_StrategyMutation']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_UserProfitResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_UserProfit'] = ResolversParentTypes['Strategy_UserProfit']
> = {
  dailyProfits?: Resolver<Array<ResolversTypes['Strategy_DailyProfit']>, ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalProfits?: Resolver<Array<ResolversTypes['Strategy_CurrencyAmount']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['StrategyType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_WorkflowResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_Workflow'] = ResolversParentTypes['Strategy_Workflow']
> = {
  actions?: Resolver<Array<ResolversTypes['Strategy_Action']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['Strategy_WorkflowStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['Strategy_WorkflowType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Strategy_YieldRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Strategy_YieldRecord'] = ResolversParentTypes['Strategy_YieldRecord']
> = {
  amount?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Strategy_Number'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['Strategy_Time'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SysResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Sys'] = ResolversParentTypes['Sys']
> = {
  environmentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  firstPublishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  publishedVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  spaceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time'
}

export type TokenBalanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TokenBalance'] = ResolversParentTypes['TokenBalance']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amountReadable?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TokenDisplayInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TokenDisplayInfo'] = ResolversParentTypes['TokenDisplayInfo']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['ToolInfo'] = ResolversParentTypes['ToolInfo']
> = {
  __resolveType: TypeResolveFn<'StrategyInfo' | 'UniswapToolInfo', ParentType, ContextType>
}

export type TotalProfitsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TotalProfits'] = ResolversParentTypes['TotalProfits']
> = {
  amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradeResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Trade'] = ResolversParentTypes['Trade']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  fee?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  feeCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  feeRate?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  market?: Resolver<ResolversTypes['Market'], ParentType, ContextType>
  price?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  quoteBaseCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  quoteOrderCurrency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  side?: Resolver<ResolversTypes['OrderSide'], ParentType, ContextType>
  size?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradeResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TradeResponse'] = ResolversParentTypes['TradeResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Trade']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradingResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Trading'] = ResolversParentTypes['Trading']
> = {
  history?: Resolver<
    ResolversTypes['TradeResponse'],
    ParentType,
    ContextType,
    RequireFields<TradingHistoryArgs, 'pagination'>
  >
  report?: Resolver<
    ResolversTypes['TradingReportResponse'],
    ParentType,
    ContextType,
    RequireFields<TradingReportArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradingConfirmQuotationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TradingConfirmQuotationResponse'] = ResolversParentTypes['TradingConfirmQuotationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradingMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TradingMutation'] = ResolversParentTypes['TradingMutation']
> = {
  confirmQuotation?: Resolver<
    ResolversTypes['TradingConfirmQuotationResponse'],
    ParentType,
    ContextType,
    RequireFields<TradingMutationConfirmQuotationArgs, 'quotationId'>
  >
  getQuotation?: Resolver<
    ResolversTypes['TradingQuotationResponse'],
    ParentType,
    ContextType,
    RequireFields<TradingMutationGetQuotationArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradingQuotationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TradingQuotationResponse'] = ResolversParentTypes['TradingQuotationResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['Quotation']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TradingReportResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['TradingReportResponse'] = ResolversParentTypes['TradingReportResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapOnchainPositionAmountInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapOnchainPositionAmountInfo'] = ResolversParentTypes['UniswapOnchainPositionAmountInfo']
> = {
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0AmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1AmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapPoolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapPoolInfo'] = ResolversParentTypes['UniswapPoolInfo']
> = {
  averageTradingVolumeUSD?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<UniswapPoolInfoAverageTradingVolumeUsdArgs, 'numberOfDays'>
  >
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  collectedFeesToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtBlockNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthGlobal0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthGlobal1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPoolActiveForReyield?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  liquidity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  liquidityProviderCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  observationIndex?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positions?: Resolver<Maybe<Array<ResolversTypes['UniswapPoolPosition']>>, ParentType, ContextType>
  sqrtPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ticks?: Resolver<Maybe<Array<ResolversTypes['UniswapPoolTick']>>, ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Price?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Price?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedETH?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSDUntracked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  untrackedVolumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapPoolPositionResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapPoolPosition'] = ResolversParentTypes['UniswapPoolPosition']
> = {
  collectedFeesToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectedFeesToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  depositedToken0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  depositedToken1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthInside0LastX128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeGrowthInside1LastX128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  liquidity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerFeeGrowthOutside0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerFeeGrowthOutside1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerTickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperFeeGrowthOutside0X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperFeeGrowthOutside1X128?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperTickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapPoolTickResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapPoolTick'] = ResolversParentTypes['UniswapPoolTick']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  liquidityNet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price0?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickIdx?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapQuoteResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapQuote'] = ResolversParentTypes['UniswapQuote']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  fromToken?: Resolver<ResolversTypes['UniswapToolToken'], ParentType, ContextType>
  fromTokenAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  inputAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  inputReadableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outputAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outputReadableAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toToken?: Resolver<ResolversTypes['UniswapToolToken'], ParentType, ContextType>
  toTokenAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolFeeTierResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolFeeTier'] = ResolversParentTypes['UniswapToolFeeTier']
> = {
  feeForContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeForDisplay?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolInfo'] = ResolversParentTypes['UniswapToolInfo']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creator?: Resolver<Maybe<ResolversTypes['Creator']>, ParentType, ContextType>
  creatorAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creatorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  expectedAPY?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['UniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numOfApply?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  performanceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolInfo?: Resolver<Maybe<ResolversTypes['UniswapPoolInfo']>, ParentType, ContextType>
  riskLevel?: Resolver<ResolversTypes['RiskLevel'], ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['UniswapToolTag']>>, ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickLowerDiffForContract?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiffForContract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTagResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolTag'] = ResolversParentTypes['UniswapToolTag']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['UniswapToolTagType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTokenResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolToken'] = ResolversParentTypes['UniswapToolToken']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  derivedETH?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feesUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  poolCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  priceChart?: Resolver<
    Maybe<ResolversTypes['UniswapToolTokenPriceChart']>,
    ParentType,
    ContextType
  >
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalSupply?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLocked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalValueLockedUSDUntracked?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  txCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  untrackedVolumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volume?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  volumeUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTokenPriceChartResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolTokenPriceChart'] = ResolversParentTypes['UniswapToolTokenPriceChart']
> = {
  currentPriceUSD?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  prices?: Resolver<
    Maybe<Array<ResolversTypes['UniswapToolTokenPriceChartPrice']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapToolTokenPriceChartPriceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapToolTokenPriceChartPrice'] = ResolversParentTypes['UniswapToolTokenPriceChartPrice']
> = {
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UniswapV3BacktestResultResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UniswapV3BacktestResult'] = ResolversParentTypes['UniswapV3BacktestResult']
> = {
  activeLiquidityPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  annualReturn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  compound?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dailyReturn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  dataPointsCount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  days?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  diff?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  fees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  impermanentLoss?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  investmentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  maxDrawdown?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  period?: Resolver<ResolversTypes['UniswapV3BacktestPeriod'], ParentType, ContextType>
  poolID?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  protocol?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  totalReturn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  volatility?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  actionLogs?: Resolver<
    ResolversTypes['UserActionLogResponse'],
    ParentType,
    ContextType,
    RequireFields<UserActionLogsArgs, 'pagination'>
  >
  agreements?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  amlInfo?: Resolver<Maybe<ResolversTypes['AML']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['Email'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  is2FAEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isDisabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isOldUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kycInfo?: Resolver<Maybe<ResolversTypes['KYC']>, ParentType, ContextType>
  loginRecords?: Resolver<
    ResolversTypes['UserLoginRecordsResponse'],
    ParentType,
    ContextType,
    RequireFields<UserLoginRecordsArgs, 'pagination'>
  >
  notifications?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType>
  oauthClients?: Resolver<
    ResolversTypes['UserOAuthClientResponse'],
    ParentType,
    ContextType,
    Partial<UserOauthClientsArgs>
  >
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  point?: Resolver<Maybe<ResolversTypes['Point']>, ParentType, ContextType>
  preference?: Resolver<ResolversTypes['UserPreference'], ParentType, ContextType>
  questionnaires?: Resolver<
    Array<ResolversTypes['Questionnaire']>,
    ParentType,
    ContextType,
    Partial<UserQuestionnairesArgs>
  >
  referralCodes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  referralCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  referrer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  roles?: Resolver<Array<ResolversTypes['UserRole']>, ParentType, ContextType>
  scopes?: Resolver<Array<ResolversTypes['UserScope']>, ParentType, ContextType>
  special?: Resolver<
    Maybe<ResolversTypes['SpecialUserCollection']>,
    ParentType,
    ContextType,
    RequireFields<UserSpecialArgs, 'limit' | 'skip'>
  >
  trading?: Resolver<Maybe<ResolversTypes['Trading']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  vipLevel?: Resolver<ResolversTypes['VIPLevel'], ParentType, ContextType>
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType>
  wallet?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserActionLogResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserActionLog'] = ResolversParentTypes['UserActionLog']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  operator?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserActionLogResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserActionLogResponse'] = ResolversParentTypes['UserActionLogResponse']
> = {
  logs?: Resolver<Array<ResolversTypes['UserActionLog']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserAgreeResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserAgreeResponse'] = ResolversParentTypes['UserAgreeResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserBindPhoneConfirmationResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserBindPhoneConfirmationResponse'] = ResolversParentTypes['UserBindPhoneConfirmationResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserBindPhoneResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserBindPhoneResponse'] = ResolversParentTypes['UserBindPhoneResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCancelOAuthResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserCancelOAuthResponse'] = ResolversParentTypes['UserCancelOAuthResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserChangePasswordResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserChangePasswordResponse'] = ResolversParentTypes['UserChangePasswordResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCustomizeReferralResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserCustomizeReferralResponse'] = ResolversParentTypes['UserCustomizeReferralResponse']
> = {
  data?: Resolver<ResolversTypes['UserReferral'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserDeleteBankAccountResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserDeleteBankAccountResponse'] = ResolversParentTypes['UserDeleteBankAccountResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserDeviceTokenResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserDeviceTokenResponse'] = ResolversParentTypes['UserDeviceTokenResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserFillReferrerCodeResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserFillReferrerCodeResponse'] = ResolversParentTypes['UserFillReferrerCodeResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserForgotPasswordConfirmResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserForgotPasswordConfirmResponse'] = ResolversParentTypes['UserForgotPasswordConfirmResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserForgotPasswordResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserForgotPasswordResponse'] = ResolversParentTypes['UserForgotPasswordResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo']
> = {
  aICompletionRecords?: Resolver<
    Maybe<Array<ResolversTypes['aICompletionRecords']>>,
    ParentType,
    ContextType,
    Partial<UserInfoAiCompletionRecordsArgs>
  >
  cefiDepositAddress?: Resolver<
    Maybe<ResolversTypes['CefiDepositAddress']>,
    ParentType,
    ContextType,
    Partial<UserInfoCefiDepositAddressArgs>
  >
  cefiWallets?: Resolver<
    Maybe<Array<ResolversTypes['CefiWallet']>>,
    ParentType,
    ContextType,
    Partial<UserInfoCefiWalletsArgs>
  >
  defiTokensBalanceAmount?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TokenBalance']>>>,
    ParentType,
    ContextType,
    RequireFields<UserInfoDefiTokensBalanceAmountArgs, 'input'>
  >
  defiWallets?: Resolver<
    Maybe<Array<ResolversTypes['DefiWallet']>>,
    ParentType,
    ContextType,
    Partial<UserInfoDefiWalletsArgs>
  >
  externalAPIKeys?: Resolver<
    Maybe<Array<ResolversTypes['ExternalAPIKey']>>,
    ParentType,
    ContextType,
    Partial<UserInfoExternalApiKeysArgs>
  >
  gimCoolPayPaymentInfo?: Resolver<
    Maybe<ResolversTypes['GimCoolPayPaymentInfo']>,
    ParentType,
    ContextType,
    Partial<UserInfoGimCoolPayPaymentInfoArgs>
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nftCollections?: Resolver<Maybe<Array<ResolversTypes['NFTCollections']>>, ParentType, ContextType>
  nftMerkleTreeHexProof?: Resolver<
    Maybe<ResolversTypes['NFTMerkleTreeHexProof']>,
    ParentType,
    ContextType
  >
  nftWhitelistInfoByPhrases?: Resolver<
    Maybe<Array<ResolversTypes['NFTWhitelistInfoByPhrase']>>,
    ParentType,
    ContextType
  >
  ownedToolsSetting?: Resolver<
    Maybe<Array<ResolversTypes['UserUniswapToolSettingInfo']>>,
    ParentType,
    ContextType
  >
  reyieldLicense?: Resolver<Maybe<ResolversTypes['ReyieldLicense']>, ParentType, ContextType>
  strategies?: Resolver<
    Maybe<Array<ResolversTypes['UserStrategyInfo']>>,
    ParentType,
    ContextType,
    Partial<UserInfoStrategiesArgs>
  >
  strategyPerformance?: Resolver<
    Maybe<ResolversTypes['UserStrategyPerformance']>,
    ParentType,
    ContextType,
    RequireFields<UserInfoStrategyPerformanceArgs, 'strategyID'>
  >
  strategyProfit?: Resolver<
    Maybe<ResolversTypes['UserStrategyProfit']>,
    ParentType,
    ContextType,
    Partial<UserInfoStrategyProfitArgs>
  >
  strategyProgress?: Resolver<
    Maybe<ResolversTypes['StrategyProgress']>,
    ParentType,
    ContextType,
    RequireFields<UserInfoStrategyProgressArgs, 'strategyID'>
  >
  strategyRecords?: Resolver<
    Maybe<Array<ResolversTypes['UserStrategyRecord']>>,
    ParentType,
    ContextType,
    Partial<UserInfoStrategyRecordsArgs>
  >
  tools?: Resolver<Maybe<Array<ResolversTypes['UserToolInfo']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserLoginRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserLoginRecord'] = ResolversParentTypes['UserLoginRecord']
> = {
  browser?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  device?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ipAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ipCountry?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  os?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  userAgent?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserLoginRecordsResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserLoginRecordsResponse'] = ResolversParentTypes['UserLoginRecordsResponse']
> = {
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  records?: Resolver<Array<ResolversTypes['UserLoginRecord']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserLoginResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserLoginResponse'] = ResolversParentTypes['UserLoginResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['LoginResponse']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserMutation'] = ResolversParentTypes['UserMutation']
> = {
  userAgree?: Resolver<
    ResolversTypes['UserAgreeResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserAgreeArgs, 'input'>
  >
  userBindPhone?: Resolver<
    ResolversTypes['UserBindPhoneResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserBindPhoneArgs, 'input'>
  >
  userBindPhoneConfirmation?: Resolver<
    ResolversTypes['UserBindPhoneConfirmationResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserBindPhoneConfirmationArgs, 'input'>
  >
  userCancelOAuth?: Resolver<
    ResolversTypes['UserCancelOAuthResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserCancelOAuthArgs, 'input'>
  >
  userChangePassword?: Resolver<
    ResolversTypes['UserChangePasswordResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserChangePasswordArgs, 'input'>
  >
  userCustomizeReferral?: Resolver<
    ResolversTypes['UserCustomizeReferralResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserCustomizeReferralArgs, 'input'>
  >
  userDeleteBankAccount?: Resolver<
    ResolversTypes['UserDeleteBankAccountResponse'],
    ParentType,
    ContextType
  >
  userFillReferrerCode?: Resolver<
    ResolversTypes['UserFillReferrerCodeResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserFillReferrerCodeArgs, 'input'>
  >
  userForgotPassword?: Resolver<
    ResolversTypes['UserForgotPasswordResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserForgotPasswordArgs, 'input'>
  >
  userForgotPasswordConfirm?: Resolver<
    ResolversTypes['UserForgotPasswordConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserForgotPasswordConfirmArgs, 'input'>
  >
  userLogin?: Resolver<
    ResolversTypes['UserLoginResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserLoginArgs, 'input'>
  >
  userRefreshToken?: Resolver<
    ResolversTypes['UserRefreshTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserRefreshTokenArgs, 'input'>
  >
  userRegister?: Resolver<
    ResolversTypes['UserRegisterResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserRegisterArgs, 'input'>
  >
  userRegisterConfirm?: Resolver<
    ResolversTypes['UserRegisterConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserRegisterConfirmArgs, 'input'>
  >
  userRegisterDeviceToken?: Resolver<
    ResolversTypes['UserDeviceTokenResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserRegisterDeviceTokenArgs, 'input'>
  >
  userRequestAccountDisable?: Resolver<
    ResolversTypes['UserRequestAccountDisableResponse'],
    ParentType,
    ContextType
  >
  userResendForgotPassword?: Resolver<
    ResolversTypes['UserResendForgotPasswordResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserResendForgotPasswordArgs, 'input'>
  >
  userResendRegister?: Resolver<
    ResolversTypes['UserResendRegisterResponse'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserResendRegisterArgs, 'input'>
  >
  userSetPreference?: Resolver<
    ResolversTypes['UserPreferenceResponse'],
    ParentType,
    ContextType,
    Partial<UserMutationUserSetPreferenceArgs>
  >
  userSubmitQuestionnaire?: Resolver<
    ResolversTypes['Questionnaire'],
    ParentType,
    ContextType,
    RequireFields<UserMutationUserSubmitQuestionnaireArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserOAuthClientResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserOAuthClient'] = ResolversParentTypes['UserOAuthClient']
> = {
  clientID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAuthorized?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserOAuthClientResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserOAuthClientResponse'] = ResolversParentTypes['UserOAuthClientResponse']
> = {
  clients?: Resolver<Array<ResolversTypes['UserOAuthClient']>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserPreferenceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserPreference'] = ResolversParentTypes['UserPreference']
> = {
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserPreferenceResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserPreferenceResponse'] = ResolversParentTypes['UserPreferenceResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['UserPreference']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserReferralResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserReferral'] = ResolversParentTypes['UserReferral']
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRefreshTokenResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserRefreshTokenResponse'] = ResolversParentTypes['UserRefreshTokenResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['LoginInfo']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRegisterConfirmResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserRegisterConfirmResponse'] = ResolversParentTypes['UserRegisterConfirmResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRegisterResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserRegisterResponse'] = ResolversParentTypes['UserRegisterResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRequestAccountDisableResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserRequestAccountDisableResponse'] = ResolversParentTypes['UserRequestAccountDisableResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserResendForgotPasswordResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserResendForgotPasswordResponse'] = ResolversParentTypes['UserResendForgotPasswordResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserResendRegisterResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserResendRegisterResponse'] = ResolversParentTypes['UserResendRegisterResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyInfo'] = ResolversParentTypes['UserStrategyInfo']
> = {
  ceFiStatus?: Resolver<ResolversTypes['StrategyStatus'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  errMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  infos?: Resolver<Maybe<ResolversTypes['StrategyInfos']>, ParentType, ContextType>
  parameters?: Resolver<
    Maybe<Array<ResolversTypes['UserStrategyParameters']>>,
    ParentType,
    ContextType
  >
  performance?: Resolver<Maybe<ResolversTypes['UserStrategyPerformance']>, ParentType, ContextType>
  profits?: Resolver<Maybe<ResolversTypes['UserStrategyProfit']>, ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyParametersResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyParameters'] = ResolversParentTypes['UserStrategyParameters']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyPerformanceResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyPerformance'] = ResolversParentTypes['UserStrategyPerformance']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  initialValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  performances?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StrategyPerformance']>>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyProfitResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyProfit'] = ResolversParentTypes['UserStrategyProfit']
> = {
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalProfit?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TotalProfits']>>>,
    ParentType,
    ContextType
  >
  type?: Resolver<Maybe<ResolversTypes['StrategyType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStrategyRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserStrategyRecord'] = ResolversParentTypes['UserStrategyRecord']
> = {
  amount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['RecordsType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserToolInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserToolInfo'] = ResolversParentTypes['UserToolInfo']
> = {
  __resolveType: TypeResolveFn<
    'UserStrategyInfo' | 'UserUniswapPositionInfo',
    ParentType,
    ContextType
  >
}

export type UserUniswapOnchainPositionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapOnchainPositionInfo'] = ResolversParentTypes['UserUniswapOnchainPositionInfo']
> = {
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  closedPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  positionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionIdFromSmartContractPositionManager?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  roi?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  runningPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  totalInvestAmountUsdValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapPositionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapPositionInfo'] = ResolversParentTypes['UserUniswapPositionInfo']
> = {
  apy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  closedPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['UniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onchainPositionInfo?: Resolver<
    Maybe<ResolversTypes['UserUniswapOnchainPositionInfo']>,
    ParentType,
    ContextType,
    Partial<UserUniswapPositionInfoOnchainPositionInfoArgs>
  >
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  positionIdFromSmartContractPositionManager?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  progresses?: Resolver<
    Maybe<Array<ResolversTypes['UserUniswapv3PositionProgress']>>,
    ParentType,
    ContextType
  >
  publicToolInfo?: Resolver<Maybe<ResolversTypes['UniswapToolInfo']>, ParentType, ContextType>
  roi?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  runningPositionAmount?: Resolver<
    Maybe<ResolversTypes['UniswapOnchainPositionAmountInfo']>,
    ParentType,
    ContextType
  >
  status?: Resolver<ResolversTypes['UniswapToolPositionStatus'], ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  toolDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolIsOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  toolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toolPerformanceFeeRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toolSetting?: Resolver<
    Maybe<ResolversTypes['UserUniswapToolSettingInfo']>,
    ParentType,
    ContextType
  >
  totalInvestAmountUsdValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapToolOutOfRangeInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapToolOutOfRangeInfo'] = ResolversParentTypes['UserUniswapToolOutOfRangeInfo']
> = {
  earnSpendRate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  maxRebalanceTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  standbyInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  standbyMaxInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapToolSettingInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapToolSettingInfo'] = ResolversParentTypes['UserUniswapToolSettingInfo']
> = {
  chain?: Resolver<ResolversTypes['EvmChain'], ParentType, ContextType>
  closedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  feeTier?: Resolver<ResolversTypes['UniswapToolFeeTier'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isOfficial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  outOfLowerRange?: Resolver<
    ResolversTypes['UserUniswapToolOutOfRangeInfo'],
    ParentType,
    ContextType
  >
  outOfUpperRange?: Resolver<
    ResolversTypes['UserUniswapToolOutOfRangeInfo'],
    ParentType,
    ContextType
  >
  performanceFeeRatio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  poolAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['UserUniswapPositionInfo']>, ParentType, ContextType>
  publicToolInfo?: Resolver<Maybe<ResolversTypes['UniswapToolInfo']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['UniswapToolStatus'], ParentType, ContextType>
  tickLowerDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tickUpperDiff?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token0Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token0DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  token1?: Resolver<Maybe<ResolversTypes['UniswapToolToken']>, ParentType, ContextType>
  token1Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token1DisplayInfo?: Resolver<Maybe<ResolversTypes['TokenDisplayInfo']>, ParentType, ContextType>
  totalUserUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapv3PositionProgressResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapv3PositionProgress'] = ResolversParentTypes['UserUniswapv3PositionProgress']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserUniswapv3PositionProgressStatus'], ParentType, ContextType>
  steps?: Resolver<
    Array<ResolversTypes['UserUniswapv3PositionProgressStep']>,
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['UserUniswapv3PositionProgressType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserUniswapv3PositionProgressStepResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['UserUniswapv3PositionProgressStep'] = ResolversParentTypes['UserUniswapv3PositionProgressStep']
> = {
  status?: Resolver<
    ResolversTypes['UserUniswapv3PositionProgressStepStatus'],
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['UserUniswapv3PositionProgressStepType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VersionInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['VersionInfo'] = ResolversParentTypes['VersionInfo']
> = {
  buildTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  goVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pbVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  revision?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoucherResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Voucher'] = ResolversParentTypes['Voucher']
> = {
  isVoucherUsed?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<VoucherIsVoucherUsedArgs, 'code'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoucherMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['VoucherMutation'] = ResolversParentTypes['VoucherMutation']
> = {
  voucherUse?: Resolver<
    ResolversTypes['VoucherUseResponse'],
    ParentType,
    ContextType,
    RequireFields<VoucherMutationVoucherUseArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoucherRecordResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['VoucherRecord'] = ResolversParentTypes['VoucherRecord']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  expiredDate?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>
  isDisabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maxUsageTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  point?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  usedTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VoucherUseResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['VoucherUseResponse'] = ResolversParentTypes['VoucherUseResponse']
> = {
  point?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Wallet'] = ResolversParentTypes['Wallet']
> = {
  balances?: Resolver<
    Maybe<Array<ResolversTypes['Balance']>>,
    ParentType,
    ContextType,
    Partial<WalletBalancesArgs>
  >
  commonAddresses?: Resolver<
    ResolversTypes['CommonAddressResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletCommonAddressesArgs, 'pagination'>
  >
  depositCryptoInfo?: Resolver<
    Maybe<Array<ResolversTypes['Address']>>,
    ParentType,
    ContextType,
    RequireFields<WalletDepositCryptoInfoArgs, 'filter'>
  >
  depositFiatInfo?: Resolver<ResolversTypes['FiatDepositInfo'], ParentType, ContextType>
  deposits?: Resolver<
    ResolversTypes['DepositResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletDepositsArgs, 'pagination'>
  >
  faasPayments?: Resolver<
    ResolversTypes['FAASPaymentResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletFaasPaymentsArgs, 'pagination'>
  >
  isInternalTransfer?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<WalletIsInternalTransferArgs, 'address'>
  >
  ledgers?: Resolver<
    ResolversTypes['LedgerResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletLedgersArgs, 'pagination'>
  >
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  withdrawInfo?: Resolver<
    ResolversTypes['WithdrawInfo'],
    ParentType,
    ContextType,
    RequireFields<WalletWithdrawInfoArgs, 'filter'>
  >
  withdrawals?: Resolver<
    ResolversTypes['WithdrawalResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletWithdrawalsArgs, 'pagination'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletBalancesResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletBalances'] = ResolversParentTypes['WalletBalances']
> = {
  currencyBalances?: Resolver<Array<ResolversTypes['CurrencyBalances']>, ParentType, ContextType>
  wallet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletCreateCommonAddressResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletCreateCommonAddressResponse'] = ResolversParentTypes['WalletCreateCommonAddressResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletCreateCryptoAddressResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletCreateCryptoAddressResponse'] = ResolversParentTypes['WalletCreateCryptoAddressResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletCryptoWithdrawResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletCryptoWithdrawResponse'] = ResolversParentTypes['WalletCryptoWithdrawResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['WithdrawCryptoResponse']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletDeleteCommonAddressResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletDeleteCommonAddressResponse'] = ResolversParentTypes['WalletDeleteCommonAddressResponse']
> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletDepositResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletDepositResponse'] = ResolversParentTypes['WalletDepositResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['DepositResponse']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletFaasPaymentConfirmResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletFAASPaymentConfirmResponse'] = ResolversParentTypes['WalletFAASPaymentConfirmResponse']
> = {
  data?: Resolver<ResolversTypes['FAASPayment'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletFiatWithdrawResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletFiatWithdrawResponse'] = ResolversParentTypes['WalletFiatWithdrawResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['WithdrawFiatResponse']>, ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletMutation'] = ResolversParentTypes['WalletMutation']
> = {
  walletCreateCommonAddress?: Resolver<
    ResolversTypes['WalletCreateCommonAddressResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletCreateCommonAddressArgs, 'input'>
  >
  walletCreateCryptoAddress?: Resolver<
    ResolversTypes['WalletCreateCryptoAddressResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletCreateCryptoAddressArgs, 'input'>
  >
  walletCryptoWithdraw?: Resolver<
    ResolversTypes['WalletCryptoWithdrawResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletCryptoWithdrawArgs, 'input'>
  >
  walletDeleteCommonAddress?: Resolver<
    ResolversTypes['WalletDeleteCommonAddressResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletDeleteCommonAddressArgs, 'input'>
  >
  walletFAASPaymentConfirm?: Resolver<
    ResolversTypes['WalletFAASPaymentConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletFaasPaymentConfirmArgs, 'input'>
  >
  walletFiatWithdraw?: Resolver<
    ResolversTypes['WalletFiatWithdrawResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletFiatWithdrawArgs, 'input'>
  >
  walletWithdrawConfirm?: Resolver<
    ResolversTypes['WalletWithdrawConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<WalletMutationWalletWithdrawConfirmArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletWithdrawConfirmResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletWithdrawConfirmResponse'] = ResolversParentTypes['WalletWithdrawConfirmResponse']
> = {
  data?: Resolver<ResolversTypes['Withdrawal'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletWithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WalletWithdrawalResponse'] = ResolversParentTypes['WalletWithdrawalResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['WithdrawalResponse']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WithdrawCryptoResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WithdrawCryptoResponse'] = ResolversParentTypes['WithdrawCryptoResponse']
> = {
  __resolveType: TypeResolveFn<
    'CryptoWithdrawal' | 'InternalTransferOut' | 'Response2FA',
    ParentType,
    ContextType
  >
}

export type WithdrawFiatResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WithdrawFiatResponse'] = ResolversParentTypes['WithdrawFiatResponse']
> = {
  __resolveType: TypeResolveFn<'FiatWithdrawal' | 'Response2FA', ParentType, ContextType>
}

export type WithdrawInfoResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WithdrawInfo'] = ResolversParentTypes['WithdrawInfo']
> = {
  dailyAccumulated?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  dailyMaxLimit?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  fee?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  maxLimit?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  minLimit?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  monthlyAccumulated?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  monthlyMaxLimit?: Resolver<ResolversTypes['Number'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WithdrawalResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['Withdrawal'] = ResolversParentTypes['Withdrawal']
> = {
  __resolveType: TypeResolveFn<
    'CryptoWithdrawal' | 'FiatWithdrawal' | 'InternalTransferOut',
    ParentType,
    ContextType
  >
}

export type WithdrawalResponseResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['WithdrawalResponse'] = ResolversParentTypes['WithdrawalResponse']
> = {
  data?: Resolver<Maybe<Array<ResolversTypes['Withdrawal']>>, ParentType, ContextType>
  meta?: Resolver<ResolversTypes['MetaPagination'], ParentType, ContextType>
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AICompletionRecordsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['aICompletionRecords'] = ResolversParentTypes['aICompletionRecords']
> = {
  completedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  meta?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<AICompletionRecordsMetaArgs>
  >
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  promptMutationInput?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<AICompletionRecordsPromptMutationInputArgs>
  >
  promptMutationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  suggestions?: Resolver<
    Array<ResolversTypes['suggestStrategyToolWithAllocations']>,
    ParentType,
    ContextType,
    Partial<AICompletionRecordsSuggestionsArgs>
  >
  suggestions_aggregate?: Resolver<
    ResolversTypes['suggestStrategyToolWithAllocations_aggregate'],
    ParentType,
    ContextType,
    Partial<AICompletionRecordsSuggestions_AggregateArgs>
  >
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  userAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FrontendQueryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['frontendQuery'] = ResolversParentTypes['frontendQuery']
> = {
  version?: Resolver<ResolversTypes['FrontendVersion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb'
}

export type Mutation_RootResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']
> = {
  admin?: Resolver<Maybe<ResolversTypes['AdminMutation']>, ParentType, ContextType>
  auth?: Resolver<Maybe<ResolversTypes['AuthMutation']>, ParentType, ContextType>
  dev?: Resolver<Maybe<ResolversTypes['DevMutation']>, ParentType, ContextType>
  kyc?: Resolver<Maybe<ResolversTypes['KYCMutation']>, ParentType, ContextType>
  notification?: Resolver<Maybe<ResolversTypes['NotificationMutation']>, ParentType, ContextType>
  point?: Resolver<Maybe<ResolversTypes['PointMutation']>, ParentType, ContextType>
  resource?: Resolver<Maybe<ResolversTypes['ResourceMutation']>, ParentType, ContextType>
  reyield?: Resolver<Maybe<ResolversTypes['reyieldMutation']>, ParentType, ContextType>
  strategy?: Resolver<Maybe<ResolversTypes['strategyMutation']>, ParentType, ContextType>
  trading?: Resolver<Maybe<ResolversTypes['TradingMutation']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['UserMutation']>, ParentType, ContextType>
  voucher?: Resolver<Maybe<ResolversTypes['VoucherMutation']>, ParentType, ContextType>
  wallet?: Resolver<Maybe<ResolversTypes['WalletMutation']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['reyieldMutation'] = ResolversParentTypes['reyieldMutation']
> = {
  closeUniswapv3Position?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationCloseUniswapv3PositionArgs, 'input'>
  >
  createAICompletionRecord?: Resolver<
    ResolversTypes['ReyieldaICompletionRecords'],
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationCreateAiCompletionRecordArgs, 'input'>
  >
  createUniswapv3Position?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationCreateUniswapv3PositionArgs, 'input'>
  >
  createUniswapv3Tool?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationCreateUniswapv3ToolArgs, 'input'>
  >
  externalAPIKeyCreate?: Resolver<
    ResolversTypes['ReyieldExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationExternalApiKeyCreateArgs, 'input'>
  >
  externalAPIKeyDelete?: Resolver<
    ResolversTypes['ReyieldExternalAPIKey'],
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationExternalApiKeyDeleteArgs, 'input'>
  >
  strategyStart?: Resolver<
    ResolversTypes['ReyieldStrategyStartResponse'],
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationStrategyStartArgs, 'input'>
  >
  strategyStop?: Resolver<
    ResolversTypes['ReyieldStrategyStopResponse'],
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationStrategyStopArgs, 'input'>
  >
  strategyUpdate?: Resolver<
    ResolversTypes['ReyieldStrategyUpdateResponse'],
    ParentType,
    ContextType,
    RequireFields<ReyieldMutationStrategyUpdateArgs, 'input'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReyieldQueryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['reyieldQuery'] = ResolversParentTypes['reyieldQuery']
> = {
  coinPrices?: Resolver<Maybe<Array<ResolversTypes['ReyieldCoinPrice']>>, ParentType, ContextType>
  me?: Resolver<ResolversTypes['ReyieldUserInfo'], ParentType, ContextType>
  poolsWithArguments?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUniswapPoolInfo']>>,
    ParentType,
    ContextType,
    RequireFields<ReyieldQueryPoolsWithArgumentsArgs, 'chain'>
  >
  rateHistorical?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldRateHistorical']>>,
    ParentType,
    ContextType,
    RequireFields<ReyieldQueryRateHistoricalArgs, 'filter'>
  >
  strategies?: Resolver<
    Array<ResolversTypes['ReyieldStrategyInfo']>,
    ParentType,
    ContextType,
    Partial<ReyieldQueryStrategiesArgs>
  >
  tools?: Resolver<Array<ResolversTypes['ReyieldToolInfo']>, ParentType, ContextType>
  uniswapQuote?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapQuote']>,
    ParentType,
    ContextType,
    RequireFields<ReyieldQueryUniswapQuoteArgs, 'input'>
  >
  uniswapTokens?: Resolver<
    Maybe<Array<ResolversTypes['ReyieldUniswapToolToken']>>,
    ParentType,
    ContextType,
    RequireFields<ReyieldQueryUniswapTokensArgs, 'chain'>
  >
  uniswapV3Backtest?: Resolver<
    Maybe<ResolversTypes['ReyieldUniswapV3BacktestResult']>,
    ParentType,
    ContextType,
    RequireFields<
      ReyieldQueryUniswapV3BacktestArgs,
      'compound' | 'days' | 'diff' | 'investmentAmount' | 'period' | 'poolID' | 'protocol'
    >
  >
  version?: Resolver<ResolversTypes['ReyieldFrontendVersion'], ParentType, ContextType>
  wallet?: Resolver<
    Maybe<ResolversTypes['ReyieldDefiWallet']>,
    ParentType,
    ContextType,
    Partial<ReyieldQueryWalletArgs>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyMutationResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['strategyMutation'] = ResolversParentTypes['strategyMutation']
> = {
  admin?: Resolver<Maybe<ResolversTypes['Strategy_AdminMutation']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['Strategy_UserMutation']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StrategyQueryResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['strategyQuery'] = ResolversParentTypes['strategyQuery']
> = {
  admin?: Resolver<Maybe<ResolversTypes['Strategy_Admin']>, ParentType, ContextType>
  public?: Resolver<Maybe<ResolversTypes['Strategy_Public']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['Strategy_User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocationsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations'] = ResolversParentTypes['suggestStrategyToolWithAllocations']
> = {
  aICompletionRecordId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  allocation?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  highestAnnualReturn?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>
  maximumLoss?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_AggregateResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate'] = ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['suggestStrategyToolWithAllocations_aggregate_fields']>,
    ParentType,
    ContextType
  >
  nodes?: Resolver<
    Array<ResolversTypes['suggestStrategyToolWithAllocations']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_Aggregate_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate_fields'] = ResolversParentTypes['suggestStrategyToolWithAllocations_aggregate_fields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<SuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs>
  >
  max?: Resolver<
    Maybe<ResolversTypes['suggestStrategyToolWithAllocations_max_fields']>,
    ParentType,
    ContextType
  >
  min?: Resolver<
    Maybe<ResolversTypes['suggestStrategyToolWithAllocations_min_fields']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_Max_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_max_fields'] = ResolversParentTypes['suggestStrategyToolWithAllocations_max_fields']
> = {
  aICompletionRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highestAnnualReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>
  maximumLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestStrategyToolWithAllocations_Min_FieldsResolvers<
  ContextType = GraphqlContext,
  ParentType extends ResolversParentTypes['suggestStrategyToolWithAllocations_min_fields'] = ResolversParentTypes['suggestStrategyToolWithAllocations_min_fields']
> = {
  aICompletionRecordId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highestAnnualReturn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>
  maximumLoss?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestAmountSuggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  systemInvestCurrencySuggestion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface TimestamptzScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz'
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid'
}

export type Resolvers<ContextType = GraphqlContext> = {
  AML?: AmlResolvers<ContextType>
  AMLAssessmentItem?: AmlAssessmentItemResolvers<ContextType>
  AMLEDDRecord?: AmleddRecordResolvers<ContextType>
  AMLItemCategory?: AmlItemCategoryResolvers<ContextType>
  AMLNSRecord?: AmlnsRecordResolvers<ContextType>
  AMLRARecord?: AmlraRecordResolvers<ContextType>
  AMLRatingCriteria?: AmlRatingCriteriaResolvers<ContextType>
  AMLRiskAssessmentForm?: AmlRiskAssessmentFormResolvers<ContextType>
  AMLRiskFactor?: AmlRiskFactorResolvers<ContextType>
  AMLUserAssessmentResult?: AmlUserAssessmentResultResolvers<ContextType>
  AMLVerification?: AmlVerificationResolvers<ContextType>
  APIKey?: ApiKeyResolvers<ContextType>
  AcceptFiatWithdrawalResponse?: AcceptFiatWithdrawalResponseResolvers<ContextType>
  ActivityNotification?: ActivityNotificationResolvers<ContextType>
  ActivityNotificationCollection?: ActivityNotificationCollectionResolvers<ContextType>
  ActivityNotificationLinkingCollections?: ActivityNotificationLinkingCollectionsResolvers<ContextType>
  Address?: AddressResolvers<ContextType>
  Admin?: AdminResolvers<ContextType>
  AdminAPIKeyResponse?: AdminApiKeyResponseResolvers<ContextType>
  AdminActivateMerchantResponse?: AdminActivateMerchantResponseResolvers<ContextType>
  AdminAddAuditNoteResponse?: AdminAddAuditNoteResponseResolvers<ContextType>
  AdminApproveVirtualAccountVerificationResponse?: AdminApproveVirtualAccountVerificationResponseResolvers<ContextType>
  AdminAssignKYCVerifierResponse?: AdminAssignKycVerifierResponseResolvers<ContextType>
  AdminAuditWithdrawalResponse?: AdminAuditWithdrawalResponseResolvers<ContextType>
  AdminChangeVirtualAccountLimitResponse?: AdminChangeVirtualAccountLimitResponseResolvers<ContextType>
  AdminCheckDepositInfoResponse?: AdminCheckDepositInfoResponseResolvers<ContextType>
  AdminCheckUploadResultMResponse?: AdminCheckUploadResultMResponseResolvers<ContextType>
  AdminCheckUploadResultP01Response?: AdminCheckUploadResultP01ResponseResolvers<ContextType>
  AdminCheckUploadResultP02Response?: AdminCheckUploadResultP02ResponseResolvers<ContextType>
  AdminCheckUploadResultP05Response?: AdminCheckUploadResultP05ResponseResolvers<ContextType>
  AdminCheckUploadResultPG1Response?: AdminCheckUploadResultPg1ResponseResolvers<ContextType>
  AdminCheckUploadResultResponse?: AdminCheckUploadResultResponseResolvers<ContextType>
  AdminCreateAPIKeyResponse?: AdminCreateApiKeyResponseResolvers<ContextType>
  AdminCreateOAuthClientResponse?: AdminCreateOAuthClientResponseResolvers<ContextType>
  AdminCreateRolesResponse?: AdminCreateRolesResponseResolvers<ContextType>
  AdminCreateUserResponse?: AdminCreateUserResponseResolvers<ContextType>
  AdminDVBanResponse?: AdminDvBanResponseResolvers<ContextType>
  AdminDVUpdateResponse?: AdminDvUpdateResponseResolvers<ContextType>
  AdminDeleteAPIKeyResponse?: AdminDeleteApiKeyResponseResolvers<ContextType>
  AdminDeleteRolesResponse?: AdminDeleteRolesResponseResolvers<ContextType>
  AdminDepositReportResponse?: AdminDepositReportResponseResolvers<ContextType>
  AdminDepositResponse?: AdminDepositResponseResolvers<ContextType>
  AdminDisable2FAResponse?: AdminDisable2FaResponseResolvers<ContextType>
  AdminDisableUserResponse?: AdminDisableUserResponseResolvers<ContextType>
  AdminEDDAuditResponse?: AdminEddAuditResponseResolvers<ContextType>
  AdminEnableUserResponse?: AdminEnableUserResponseResolvers<ContextType>
  AdminHolidayAddResponse?: AdminHolidayAddResponseResolvers<ContextType>
  AdminHolidayResponse?: AdminHolidayResponseResolvers<ContextType>
  AdminIPUserResponse?: AdminIpUserResponseResolvers<ContextType>
  AdminLedgerResponse?: AdminLedgerResponseResolvers<ContextType>
  AdminMutation?: AdminMutationResolvers<ContextType>
  AdminNSAuditResponse?: AdminNsAuditResponseResolvers<ContextType>
  AdminOnHoldFiatWithdrawalResponse?: AdminOnHoldFiatWithdrawalResponseResolvers<ContextType>
  AdminOnHoldKGIVerificationResponse?: AdminOnHoldKgiVerificationResponseResolvers<ContextType>
  AdminRAAuditResponse?: AdminRaAuditResponseResolvers<ContextType>
  AdminReauditKYCResponse?: AdminReauditKycResponseResolvers<ContextType>
  AdminRecoverKGIStatusResponse?: AdminRecoverKgiStatusResponseResolvers<ContextType>
  AdminRefreshMerchantResponse?: AdminRefreshMerchantResponseResolvers<ContextType>
  AdminRejectFiatWithdrawalResponse?: AdminRejectFiatWithdrawalResponseResolvers<ContextType>
  AdminRejectKGIVerificationResponse?: AdminRejectKgiVerificationResponseResolvers<ContextType>
  AdminRejectVirtualAccountVerificationResponse?: AdminRejectVirtualAccountVerificationResponseResolvers<ContextType>
  AdminResendAMLWithdrawInfoP01Response?: AdminResendAmlWithdrawInfoP01ResponseResolvers<ContextType>
  AdminResendAMLWithdrawInfoP02Response?: AdminResendAmlWithdrawInfoP02ResponseResolvers<ContextType>
  AdminResendFiatWithdrawalResponse?: AdminResendFiatWithdrawalResponseResolvers<ContextType>
  AdminResendKGIVerificationResponse?: AdminResendKgiVerificationResponseResolvers<ContextType>
  AdminResendWithdrawInfoP01Response?: AdminResendWithdrawInfoP01ResponseResolvers<ContextType>
  AdminResendWithdrawInfoP02Response?: AdminResendWithdrawInfoP02ResponseResolvers<ContextType>
  AdminResetKYCVerificationResponse?: AdminResetKycVerificationResponseResolvers<ContextType>
  AdminResetPhoneResponse?: AdminResetPhoneResponseResolvers<ContextType>
  AdminSendBGWithdrawInfoResponse?: AdminSendBgWithdrawInfoResponseResolvers<ContextType>
  AdminSendInvestorsSettlementResponse?: AdminSendInvestorsSettlementResponseResolvers<ContextType>
  AdminSendMemberInfoResponse?: AdminSendMemberInfoResponseResolvers<ContextType>
  AdminSendWithdrawInfoP01Response?: AdminSendWithdrawInfoP01ResponseResolvers<ContextType>
  AdminSendWithdrawInfoP02Response?: AdminSendWithdrawInfoP02ResponseResolvers<ContextType>
  AdminSetOlderUserResponse?: AdminSetOlderUserResponseResolvers<ContextType>
  AdminSetTradePermissionResponse?: AdminSetTradePermissionResponseResolvers<ContextType>
  AdminSubmitAMLVerificationResponse?: AdminSubmitAmlVerificationResponseResolvers<ContextType>
  AdminSubmitKYCOneVerificationResponse?: AdminSubmitKycOneVerificationResponseResolvers<ContextType>
  AdminSubmitKYCTwoVerificationResponse?: AdminSubmitKycTwoVerificationResponseResolvers<ContextType>
  AdminSubmitRAAuditResponse?: AdminSubmitRaAuditResponseResolvers<ContextType>
  AdminSystemAuditAMLResponse?: AdminSystemAuditAmlResponseResolvers<ContextType>
  AdminTradeResponse?: AdminTradeResponseResolvers<ContextType>
  AdminTradingReportResponse?: AdminTradingReportResponseResolvers<ContextType>
  AdminUpdateCoinInfoResponse?: AdminUpdateCoinInfoResponseResolvers<ContextType>
  AdminUpdateMarketInfoResponse?: AdminUpdateMarketInfoResponseResolvers<ContextType>
  AdminUpdateOAuthClientResponse?: AdminUpdateOAuthClientResponseResolvers<ContextType>
  AdminUpdateQuotationInfoResponse?: AdminUpdateQuotationInfoResponseResolvers<ContextType>
  AdminUploadSuspiciousTransactionResponse?: AdminUploadSuspiciousTransactionResponseResolvers<ContextType>
  AdminUploadVerificationFileResponse?: AdminUploadVerificationFileResponseResolvers<ContextType>
  AdminUserResponse?: AdminUserResponseResolvers<ContextType>
  AdminVIPUpdateResponse?: AdminVipUpdateResponseResolvers<ContextType>
  AdminVerifyKYCIdentityResponse?: AdminVerifyKycIdentityResponseResolvers<ContextType>
  AdminVoucherRecordsResponse?: AdminVoucherRecordsResponseResolvers<ContextType>
  AdminWithdrawalReportResponse?: AdminWithdrawalReportResponseResolvers<ContextType>
  AdminWithdrawalResponse?: AdminWithdrawalResponseResolvers<ContextType>
  ApproveKYCLv2Response?: ApproveKycLv2ResponseResolvers<ContextType>
  Asset?: AssetResolvers<ContextType>
  AssetCollection?: AssetCollectionResolvers<ContextType>
  AssetLinkingCollections?: AssetLinkingCollectionsResolvers<ContextType>
  Attributes?: AttributesResolvers<ContextType>
  Auth2FA?: Auth2FaResolvers<ContextType>
  AuthBind2FAResponse?: AuthBind2FaResponseResolvers<ContextType>
  AuthConfirm2FAResponse?: AuthConfirm2FaResponseResolvers<ContextType>
  AuthMutation?: AuthMutationResolvers<ContextType>
  AuthUnbind2FAResponse?: AuthUnbind2FaResponseResolvers<ContextType>
  Balance?: BalanceResolvers<ContextType>
  Bank?: BankResolvers<ContextType>
  BankAccount?: BankAccountResolvers<ContextType>
  BankBranch?: BankBranchResolvers<ContextType>
  BankInfo?: BankInfoResolvers<ContextType>
  BaseDeposit?: BaseDepositResolvers<ContextType>
  BaseResponse?: BaseResponseResolvers<ContextType>
  BaseWithdrawal?: BaseWithdrawalResolvers<ContextType>
  BasicInfo?: BasicInfoResolvers<ContextType>
  CefiDepositAddress?: CefiDepositAddressResolvers<ContextType>
  CefiWallet?: CefiWalletResolvers<ContextType>
  ChartResponse?: ChartResponseResolvers<ContextType>
  CoinPrice?: CoinPriceResolvers<ContextType>
  CommonAddress?: CommonAddressResolvers<ContextType>
  CommonAddressResponse?: CommonAddressResponseResolvers<ContextType>
  Confirm2FAData?: Confirm2FaDataResolvers<ContextType>
  ContentfulMetadata?: ContentfulMetadataResolvers<ContextType>
  ContentfulTag?: ContentfulTagResolvers<ContextType>
  Country?: CountryResolvers<ContextType>
  Creator?: CreatorResolvers<ContextType>
  CryptoAsset?: CryptoAssetResolvers<ContextType>
  CryptoDeposit?: CryptoDepositResolvers<ContextType>
  CryptoWithdrawal?: CryptoWithdrawalResolvers<ContextType>
  CurrencyAmount?: CurrencyAmountResolvers<ContextType>
  CurrencyBalance?: CurrencyBalanceResolvers<ContextType>
  CurrencyBalances?: CurrencyBalancesResolvers<ContextType>
  CurrencyInfo?: CurrencyInfoResolvers<ContextType>
  DateTime?: GraphQLScalarType
  DefiWallet?: DefiWalletResolvers<ContextType>
  Deposit?: DepositResolvers<ContextType>
  DepositResponse?: DepositResponseResolvers<ContextType>
  DevMutation?: DevMutationResolvers<ContextType>
  Dimension?: GraphQLScalarType
  DisplayItem?: DisplayItemResolvers<ContextType>
  DisplayItemCollection?: DisplayItemCollectionResolvers<ContextType>
  DisplayItemForUsersCollection?: DisplayItemForUsersCollectionResolvers<ContextType>
  DisplayItemLinkingCollections?: DisplayItemLinkingCollectionsResolvers<ContextType>
  Email?: GraphQLScalarType
  Entry?: EntryResolvers<ContextType>
  EntryCollection?: EntryCollectionResolvers<ContextType>
  ExternalAPIKey?: ExternalApiKeyResolvers<ContextType>
  ExternalAPIKeyWithdrawal?: ExternalApiKeyWithdrawalResolvers<ContextType>
  FAASPayment?: FaasPaymentResolvers<ContextType>
  FAASPaymentResponse?: FaasPaymentResponseResolvers<ContextType>
  FiatDeposit?: FiatDepositResolvers<ContextType>
  FiatDepositInfo?: FiatDepositInfoResolvers<ContextType>
  FiatWithdrawal?: FiatWithdrawalResolvers<ContextType>
  FrontendVersion?: FrontendVersionResolvers<ContextType>
  GimCoolPayPaymentInfo?: GimCoolPayPaymentInfoResolvers<ContextType>
  GimCoolPayPriceResponse?: GimCoolPayPriceResponseResolvers<ContextType>
  GimCoolPayReadyToPurchasePrice?: GimCoolPayReadyToPurchasePriceResolvers<ContextType>
  HeroSlider?: HeroSliderResolvers<ContextType>
  HeroSliderCollection?: HeroSliderCollectionResolvers<ContextType>
  HeroSliderLinkingCollections?: HeroSliderLinkingCollectionsResolvers<ContextType>
  HexColor?: GraphQLScalarType
  Holiday?: HolidayResolvers<ContextType>
  IdentityInfo?: IdentityInfoResolvers<ContextType>
  InternalTransferIn?: InternalTransferInResolvers<ContextType>
  InternalTransferOut?: InternalTransferOutResolvers<ContextType>
  JSON?: GraphQLScalarType
  KYC?: KycResolvers<ContextType>
  KYCConfirmSubmissionResponse?: KycConfirmSubmissionResponseResolvers<ContextType>
  KYCLevelOne?: KycLevelOneResolvers<ContextType>
  KYCLevelTwo?: KycLevelTwoResolvers<ContextType>
  KYCMutation?: KycMutationResolvers<ContextType>
  KYCPhoto?: KycPhotoResolvers<ContextType>
  KYCPhotoVerification?: KycPhotoVerificationResolvers<ContextType>
  KYCSubmitLevelOneResponse?: KycSubmitLevelOneResponseResolvers<ContextType>
  KYCSubmitLevelTwoResponse?: KycSubmitLevelTwoResponseResolvers<ContextType>
  KgiUploadResponse?: KgiUploadResponseResolvers<ContextType>
  LandingPageCustomMessage?: LandingPageCustomMessageResolvers<ContextType>
  LandingPageCustomMessageCollection?: LandingPageCustomMessageCollectionResolvers<ContextType>
  LandingPageCustomMessageCustomMessage?: LandingPageCustomMessageCustomMessageResolvers<ContextType>
  LandingPageCustomMessageCustomMessageAssets?: LandingPageCustomMessageCustomMessageAssetsResolvers<ContextType>
  LandingPageCustomMessageCustomMessageEntries?: LandingPageCustomMessageCustomMessageEntriesResolvers<ContextType>
  LandingPageCustomMessageCustomMessageLinks?: LandingPageCustomMessageCustomMessageLinksResolvers<ContextType>
  LandingPageCustomMessageCustomMessageResources?: LandingPageCustomMessageCustomMessageResourcesResolvers<ContextType>
  LandingPageCustomMessageLinkingCollections?: LandingPageCustomMessageLinkingCollectionsResolvers<ContextType>
  Ledger?: LedgerResolvers<ContextType>
  LedgerEntry?: LedgerEntryResolvers<ContextType>
  LedgerResponse?: LedgerResponseResolvers<ContextType>
  LoginInfo?: LoginInfoResolvers<ContextType>
  LoginResponse?: LoginResponseResolvers<ContextType>
  MarketInfo?: MarketInfoResolvers<ContextType>
  MarketPrice?: MarketPriceResolvers<ContextType>
  Me?: MeResolvers<ContextType>
  MetaPagination?: MetaPaginationResolvers<ContextType>
  Metric?: MetricResolvers<ContextType>
  MetricPosition?: MetricPositionResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  NFTCollections?: NftCollectionsResolvers<ContextType>
  NFTMerkleTreeHexProof?: NftMerkleTreeHexProofResolvers<ContextType>
  NFTWhitelistInfoByPhrase?: NftWhitelistInfoByPhraseResolvers<ContextType>
  Notification?: NotificationResolvers<ContextType>
  NotificationCount?: NotificationCountResolvers<ContextType>
  NotificationCountResponse?: NotificationCountResponseResolvers<ContextType>
  NotificationInfo?: NotificationInfoResolvers<ContextType>
  NotificationListResponse?: NotificationListResponseResolvers<ContextType>
  NotificationMutation?: NotificationMutationResolvers<ContextType>
  NotificationReadResponse?: NotificationReadResponseResolvers<ContextType>
  Number?: GraphQLScalarType
  OnHoldKGIVerification?: OnHoldKgiVerificationResolvers<ContextType>
  Point?: PointResolvers<ContextType>
  PointLedgerRecord?: PointLedgerRecordResolvers<ContextType>
  PointLedgerRecordResponse?: PointLedgerRecordResponseResolvers<ContextType>
  PointMutation?: PointMutationResolvers<ContextType>
  PointPurchaseResponse?: PointPurchaseResponseResolvers<ContextType>
  PopUp?: PopUpResolvers<ContextType>
  PopUpCollection?: PopUpCollectionResolvers<ContextType>
  PopUpContentArea?: PopUpContentAreaResolvers<ContextType>
  PopUpContentAreaAssets?: PopUpContentAreaAssetsResolvers<ContextType>
  PopUpContentAreaEntries?: PopUpContentAreaEntriesResolvers<ContextType>
  PopUpContentAreaLinks?: PopUpContentAreaLinksResolvers<ContextType>
  PopUpContentAreaResources?: PopUpContentAreaResourcesResolvers<ContextType>
  PopUpLinkingCollections?: PopUpLinkingCollectionsResolvers<ContextType>
  PositionManagerInfo?: PositionManagerInfoResolvers<ContextType>
  Public?: PublicResolvers<ContextType>
  Quality?: GraphQLScalarType
  Query?: QueryResolvers<ContextType>
  Question?: QuestionResolvers<ContextType>
  QuestionInfo?: QuestionInfoResolvers<ContextType>
  QuestionOption?: QuestionOptionResolvers<ContextType>
  Questionnaire?: QuestionnaireResolvers<ContextType>
  Quotation?: QuotationResolvers<ContextType>
  RateHistorical?: RateHistoricalResolvers<ContextType>
  RejectFiatWithdrawalResponse?: RejectFiatWithdrawalResponseResolvers<ContextType>
  Release?: ReleaseResolvers<ContextType>
  ReleaseCollection?: ReleaseCollectionResolvers<ContextType>
  ReleaseLinkingCollections?: ReleaseLinkingCollectionsResolvers<ContextType>
  ReleaseNote?: ReleaseNoteResolvers<ContextType>
  ReleaseNoteCollection?: ReleaseNoteCollectionResolvers<ContextType>
  ReleaseNoteLinkingCollections?: ReleaseNoteLinkingCollectionsResolvers<ContextType>
  ReleaseNoteReleaseNote?: ReleaseNoteReleaseNoteResolvers<ContextType>
  ReleaseNoteReleaseNoteAssets?: ReleaseNoteReleaseNoteAssetsResolvers<ContextType>
  ReleaseNoteReleaseNoteEntries?: ReleaseNoteReleaseNoteEntriesResolvers<ContextType>
  ReleaseNoteReleaseNoteLinks?: ReleaseNoteReleaseNoteLinksResolvers<ContextType>
  ReleaseNoteReleaseNoteResources?: ReleaseNoteReleaseNoteResourcesResolvers<ContextType>
  ReleaseNotesCollection?: ReleaseNotesCollectionResolvers<ContextType>
  ResourceConfirmUploadResponse?: ResourceConfirmUploadResponseResolvers<ContextType>
  ResourceLink?: ResourceLinkResolvers<ContextType>
  ResourceMutation?: ResourceMutationResolvers<ContextType>
  ResourceRequestUploadURLResponse?: ResourceRequestUploadUrlResponseResolvers<ContextType>
  ResourceSys?: ResourceSysResolvers<ContextType>
  Response2FA?: Response2FaResolvers<ContextType>
  ReyieldAttributes?: ReyieldAttributesResolvers<ContextType>
  ReyieldBalance?: ReyieldBalanceResolvers<ContextType>
  ReyieldCefiDepositAddress?: ReyieldCefiDepositAddressResolvers<ContextType>
  ReyieldCefiWallet?: ReyieldCefiWalletResolvers<ContextType>
  ReyieldCoinPrice?: ReyieldCoinPriceResolvers<ContextType>
  ReyieldCreator?: ReyieldCreatorResolvers<ContextType>
  ReyieldCurrencyBalances?: ReyieldCurrencyBalancesResolvers<ContextType>
  ReyieldCurrencyInfo?: ReyieldCurrencyInfoResolvers<ContextType>
  ReyieldDefiWallet?: ReyieldDefiWalletResolvers<ContextType>
  ReyieldExternalAPIKey?: ReyieldExternalApiKeyResolvers<ContextType>
  ReyieldFrontendVersion?: ReyieldFrontendVersionResolvers<ContextType>
  ReyieldGimCoolPayPaymentInfo?: ReyieldGimCoolPayPaymentInfoResolvers<ContextType>
  ReyieldGimCoolPayPriceResponse?: ReyieldGimCoolPayPriceResponseResolvers<ContextType>
  ReyieldGimCoolPayReadyToPurchasePrice?: ReyieldGimCoolPayReadyToPurchasePriceResolvers<ContextType>
  ReyieldLicense?: ReyieldLicenseResolvers<ContextType>
  ReyieldNFTCollections?: ReyieldNftCollectionsResolvers<ContextType>
  ReyieldNFTMerkleTreeHexProof?: ReyieldNftMerkleTreeHexProofResolvers<ContextType>
  ReyieldNFTWhitelistInfoByPhrase?: ReyieldNftWhitelistInfoByPhraseResolvers<ContextType>
  ReyieldRateHistorical?: ReyieldRateHistoricalResolvers<ContextType>
  ReyieldReyieldLicense?: ReyieldReyieldLicenseResolvers<ContextType>
  ReyieldSmartContract?: ReyieldSmartContractResolvers<ContextType>
  ReyieldStrategyInfo?: ReyieldStrategyInfoResolvers<ContextType>
  ReyieldStrategyInfos?: ReyieldStrategyInfosResolvers<ContextType>
  ReyieldStrategyParameters?: ReyieldStrategyParametersResolvers<ContextType>
  ReyieldStrategyPerformance?: ReyieldStrategyPerformanceResolvers<ContextType>
  ReyieldStrategyProgress?: ReyieldStrategyProgressResolvers<ContextType>
  ReyieldStrategyProgressStep?: ReyieldStrategyProgressStepResolvers<ContextType>
  ReyieldStrategyStartResponse?: ReyieldStrategyStartResponseResolvers<ContextType>
  ReyieldStrategyStopResponse?: ReyieldStrategyStopResponseResolvers<ContextType>
  ReyieldStrategyUpdateResponse?: ReyieldStrategyUpdateResponseResolvers<ContextType>
  ReyieldTokenBalance?: ReyieldTokenBalanceResolvers<ContextType>
  ReyieldTokenDisplayInfo?: ReyieldTokenDisplayInfoResolvers<ContextType>
  ReyieldToolInfo?: ReyieldToolInfoResolvers<ContextType>
  ReyieldTotalProfits?: ReyieldTotalProfitsResolvers<ContextType>
  ReyieldUniswapOnchainPositionAmountInfo?: ReyieldUniswapOnchainPositionAmountInfoResolvers<ContextType>
  ReyieldUniswapPoolInfo?: ReyieldUniswapPoolInfoResolvers<ContextType>
  ReyieldUniswapPoolPosition?: ReyieldUniswapPoolPositionResolvers<ContextType>
  ReyieldUniswapPoolTick?: ReyieldUniswapPoolTickResolvers<ContextType>
  ReyieldUniswapQuote?: ReyieldUniswapQuoteResolvers<ContextType>
  ReyieldUniswapToolFeeTier?: ReyieldUniswapToolFeeTierResolvers<ContextType>
  ReyieldUniswapToolInfo?: ReyieldUniswapToolInfoResolvers<ContextType>
  ReyieldUniswapToolTag?: ReyieldUniswapToolTagResolvers<ContextType>
  ReyieldUniswapToolToken?: ReyieldUniswapToolTokenResolvers<ContextType>
  ReyieldUniswapToolTokenPriceChart?: ReyieldUniswapToolTokenPriceChartResolvers<ContextType>
  ReyieldUniswapToolTokenPriceChartPrice?: ReyieldUniswapToolTokenPriceChartPriceResolvers<ContextType>
  ReyieldUniswapV3BacktestResult?: ReyieldUniswapV3BacktestResultResolvers<ContextType>
  ReyieldUserInfo?: ReyieldUserInfoResolvers<ContextType>
  ReyieldUserStrategyInfo?: ReyieldUserStrategyInfoResolvers<ContextType>
  ReyieldUserStrategyParameters?: ReyieldUserStrategyParametersResolvers<ContextType>
  ReyieldUserStrategyPerformance?: ReyieldUserStrategyPerformanceResolvers<ContextType>
  ReyieldUserStrategyProfit?: ReyieldUserStrategyProfitResolvers<ContextType>
  ReyieldUserStrategyRecord?: ReyieldUserStrategyRecordResolvers<ContextType>
  ReyieldUserToolInfo?: ReyieldUserToolInfoResolvers<ContextType>
  ReyieldUserUniswapOnchainPositionInfo?: ReyieldUserUniswapOnchainPositionInfoResolvers<ContextType>
  ReyieldUserUniswapPositionInfo?: ReyieldUserUniswapPositionInfoResolvers<ContextType>
  ReyieldUserUniswapToolOutOfRangeInfo?: ReyieldUserUniswapToolOutOfRangeInfoResolvers<ContextType>
  ReyieldUserUniswapToolSettingInfo?: ReyieldUserUniswapToolSettingInfoResolvers<ContextType>
  ReyieldUserUniswapv3PositionProgress?: ReyieldUserUniswapv3PositionProgressResolvers<ContextType>
  ReyieldUserUniswapv3PositionProgressStep?: ReyieldUserUniswapv3PositionProgressStepResolvers<ContextType>
  ReyieldWalletBalances?: ReyieldWalletBalancesResolvers<ContextType>
  ReyieldaICompletionRecords?: ReyieldaICompletionRecordsResolvers<ContextType>
  ReyieldsuggestStrategyToolWithAllocations?: ReyieldsuggestStrategyToolWithAllocationsResolvers<ContextType>
  ReyieldsuggestStrategyToolWithAllocations_aggregate?: ReyieldsuggestStrategyToolWithAllocations_AggregateResolvers<ContextType>
  ReyieldsuggestStrategyToolWithAllocations_aggregate_fields?: ReyieldsuggestStrategyToolWithAllocations_Aggregate_FieldsResolvers<ContextType>
  ReyieldsuggestStrategyToolWithAllocations_max_fields?: ReyieldsuggestStrategyToolWithAllocations_Max_FieldsResolvers<ContextType>
  ReyieldsuggestStrategyToolWithAllocations_min_fields?: ReyieldsuggestStrategyToolWithAllocations_Min_FieldsResolvers<ContextType>
  Section?: SectionResolvers<ContextType>
  SectionCollection?: SectionCollectionResolvers<ContextType>
  SectionContent?: SectionContentResolvers<ContextType>
  SectionContentCollection?: SectionContentCollectionResolvers<ContextType>
  SectionContentDescription?: SectionContentDescriptionResolvers<ContextType>
  SectionContentDescriptionAssets?: SectionContentDescriptionAssetsResolvers<ContextType>
  SectionContentDescriptionEntries?: SectionContentDescriptionEntriesResolvers<ContextType>
  SectionContentDescriptionLinks?: SectionContentDescriptionLinksResolvers<ContextType>
  SectionContentDescriptionResources?: SectionContentDescriptionResourcesResolvers<ContextType>
  SectionContentLinkingCollections?: SectionContentLinkingCollectionsResolvers<ContextType>
  SectionLinkingCollections?: SectionLinkingCollectionsResolvers<ContextType>
  SectionSectionContentsCollection?: SectionSectionContentsCollectionResolvers<ContextType>
  SmartContract?: SmartContractResolvers<ContextType>
  SpecialUser?: SpecialUserResolvers<ContextType>
  SpecialUserCollection?: SpecialUserCollectionResolvers<ContextType>
  SpecialUserLinkingCollections?: SpecialUserLinkingCollectionsResolvers<ContextType>
  StrategyInfo?: StrategyInfoResolvers<ContextType>
  StrategyInfos?: StrategyInfosResolvers<ContextType>
  StrategyMeta?: StrategyMetaResolvers<ContextType>
  StrategyMetaCollection?: StrategyMetaCollectionResolvers<ContextType>
  StrategyMetaLinkingCollections?: StrategyMetaLinkingCollectionsResolvers<ContextType>
  StrategyMetaSectionsCollection?: StrategyMetaSectionsCollectionResolvers<ContextType>
  StrategyParameters?: StrategyParametersResolvers<ContextType>
  StrategyPerformance?: StrategyPerformanceResolvers<ContextType>
  StrategyProgress?: StrategyProgressResolvers<ContextType>
  StrategyProgressStep?: StrategyProgressStepResolvers<ContextType>
  StrategyStartResponse?: StrategyStartResponseResolvers<ContextType>
  StrategyStopResponse?: StrategyStopResponseResolvers<ContextType>
  StrategyUpdateResponse?: StrategyUpdateResponseResolvers<ContextType>
  Strategy_Action?: Strategy_ActionResolvers<ContextType>
  Strategy_ActionDEFIApproveSwapReward?: Strategy_ActionDefiApproveSwapRewardResolvers<ContextType>
  Strategy_ActionDEFIDeductTxnFee?: Strategy_ActionDefiDeductTxnFeeResolvers<ContextType>
  Strategy_ActionDEFIDispatchUSDTReward?: Strategy_ActionDefiDispatchUsdtRewardResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVAddLiquidity?: Strategy_ActionDefimim3CrvAddLiquidityResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVApproveConvexBooster?: Strategy_ActionDefimim3CrvApproveConvexBoosterResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVApproveCurve3Pool?: Strategy_ActionDefimim3CrvApproveCurve3PoolResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVClaimReward?: Strategy_ActionDefimim3CrvClaimRewardResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVDepositAll?: Strategy_ActionDefimim3CrvDepositAllResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVRemoveLiquidity?: Strategy_ActionDefimim3CrvRemoveLiquidityResolvers<ContextType>
  Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap?: Strategy_ActionDefimim3CrvWithdrawAndUnwrapResolvers<ContextType>
  Strategy_ActionDEFIMIMUSDTApproveCurve3Pool?: Strategy_ActionDefimimusdtApproveCurve3PoolResolvers<ContextType>
  Strategy_ActionDEFIRebalanceTxnFeeEstimation?: Strategy_ActionDefiRebalanceTxnFeeEstimationResolvers<ContextType>
  Strategy_ActionDEFIStakePool?: Strategy_ActionDefiStakePoolResolvers<ContextType>
  Strategy_ActionDEFISwapETHToUSDT?: Strategy_ActionDefiSwapEthToUsdtResolvers<ContextType>
  Strategy_ActionDEFISwapReward?: Strategy_ActionDefiSwapRewardResolvers<ContextType>
  Strategy_ActionDEFITransferUserLPBalanceToPool?: Strategy_ActionDefiTransferUserLpBalanceToPoolResolvers<ContextType>
  Strategy_ActionDEFIUnStakePool?: Strategy_ActionDefiUnStakePoolResolvers<ContextType>
  Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity?: Strategy_ActionDefiUpdatePoolBalanceAfterAddLiquidityResolvers<ContextType>
  Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward?: Strategy_ActionDefiUpdatePoolBalanceAfterClaimRewardResolvers<ContextType>
  Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity?: Strategy_ActionDefiUpdatePoolBalanceAfterRmLiquidityResolvers<ContextType>
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT?: Strategy_ActionDefiUpdatePoolBalanceAfterSwapEthToUsdtResolvers<ContextType>
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward?: Strategy_ActionDefiUpdatePoolBalanceAfterSwapRewardResolvers<ContextType>
  Strategy_ActionDEFIUpdateUserRecordStatus?: Strategy_ActionDefiUpdateUserRecordStatusResolvers<ContextType>
  Strategy_ActionExternalDepositCheck?: Strategy_ActionExternalDepositCheckResolvers<ContextType>
  Strategy_ActionExternalSwap?: Strategy_ActionExternalSwapResolvers<ContextType>
  Strategy_ActionExternalWalletTransfer?: Strategy_ActionExternalWalletTransferResolvers<ContextType>
  Strategy_ActionExternalWithdrawal?: Strategy_ActionExternalWithdrawalResolvers<ContextType>
  Strategy_ActionInternalDepositCheck?: Strategy_ActionInternalDepositCheckResolvers<ContextType>
  Strategy_ActionInternalTrade?: Strategy_ActionInternalTradeResolvers<ContextType>
  Strategy_ActionInternalWithdrawal?: Strategy_ActionInternalWithdrawalResolvers<ContextType>
  Strategy_ActionLog?: Strategy_ActionLogResolvers<ContextType>
  Strategy_ActionStrategyBatchStart?: Strategy_ActionStrategyBatchStartResolvers<ContextType>
  Strategy_ActionStrategyBatchStop?: Strategy_ActionStrategyBatchStopResolvers<ContextType>
  Strategy_ActionStrategyBatchUpdate?: Strategy_ActionStrategyBatchUpdateResolvers<ContextType>
  Strategy_ActionStrategyStart?: Strategy_ActionStrategyStartResolvers<ContextType>
  Strategy_ActionStrategyStop?: Strategy_ActionStrategyStopResolvers<ContextType>
  Strategy_ActionStrategyUpdate?: Strategy_ActionStrategyUpdateResolvers<ContextType>
  Strategy_ActionTransferFromDEFIWalletPostProcessing?: Strategy_ActionTransferFromDefiWalletPostProcessingResolvers<ContextType>
  Strategy_ActionTransferFromDEFIWalletPreProcessing?: Strategy_ActionTransferFromDefiWalletPreProcessingResolvers<ContextType>
  Strategy_ActionTransferFromDEFIWalletProcessing?: Strategy_ActionTransferFromDefiWalletProcessingResolvers<ContextType>
  Strategy_ActionTransferToDEFIWalletPostProcessing?: Strategy_ActionTransferToDefiWalletPostProcessingResolvers<ContextType>
  Strategy_ActionTransferToDEFIWalletPreProcessing?: Strategy_ActionTransferToDefiWalletPreProcessingResolvers<ContextType>
  Strategy_ActionTransferToDEFIWalletProcessing?: Strategy_ActionTransferToDefiWalletProcessingResolvers<ContextType>
  Strategy_ActionWatchTxnStatus?: Strategy_ActionWatchTxnStatusResolvers<ContextType>
  Strategy_Admin?: Strategy_AdminResolvers<ContextType>
  Strategy_AdminMutation?: Strategy_AdminMutationResolvers<ContextType>
  Strategy_ArbitrageState?: Strategy_ArbitrageStateResolvers<ContextType>
  Strategy_Balance?: Strategy_BalanceResolvers<ContextType>
  Strategy_CurrencyAmount?: Strategy_CurrencyAmountResolvers<ContextType>
  Strategy_DailyProfit?: Strategy_DailyProfitResolvers<ContextType>
  Strategy_DebtRecord?: Strategy_DebtRecordResolvers<ContextType>
  Strategy_EstimatePointsConsumingResult?: Strategy_EstimatePointsConsumingResultResolvers<ContextType>
  Strategy_EstimateStrategyRequiredAmountResponse?: Strategy_EstimateStrategyRequiredAmountResponseResolvers<ContextType>
  Strategy_ExchangeAsset?: Strategy_ExchangeAssetResolvers<ContextType>
  Strategy_ExchangeWithdrawalAuthorizationResponse?: Strategy_ExchangeWithdrawalAuthorizationResponseResolvers<ContextType>
  Strategy_ExchangesNetAssetChart?: Strategy_ExchangesNetAssetChartResolvers<ContextType>
  Strategy_ExchangesNetAssetChartData?: Strategy_ExchangesNetAssetChartDataResolvers<ContextType>
  Strategy_ExternalAPIKey?: Strategy_ExternalApiKeyResolvers<ContextType>
  Strategy_ExternalAPIKeyMutation?: Strategy_ExternalApiKeyMutationResolvers<ContextType>
  Strategy_ExternalAPIKeyWithdrawal?: Strategy_ExternalApiKeyWithdrawalResolvers<ContextType>
  Strategy_GenericFlowMutation?: Strategy_GenericFlowMutationResolvers<ContextType>
  Strategy_LendingOffer?: Strategy_LendingOfferResolvers<ContextType>
  Strategy_LendingState?: Strategy_LendingStateResolvers<ContextType>
  Strategy_Number?: GraphQLScalarType
  Strategy_Performance?: Strategy_PerformanceResolvers<ContextType>
  Strategy_Public?: Strategy_PublicResolvers<ContextType>
  Strategy_Strategy?: Strategy_StrategyResolvers<ContextType>
  Strategy_StrategyDebt?: Strategy_StrategyDebtResolvers<ContextType>
  Strategy_StrategyDescription?: Strategy_StrategyDescriptionResolvers<ContextType>
  Strategy_StrategyInfo?: Strategy_StrategyInfoResolvers<ContextType>
  Strategy_StrategyMutation?: Strategy_StrategyMutationResolvers<ContextType>
  Strategy_StrategyParameter?: Strategy_StrategyParameterResolvers<ContextType>
  Strategy_StrategyParameterDesc?: Strategy_StrategyParameterDescResolvers<ContextType>
  Strategy_StrategyPerformance?: Strategy_StrategyPerformanceResolvers<ContextType>
  Strategy_StrategyState?: Strategy_StrategyStateResolvers<ContextType>
  Strategy_Time?: GraphQLScalarType
  Strategy_User?: Strategy_UserResolvers<ContextType>
  Strategy_UserMutation?: Strategy_UserMutationResolvers<ContextType>
  Strategy_UserProfit?: Strategy_UserProfitResolvers<ContextType>
  Strategy_Workflow?: Strategy_WorkflowResolvers<ContextType>
  Strategy_YieldRecord?: Strategy_YieldRecordResolvers<ContextType>
  Sys?: SysResolvers<ContextType>
  Time?: GraphQLScalarType
  TokenBalance?: TokenBalanceResolvers<ContextType>
  TokenDisplayInfo?: TokenDisplayInfoResolvers<ContextType>
  ToolInfo?: ToolInfoResolvers<ContextType>
  TotalProfits?: TotalProfitsResolvers<ContextType>
  Trade?: TradeResolvers<ContextType>
  TradeResponse?: TradeResponseResolvers<ContextType>
  Trading?: TradingResolvers<ContextType>
  TradingConfirmQuotationResponse?: TradingConfirmQuotationResponseResolvers<ContextType>
  TradingMutation?: TradingMutationResolvers<ContextType>
  TradingQuotationResponse?: TradingQuotationResponseResolvers<ContextType>
  TradingReportResponse?: TradingReportResponseResolvers<ContextType>
  UniswapOnchainPositionAmountInfo?: UniswapOnchainPositionAmountInfoResolvers<ContextType>
  UniswapPoolInfo?: UniswapPoolInfoResolvers<ContextType>
  UniswapPoolPosition?: UniswapPoolPositionResolvers<ContextType>
  UniswapPoolTick?: UniswapPoolTickResolvers<ContextType>
  UniswapQuote?: UniswapQuoteResolvers<ContextType>
  UniswapToolFeeTier?: UniswapToolFeeTierResolvers<ContextType>
  UniswapToolInfo?: UniswapToolInfoResolvers<ContextType>
  UniswapToolTag?: UniswapToolTagResolvers<ContextType>
  UniswapToolToken?: UniswapToolTokenResolvers<ContextType>
  UniswapToolTokenPriceChart?: UniswapToolTokenPriceChartResolvers<ContextType>
  UniswapToolTokenPriceChartPrice?: UniswapToolTokenPriceChartPriceResolvers<ContextType>
  UniswapV3BacktestResult?: UniswapV3BacktestResultResolvers<ContextType>
  User?: UserResolvers<ContextType>
  UserActionLog?: UserActionLogResolvers<ContextType>
  UserActionLogResponse?: UserActionLogResponseResolvers<ContextType>
  UserAgreeResponse?: UserAgreeResponseResolvers<ContextType>
  UserBindPhoneConfirmationResponse?: UserBindPhoneConfirmationResponseResolvers<ContextType>
  UserBindPhoneResponse?: UserBindPhoneResponseResolvers<ContextType>
  UserCancelOAuthResponse?: UserCancelOAuthResponseResolvers<ContextType>
  UserChangePasswordResponse?: UserChangePasswordResponseResolvers<ContextType>
  UserCustomizeReferralResponse?: UserCustomizeReferralResponseResolvers<ContextType>
  UserDeleteBankAccountResponse?: UserDeleteBankAccountResponseResolvers<ContextType>
  UserDeviceTokenResponse?: UserDeviceTokenResponseResolvers<ContextType>
  UserFillReferrerCodeResponse?: UserFillReferrerCodeResponseResolvers<ContextType>
  UserForgotPasswordConfirmResponse?: UserForgotPasswordConfirmResponseResolvers<ContextType>
  UserForgotPasswordResponse?: UserForgotPasswordResponseResolvers<ContextType>
  UserInfo?: UserInfoResolvers<ContextType>
  UserLoginRecord?: UserLoginRecordResolvers<ContextType>
  UserLoginRecordsResponse?: UserLoginRecordsResponseResolvers<ContextType>
  UserLoginResponse?: UserLoginResponseResolvers<ContextType>
  UserMutation?: UserMutationResolvers<ContextType>
  UserOAuthClient?: UserOAuthClientResolvers<ContextType>
  UserOAuthClientResponse?: UserOAuthClientResponseResolvers<ContextType>
  UserPreference?: UserPreferenceResolvers<ContextType>
  UserPreferenceResponse?: UserPreferenceResponseResolvers<ContextType>
  UserReferral?: UserReferralResolvers<ContextType>
  UserRefreshTokenResponse?: UserRefreshTokenResponseResolvers<ContextType>
  UserRegisterConfirmResponse?: UserRegisterConfirmResponseResolvers<ContextType>
  UserRegisterResponse?: UserRegisterResponseResolvers<ContextType>
  UserRequestAccountDisableResponse?: UserRequestAccountDisableResponseResolvers<ContextType>
  UserResendForgotPasswordResponse?: UserResendForgotPasswordResponseResolvers<ContextType>
  UserResendRegisterResponse?: UserResendRegisterResponseResolvers<ContextType>
  UserStrategyInfo?: UserStrategyInfoResolvers<ContextType>
  UserStrategyParameters?: UserStrategyParametersResolvers<ContextType>
  UserStrategyPerformance?: UserStrategyPerformanceResolvers<ContextType>
  UserStrategyProfit?: UserStrategyProfitResolvers<ContextType>
  UserStrategyRecord?: UserStrategyRecordResolvers<ContextType>
  UserToolInfo?: UserToolInfoResolvers<ContextType>
  UserUniswapOnchainPositionInfo?: UserUniswapOnchainPositionInfoResolvers<ContextType>
  UserUniswapPositionInfo?: UserUniswapPositionInfoResolvers<ContextType>
  UserUniswapToolOutOfRangeInfo?: UserUniswapToolOutOfRangeInfoResolvers<ContextType>
  UserUniswapToolSettingInfo?: UserUniswapToolSettingInfoResolvers<ContextType>
  UserUniswapv3PositionProgress?: UserUniswapv3PositionProgressResolvers<ContextType>
  UserUniswapv3PositionProgressStep?: UserUniswapv3PositionProgressStepResolvers<ContextType>
  VersionInfo?: VersionInfoResolvers<ContextType>
  Voucher?: VoucherResolvers<ContextType>
  VoucherMutation?: VoucherMutationResolvers<ContextType>
  VoucherRecord?: VoucherRecordResolvers<ContextType>
  VoucherUseResponse?: VoucherUseResponseResolvers<ContextType>
  Wallet?: WalletResolvers<ContextType>
  WalletBalances?: WalletBalancesResolvers<ContextType>
  WalletCreateCommonAddressResponse?: WalletCreateCommonAddressResponseResolvers<ContextType>
  WalletCreateCryptoAddressResponse?: WalletCreateCryptoAddressResponseResolvers<ContextType>
  WalletCryptoWithdrawResponse?: WalletCryptoWithdrawResponseResolvers<ContextType>
  WalletDeleteCommonAddressResponse?: WalletDeleteCommonAddressResponseResolvers<ContextType>
  WalletDepositResponse?: WalletDepositResponseResolvers<ContextType>
  WalletFAASPaymentConfirmResponse?: WalletFaasPaymentConfirmResponseResolvers<ContextType>
  WalletFiatWithdrawResponse?: WalletFiatWithdrawResponseResolvers<ContextType>
  WalletMutation?: WalletMutationResolvers<ContextType>
  WalletWithdrawConfirmResponse?: WalletWithdrawConfirmResponseResolvers<ContextType>
  WalletWithdrawalResponse?: WalletWithdrawalResponseResolvers<ContextType>
  WithdrawCryptoResponse?: WithdrawCryptoResponseResolvers<ContextType>
  WithdrawFiatResponse?: WithdrawFiatResponseResolvers<ContextType>
  WithdrawInfo?: WithdrawInfoResolvers<ContextType>
  Withdrawal?: WithdrawalResolvers<ContextType>
  WithdrawalResponse?: WithdrawalResponseResolvers<ContextType>
  aICompletionRecords?: AICompletionRecordsResolvers<ContextType>
  frontendQuery?: FrontendQueryResolvers<ContextType>
  jsonb?: GraphQLScalarType
  mutation_root?: Mutation_RootResolvers<ContextType>
  reyieldMutation?: ReyieldMutationResolvers<ContextType>
  reyieldQuery?: ReyieldQueryResolvers<ContextType>
  strategyMutation?: StrategyMutationResolvers<ContextType>
  strategyQuery?: StrategyQueryResolvers<ContextType>
  suggestStrategyToolWithAllocations?: SuggestStrategyToolWithAllocationsResolvers<ContextType>
  suggestStrategyToolWithAllocations_aggregate?: SuggestStrategyToolWithAllocations_AggregateResolvers<ContextType>
  suggestStrategyToolWithAllocations_aggregate_fields?: SuggestStrategyToolWithAllocations_Aggregate_FieldsResolvers<ContextType>
  suggestStrategyToolWithAllocations_max_fields?: SuggestStrategyToolWithAllocations_Max_FieldsResolvers<ContextType>
  suggestStrategyToolWithAllocations_min_fields?: SuggestStrategyToolWithAllocations_Min_FieldsResolvers<ContextType>
  timestamptz?: GraphQLScalarType
  uuid?: GraphQLScalarType
}
