import { $enum } from 'ts-enum-util'

import {
  EvmChain,
  Exchange,
  GimCoolPayPaymentSupportingCoinType,
  RiskLevel,
  RiskToleranceLevelName,
  StrategyProgressStepType,
  StrategyStatus,
  UniswapToolPositionStatus,
} from '@/generated/graphql'

const UNKNOWN = 'UNKNOWN'

interface MappingType {
  [key: string]: string
}

export const SUPPORTING_CHAINS_IMAGE_MAP: MappingType = {
  [EvmChain.Ethereum]: '/images/ethereum.png',
  ethereum: '/images/ethereum.png',
  [EvmChain.Polygon]: '/images/polygon.png',
  polygon: '/images/polygon.png',
  [EvmChain.Optimism]: '/images/optimism.png',
  optimism: '/images/optimism.png',
  'OP Mainnet': '/images/optimism.png',
  [EvmChain.Arbitrum]: '/images/arbitrum.png',
  'Arbitrum One': '/images/arbitrum.png',
  [EvmChain.Goerli]: '/images/ethereum.png',
  goerli: '/images/ethereum.png',
}

export const getSupportingChainImage = (chain: EvmChain | string) =>
  SUPPORTING_CHAINS_IMAGE_MAP[chain] ?? '/favicon/favicon-16x16.png'

export const SUPPORTING_CHAIN_TRANSLATION_MAP: MappingType = {
  [EvmChain.Ethereum]: 'Ethereum',
  [EvmChain.Polygon]: 'Polygon',
}

export const getSupportingChainsDisplayName = (chain: EvmChain) =>
  SUPPORTING_CHAIN_TRANSLATION_MAP[chain] ?? UNKNOWN

export const supportingChains = $enum(EvmChain)
  .getValues()
  .map((chain) => ({
    label: chain,
    value: chain,
  }))

export const getChainScanUrl = (chain: EvmChain | undefined) => {
  switch (chain) {
    case EvmChain.Ethereum:
      return 'https://etherscan.io/address/'

    case EvmChain.Polygon:
      return 'https://polygonscan.com/address/'

    case EvmChain.Optimism:
      return 'https://optimistic.etherscan.io/address/'

    case EvmChain.Goerli:
      return 'https://goerli.etherscan.io/address/'

    case EvmChain.Arbitrum:
      return 'https://arbiscan.io/address/'
    default:
      return 'https://etherscan.io/address/'
  }
}

export const SUPPORTING_CONNECTOR_IMAGE_MAP: MappingType = {
  MetaMask: '/images/metamask.png',
  okxWallet: '/images/okx.png',
  metaMaskConnecting: '/images/metamask-no-bg.png',
  WalletConnect: '/images/wallet-connect.png',
  walletConnectLegacy: '/images/wallet-connect.png',
  injected: '/images/metamask.png',
}
export const SUPPORTING_CONNECTOR_TRANSLATION_MAP: MappingType = {
  MetaMask: 'MetaMask',
  WalletConnect: 'Wallet Connect',
  WalletConnectLegacy: 'Wallet Connect Legacy',
  injected: 'Injected',
  okxWallet: 'OKX Wallet',
}

export enum WalletAssetType {
  DeFi = 'DeFi',
  CeFi = 'CeFi',
}

export enum StrategyStatusCategory {
  Ongoing = 'ongoing',
  Finished = 'finished',
  None = 'none',
}

export enum CreateToolCategory {
  Created = 'created',
  Listed = 'listed',
}

export enum ListToolCategory {
  Listing = 'listing',
  Delist = 'delist',
}

export enum VestingType {
  Vesting = 'Vesting',
  Finished = 'Finished',
}

export const getSupportingConnectorImage = (connectorName: string) =>
  SUPPORTING_CONNECTOR_IMAGE_MAP[connectorName] ?? UNKNOWN
export const getSupportingConnectorDisplayName = (connectorName: string) =>
  SUPPORTING_CONNECTOR_TRANSLATION_MAP[connectorName] ?? UNKNOWN

export enum LENDING_PARAMETERS_KEY {
  MAX_AMOUNT = 'max_amount',
  KEEP_AMOUNT = 'keep_amount',
  CHAIN = 'chain',
  WITHOUT_TX = 'without_tx',
  TX_HASH = 'tx_hash',
}

export const STRATEGY_PROGRESS_STEP_TYPE_MAP: MappingType = {
  [StrategyProgressStepType.StrategyStart]: 'Enable the tool',
  [StrategyProgressStepType.StrategyUpdate]: 'Update tool',
  [StrategyProgressStepType.StrategyStop]: 'Disable the tool',
  [StrategyProgressStepType.CexSwap]: 'Currency swapping on %{exchange}',
  [StrategyProgressStepType.CexWalletsTransfer]: 'Funds transfer between %{exchange} wallets',
  [StrategyProgressStepType.CexDepositCheck]: 'Transacting to %{exchange}',
  [StrategyProgressStepType.Unknown]: 'Unknown',
}

export const PROCESSING_STATUS = [
  StrategyStatus.WaitForUserAction,
  StrategyStatus.Preparing,
  StrategyStatus.Running,
  StrategyStatus.Stopping,
]

export const PROCESSING_STATUS_DEFI = [
  UniswapToolPositionStatus.Closing,
  UniswapToolPositionStatus.Running,
  UniswapToolPositionStatus.Pending,
  UniswapToolPositionStatus.Unknown,
]
export const FINISHED_STATUS_DEFI = [
  UniswapToolPositionStatus.Closing,
  UniswapToolPositionStatus.Unknown,
  UniswapToolPositionStatus.Closed,
]

export const FINISHED_STATUS = [
  StrategyStatus.Error,
  StrategyStatus.Stopped,
  StrategyStatus.Stopping,
]

export enum NEXT_ACTION_AFTER_CONTRACT_WRITE {
  STRATEGY_START = 'STRATEGY_START',
  STRATEGY_UPDATE = 'STRATEGY_UPDATE',
}

export enum GPT_SWITCH_TYPE {
  REYIELD_GPT = 'REYIELD_GPT',
  CHAT_HISTORY = 'CHAT_HISTORY',
}

export enum GPT_SUGGESTED_ANSWER_TYPE {
  TEXT = 'text',
  COMPONENT = 'component',
}

export enum GPT_USER_ANSWERED_KEY_MAPPING {
  GOAL = 'goal',
  EXPECTED_RETURN = 'expectedReturn',
  INVESTMENT_AMOUNT = 'investmentAmount',
  INVESTMENT_PERIOD = 'investmentPeriod',
  RISK_TOLERANCE_LEVEL = 'riskToleranceLevel',
  RISK_TOLERANCE_STYLE = 'riskToleranceStyle',
}

export const riskToleranceLevelNameStringMapping: MappingType = {
  [RiskToleranceLevelName.Aggressive]: 'Aggressive',
  [RiskToleranceLevelName.Conservative]: 'Conservative',
  [RiskToleranceLevelName.ExtremelyAggressive]: 'Extremely Aggressive',
  [RiskToleranceLevelName.ExtremelyConservative]: 'Extremely Conservative',
  [RiskToleranceLevelName.Moderate]: 'Moderate',
  [RiskToleranceLevelName.VeryConservative]: 'Very Conservative',
}

export const riskLevelNameStringMapping: MappingType = {
  [RiskLevel.Low]: 'Low',
  [RiskLevel.MediumLow]: 'Medium Low',
  [RiskLevel.Medium]: 'Medium',
  [RiskLevel.MediumHigh]: 'Medium High',
  [RiskLevel.High]: 'High',
  [RiskLevel.Unknown]: 'Unknown',
}

export const getRiskLevel = (rislLevel: string) => {
  switch (rislLevel) {
    case 'low':
      return RiskLevel.Low
    case 'medium_low':
      return RiskLevel.MediumLow
    case 'medium':
      return RiskLevel.Medium
    case 'medium_high':
      return RiskLevel.MediumHigh
    case 'high':
      return RiskLevel.High
    default:
      return RiskLevel.Unknown
  }
}

export const EXCHANGE_ICON_MAPPING: MappingType = {
  [Exchange.Bitfinex]: '/images/bitfinex.png',
  [Exchange.Tradingview]: '/images/tradingview.png',
  [Exchange.Uniswap]: '/images/uniswap.png',
  [Exchange.Unknown]: '/images/fi-cnsuxl-question-mark.png',
}

export enum RateHistoricalPeriod {
  Week = '7d',
  Month = '1M',
  Year = '1Y',
}

export enum NFTUtilitiesType {
  Permanent = 'Permanent',
  Timeliness = 'Timeliness',
  MysteryBox = 'MysteryBox',
}

export const SUPPORTED_CURRENCY_INFO = [
  {
    id: GimCoolPayPaymentSupportingCoinType.Eth,
    coinType: GimCoolPayPaymentSupportingCoinType.Eth,
    icon: 'cryptocurrency-color:eth',
    label: 'ETH',
  },
  {
    id: GimCoolPayPaymentSupportingCoinType.Usdt,
    coinType: GimCoolPayPaymentSupportingCoinType.Usdt,
    icon: 'cryptocurrency-color:usdt',
    label: 'USDT',
  },
  {
    id: GimCoolPayPaymentSupportingCoinType.Usdc,
    coinType: GimCoolPayPaymentSupportingCoinType.Usdc,
    icon: 'cryptocurrency-color:usdc',
    label: 'USDC',
  },
]
