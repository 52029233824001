import { Session } from 'next-auth'
import { SessionProvider, signOut, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { configureChains, createConfig, useDisconnect, WagmiConfig } from 'wagmi'
import { arbitrum, goerli, mainnet, optimism, optimismGoerli, polygon } from 'wagmi/chains'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { publicProvider } from 'wagmi/providers/public'

import { NEXT_PUBLIC_VERCEL_ENV } from '@/config'
import { NFT_MINT_INFO } from '@/nfts/config'

import { OkxConnector } from './OkxConnector'

type Props = {
  children: React.ReactNode
  session?: Session
}
const chainsForDevelopment = [mainnet, polygon, optimism, arbitrum, goerli, optimismGoerli]

const { publicClient, chains } = configureChains(
  NFT_MINT_INFO.IS_MINT_TESTING
    ? chainsForDevelopment
    : NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ? chainsForDevelopment
    : [mainnet, polygon, optimism, arbitrum],
  [publicProvider()]
)
const metaMaskConnector = new MetaMaskConnector({
  chains,
})

const okxConnector = new OkxConnector({
  chains,
})

const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId: '52ac9c99e8bc2cbe3332e3fe90a07a5b',
  },
})

const config = createConfig({
  publicClient,
  autoConnect: true,
  connectors: [metaMaskConnector, okxConnector, walletConnectConnector],
})

const SessionChecker = () => {
  const { data: sessionData } = useSession()
  const { disconnectAsync } = useDisconnect()

  useEffect(() => {
    async function handleSignOut() {
      await disconnectAsync()
      await signOut({
        redirect: false,
      })
    }
    if (typeof window !== 'undefined' && sessionData && sessionData?.error) {
      handleSignOut()
    }
  }, [sessionData])
  return <div />
}

export default function WalletProvider({ children, session }: Props) {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  return (
    <WagmiConfig config={config}>
      <SessionProvider session={session} refetchInterval={0}>
        {mounted && children}
        <SessionChecker />
      </SessionProvider>
    </WagmiConfig>
  )
}
