import moment, { Moment } from 'moment'

import { Nft_Round } from '@/generated/graphql'

export const dateFormat = 'MMM DD HH:mm A (UTC+8)'
export const dateFormatWithoutUtc = 'MMM DD HH:mm A'

export const NFT_MINT_INFO: {
  IS_MINT_TESTING: boolean
  NFT_CONTRACT_ADDRESS: string
  CURRENT_ROUND: Nft_Round
  ROUND: {
    [key in Nft_Round]: {
      NAME: string
      START_TIME: Moment
      END_TIME: Moment
      ROUND_NAME: string
      COUNTDOWN_TEXT: string
      CONTRACT_FUNCTION_NAME: string
    }
  }
} = {
  IS_MINT_TESTING: false, // TBD: change and deploy it after testing
  NFT_CONTRACT_ADDRESS: '0xD3570f7aCe016D769E3aFC447ec8207A9c72E529', // TBD: change and deploy it after contract deployed
  CURRENT_ROUND: Nft_Round.SoldOut, // TBD: change and deploy it when different round
  ROUND: {
    [Nft_Round.WhitelistCheck]: {
      NAME: 'WHITELIST CHECK',
      START_TIME: moment('2023-08-08').startOf('day'),
      END_TIME: moment('2023-08-17').startOf('day').add(12, 'hours'),
      /*
        1st Stage - Guarantee Whitelist
        2nd Stage - Allow Whitelist (will change this soon)
      */
      ROUND_NAME: '1st Stage - Guarantee Whitelist',
      COUNTDOWN_TEXT: 'COUNTDOWN TO START 1ST MINT STAGE',
      CONTRACT_FUNCTION_NAME: '',
    },
    [Nft_Round.Airdrop]: {
      NAME: 'Airdrop Date',
      START_TIME: moment('2023-08-15').startOf('day').add('13', 'hours'),
      END_TIME: moment('2023-08-16').startOf('day').add('11', 'hours').add(50, 'minutes'),
      ROUND_NAME: 'Airdrop',
      COUNTDOWN_TEXT: ':D',
      CONTRACT_FUNCTION_NAME: '',
    },
    [Nft_Round.GuaranteeWhitelistMint]: {
      NAME: 'Mint Date',
      START_TIME: moment('2023-08-17').startOf('day').add('12', 'hours'),
      END_TIME: moment('2023-08-20').startOf('day').add('13', 'hours'),
      ROUND_NAME: '1st Stage - Guarantee Whitelist',
      COUNTDOWN_TEXT: 'COUNTDOWN FOR 1ST MINT STAGE',
      CONTRACT_FUNCTION_NAME: 'mintWhitelist',
    },
    [Nft_Round.AllowWhitelistMint]: {
      NAME: 'Mint Date',
      START_TIME: moment('2023-08-18').startOf('day').add('13', 'hours'),
      END_TIME: moment('2023-08-20').startOf('day').add('13', 'hours'),
      ROUND_NAME: '2nd Stage - Allow Whitelist',
      COUNTDOWN_TEXT: 'COUNTDOWN FOR 2ND MINT STAGE',
      CONTRACT_FUNCTION_NAME: 'mintWhitelist',
    },
    [Nft_Round.SoldOut]: {
      NAME: 'SOLD OUT',
      START_TIME: moment('2023-08-21').startOf('day').add('12', 'hours').subtract(1, 's'),
      END_TIME: moment('2023-08-21').startOf('day').add('12', 'hours').subtract(1, 's'),
      ROUND_NAME: 'Sold Out',
      COUNTDOWN_TEXT: 'MINT STAGE IS ENDED',
      CONTRACT_FUNCTION_NAME: '',
    },
  },
}
