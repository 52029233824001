import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any
  Email: any
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  Number: any
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any
  Strategy_Number: any
  Strategy_Time: any
  Time: any
  jsonb: any
  timestamptz: any
  uuid: any
}

export type Aml = {
  __typename?: 'AML'
  /** EDD record of the AML */
  edd?: Maybe<AmleddRecord>
  /** NS record of the AML */
  ns?: Maybe<AmlnsRecord>
  /** RA record of the AML */
  ra?: Maybe<AmlraRecord>
  /** results of user's risk assessment of the AML */
  raResults?: Maybe<Array<AmlUserAssessmentResult>>
  /** status of the AML */
  status: AmlStatus
}

export type AmlAssessmentItem = {
  __typename?: 'AMLAssessmentItem'
  /** description of the assessment item */
  description: Scalars['String']
  /** unique identifier for the assessment item */
  id: Scalars['String']
  /** item categories of the assessment item */
  itemCategories: Array<AmlItemCategory>
  /** weights of the assessment item */
  weights: Scalars['String']
}

export type AmleddRecord = {
  __typename?: 'AMLEDDRecord'
  /** creation date of the EDD record */
  createdAt: Scalars['Time']
  /** the first audit's identifier of the EDD record */
  firstAuditorID?: Maybe<Scalars['ID']>
  /** unique identifier for the EDD record */
  id: Scalars['ID']
  /** the second audit's identifier of the EDD record */
  secondAuditorID?: Maybe<Scalars['ID']>
  /** status of the EDD record */
  status: EddStatus
  /** updated date of the EDD record */
  updatedAt: Scalars['Time']
}

export type AmlItemCategory = {
  __typename?: 'AMLItemCategory'
  /** allow multiple selection of the item category */
  allowMultipleSelection: Scalars['Boolean']
  /** description of the item category */
  description: Scalars['String']
  /** unique identifier for the item category */
  id: Scalars['String']
  /** risk factors of the item category */
  riskFactors: Array<AmlRiskFactor>
}

export type AmlnsRecord = {
  __typename?: 'AMLNSRecord'
  /** creation date of the NS record */
  createdAt: Scalars['Time']
  /** the first audit's identifier of the NS record */
  firstAuditorID?: Maybe<Scalars['ID']>
  /** unique identifier for the NS record */
  id: Scalars['ID']
  /** is system audited of the NS record */
  isSystemAudited: Scalars['Boolean']
  /** the second audit's identifier of the NS record */
  secondAuditorID?: Maybe<Scalars['ID']>
  /** status of the NS record */
  status: NsStatus
  /** updated date of the NS record */
  updatedAt: Scalars['Time']
}

export type AmlraRecord = {
  __typename?: 'AMLRARecord'
  /** creation date of the RA record */
  createdAt: Scalars['Time']
  /** the first audit's identifier of the RA record */
  firstAuditorID?: Maybe<Scalars['ID']>
  /** unique identifier for the RA record */
  id: Scalars['ID']
  /** is system audited of the RA record */
  isSystemAudited: Scalars['Boolean']
  /** the second audit's identifier of the RA record */
  secondAuditorID?: Maybe<Scalars['ID']>
  /** status of the RA record */
  status: RaStatus
  /** updated date of the RA record */
  updatedAt: Scalars['Time']
  /** the identifier of user's assessment result for the RA record */
  userAssessmentResultID?: Maybe<Scalars['String']>
}

export type AmlRatingCriteria = {
  __typename?: 'AMLRatingCriteria'
  /** high threshold of the rating criteria */
  high: Scalars['Int']
  /** medium threshold of the rating criteria */
  medium: Scalars['Int']
}

export type AmlRiskAssessmentForm = {
  __typename?: 'AMLRiskAssessmentForm'
  /** assessment items for the risk assessment form */
  assessmentItems: Array<AmlAssessmentItem>
  /** creation date of the risk assessment form */
  createdAt: Scalars['Time']
  /** criteria for the risk assessment form */
  criteria: AmlRatingCriteria
  /** unique identifier for the risk assessment form */
  id: Scalars['String']
  /** updated date of the risk assessment form */
  updatedAt: Scalars['Time']
  /** version of the risk assessment form */
  version: Scalars['String']
}

export type AmlRiskFactor = {
  __typename?: 'AMLRiskFactor'
  /** description of the risk factor */
  description: Scalars['String']
  /** unique identifier for the risk factor */
  id: Scalars['String']
  /** is selected of the risk factor */
  isSelected: Scalars['Boolean']
  /** score of the risk factor */
  score: Scalars['Int']
  /** severity of the risk factor */
  severity: Scalars['String']
}

export enum AmlStatus {
  AmlStatusPending = 'AMLStatusPending',
  AmlStatusRejected = 'AMLStatusRejected',
  AmlStatusUnverified = 'AMLStatusUnverified',
  AmlStatusVerified = 'AMLStatusVerified',
}

export type AmlUserAssessmentResult = {
  __typename?: 'AMLUserAssessmentResult'
  /** assessor's id of the user assessment result */
  assessorID: Scalars['String']
  /** creation date of the user assessment result */
  createdAt: Scalars['Time']
  /** risk assessment form of the user assessment result */
  form: AmlRiskAssessmentForm
  /** unique identifier for the user assessment result */
  id: Scalars['String']
  /** assessment score of the user assessment result */
  score: Scalars['String']
  /** assessment status of the user assessment result */
  status: Scalars['String']
  /** updated date of the user assessment result */
  updatedAt: Scalars['Time']
  /** user's id of the user assessment result */
  userID: Scalars['String']
}

export type AmlVerification = {
  __typename?: 'AMLVerification'
  createdAt: Scalars['Time']
  eddStatus: EddStatus
  id: Scalars['ID']
  nsStatus: NsStatus
  raStatus: RaStatus
  status: AmlStatus
  updatedAt: Scalars['Time']
}

export type ApiKey = {
  __typename?: 'APIKey'
  createdAt: Scalars['Time']
  id: Scalars['ID']
  key: Scalars['String']
  name: Scalars['String']
  permissions: Array<CasbinRole>
  secret?: Maybe<Scalars['String']>
}

export type AcceptFiatWithdrawalInput = {
  userID: Scalars['ID']
  withdrawalID: Scalars['ID']
}

export type AcceptFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AcceptFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotification = Entry & {
  __typename?: 'ActivityNotification'
  contentfulMetadata: ContentfulMetadata
  desc?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<ActivityNotificationLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationDescArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/activityNotification) */
export type ActivityNotificationTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ActivityNotificationCollection = {
  __typename?: 'ActivityNotificationCollection'
  items: Array<Maybe<ActivityNotification>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ActivityNotificationFilter = {
  AND?: InputMaybe<Array<InputMaybe<ActivityNotificationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ActivityNotificationFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  desc?: InputMaybe<Scalars['String']>
  desc_contains?: InputMaybe<Scalars['String']>
  desc_exists?: InputMaybe<Scalars['Boolean']>
  desc_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  desc_not?: InputMaybe<Scalars['String']>
  desc_not_contains?: InputMaybe<Scalars['String']>
  desc_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ActivityNotificationLinkingCollections = {
  __typename?: 'ActivityNotificationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ActivityNotificationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ActivityNotificationOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Address = {
  __typename?: 'Address'
  chain: Chain
  currency: Currency
  value: Scalars['String']
}

export type Admin = {
  __typename?: 'Admin'
  apiKeys: AdminApiKeyResponse
  depositReport: AdminDepositReportResponse
  deposits: AdminDepositResponse
  /** holiday: get legal holiday */
  holiday: AdminHolidayResponse
  ipUsers: AdminIpUserResponse
  kgiReadyUsers: Array<Maybe<User>>
  ledgers: AdminLedgerResponse
  onHoldFiatWithdrawals: AdminOnHoldFiatWithdrawalResponse
  onHoldKGIVerification: AdminOnHoldKgiVerificationResponse
  /** get the latest risk assessment form */
  theLatestAMLRiskAssessmentForm: AmlRiskAssessmentForm
  tradeReport: AdminTradingReportResponse
  trades: AdminTradeResponse
  users?: Maybe<AdminUserResponse>
  verifiers: Array<Maybe<User>>
  /** voucherRecords: 取得兌換碼列表 */
  voucherRecords: AdminVoucherRecordsResponse
  withdrawalReport: AdminWithdrawalReportResponse
  withdrawals: AdminWithdrawalResponse
}

export type AdminApiKeysArgs = {
  filter?: InputMaybe<AdminApiKeyFilter>
  pagination: PaginationQuery
}

export type AdminDepositReportArgs = {
  filter?: InputMaybe<AdminDepositFilter>
}

export type AdminDepositsArgs = {
  filter?: InputMaybe<AdminDepositFilter>
  pagination: PaginationQuery
}

export type AdminHolidayArgs = {
  filter?: InputMaybe<AdminHolidayFilter>
  pagination: PaginationQuery
}

export type AdminIpUsersArgs = {
  input: AdminIpUserFilter
}

export type AdminLedgersArgs = {
  filter?: InputMaybe<AdminLedgerFilter>
  pagination: PaginationQuery
}

export type AdminTradeReportArgs = {
  input: AdminTradeFilter
}

export type AdminTradesArgs = {
  filter?: InputMaybe<AdminTradeFilter>
  pagination: PaginationQuery
}

export type AdminUsersArgs = {
  filter?: InputMaybe<AdminUserFilter>
  pagination: PaginationQuery
}

export type AdminVerifiersArgs = {
  filter: AdminVerifierFilter
}

export type AdminVoucherRecordsArgs = {
  filter?: InputMaybe<AdminVoucherFilter>
  pagination: PaginationQuery
}

export type AdminWithdrawalReportArgs = {
  filter?: InputMaybe<AdminWithdrawalFilter>
}

export type AdminWithdrawalsArgs = {
  filter?: InputMaybe<AdminWithdrawalFilter>
  pagination: PaginationQuery
}

export type AdminApiKeyFilter = {
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminApiKeyResponse = BaseResponse & {
  __typename?: 'AdminAPIKeyResponse'
  data?: Maybe<Array<ApiKey>>
  result: Scalars['Boolean']
}

export type AdminActivateMerchantInput = {
  apiCode: Scalars['String']
  apiSecret: Scalars['String']
  merchantID: Scalars['Int']
  orderPrefix: Scalars['String']
  refreshCode: Scalars['String']
  userID: Scalars['ID']
}

export type AdminActivateMerchantResponse = BaseResponse & {
  __typename?: 'AdminActivateMerchantResponse'
  result: Scalars['Boolean']
}

export type AdminAddAuditNoteInput = {
  note: Scalars['String']
  userID: Scalars['ID']
}

export type AdminAddAuditNoteResponse = BaseResponse & {
  __typename?: 'AdminAddAuditNoteResponse'
  result: Scalars['Boolean']
}

export type AdminApproveVirtualAccountVerificationInput = {
  userID: Scalars['ID']
}

export type AdminApproveVirtualAccountVerificationResponse = BaseResponse & {
  __typename?: 'AdminApproveVirtualAccountVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminAssignKycVerifierInput = {
  level: KycLevel
  userID: Scalars['ID']
  verifierID: Scalars['ID']
}

export type AdminAssignKycVerifierResponse = BaseResponse & {
  __typename?: 'AdminAssignKYCVerifierResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminAuditWithdrawalInput = {
  status: WithdrawalStatus
  withdrawalID: Scalars['ID']
}

export type AdminAuditWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminAuditWithdrawalResponse'
  data?: Maybe<BaseWithdrawal>
  result: Scalars['Boolean']
}

export type AdminChangeVirtualAccountLimitInput = {
  dailyLimit: Scalars['Int']
  monthlyLimit: Scalars['Int']
  txLimit: Scalars['Int']
  vAccount: Scalars['String']
}

export type AdminChangeVirtualAccountLimitResponse = BaseResponse & {
  __typename?: 'AdminChangeVirtualAccountLimitResponse'
  result: Scalars['Boolean']
}

export type AdminCheckDepositInfoInput = {
  endDate: Scalars['Time']
  startDate: Scalars['Time']
  vAccount: Scalars['String']
}

export type AdminCheckDepositInfoResponse = BaseResponse & {
  __typename?: 'AdminCheckDepositInfoResponse'
  result: Scalars['Boolean']
}

export type AdminCheckFileUploadInput = {
  filename: Scalars['String']
}

export type AdminCheckUploadResultMResponse = BaseResponse & {
  __typename?: 'AdminCheckUploadResultMResponse'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultP01Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultP01Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultP02Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultP02Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultP05Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultP05Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultPg1Response = BaseResponse & {
  __typename?: 'AdminCheckUploadResultPG1Response'
  response?: Maybe<Array<KgiUploadResponse>>
  result: Scalars['Boolean']
}

export type AdminCheckUploadResultResponse = BaseResponse & {
  __typename?: 'AdminCheckUploadResultResponse'
  result: Scalars['Boolean']
}

export type AdminCreateApiKeyInput = {
  name: Scalars['String']
  permissions?: InputMaybe<Array<CasbinRole>>
  userID: Scalars['ID']
}

export type AdminCreateApiKeyResponse = BaseResponse & {
  __typename?: 'AdminCreateAPIKeyResponse'
  data?: Maybe<ApiKey>
  result: Scalars['Boolean']
}

export type AdminCreateOAuthClientInput = {
  name: Scalars['String']
  redirectURI?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminCreateOAuthClientResponse = BaseResponse & {
  __typename?: 'AdminCreateOAuthClientResponse'
  clientID: Scalars['ID']
  result: Scalars['Boolean']
}

export type AdminCreateRolesInput = {
  roles: Array<UserRole>
  userID: Scalars['ID']
}

export type AdminCreateRolesResponse = BaseResponse & {
  __typename?: 'AdminCreateRolesResponse'
  result: Scalars['Boolean']
}

export type AdminCreateUserInput = {
  email: Scalars['String']
  phone: Scalars['String']
}

export type AdminCreateUserResponse = BaseResponse & {
  __typename?: 'AdminCreateUserResponse'
  result: Scalars['Boolean']
  userID: Scalars['ID']
}

export type AdminDvBanInput = {
  userID: Scalars['ID']
}

export type AdminDvBanResponse = BaseResponse & {
  __typename?: 'AdminDVBanResponse'
  result: Scalars['Boolean']
}

export type AdminDvUpdateInput = {
  userID: Scalars['ID']
  vipLevel: VipLevel
}

export type AdminDvUpdateResponse = BaseResponse & {
  __typename?: 'AdminDVUpdateResponse'
  result: Scalars['Boolean']
}

export type AdminDeleteApiKeyInput = {
  key: Scalars['String']
}

export type AdminDeleteApiKeyResponse = BaseResponse & {
  __typename?: 'AdminDeleteAPIKeyResponse'
  result: Scalars['Boolean']
}

export type AdminDeleteRolesInput = {
  roles: Array<UserRole>
  userID: Scalars['ID']
}

export type AdminDeleteRolesResponse = BaseResponse & {
  __typename?: 'AdminDeleteRolesResponse'
  result: Scalars['Boolean']
}

export type AdminDepositFilter = {
  currency?: InputMaybe<Array<Currency>>
  depositID?: InputMaybe<Scalars['ID']>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<DepositStatus>>
  type?: InputMaybe<Array<DepositType>>
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminDepositReportResponse = BaseResponse & {
  __typename?: 'AdminDepositReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type AdminDepositResponse = BaseResponse & {
  __typename?: 'AdminDepositResponse'
  data?: Maybe<Array<Deposit>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminDisable2FaInput = {
  userID: Scalars['ID']
}

export type AdminDisable2FaResponse = BaseResponse & {
  __typename?: 'AdminDisable2FAResponse'
  result: Scalars['Boolean']
}

export type AdminDisableUserInput = {
  userID: Scalars['ID']
}

export type AdminDisableUserResponse = BaseResponse & {
  __typename?: 'AdminDisableUserResponse'
  result: Scalars['Boolean']
}

export type AdminEddAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminEddAuditResponse = BaseResponse & {
  __typename?: 'AdminEDDAuditResponse'
  record: AmleddRecord
  result: Scalars['Boolean']
}

export type AdminEnableUserInput = {
  userID: Scalars['ID']
}

export type AdminEnableUserResponse = BaseResponse & {
  __typename?: 'AdminEnableUserResponse'
  result: Scalars['Boolean']
}

export type AdminHolidayAddInput = {
  category?: InputMaybe<Scalars['String']>
  date: Scalars['Time']
  name?: InputMaybe<Scalars['String']>
}

export type AdminHolidayAddResponse = BaseResponse & {
  __typename?: 'AdminHolidayAddResponse'
  result: Scalars['Boolean']
}

export type AdminHolidayFilter = {
  end: Scalars['Time']
  /** start & end are in YYYYMMDD */
  start: Scalars['Time']
}

export type AdminHolidayResponse = BaseResponse & {
  __typename?: 'AdminHolidayResponse'
  meta: MetaPagination
  records: Array<Holiday>
  result: Scalars['Boolean']
}

export type AdminIpUserFilter = {
  ipAddress: Scalars['String']
}

export type AdminIpUserResponse = BaseResponse & {
  __typename?: 'AdminIPUserResponse'
  data?: Maybe<Array<User>>
  result: Scalars['Boolean']
}

export type AdminLedgerFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  ledgerID?: InputMaybe<Scalars['ID']>
  startDate?: InputMaybe<Scalars['Time']>
  type?: InputMaybe<Array<WalletOperationType>>
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminLedgerResponse = BaseResponse & {
  __typename?: 'AdminLedgerResponse'
  data?: Maybe<Array<Ledger>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminMutation = {
  __typename?: 'AdminMutation'
  adminActivateMerchant: AdminActivateMerchantResponse
  adminAddAuditNote: AdminAddAuditNoteResponse
  /** approve the result of enhanced due diligence */
  adminApproveEDDAudit: AdminEddAuditResponse
  /** approve the result of name screening */
  adminApproveNSAudit: AdminNsAuditResponse
  /** approve the result of risk assessment */
  adminApproveRAAudit: AdminRaAuditResponse
  adminApproveVirtualAccountVerification: AdminApproveVirtualAccountVerificationResponse
  adminAssignKYCVerifier: AdminAssignKycVerifierResponse
  adminAuditWithdrawal: AdminAuditWithdrawalResponse
  adminChangeVirtualAccountLimit: AdminChangeVirtualAccountLimitResponse
  adminCheckDepositInfo: AdminCheckDepositInfoResponse
  adminCheckUploadResult: AdminCheckUploadResultResponse
  adminCheckUploadResultM: AdminCheckUploadResultMResponse
  adminCheckUploadResultP01: AdminCheckUploadResultP01Response
  adminCheckUploadResultP02: AdminCheckUploadResultP02Response
  adminCheckUploadResultP05: AdminCheckUploadResultP05Response
  adminCheckUploadResultPG1: AdminCheckUploadResultPg1Response
  adminCreateAPIKey: AdminCreateApiKeyResponse
  /** adminCreateOAuthClient: 新增OAuth Client */
  adminCreateOAuthClient: AdminCreateOAuthClientResponse
  adminCreateRoles: AdminCreateRolesResponse
  /** adminCreateUser: 建立user */
  adminCreateUser: AdminCreateUserResponse
  /** adminDVBan: 將入金檢核檔限制調降成0 */
  adminDVBan: AdminDvBanResponse
  /** adminDVUpdate: 升級入金檢核檔額度 */
  adminDVUpdate: AdminDvUpdateResponse
  adminDeleteAPIKey: AdminDeleteApiKeyResponse
  adminDeleteRoles: AdminDeleteRolesResponse
  adminDisable2FA: AdminDisable2FaResponse
  adminDisableUser: AdminDisableUserResponse
  adminEnableUser: AdminEnableUserResponse
  /** adminHolidayAdd: 新增假日(非工作日) */
  adminHolidayAdd: AdminHolidayAddResponse
  adminReauditKYC: AdminReauditKycResponse
  adminRecoverKGIStatus?: Maybe<AdminRecoverKgiStatusResponse>
  adminRefreshMerchant: AdminRefreshMerchantResponse
  /** reject the result of enhanced due diligence */
  adminRejectEDDAudit: AdminEddAuditResponse
  adminRejectFiatWithdrawal: AdminRejectFiatWithdrawalResponse
  adminRejectKGIVerification: AdminRejectKgiVerificationResponse
  /** reject the result of name screening */
  adminRejectNSAudit: AdminNsAuditResponse
  /** reject the result of risk assessment */
  adminRejectRAAudit: AdminRaAuditResponse
  adminRejectVirtualAccountVerication: AdminRejectVirtualAccountVerificationResponse
  adminResendAMLWithdrawInfoP01: AdminResendAmlWithdrawInfoP01Response
  adminResendAMLWithdrawInfoP02: AdminResendAmlWithdrawInfoP02Response
  adminResendFiatWithdrawal: AdminResendFiatWithdrawalResponse
  adminResendKGIVerification: AdminResendKgiVerificationResponse
  adminResendWithdrawInfoP01: AdminResendWithdrawInfoP01Response
  adminResendWithdrawInfoP02: AdminResendWithdrawInfoP02Response
  /** reset the result of enhanced due diligence */
  adminResetEDDAudit: AdminEddAuditResponse
  adminResetKYCVerification: AdminResetKycVerificationResponse
  /** reset the result of name screening */
  adminResetNSAudit: AdminNsAuditResponse
  adminResetPhone: AdminResetPhoneResponse
  /** reset the result of risk assessment */
  adminResetRAAudit: AdminRaAuditResponse
  adminSendBGWithdrawInfo: AdminSendBgWithdrawInfoResponse
  adminSendInvestorsSettlement: AdminSendInvestorsSettlementResponse
  adminSendMemberInfo: AdminSendMemberInfoResponse
  adminSendWithdrawInfoP01: AdminSendWithdrawInfoP01Response
  adminSendWithdrawInfoP02: AdminSendWithdrawInfoP02Response
  adminSetOldUser: AdminSetOlderUserResponse
  adminSetTradePermission: AdminSetTradePermissionResponse
  /** deprecated: use adminAuditNS, adminAuditRA and adminAuditEDD instead */
  adminSubmitAMLVerification: AdminSubmitAmlVerificationResponse
  /** submit the result of enhanced due diligence */
  adminSubmitEDDAudit: AdminEddAuditResponse
  adminSubmitKYCOneVerification: AdminSubmitKycOneVerificationResponse
  adminSubmitKYCTwoVerification: AdminSubmitKycTwoVerificationResponse
  /** submit the result of name screening */
  adminSubmitNSAudit: AdminNsAuditResponse
  /** submit the result of risk assessment */
  adminSubmitRAAudit: AdminSubmitRaAuditResponse
  /** trigger the system to audit AML */
  adminSystemAuditAML: AdminSystemAuditAmlResponse
  adminUpdateCoinInfo: AdminUpdateCoinInfoResponse
  adminUpdateMarketInfo: AdminUpdateMarketInfoResponse
  /** adminUpdateOAuthClient: 修改OAuth Client */
  adminUpdateOAuthClient: AdminUpdateOAuthClientResponse
  adminUpdateQuotationInfo: AdminUpdateQuotationInfoResponse
  adminUploadSuspiciousTransaction: AdminUploadSuspiciousTransactionResponse
  adminUploadVerificationFile: AdminUploadVerificationFileResponse
  /** adminVIPUpgrade: VIP等級調整 */
  adminVIPUpgrade: AdminVipUpdateResponse
  adminVerifyKYCIdentity: AdminVerifyKycIdentityResponse
  /** adminVoucherCreate: 新增兌換碼 */
  adminVoucherCreate: VoucherRecord
  /** adminVoucherEdit: 編輯兌換碼 */
  adminVoucherEdit: VoucherRecord
}

export type AdminMutationAdminActivateMerchantArgs = {
  input: AdminActivateMerchantInput
}

export type AdminMutationAdminAddAuditNoteArgs = {
  input: AdminAddAuditNoteInput
}

export type AdminMutationAdminApproveEddAuditArgs = {
  input: AdminEddAuditInput
}

export type AdminMutationAdminApproveNsAuditArgs = {
  input: AdminNsAuditInput
}

export type AdminMutationAdminApproveRaAuditArgs = {
  input: AdminRaAuditInput
}

export type AdminMutationAdminApproveVirtualAccountVerificationArgs = {
  input: AdminApproveVirtualAccountVerificationInput
}

export type AdminMutationAdminAssignKycVerifierArgs = {
  input: AdminAssignKycVerifierInput
}

export type AdminMutationAdminAuditWithdrawalArgs = {
  input: AdminAuditWithdrawalInput
}

export type AdminMutationAdminChangeVirtualAccountLimitArgs = {
  input: AdminChangeVirtualAccountLimitInput
}

export type AdminMutationAdminCheckDepositInfoArgs = {
  input?: InputMaybe<AdminCheckDepositInfoInput>
}

export type AdminMutationAdminCheckUploadResultArgs = {
  input?: InputMaybe<AdminCheckFileUploadInput>
}

export type AdminMutationAdminCreateApiKeyArgs = {
  input: AdminCreateApiKeyInput
}

export type AdminMutationAdminCreateOAuthClientArgs = {
  input: AdminCreateOAuthClientInput
}

export type AdminMutationAdminCreateRolesArgs = {
  input: AdminCreateRolesInput
}

export type AdminMutationAdminCreateUserArgs = {
  input: AdminCreateUserInput
}

export type AdminMutationAdminDvBanArgs = {
  input: AdminDvBanInput
}

export type AdminMutationAdminDvUpdateArgs = {
  input: AdminDvUpdateInput
}

export type AdminMutationAdminDeleteApiKeyArgs = {
  input: AdminDeleteApiKeyInput
}

export type AdminMutationAdminDeleteRolesArgs = {
  input: AdminDeleteRolesInput
}

export type AdminMutationAdminDisable2FaArgs = {
  input: AdminDisable2FaInput
}

export type AdminMutationAdminDisableUserArgs = {
  input: AdminDisableUserInput
}

export type AdminMutationAdminEnableUserArgs = {
  input: AdminEnableUserInput
}

export type AdminMutationAdminHolidayAddArgs = {
  input: AdminHolidayAddInput
}

export type AdminMutationAdminReauditKycArgs = {
  input: AdminReauditKycInput
}

export type AdminMutationAdminRefreshMerchantArgs = {
  input: AdminRefreshMerchantInput
}

export type AdminMutationAdminRejectEddAuditArgs = {
  input: AdminEddAuditInput
}

export type AdminMutationAdminRejectFiatWithdrawalArgs = {
  input: AdminRejectFiatWithdrawalInput
}

export type AdminMutationAdminRejectKgiVerificationArgs = {
  input: AdminRejectKgiVerificationInput
}

export type AdminMutationAdminRejectNsAuditArgs = {
  input: AdminNsAuditInput
}

export type AdminMutationAdminRejectRaAuditArgs = {
  input: AdminRaAuditInput
}

export type AdminMutationAdminRejectVirtualAccountVericationArgs = {
  input: AdminRejectVirtualAccountVerificationInput
}

export type AdminMutationAdminResendFiatWithdrawalArgs = {
  input: AdminResendFiatWithdrawalInput
}

export type AdminMutationAdminResendKgiVerificationArgs = {
  input: AdminResendKgiVerificationInput
}

export type AdminMutationAdminResendWithdrawInfoP01Args = {
  input?: InputMaybe<AdminResendWithdrawInfoP01Input>
}

export type AdminMutationAdminResendWithdrawInfoP02Args = {
  input?: InputMaybe<AdminResendWithdrawInfoP02Input>
}

export type AdminMutationAdminResetEddAuditArgs = {
  input: AdminEddAuditInput
}

export type AdminMutationAdminResetKycVerificationArgs = {
  input: AdminResetKycVerificationInput
}

export type AdminMutationAdminResetNsAuditArgs = {
  input: AdminNsAuditInput
}

export type AdminMutationAdminResetPhoneArgs = {
  input: AdminResetPhoneInput
}

export type AdminMutationAdminResetRaAuditArgs = {
  input: AdminRaAuditInput
}

export type AdminMutationAdminSendBgWithdrawInfoArgs = {
  input: AdminSendBgWithdrawInfoInput
}

export type AdminMutationAdminSetOldUserArgs = {
  input: AdminSetOldUserInput
}

export type AdminMutationAdminSetTradePermissionArgs = {
  input: AdminSetTradePermissionInput
}

export type AdminMutationAdminSubmitAmlVerificationArgs = {
  input: AdminSubmitAmlVerificationInput
}

export type AdminMutationAdminSubmitEddAuditArgs = {
  input: AdminSubmitEddAuditInput
}

export type AdminMutationAdminSubmitKycOneVerificationArgs = {
  input: AdminSubmitKycOneVerificationInput
}

export type AdminMutationAdminSubmitKycTwoVerificationArgs = {
  input: AdminSubmitKycTwoVerificationInput
}

export type AdminMutationAdminSubmitNsAuditArgs = {
  input: AdminSubmitNsAuditInput
}

export type AdminMutationAdminSubmitRaAuditArgs = {
  input: AdminSubmitRaAuditInput
}

export type AdminMutationAdminSystemAuditAmlArgs = {
  input: AdminSystemAuditAmlInput
}

export type AdminMutationAdminUpdateOAuthClientArgs = {
  input: AdminUpdateOAuthClientInput
}

export type AdminMutationAdminUploadSuspiciousTransactionArgs = {
  input: AdminUploadSuspiciousTransactionInput
}

export type AdminMutationAdminVipUpgradeArgs = {
  input: AdminVipUpdateInput
}

export type AdminMutationAdminVerifyKycIdentityArgs = {
  input: AdminVerifyKycIdentityInput
}

export type AdminMutationAdminVoucherCreateArgs = {
  input: AdminVoucherCreateInput
}

export type AdminMutationAdminVoucherEditArgs = {
  input: AdminVoucherEditInput
}

export type AdminNsAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminNsAuditResponse = BaseResponse & {
  __typename?: 'AdminNSAuditResponse'
  record: AmlnsRecord
  result: Scalars['Boolean']
}

export type AdminOnHoldFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminOnHoldFiatWithdrawalResponse'
  data?: Maybe<Array<OnHoldKgiVerification>>
  result: Scalars['Boolean']
}

export type AdminOnHoldKgiVerificationResponse = BaseResponse & {
  __typename?: 'AdminOnHoldKGIVerificationResponse'
  data?: Maybe<Array<OnHoldKgiVerification>>
  result: Scalars['Boolean']
}

export enum AdminP05Type {
  Bg = 'BG',
  Nc = 'NC',
}

export type AdminRaAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminRaAuditResponse = BaseResponse & {
  __typename?: 'AdminRAAuditResponse'
  record: AmlraRecord
  result: Scalars['Boolean']
}

export type AdminReauditKycInput = {
  level: KycLevel
  userID: Scalars['ID']
}

export type AdminReauditKycResponse = BaseResponse & {
  __typename?: 'AdminReauditKYCResponse'
  result: Scalars['Boolean']
}

export type AdminRecoverKgiStatusResponse = BaseResponse & {
  __typename?: 'AdminRecoverKGIStatusResponse'
  result: Scalars['Boolean']
}

export type AdminRefreshMerchantInput = {
  userID: Scalars['ID']
}

export type AdminRefreshMerchantResponse = BaseResponse & {
  __typename?: 'AdminRefreshMerchantResponse'
  result: Scalars['Boolean']
}

export type AdminRejectFiatWithdrawalInput = {
  reason: Scalars['String']
  refund: Scalars['Boolean']
  withdrawalID: Scalars['ID']
}

export type AdminRejectFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminRejectFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

export type AdminRejectKgiVerificationInput = {
  reason: Scalars['String']
  userID: Scalars['ID']
}

export type AdminRejectKgiVerificationResponse = BaseResponse & {
  __typename?: 'AdminRejectKGIVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminRejectVirtualAccountVerificationInput = {
  reason: Scalars['String']
  userID: Scalars['ID']
}

export type AdminRejectVirtualAccountVerificationResponse = BaseResponse & {
  __typename?: 'AdminRejectVirtualAccountVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminResendAmlWithdrawInfoP01Response = BaseResponse & {
  __typename?: 'AdminResendAMLWithdrawInfoP01Response'
  result: Scalars['Boolean']
}

export type AdminResendAmlWithdrawInfoP02Response = BaseResponse & {
  __typename?: 'AdminResendAMLWithdrawInfoP02Response'
  result: Scalars['Boolean']
}

export type AdminResendFiatWithdrawalInput = {
  withdrawalID: Scalars['ID']
}

export type AdminResendFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminResendFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

export type AdminResendKgiVerificationInput = {
  userID: Scalars['ID']
}

export type AdminResendKgiVerificationResponse = BaseResponse & {
  __typename?: 'AdminResendKGIVerificationResponse'
  result: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP01Input = {
  isAMLResend: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP01Response = BaseResponse & {
  __typename?: 'AdminResendWithdrawInfoP01Response'
  result: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP02Input = {
  isAMLResend: Scalars['Boolean']
}

export type AdminResendWithdrawInfoP02Response = BaseResponse & {
  __typename?: 'AdminResendWithdrawInfoP02Response'
  result: Scalars['Boolean']
}

export type AdminResetKycVerificationInput = {
  level: KycLevel
  userID: Scalars['ID']
}

export type AdminResetKycVerificationResponse = BaseResponse & {
  __typename?: 'AdminResetKYCVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminResetPhoneInput = {
  userID: Scalars['ID']
}

export type AdminResetPhoneResponse = BaseResponse & {
  __typename?: 'AdminResetPhoneResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSendBgWithdrawInfoInput = {
  amount: Scalars['Int']
  type?: InputMaybe<AdminP05Type>
}

export type AdminSendBgWithdrawInfoResponse = BaseResponse & {
  __typename?: 'AdminSendBGWithdrawInfoResponse'
  result: Scalars['Boolean']
}

export type AdminSendInvestorsSettlementResponse = BaseResponse & {
  __typename?: 'AdminSendInvestorsSettlementResponse'
  result: Scalars['Boolean']
}

export type AdminSendMemberInfoResponse = BaseResponse & {
  __typename?: 'AdminSendMemberInfoResponse'
  result: Scalars['Boolean']
}

export type AdminSendWithdrawInfoP01Response = BaseResponse & {
  __typename?: 'AdminSendWithdrawInfoP01Response'
  result: Scalars['Boolean']
}

export type AdminSendWithdrawInfoP02Response = BaseResponse & {
  __typename?: 'AdminSendWithdrawInfoP02Response'
  result: Scalars['Boolean']
}

export type AdminSetOldUserInput = {
  setOldUser: Scalars['Boolean']
  userID: Scalars['ID']
}

export type AdminSetOlderUserResponse = BaseResponse & {
  __typename?: 'AdminSetOlderUserResponse'
  result: Scalars['Boolean']
}

export type AdminSetTradePermissionInput = {
  enable: Scalars['Boolean']
  userID: Scalars['ID']
}

export type AdminSetTradePermissionResponse = BaseResponse & {
  __typename?: 'AdminSetTradePermissionResponse'
  result: Scalars['Boolean']
}

export type AdminSubmitAmlVerificationInput = {
  eddStatus: EddStatus
  nsStatus: NsStatus
  raStatus: RaStatus
  userID: Scalars['ID']
}

export type AdminSubmitAmlVerificationResponse = BaseResponse & {
  __typename?: 'AdminSubmitAMLVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSubmitEddAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  status: EddStatus
  userID: Scalars['ID']
}

export type AdminSubmitKycOneVerificationInput = {
  addressPhotoVerificationResult: PhotoVerificationResultInput
  idBackPhotoVerificationResult: PhotoVerificationResultInput
  idFrontPhotoVerificationResult: PhotoVerificationResultInput
  secondIDPhotoVerificationResult: PhotoVerificationResultInput
  selfiePhotoVerificationResult: PhotoVerificationResultInput
  userID: Scalars['ID']
}

export type AdminSubmitKycOneVerificationResponse = BaseResponse & {
  __typename?: 'AdminSubmitKYCOneVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSubmitKycTwoVerificationInput = {
  bankPhotoVerificationResult: PhotoVerificationResultInput
  userID: Scalars['ID']
}

export type AdminSubmitKycTwoVerificationResponse = BaseResponse & {
  __typename?: 'AdminSubmitKYCTwoVerificationResponse'
  result: Scalars['Boolean']
  user?: Maybe<User>
}

export type AdminSubmitNsAuditInput = {
  remark?: InputMaybe<Scalars['String']>
  status: NsStatus
  userID: Scalars['ID']
}

export type AdminSubmitRaAuditInput = {
  /** identifiers of selected factors */
  factorIDs: Array<Scalars['String']>
  /** identifier of risk assessment form */
  formID: Scalars['String']
  remark?: InputMaybe<Scalars['String']>
  userID: Scalars['ID']
}

export type AdminSubmitRaAuditResponse = BaseResponse & {
  __typename?: 'AdminSubmitRAAuditResponse'
  record: AmlraRecord
  result: Scalars['Boolean']
  userAssessmentResult: AmlUserAssessmentResult
}

export type AdminSystemAuditAmlInput = {
  userID: Scalars['ID']
}

export type AdminSystemAuditAmlResponse = BaseResponse & {
  __typename?: 'AdminSystemAuditAMLResponse'
  result: Scalars['Boolean']
}

export type AdminTradeFilter = {
  endDate?: InputMaybe<Scalars['Time']>
  markets?: InputMaybe<Array<Market>>
  side?: InputMaybe<OrderSide>
  startDate?: InputMaybe<Scalars['Time']>
  tradeID?: InputMaybe<Scalars['ID']>
  userID?: InputMaybe<Scalars['ID']>
}

export type AdminTradeResponse = BaseResponse & {
  __typename?: 'AdminTradeResponse'
  data?: Maybe<Array<Trade>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminTradingReportResponse = BaseResponse & {
  __typename?: 'AdminTradingReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type AdminUpdateCoinInfoResponse = BaseResponse & {
  __typename?: 'AdminUpdateCoinInfoResponse'
  result: Scalars['Boolean']
}

export type AdminUpdateMarketInfoResponse = BaseResponse & {
  __typename?: 'AdminUpdateMarketInfoResponse'
  result: Scalars['Boolean']
}

export type AdminUpdateOAuthClientInput = {
  clientID: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  redirectURI?: InputMaybe<Scalars['String']>
}

export type AdminUpdateOAuthClientResponse = BaseResponse & {
  __typename?: 'AdminUpdateOAuthClientResponse'
  result: Scalars['Boolean']
}

export type AdminUpdateQuotationInfoResponse = BaseResponse & {
  __typename?: 'AdminUpdateQuotationInfoResponse'
  result: Scalars['Boolean']
}

export type AdminUploadSuspiciousTransactionInput = {
  baseDate: Scalars['Time']
}

export type AdminUploadSuspiciousTransactionResponse = BaseResponse & {
  __typename?: 'AdminUploadSuspiciousTransactionResponse'
  result: Scalars['Boolean']
}

export type AdminUploadVerificationFileResponse = BaseResponse & {
  __typename?: 'AdminUploadVerificationFileResponse'
  result: Scalars['Boolean']
}

export type AdminUserFilter = {
  email?: InputMaybe<Scalars['String']>
  kycStatus?: InputMaybe<KycStatus>
  name?: InputMaybe<Scalars['String']>
  updatedAfter?: InputMaybe<Scalars['Time']>
  updatedBefore?: InputMaybe<Scalars['Time']>
  userID?: InputMaybe<Scalars['ID']>
  verifierID?: InputMaybe<Scalars['ID']>
}

export type AdminUserResponse = BaseResponse & {
  __typename?: 'AdminUserResponse'
  data?: Maybe<Array<User>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type AdminVipUpdateInput = {
  userID: Scalars['ID']
  vipLevel: VipLevel
}

export type AdminVipUpdateResponse = BaseResponse & {
  __typename?: 'AdminVIPUpdateResponse'
  result: Scalars['Boolean']
}

export type AdminVerifierFilter = {
  roles: Array<UserRole>
}

export type AdminVerifyKycIdentityInput = {
  userID: Scalars['ID']
}

export type AdminVerifyKycIdentityResponse = BaseResponse & {
  __typename?: 'AdminVerifyKYCIdentityResponse'
  result: Scalars['Boolean']
}

export type AdminVoucherCreateInput = {
  /** code: 兌換碼，最大長度 8，為空將由系統自動產出 */
  code?: InputMaybe<Scalars['String']>
  /** description: 兌換碼說明，最大長度 100 */
  description?: InputMaybe<Scalars['String']>
  /** expiredDate: 兌換碼到期日期，空代表無限制 */
  expiredDate?: InputMaybe<Scalars['Time']>
  /** maxUsageTimes: 兌換碼最大可用次數 */
  maxUsageTimes: Scalars['Int']
  /** name: 兌換碼名稱，最大長度 20 */
  name: Scalars['String']
  /** point: 兌換點數 */
  point: Scalars['Int']
  /** type: 兌換碼類型，最大長度 8 */
  type?: InputMaybe<Scalars['String']>
}

export type AdminVoucherEditInput = {
  /** code: 兌換碼 */
  code: Scalars['String']
  /** description: 兌換碼說明，最大長度 100 */
  description?: InputMaybe<Scalars['String']>
  /** isDisabled: 兌換碼是否被停用，true:停用 false:可用 */
  isIsDisabled?: InputMaybe<Scalars['Boolean']>
  /** name: 兌換碼名稱，最大長度 20 */
  name?: InputMaybe<Scalars['String']>
  /** type: 兌換碼類型，最大長度 8 */
  type?: InputMaybe<Scalars['String']>
}

export type AdminVoucherFilter = {
  code?: InputMaybe<Scalars['String']>
  expiredDate?: InputMaybe<Scalars['Time']>
  name?: InputMaybe<Scalars['String']>
  skipExpired?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<Scalars['String']>
}

export type AdminVoucherRecordsResponse = BaseResponse & {
  __typename?: 'AdminVoucherRecordsResponse'
  meta: MetaPagination
  records: Array<VoucherRecord>
  result: Scalars['Boolean']
}

export type AdminWithdrawalFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<WithdrawalStatus>>
  type?: InputMaybe<Array<WithdrawalType>>
  userID?: InputMaybe<Scalars['ID']>
  withdrawalID?: InputMaybe<Scalars['ID']>
}

export type AdminWithdrawalReportResponse = BaseResponse & {
  __typename?: 'AdminWithdrawalReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type AdminWithdrawalResponse = BaseResponse & {
  __typename?: 'AdminWithdrawalResponse'
  data?: Maybe<Array<Withdrawal>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type ApproveKycLv2Input = {
  userID: Scalars['ID']
}

export type ApproveKycLv2Response = BaseResponse & {
  __typename?: 'ApproveKYCLv2Response'
  result: Scalars['Boolean']
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset'
  contentType?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  linkedFrom?: Maybe<AssetLinkingCollections>
  size?: Maybe<Scalars['Int']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
  transform?: InputMaybe<ImageTransformOptions>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AssetCollection = {
  __typename?: 'AssetCollection'
  items: Array<Maybe<Asset>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  contentType?: InputMaybe<Scalars['String']>
  contentType_contains?: InputMaybe<Scalars['String']>
  contentType_exists?: InputMaybe<Scalars['Boolean']>
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentType_not?: InputMaybe<Scalars['String']>
  contentType_not_contains?: InputMaybe<Scalars['String']>
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName?: InputMaybe<Scalars['String']>
  fileName_contains?: InputMaybe<Scalars['String']>
  fileName_exists?: InputMaybe<Scalars['Boolean']>
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName_not?: InputMaybe<Scalars['String']>
  fileName_not_contains?: InputMaybe<Scalars['String']>
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  height?: InputMaybe<Scalars['Int']>
  height_exists?: InputMaybe<Scalars['Boolean']>
  height_gt?: InputMaybe<Scalars['Int']>
  height_gte?: InputMaybe<Scalars['Int']>
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  height_lt?: InputMaybe<Scalars['Int']>
  height_lte?: InputMaybe<Scalars['Int']>
  height_not?: InputMaybe<Scalars['Int']>
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size?: InputMaybe<Scalars['Int']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_gt?: InputMaybe<Scalars['Int']>
  size_gte?: InputMaybe<Scalars['Int']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size_lt?: InputMaybe<Scalars['Int']>
  size_lte?: InputMaybe<Scalars['Int']>
  size_not?: InputMaybe<Scalars['Int']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  width?: InputMaybe<Scalars['Int']>
  width_exists?: InputMaybe<Scalars['Boolean']>
  width_gt?: InputMaybe<Scalars['Int']>
  width_gte?: InputMaybe<Scalars['Int']>
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  width_lt?: InputMaybe<Scalars['Int']>
  width_lte?: InputMaybe<Scalars['Int']>
  width_not?: InputMaybe<Scalars['Int']>
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections'
  displayItemCollection?: Maybe<DisplayItemCollection>
  entryCollection?: Maybe<EntryCollection>
  heroSliderCollection?: Maybe<HeroSliderCollection>
  popUpCollection?: Maybe<PopUpCollection>
}

export type AssetLinkingCollectionsDisplayItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsDisplayItemCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsHeroSliderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsHeroSliderCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPopUpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsPopUpCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AssetLinkingCollectionsDisplayItemCollectionOrder {
  EnableSpecialSharingBackgroundsModeAsc = 'enableSpecialSharingBackgroundsMode_ASC',
  EnableSpecialSharingBackgroundsModeDesc = 'enableSpecialSharingBackgroundsMode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsHeroSliderCollectionOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsPopUpCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  CallToActionButtonLinkAsc = 'callToActionButtonLink_ASC',
  CallToActionButtonLinkDesc = 'callToActionButtonLink_DESC',
  CallToActionButtonTextAsc = 'callToActionButtonText_ASC',
  CallToActionButtonTextDesc = 'callToActionButtonText_DESC',
  CallToActionButtonVariantAsc = 'callToActionButtonVariant_ASC',
  CallToActionButtonVariantDesc = 'callToActionButtonVariant_DESC',
  CloseButtonTextAsc = 'closeButtonText_ASC',
  CloseButtonTextDesc = 'closeButtonText_DESC',
  CloseButtonVariantAsc = 'closeButtonVariant_ASC',
  CloseButtonVariantDesc = 'closeButtonVariant_DESC',
  DisplayEnvironmentAsc = 'displayEnvironment_ASC',
  DisplayEnvironmentDesc = 'displayEnvironment_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

export type Attributes = {
  __typename?: 'Attributes'
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type Auth2Fa = {
  __typename?: 'Auth2FA'
  qrCode: Scalars['String']
  secret: Scalars['String']
  token2FA: Scalars['String']
}

export type AuthBind2FaResponse = BaseResponse & {
  __typename?: 'AuthBind2FAResponse'
  data: Auth2Fa
  result: Scalars['Boolean']
}

export type AuthConfirm2FaInput = {
  code2FA: Scalars['String']
  token2FA: Scalars['String']
}

export type AuthConfirm2FaResponse = BaseResponse & {
  __typename?: 'AuthConfirm2FAResponse'
  data?: Maybe<Confirm2FaData>
  result: Scalars['Boolean']
}

export type AuthMutation = {
  __typename?: 'AuthMutation'
  authBind2FA: AuthBind2FaResponse
  authConfirm2FA: AuthConfirm2FaResponse
  authUnbind2FA: AuthUnbind2FaResponse
}

export type AuthMutationAuthConfirm2FaArgs = {
  input: AuthConfirm2FaInput
}

export type AuthMutationAuthUnbind2FaArgs = {
  input: AuthUnbind2FaInput
}

export type AuthUnbind2FaInput = {
  code2FA: Scalars['String']
}

export type AuthUnbind2FaResponse = BaseResponse & {
  __typename?: 'AuthUnbind2FAResponse'
  result: Scalars['Boolean']
}

export type Balance = {
  __typename?: 'Balance'
  available: Scalars['Number']
  currency: Currency
  frozen: Scalars['Number']
  total: Scalars['Number']
  type: CurrencyType
}

export type Bank = {
  __typename?: 'Bank'
  branches: Array<Maybe<BankBranch>>
  code: Scalars['String']
  name: Scalars['String']
}

export type BankAccount = {
  __typename?: 'BankAccount'
  account: Scalars['String']
  accountHolder: Scalars['String']
  bankCode: Scalars['String']
  bankName: Scalars['String']
  branchCode?: Maybe<Scalars['String']>
  branchName?: Maybe<Scalars['String']>
  swiftCode?: Maybe<Scalars['String']>
}

export type BankBranch = {
  __typename?: 'BankBranch'
  code: Scalars['String']
  name: Scalars['String']
}

export type BankInfo = {
  __typename?: 'BankInfo'
  account: Scalars['String']
  accountHolder: Scalars['String']
  bankCode: Scalars['String']
  branchCode: Scalars['String']
  createdAt: Scalars['Time']
  id: Scalars['ID']
  updatedAt: Scalars['Time']
}

export type BankInfoInput = {
  account: Scalars['String']
  bankCode: Scalars['String']
  bankPhotoResourceID: Scalars['ID']
  branchCode: Scalars['String']
  holder: Scalars['String']
}

export type BaseDeposit = {
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  id: Scalars['ID']
  status: DepositStatus
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type BaseResponse = {
  result: Scalars['Boolean']
}

export type BaseWithdrawal = {
  amount?: Maybe<Scalars['Number']>
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency?: Maybe<Currency>
  fee?: Maybe<Scalars['Number']>
  feeCurrency?: Maybe<Currency>
  id: Scalars['ID']
  status?: Maybe<WithdrawalStatus>
  type?: Maybe<WithdrawalType>
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type BasicInfo = {
  __typename?: 'BasicInfo'
  address?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  gender?: Maybe<Gender>
  id: Scalars['ID']
  industry?: Maybe<Industry>
  jobTitle?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt: Scalars['Time']
}

export type BasicInfoInput = {
  address?: InputMaybe<Scalars['String']>
  birthday?: InputMaybe<Scalars['Time']>
  gender?: InputMaybe<Gender>
  industry?: InputMaybe<Industry>
  jobTitle?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export enum CasbinRole {
  RoleAdmin = 'RoleAdmin',
  RoleCmplManager = 'RoleCMPLManager',
  RoleCmplMember = 'RoleCMPLMember',
  RoleCsManager = 'RoleCSManager',
  RoleCsMember = 'RoleCSMember',
  RoleDeveloper = 'RoleDeveloper',
  RoleFinManager = 'RoleFINManager',
  RoleFinMember = 'RoleFINMember',
  RoleMktManager = 'RoleMKTManager',
  RoleMktMember = 'RoleMKTMember',
  RoleRcManager = 'RoleRCManager',
  RoleRcMember = 'RoleRCMember',
  RoleServiceFaas = 'RoleServiceFAAS',
  RoleServiceFaasMerchant = 'RoleServiceFAASMerchant',
  RoleServiceKyc = 'RoleServiceKYC',
  RoleServiceMine = 'RoleServiceMine',
  RoleUserBabyWhale = 'RoleUserBabyWhale',
  RoleUserBigWhale = 'RoleUserBigWhale',
  RoleUserGiantWhale = 'RoleUserGiantWhale',
  RoleUserLv1 = 'RoleUserLV1',
  RoleUserLv2 = 'RoleUserLV2',
  RoleUserLittleWhale = 'RoleUserLittleWhale',
  RoleUserNew = 'RoleUserNew',
  RoleUserPublic = 'RoleUserPublic',
  RoleUserVip = 'RoleUserVIP',
  RoleUserVvip = 'RoleUserVVIP',
}

export type CefiDepositAddress = {
  __typename?: 'CefiDepositAddress'
  address: Scalars['String']
  chain: Chain
}

export type CefiDepositAddressInput = {
  chain: Chain
  currency: Scalars['String']
  exchange: Exchange
  wallet?: InputMaybe<WalletType>
}

export type CefiWallet = {
  __typename?: 'CefiWallet'
  exchange: Scalars['String']
  walletBalances: Array<WalletBalances>
}

export type CefiWalletFilterInput = {
  exchange: Exchange
  hideTinyUSDValue?: InputMaybe<Scalars['Int']>
}

export enum Chain {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Tron = 'Tron',
}

export type ChainFilter = {
  chain?: InputMaybe<Array<Chain>>
}

export type ChartInput = {
  endTime: Scalars['Time']
  granularity: Granularity
  market: Market
  priceType: PriceType
  startTime?: InputMaybe<Scalars['Time']>
}

export type ChartResponse = BaseResponse & {
  __typename?: 'ChartResponse'
  data?: Maybe<Array<MarketPrice>>
  result: Scalars['Boolean']
}

export type CloseUniswapv3PositionInput = {
  positionId: Scalars['String']
  txHash: Scalars['String']
}

export type CoinPrice = {
  __typename?: 'CoinPrice'
  symbol: Scalars['String']
  usdtPrice: Scalars['String']
}

export type CommonAddress = {
  __typename?: 'CommonAddress'
  address: Address
  id: Scalars['ID']
  name: Scalars['String']
}

export type CommonAddressFilter = {
  chains?: InputMaybe<Array<Chain>>
  currencies?: InputMaybe<Array<Currency>>
}

export type CommonAddressResponse = BaseResponse & {
  __typename?: 'CommonAddressResponse'
  data?: Maybe<Array<CommonAddress>>
  result: Scalars['Boolean']
}

export type Confirm2FaData =
  | CryptoWithdrawal
  | ExternalApiKeyWithdrawal
  | FaasPayment
  | FiatWithdrawal
  | InternalTransferOut
  | LoginInfo

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata'
  tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>
  tags_exists?: InputMaybe<Scalars['Boolean']>
}

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type Country = {
  __typename?: 'Country'
  callingCode: Scalars['String']
  code: Scalars['String']
}

export type CreateAiCompletionRecordInput = {
  expectedReturn: Scalars['String']
  goal: Scalars['String']
  investmentAmount: Scalars['String']
  investmentPeriod: Scalars['String']
  items: Array<CreateAiCompletionRecordItemInput>
  promptVersion?: InputMaybe<PromptVersion>
  riskToleranceLevel: RiskToleranceLevelName
}

export type CreateAiCompletionRecordItemInput = {
  desc: Scalars['String']
  highestAnnualReturn: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
}

export type CreateUniswapv3PositionInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type CreateUniswapv3ToolInput = {
  chain: EvmChain
  description?: InputMaybe<Scalars['String']>
  feeTier: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  outOfLowerRange: OutOfRangeInfoInput
  outOfUpperRange: OutOfRangeInfoInput
  tickLowerDiff: Scalars['Float']
  tickUpperDiff: Scalars['Float']
  token0Address: Scalars['String']
  token1Address: Scalars['String']
}

export type Creator = {
  __typename?: 'Creator'
  address: Scalars['String']
  id: Scalars['String']
}

export type CryptoAsset = {
  __typename?: 'CryptoAsset'
  asset?: Maybe<PaymentMethod>
}

export type CryptoAssetAssetArgs = {
  currency?: InputMaybe<Currency>
}

export type CryptoDeposit = BaseDeposit & {
  __typename?: 'CryptoDeposit'
  amount: Scalars['Number']
  chain?: Maybe<Chain>
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fromAddress?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: DepositStatus
  toAddress: Scalars['String']
  txID?: Maybe<Scalars['String']>
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type CryptoWithdrawal = BaseWithdrawal & {
  __typename?: 'CryptoWithdrawal'
  amount: Scalars['Number']
  chain: Chain
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fee: Scalars['Number']
  feeCurrency: Currency
  fromAddress?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isDeduction: Scalars['Boolean']
  status: WithdrawalStatus
  toAddress: Scalars['String']
  txID?: Maybe<Scalars['String']>
  type: WithdrawalType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export enum Currency {
  Btc = 'BTC',
  Eth = 'ETH',
  Matic = 'MATIC',
  Trx = 'TRX',
  Twd = 'TWD',
  Usdt = 'USDT',
}

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount'
  amount: Scalars['String']
  currency: Scalars['String']
}

export type CurrencyBalance = {
  __typename?: 'CurrencyBalance'
  available: Scalars['String']
  total: Scalars['String']
}

export type CurrencyBalances = {
  __typename?: 'CurrencyBalances'
  balance: CurrencyBalance
  currency: Scalars['String']
}

export type CurrencyFilter = {
  currency?: InputMaybe<Array<Currency>>
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo'
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
}

export enum CurrencyType {
  CurrencyTypeCrypto = 'CurrencyTypeCrypto',
  CurrencyTypeFiat = 'CurrencyTypeFiat',
  CurrencyTypeStable = 'CurrencyTypeStable',
}

export type DefiTokensBalanceAmountInput = {
  chain: EvmChain
  chainId: Scalars['Int']
  tokenAddresses: Array<Scalars['String']>
}

export type DefiWallet = {
  __typename?: 'DefiWallet'
  balance: Scalars['String']
  balanceComputed?: Maybe<Scalars['String']>
  balanceComputedWithUnit?: Maybe<Scalars['String']>
  chain: EvmChain
  currencyInfo: CurrencyInfo
  iconifySrc: Scalars['String']
  id: Scalars['String']
}

export type DefiWalletFilterInput = {
  address: Scalars['String']
}

export type DefiWalletsFilterInput = {
  chains?: InputMaybe<Array<EvmChain>>
}

export type Deposit = CryptoDeposit | FiatDeposit | InternalTransferIn

export type DepositFilter = {
  currency?: InputMaybe<Array<Currency>>
  depositID?: InputMaybe<Scalars['ID']>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<DepositStatus>>
  type?: InputMaybe<Array<DepositType>>
}

export type DepositResponse = BaseResponse & {
  __typename?: 'DepositResponse'
  data?: Maybe<Array<Deposit>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export enum DepositStatus {
  DepositStatusAmlScreenIgnored = 'DepositStatusAMLScreenIgnored',
  DepositStatusBlocklistIgnored = 'DepositStatusBlocklistIgnored',
  DepositStatusCompleted = 'DepositStatusCompleted',
  DepositStatusRejected = 'DepositStatusRejected',
  DepositStatusWaitingConfirmation = 'DepositStatusWaitingConfirmation',
}

export enum DepositType {
  DepositTypeCrypto = 'DepositTypeCrypto',
  DepositTypeFiat = 'DepositTypeFiat',
  DepositTypeInternalTransfer = 'DepositTypeInternalTransfer',
  DepositTypeManualDeposit = 'DepositTypeManualDeposit',
  DepositTypeStakeReward = 'DepositTypeStakeReward',
}

export type DevMutation = {
  __typename?: 'DevMutation'
  acceptFiatWithdrawal: AcceptFiatWithdrawalResponse
  approveKYCLv2: ApproveKycLv2Response
  rejectFiatWithdrawal: RejectFiatWithdrawalResponse
}

export type DevMutationAcceptFiatWithdrawalArgs = {
  input: AcceptFiatWithdrawalInput
}

export type DevMutationApproveKycLv2Args = {
  input: ApproveKycLv2Input
}

export type DevMutationRejectFiatWithdrawalArgs = {
  input: RejectFiatWithdrawalInput
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItem = Entry & {
  __typename?: 'DisplayItem'
  contentfulMetadata: ContentfulMetadata
  defaultSharingBackground?: Maybe<Asset>
  enableSpecialSharingBackgroundsMode?: Maybe<Scalars['Boolean']>
  forUsersCollection?: Maybe<DisplayItemForUsersCollection>
  linkedFrom?: Maybe<DisplayItemLinkingCollections>
  purpose?: Maybe<Scalars['String']>
  specialSharingBackgroundsCollection?: Maybe<AssetCollection>
  sys: Sys
  title?: Maybe<Scalars['String']>
  userProfileImage?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemDefaultSharingBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemEnableSpecialSharingBackgroundsModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemForUsersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<DisplayItemForUsersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SpecialUserFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemPurposeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemSpecialSharingBackgroundsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/displayItem) */
export type DisplayItemUserProfileImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type DisplayItemCollection = {
  __typename?: 'DisplayItemCollection'
  items: Array<Maybe<DisplayItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type DisplayItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<DisplayItemFilter>>>
  OR?: InputMaybe<Array<InputMaybe<DisplayItemFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  defaultSharingBackground_exists?: InputMaybe<Scalars['Boolean']>
  enableSpecialSharingBackgroundsMode?: InputMaybe<Scalars['Boolean']>
  enableSpecialSharingBackgroundsMode_exists?: InputMaybe<Scalars['Boolean']>
  enableSpecialSharingBackgroundsMode_not?: InputMaybe<Scalars['Boolean']>
  forUsers?: InputMaybe<CfSpecialUserNestedFilter>
  forUsersCollection_exists?: InputMaybe<Scalars['Boolean']>
  purpose?: InputMaybe<Scalars['String']>
  purpose_contains?: InputMaybe<Scalars['String']>
  purpose_exists?: InputMaybe<Scalars['Boolean']>
  purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  purpose_not?: InputMaybe<Scalars['String']>
  purpose_not_contains?: InputMaybe<Scalars['String']>
  purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  specialSharingBackgroundsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  userProfileImage_exists?: InputMaybe<Scalars['Boolean']>
}

export type DisplayItemForUsersCollection = {
  __typename?: 'DisplayItemForUsersCollection'
  items: Array<Maybe<SpecialUser>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum DisplayItemForUsersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type DisplayItemLinkingCollections = {
  __typename?: 'DisplayItemLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type DisplayItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum DisplayItemOrder {
  EnableSpecialSharingBackgroundsModeAsc = 'enableSpecialSharingBackgroundsMode_ASC',
  EnableSpecialSharingBackgroundsModeDesc = 'enableSpecialSharingBackgroundsMode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum EddStatus {
  EddStatusAccepted = 'EDDStatusAccepted',
  EddStatusPending = 'EDDStatusPending',
  EddStatusRejected = 'EDDStatusRejected',
  EddStatusUnverified = 'EDDStatusUnverified',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata
  sys: Sys
}

export type EntryCollection = {
  __typename?: 'EntryCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum EvmChain {
  Arbitrum = 'Arbitrum',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  Optimism = 'Optimism',
  OptimismGoerli = 'OptimismGoerli',
  Polygon = 'Polygon',
  Unknown = 'Unknown',
}

export enum Exchange {
  Bitgin = 'BITGIN',
  Binance = 'Binance',
  Bitfinex = 'Bitfinex',
  Convex = 'Convex',
  Ftx = 'FTX',
  Max = 'MAX',
  Tradingview = 'Tradingview',
  Uniswap = 'Uniswap',
  Unknown = 'Unknown',
}

export type ExternalApiKey = {
  __typename?: 'ExternalAPIKey'
  createdAt?: Maybe<Scalars['String']>
  exchange: Exchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  user?: Maybe<UserInfo>
  userId?: Maybe<Scalars['String']>
}

export type ExternalApiKeyCreateInput = {
  exchange: Exchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
}

export type ExternalApiKeyDeleteInput = {
  id: Scalars['String']
}

export type ExternalApiKeyWithdrawal = {
  __typename?: 'ExternalAPIKeyWithdrawal'
  id: Scalars['ID']
}

export type ExternalApiKeysFilter = {
  exchange?: InputMaybe<Exchange>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export enum ExternalWallet {
  WalletBfxExchange = 'WalletBFXExchange',
  WalletBfxFunding = 'WalletBFXFunding',
  WalletBfxMargin = 'WalletBFXMargin',
  WalletBncSaving = 'WalletBNCSaving',
  /** Default to Spot if exchange is not supporting different wallet types */
  WalletSpot = 'WalletSpot',
}

export type FaasPayment = {
  __typename?: 'FAASPayment'
  createdAt: Scalars['Time']
  cryptoWithdrawal?: Maybe<CryptoWithdrawal>
  id: Scalars['ID']
  internalTransferOut?: Maybe<InternalTransferOut>
  merchantID: Scalars['ID']
  updatedAt: Scalars['Time']
}

export type FaasPaymentFilter = {
  currencies?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  merchantID?: InputMaybe<Scalars['ID']>
  orderID?: InputMaybe<Scalars['String']>
  paymentID?: InputMaybe<Scalars['ID']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<WithdrawalStatus>>
  type?: InputMaybe<Array<WithdrawalType>>
}

export type FaasPaymentResponse = BaseResponse & {
  __typename?: 'FAASPaymentResponse'
  data?: Maybe<Array<FaasPayment>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type FiatDeposit = BaseDeposit & {
  __typename?: 'FiatDeposit'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  id: Scalars['ID']
  status: DepositStatus
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type FiatDepositInfo = {
  __typename?: 'FiatDepositInfo'
  dailyAccumulated: Scalars['Number']
  monthlyAccumulated: Scalars['Number']
  userBindBank: BankAccount
  virtualBank: BankAccount
}

export type FiatWithdrawal = BaseWithdrawal & {
  __typename?: 'FiatWithdrawal'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fee: Scalars['Number']
  feeCurrency: Currency
  id: Scalars['ID']
  status: WithdrawalStatus
  toBankAccount: BankInfo
  toBankAccountID: Scalars['ID']
  type: WithdrawalType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type FrontendVersion = {
  __typename?: 'FrontendVersion'
  commitHash: Scalars['String']
  smartContracts: Array<SmartContract>
  version: Scalars['String']
}

export enum Gender {
  GenderFemale = 'GenderFemale',
  GenderMale = 'GenderMale',
}

export type GimCoolPayPaymentInfo = {
  __typename?: 'GimCoolPayPaymentInfo'
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount?: Maybe<Scalars['String']>
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatAmount?: Maybe<Scalars['String']>
  fiatType: GimCoolPayPaymentSupportingFiatType
  paymentHref: Scalars['String']
  price?: Maybe<GimCoolPayPriceResponse>
  walletAddress: Scalars['String']
  walletAddressTag?: Maybe<Scalars['String']>
}

export type GimCoolPayPaymentInfoInput = {
  blockchain: GimCoolPayPaymentSupportingBlockchain
  coinAmount: Scalars['String']
  coinType: GimCoolPayPaymentSupportingCoinType
  fiatType: GimCoolPayPaymentSupportingFiatType
  isSandBox?: InputMaybe<Scalars['Boolean']>
}

export enum GimCoolPayPaymentSupportingBlockchain {
  Eth = 'ETH',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingCoinType {
  Eth = 'ETH',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Unknown = 'Unknown',
}

export enum GimCoolPayPaymentSupportingFiatType {
  Usd = 'USD',
  Unknown = 'Unknown',
}

export type GimCoolPayPriceResponse = {
  __typename?: 'GimCoolPayPriceResponse'
  prices?: Maybe<Array<GimCoolPayReadyToPurchasePrice>>
  spotPrice: Scalars['String']
}

export type GimCoolPayReadyToPurchasePrice = {
  __typename?: 'GimCoolPayReadyToPurchasePrice'
  coinAmount: Scalars['String']
  coinCode: Scalars['String']
  feeAmount: Scalars['String']
  fiatAmount: Scalars['String']
  fiatCode: Scalars['String']
  networkFee: Scalars['String']
  paymentMethodId: Scalars['String']
  spotPriceFee: Scalars['String']
  spotPriceIncludingFee: Scalars['String']
  type: Scalars['String']
}

export enum Granularity {
  Granularity1d = 'Granularity1d',
  Granularity1h = 'Granularity1h',
  Granularity1m = 'Granularity1m',
  Granularity1w = 'Granularity1w',
  Granularity4h = 'Granularity4h',
  Granularity5m = 'Granularity5m',
  Granularity12h = 'Granularity12h',
  Granularity15m = 'Granularity15m',
  Granularity30m = 'Granularity30m',
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSlider = Entry & {
  __typename?: 'HeroSlider'
  contentfulMetadata: ContentfulMetadata
  image?: Maybe<Asset>
  link?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<HeroSliderLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/heroSlider) */
export type HeroSliderTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type HeroSliderCollection = {
  __typename?: 'HeroSliderCollection'
  items: Array<Maybe<HeroSlider>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HeroSliderFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeroSliderFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HeroSliderFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HeroSliderLinkingCollections = {
  __typename?: 'HeroSliderLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type HeroSliderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum HeroSliderOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Holiday = {
  __typename?: 'Holiday'
  category?: Maybe<Scalars['String']>
  /** date is in YYYY-MM-DD */
  date: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type IdentityInfo = {
  __typename?: 'IdentityInfo'
  barcodeNumber?: Maybe<Scalars['String']>
  createdAt: Scalars['Time']
  expirationDate?: Maybe<Scalars['Time']>
  id: Scalars['ID']
  idNumber?: Maybe<Scalars['String']>
  idType?: Maybe<IdentityType>
  issuanceDate?: Maybe<Scalars['Time']>
  issuanceLocation?: Maybe<Scalars['String']>
  issuanceType?: Maybe<IdentityIssuanceType>
  nationality?: Maybe<Scalars['String']>
  updatedAt: Scalars['Time']
  verifyStatus?: Maybe<IdentityVerifyStatus>
}

export type IdentityInfoInput = {
  addressPhotoResourceID?: InputMaybe<Scalars['ID']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  expirationDate?: InputMaybe<Scalars['Time']>
  idBackPhotoResourceID?: InputMaybe<Scalars['ID']>
  idFrontPhotoResourceID?: InputMaybe<Scalars['ID']>
  idNumber?: InputMaybe<Scalars['String']>
  idType?: InputMaybe<IdentityType>
  issuanceDate?: InputMaybe<Scalars['Time']>
  issuanceLocation?: InputMaybe<Scalars['String']>
  issuanceType?: InputMaybe<IdentityIssuanceType>
  nationality?: InputMaybe<Scalars['String']>
  secondIDPhotoResourceID?: InputMaybe<Scalars['ID']>
  selfiePhotoResourceID?: InputMaybe<Scalars['ID']>
}

export enum IdentityIssuanceType {
  IdentityIssuanceTypeInitial = 'IdentityIssuanceTypeInitial',
  IdentityIssuanceTypeReissue = 'IdentityIssuanceTypeReissue',
  IdentityIssuanceTypeReplacement = 'IdentityIssuanceTypeReplacement',
}

export enum IdentityType {
  IdentityTypeArc = 'IdentityTypeARC',
  IdentityTypeIdCard = 'IdentityTypeIDCard',
}

export enum IdentityVerifyStatus {
  IdentityVerifyStatusFailed = 'IdentityVerifyStatusFailed',
  IdentityVerifyStatusSuccess = 'IdentityVerifyStatusSuccess',
  IdentityVerifyStatusUnverified = 'IdentityVerifyStatusUnverified',
  IdentityVerifyStatusZ21Disable = 'IdentityVerifyStatusZ21Disable',
  IdentityVerifyStatusZ21Missing = 'IdentityVerifyStatusZ21Missing',
  IdentityVerifyStatusZ21Suspend = 'IdentityVerifyStatusZ21Suspend',
  IdentityVerifyStatusZ21SystemUnavailable = 'IdentityVerifyStatusZ21SystemUnavailable',
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>
}

export enum Industry {
  IndustryCategoryA = 'IndustryCategoryA',
  IndustryCategoryAa = 'IndustryCategoryAA',
  IndustryCategoryAb = 'IndustryCategoryAB',
  IndustryCategoryAc = 'IndustryCategoryAC',
  IndustryCategoryAd = 'IndustryCategoryAD',
  IndustryCategoryAe = 'IndustryCategoryAE',
  IndustryCategoryAf = 'IndustryCategoryAF',
  IndustryCategoryAg = 'IndustryCategoryAG',
  IndustryCategoryB = 'IndustryCategoryB',
  IndustryCategoryC = 'IndustryCategoryC',
  IndustryCategoryD = 'IndustryCategoryD',
  IndustryCategoryE = 'IndustryCategoryE',
  IndustryCategoryF = 'IndustryCategoryF',
  IndustryCategoryG = 'IndustryCategoryG',
  IndustryCategoryH = 'IndustryCategoryH',
  IndustryCategoryI = 'IndustryCategoryI',
  IndustryCategoryJ = 'IndustryCategoryJ',
  IndustryCategoryK = 'IndustryCategoryK',
  IndustryCategoryL = 'IndustryCategoryL',
  IndustryCategoryM = 'IndustryCategoryM',
  IndustryCategoryN = 'IndustryCategoryN',
  IndustryCategoryO = 'IndustryCategoryO',
  IndustryCategoryP = 'IndustryCategoryP',
  IndustryCategoryQ = 'IndustryCategoryQ',
  IndustryCategoryR = 'IndustryCategoryR',
  IndustryCategoryS = 'IndustryCategoryS',
  IndustryCategoryT = 'IndustryCategoryT',
  IndustryCategoryU = 'IndustryCategoryU',
  IndustryCategoryV = 'IndustryCategoryV',
  IndustryCategoryW = 'IndustryCategoryW',
  IndustryCategoryX = 'IndustryCategoryX',
  IndustryCategoryY = 'IndustryCategoryY',
  IndustryCategoryZ = 'IndustryCategoryZ',
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export type InternalTransferIn = BaseDeposit & {
  __typename?: 'InternalTransferIn'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fromUserID: Scalars['ID']
  id: Scalars['ID']
  status: DepositStatus
  type: DepositType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type InternalTransferOut = BaseWithdrawal & {
  __typename?: 'InternalTransferOut'
  amount: Scalars['Number']
  completedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  currency: Currency
  fee: Scalars['Number']
  feeCurrency: Currency
  id: Scalars['ID']
  status: WithdrawalStatus
  toAddress: Scalars['String']
  toUserID: Scalars['ID']
  type: WithdrawalType
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type Kyc = {
  __typename?: 'KYC'
  /** deprecated in the future */
  aml?: Maybe<AmlVerification>
  lv1?: Maybe<KycLevelOne>
  lv2?: Maybe<KycLevelTwo>
}

export type KycConfirmSubmissionInput = {
  level: KycLevel
}

export type KycConfirmSubmissionResponse = BaseResponse & {
  __typename?: 'KYCConfirmSubmissionResponse'
  result: Scalars['Boolean']
}

export enum KycLevel {
  KycLevelOne = 'KYCLevelOne',
  KycLevelTwo = 'KYCLevelTwo',
}

export type KycLevelOne = {
  __typename?: 'KYCLevelOne'
  addressPhoto?: Maybe<KycPhoto>
  basicInfo?: Maybe<BasicInfo>
  createdAt: Scalars['Time']
  id: Scalars['ID']
  idBackPhoto?: Maybe<KycPhoto>
  idFrontPhoto?: Maybe<KycPhoto>
  identityInfo?: Maybe<IdentityInfo>
  isReSubmitted: Scalars['Boolean']
  /** deprecated in the future */
  questions?: Maybe<QuestionInfo>
  secondIDPhoto?: Maybe<KycPhoto>
  selfiePhoto?: Maybe<KycPhoto>
  status: KycStatus
  updatedAt: Scalars['Time']
  verifier?: Maybe<User>
}

export type KycLevelTwo = {
  __typename?: 'KYCLevelTwo'
  bankInfo?: Maybe<BankInfo>
  bankPhoto?: Maybe<KycPhoto>
  createdAt: Scalars['Time']
  id: Scalars['ID']
  isReSubmitted: Scalars['Boolean']
  status: KycStatus
  updatedAt: Scalars['Time']
  verifier?: Maybe<User>
}

export type KycMutation = {
  __typename?: 'KYCMutation'
  kycConfirmSubmission: KycConfirmSubmissionResponse
  kycSubmitLevelOne: KycSubmitLevelOneResponse
  kycSubmitLevelTwo: KycSubmitLevelTwoResponse
}

export type KycMutationKycConfirmSubmissionArgs = {
  input: KycConfirmSubmissionInput
}

export type KycMutationKycSubmitLevelOneArgs = {
  input: KycSubmitLevelOneInput
}

export type KycMutationKycSubmitLevelTwoArgs = {
  input: KycSubmitLevelTwoInput
}

export type KycPhoto = {
  __typename?: 'KYCPhoto'
  createdAt: Scalars['Time']
  firstVerification?: Maybe<KycPhotoVerification>
  id: Scalars['ID']
  rejectedReason?: Maybe<Scalars['String']>
  resourceURL?: Maybe<Scalars['String']>
  secondVerification?: Maybe<KycPhotoVerification>
  status: KycPhotoStatus
  updatedAt: Scalars['Time']
}

export enum KycPhotoStatus {
  Rejected = 'Rejected',
  Verified = 'Verified',
  Verifying = 'Verifying',
}

export type KycPhotoVerification = {
  __typename?: 'KYCPhotoVerification'
  createdAt: Scalars['Time']
  id: Scalars['ID']
  isVerified?: Maybe<Scalars['Boolean']>
  rejectedReason?: Maybe<Scalars['String']>
  updatedAt: Scalars['Time']
  verifier: User
}

export enum KycStatus {
  KycStatusAmlVerifying = 'KYCStatusAMLVerifying',
  KycStatusFirstVerifying = 'KYCStatusFirstVerifying',
  KycStatusKgiVerifying = 'KYCStatusKGIVerifying',
  KycStatusPreparing = 'KYCStatusPreparing',
  KycStatusRejected = 'KYCStatusRejected',
  KycStatusSecondVerifying = 'KYCStatusSecondVerifying',
  KycStatusVerified = 'KYCStatusVerified',
}

export type KycSubmitLevelOneInput = {
  basicInfo?: InputMaybe<BasicInfoInput>
  identityInfo?: InputMaybe<IdentityInfoInput>
  questionnaire?: InputMaybe<QuestionnaireInput>
}

export type KycSubmitLevelOneResponse = BaseResponse & {
  __typename?: 'KYCSubmitLevelOneResponse'
  result: Scalars['Boolean']
}

export type KycSubmitLevelTwoInput = {
  bankInfo?: InputMaybe<BankInfoInput>
}

export type KycSubmitLevelTwoResponse = BaseResponse & {
  __typename?: 'KYCSubmitLevelTwoResponse'
  result: Scalars['Boolean']
}

export type KgiUploadResponse = {
  __typename?: 'KgiUploadResponse'
  Code: Scalars['String']
  Desc: Scalars['String']
  Filename: Scalars['String']
  Status: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessage = Entry & {
  __typename?: 'LandingPageCustomMessage'
  contentfulMetadata: ContentfulMetadata
  customMessage?: Maybe<LandingPageCustomMessageCustomMessage>
  displayEnvironment?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['DateTime']>
  linkedFrom?: Maybe<LandingPageCustomMessageLinkingCollections>
  startTime?: Maybe<Scalars['DateTime']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageCustomMessageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageDisplayEnvironmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageEndTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/landingPageCustomMessage) */
export type LandingPageCustomMessageStartTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type LandingPageCustomMessageCollection = {
  __typename?: 'LandingPageCustomMessageCollection'
  items: Array<Maybe<LandingPageCustomMessage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type LandingPageCustomMessageCustomMessage = {
  __typename?: 'LandingPageCustomMessageCustomMessage'
  json: Scalars['JSON']
  links: LandingPageCustomMessageCustomMessageLinks
}

export type LandingPageCustomMessageCustomMessageAssets = {
  __typename?: 'LandingPageCustomMessageCustomMessageAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type LandingPageCustomMessageCustomMessageEntries = {
  __typename?: 'LandingPageCustomMessageCustomMessageEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type LandingPageCustomMessageCustomMessageLinks = {
  __typename?: 'LandingPageCustomMessageCustomMessageLinks'
  assets: LandingPageCustomMessageCustomMessageAssets
  entries: LandingPageCustomMessageCustomMessageEntries
  resources: LandingPageCustomMessageCustomMessageResources
}

export type LandingPageCustomMessageCustomMessageResources = {
  __typename?: 'LandingPageCustomMessageCustomMessageResources'
  block: Array<ResourceLink>
}

export type LandingPageCustomMessageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LandingPageCustomMessageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<LandingPageCustomMessageFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customMessage_contains?: InputMaybe<Scalars['String']>
  customMessage_exists?: InputMaybe<Scalars['Boolean']>
  customMessage_not_contains?: InputMaybe<Scalars['String']>
  displayEnvironment?: InputMaybe<Scalars['String']>
  displayEnvironment_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_exists?: InputMaybe<Scalars['Boolean']>
  displayEnvironment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayEnvironment_not?: InputMaybe<Scalars['String']>
  displayEnvironment_not_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  endTime?: InputMaybe<Scalars['DateTime']>
  endTime_exists?: InputMaybe<Scalars['Boolean']>
  endTime_gt?: InputMaybe<Scalars['DateTime']>
  endTime_gte?: InputMaybe<Scalars['DateTime']>
  endTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  endTime_lt?: InputMaybe<Scalars['DateTime']>
  endTime_lte?: InputMaybe<Scalars['DateTime']>
  endTime_not?: InputMaybe<Scalars['DateTime']>
  endTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_exists?: InputMaybe<Scalars['Boolean']>
  startTime_gt?: InputMaybe<Scalars['DateTime']>
  startTime_gte?: InputMaybe<Scalars['DateTime']>
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime_lt?: InputMaybe<Scalars['DateTime']>
  startTime_lte?: InputMaybe<Scalars['DateTime']>
  startTime_not?: InputMaybe<Scalars['DateTime']>
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
}

export type LandingPageCustomMessageLinkingCollections = {
  __typename?: 'LandingPageCustomMessageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type LandingPageCustomMessageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum LandingPageCustomMessageOrder {
  DisplayEnvironmentAsc = 'displayEnvironment_ASC',
  DisplayEnvironmentDesc = 'displayEnvironment_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Ledger = {
  __typename?: 'Ledger'
  amount: Scalars['Number']
  balance: Scalars['Number']
  createdAt: Scalars['Time']
  currency: Currency
  id: Scalars['ID']
  ledgerEntry: LedgerEntry
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type LedgerEntry =
  | CryptoDeposit
  | CryptoWithdrawal
  | FiatDeposit
  | FiatWithdrawal
  | InternalTransferIn
  | InternalTransferOut
  | Trade

export type LedgerFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  ledgerID?: InputMaybe<Scalars['ID']>
  startDate?: InputMaybe<Scalars['Time']>
  type?: InputMaybe<Array<WalletOperationType>>
}

export type LedgerResponse = BaseResponse & {
  __typename?: 'LedgerResponse'
  data?: Maybe<Array<Ledger>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type LoginInfo = {
  __typename?: 'LoginInfo'
  accessToken: Scalars['String']
  expiresIn: Scalars['Int']
  refreshToken?: Maybe<Scalars['String']>
}

export type LoginResponse = LoginInfo | Response2Fa

export enum Market {
  Btctwd = 'BTCTWD',
  Ethtwd = 'ETHTWD',
  Usdttwd = 'USDTTWD',
}

export type MarketInfo = {
  __typename?: 'MarketInfo'
  feeRate: Scalars['Number']
  isTradable: Scalars['Boolean']
  market: Market
  maxSize: Scalars['Number']
  minSize: Scalars['Number']
  priceIncrement: Scalars['Number']
  sizeIncrement: Scalars['Number']
}

export type MarketPrice = {
  __typename?: 'MarketPrice'
  ask: Scalars['Number']
  bid: Scalars['Number']
  market: Market
  time: Scalars['Time']
  type: PriceType
}

export type Me = {
  __typename?: 'Me'
  id: Scalars['ID']
  information: User
}

export type MetaPagination = {
  __typename?: 'MetaPagination'
  total: Scalars['Int']
}

export type Metric = {
  __typename?: 'Metric'
  createdAt: Scalars['String']
  id: Scalars['String']
  positionManagersInfo?: Maybe<Array<PositionManagerInfo>>
  positions?: Maybe<Array<MetricPosition>>
}

export type MetricPosition = {
  __typename?: 'MetricPosition'
  amountUsd: Scalars['String']
  chain: EvmChain
  createdAt: Scalars['String']
  feeUsd: Scalars['String']
  id: Scalars['String']
  positionId: Scalars['Int']
  positionIdTotalCount: Scalars['Int']
  positionIsRunning: Scalars['Boolean']
  positionManagerContractAddress: Scalars['String']
  positionOwnerAddress: Scalars['String']
  strategyId: Scalars['String']
  strategyProvider: Scalars['String']
  sumUsd: Scalars['String']
}

export enum MobileOs {
  MobileOsAndroid = 'MobileOSAndroid',
  MobileOsIos = 'MobileOSIos',
  MobileOsWindowsPhone = 'MobileOSWindowsPhone',
}

export type Mutation = {
  __typename?: 'Mutation'
  closeUniswapv3Position?: Maybe<Scalars['String']>
  createAICompletionRecord: AICompletionRecords
  /** Reyield Backend will monitor this position with the txHash (either pending or confirmed) */
  createUniswapv3Position?: Maybe<Scalars['String']>
  createUniswapv3Tool?: Maybe<Scalars['String']>
  externalAPIKeyCreate: ExternalApiKey
  externalAPIKeyDelete: ExternalApiKey
  generateMetrics: Scalars['Boolean']
  strategyStart: StrategyStartResponse
  strategyStop: StrategyStopResponse
  strategyUpdate: StrategyUpdateResponse
}

export type MutationCloseUniswapv3PositionArgs = {
  input: CloseUniswapv3PositionInput
}

export type MutationCreateAiCompletionRecordArgs = {
  input: CreateAiCompletionRecordInput
}

export type MutationCreateUniswapv3PositionArgs = {
  input: CreateUniswapv3PositionInput
}

export type MutationCreateUniswapv3ToolArgs = {
  input: CreateUniswapv3ToolInput
}

export type MutationExternalApiKeyCreateArgs = {
  input: ExternalApiKeyCreateInput
}

export type MutationExternalApiKeyDeleteArgs = {
  input: ExternalApiKeyDeleteInput
}

export type MutationStrategyStartArgs = {
  input: StrategyStartInput
}

export type MutationStrategyStopArgs = {
  input: StrategyStopInput
}

export type MutationStrategyUpdateArgs = {
  input: StrategyUpdateInput
}

export type NftCollections = {
  __typename?: 'NFTCollections'
  attributes?: Maybe<Array<Maybe<Attributes>>>
  contract: Scalars['String']
  description: Scalars['String']
  imageUrl: Scalars['String']
  name: Scalars['String']
  tokenId: Scalars['String']
}

export type NftMerkleTreeHexProof = {
  __typename?: 'NFTMerkleTreeHexProof'
  hexProof?: Maybe<Array<Scalars['String']>>
}

export type NftWhitelistInfoByPhrase = {
  __typename?: 'NFTWhitelistInfoByPhrase'
  id: Scalars['String']
  isWhitelisted: Scalars['Boolean']
  phrase: Nft_Round
  userAddress: Scalars['String']
}

export enum Nft_Round {
  Airdrop = 'AIRDROP',
  AllowWhitelistMint = 'ALLOW_WHITELIST_MINT',
  GuaranteeWhitelistMint = 'GUARANTEE_WHITELIST_MINT',
  SoldOut = 'SOLD_OUT',
  WhitelistCheck = 'WHITELIST_CHECK',
}

export enum NsStatus {
  NsStatusFake = 'NSStatusFake',
  NsStatusNotFound = 'NSStatusNotFound',
  NsStatusPending = 'NSStatusPending',
  NsStatusReal = 'NSStatusReal',
  NsStatusUnverified = 'NSStatusUnverified',
}

export type Notification = {
  __typename?: 'Notification'
  notificationCount: NotificationCountResponse
  notificationList: NotificationListResponse
}

export type NotificationNotificationCountArgs = {
  filter?: InputMaybe<NotificationCountFilter>
}

export type NotificationNotificationListArgs = {
  filter?: InputMaybe<NotificationListFilter>
  pagination?: InputMaybe<PaginationQuery>
}

export type NotificationCount = {
  __typename?: 'NotificationCount'
  num: Scalars['Int']
}

export type NotificationCountFilter = {
  isRead?: InputMaybe<Scalars['Boolean']>
  productName?: InputMaybe<ProductName>
}

export type NotificationCountResponse = BaseResponse & {
  __typename?: 'NotificationCountResponse'
  data: NotificationCount
  result: Scalars['Boolean']
}

export type NotificationInfo = {
  __typename?: 'NotificationInfo'
  id: Scalars['ID']
  isRead: Scalars['Boolean']
  link?: Maybe<Scalars['String']>
  message: Scalars['String']
  productName: ProductName
  timestamp: Scalars['Time']
  title: Scalars['String']
}

export type NotificationListFilter = {
  productName?: InputMaybe<ProductName>
}

export type NotificationListResponse = BaseResponse & {
  __typename?: 'NotificationListResponse'
  data: Array<NotificationInfo>
  result: Scalars['Boolean']
}

export type NotificationMutation = {
  __typename?: 'NotificationMutation'
  notificationRead: NotificationReadResponse
}

export type NotificationMutationNotificationReadArgs = {
  input?: InputMaybe<NotificationReadInput>
}

export enum NotificationProvider {
  /** NotificationProviderAPNs: Apple Push Notification Service */
  NotificationProviderApNs = 'NotificationProviderAPNs',
  /** NotificationProviderFCM: Firebase Cloud Message */
  NotificationProviderFcm = 'NotificationProviderFCM',
  /** NotificationProviderSNS:  Amazon Simple Notification Service */
  NotificationProviderSns = 'NotificationProviderSNS',
}

export type NotificationReadFilter = {
  notificationIDs?: InputMaybe<Array<Scalars['ID']>>
  productName?: InputMaybe<ProductName>
}

export type NotificationReadInput = {
  filter?: InputMaybe<NotificationReadFilter>
}

export type NotificationReadResponse = BaseResponse & {
  __typename?: 'NotificationReadResponse'
  result: Scalars['Boolean']
}

export type OnHoldKgiVerification = {
  __typename?: 'OnHoldKGIVerification'
  filename: Scalars['String']
  id: Scalars['ID']
  status: Scalars['String']
  txID: Scalars['String']
  userID: Scalars['ID']
}

export enum OrderSide {
  OrderSideBuy = 'OrderSideBuy',
  OrderSideSell = 'OrderSideSell',
  OrderSideUnknown = 'OrderSideUnknown',
}

export type OutOfRangeInfoInput = {
  earnSpendRate: Scalars['Int']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type PaginationInput = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type PaginationQuery = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export enum PaymentMethod {
  PaymentMethodBitcoin = 'PaymentMethodBitcoin',
  PaymentMethodErc20 = 'PaymentMethodERC20',
  PaymentMethodEthereum = 'PaymentMethodEthereum',
  PaymentMethodSwift = 'PaymentMethodSWIFT',
  PaymentMethodTrc20 = 'PaymentMethodTRC20',
}

export type PhotoVerificationResultInput = {
  rejectedReason?: InputMaybe<Scalars['String']>
  verified: Scalars['Boolean']
}

export type Point = {
  __typename?: 'Point'
  ledgers: PointLedgerRecordResponse
  quantity: Scalars['Int']
}

export type PointLedgersArgs = {
  filter?: InputMaybe<PointLedgerFilter>
  pagination: PaginationQuery
}

export type PointLedgerFilter = {
  productID?: InputMaybe<Scalars['ID']>
}

export type PointLedgerRecord = {
  __typename?: 'PointLedgerRecord'
  description: Scalars['String']
  id: Scalars['ID']
  points: Scalars['Int']
  /** 產品ID, 例如策略, 就帶入StrategyID */
  productID?: Maybe<Scalars['ID']>
  time: Scalars['Time']
  total: Scalars['Int']
  type: PointType
}

export type PointLedgerRecordResponse = {
  __typename?: 'PointLedgerRecordResponse'
  data?: Maybe<Array<Maybe<PointLedgerRecord>>>
  result: Scalars['Boolean']
}

export type PointMutation = {
  __typename?: 'PointMutation'
  /** pointPurchase: 購買點數 */
  pointPurchase: PointPurchaseResponse
}

export type PointMutationPointPurchaseArgs = {
  input: PointPurchaseInput
}

export type PointPurchaseInput = {
  /** number 購買的數量 */
  number: Scalars['Int']
}

export type PointPurchaseResponse = BaseResponse & {
  __typename?: 'PointPurchaseResponse'
  /** number 實際購買的數量 */
  number: Scalars['Int']
  /** price 實際每點花費的 USDT */
  price: Scalars['Number']
  result: Scalars['Boolean']
  /** total 實際總花費 */
  total: Scalars['Number']
}

export enum PointType {
  PointTypeCreate = 'PointTypeCreate',
  PointTypeDeductStrategy = 'PointTypeDeductStrategy',
  PointTypeFillCash = 'PointTypeFillCash',
  PointTypeFillPurchase = 'PointTypeFillPurchase',
  PointTypeFillReferral = 'PointTypeFillReferral',
  PointTypeFillReferrer = 'PointTypeFillReferrer',
  PointTypeFillTrade = 'PointTypeFillTrade',
  PointTypeFillVoucher = 'PointTypeFillVoucher',
  PointTypeRepayStrategyDebt = 'PointTypeRepayStrategyDebt',
}

export enum PoolsWithArgumentsFlag {
  /** This will use default value to filter */
  Default = 'Default',
  /** This will include selected Tokens (Ex: BTC, ETH ...) */
  Generic = 'Generic',
  /** This will include stable tokens (Ex: USDT, USDC ...) */
  Stables = 'Stables',
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUp = Entry & {
  __typename?: 'PopUp'
  backgroundColor?: Maybe<Scalars['String']>
  callToActionButtonLink?: Maybe<Scalars['String']>
  callToActionButtonText?: Maybe<Scalars['String']>
  callToActionButtonVariant?: Maybe<Scalars['String']>
  closeButtonText?: Maybe<Scalars['String']>
  closeButtonVariant?: Maybe<Scalars['String']>
  contentArea?: Maybe<PopUpContentArea>
  contentfulMetadata: ContentfulMetadata
  displayEnvironment?: Maybe<Scalars['String']>
  displayMode?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['DateTime']>
  imageDesktop?: Maybe<Asset>
  imageModile?: Maybe<Asset>
  linkedFrom?: Maybe<PopUpLinkingCollections>
  priority?: Maybe<Scalars['Int']>
  startTime?: Maybe<Scalars['DateTime']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCallToActionButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCallToActionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCallToActionButtonVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCloseButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpCloseButtonVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpContentAreaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpDisplayEnvironmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpDisplayModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpEndTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpImageDesktopArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpImageModileArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpPriorityArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpStartTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/popUp) */
export type PopUpTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PopUpCollection = {
  __typename?: 'PopUpCollection'
  items: Array<Maybe<PopUp>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PopUpContentArea = {
  __typename?: 'PopUpContentArea'
  json: Scalars['JSON']
  links: PopUpContentAreaLinks
}

export type PopUpContentAreaAssets = {
  __typename?: 'PopUpContentAreaAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PopUpContentAreaEntries = {
  __typename?: 'PopUpContentAreaEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PopUpContentAreaLinks = {
  __typename?: 'PopUpContentAreaLinks'
  assets: PopUpContentAreaAssets
  entries: PopUpContentAreaEntries
  resources: PopUpContentAreaResources
}

export type PopUpContentAreaResources = {
  __typename?: 'PopUpContentAreaResources'
  block: Array<ResourceLink>
}

export type PopUpFilter = {
  AND?: InputMaybe<Array<InputMaybe<PopUpFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PopUpFilter>>>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonLink?: InputMaybe<Scalars['String']>
  callToActionButtonLink_contains?: InputMaybe<Scalars['String']>
  callToActionButtonLink_exists?: InputMaybe<Scalars['Boolean']>
  callToActionButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonLink_not?: InputMaybe<Scalars['String']>
  callToActionButtonLink_not_contains?: InputMaybe<Scalars['String']>
  callToActionButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonText?: InputMaybe<Scalars['String']>
  callToActionButtonText_contains?: InputMaybe<Scalars['String']>
  callToActionButtonText_exists?: InputMaybe<Scalars['Boolean']>
  callToActionButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonText_not?: InputMaybe<Scalars['String']>
  callToActionButtonText_not_contains?: InputMaybe<Scalars['String']>
  callToActionButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonVariant?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_contains?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_exists?: InputMaybe<Scalars['Boolean']>
  callToActionButtonVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionButtonVariant_not?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_not_contains?: InputMaybe<Scalars['String']>
  callToActionButtonVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonText?: InputMaybe<Scalars['String']>
  closeButtonText_contains?: InputMaybe<Scalars['String']>
  closeButtonText_exists?: InputMaybe<Scalars['Boolean']>
  closeButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonText_not?: InputMaybe<Scalars['String']>
  closeButtonText_not_contains?: InputMaybe<Scalars['String']>
  closeButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonVariant?: InputMaybe<Scalars['String']>
  closeButtonVariant_contains?: InputMaybe<Scalars['String']>
  closeButtonVariant_exists?: InputMaybe<Scalars['Boolean']>
  closeButtonVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  closeButtonVariant_not?: InputMaybe<Scalars['String']>
  closeButtonVariant_not_contains?: InputMaybe<Scalars['String']>
  closeButtonVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentArea_contains?: InputMaybe<Scalars['String']>
  contentArea_exists?: InputMaybe<Scalars['Boolean']>
  contentArea_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayEnvironment?: InputMaybe<Scalars['String']>
  displayEnvironment_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_exists?: InputMaybe<Scalars['Boolean']>
  displayEnvironment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayEnvironment_not?: InputMaybe<Scalars['String']>
  displayEnvironment_not_contains?: InputMaybe<Scalars['String']>
  displayEnvironment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode?: InputMaybe<Scalars['String']>
  displayMode_contains?: InputMaybe<Scalars['String']>
  displayMode_exists?: InputMaybe<Scalars['Boolean']>
  displayMode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode_not?: InputMaybe<Scalars['String']>
  displayMode_not_contains?: InputMaybe<Scalars['String']>
  displayMode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  endTime?: InputMaybe<Scalars['DateTime']>
  endTime_exists?: InputMaybe<Scalars['Boolean']>
  endTime_gt?: InputMaybe<Scalars['DateTime']>
  endTime_gte?: InputMaybe<Scalars['DateTime']>
  endTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  endTime_lt?: InputMaybe<Scalars['DateTime']>
  endTime_lte?: InputMaybe<Scalars['DateTime']>
  endTime_not?: InputMaybe<Scalars['DateTime']>
  endTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  imageDesktop_exists?: InputMaybe<Scalars['Boolean']>
  imageModile_exists?: InputMaybe<Scalars['Boolean']>
  priority?: InputMaybe<Scalars['Int']>
  priority_exists?: InputMaybe<Scalars['Boolean']>
  priority_gt?: InputMaybe<Scalars['Int']>
  priority_gte?: InputMaybe<Scalars['Int']>
  priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  priority_lt?: InputMaybe<Scalars['Int']>
  priority_lte?: InputMaybe<Scalars['Int']>
  priority_not?: InputMaybe<Scalars['Int']>
  priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_exists?: InputMaybe<Scalars['Boolean']>
  startTime_gt?: InputMaybe<Scalars['DateTime']>
  startTime_gte?: InputMaybe<Scalars['DateTime']>
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime_lt?: InputMaybe<Scalars['DateTime']>
  startTime_lte?: InputMaybe<Scalars['DateTime']>
  startTime_not?: InputMaybe<Scalars['DateTime']>
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PopUpLinkingCollections = {
  __typename?: 'PopUpLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PopUpLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PopUpOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  CallToActionButtonLinkAsc = 'callToActionButtonLink_ASC',
  CallToActionButtonLinkDesc = 'callToActionButtonLink_DESC',
  CallToActionButtonTextAsc = 'callToActionButtonText_ASC',
  CallToActionButtonTextDesc = 'callToActionButtonText_DESC',
  CallToActionButtonVariantAsc = 'callToActionButtonVariant_ASC',
  CallToActionButtonVariantDesc = 'callToActionButtonVariant_DESC',
  CloseButtonTextAsc = 'closeButtonText_ASC',
  CloseButtonTextDesc = 'closeButtonText_DESC',
  CloseButtonVariantAsc = 'closeButtonVariant_ASC',
  CloseButtonVariantDesc = 'closeButtonVariant_DESC',
  DisplayEnvironmentAsc = 'displayEnvironment_ASC',
  DisplayEnvironmentDesc = 'displayEnvironment_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PositionManagerInfo = {
  __typename?: 'PositionManagerInfo'
  chain: EvmChain
  count: Scalars['Int']
  id: Scalars['String']
}

export enum PriceType {
  PriceTypeDiscounted = 'PriceTypeDiscounted',
  PriceTypeNormal = 'PriceTypeNormal',
}

export enum ProductName {
  Bgsp = 'BGSP',
  Bgx = 'BGX',
}

export enum PromptVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export type Public = {
  __typename?: 'Public'
  banks: Array<Maybe<Bank>>
  chart: ChartResponse
  countries: Array<Maybe<Country>>
  cryptoAsset?: Maybe<CryptoAsset>
  keyOwnerID: Scalars['ID']
  marketInfo: Array<MarketInfo>
  marketPrice: Array<Maybe<MarketPrice>>
  /** empty questionnaire information */
  questionnaire: Questionnaire
  version: VersionInfo
}

export type PublicBanksArgs = {
  bankCode?: InputMaybe<Scalars['String']>
}

export type PublicChartArgs = {
  input: ChartInput
}

export type PublicKeyOwnerIdArgs = {
  key: Scalars['String']
}

export type PublicMarketInfoArgs = {
  markets: Array<Market>
}

export type PublicMarketPriceArgs = {
  markets: Array<Market>
  types: Array<PriceType>
}

export type PublicQuestionnaireArgs = {
  purpose: QuestionnairePurpose
  version?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  coinPrices?: Maybe<Array<CoinPrice>>
  me: UserInfo
  metric?: Maybe<Array<Metric>>
  poolsWithArguments?: Maybe<Array<UniswapPoolInfo>>
  rateHistorical?: Maybe<Array<RateHistorical>>
  strategies: Array<StrategyInfo>
  tools: Array<ToolInfo>
  uniswapQuote?: Maybe<UniswapQuote>
  uniswapTokens?: Maybe<Array<UniswapToolToken>>
  uniswapV3Backtest?: Maybe<UniswapV3BacktestResult>
  version: FrontendVersion
  wallet?: Maybe<DefiWallet>
}

export type QueryPoolsWithArgumentsArgs = {
  chain: EvmChain
  first?: InputMaybe<Scalars['Int']>
  flag?: InputMaybe<PoolsWithArgumentsFlag>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Scalars['String']>
  poolIds?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Int']>
  token0s?: InputMaybe<Array<Scalars['String']>>
  token1s?: InputMaybe<Array<Scalars['String']>>
}

export type QueryRateHistoricalArgs = {
  filter: RateHistoricalFilterInput
}

export type QueryStrategiesArgs = {
  filter?: InputMaybe<StrategyFilterInput>
}

export type QueryUniswapQuoteArgs = {
  input: UniswapQuoteInput
  source?: InputMaybe<QuoteDataSource>
}

export type QueryUniswapTokensArgs = {
  chain: EvmChain
}

export type QueryUniswapV3BacktestArgs = {
  compound: Scalars['Boolean']
  days: Scalars['Int']
  diff: Scalars['Float']
  investmentAmount: Scalars['Float']
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
}

export type QueryWalletArgs = {
  input?: InputMaybe<DefiWalletFilterInput>
}

export type Question = {
  __typename?: 'Question'
  /** question description */
  description: Scalars['String']
  /** unique key for this question */
  id: Scalars['ID']
  /** question options */
  options: Array<QuestionOption>
  /** question type */
  type: QuestionType
}

export enum QuestionAnnualIncome {
  QuestionAnnualIncomeBetween1MAnd2M = 'QuestionAnnualIncomeBetween1MAnd2M',
  QuestionAnnualIncomeBetween500KAnd1M = 'QuestionAnnualIncomeBetween500KAnd1M',
  QuestionAnnualIncomeLessThan500k = 'QuestionAnnualIncomeLessThan500k',
  QuestionAnnualIncomeMoreThan2M = 'QuestionAnnualIncomeMoreThan2M',
}

export type QuestionInfo = {
  __typename?: 'QuestionInfo'
  annualIncome: QuestionAnnualIncome
  anotherNationality?: Maybe<Scalars['String']>
  createdAt: Scalars['Time']
  id: Scalars['ID']
  period: QuestionPeriod
  purpose: QuestionPurpose
  updatedAt: Scalars['Time']
}

export type QuestionInput = {
  /** option ids from QuestionDetail */
  answers: Array<QuestionResultInput>
  /** question id from Questionnaire */
  questionID: Scalars['ID']
}

export type QuestionOption = {
  __typename?: 'QuestionOption'
  /** description of this option */
  description: Scalars['String']
  /** if true, remark is required, if false, remark should be empty string */
  hasRemark: Scalars['Boolean']
  /** key of this option */
  id: Scalars['ID']
  /** selected status of this option */
  isSelected: Scalars['Boolean']
  /** string value of this option */
  remark: Scalars['String']
}

export enum QuestionPeriod {
  QuestionPeriodBetweenOneAndThreeYears = 'QuestionPeriodBetweenOneAndThreeYears',
  QuestionPeriodFirstTime = 'QuestionPeriodFirstTime',
  QuestionPeriodLessThanOneYear = 'QuestionPeriodLessThanOneYear',
  QuestionPeriodMoreThanThreeYears = 'QuestionPeriodMoreThanThreeYears',
}

export enum QuestionPurpose {
  QuestionPurposeInvestment = 'QuestionPurposeInvestment',
  QuestionPurposePayment = 'QuestionPurposePayment',
}

export type QuestionResultInput = {
  /** uuid from option */
  optionID: Scalars['ID']
  /** remark string */
  remark?: InputMaybe<Scalars['String']>
}

export enum QuestionType {
  QuestionTypeMultipleSelection = 'QuestionTypeMultipleSelection',
  QuestionTypeSingleSelection = 'QuestionTypeSingleSelection',
}

export type Questionnaire = {
  __typename?: 'Questionnaire'
  /** created time of this questionnaire */
  createdAt: Scalars['Time']
  /** unique key for this questionnaire */
  id: Scalars['ID']
  /** purpose of this questionnaire, e.g. KYC, AML, etc. */
  purpose: QuestionnairePurpose
  /** questions */
  questions: Array<Question>
  /** updated time of this questionnaire */
  updatedAt?: Maybe<Scalars['Time']>
  /** version of this questionnaire */
  version: Scalars['String']
}

export type QuestionnaireInput = {
  /** unique key for this questionnaire */
  id: Scalars['ID']
  /** questions inputs */
  questions: Array<QuestionInput>
}

export enum QuestionnairePurpose {
  QuestionnairePurposeKycaml = 'QuestionnairePurposeKYCAML',
}

export type Quotation = {
  __typename?: 'Quotation'
  baseAmount: Scalars['Number']
  createdAt: Scalars['Time']
  expiredAt: Scalars['Time']
  fee: Scalars['Number']
  feeCurrency: Currency
  feeRate: Scalars['Number']
  id: Scalars['ID']
  market: Market
  price: Scalars['Number']
  quoteAmount: Scalars['Number']
  side: OrderSide
}

export enum QuoteDataSource {
  Blockchain = 'blockchain',
  Thegraph = 'thegraph',
}

export enum RaStatus {
  RaStatusForbidden = 'RAStatusForbidden',
  RaStatusHighRisk = 'RAStatusHighRisk',
  RaStatusLowRisk = 'RAStatusLowRisk',
  RaStatusMediumRisk = 'RAStatusMediumRisk',
  /** deprecated */
  RaStatusNormalRisk = 'RAStatusNormalRisk',
  RaStatusUnverified = 'RAStatusUnverified',
}

export type RateHistorical = {
  __typename?: 'RateHistorical'
  rate: Scalars['String']
  time: Scalars['String']
}

export type RateHistoricalFilterInput = {
  period: Scalars['String']
  sid?: InputMaybe<Scalars['String']>
}

export enum RecordsType {
  Revenue = 'revenue',
  Unknown = 'unknown',
}

export type RejectFiatWithdrawalInput = {
  userID: Scalars['ID']
  withdrawalID: Scalars['ID']
}

export type RejectFiatWithdrawalResponse = BaseResponse & {
  __typename?: 'RejectFiatWithdrawalResponse'
  result: Scalars['Boolean']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type Release = Entry & {
  __typename?: 'Release'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ReleaseLinkingCollections>
  notesCollection?: Maybe<ReleaseNotesCollection>
  releaseDate?: Maybe<Scalars['DateTime']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseNotesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ReleaseNotesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ReleaseNoteFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseReleaseDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/release) */
export type ReleaseTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ReleaseCollection = {
  __typename?: 'ReleaseCollection'
  items: Array<Maybe<Release>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ReleaseFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReleaseFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ReleaseFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  notes?: InputMaybe<CfReleaseNoteNestedFilter>
  notesCollection_exists?: InputMaybe<Scalars['Boolean']>
  releaseDate?: InputMaybe<Scalars['DateTime']>
  releaseDate_exists?: InputMaybe<Scalars['Boolean']>
  releaseDate_gt?: InputMaybe<Scalars['DateTime']>
  releaseDate_gte?: InputMaybe<Scalars['DateTime']>
  releaseDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  releaseDate_lt?: InputMaybe<Scalars['DateTime']>
  releaseDate_lte?: InputMaybe<Scalars['DateTime']>
  releaseDate_not?: InputMaybe<Scalars['DateTime']>
  releaseDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ReleaseLinkingCollections = {
  __typename?: 'ReleaseLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ReleaseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNote = Entry & {
  __typename?: 'ReleaseNote'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ReleaseNoteLinkingCollections>
  platform?: Maybe<Scalars['String']>
  releaseNote?: Maybe<ReleaseNoteReleaseNote>
  sys: Sys
  version?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNoteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNotePlatformArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNoteReleaseNoteArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/releaseNote) */
export type ReleaseNoteVersionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ReleaseNoteCollection = {
  __typename?: 'ReleaseNoteCollection'
  items: Array<Maybe<ReleaseNote>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ReleaseNoteFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReleaseNoteFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ReleaseNoteFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  platform?: InputMaybe<Scalars['String']>
  platform_contains?: InputMaybe<Scalars['String']>
  platform_exists?: InputMaybe<Scalars['Boolean']>
  platform_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  platform_not?: InputMaybe<Scalars['String']>
  platform_not_contains?: InputMaybe<Scalars['String']>
  platform_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  releaseNote_contains?: InputMaybe<Scalars['String']>
  releaseNote_exists?: InputMaybe<Scalars['Boolean']>
  releaseNote_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  version?: InputMaybe<Scalars['String']>
  version_contains?: InputMaybe<Scalars['String']>
  version_exists?: InputMaybe<Scalars['Boolean']>
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  version_not?: InputMaybe<Scalars['String']>
  version_not_contains?: InputMaybe<Scalars['String']>
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ReleaseNoteLinkingCollections = {
  __typename?: 'ReleaseNoteLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  releaseCollection?: Maybe<ReleaseCollection>
}

export type ReleaseNoteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ReleaseNoteLinkingCollectionsReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ReleaseNoteLinkingCollectionsReleaseCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ReleaseNoteLinkingCollectionsReleaseCollectionOrder {
  ReleaseDateAsc = 'releaseDate_ASC',
  ReleaseDateDesc = 'releaseDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ReleaseNoteOrder {
  PlatformAsc = 'platform_ASC',
  PlatformDesc = 'platform_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

export type ReleaseNoteReleaseNote = {
  __typename?: 'ReleaseNoteReleaseNote'
  json: Scalars['JSON']
  links: ReleaseNoteReleaseNoteLinks
}

export type ReleaseNoteReleaseNoteAssets = {
  __typename?: 'ReleaseNoteReleaseNoteAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ReleaseNoteReleaseNoteEntries = {
  __typename?: 'ReleaseNoteReleaseNoteEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ReleaseNoteReleaseNoteLinks = {
  __typename?: 'ReleaseNoteReleaseNoteLinks'
  assets: ReleaseNoteReleaseNoteAssets
  entries: ReleaseNoteReleaseNoteEntries
  resources: ReleaseNoteReleaseNoteResources
}

export type ReleaseNoteReleaseNoteResources = {
  __typename?: 'ReleaseNoteReleaseNoteResources'
  block: Array<ResourceLink>
}

export type ReleaseNotesCollection = {
  __typename?: 'ReleaseNotesCollection'
  items: Array<Maybe<ReleaseNote>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ReleaseNotesCollectionOrder {
  PlatformAsc = 'platform_ASC',
  PlatformDesc = 'platform_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

export enum ReleaseOrder {
  ReleaseDateAsc = 'releaseDate_ASC',
  ReleaseDateDesc = 'releaseDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ResourceConfirmUploadInput = {
  eTag: Scalars['String']
  objKey: Scalars['String']
}

export type ResourceConfirmUploadResponse = BaseResponse & {
  __typename?: 'ResourceConfirmUploadResponse'
  id: Scalars['ID']
  result: Scalars['Boolean']
}

export type ResourceLink = {
  __typename?: 'ResourceLink'
  sys: ResourceSys
}

export type ResourceMutation = {
  __typename?: 'ResourceMutation'
  resourceConfirmUpload: ResourceConfirmUploadResponse
  resourceRequestUploadURL: ResourceRequestUploadUrlResponse
}

export type ResourceMutationResourceConfirmUploadArgs = {
  input: ResourceConfirmUploadInput
}

export type ResourceMutationResourceRequestUploadUrlArgs = {
  input: ResourceRequestUploadUrlInput
}

export type ResourceRequestUploadUrlInput = {
  fileExtension: Scalars['String']
}

export type ResourceRequestUploadUrlResponse = BaseResponse & {
  __typename?: 'ResourceRequestUploadURLResponse'
  result: Scalars['Boolean']
  url: Scalars['String']
}

export type ResourceSys = {
  __typename?: 'ResourceSys'
  linkType: Scalars['String']
  type: Scalars['String']
  urn: Scalars['String']
}

export type Response2Fa = {
  __typename?: 'Response2FA'
  token2FA: Scalars['String']
}

export type ReyieldAttributes = {
  __typename?: 'ReyieldAttributes'
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ReyieldBalance = {
  __typename?: 'ReyieldBalance'
  available: Scalars['String']
  total: Scalars['String']
}

export type ReyieldCefiDepositAddress = {
  __typename?: 'ReyieldCefiDepositAddress'
  address: Scalars['String']
  chain: ReyieldChain
}

export type ReyieldCefiDepositAddressInput = {
  chain: ReyieldChain
  currency: Scalars['String']
  exchange: ReyieldExchange
  wallet?: InputMaybe<ReyieldWalletType>
}

export type ReyieldCefiWallet = {
  __typename?: 'ReyieldCefiWallet'
  exchange: Scalars['String']
  walletBalances: Array<ReyieldWalletBalances>
}

export type ReyieldCefiWalletFilterInput = {
  exchange: ReyieldExchange
  hideTinyUSDValue?: InputMaybe<Scalars['Int']>
}

export enum ReyieldChain {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Tron = 'Tron',
}

export type ReyieldCloseUniswapv3PositionInput = {
  positionId: Scalars['String']
  txHash: Scalars['String']
}

export type ReyieldCoinPrice = {
  __typename?: 'ReyieldCoinPrice'
  symbol: Scalars['String']
  usdtPrice: Scalars['String']
}

export type ReyieldCreateAiCompletionRecordInput = {
  expectedReturn: Scalars['String']
  goal: Scalars['String']
  investmentAmount: Scalars['String']
  investmentPeriod: Scalars['String']
  items: Array<ReyieldCreateAiCompletionRecordItemInput>
  promptVersion?: InputMaybe<ReyieldPromptVersion>
  riskToleranceLevel: ReyieldRiskToleranceLevelName
}

export type ReyieldCreateAiCompletionRecordItemInput = {
  desc: Scalars['String']
  highestAnnualReturn: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
}

export type ReyieldCreateUniswapv3PositionInput = {
  toolId: Scalars['String']
  txHash: Scalars['String']
}

export type ReyieldCreateUniswapv3ToolInput = {
  chain: ReyieldEvmChain
  description?: InputMaybe<Scalars['String']>
  feeTier: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  outOfLowerRange: ReyieldOutOfRangeInfoInput
  outOfUpperRange: ReyieldOutOfRangeInfoInput
  tickLowerDiff: Scalars['Float']
  tickUpperDiff: Scalars['Float']
  token0Address: Scalars['String']
  token1Address: Scalars['String']
}

export type ReyieldCreator = {
  __typename?: 'ReyieldCreator'
  address: Scalars['String']
  id: Scalars['String']
}

export type ReyieldCurrencyBalances = {
  __typename?: 'ReyieldCurrencyBalances'
  balance: ReyieldBalance
  currency: Scalars['String']
}

export type ReyieldCurrencyInfo = {
  __typename?: 'ReyieldCurrencyInfo'
  decimals: Scalars['Int']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type ReyieldDefiTokensBalanceAmountInput = {
  chain: ReyieldEvmChain
  chainId: Scalars['Int']
  tokenAddresses: Array<Scalars['String']>
}

export type ReyieldDefiWallet = {
  __typename?: 'ReyieldDefiWallet'
  balance: Scalars['String']
  balanceComputed?: Maybe<Scalars['String']>
  balanceComputedWithUnit?: Maybe<Scalars['String']>
  chain: ReyieldEvmChain
  currencyInfo: ReyieldCurrencyInfo
  id: Scalars['String']
}

export type ReyieldDefiWalletFilterInput = {
  address: Scalars['String']
}

export type ReyieldDefiWalletsFilterInput = {
  chains?: InputMaybe<Array<ReyieldEvmChain>>
}

export enum ReyieldEvmChain {
  Arbitrum = 'Arbitrum',
  Ethereum = 'Ethereum',
  Goerli = 'Goerli',
  Optimism = 'Optimism',
  OptimismGoerli = 'OptimismGoerli',
  Polygon = 'Polygon',
  Unknown = 'Unknown',
}

export enum ReyieldExchange {
  Bitfinex = 'bitfinex',
  Tradingview = 'tradingview',
  Uniswap = 'uniswap',
  Unknown = 'unknown',
}

export type ReyieldExternalApiKey = {
  __typename?: 'ReyieldExternalAPIKey'
  createdAt?: Maybe<Scalars['String']>
  exchange: ReyieldExchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  user?: Maybe<ReyieldUserInfo>
  userId?: Maybe<Scalars['String']>
}

export type ReyieldExternalApiKeyCreateInput = {
  exchange: ReyieldExchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
}

export type ReyieldExternalApiKeyDeleteInput = {
  id: Scalars['String']
}

export type ReyieldExternalApiKeysFilter = {
  exchange?: InputMaybe<ReyieldExchange>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export type ReyieldFrontendVersion = {
  __typename?: 'ReyieldFrontendVersion'
  commitHash: Scalars['String']
  smartContracts: Array<ReyieldSmartContract>
  version: Scalars['String']
}

export type ReyieldGimCoolPayPaymentInfo = {
  __typename?: 'ReyieldGimCoolPayPaymentInfo'
  blockchain: ReyieldGimCoolPayPaymentSupportingBlockchain
  coinAmount?: Maybe<Scalars['String']>
  coinType: ReyieldGimCoolPayPaymentSupportingCoinType
  fiatAmount?: Maybe<Scalars['String']>
  fiatType: ReyieldGimCoolPayPaymentSupportingFiatType
  paymentHref: Scalars['String']
  price?: Maybe<ReyieldGimCoolPayPriceResponse>
  walletAddress: Scalars['String']
  walletAddressTag?: Maybe<Scalars['String']>
}

export type ReyieldGimCoolPayPaymentInfoInput = {
  blockchain: ReyieldGimCoolPayPaymentSupportingBlockchain
  coinAmount: Scalars['String']
  coinType: ReyieldGimCoolPayPaymentSupportingCoinType
  fiatType: ReyieldGimCoolPayPaymentSupportingFiatType
  isSandBox?: InputMaybe<Scalars['Boolean']>
}

export enum ReyieldGimCoolPayPaymentSupportingBlockchain {
  Eth = 'ETH',
  Unknown = 'Unknown',
}

export enum ReyieldGimCoolPayPaymentSupportingCoinType {
  Eth = 'ETH',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Unknown = 'Unknown',
}

export enum ReyieldGimCoolPayPaymentSupportingFiatType {
  Usd = 'USD',
  Unknown = 'Unknown',
}

export type ReyieldGimCoolPayPriceResponse = {
  __typename?: 'ReyieldGimCoolPayPriceResponse'
  prices?: Maybe<Array<ReyieldGimCoolPayReadyToPurchasePrice>>
  spotPrice: Scalars['String']
}

export type ReyieldGimCoolPayReadyToPurchasePrice = {
  __typename?: 'ReyieldGimCoolPayReadyToPurchasePrice'
  coinAmount: Scalars['String']
  coinCode: Scalars['String']
  feeAmount: Scalars['String']
  fiatAmount: Scalars['String']
  fiatCode: Scalars['String']
  networkFee: Scalars['String']
  paymentMethodId: Scalars['String']
  spotPriceFee: Scalars['String']
  spotPriceIncludingFee: Scalars['String']
  type: Scalars['String']
}

export type ReyieldLicense = {
  __typename?: 'ReyieldLicense'
  numberOfLicense: Scalars['Int']
  numberOfUsedLicense: Scalars['Int']
}

export type ReyieldNftCollections = {
  __typename?: 'ReyieldNFTCollections'
  attributes?: Maybe<Array<Maybe<ReyieldAttributes>>>
  contract: Scalars['String']
  description: Scalars['String']
  imageUrl: Scalars['String']
  name: Scalars['String']
  tokenId: Scalars['String']
}

export type ReyieldNftMerkleTreeHexProof = {
  __typename?: 'ReyieldNFTMerkleTreeHexProof'
  hexProof?: Maybe<Array<Scalars['String']>>
}

export type ReyieldNftWhitelistInfoByPhrase = {
  __typename?: 'ReyieldNFTWhitelistInfoByPhrase'
  id: Scalars['String']
  isWhitelisted: Scalars['Boolean']
  phrase: ReyieldNft_Round
  userAddress: Scalars['String']
}

export enum ReyieldNft_Round {
  Airdrop = 'AIRDROP',
  AllowWhitelistMint = 'ALLOW_WHITELIST_MINT',
  GuaranteeWhitelistMint = 'GUARANTEE_WHITELIST_MINT',
  SoldOut = 'SOLD_OUT',
  WhitelistCheck = 'WHITELIST_CHECK',
}

export type ReyieldOutOfRangeInfoInput = {
  earnSpendRate: Scalars['Int']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export enum ReyieldPoolsWithArgumentsFlag {
  /** This will use default value to filter */
  Default = 'Default',
  /** This will include selected Tokens (Ex: BTC, ETH ...) */
  Generic = 'Generic',
  /** This will include stable tokens (Ex: USDT, USDC ...) */
  Stables = 'Stables',
}

export enum ReyieldPromptVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export enum ReyieldQuoteDataSource {
  Blockchain = 'blockchain',
  Thegraph = 'thegraph',
}

export type ReyieldRateHistorical = {
  __typename?: 'ReyieldRateHistorical'
  rate: Scalars['String']
  time: Scalars['String']
}

export type ReyieldRateHistoricalFilterInput = {
  period: Scalars['String']
  sid?: InputMaybe<Scalars['String']>
}

export enum ReyieldRecordsType {
  Revenue = 'revenue',
  Unknown = 'unknown',
}

export type ReyieldReyieldLicense = {
  __typename?: 'ReyieldReyieldLicense'
  numberOfLicense: Scalars['Int']
  numberOfUsedLicense: Scalars['Int']
}

export enum ReyieldRiskLevel {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'medium_high',
  MediumLow = 'medium_low',
  Unknown = 'unknown',
}

export enum ReyieldRiskToleranceLevelName {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  ExtremelyAggressive = 'ExtremelyAggressive',
  ExtremelyConservative = 'ExtremelyConservative',
  Moderate = 'Moderate',
  VeryConservative = 'VeryConservative',
}

export type ReyieldSmartContract = {
  __typename?: 'ReyieldSmartContract'
  address: Scalars['String']
  chain: ReyieldEvmChain
  name: Scalars['String']
}

export type ReyieldStrategyFilterInput = {
  riskLevel?: InputMaybe<ReyieldRiskLevel>
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<ReyieldStrategyType>
}

export type ReyieldStrategyInfo = {
  __typename?: 'ReyieldStrategyInfo'
  currency: Scalars['String']
  description: Scalars['String']
  earningCurrency: Scalars['String']
  exchanges: Array<ReyieldExchange>
  expectedAPY: Scalars['String']
  id: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  parameters: Array<ReyieldStrategyParameters>
  riskLevel: ReyieldRiskLevel
  sid: Scalars['String']
  stage: ReyieldStrategyInfoStage
  suggestionPeriod: Scalars['String']
  target: Scalars['String']
  type: ReyieldStrategyType
}

export enum ReyieldStrategyInfoStage {
  Active = 'Active',
  Beta = 'Beta',
  Mock = 'Mock',
}

export type ReyieldStrategyInfos = {
  __typename?: 'ReyieldStrategyInfos'
  currency: Scalars['String']
  earningCurrency: Scalars['String']
  exchange?: Maybe<ReyieldExchange>
  minEnableAmount: Scalars['String']
  name: Scalars['String']
}

export enum ReyieldStrategyParameterType {
  Bool = 'bool',
  Num = 'num',
  Str = 'str',
  Unknown = 'unknown',
}

export type ReyieldStrategyParameters = {
  __typename?: 'ReyieldStrategyParameters'
  defaultValue?: Maybe<Scalars['String']>
  description: Scalars['String']
  key: Scalars['String']
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: ReyieldStrategyParameterType
  updatable: Scalars['Boolean']
}

export type ReyieldStrategyPerformance = {
  __typename?: 'ReyieldStrategyPerformance'
  amount: Scalars['String']
  apy: Scalars['String']
  currency: Scalars['String']
  ror: Scalars['String']
}

export type ReyieldStrategyProgress = {
  __typename?: 'ReyieldStrategyProgress'
  createdAt: Scalars['String']
  id: Scalars['ID']
  index: Scalars['Int']
  status: ReyieldStrategyProgressStatus
  steps: Array<Maybe<ReyieldStrategyProgressStep>>
  type: ReyieldStrategyProgressType
  updatedAt: Scalars['String']
}

export enum ReyieldStrategyProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type ReyieldStrategyProgressStep = {
  __typename?: 'ReyieldStrategyProgressStep'
  status: ReyieldStrategyProgressStepStatus
  type: ReyieldStrategyProgressStepType
}

export enum ReyieldStrategyProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum ReyieldStrategyProgressStepType {
  CexDepositCheck = 'cex_deposit_check',
  CexSwap = 'cex_swap',
  CexWalletsTransfer = 'cex_wallets_transfer',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export enum ReyieldStrategyProgressType {
  StrategyFinalize = 'strategy_finalize',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export type ReyieldStrategyStartInput = {
  parameters: Array<ReyieldStrategyStartParamenters>
  sid: Scalars['String']
}

export type ReyieldStrategyStartParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type ReyieldStrategyStartResponse = {
  __typename?: 'ReyieldStrategyStartResponse'
  strategy: ReyieldUserStrategyInfo
}

export enum ReyieldStrategyStatus {
  Error = 'error',
  Preparing = 'preparing',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Unknown = 'unknown',
  WaitForUserAction = 'wait_for_user_action',
}

export type ReyieldStrategyStopInput = {
  isForce?: InputMaybe<Scalars['Boolean']>
  strategyID: Scalars['String']
}

export type ReyieldStrategyStopResponse = {
  __typename?: 'ReyieldStrategyStopResponse'
  strategy: ReyieldUserStrategyInfo
}

export enum ReyieldStrategyType {
  Arbitrage = 'arbitrage',
  Defi = 'defi',
  Hedging = 'hedging',
  Lending = 'lending',
  Unknown = 'unknown',
}

export type ReyieldStrategyUpdateInput = {
  parameters: Array<ReyieldStrategyUpdateParamenters>
  strategyID: Scalars['String']
}

export type ReyieldStrategyUpdateParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type ReyieldStrategyUpdateResponse = {
  __typename?: 'ReyieldStrategyUpdateResponse'
  strategy: ReyieldUserStrategyInfo
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type ReyieldString_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type ReyieldTokenBalance = {
  __typename?: 'ReyieldTokenBalance'
  address: Scalars['String']
  amount: Scalars['String']
  amountReadable: Scalars['String']
  chain: ReyieldEvmChain
  decimals: Scalars['Int']
  id: Scalars['String']
  symbol: Scalars['String']
}

export type ReyieldTokenDisplayInfo = {
  __typename?: 'ReyieldTokenDisplayInfo'
  address: Scalars['String']
  chain: ReyieldEvmChain
  id: Scalars['String']
  imageURL: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type ReyieldToolInfo = ReyieldStrategyInfo | ReyieldUniswapToolInfo

export type ReyieldTotalProfits = {
  __typename?: 'ReyieldTotalProfits'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
}

export type ReyieldUniswapOnchainPositionAmountInfo = {
  __typename?: 'ReyieldUniswapOnchainPositionAmountInfo'
  /** this data is from thegraph */
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Amount: Scalars['String']
  token0AmountUsdValue: Scalars['String']
  /** this data is from thegraph */
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Amount: Scalars['String']
  token1AmountUsdValue: Scalars['String']
}

export type ReyieldUniswapPoolInfo = {
  __typename?: 'ReyieldUniswapPoolInfo'
  averageTradingVolumeUSD?: Maybe<Scalars['String']>
  chain: ReyieldEvmChain
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  collectedFeesUSD: Scalars['String']
  createdAtBlockNumber: Scalars['String']
  createdAtTimestamp: Scalars['String']
  feeGrowthGlobal0X128: Scalars['String']
  feeGrowthGlobal1X128: Scalars['String']
  feeTier: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  /** this part is from smart contract developed by reyield */
  isPoolActiveForReyield?: Maybe<Scalars['Boolean']>
  liquidity: Scalars['String']
  liquidityProviderCount: Scalars['String']
  observationIndex: Scalars['String']
  positions?: Maybe<Array<ReyieldUniswapPoolPosition>>
  sqrtPrice: Scalars['String']
  tick?: Maybe<Scalars['String']>
  ticks?: Maybe<Array<ReyieldUniswapPoolTick>>
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Price: Scalars['String']
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Price: Scalars['String']
  totalValueLockedETH: Scalars['String']
  totalValueLockedToken0: Scalars['String']
  totalValueLockedToken1: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volumeToken0: Scalars['String']
  volumeToken1: Scalars['String']
  volumeUSD: Scalars['String']
}

export type ReyieldUniswapPoolInfoAverageTradingVolumeUsdArgs = {
  numberOfDays: Scalars['Int']
}

export type ReyieldUniswapPoolPosition = {
  __typename?: 'ReyieldUniswapPoolPosition'
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  depositedToken0: Scalars['String']
  depositedToken1: Scalars['String']
  feeGrowthInside0LastX128: Scalars['String']
  feeGrowthInside1LastX128: Scalars['String']
  id: Scalars['ID']
  liquidity: Scalars['String']
  tickLowerFeeGrowthOutside0X128: Scalars['String']
  tickLowerFeeGrowthOutside1X128: Scalars['String']
  tickLowerTickIdx: Scalars['String']
  tickUpperFeeGrowthOutside0X128: Scalars['String']
  tickUpperFeeGrowthOutside1X128: Scalars['String']
  tickUpperTickIdx: Scalars['String']
  transactionTimestamp: Scalars['String']
}

export type ReyieldUniswapPoolTick = {
  __typename?: 'ReyieldUniswapPoolTick'
  id: Scalars['String']
  liquidityNet: Scalars['String']
  price0: Scalars['String']
  price1: Scalars['String']
  tickIdx: Scalars['String']
}

export type ReyieldUniswapQuote = {
  __typename?: 'ReyieldUniswapQuote'
  chain: ReyieldEvmChain
  fromToken: ReyieldUniswapToolToken
  fromTokenAddress: Scalars['String']
  id: Scalars['String']
  inputAmount: Scalars['String']
  inputReadableAmount: Scalars['String']
  outputAmount: Scalars['String']
  outputReadableAmount: Scalars['String']
  toToken: ReyieldUniswapToolToken
  toTokenAddress: Scalars['String']
}

export type ReyieldUniswapQuoteInput = {
  chain: ReyieldEvmChain
  fromTokenAddress: Scalars['String']
  inputReadableAmount: Scalars['String']
  toTokenAddress: Scalars['String']
}

export type ReyieldUniswapToolFeeTier = {
  __typename?: 'ReyieldUniswapToolFeeTier'
  feeForContract: Scalars['String']
  feeForDisplay: Scalars['String']
  id: Scalars['String']
}

export type ReyieldUniswapToolInfo = {
  __typename?: 'ReyieldUniswapToolInfo'
  chain: ReyieldEvmChain
  createdAt: Scalars['String']
  creator?: Maybe<ReyieldCreator>
  creatorAddress: Scalars['String']
  creatorId: Scalars['String']
  description: Scalars['String']
  expectedAPY: Scalars['String']
  feeTier: ReyieldUniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  performanceFee: Scalars['String']
  poolAddress: Scalars['String']
  /** this data is from thegraph */
  poolInfo?: Maybe<ReyieldUniswapPoolInfo>
  riskLevel: ReyieldRiskLevel
  tags?: Maybe<Array<ReyieldUniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickLowerDiffForContract: Scalars['String']
  tickUpperDiff: Scalars['String']
  tickUpperDiffForContract?: Maybe<Scalars['String']>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
}

export enum ReyieldUniswapToolPositionStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum ReyieldUniswapToolStatus {
  Created = 'CREATED',
  Deprecated = 'DEPRECATED',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type ReyieldUniswapToolTag = {
  __typename?: 'ReyieldUniswapToolTag'
  id: Scalars['String']
  name: Scalars['String']
  type: ReyieldUniswapToolTagType
}

export enum ReyieldUniswapToolTagType {
  Chain = 'chain',
  Custom = 'custom',
  IsOfficial = 'isOfficial',
  Uniswapv3 = 'uniswapv3',
  Unknown = 'unknown',
}

export type ReyieldUniswapToolToken = {
  __typename?: 'ReyieldUniswapToolToken'
  chain: ReyieldEvmChain
  decimals: Scalars['String']
  derivedETH: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  poolCount: Scalars['String']
  priceChart?: Maybe<ReyieldUniswapToolTokenPriceChart>
  symbol: Scalars['String']
  totalSupply: Scalars['String']
  totalValueLocked: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volume: Scalars['String']
  volumeUSD: Scalars['String']
}

export type ReyieldUniswapToolTokenPriceChart = {
  __typename?: 'ReyieldUniswapToolTokenPriceChart'
  currentPriceUSD?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  prices?: Maybe<Array<ReyieldUniswapToolTokenPriceChartPrice>>
}

export type ReyieldUniswapToolTokenPriceChartPrice = {
  __typename?: 'ReyieldUniswapToolTokenPriceChartPrice'
  timestamp: Scalars['String']
  value: Scalars['String']
}

export enum ReyieldUniswapV3BacktestPeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type ReyieldUniswapV3BacktestResult = {
  __typename?: 'ReyieldUniswapV3BacktestResult'
  activeLiquidityPercent: Scalars['Float']
  annualReturn: Scalars['Float']
  compound: Scalars['Boolean']
  createdAt: Scalars['String']
  dailyReturn: Scalars['Float']
  dataPointsCount: Scalars['String']
  days: Scalars['Int']
  diff: Scalars['Float']
  fees?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  impermanentLoss: Scalars['Float']
  investmentAmount: Scalars['Float']
  maxDrawdown?: Maybe<Scalars['Float']>
  period: ReyieldUniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: ReyieldEvmChain
  totalReturn?: Maybe<Scalars['Float']>
  volatility?: Maybe<Scalars['Float']>
}

export type ReyieldUserAiCompletionRecordsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ReyieldUserInfo = {
  __typename?: 'ReyieldUserInfo'
  aICompletionRecords?: Maybe<Array<ReyieldaICompletionRecords>>
  cefiDepositAddress?: Maybe<ReyieldCefiDepositAddress>
  cefiWallets?: Maybe<Array<ReyieldCefiWallet>>
  defiTokensBalanceAmount?: Maybe<Array<Maybe<ReyieldTokenBalance>>>
  defiWallets?: Maybe<Array<ReyieldDefiWallet>>
  externalAPIKeys?: Maybe<Array<ReyieldExternalApiKey>>
  gimCoolPayPaymentInfo?: Maybe<ReyieldGimCoolPayPaymentInfo>
  id: Scalars['String']
  nftCollections?: Maybe<Array<ReyieldNftCollections>>
  nftMerkleTreeHexProof?: Maybe<ReyieldNftMerkleTreeHexProof>
  nftWhitelistInfoByPhrases?: Maybe<Array<ReyieldNftWhitelistInfoByPhrase>>
  ownedToolsSetting?: Maybe<Array<ReyieldUserUniswapToolSettingInfo>>
  reyieldLicense?: Maybe<ReyieldReyieldLicense>
  strategies?: Maybe<Array<ReyieldUserStrategyInfo>>
  strategyPerformance?: Maybe<ReyieldUserStrategyPerformance>
  strategyProfit?: Maybe<ReyieldUserStrategyProfit>
  strategyProgress?: Maybe<ReyieldStrategyProgress>
  strategyRecords?: Maybe<Array<ReyieldUserStrategyRecord>>
  tools?: Maybe<Array<ReyieldUserToolInfo>>
}

export type ReyieldUserInfoAiCompletionRecordsArgs = {
  filter?: InputMaybe<ReyieldUserAiCompletionRecordsFilterInput>
}

export type ReyieldUserInfoCefiDepositAddressArgs = {
  filter?: InputMaybe<ReyieldCefiDepositAddressInput>
}

export type ReyieldUserInfoCefiWalletsArgs = {
  filter?: InputMaybe<ReyieldCefiWalletFilterInput>
}

export type ReyieldUserInfoDefiTokensBalanceAmountArgs = {
  input: ReyieldDefiTokensBalanceAmountInput
}

export type ReyieldUserInfoDefiWalletsArgs = {
  filter?: InputMaybe<ReyieldDefiWalletsFilterInput>
}

export type ReyieldUserInfoExternalApiKeysArgs = {
  filter?: InputMaybe<ReyieldExternalApiKeysFilter>
}

export type ReyieldUserInfoGimCoolPayPaymentInfoArgs = {
  input?: InputMaybe<ReyieldGimCoolPayPaymentInfoInput>
}

export type ReyieldUserInfoStrategiesArgs = {
  filter?: InputMaybe<ReyieldUserStrategyFilterInput>
}

export type ReyieldUserInfoStrategyPerformanceArgs = {
  strategyID: Scalars['String']
}

export type ReyieldUserInfoStrategyProfitArgs = {
  filter?: InputMaybe<ReyieldUserStrategyProfitFilterInput>
}

export type ReyieldUserInfoStrategyProgressArgs = {
  strategyID: Scalars['String']
}

export type ReyieldUserInfoStrategyRecordsArgs = {
  filter?: InputMaybe<ReyieldUserStrategyRecordsFilterInput>
}

export type ReyieldUserStrategyFilterInput = {
  sid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Array<InputMaybe<ReyieldStrategyStatus>>>
  strategyID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ReyieldUserStrategyInfo = {
  __typename?: 'ReyieldUserStrategyInfo'
  ceFiStatus: ReyieldStrategyStatus
  closedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['String']
  infos?: Maybe<ReyieldStrategyInfos>
  parameters?: Maybe<Array<ReyieldUserStrategyParameters>>
  performance?: Maybe<ReyieldUserStrategyPerformance>
  profits?: Maybe<ReyieldUserStrategyProfit>
  sid: Scalars['String']
  updatedAt: Scalars['String']
}

export type ReyieldUserStrategyParameters = {
  __typename?: 'ReyieldUserStrategyParameters'
  key: Scalars['String']
  value: Scalars['String']
}

export type ReyieldUserStrategyPerformance = {
  __typename?: 'ReyieldUserStrategyPerformance'
  duration?: Maybe<Scalars['Int']>
  initialValue?: Maybe<Scalars['String']>
  performances?: Maybe<Array<Maybe<ReyieldStrategyPerformance>>>
}

export type ReyieldUserStrategyProfit = {
  __typename?: 'ReyieldUserStrategyProfit'
  sid?: Maybe<Scalars['String']>
  totalProfit?: Maybe<Array<Maybe<ReyieldTotalProfits>>>
  type?: Maybe<ReyieldStrategyType>
}

export type ReyieldUserStrategyProfitFilterInput = {
  sid: Array<InputMaybe<Scalars['String']>>
  type?: InputMaybe<Array<InputMaybe<ReyieldStrategyType>>>
}

export type ReyieldUserStrategyRecord = {
  __typename?: 'ReyieldUserStrategyRecord'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<ReyieldRecordsType>
}

export type ReyieldUserStrategyRecordsFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  strategyID: Scalars['String']
  type?: InputMaybe<ReyieldRecordsType>
}

export type ReyieldUserToolInfo = ReyieldUserStrategyInfo | ReyieldUserUniswapPositionInfo

export type ReyieldUserUniswapOnchainPositionInfo = {
  __typename?: 'ReyieldUserUniswapOnchainPositionInfo'
  apy: Scalars['String']
  chain: ReyieldEvmChain
  closedPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  totalInvestAmountUsdValue?: Maybe<Scalars['String']>
}

export type ReyieldUserUniswapPositionInfo = {
  __typename?: 'ReyieldUserUniswapPositionInfo'
  apy: Scalars['String']
  chain: ReyieldEvmChain
  closedAt?: Maybe<Scalars['String']>
  closedPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  createdAt: Scalars['String']
  feeTier: ReyieldUniswapToolFeeTier
  id: Scalars['String']
  /** backend will cache the data for n minutes */
  onchainPositionInfo?: Maybe<ReyieldUserUniswapOnchainPositionInfo>
  poolAddress: Scalars['String']
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  progresses?: Maybe<Array<ReyieldUserUniswapv3PositionProgress>>
  publicToolInfo?: Maybe<ReyieldUniswapToolInfo>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<ReyieldUniswapOnchainPositionAmountInfo>
  status: ReyieldUniswapToolPositionStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  toolDescription: Scalars['String']
  toolId: Scalars['String']
  toolIsOfficial: Scalars['Boolean']
  toolName: Scalars['String']
  toolPerformanceFeeRatio?: Maybe<Scalars['String']>
  /** if the user is the creator of the tool, then the toolSetting will be returned */
  toolSetting?: Maybe<ReyieldUserUniswapToolSettingInfo>
  totalInvestAmountUsdValue: Scalars['String']
  updatedAt: Scalars['String']
  userAddress: Scalars['String']
}

export type ReyieldUserUniswapPositionInfoOnchainPositionInfoArgs = {
  withoutCache?: InputMaybe<Scalars['Boolean']>
}

export type ReyieldUserUniswapToolOutOfRangeInfo = {
  __typename?: 'ReyieldUserUniswapToolOutOfRangeInfo'
  earnSpendRate: Scalars['String']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type ReyieldUserUniswapToolSettingInfo = {
  __typename?: 'ReyieldUserUniswapToolSettingInfo'
  chain: ReyieldEvmChain
  closedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  feeTier: ReyieldUniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  name: Scalars['String']
  outOfLowerRange: ReyieldUserUniswapToolOutOfRangeInfo
  outOfUpperRange: ReyieldUserUniswapToolOutOfRangeInfo
  performanceFeeRatio?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  /** User will have only one position for each tool */
  position?: Maybe<ReyieldUserUniswapPositionInfo>
  publicToolInfo?: Maybe<ReyieldUniswapToolInfo>
  status: ReyieldUniswapToolStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<ReyieldUniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<ReyieldUniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<ReyieldTokenDisplayInfo>
  totalUserUsed: Scalars['Int']
  updatedAt?: Maybe<Scalars['String']>
}

export type ReyieldUserUniswapv3PositionProgress = {
  __typename?: 'ReyieldUserUniswapv3PositionProgress'
  createdAt: Scalars['String']
  id: Scalars['String']
  index: Scalars['Int']
  status: ReyieldUserUniswapv3PositionProgressStatus
  steps: Array<ReyieldUserUniswapv3PositionProgressStep>
  type: ReyieldUserUniswapv3PositionProgressType
  updatedAt: Scalars['String']
}

export enum ReyieldUserUniswapv3PositionProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type ReyieldUserUniswapv3PositionProgressStep = {
  __typename?: 'ReyieldUserUniswapv3PositionProgressStep'
  status: ReyieldUserUniswapv3PositionProgressStepStatus
  type: ReyieldUserUniswapv3PositionProgressStepType
}

export enum ReyieldUserUniswapv3PositionProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum ReyieldUserUniswapv3PositionProgressStepType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export enum ReyieldUserUniswapv3PositionProgressType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export type ReyieldWalletBalances = {
  __typename?: 'ReyieldWalletBalances'
  currencyBalances: Array<ReyieldCurrencyBalances>
  wallet: Scalars['String']
}

export enum ReyieldWalletType {
  BfxExchange = 'bfx_exchange',
  BfxFunding = 'bfx_funding',
  BfxMargin = 'bfx_margin',
  Spot = 'spot',
  Unknown = 'unknown',
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecords = {
  __typename?: 'ReyieldaICompletionRecords'
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['String']
  meta?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  promptMutationInput?: Maybe<Scalars['jsonb']>
  promptMutationName?: Maybe<Scalars['String']>
  /** An array relationship */
  suggestions: Array<ReyieldsuggestStrategyToolWithAllocations>
  /** An aggregate relationship */
  suggestions_aggregate: ReyieldsuggestStrategyToolWithAllocations_Aggregate
  updatedAt: Scalars['timestamptz']
  userAddress?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsPromptMutationInputArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>
}

/** columns and relationships of "aICompletionRecords" */
export type ReyieldaICompletionRecordsSuggestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>
}

/** column ordering options */
export enum Reyieldorder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations'
  aICompletionRecordId: Scalars['String']
  allocation: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Scalars['String']
  highestAnnualReturn: Scalars['String']
  id: Scalars['uuid']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations_Aggregate = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_aggregate'
  aggregate?: Maybe<ReyieldsuggestStrategyToolWithAllocations_Aggregate_Fields>
  nodes: Array<ReyieldsuggestStrategyToolWithAllocations>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations_Aggregate_Fields = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<ReyieldsuggestStrategyToolWithAllocations_Max_Fields>
  min?: Maybe<ReyieldsuggestStrategyToolWithAllocations_Min_Fields>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type ReyieldsuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "suggestStrategyToolWithAllocations". All fields are combined with a logical 'AND'. */
export type ReyieldsuggestStrategyToolWithAllocations_Bool_Exp = {
  _and?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>>
  _not?: InputMaybe<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>
  _or?: InputMaybe<Array<ReyieldsuggestStrategyToolWithAllocations_Bool_Exp>>
  aICompletionRecordId?: InputMaybe<ReyieldString_Comparison_Exp>
  allocation?: InputMaybe<ReyieldString_Comparison_Exp>
  createdAt?: InputMaybe<Reyieldtimestamptz_Comparison_Exp>
  description?: InputMaybe<ReyieldString_Comparison_Exp>
  highestAnnualReturn?: InputMaybe<ReyieldString_Comparison_Exp>
  id?: InputMaybe<Reyielduuid_Comparison_Exp>
  maximumLoss?: InputMaybe<ReyieldString_Comparison_Exp>
  name?: InputMaybe<ReyieldString_Comparison_Exp>
  sid?: InputMaybe<ReyieldString_Comparison_Exp>
  systemInvestAmountSuggestion?: InputMaybe<ReyieldString_Comparison_Exp>
  systemInvestCurrencySuggestion?: InputMaybe<ReyieldString_Comparison_Exp>
  updatedAt?: InputMaybe<Reyieldtimestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type ReyieldsuggestStrategyToolWithAllocations_Max_Fields = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_max_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type ReyieldsuggestStrategyToolWithAllocations_Min_Fields = {
  __typename?: 'ReyieldsuggestStrategyToolWithAllocations_min_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** Ordering options when selecting data from "suggestStrategyToolWithAllocations". */
export type ReyieldsuggestStrategyToolWithAllocations_Order_By = {
  aICompletionRecordId?: InputMaybe<Reyieldorder_By>
  allocation?: InputMaybe<Reyieldorder_By>
  createdAt?: InputMaybe<Reyieldorder_By>
  description?: InputMaybe<Reyieldorder_By>
  highestAnnualReturn?: InputMaybe<Reyieldorder_By>
  id?: InputMaybe<Reyieldorder_By>
  maximumLoss?: InputMaybe<Reyieldorder_By>
  name?: InputMaybe<Reyieldorder_By>
  sid?: InputMaybe<Reyieldorder_By>
  systemInvestAmountSuggestion?: InputMaybe<Reyieldorder_By>
  systemInvestCurrencySuggestion?: InputMaybe<Reyieldorder_By>
  updatedAt?: InputMaybe<Reyieldorder_By>
}

/** select columns of table "suggestStrategyToolWithAllocations" */
export enum ReyieldsuggestStrategyToolWithAllocations_Select_Column {
  /** column name */
  AICompletionRecordId = 'aICompletionRecordId',
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HighestAnnualReturn = 'highestAnnualReturn',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumLoss = 'maximumLoss',
  /** column name */
  Name = 'name',
  /** column name */
  Sid = 'sid',
  /** column name */
  SystemInvestAmountSuggestion = 'systemInvestAmountSuggestion',
  /** column name */
  SystemInvestCurrencySuggestion = 'systemInvestCurrencySuggestion',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Reyieldtimestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Reyielduuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export enum RiskLevel {
  RiskLevelHigh = 'RiskLevelHigh',
  RiskLevelLow = 'RiskLevelLow',
  RiskLevelMedium = 'RiskLevelMedium',
  RiskLevelMediumHigh = 'RiskLevelMediumHigh',
  RiskLevelMediumLow = 'RiskLevelMediumLow',
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  MediumHigh = 'medium_high',
  MediumLow = 'medium_low',
  Unknown = 'unknown',
}

export enum RiskToleranceLevelName {
  Aggressive = 'Aggressive',
  Conservative = 'Conservative',
  ExtremelyAggressive = 'ExtremelyAggressive',
  ExtremelyConservative = 'ExtremelyConservative',
  Moderate = 'Moderate',
  VeryConservative = 'VeryConservative',
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type Section = Entry & {
  __typename?: 'Section'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SectionLinkingCollections>
  sectionContentsCollection?: Maybe<SectionSectionContentsCollection>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type SectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type SectionSectionContentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionSectionContentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionContentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/section) */
export type SectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionCollection = {
  __typename?: 'SectionCollection'
  items: Array<Maybe<Section>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContent = Entry & {
  __typename?: 'SectionContent'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<SectionContentDescription>
  externalLink?: Maybe<Scalars['String']>
  externalLinkDisplayText?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionContentLinkingCollections>
  subTitle?: Maybe<Scalars['String']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentExternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentExternalLinkDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/sectionContent) */
export type SectionContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionContentCollection = {
  __typename?: 'SectionContentCollection'
  items: Array<Maybe<SectionContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionContentDescription = {
  __typename?: 'SectionContentDescription'
  json: Scalars['JSON']
  links: SectionContentDescriptionLinks
}

export type SectionContentDescriptionAssets = {
  __typename?: 'SectionContentDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type SectionContentDescriptionEntries = {
  __typename?: 'SectionContentDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type SectionContentDescriptionLinks = {
  __typename?: 'SectionContentDescriptionLinks'
  assets: SectionContentDescriptionAssets
  entries: SectionContentDescriptionEntries
  resources: SectionContentDescriptionResources
}

export type SectionContentDescriptionResources = {
  __typename?: 'SectionContentDescriptionResources'
  block: Array<ResourceLink>
}

export type SectionContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  externalLink?: InputMaybe<Scalars['String']>
  externalLinkDisplayText?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  externalLinkDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLinkDisplayText_not?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle?: InputMaybe<Scalars['String']>
  subTitle_contains?: InputMaybe<Scalars['String']>
  subTitle_exists?: InputMaybe<Scalars['Boolean']>
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle_not?: InputMaybe<Scalars['String']>
  subTitle_not_contains?: InputMaybe<Scalars['String']>
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionContentLinkingCollections = {
  __typename?: 'SectionContentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionCollection?: Maybe<SectionCollection>
}

export type SectionContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionContentLinkingCollectionsSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionContentLinkingCollectionsSectionCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionContentLinkingCollectionsSectionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionContentOrder {
  ExternalLinkDisplayTextAsc = 'externalLinkDisplayText_ASC',
  ExternalLinkDisplayTextDesc = 'externalLinkDisplayText_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  SubTitleAsc = 'subTitle_ASC',
  SubTitleDesc = 'subTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sectionContents?: InputMaybe<CfSectionContentNestedFilter>
  sectionContentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionLinkingCollections = {
  __typename?: 'SectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  strategyMetaCollection?: Maybe<StrategyMetaCollection>
}

export type SectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionLinkingCollectionsStrategyMetaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionLinkingCollectionsStrategyMetaCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionLinkingCollectionsStrategyMetaCollectionOrder {
  SidAsc = 'sid_ASC',
  SidDesc = 'sid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionSectionContentsCollection = {
  __typename?: 'SectionSectionContentsCollection'
  items: Array<Maybe<SectionContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionSectionContentsCollectionOrder {
  ExternalLinkDisplayTextAsc = 'externalLinkDisplayText_ASC',
  ExternalLinkDisplayTextDesc = 'externalLinkDisplayText_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  SubTitleAsc = 'subTitle_ASC',
  SubTitleDesc = 'subTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SmartContract = {
  __typename?: 'SmartContract'
  address: Scalars['String']
  chain: EvmChain
  name: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/specialUser) */
export type SpecialUser = Entry & {
  __typename?: 'SpecialUser'
  contentfulMetadata: ContentfulMetadata
  email?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SpecialUserLinkingCollections>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/specialUser) */
export type SpecialUserEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/specialUser) */
export type SpecialUserLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SpecialUserCollection = {
  __typename?: 'SpecialUserCollection'
  items: Array<Maybe<SpecialUser>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SpecialUserFilter = {
  AND?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SpecialUserFilter_Remote_Rel_Bitginspecial = {
  AND?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SpecialUserFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SpecialUserLinkingCollections = {
  __typename?: 'SpecialUserLinkingCollections'
  displayItemCollection?: Maybe<DisplayItemCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type SpecialUserLinkingCollectionsDisplayItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SpecialUserLinkingCollectionsDisplayItemCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SpecialUserLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SpecialUserLinkingCollectionsDisplayItemCollectionOrder {
  EnableSpecialSharingBackgroundsModeAsc = 'enableSpecialSharingBackgroundsMode_ASC',
  EnableSpecialSharingBackgroundsModeDesc = 'enableSpecialSharingBackgroundsMode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SpecialUserOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StrategyFilterInput = {
  riskLevel?: InputMaybe<RiskLevel>
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<StrategyType>
}

export type StrategyInfo = {
  __typename?: 'StrategyInfo'
  currency: Scalars['String']
  description: Scalars['String']
  earningCurrency: Scalars['String']
  exchanges: Array<Exchange>
  expectedAPY: Scalars['String']
  id: Scalars['String']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  parameters: Array<StrategyParameters>
  riskLevel: RiskLevel
  sid: Scalars['String']
  stage: StrategyInfoStage
  suggestionPeriod: Scalars['String']
  target: Scalars['String']
  type: StrategyType
}

export enum StrategyInfoStage {
  Active = 'Active',
  Beta = 'Beta',
  Mock = 'Mock',
}

export type StrategyInfos = {
  __typename?: 'StrategyInfos'
  currency: Scalars['String']
  earningCurrency: Scalars['String']
  exchange?: Maybe<Exchange>
  minEnableAmount: Scalars['String']
  name: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMeta = Entry & {
  __typename?: 'StrategyMeta'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<StrategyMetaLinkingCollections>
  sectionsCollection?: Maybe<StrategyMetaSectionsCollection>
  sid?: Maybe<Scalars['String']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMetaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMetaSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<StrategyMetaSectionsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/hzepso4hl9sl/content_types/strategyMeta) */
export type StrategyMetaSidArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type StrategyMetaCollection = {
  __typename?: 'StrategyMetaCollection'
  items: Array<Maybe<StrategyMeta>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type StrategyMetaFilter = {
  AND?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sections?: InputMaybe<CfSectionNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sid?: InputMaybe<Scalars['String']>
  sid_contains?: InputMaybe<Scalars['String']>
  sid_exists?: InputMaybe<Scalars['Boolean']>
  sid_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sid_not?: InputMaybe<Scalars['String']>
  sid_not_contains?: InputMaybe<Scalars['String']>
  sid_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo = {
  AND?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<StrategyMetaFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sections?: InputMaybe<CfSectionNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sid_contains?: InputMaybe<Scalars['String']>
  sid_exists?: InputMaybe<Scalars['Boolean']>
  sid_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sid_not?: InputMaybe<Scalars['String']>
  sid_not_contains?: InputMaybe<Scalars['String']>
  sid_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type StrategyMetaLinkingCollections = {
  __typename?: 'StrategyMetaLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type StrategyMetaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum StrategyMetaOrder {
  SidAsc = 'sid_ASC',
  SidDesc = 'sid_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type StrategyMetaSectionsCollection = {
  __typename?: 'StrategyMetaSectionsCollection'
  items: Array<Maybe<Section>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum StrategyMetaSectionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum StrategyParameterType {
  Bool = 'bool',
  Num = 'num',
  Str = 'str',
  Unknown = 'unknown',
}

export type StrategyParameters = {
  __typename?: 'StrategyParameters'
  defaultValue?: Maybe<Scalars['String']>
  description: Scalars['String']
  key: Scalars['String']
  maxValue?: Maybe<Scalars['String']>
  minValue?: Maybe<Scalars['String']>
  name: Scalars['String']
  type: StrategyParameterType
  updatable: Scalars['Boolean']
}

export type StrategyPerformance = {
  __typename?: 'StrategyPerformance'
  amount: Scalars['String']
  apy: Scalars['String']
  currency: Scalars['String']
  ror: Scalars['String']
}

export type StrategyProgress = {
  __typename?: 'StrategyProgress'
  createdAt: Scalars['String']
  id: Scalars['ID']
  index: Scalars['Int']
  status: StrategyProgressStatus
  steps: Array<Maybe<StrategyProgressStep>>
  type: StrategyProgressType
  updatedAt: Scalars['String']
}

export enum StrategyProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type StrategyProgressStep = {
  __typename?: 'StrategyProgressStep'
  status: StrategyProgressStepStatus
  type: StrategyProgressStepType
}

export enum StrategyProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum StrategyProgressStepType {
  CexDepositCheck = 'cex_deposit_check',
  CexSwap = 'cex_swap',
  CexWalletsTransfer = 'cex_wallets_transfer',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export enum StrategyProgressType {
  StrategyFinalize = 'strategy_finalize',
  StrategyStart = 'strategy_start',
  StrategyStop = 'strategy_stop',
  StrategyUpdate = 'strategy_update',
  Unknown = 'unknown',
}

export type StrategyStartInput = {
  parameters: Array<StrategyStartParamenters>
  sid: Scalars['String']
}

export type StrategyStartParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyStartResponse = {
  __typename?: 'StrategyStartResponse'
  strategy: UserStrategyInfo
}

export enum StrategyStatus {
  StrategyStatusError = 'StrategyStatusError',
  StrategyStatusObserving = 'StrategyStatusObserving',
  StrategyStatusPreparing = 'StrategyStatusPreparing',
  StrategyStatusRunning = 'StrategyStatusRunning',
  StrategyStatusStopped = 'StrategyStatusStopped',
  StrategyStatusStopping = 'StrategyStatusStopping',
  StrategyStatusUnknown = 'StrategyStatusUnknown',
  StrategyStatusWaitForUserAction = 'StrategyStatusWaitForUserAction',
  Error = 'error',
  Preparing = 'preparing',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Unknown = 'unknown',
  WaitForUserAction = 'wait_for_user_action',
}

export type StrategyStopInput = {
  isForce?: InputMaybe<Scalars['Boolean']>
  strategyID: Scalars['String']
}

export type StrategyStopResponse = {
  __typename?: 'StrategyStopResponse'
  strategy: UserStrategyInfo
}

export enum StrategyType {
  /** 搬磚 */
  StrategyTypeArbitrage = 'StrategyTypeArbitrage',
  /** DeFi */
  StrategyTypeDeFi = 'StrategyTypeDeFi',
  /** 期現套利 */
  StrategyTypeHedging = 'StrategyTypeHedging',
  /** 放貸 */
  StrategyTypeLending = 'StrategyTypeLending',
  StrategyTypeUnknown = 'StrategyTypeUnknown',
  Arbitrage = 'arbitrage',
  Defi = 'defi',
  Hedging = 'hedging',
  Lending = 'lending',
  Unknown = 'unknown',
}

export type StrategyUpdateInput = {
  parameters: Array<StrategyUpdateParamenters>
  strategyID: Scalars['String']
}

export type StrategyUpdateParamenters = {
  key: Scalars['String']
  value: Scalars['String']
}

export type StrategyUpdateResponse = {
  __typename?: 'StrategyUpdateResponse'
  strategy: UserStrategyInfo
}

/** Action */
export type Strategy_Action = {
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiApproveSwapReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIApproveSwapReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiDeductTxnFee = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIDeductTxnFee'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiDispatchUsdtReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIDispatchUSDTReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvAddLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVAddLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvApproveConvexBooster = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVApproveConvexBooster'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvApproveCurve3Pool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVApproveCurve3Pool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvClaimReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVClaimReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvDepositAll = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVDepositAll'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvRemoveLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVRemoveLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimim3CrvWithdrawAndUnwrap = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefimimusdtApproveCurve3Pool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIMIMUSDTApproveCurve3Pool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiRebalanceTxnFeeEstimation = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIRebalanceTxnFeeEstimation'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiStakePool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIStakePool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiSwapEthToUsdt = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFISwapETHToUSDT'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiSwapReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFISwapReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiTransferUserLpBalanceToPool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFITransferUserLPBalanceToPool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUnStakePool = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUnStakePool'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterAddLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterClaimReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterRmLiquidity = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterSwapEthToUsdt = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdatePoolBalanceAfterSwapReward = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionDefiUpdateUserRecordStatus = Strategy_Action & {
  __typename?: 'Strategy_ActionDEFIUpdateUserRecordStatus'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalDepositCheck = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalDepositCheck'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalSwap = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalSwap'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalWalletTransfer = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalWalletTransfer'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionExternalWithdrawal = Strategy_Action & {
  __typename?: 'Strategy_ActionExternalWithdrawal'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionInternalDepositCheck = Strategy_Action & {
  __typename?: 'Strategy_ActionInternalDepositCheck'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionInternalTrade = Strategy_Action & {
  __typename?: 'Strategy_ActionInternalTrade'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionInternalWithdrawal = Strategy_Action & {
  __typename?: 'Strategy_ActionInternalWithdrawal'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionLog = {
  __typename?: 'Strategy_ActionLog'
  action: Scalars['String']
  createdAt: Scalars['Strategy_Time']
  description: Scalars['String']
  id: Scalars['ID']
}

export type Strategy_ActionLogFilter = {
  actions?: InputMaybe<Array<Scalars['String']>>
  workflowIds?: InputMaybe<Array<Scalars['ID']>>
}

export type Strategy_ActionStrategyBatchStart = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyBatchStart'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyBatchStop = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyBatchStop'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyBatchUpdate = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyBatchUpdate'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyStart = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyStart'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyStop = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyStop'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionStrategyUpdate = Strategy_Action & {
  __typename?: 'Strategy_ActionStrategyUpdate'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferFromDefiWalletPostProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferFromDEFIWalletPostProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferFromDefiWalletPreProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferFromDEFIWalletPreProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferFromDefiWalletProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferFromDEFIWalletProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferToDefiWalletPostProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferToDEFIWalletPostProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferToDefiWalletPreProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferToDEFIWalletPreProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionTransferToDefiWalletProcessing = Strategy_Action & {
  __typename?: 'Strategy_ActionTransferToDEFIWalletProcessing'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_ActionWatchTxnStatus = Strategy_Action & {
  __typename?: 'Strategy_ActionWatchTxnStatus'
  error?: Maybe<Scalars['String']>
  params?: Maybe<Scalars['String']>
  status: Strategy_WorkflowActionStatus
  type: Strategy_WorkflowAction
}

export type Strategy_Admin = {
  __typename?: 'Strategy_Admin'
  externalAPIKeys: Array<Strategy_ExternalApiKey>
  workflows: Array<Strategy_Workflow>
}

export type Strategy_AdminExternalApiKeysArgs = {
  filter?: InputMaybe<Strategy_AdminExternalApiKeyFilter>
}

export type Strategy_AdminWorkflowsArgs = {
  filter?: InputMaybe<Strategy_AdminWorkflowFilter>
  pagination?: InputMaybe<PaginationQuery>
}

export type Strategy_AdminExternalApiKeyFilter = {
  exchange?: InputMaybe<Exchange>
  externalAPIKeyID?: InputMaybe<Scalars['ID']>
  isRevoked?: InputMaybe<Scalars['Boolean']>
  userID?: InputMaybe<Scalars['ID']>
}

export type Strategy_AdminMutation = {
  __typename?: 'Strategy_AdminMutation'
  defiNormalFlow: Strategy_Workflow
  defiRebalanceFlow: Strategy_Workflow
  defiWithdrawExpressFlow: Strategy_Workflow
  workflowRetry: Scalars['Boolean']
}

export type Strategy_AdminMutationDefiNormalFlowArgs = {
  input: Strategy_DefiNormalFlowInput
}

export type Strategy_AdminMutationDefiRebalanceFlowArgs = {
  input: Strategy_DefiRebalanceFlowInput
}

export type Strategy_AdminMutationDefiWithdrawExpressFlowArgs = {
  input: Strategy_DefiWithdrawExpressFlowInput
}

export type Strategy_AdminMutationWorkflowRetryArgs = {
  input: Strategy_AdminWorkflowRetryInput
}

export type Strategy_AdminWorkflowFilter = {
  id?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Array<InputMaybe<Strategy_WorkflowStatus>>>
  type?: InputMaybe<Array<InputMaybe<Strategy_WorkflowType>>>
  userID?: InputMaybe<Scalars['ID']>
}

export type Strategy_AdminWorkflowRetryInput = {
  id: Scalars['ID']
  renewActions?: InputMaybe<Array<InputMaybe<Strategy_RenewAction>>>
  startIndex?: InputMaybe<Scalars['Int']>
}

export type Strategy_ArbitrageState = {
  __typename?: 'Strategy_ArbitrageState'
  /** 已完成數量 */
  executedAmount: Scalars['Strategy_Number']
  /** USDT數量 */
  totalAmount: Scalars['Strategy_Number']
  /** 最後更新時間 */
  updatedAt: Scalars['Strategy_Time']
}

export type Strategy_Balance = {
  __typename?: 'Strategy_Balance'
  availTWDValue: Scalars['Strategy_Number']
  availUSDValue: Scalars['Strategy_Number']
  available: Scalars['Strategy_Number']
  currency: Scalars['String']
  total: Scalars['Strategy_Number']
  totalTWDValue: Scalars['Strategy_Number']
  totalUSDValue: Scalars['Strategy_Number']
  walletType: ExternalWallet
}

export type Strategy_CurrencyAmount = {
  __typename?: 'Strategy_CurrencyAmount'
  /** 數量 */
  amount: Scalars['Strategy_Number']
  /** 幣種 */
  currency: Scalars['String']
}

export type Strategy_DefiNormalFlowInput = {
  lpToken: Strategy_Token
  testTxHash?: InputMaybe<Scalars['String']>
}

export type Strategy_DefiRebalanceFlowInput = {
  lpToken: Strategy_Token
}

export type Strategy_DefiWithdrawExpressFlowInput = {
  lpToken: Strategy_Token
  testTxHash?: InputMaybe<Scalars['String']>
}

export type Strategy_DailyProfit = {
  __typename?: 'Strategy_DailyProfit'
  /** 當天收益 */
  amount: Scalars['Strategy_Number']
  /** 幣種 */
  currency: Scalars['String']
  /** 日期 */
  time: Scalars['Strategy_Time']
}

export type Strategy_DebtRecord = {
  __typename?: 'Strategy_DebtRecord'
  amount: Scalars['Int']
  id: Scalars['ID']
  time: Scalars['Strategy_Time']
}

export type Strategy_EstimatePointsConsumingInput = {
  parameters: Array<Strategy_StrategyParameterInput>
  sid: Scalars['String']
}

export type Strategy_EstimatePointsConsumingResult = {
  __typename?: 'Strategy_EstimatePointsConsumingResult'
  points: Scalars['Int']
  sid: Scalars['String']
}

export type Strategy_EstimateStrategyRequiredAmountResponse = {
  __typename?: 'Strategy_EstimateStrategyRequiredAmountResponse'
  currency: Currency
  lackingAmount: Scalars['Strategy_Number']
}

export type Strategy_ExchangeAsset = {
  __typename?: 'Strategy_ExchangeAsset'
  balances: Array<Strategy_Balance>
  exchange: Exchange
  netAvailBalanceInTWD: Scalars['Strategy_Number']
  netAvailBalanceInUSD: Scalars['Strategy_Number']
  netBalanceInTWD: Scalars['Strategy_Number']
  netBalanceInUSD: Scalars['Strategy_Number']
}

export type Strategy_ExchangeBalancesInput = {
  externalAPIKeyID: Scalars['ID']
}

export type Strategy_ExchangeWithdrawalAuthorizationInput = {
  externalAPIKeyIDs?: InputMaybe<Array<Scalars['ID']>>
}

export type Strategy_ExchangeWithdrawalAuthorizationResponse = {
  __typename?: 'Strategy_ExchangeWithdrawalAuthorizationResponse'
  exchange: Exchange
  is2FARequired: Scalars['Boolean']
  isWithdrawalPasswordRequired: Scalars['Boolean']
}

export type Strategy_ExchangesNetAssetChart = {
  __typename?: 'Strategy_ExchangesNetAssetChart'
  data: Array<Strategy_ExchangesNetAssetChartData>
  exchange: Exchange
}

export type Strategy_ExchangesNetAssetChartData = {
  __typename?: 'Strategy_ExchangesNetAssetChartData'
  netAssetTWDValue: Scalars['Strategy_Number']
  netAssetUSDValue: Scalars['Strategy_Number']
  time: Scalars['Strategy_Time']
}

export type Strategy_ExchangesNetAssetChartInput = {
  endTime: Scalars['Strategy_Time']
  exchanges: Array<Exchange>
  granularity: Granularity
  startTime?: InputMaybe<Scalars['Strategy_Time']>
}

export type Strategy_ExternalApiKey = {
  __typename?: 'Strategy_ExternalAPIKey'
  createdAt: Scalars['Strategy_Time']
  exchange: Exchange
  id: Scalars['ID']
  isRevoked: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  /** format in first 3 char + ********** + last 3 char */
  secret: Scalars['String']
  subaccount?: Maybe<Scalars['String']>
  userID: Scalars['ID']
}

export type Strategy_ExternalApiKeyCreateInput = {
  exchange: Exchange
  key: Scalars['String']
  name: Scalars['String']
  secret: Scalars['String']
  subaccount?: InputMaybe<Scalars['String']>
}

export type Strategy_ExternalApiKeyDeleteInput = {
  id: Scalars['ID']
}

export type Strategy_ExternalApiKeyDepositInput = {
  amount: Scalars['Strategy_Number']
  /** code2FA only for ftx */
  chain?: InputMaybe<Chain>
  /** password only for ftx */
  code2FA?: InputMaybe<Scalars['String']>
  /** when currency is twd, chain not required */
  currency: Currency
  externalAPIKeyID: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
}

export type Strategy_ExternalApiKeyFilter = {
  exchange?: InputMaybe<Exchange>
  externalAPIKeyID?: InputMaybe<Scalars['ID']>
  isRevoked?: InputMaybe<Scalars['Boolean']>
}

export type Strategy_ExternalApiKeyMutation = {
  __typename?: 'Strategy_ExternalAPIKeyMutation'
  externalAPIKeyCreate: Strategy_ExternalApiKey
  externalAPIKeyDelete: Scalars['Boolean']
  externalAPIKeyDeposit: Scalars['Boolean']
  externalAPIKeySwap: Scalars['Boolean']
  /** externalAPIKeyTransferBetweenWallets only for bitfinex so far */
  externalAPIKeyTransferBetweenWallets: Scalars['Boolean']
  /** deposit asset to this api key wallet */
  externalAPIKeyWithdrawal: Scalars['String']
  externalAPIKeyWithdrawalConfirm: Strategy_ExternalApiKeyWithdrawal
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyCreateArgs = {
  input: Strategy_ExternalApiKeyCreateInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyDeleteArgs = {
  input: Strategy_ExternalApiKeyDeleteInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyDepositArgs = {
  input: Strategy_ExternalApiKeyDepositInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeySwapArgs = {
  input: Strategy_ExternalApiKeySwapInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyTransferBetweenWalletsArgs = {
  input: Strategy_ExternalApiKeyTransferBetweenWalletsInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyWithdrawalArgs = {
  input: Strategy_ExternalApiKeyWithdrawalInput
}

export type Strategy_ExternalApiKeyMutationExternalApiKeyWithdrawalConfirmArgs = {
  input: Strategy_ExternalApiKeyWithdrawalConfirmInput
}

export type Strategy_ExternalApiKeySwapInput = {
  externalAPIKeyID: Scalars['ID']
  fromAmount: Scalars['Strategy_Number']
  fromCurrency: Scalars['String']
  toCurrency: Scalars['String']
}

export type Strategy_ExternalApiKeyTransferBetweenWalletsInput = {
  amount: Scalars['Strategy_Number']
  currency: Scalars['String']
  externalAPIKeyID: Scalars['ID']
  fromWallet: Scalars['String']
  toWallet: Scalars['String']
}

export type Strategy_ExternalApiKeyWithdrawal = {
  __typename?: 'Strategy_ExternalAPIKeyWithdrawal'
  amount: Scalars['Strategy_Number']
  createdAt: Scalars['Strategy_Time']
  currency: Currency
  exchange: Exchange
  id: Scalars['ID']
  toAddress: Scalars['String']
  userID: Scalars['ID']
  withdrawalID: Scalars['ID']
}

export type Strategy_ExternalApiKeyWithdrawalConfirmInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type Strategy_ExternalApiKeyWithdrawalInput = {
  amount: Scalars['Strategy_Number']
  currency: Currency
  externalAPIKeyID: Scalars['ID']
  isDeduction: Scalars['Boolean']
}

export type Strategy_GenericFlowMutation = {
  __typename?: 'Strategy_GenericFlowMutation'
  withdrawalCryptoStart: Strategy_Workflow
  withdrawalFiatStart: Strategy_Workflow
  withdrawalRetry: Strategy_Workflow
}

export type Strategy_GenericFlowMutationWithdrawalCryptoStartArgs = {
  input: Strategy_WithdrawalCryptoStartInput
}

export type Strategy_GenericFlowMutationWithdrawalFiatStartArgs = {
  input: Strategy_WithdrawalFiatStartInput
}

export type Strategy_GenericFlowMutationWithdrawalRetryArgs = {
  input: Strategy_WithdrawalRetryInput
}

export type Strategy_LendingOffer = {
  __typename?: 'Strategy_LendingOffer'
  /** 貸款數量 */
  amount: Scalars['Strategy_Number']
  /** 建立時間 */
  createdAt: Scalars['Strategy_Time']
  /** 放貸單號 */
  id: Scalars['String']
  /** 貸款天數 */
  period: Scalars['Int']
  /** 借出在哪個交易對 */
  positionPair: Scalars['String']
  /** 貸款日利率 */
  rate: Scalars['Strategy_Number']
}

export type Strategy_LendingState = {
  __typename?: 'Strategy_LendingState'
  /** 已借出 */
  activeOffers: Array<Strategy_LendingOffer>
  /** 掛單中 */
  openOffers: Array<Strategy_LendingOffer>
  /** 最後更新時間 */
  updatedAt: Scalars['Strategy_Time']
}

export type Strategy_Performance = {
  __typename?: 'Strategy_Performance'
  /** 數量 */
  amount: Scalars['Strategy_Number']
  /** 年化報酬率 */
  apy: Scalars['Strategy_Number']
  /** 幣種 */
  currency: Scalars['String']
  /** 累計收益率 */
  ror: Scalars['Strategy_Number']
}

export type Strategy_ProfitInputFilter = {
  sid?: InputMaybe<Scalars['String']>
  type?: InputMaybe<StrategyType>
}

export type Strategy_Public = {
  __typename?: 'Strategy_Public'
  strategies: Array<Strategy_StrategyInfo>
}

export type Strategy_PublicStrategiesArgs = {
  filter?: InputMaybe<Strategy_StrategyFilter>
}

export type Strategy_RenewAction = {
  /** params is the json string of action's runtime parameters after updated by admin */
  params: Scalars['String']
  type: Strategy_WorkflowAction
}

export type Strategy_Strategy = {
  __typename?: 'Strategy_Strategy'
  closedAt?: Maybe<Scalars['Strategy_Time']>
  createdAt: Scalars['Strategy_Time']
  /** 策略負債 */
  debt: Strategy_StrategyDebt
  displayInfo?: Maybe<StrategyMetaCollection>
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['ID']
  info: Strategy_StrategyInfo
  parameters: Array<Strategy_StrategyParameter>
  performance: Strategy_StrategyPerformance
  principal: Strategy_CurrencyAmount
  sid: Scalars['String']
  state?: Maybe<Strategy_StrategyState>
  status: StrategyStatus
  updatedAt: Scalars['Strategy_Time']
  workflows: Array<Strategy_Workflow>
  yieldRecords: Array<Maybe<Strategy_YieldRecord>>
}

export type Strategy_StrategyDisplayInfoArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<StrategyMetaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo>
}

export type Strategy_StrategyYieldRecordsArgs = {
  pagination?: InputMaybe<PaginationQuery>
}

export type Strategy_StrategyCloseInput = {
  id: Scalars['ID']
}

export type Strategy_StrategyDeFiStartInput = {
  parameters: Array<Strategy_StrategyParameterInput>
  sid: Scalars['String']
}

export type Strategy_StrategyDebt = {
  __typename?: 'Strategy_StrategyDebt'
  /** 總共欠繳點數 */
  quantity: Scalars['Int']
  /** 欠繳紀錄 */
  records: Array<Maybe<Strategy_DebtRecord>>
}

export type Strategy_StrategyDescription = {
  __typename?: 'Strategy_StrategyDescription'
  key: Scalars['String']
  value: Scalars['String']
}

export type Strategy_StrategyFilter = {
  riskLevel?: InputMaybe<RiskLevel>
  sid?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<StrategyStatus>>
  strategyID?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<StrategyType>
}

export type Strategy_StrategyInfo = {
  __typename?: 'Strategy_StrategyInfo'
  /** 預估關單所需礦工費 */
  closeGasFee: Strategy_CurrencyAmount
  /** 投入資金的幣種 */
  currency: Scalars['String']
  /** 策略介紹 */
  descriptionDetail: Scalars['String']
  /** deprecated... */
  descriptionList: Array<Strategy_StrategyDescription>
  displayInfo?: Maybe<StrategyMetaCollection>
  /** 利潤的幣種 */
  earningCurrency: Scalars['String']
  /** 策略運行的交易所 */
  exchanges: Array<Exchange>
  /** 預期年化 */
  expectedAPY: Scalars['String']
  /** deprecated... */
  feePerHourAmount: Scalars['String']
  /** 策略名稱 */
  name: Scalars['String']
  /** 開單次數 */
  numberOfApplication: Scalars['Int']
  /** 預估開單所需礦工費 */
  openGasFee: Strategy_CurrencyAmount
  /** 參數列表 */
  parameterDescriptions: Array<Strategy_StrategyParameterDesc>
  /** deprecated... */
  pointPerHourAmount: Scalars['String']
  /** 總共替用戶賺取利潤 */
  profits: Array<Strategy_CurrencyAmount>
  /** 風險等級 */
  riskLevel: RiskLevel
  /** 策略代碼 */
  sid: Scalars['String']
  /** 建議執行時間 */
  suggestionPeriod: Scalars['String']
  /** 策略標的 */
  target: Scalars['String']
  /** 策略類型 */
  type: StrategyType
}

export type Strategy_StrategyInfoDisplayInfoArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<StrategyMetaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<StrategyMetaFilter_Remote_Rel_Bitgin_StrategydisplayInfo>
}

export type Strategy_StrategyMutation = {
  __typename?: 'Strategy_StrategyMutation'
  strategyClose: Strategy_Strategy
  strategyDeFiStart: Strategy_Strategy
  strategyRetry: Scalars['Boolean']
  strategyStart: Strategy_Strategy
  strategyStop: Strategy_Strategy
  strategyUpdate: Strategy_Strategy
}

export type Strategy_StrategyMutationStrategyCloseArgs = {
  input: Strategy_StrategyCloseInput
}

export type Strategy_StrategyMutationStrategyDeFiStartArgs = {
  input: Strategy_StrategyDeFiStartInput
}

export type Strategy_StrategyMutationStrategyRetryArgs = {
  input: Strategy_StrategyRetryInput
}

export type Strategy_StrategyMutationStrategyStartArgs = {
  input: Strategy_StrategyStartInput
}

export type Strategy_StrategyMutationStrategyStopArgs = {
  input: Strategy_StrategyStopInput
}

export type Strategy_StrategyMutationStrategyUpdateArgs = {
  input: Strategy_StrategyUpdateInput
}

export type Strategy_StrategyParameter = {
  __typename?: 'Strategy_StrategyParameter'
  key: Scalars['String']
  value: Scalars['String']
}

export type Strategy_StrategyParameterDesc = {
  __typename?: 'Strategy_StrategyParameterDesc'
  defaultValue: Scalars['String']
  description: Scalars['String']
  key: Scalars['String']
  maxValue: Scalars['String']
  minValue: Scalars['String']
  name: Scalars['String']
  type: Strategy_StrategyParameterType
  updatable: Scalars['Boolean']
}

export type Strategy_StrategyParameterInput = {
  key: Scalars['String']
  value: Scalars['String']
}

export enum Strategy_StrategyParameterType {
  StrategyParameterTypeBool = 'StrategyParameterTypeBool',
  StrategyParameterTypeNum = 'StrategyParameterTypeNum',
  StrategyParameterTypeStr = 'StrategyParameterTypeStr',
}

export type Strategy_StrategyPerformance = {
  __typename?: 'Strategy_StrategyPerformance'
  /** in seconds */
  duration: Scalars['Int']
  /** 投入本金, 放貸是最大額度 */
  initialValue: Scalars['Strategy_Number']
  /** 收益績效，以幣種計算 */
  performances: Array<Strategy_Performance>
}

export type Strategy_StrategyRetryInput = {
  id: Scalars['ID']
}

export type Strategy_StrategyStartInput = {
  exAPIKeyIDs: Array<Scalars['ID']>
  parameters: Array<Strategy_StrategyParameterInput>
  sid: Scalars['String']
}

/** add other strategy state type in future */
export type Strategy_StrategyState = Strategy_ArbitrageState | Strategy_LendingState

export type Strategy_StrategyStopInput = {
  /** id for running strategy */
  id: Scalars['ID']
  /** 是否強制停止 */
  isForce?: InputMaybe<Scalars['Boolean']>
}

export type Strategy_StrategyUpdateInput = {
  /** id for running strategy */
  id: Scalars['ID']
  parameters: Array<Strategy_StrategyParameterInput>
}

export enum Strategy_Token {
  Crv = 'CRV',
  Cvx = 'CVX',
  Eth = 'ETH',
  Mim3Crv = 'MIM3CRV',
  Usdt = 'USDT',
  Weth = 'WETH',
}

export type Strategy_User = {
  __typename?: 'Strategy_User'
  actionLogs: Array<Strategy_ActionLog>
  /** 下個月進行中策略 + 負債 = 評估總點數是否足夠使用 */
  estimatePointIsEnough: Scalars['Boolean']
  estimatePointsConsuming: Array<Strategy_EstimatePointsConsumingResult>
  estimateStrategyRequiredAmount: Strategy_EstimateStrategyRequiredAmountResponse
  exchangeBalances: Array<Strategy_Balance>
  /** 出金是否需要 2FA or password */
  exchangeWithdrawalAuthorization?: Maybe<
    Array<Maybe<Strategy_ExchangeWithdrawalAuthorizationResponse>>
  >
  exchangesAssets: Array<Strategy_ExchangeAsset>
  exchangesNetAssetChart: Array<Strategy_ExchangesNetAssetChart>
  externalAPIKeys: Array<Strategy_ExternalApiKey>
  profits: Array<Maybe<Strategy_UserProfit>>
  strategies: Array<Strategy_Strategy>
  workflows: Array<Strategy_Workflow>
}

export type Strategy_UserActionLogsArgs = {
  filter?: InputMaybe<Strategy_ActionLogFilter>
  pagination: PaginationQuery
}

export type Strategy_UserEstimatePointsConsumingArgs = {
  input: Array<Strategy_EstimatePointsConsumingInput>
}

export type Strategy_UserEstimateStrategyRequiredAmountArgs = {
  input: Strategy_StrategyStartInput
}

export type Strategy_UserExchangeBalancesArgs = {
  input: Strategy_ExchangeBalancesInput
}

export type Strategy_UserExchangeWithdrawalAuthorizationArgs = {
  input: Strategy_ExchangeWithdrawalAuthorizationInput
}

export type Strategy_UserExchangesNetAssetChartArgs = {
  input: Strategy_ExchangesNetAssetChartInput
}

export type Strategy_UserExternalApiKeysArgs = {
  filter?: InputMaybe<Strategy_ExternalApiKeyFilter>
}

export type Strategy_UserProfitsArgs = {
  filter?: InputMaybe<Strategy_ProfitInputFilter>
}

export type Strategy_UserStrategiesArgs = {
  filter?: InputMaybe<Strategy_StrategyFilter>
  pagination: PaginationQuery
}

export type Strategy_UserWorkflowsArgs = {
  filter?: InputMaybe<Strategy_WorkflowFilter>
  pagination: PaginationQuery
}

export type Strategy_UserMutation = {
  __typename?: 'Strategy_UserMutation'
  externalAPIKey?: Maybe<Strategy_ExternalApiKeyMutation>
  genericFlow?: Maybe<Strategy_GenericFlowMutation>
  /** 償還所有策略所欠繳的點數 by 策略建立時間 */
  repayStrategyDebt: Scalars['Boolean']
  strategy?: Maybe<Strategy_StrategyMutation>
}

export type Strategy_UserProfit = {
  __typename?: 'Strategy_UserProfit'
  /** 最近14天的紀錄，如果當天沒收益紀錄則補零 */
  dailyProfits: Array<Strategy_DailyProfit>
  /** 策略ID */
  sid: Scalars['String']
  /** 總收益 */
  totalProfits: Array<Strategy_CurrencyAmount>
  /** 策略類型 */
  type: StrategyType
}

export type Strategy_WithdrawalCryptoStartInput = {
  exAPIKeyID: Scalars['ID']
  fromAmount: Scalars['Strategy_Number']
  fromCurrency: Scalars['String']
  fromExchange: Exchange
  fromWallet?: InputMaybe<ExternalWallet>
  password?: InputMaybe<Scalars['String']>
  toCurrency: Currency
  twoFactorCode?: InputMaybe<Scalars['String']>
  viaChain: Chain
  viaCurrency: Currency
}

export type Strategy_WithdrawalFiatStartInput = {
  amount: Scalars['Strategy_Number']
  currency: Currency
  exAPIKeyID: Scalars['ID']
  exchange: Exchange
  password?: InputMaybe<Scalars['String']>
  twoFactorCode?: InputMaybe<Scalars['String']>
}

export type Strategy_WithdrawalRetryInput = {
  password?: InputMaybe<Scalars['String']>
  twoFactorCode?: InputMaybe<Scalars['String']>
  workflowID: Scalars['ID']
}

export type Strategy_Workflow = {
  __typename?: 'Strategy_Workflow'
  actions: Array<Strategy_Action>
  createdAt: Scalars['Strategy_Time']
  id: Scalars['ID']
  index: Scalars['Int']
  status: Strategy_WorkflowStatus
  type: Strategy_WorkflowType
  updatedAt: Scalars['Strategy_Time']
}

export enum Strategy_WorkflowAction {
  WadefiApproveSwapReward = 'WADEFIApproveSwapReward',
  WadefiDeductTxnFee = 'WADEFIDeductTxnFee',
  WadefiDispatchUsdtReward = 'WADEFIDispatchUSDTReward',
  Wadefimim3CrvAddLiquidity = 'WADEFIMIM3CRVAddLiquidity',
  Wadefimim3CrvApproveConvexBooster = 'WADEFIMIM3CRVApproveConvexBooster',
  Wadefimim3CrvApproveCurve3Pool = 'WADEFIMIM3CRVApproveCurve3Pool',
  Wadefimim3CrvClaimReward = 'WADEFIMIM3CRVClaimReward',
  Wadefimim3CrvDepositAll = 'WADEFIMIM3CRVDepositAll',
  Wadefimim3CrvRemoveLiquidity = 'WADEFIMIM3CRVRemoveLiquidity',
  Wadefimim3CrvWithdrawAndUnwrap = 'WADEFIMIM3CRVWithdrawAndUnwrap',
  WadefiRebalanceTxnFeeEstimation = 'WADEFIRebalanceTxnFeeEstimation',
  WadefiStakePool = 'WADEFIStakePool',
  WadefiSwapEthToUsdt = 'WADEFISwapETHToUSDT',
  WadefiSwapReward = 'WADEFISwapReward',
  WadefiTransferUserLpBalanceToPool = 'WADEFITransferUserLPBalanceToPool',
  WadefiusdtApproveCurve3Pool = 'WADEFIUSDTApproveCurve3Pool',
  WadefiUnStakePool = 'WADEFIUnStakePool',
  WadefiUpdatePoolBalanceAfterAddLiquidity = 'WADEFIUpdatePoolBalanceAfterAddLiquidity',
  WadefiUpdatePoolBalanceAfterClaimReward = 'WADEFIUpdatePoolBalanceAfterClaimReward',
  WadefiUpdatePoolBalanceAfterRmLiquidity = 'WADEFIUpdatePoolBalanceAfterRmLiquidity',
  WadefiUpdatePoolBalanceAfterSwapEthToUsdt = 'WADEFIUpdatePoolBalanceAfterSwapETHToUSDT',
  WadefiUpdatePoolBalanceAfterSwapReward = 'WADEFIUpdatePoolBalanceAfterSwapReward',
  WadefiUpdateUserRecordStatus = 'WADEFIUpdateUserRecordStatus',
  WadefiWatchTxnStatus = 'WADEFIWatchTxnStatus',
  WaExternalDepositCheck = 'WAExternalDepositCheck',
  WaExternalSwap = 'WAExternalSwap',
  WaExternalWalletTransfer = 'WAExternalWalletTransfer',
  WaExternalWithdrawal = 'WAExternalWithdrawal',
  WaInternalDepositCheck = 'WAInternalDepositCheck',
  WaInternalTrade = 'WAInternalTrade',
  WaInternalWithdrawal = 'WAInternalWithdrawal',
  WaStrategyBatchStart = 'WAStrategyBatchStart',
  WaStrategyBatchStop = 'WAStrategyBatchStop',
  WaStrategyBatchUpdate = 'WAStrategyBatchUpdate',
  WaStrategyStart = 'WAStrategyStart',
  WaStrategyStop = 'WAStrategyStop',
  WaStrategyUpdate = 'WAStrategyUpdate',
  WaTransferFromDefiWalletPostProcessing = 'WATransferFromDEFIWalletPostProcessing',
  WaTransferFromDefiWalletPreProcessing = 'WATransferFromDEFIWalletPreProcessing',
  WaTransferFromDefiWalletProcessing = 'WATransferFromDEFIWalletProcessing',
  WaTransferToDefiWalletPostProcessing = 'WATransferToDEFIWalletPostProcessing',
  WaTransferToDefiWalletPreProcessing = 'WATransferToDEFIWalletPreProcessing',
  WaTransferToDefiWalletProcessing = 'WATransferToDEFIWalletProcessing',
  WaUnknown = 'WAUnknown',
}

export enum Strategy_WorkflowActionStatus {
  WaStatusFailed = 'WAStatusFailed',
  WaStatusProcessing = 'WAStatusProcessing',
  WaStatusSuccess = 'WAStatusSuccess',
  WaStatusUnknown = 'WAStatusUnknown',
  WaStatusWaiting = 'WAStatusWaiting',
}

export type Strategy_WorkflowFilter = {
  id?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<Array<InputMaybe<Strategy_WorkflowStatus>>>
  type?: InputMaybe<Array<InputMaybe<Strategy_WorkflowType>>>
}

export enum Strategy_WorkflowStatus {
  WorkflowStatusCancelled = 'WorkflowStatusCancelled',
  WorkflowStatusFailed = 'WorkflowStatusFailed',
  WorkflowStatusProcessing = 'WorkflowStatusProcessing',
  WorkflowStatusSuccess = 'WorkflowStatusSuccess',
  WorkflowStatusUnknown = 'WorkflowStatusUnknown',
}

export enum Strategy_WorkflowType {
  WorkflowTypeDefiNormal = 'WorkflowTypeDEFINormal',
  WorkflowTypeDefiRebalance = 'WorkflowTypeDEFIRebalance',
  WorkflowTypeDefiTest = 'WorkflowTypeDEFITest',
  WorkflowTypeDefiWithdrawExpress = 'WorkflowTypeDEFIWithdrawExpress',
  WorkflowTypeStrategyStart = 'WorkflowTypeStrategyStart',
  WorkflowTypeStrategyStop = 'WorkflowTypeStrategyStop',
  WorkflowTypeStrategyUpdate = 'WorkflowTypeStrategyUpdate',
  WorkflowTypeUnknown = 'WorkflowTypeUnknown',
  WorkflowTypeWithdrawFlowCrypto = 'WorkflowTypeWithdrawFlowCrypto',
  WorkflowTypeWithdrawFlowFiat = 'WorkflowTypeWithdrawFlowFiat',
}

export type Strategy_YieldRecord = {
  __typename?: 'Strategy_YieldRecord'
  amount: Scalars['Strategy_Number']
  currency: Scalars['String']
  id: Scalars['ID']
  rate: Scalars['Strategy_Number']
  time: Scalars['Strategy_Time']
  type: Scalars['String']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type Sys = {
  __typename?: 'Sys'
  environmentId: Scalars['String']
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedVersion?: Maybe<Scalars['Int']>
  spaceId: Scalars['String']
}

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']>
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>
  publishedAt_not?: InputMaybe<Scalars['DateTime']>
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedVersion?: InputMaybe<Scalars['Float']>
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>
  publishedVersion_gt?: InputMaybe<Scalars['Float']>
  publishedVersion_gte?: InputMaybe<Scalars['Float']>
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  publishedVersion_lt?: InputMaybe<Scalars['Float']>
  publishedVersion_lte?: InputMaybe<Scalars['Float']>
  publishedVersion_not?: InputMaybe<Scalars['Float']>
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

export type TokenBalance = {
  __typename?: 'TokenBalance'
  address: Scalars['String']
  amount: Scalars['String']
  amountReadable: Scalars['String']
  chain: EvmChain
  decimals: Scalars['Int']
  id: Scalars['String']
  symbol: Scalars['String']
}

export type TokenDisplayInfo = {
  __typename?: 'TokenDisplayInfo'
  address: Scalars['String']
  chain: EvmChain
  id: Scalars['String']
  imageURL: Scalars['String']
  name: Scalars['String']
  symbol: Scalars['String']
}

export type ToolInfo = StrategyInfo | UniswapToolInfo

export type TotalProfits = {
  __typename?: 'TotalProfits'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
}

export type Trade = {
  __typename?: 'Trade'
  createdAt: Scalars['Time']
  fee: Scalars['Number']
  feeCurrency: Currency
  feeRate: Scalars['Number']
  id: Scalars['ID']
  market: Market
  price: Scalars['Number']
  quoteBaseCurrency: Currency
  quoteOrderCurrency: Currency
  side: OrderSide
  size: Scalars['Number']
  updatedAt: Scalars['Time']
  userID: Scalars['ID']
}

export type TradeFilter = {
  endDate?: InputMaybe<Scalars['Time']>
  id?: InputMaybe<Scalars['ID']>
  markets?: InputMaybe<Array<Market>>
  side?: InputMaybe<OrderSide>
  startDate?: InputMaybe<Scalars['Time']>
}

export type TradeResponse = BaseResponse & {
  __typename?: 'TradeResponse'
  data?: Maybe<Array<Trade>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type Trading = {
  __typename?: 'Trading'
  history: TradeResponse
  report: TradingReportResponse
}

export type TradingHistoryArgs = {
  filter?: InputMaybe<TradeFilter>
  pagination: PaginationQuery
}

export type TradingReportArgs = {
  input: TradeFilter
}

export type TradingConfirmQuotationResponse = BaseResponse & {
  __typename?: 'TradingConfirmQuotationResponse'
  result: Scalars['Boolean']
}

export type TradingMutation = {
  __typename?: 'TradingMutation'
  confirmQuotation: TradingConfirmQuotationResponse
  getQuotation: TradingQuotationResponse
}

export type TradingMutationConfirmQuotationArgs = {
  clientID?: InputMaybe<Scalars['ID']>
  quotationId: Scalars['ID']
}

export type TradingMutationGetQuotationArgs = {
  input: TradingQuotationInput
}

export type TradingQuotationInput = {
  /** input base amount or quote amount */
  baseAmount?: InputMaybe<Scalars['Number']>
  market: Market
  quoteAmount?: InputMaybe<Scalars['Number']>
  side: OrderSide
}

export type TradingQuotationResponse = BaseResponse & {
  __typename?: 'TradingQuotationResponse'
  data?: Maybe<Quotation>
  result: Scalars['Boolean']
}

export type TradingReportResponse = BaseResponse & {
  __typename?: 'TradingReportResponse'
  /** base64 encoding */
  data?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export type UniswapOnchainPositionAmountInfo = {
  __typename?: 'UniswapOnchainPositionAmountInfo'
  /** this data is from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Amount: Scalars['String']
  token0AmountUsdValue: Scalars['String']
  /** this data is from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Amount: Scalars['String']
  token1AmountUsdValue: Scalars['String']
}

export type UniswapPoolInfo = {
  __typename?: 'UniswapPoolInfo'
  averageTradingVolumeUSD?: Maybe<Scalars['String']>
  chain: EvmChain
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  collectedFeesUSD: Scalars['String']
  createdAtBlockNumber: Scalars['String']
  createdAtTimestamp: Scalars['String']
  feeGrowthGlobal0X128: Scalars['String']
  feeGrowthGlobal1X128: Scalars['String']
  feeTier: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  /** this part is from smart contract developed by reyield */
  isPoolActiveForReyield?: Maybe<Scalars['Boolean']>
  liquidity: Scalars['String']
  liquidityProviderCount: Scalars['String']
  observationIndex: Scalars['String']
  positions?: Maybe<Array<UniswapPoolPosition>>
  sqrtPrice: Scalars['String']
  tick?: Maybe<Scalars['String']>
  ticks?: Maybe<Array<UniswapPoolTick>>
  token0?: Maybe<UniswapToolToken>
  token0Price: Scalars['String']
  token1?: Maybe<UniswapToolToken>
  token1Price: Scalars['String']
  totalValueLockedETH: Scalars['String']
  totalValueLockedToken0: Scalars['String']
  totalValueLockedToken1: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volumeToken0: Scalars['String']
  volumeToken1: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapPoolInfoAverageTradingVolumeUsdArgs = {
  numberOfDays: Scalars['Int']
}

export type UniswapPoolPosition = {
  __typename?: 'UniswapPoolPosition'
  collectedFeesToken0: Scalars['String']
  collectedFeesToken1: Scalars['String']
  depositedToken0: Scalars['String']
  depositedToken1: Scalars['String']
  feeGrowthInside0LastX128: Scalars['String']
  feeGrowthInside1LastX128: Scalars['String']
  id: Scalars['ID']
  liquidity: Scalars['String']
  tickLowerFeeGrowthOutside0X128: Scalars['String']
  tickLowerFeeGrowthOutside1X128: Scalars['String']
  tickLowerTickIdx: Scalars['String']
  tickUpperFeeGrowthOutside0X128: Scalars['String']
  tickUpperFeeGrowthOutside1X128: Scalars['String']
  tickUpperTickIdx: Scalars['String']
  transactionTimestamp: Scalars['String']
}

export type UniswapPoolTick = {
  __typename?: 'UniswapPoolTick'
  id: Scalars['String']
  liquidityNet: Scalars['String']
  price0: Scalars['String']
  price1: Scalars['String']
  tickIdx: Scalars['String']
}

export type UniswapQuote = {
  __typename?: 'UniswapQuote'
  chain: EvmChain
  fromToken: UniswapToolToken
  fromTokenAddress: Scalars['String']
  id: Scalars['String']
  inputAmount: Scalars['String']
  inputReadableAmount: Scalars['String']
  outputAmount: Scalars['String']
  outputReadableAmount: Scalars['String']
  toToken: UniswapToolToken
  toTokenAddress: Scalars['String']
}

export type UniswapQuoteInput = {
  chain: EvmChain
  fromTokenAddress: Scalars['String']
  inputReadableAmount: Scalars['String']
  toTokenAddress: Scalars['String']
}

export type UniswapToolFeeTier = {
  __typename?: 'UniswapToolFeeTier'
  feeForContract: Scalars['String']
  feeForDisplay: Scalars['String']
  id: Scalars['String']
}

export type UniswapToolInfo = {
  __typename?: 'UniswapToolInfo'
  chain: EvmChain
  createdAt: Scalars['String']
  creator?: Maybe<Creator>
  creatorAddress: Scalars['String']
  creatorId: Scalars['String']
  description: Scalars['String']
  expectedAPY: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  numOfApply?: Maybe<Scalars['Int']>
  performanceFee: Scalars['String']
  poolAddress: Scalars['String']
  /** this data is from thegraph */
  poolInfo?: Maybe<UniswapPoolInfo>
  riskLevel: RiskLevel
  tags?: Maybe<Array<UniswapToolTag>>
  tickLowerDiff: Scalars['String']
  tickLowerDiffForContract: Scalars['String']
  tickUpperDiff: Scalars['String']
  tickUpperDiffForContract?: Maybe<Scalars['String']>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
}

export enum UniswapToolPositionStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export enum UniswapToolStatus {
  Created = 'CREATED',
  Deprecated = 'DEPRECATED',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export type UniswapToolTag = {
  __typename?: 'UniswapToolTag'
  id: Scalars['String']
  name: Scalars['String']
  type: UniswapToolTagType
}

export enum UniswapToolTagType {
  Chain = 'chain',
  Custom = 'custom',
  IsOfficial = 'isOfficial',
  Uniswapv3 = 'uniswapv3',
  Unknown = 'unknown',
}

export type UniswapToolToken = {
  __typename?: 'UniswapToolToken'
  chain: EvmChain
  decimals: Scalars['String']
  derivedETH: Scalars['String']
  feesUSD: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  poolCount: Scalars['String']
  priceChart?: Maybe<UniswapToolTokenPriceChart>
  symbol: Scalars['String']
  totalSupply: Scalars['String']
  totalValueLocked: Scalars['String']
  totalValueLockedUSD: Scalars['String']
  totalValueLockedUSDUntracked: Scalars['String']
  txCount: Scalars['String']
  untrackedVolumeUSD: Scalars['String']
  volume: Scalars['String']
  volumeUSD: Scalars['String']
}

export type UniswapToolTokenPriceChart = {
  __typename?: 'UniswapToolTokenPriceChart'
  currentPriceUSD?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  prices?: Maybe<Array<UniswapToolTokenPriceChartPrice>>
}

export type UniswapToolTokenPriceChartPrice = {
  __typename?: 'UniswapToolTokenPriceChartPrice'
  timestamp: Scalars['String']
  value: Scalars['String']
}

export enum UniswapV3BacktestPeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type UniswapV3BacktestResult = {
  __typename?: 'UniswapV3BacktestResult'
  activeLiquidityPercent: Scalars['Float']
  annualReturn: Scalars['Float']
  compound: Scalars['Boolean']
  createdAt: Scalars['String']
  dailyReturn: Scalars['Float']
  dataPointsCount: Scalars['String']
  days: Scalars['Int']
  diff: Scalars['Float']
  fees?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  impermanentLoss: Scalars['Float']
  investmentAmount: Scalars['Float']
  maxDrawdown?: Maybe<Scalars['Float']>
  period: UniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: EvmChain
  totalReturn?: Maybe<Scalars['Float']>
  volatility?: Maybe<Scalars['Float']>
}

export type User = {
  __typename?: 'User'
  actionLogs: UserActionLogResponse
  agreements?: Maybe<Array<Scalars['String']>>
  amlInfo?: Maybe<Aml>
  createdAt: Scalars['Time']
  email: Scalars['Email']
  id: Scalars['ID']
  is2FAEnabled: Scalars['Boolean']
  isDisabled: Scalars['Boolean']
  isOldUser: Scalars['Boolean']
  kycInfo?: Maybe<Kyc>
  loginRecords: UserLoginRecordsResponse
  notifications?: Maybe<Notification>
  /** oauthClient: 取得該使用者授權過oauth的client */
  oauthClients: UserOAuthClientResponse
  phone?: Maybe<Scalars['String']>
  point?: Maybe<Point>
  preference: UserPreference
  /** the questionnaires filled out by this user */
  questionnaires: Array<Questionnaire>
  referralCodes: Array<Scalars['String']>
  /** referralCount: 取得該用戶的推薦好友數 */
  referralCount: Scalars['Int']
  /** return referrer name */
  referrer?: Maybe<Scalars['String']>
  roles: Array<UserRole>
  scopes: Array<UserScope>
  special?: Maybe<SpecialUserCollection>
  trading?: Maybe<Trading>
  updatedAt: Scalars['Time']
  vipLevel: VipLevel
  /** voucher: 兌換碼 */
  voucher?: Maybe<Voucher>
  wallet?: Maybe<Wallet>
}

export type UserActionLogsArgs = {
  pagination: PaginationQuery
}

export type UserLoginRecordsArgs = {
  pagination: PaginationQuery
}

export type UserOauthClientsArgs = {
  filter?: InputMaybe<UserOAuthClientFilter>
  pagination?: InputMaybe<PaginationQuery>
}

export type UserQuestionnairesArgs = {
  purpose?: InputMaybe<QuestionnairePurpose>
  version?: InputMaybe<Scalars['String']>
}

export type UserSpecialArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SpecialUserOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SpecialUserFilter_Remote_Rel_Bitginspecial>
}

export type UserAiCompletionRecordsFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserActionLog = {
  __typename?: 'UserActionLog'
  action: Scalars['String']
  description: Scalars['String']
  id: Scalars['ID']
  operator: Scalars['String']
  timestamp: Scalars['Time']
}

export type UserActionLogResponse = BaseResponse & {
  __typename?: 'UserActionLogResponse'
  logs: Array<UserActionLog>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type UserAgreeInput = {
  agreementVersion: Scalars['String']
}

export type UserAgreeResponse = BaseResponse & {
  __typename?: 'UserAgreeResponse'
  result: Scalars['Boolean']
}

export type UserBindPhoneConfirmationInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type UserBindPhoneConfirmationResponse = BaseResponse & {
  __typename?: 'UserBindPhoneConfirmationResponse'
  result: Scalars['Boolean']
}

export type UserBindPhoneInput = {
  countryCode: Scalars['String']
  /** E.164 format */
  phone: Scalars['String']
}

export type UserBindPhoneResponse = BaseResponse & {
  __typename?: 'UserBindPhoneResponse'
  id: Scalars['ID']
  result: Scalars['Boolean']
}

export type UserCancelOAuthInput = {
  clientID: Scalars['ID']
}

export type UserCancelOAuthResponse = BaseResponse & {
  __typename?: 'UserCancelOAuthResponse'
  result: Scalars['Boolean']
}

export type UserChangePasswordInput = {
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

export type UserChangePasswordResponse = BaseResponse & {
  __typename?: 'UserChangePasswordResponse'
  result: Scalars['Boolean']
}

export type UserCustomizeReferralInput = {
  referralCode: Scalars['String']
}

export type UserCustomizeReferralResponse = BaseResponse & {
  __typename?: 'UserCustomizeReferralResponse'
  data: UserReferral
  result: Scalars['Boolean']
}

export type UserDeleteBankAccountResponse = BaseResponse & {
  __typename?: 'UserDeleteBankAccountResponse'
  result: Scalars['Boolean']
}

export type UserDeviceTokenInput = {
  /** deviceToken: device token. we can get this value by call provider's api. ex: FCM onRegister function. */
  deviceToken: Scalars['String']
  /** model: mobile's model. ex: iphone14, pixel4...etc */
  model: Scalars['String']
  /** os: mobile os. ex: android, ios...etc */
  os: MobileOs
  /** provider: push notification provider. ex FCM, APNs, SNS...etc */
  provider: NotificationProvider
}

export type UserDeviceTokenResponse = BaseResponse & {
  __typename?: 'UserDeviceTokenResponse'
  result: Scalars['Boolean']
}

export type UserFillReferrerCodeInput = {
  referrerCode: Scalars['String']
}

export type UserFillReferrerCodeResponse = BaseResponse & {
  __typename?: 'UserFillReferrerCodeResponse'
  result: Scalars['Boolean']
}

export type UserForgotPasswordConfirmInput = {
  code: Scalars['String']
  email: Scalars['String']
  newPassword: Scalars['String']
}

export type UserForgotPasswordConfirmResponse = BaseResponse & {
  __typename?: 'UserForgotPasswordConfirmResponse'
  result: Scalars['Boolean']
}

export type UserForgotPasswordInput = {
  email: Scalars['String']
}

export type UserForgotPasswordResponse = BaseResponse & {
  __typename?: 'UserForgotPasswordResponse'
  result: Scalars['Boolean']
}

export type UserInfo = {
  __typename?: 'UserInfo'
  aICompletionRecords?: Maybe<Array<AICompletionRecords>>
  cefiDepositAddress?: Maybe<CefiDepositAddress>
  cefiWallets?: Maybe<Array<CefiWallet>>
  defiTokensBalanceAmount?: Maybe<Array<Maybe<TokenBalance>>>
  defiWallets?: Maybe<Array<DefiWallet>>
  externalAPIKeys?: Maybe<Array<ExternalApiKey>>
  gimCoolPayPaymentInfo?: Maybe<GimCoolPayPaymentInfo>
  id: Scalars['String']
  isBitfinexAPIKeyBind: Scalars['Boolean']
  nftCollections?: Maybe<Array<NftCollections>>
  nftMerkleTreeHexProof?: Maybe<NftMerkleTreeHexProof>
  nftWhitelistInfoByPhrases?: Maybe<Array<NftWhitelistInfoByPhrase>>
  ownedToolsSetting?: Maybe<Array<UserUniswapToolSettingInfo>>
  reyieldLicense?: Maybe<ReyieldLicense>
  strategies?: Maybe<Array<UserStrategyInfo>>
  strategyPerformance?: Maybe<UserStrategyPerformance>
  strategyProfit?: Maybe<UserStrategyProfit>
  strategyProgress?: Maybe<StrategyProgress>
  strategyRecords?: Maybe<Array<UserStrategyRecord>>
  tools?: Maybe<Array<UserToolInfo>>
}

export type UserInfoAiCompletionRecordsArgs = {
  filter?: InputMaybe<UserAiCompletionRecordsFilterInput>
}

export type UserInfoCefiDepositAddressArgs = {
  filter?: InputMaybe<CefiDepositAddressInput>
}

export type UserInfoCefiWalletsArgs = {
  filter?: InputMaybe<CefiWalletFilterInput>
}

export type UserInfoDefiTokensBalanceAmountArgs = {
  input: DefiTokensBalanceAmountInput
}

export type UserInfoDefiWalletsArgs = {
  filter?: InputMaybe<DefiWalletsFilterInput>
}

export type UserInfoExternalApiKeysArgs = {
  filter?: InputMaybe<ExternalApiKeysFilter>
}

export type UserInfoGimCoolPayPaymentInfoArgs = {
  input?: InputMaybe<GimCoolPayPaymentInfoInput>
}

export type UserInfoStrategiesArgs = {
  filter?: InputMaybe<UserStrategyFilterInput>
}

export type UserInfoStrategyPerformanceArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyProfitArgs = {
  filter?: InputMaybe<UserStrategyProfitFilterInput>
}

export type UserInfoStrategyProgressArgs = {
  strategyID: Scalars['String']
}

export type UserInfoStrategyRecordsArgs = {
  filter?: InputMaybe<UserStrategyRecordsFilterInput>
}

export type UserLoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type UserLoginRecord = {
  __typename?: 'UserLoginRecord'
  browser: Scalars['String']
  device: Scalars['String']
  ipAddress: Scalars['String']
  ipCountry: Scalars['String']
  os: Scalars['String']
  timestamp: Scalars['Time']
  userAgent: Scalars['String']
}

export type UserLoginRecordsResponse = BaseResponse & {
  __typename?: 'UserLoginRecordsResponse'
  meta: MetaPagination
  records: Array<UserLoginRecord>
  result: Scalars['Boolean']
}

export type UserLoginResponse = BaseResponse & {
  __typename?: 'UserLoginResponse'
  data?: Maybe<LoginResponse>
  result: Scalars['Boolean']
}

export type UserMutation = {
  __typename?: 'UserMutation'
  userAgree: UserAgreeResponse
  userBindPhone: UserBindPhoneResponse
  userBindPhoneConfirmation: UserBindPhoneConfirmationResponse
  userCancelOAuth: UserCancelOAuthResponse
  userChangePassword: UserChangePasswordResponse
  userCustomizeReferral: UserCustomizeReferralResponse
  userDeleteBankAccount: UserDeleteBankAccountResponse
  userFillReferrerCode: UserFillReferrerCodeResponse
  userForgotPassword: UserForgotPasswordResponse
  userForgotPasswordConfirm: UserForgotPasswordConfirmResponse
  userLogin: UserLoginResponse
  userRefreshToken: UserRefreshTokenResponse
  userRegister: UserRegisterResponse
  userRegisterConfirm: UserRegisterConfirmResponse
  /**
   * userRegisterDeviceToken: record user's device token. plz call this api every time when:
   * - login success
   * - app launch
   */
  userRegisterDeviceToken: UserDeviceTokenResponse
  userRequestAccountDisable: UserRequestAccountDisableResponse
  userResendForgotPassword: UserResendForgotPasswordResponse
  userResendRegister: UserResendRegisterResponse
  userSetPreference: UserPreferenceResponse
  userSubmitQuestionnaire: Questionnaire
}

export type UserMutationUserAgreeArgs = {
  input: UserAgreeInput
}

export type UserMutationUserBindPhoneArgs = {
  input: UserBindPhoneInput
}

export type UserMutationUserBindPhoneConfirmationArgs = {
  input: UserBindPhoneConfirmationInput
}

export type UserMutationUserCancelOAuthArgs = {
  input: UserCancelOAuthInput
}

export type UserMutationUserChangePasswordArgs = {
  input: UserChangePasswordInput
}

export type UserMutationUserCustomizeReferralArgs = {
  input: UserCustomizeReferralInput
}

export type UserMutationUserFillReferrerCodeArgs = {
  input: UserFillReferrerCodeInput
}

export type UserMutationUserForgotPasswordArgs = {
  input: UserForgotPasswordInput
}

export type UserMutationUserForgotPasswordConfirmArgs = {
  input: UserForgotPasswordConfirmInput
}

export type UserMutationUserLoginArgs = {
  input: UserLoginInput
}

export type UserMutationUserRefreshTokenArgs = {
  input: UserRefreshTokenInput
}

export type UserMutationUserRegisterArgs = {
  input: UserRegisterInput
}

export type UserMutationUserRegisterConfirmArgs = {
  input: UserRegisterConfirmInput
}

export type UserMutationUserRegisterDeviceTokenArgs = {
  input: UserDeviceTokenInput
}

export type UserMutationUserResendForgotPasswordArgs = {
  input: UserResendForgotPasswordInput
}

export type UserMutationUserResendRegisterArgs = {
  input: UserResendRegisterInput
}

export type UserMutationUserSetPreferenceArgs = {
  input?: InputMaybe<UserSetPreferenceInput>
}

export type UserMutationUserSubmitQuestionnaireArgs = {
  input: QuestionnaireInput
}

export type UserOAuthClient = {
  __typename?: 'UserOAuthClient'
  clientID: Scalars['ID']
  isAuthorized: Scalars['Boolean']
  name: Scalars['String']
  updatedAt: Scalars['Time']
}

export type UserOAuthClientFilter = {
  isAuthorized?: InputMaybe<Scalars['Boolean']>
}

export type UserOAuthClientResponse = BaseResponse & {
  __typename?: 'UserOAuthClientResponse'
  clients: Array<UserOAuthClient>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type UserPreference = {
  __typename?: 'UserPreference'
  currency?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
}

export type UserPreferenceResponse = BaseResponse & {
  __typename?: 'UserPreferenceResponse'
  data?: Maybe<UserPreference>
  result: Scalars['Boolean']
}

export type UserReferral = {
  __typename?: 'UserReferral'
  code?: Maybe<Scalars['String']>
}

export type UserRefreshTokenInput = {
  email: Scalars['String']
  refreshToken: Scalars['String']
}

export type UserRefreshTokenResponse = BaseResponse & {
  __typename?: 'UserRefreshTokenResponse'
  data?: Maybe<LoginInfo>
  result: Scalars['Boolean']
}

export type UserRegisterConfirmInput = {
  code: Scalars['String']
  email: Scalars['String']
}

export type UserRegisterConfirmResponse = BaseResponse & {
  __typename?: 'UserRegisterConfirmResponse'
  result: Scalars['Boolean']
}

export type UserRegisterInput = {
  email: Scalars['Email']
  password: Scalars['String']
  referralCode?: InputMaybe<Scalars['String']>
}

export type UserRegisterResponse = BaseResponse & {
  __typename?: 'UserRegisterResponse'
  result: Scalars['Boolean']
}

export type UserRequestAccountDisableResponse = BaseResponse & {
  __typename?: 'UserRequestAccountDisableResponse'
  result: Scalars['Boolean']
}

export type UserResendForgotPasswordInput = {
  email: Scalars['String']
}

export type UserResendForgotPasswordResponse = BaseResponse & {
  __typename?: 'UserResendForgotPasswordResponse'
  result: Scalars['Boolean']
}

export type UserResendRegisterInput = {
  email: Scalars['String']
}

export type UserResendRegisterResponse = BaseResponse & {
  __typename?: 'UserResendRegisterResponse'
  result: Scalars['Boolean']
}

export enum UserRole {
  RoleAccounting = 'RoleAccounting',
  RoleCsManager = 'RoleCSManager',
  RoleCsMember = 'RoleCSMember',
  RoleCompliance = 'RoleCompliance',
  RoleDeveloper = 'RoleDeveloper',
  RoleMarketing = 'RoleMarketing',
  RoleRiskControl = 'RoleRiskControl',
  RoleSuperAdmin = 'RoleSuperAdmin',
}

export enum UserScope {
  ScopePublic = 'ScopePublic',
  ScopeUserAccountRead = 'ScopeUserAccountRead',
  ScopeUserAccountWrite = 'ScopeUserAccountWrite',
  ScopeUserBalanceRead = 'ScopeUserBalanceRead',
  ScopeUserCryptoDeposit = 'ScopeUserCryptoDeposit',
  ScopeUserCryptoInternalTransfer = 'ScopeUserCryptoInternalTransfer',
  ScopeUserCryptoWithdrawal = 'ScopeUserCryptoWithdrawal',
  ScopeUserFiatDeposit = 'ScopeUserFiatDeposit',
  ScopeUserFiatWithdrawal = 'ScopeUserFiatWithdrawal',
  ScopeUserKycRead = 'ScopeUserKYCRead',
  ScopeUserKycWrite = 'ScopeUserKYCWrite',
  ScopeUserLedgerRead = 'ScopeUserLedgerRead',
  ScopeUserPointWrite = 'ScopeUserPointWrite',
  ScopeUserTradeRead = 'ScopeUserTradeRead',
  ScopeUserTradeWrite = 'ScopeUserTradeWrite',
  ScopeUserWalletRead = 'ScopeUserWalletRead',
  ScopeUserWalletWrite = 'ScopeUserWalletWrite',
}

export type UserSetPreferenceInput = {
  currency?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Scalars['String']>
}

export type UserStrategyFilterInput = {
  sid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Array<InputMaybe<StrategyStatus>>>
  strategyID?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UserStrategyInfo = {
  __typename?: 'UserStrategyInfo'
  ceFiStatus: StrategyStatus
  closedAt?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  errMsg?: Maybe<Scalars['String']>
  id: Scalars['String']
  infos?: Maybe<StrategyInfos>
  parameters?: Maybe<Array<UserStrategyParameters>>
  performance?: Maybe<UserStrategyPerformance>
  profits?: Maybe<UserStrategyProfit>
  sid: Scalars['String']
  updatedAt: Scalars['String']
}

export type UserStrategyParameters = {
  __typename?: 'UserStrategyParameters'
  key: Scalars['String']
  value: Scalars['String']
}

export type UserStrategyPerformance = {
  __typename?: 'UserStrategyPerformance'
  duration?: Maybe<Scalars['Int']>
  initialValue?: Maybe<Scalars['String']>
  performances?: Maybe<Array<Maybe<StrategyPerformance>>>
}

export type UserStrategyProfit = {
  __typename?: 'UserStrategyProfit'
  sid?: Maybe<Scalars['String']>
  totalProfit?: Maybe<Array<Maybe<TotalProfits>>>
  type?: Maybe<StrategyType>
}

export type UserStrategyProfitFilterInput = {
  sid: Array<InputMaybe<Scalars['String']>>
  type?: InputMaybe<Array<InputMaybe<StrategyType>>>
}

export type UserStrategyRecord = {
  __typename?: 'UserStrategyRecord'
  amount?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  rate?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
  type?: Maybe<RecordsType>
}

export type UserStrategyRecordsFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  strategyID: Scalars['String']
  type?: InputMaybe<RecordsType>
}

export type UserToolInfo = UserStrategyInfo | UserUniswapPositionInfo

export type UserUniswapOnchainPositionInfo = {
  __typename?: 'UserUniswapOnchainPositionInfo'
  apy: Scalars['String']
  chain: EvmChain
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalInvestAmountUsdValue?: Maybe<Scalars['String']>
}

export type UserUniswapPositionInfo = {
  __typename?: 'UserUniswapPositionInfo'
  apy: Scalars['String']
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  closedPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  createdAt: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  /** backend will cache the data for n minutes */
  onchainPositionInfo?: Maybe<UserUniswapOnchainPositionInfo>
  poolAddress: Scalars['String']
  positionId: Scalars['String']
  positionIdFromSmartContractPositionManager?: Maybe<Scalars['Int']>
  progresses?: Maybe<Array<UserUniswapv3PositionProgress>>
  publicToolInfo?: Maybe<UniswapToolInfo>
  roi: Scalars['String']
  runningPositionAmount?: Maybe<UniswapOnchainPositionAmountInfo>
  status: UniswapToolPositionStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  toolDescription: Scalars['String']
  toolId: Scalars['String']
  toolIsOfficial: Scalars['Boolean']
  toolName: Scalars['String']
  toolPerformanceFeeRatio?: Maybe<Scalars['String']>
  /** if the user is the creator of the tool, then the toolSetting will be returned */
  toolSetting?: Maybe<UserUniswapToolSettingInfo>
  totalInvestAmountUsdValue: Scalars['String']
  updatedAt: Scalars['String']
  userAddress: Scalars['String']
}

export type UserUniswapPositionInfoOnchainPositionInfoArgs = {
  withoutCache?: InputMaybe<Scalars['Boolean']>
}

export type UserUniswapToolOutOfRangeInfo = {
  __typename?: 'UserUniswapToolOutOfRangeInfo'
  earnSpendRate: Scalars['String']
  maxRebalanceTimes: Scalars['Int']
  standbyInterval: Scalars['Int']
  standbyMaxInterval: Scalars['Int']
}

export type UserUniswapToolSettingInfo = {
  __typename?: 'UserUniswapToolSettingInfo'
  chain: EvmChain
  closedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  feeTier: UniswapToolFeeTier
  id: Scalars['String']
  isOfficial: Scalars['Boolean']
  name: Scalars['String']
  outOfLowerRange: UserUniswapToolOutOfRangeInfo
  outOfUpperRange: UserUniswapToolOutOfRangeInfo
  performanceFeeRatio?: Maybe<Scalars['String']>
  poolAddress: Scalars['String']
  /** User will have only one position for each tool */
  position?: Maybe<UserUniswapPositionInfo>
  publicToolInfo?: Maybe<UniswapToolInfo>
  status: UniswapToolStatus
  tickLowerDiff: Scalars['String']
  tickUpperDiff: Scalars['String']
  /** api call from thegraph */
  token0?: Maybe<UniswapToolToken>
  token0Address: Scalars['String']
  token0DisplayInfo?: Maybe<TokenDisplayInfo>
  /** api call from thegraph */
  token1?: Maybe<UniswapToolToken>
  token1Address: Scalars['String']
  token1DisplayInfo?: Maybe<TokenDisplayInfo>
  totalUserUsed: Scalars['Int']
  updatedAt?: Maybe<Scalars['String']>
}

export type UserUniswapv3PositionProgress = {
  __typename?: 'UserUniswapv3PositionProgress'
  createdAt: Scalars['String']
  id: Scalars['String']
  index: Scalars['Int']
  status: UserUniswapv3PositionProgressStatus
  steps: Array<UserUniswapv3PositionProgressStep>
  type: UserUniswapv3PositionProgressType
  updatedAt: Scalars['String']
}

export enum UserUniswapv3PositionProgressStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Prepared = 'prepared',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
}

export type UserUniswapv3PositionProgressStep = {
  __typename?: 'UserUniswapv3PositionProgressStep'
  status: UserUniswapv3PositionProgressStepStatus
  type: UserUniswapv3PositionProgressStepType
}

export enum UserUniswapv3PositionProgressStepStatus {
  Failed = 'failed',
  Processing = 'processing',
  Success = 'success',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export enum UserUniswapv3PositionProgressStepType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export enum UserUniswapv3PositionProgressType {
  Uniswapv3ClosePositionWatching = 'uniswapv3_close_position_watching',
  Uniswapv3CreateListedToolPositionWatching = 'uniswapv3_create_listed_tool_position_watching',
  Uniswapv3CreatePositionWatching = 'uniswapv3_create_position_watching',
  Uniswapv3ListToolWatching = 'uniswapv3_list_tool_watching',
  Unknown = 'unknown',
}

export enum VipLevel {
  VipLevel0 = 'VIPLevel0',
  VipLevel1 = 'VIPLevel1',
  VipLevel2 = 'VIPLevel2',
  VipLevel3 = 'VIPLevel3',
  VipLevel4 = 'VIPLevel4',
  VipLevel5 = 'VIPLevel5',
}

export type VersionInfo = {
  __typename?: 'VersionInfo'
  buildTime: Scalars['String']
  goVersion: Scalars['String']
  pbVersion: Scalars['String']
  revision: Scalars['String']
  version: Scalars['String']
}

export type Voucher = {
  __typename?: 'Voucher'
  /** isVoucherUsed: 確認該用戶是否已使用該兌換碼 */
  isVoucherUsed: Scalars['Boolean']
}

export type VoucherIsVoucherUsedArgs = {
  code: Scalars['String']
}

export type VoucherMutation = {
  __typename?: 'VoucherMutation'
  /** voucherUse: 使用兌換碼 */
  voucherUse: VoucherUseResponse
}

export type VoucherMutationVoucherUseArgs = {
  input: VoucherUseInput
}

export type VoucherRecord = {
  __typename?: 'VoucherRecord'
  /** code: 兌換碼，最大長度 8，unique */
  code: Scalars['String']
  /** description: 兌換碼說明，最大長度 100 */
  description?: Maybe<Scalars['String']>
  /** expiredDate: 兌換碼到期日期，空代表無限制 */
  expiredDate?: Maybe<Scalars['Time']>
  /** isDisabled: 兌換碼是否被停用，true:停用 false:可用 */
  isDisabled: Scalars['Boolean']
  /** maxUsageTimes: 兌換碼最大可用次數 */
  maxUsageTimes: Scalars['Int']
  /** name: 兌換碼名稱，最大長度 20 */
  name: Scalars['String']
  /** point: 兌換點數 */
  point: Scalars['Int']
  /** type: 兌換碼類型，最大長度 8 */
  type: Scalars['String']
  /** usedTimes: 兌換碼已使用次數 */
  usedTimes: Scalars['Int']
}

export type VoucherUseInput = {
  /** code: 兌換碼 */
  code: Scalars['String']
}

export type VoucherUseResponse = BaseResponse & {
  __typename?: 'VoucherUseResponse'
  point: Scalars['Int']
  result: Scalars['Boolean']
  type: Scalars['String']
}

export type Wallet = {
  __typename?: 'Wallet'
  balances?: Maybe<Array<Balance>>
  commonAddresses: CommonAddressResponse
  depositCryptoInfo?: Maybe<Array<Address>>
  depositFiatInfo: FiatDepositInfo
  deposits: DepositResponse
  faasPayments: FaasPaymentResponse
  isInternalTransfer: Scalars['Boolean']
  ledgers: LedgerResponse
  userID: Scalars['ID']
  withdrawInfo: WithdrawInfo
  withdrawals: WithdrawalResponse
}

export type WalletBalancesArgs = {
  filter?: InputMaybe<CurrencyFilter>
}

export type WalletCommonAddressesArgs = {
  filter?: InputMaybe<CommonAddressFilter>
  pagination: PaginationQuery
}

export type WalletDepositCryptoInfoArgs = {
  filter: ChainFilter
}

export type WalletDepositsArgs = {
  filter?: InputMaybe<DepositFilter>
  pagination: PaginationQuery
}

export type WalletFaasPaymentsArgs = {
  filter?: InputMaybe<FaasPaymentFilter>
  pagination: PaginationQuery
}

export type WalletIsInternalTransferArgs = {
  address: Scalars['String']
}

export type WalletLedgersArgs = {
  filter?: InputMaybe<LedgerFilter>
  pagination: PaginationQuery
}

export type WalletWithdrawInfoArgs = {
  filter: WithdrawInfoFilter
}

export type WalletWithdrawalsArgs = {
  filter?: InputMaybe<WithdrawalFilter>
  pagination: PaginationQuery
}

export type WalletAddressInput = {
  address: Scalars['String']
  chain: Chain
  currency: Currency
}

export type WalletBalances = {
  __typename?: 'WalletBalances'
  currencyBalances: Array<CurrencyBalances>
  wallet: Scalars['String']
}

export type WalletCreateCommonAddress = {
  address: WalletAddressInput
  name: Scalars['String']
}

export type WalletCreateCommonAddressResponse = BaseResponse & {
  __typename?: 'WalletCreateCommonAddressResponse'
  result: Scalars['Boolean']
}

export type WalletCreateCryptoAddressInput = {
  chain: Chain
  currency: Currency
}

export type WalletCreateCryptoAddressResponse = BaseResponse & {
  __typename?: 'WalletCreateCryptoAddressResponse'
  data?: Maybe<Address>
  result: Scalars['Boolean']
}

export type WalletCryptoWithdrawInput = {
  address: WalletAddressInput
  amount: Scalars['Number']
  deduct: Scalars['Boolean']
}

export type WalletCryptoWithdrawResponse = BaseResponse & {
  __typename?: 'WalletCryptoWithdrawResponse'
  data?: Maybe<WithdrawCryptoResponse>
  result: Scalars['Boolean']
}

export type WalletDeleteCommonAddress = {
  id: Scalars['ID']
}

export type WalletDeleteCommonAddressResponse = BaseResponse & {
  __typename?: 'WalletDeleteCommonAddressResponse'
  result: Scalars['Boolean']
}

export type WalletDepositResponse = BaseResponse & {
  __typename?: 'WalletDepositResponse'
  data?: Maybe<Array<DepositResponse>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type WalletFaasPaymentConfirmInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type WalletFaasPaymentConfirmResponse = BaseResponse & {
  __typename?: 'WalletFAASPaymentConfirmResponse'
  data: FaasPayment
  result: Scalars['Boolean']
}

export type WalletFiatWithdrawInput = {
  amount: Scalars['Number']
}

export type WalletFiatWithdrawResponse = BaseResponse & {
  __typename?: 'WalletFiatWithdrawResponse'
  data?: Maybe<WithdrawFiatResponse>
  result: Scalars['Boolean']
}

export type WalletMutation = {
  __typename?: 'WalletMutation'
  walletCreateCommonAddress: WalletCreateCommonAddressResponse
  walletCreateCryptoAddress: WalletCreateCryptoAddressResponse
  walletCryptoWithdraw: WalletCryptoWithdrawResponse
  walletDeleteCommonAddress: WalletDeleteCommonAddressResponse
  walletFAASPaymentConfirm: WalletFaasPaymentConfirmResponse
  walletFiatWithdraw: WalletFiatWithdrawResponse
  walletWithdrawConfirm: WalletWithdrawConfirmResponse
}

export type WalletMutationWalletCreateCommonAddressArgs = {
  input: WalletCreateCommonAddress
}

export type WalletMutationWalletCreateCryptoAddressArgs = {
  input: WalletCreateCryptoAddressInput
}

export type WalletMutationWalletCryptoWithdrawArgs = {
  input: WalletCryptoWithdrawInput
}

export type WalletMutationWalletDeleteCommonAddressArgs = {
  input: WalletDeleteCommonAddress
}

export type WalletMutationWalletFaasPaymentConfirmArgs = {
  input: WalletFaasPaymentConfirmInput
}

export type WalletMutationWalletFiatWithdrawArgs = {
  input: WalletFiatWithdrawInput
}

export type WalletMutationWalletWithdrawConfirmArgs = {
  input: WalletWithdrawConfirmInput
}

export enum WalletOperationType {
  WalletOperationTypeCryptoDeposit = 'WalletOperationTypeCryptoDeposit',
  WalletOperationTypeCryptoWithdraw = 'WalletOperationTypeCryptoWithdraw',
  WalletOperationTypeFiatDeposit = 'WalletOperationTypeFiatDeposit',
  WalletOperationTypeFiatWithdraw = 'WalletOperationTypeFiatWithdraw',
  WalletOperationTypeInternalTransferIn = 'WalletOperationTypeInternalTransferIn',
  WalletOperationTypeInternalTransferOut = 'WalletOperationTypeInternalTransferOut',
  WalletOperationTypeTrade = 'WalletOperationTypeTrade',
}

export enum WalletType {
  BfxExchange = 'bfx_exchange',
  BfxFunding = 'bfx_funding',
  BfxMargin = 'bfx_margin',
  Spot = 'spot',
  Unknown = 'unknown',
}

export type WalletWithdrawConfirmInput = {
  code: Scalars['String']
  id: Scalars['ID']
}

export type WalletWithdrawConfirmResponse = BaseResponse & {
  __typename?: 'WalletWithdrawConfirmResponse'
  data: Withdrawal
  result: Scalars['Boolean']
}

export type WalletWithdrawalResponse = BaseResponse & {
  __typename?: 'WalletWithdrawalResponse'
  data?: Maybe<Array<WithdrawalResponse>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export type WithdrawCryptoResponse = CryptoWithdrawal | InternalTransferOut | Response2Fa

export type WithdrawFiatResponse = FiatWithdrawal | Response2Fa

export type WithdrawInfo = {
  __typename?: 'WithdrawInfo'
  dailyAccumulated: Scalars['Number']
  dailyMaxLimit: Scalars['Number']
  fee: Scalars['Number']
  maxLimit: Scalars['Number']
  minLimit: Scalars['Number']
  monthlyAccumulated: Scalars['Number']
  monthlyMaxLimit: Scalars['Number']
}

export type WithdrawInfoFilter = {
  chain?: InputMaybe<Chain>
  currency: Currency
}

export type Withdrawal = CryptoWithdrawal | FiatWithdrawal | InternalTransferOut

export type WithdrawalFilter = {
  currency?: InputMaybe<Array<Currency>>
  endDate?: InputMaybe<Scalars['Time']>
  startDate?: InputMaybe<Scalars['Time']>
  status?: InputMaybe<Array<WithdrawalStatus>>
  type?: InputMaybe<Array<WithdrawalType>>
  withdrawalID?: InputMaybe<Scalars['ID']>
}

export type WithdrawalResponse = BaseResponse & {
  __typename?: 'WithdrawalResponse'
  data?: Maybe<Array<Withdrawal>>
  meta: MetaPagination
  result: Scalars['Boolean']
}

export enum WithdrawalStatus {
  WithdrawalStatusApproved = 'WithdrawalStatusApproved',
  WithdrawalStatusCancel = 'WithdrawalStatusCancel',
  WithdrawalStatusCompleted = 'WithdrawalStatusCompleted',
  WithdrawalStatusFailed = 'WithdrawalStatusFailed',
  WithdrawalStatusPending = 'WithdrawalStatusPending',
  WithdrawalStatusRejected = 'WithdrawalStatusRejected',
  WithdrawalStatusSent = 'WithdrawalStatusSent',
  WithdrawalStatusUnknown = 'WithdrawalStatusUnknown',
  WithdrawalStatusWaitingApproval = 'WithdrawalStatusWaitingApproval',
}

export enum WithdrawalType {
  WithdrawalTypeCrypto = 'WithdrawalTypeCrypto',
  WithdrawalTypeFiatKgi = 'WithdrawalTypeFiatKGI',
  WithdrawalTypeInternalTransfer = 'WithdrawalTypeInternalTransfer',
  WithdrawalTypeManualWithdrawal = 'WithdrawalTypeManualWithdrawal',
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecords = {
  __typename?: 'aICompletionRecords'
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['String']
  meta?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  promptMutationInput?: Maybe<Scalars['jsonb']>
  promptMutationName?: Maybe<Scalars['String']>
  /** An array relationship */
  suggestions: Array<SuggestStrategyToolWithAllocations>
  /** An aggregate relationship */
  suggestions_aggregate: SuggestStrategyToolWithAllocations_Aggregate
  updatedAt: Scalars['timestamptz']
  userAddress?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsPromptMutationInputArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestionsArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsSuggestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Order_By>>
  where?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
}

export type CfReleaseNoteNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfReleaseNoteNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfReleaseNoteNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  platform?: InputMaybe<Scalars['String']>
  platform_contains?: InputMaybe<Scalars['String']>
  platform_exists?: InputMaybe<Scalars['Boolean']>
  platform_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  platform_not?: InputMaybe<Scalars['String']>
  platform_not_contains?: InputMaybe<Scalars['String']>
  platform_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  releaseNote_contains?: InputMaybe<Scalars['String']>
  releaseNote_exists?: InputMaybe<Scalars['Boolean']>
  releaseNote_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  version?: InputMaybe<Scalars['String']>
  version_contains?: InputMaybe<Scalars['String']>
  version_exists?: InputMaybe<Scalars['Boolean']>
  version_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  version_not?: InputMaybe<Scalars['String']>
  version_not_contains?: InputMaybe<Scalars['String']>
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionContentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionContentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionContentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  externalLink?: InputMaybe<Scalars['String']>
  externalLinkDisplayText?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  externalLinkDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLinkDisplayText_not?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_contains?: InputMaybe<Scalars['String']>
  externalLinkDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle?: InputMaybe<Scalars['String']>
  subTitle_contains?: InputMaybe<Scalars['String']>
  subTitle_exists?: InputMaybe<Scalars['Boolean']>
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subTitle_not?: InputMaybe<Scalars['String']>
  subTitle_not_contains?: InputMaybe<Scalars['String']>
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sectionContentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSpecialUserNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSpecialUserNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSpecialUserNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

export type FrontendQuery = {
  __typename?: 'frontendQuery'
  version: FrontendVersion
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** Need admin permissions */
  admin?: Maybe<AdminMutation>
  auth?: Maybe<AuthMutation>
  dev?: Maybe<DevMutation>
  kyc?: Maybe<KycMutation>
  notification?: Maybe<NotificationMutation>
  point?: Maybe<PointMutation>
  resource?: Maybe<ResourceMutation>
  reyield?: Maybe<ReyieldMutation>
  strategy?: Maybe<StrategyMutation>
  trading?: Maybe<TradingMutation>
  user?: Maybe<UserMutation>
  voucher?: Maybe<VoucherMutation>
  wallet?: Maybe<WalletMutation>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type ReyieldMutation = {
  __typename?: 'reyieldMutation'
  closeUniswapv3Position?: Maybe<Scalars['String']>
  createAICompletionRecord: ReyieldaICompletionRecords
  /** Reyield Backend will monitor this position with the txHash (either pending or confirmed) */
  createUniswapv3Position?: Maybe<Scalars['String']>
  createUniswapv3Tool?: Maybe<Scalars['String']>
  externalAPIKeyCreate: ReyieldExternalApiKey
  externalAPIKeyDelete: ReyieldExternalApiKey
  strategyStart: ReyieldStrategyStartResponse
  strategyStop: ReyieldStrategyStopResponse
  strategyUpdate: ReyieldStrategyUpdateResponse
}

export type ReyieldMutationCloseUniswapv3PositionArgs = {
  input: ReyieldCloseUniswapv3PositionInput
}

export type ReyieldMutationCreateAiCompletionRecordArgs = {
  input: ReyieldCreateAiCompletionRecordInput
}

export type ReyieldMutationCreateUniswapv3PositionArgs = {
  input: ReyieldCreateUniswapv3PositionInput
}

export type ReyieldMutationCreateUniswapv3ToolArgs = {
  input: ReyieldCreateUniswapv3ToolInput
}

export type ReyieldMutationExternalApiKeyCreateArgs = {
  input: ReyieldExternalApiKeyCreateInput
}

export type ReyieldMutationExternalApiKeyDeleteArgs = {
  input: ReyieldExternalApiKeyDeleteInput
}

export type ReyieldMutationStrategyStartArgs = {
  input: ReyieldStrategyStartInput
}

export type ReyieldMutationStrategyStopArgs = {
  input: ReyieldStrategyStopInput
}

export type ReyieldMutationStrategyUpdateArgs = {
  input: ReyieldStrategyUpdateInput
}

export type ReyieldQuery = {
  __typename?: 'reyieldQuery'
  coinPrices?: Maybe<Array<ReyieldCoinPrice>>
  me: ReyieldUserInfo
  poolsWithArguments?: Maybe<Array<ReyieldUniswapPoolInfo>>
  rateHistorical?: Maybe<Array<ReyieldRateHistorical>>
  strategies: Array<ReyieldStrategyInfo>
  tools: Array<ReyieldToolInfo>
  uniswapQuote?: Maybe<ReyieldUniswapQuote>
  uniswapTokens?: Maybe<Array<ReyieldUniswapToolToken>>
  uniswapV3Backtest?: Maybe<ReyieldUniswapV3BacktestResult>
  version: ReyieldFrontendVersion
  wallet?: Maybe<ReyieldDefiWallet>
}

export type ReyieldQueryPoolsWithArgumentsArgs = {
  chain: ReyieldEvmChain
  first?: InputMaybe<Scalars['Int']>
  flag?: InputMaybe<ReyieldPoolsWithArgumentsFlag>
  orderBy?: InputMaybe<Scalars['String']>
  orderDirection?: InputMaybe<Scalars['String']>
  poolIds?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Int']>
  token0s?: InputMaybe<Array<Scalars['String']>>
  token1s?: InputMaybe<Array<Scalars['String']>>
}

export type ReyieldQueryRateHistoricalArgs = {
  filter: ReyieldRateHistoricalFilterInput
}

export type ReyieldQueryStrategiesArgs = {
  filter?: InputMaybe<ReyieldStrategyFilterInput>
}

export type ReyieldQueryUniswapQuoteArgs = {
  input: ReyieldUniswapQuoteInput
  source?: InputMaybe<ReyieldQuoteDataSource>
}

export type ReyieldQueryUniswapTokensArgs = {
  chain: ReyieldEvmChain
}

export type ReyieldQueryUniswapV3BacktestArgs = {
  compound: Scalars['Boolean']
  days: Scalars['Int']
  diff: Scalars['Float']
  investmentAmount: Scalars['Float']
  period: ReyieldUniswapV3BacktestPeriod
  poolID: Scalars['String']
  protocol: ReyieldEvmChain
}

export type ReyieldQueryWalletArgs = {
  input?: InputMaybe<ReyieldDefiWalletFilterInput>
}

export type StrategyMutation = {
  __typename?: 'strategyMutation'
  admin?: Maybe<Strategy_AdminMutation>
  user?: Maybe<Strategy_UserMutation>
}

export type StrategyQuery = {
  __typename?: 'strategyQuery'
  admin?: Maybe<Strategy_Admin>
  public?: Maybe<Strategy_Public>
  user?: Maybe<Strategy_User>
}

/** columns and relationships of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations = {
  __typename?: 'suggestStrategyToolWithAllocations'
  aICompletionRecordId: Scalars['String']
  allocation: Scalars['String']
  createdAt: Scalars['timestamptz']
  description: Scalars['String']
  highestAnnualReturn: Scalars['String']
  id: Scalars['uuid']
  maximumLoss: Scalars['String']
  name: Scalars['String']
  sid: Scalars['String']
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate'
  aggregate?: Maybe<SuggestStrategyToolWithAllocations_Aggregate_Fields>
  nodes: Array<SuggestStrategyToolWithAllocations>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp = {
  count?: InputMaybe<SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count>
}

export type SuggestStrategyToolWithAllocations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<SuggestStrategyToolWithAllocations_Max_Fields>
  min?: Maybe<SuggestStrategyToolWithAllocations_Min_Fields>
}

/** aggregate fields of "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<SuggestStrategyToolWithAllocations_Max_Order_By>
  min?: InputMaybe<SuggestStrategyToolWithAllocations_Min_Order_By>
}

/** Boolean expression to filter rows from the table "suggestStrategyToolWithAllocations". All fields are combined with a logical 'AND'. */
export type SuggestStrategyToolWithAllocations_Bool_Exp = {
  _and?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  _not?: InputMaybe<SuggestStrategyToolWithAllocations_Bool_Exp>
  _or?: InputMaybe<Array<SuggestStrategyToolWithAllocations_Bool_Exp>>
  aICompletionRecordId?: InputMaybe<String_Comparison_Exp>
  allocation?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  highestAnnualReturn?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  maximumLoss?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  sid?: InputMaybe<String_Comparison_Exp>
  systemInvestAmountSuggestion?: InputMaybe<String_Comparison_Exp>
  systemInvestCurrencySuggestion?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type SuggestStrategyToolWithAllocations_Max_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_max_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Max_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type SuggestStrategyToolWithAllocations_Min_Fields = {
  __typename?: 'suggestStrategyToolWithAllocations_min_fields'
  aICompletionRecordId?: Maybe<Scalars['String']>
  allocation?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  highestAnnualReturn?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  maximumLoss?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  sid?: Maybe<Scalars['String']>
  systemInvestAmountSuggestion?: Maybe<Scalars['String']>
  systemInvestCurrencySuggestion?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Min_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "suggestStrategyToolWithAllocations". */
export type SuggestStrategyToolWithAllocations_Order_By = {
  aICompletionRecordId?: InputMaybe<Order_By>
  allocation?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  highestAnnualReturn?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  maximumLoss?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  sid?: InputMaybe<Order_By>
  systemInvestAmountSuggestion?: InputMaybe<Order_By>
  systemInvestCurrencySuggestion?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** select columns of table "suggestStrategyToolWithAllocations" */
export enum SuggestStrategyToolWithAllocations_Select_Column {
  /** column name */
  AICompletionRecordId = 'aICompletionRecordId',
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HighestAnnualReturn = 'highestAnnualReturn',
  /** column name */
  Id = 'id',
  /** column name */
  MaximumLoss = 'maximumLoss',
  /** column name */
  Name = 'name',
  /** column name */
  Sid = 'sid',
  /** column name */
  SystemInvestAmountSuggestion = 'systemInvestAmountSuggestion',
  /** column name */
  SystemInvestCurrencySuggestion = 'systemInvestCurrencySuggestion',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "suggestStrategyToolWithAllocations" */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type SuggestStrategyToolWithAllocations_Stream_Cursor_Value_Input = {
  aICompletionRecordId?: InputMaybe<Scalars['String']>
  allocation?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  highestAnnualReturn?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  maximumLoss?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  sid?: InputMaybe<Scalars['String']>
  systemInvestAmountSuggestion?: InputMaybe<Scalars['String']>
  systemInvestCurrencySuggestion?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type TokenDisplayInfoFragment = {
  __typename?: 'TokenDisplayInfo'
  id: string
  chain: EvmChain
  name: string
  address: string
  imageURL: string
  symbol: string
}

export type UserUniswapv3PositionProgressFragment = {
  __typename?: 'UserUniswapv3PositionProgress'
  id: string
  createdAt: string
  updatedAt: string
  index: number
  status: UserUniswapv3PositionProgressStatus
  type: UserUniswapv3PositionProgressType
  steps: Array<{
    __typename?: 'UserUniswapv3PositionProgressStep'
    status: UserUniswapv3PositionProgressStepStatus
    type: UserUniswapv3PositionProgressStepType
  }>
}

export type UserUniswapOnchainPositionInfoFragment = {
  __typename?: 'UserUniswapOnchainPositionInfo'
  apy: string
  roi: string
  totalInvestAmountUsdValue?: string | null
  token0Address: string
  token1Address: string
  positionIdFromSmartContractPositionManager?: number | null
  positionId: string
  runningPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  closedPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
}

export type UniswapOnchainPositionAmountInfoFragment = {
  __typename?: 'UniswapOnchainPositionAmountInfo'
  token0Amount: string
  token1Amount: string
  token0AmountUsdValue: string
  token1AmountUsdValue: string
}

export type UserUniswapPositionInfoFragment = {
  __typename?: 'UserUniswapPositionInfo'
  id: string
  userAddress: string
  chain: EvmChain
  positionIdFromSmartContractPositionManager?: number | null
  toolId: string
  toolName: string
  toolDescription: string
  toolIsOfficial: boolean
  toolPerformanceFeeRatio?: string | null
  token0Address: string
  token1Address: string
  poolAddress: string
  tickLowerDiff: string
  tickUpperDiff: string
  createdAt: string
  closedAt?: string | null
  updatedAt: string
  apy: string
  roi: string
  totalInvestAmountUsdValue: string
  status: UniswapToolPositionStatus
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  publicToolInfo?: {
    __typename?: 'UniswapToolInfo'
    id: string
    description: string
    chain: EvmChain
    createdAt: string
    name: string
    numOfApply?: number | null
    expectedAPY: string
    isOfficial: boolean
    riskLevel: RiskLevel
    tickLowerDiff: string
    tickLowerDiffForContract: string
    tickUpperDiff: string
    tickUpperDiffForContract?: string | null
    performanceFee: string
    token0Address: string
    token1Address: string
    creatorId: string
    creatorAddress: string
    maximumLoss: string
    poolAddress: string
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    tags?: Array<{
      __typename?: 'UniswapToolTag'
      id: string
      type: UniswapToolTagType
      name: string
    }> | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForContract: string
      feeForDisplay: string
    }
    poolInfo?: {
      __typename?: 'UniswapPoolInfo'
      id: string
      untrackedVolumeUSD: string
      txCount: string
      totalValueLockedUSDUntracked: string
      totalValueLockedUSD: string
      totalValueLockedToken1: string
      totalValueLockedToken0: string
      totalValueLockedETH: string
      observationIndex: string
      feesUSD: string
      createdAtBlockNumber: string
      createdAtTimestamp: string
      liquidityProviderCount: string
      liquidity: string
      sqrtPrice: string
      feeGrowthGlobal0X128: string
      feeGrowthGlobal1X128: string
      tick?: string | null
      volumeUSD: string
      token0Price: string
      token1Price: string
      averageTradingVolumeUSD1D?: string | null
      averageTradingVolumeUSD7D?: string | null
      token0?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
      token1?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
    } | null
  } | null
  feeTier: {
    __typename?: 'UniswapToolFeeTier'
    id: string
    feeForDisplay: string
    feeForContract: string
  }
  runningPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  closedPositionAmount?: {
    __typename?: 'UniswapOnchainPositionAmountInfo'
    token0Amount: string
    token1Amount: string
    token0AmountUsdValue: string
    token1AmountUsdValue: string
  } | null
  onchainPositionInfo?: {
    __typename?: 'UserUniswapOnchainPositionInfo'
    apy: string
    roi: string
    totalInvestAmountUsdValue?: string | null
    token0Address: string
    token1Address: string
    positionIdFromSmartContractPositionManager?: number | null
    positionId: string
    runningPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    closedPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
  } | null
  progresses?: Array<{
    __typename?: 'UserUniswapv3PositionProgress'
    id: string
    createdAt: string
    updatedAt: string
    index: number
    status: UserUniswapv3PositionProgressStatus
    type: UserUniswapv3PositionProgressType
    steps: Array<{
      __typename?: 'UserUniswapv3PositionProgressStep'
      status: UserUniswapv3PositionProgressStepStatus
      type: UserUniswapv3PositionProgressStepType
    }>
  }> | null
}

export type UserUniswapToolSettingInfoFragment = {
  __typename?: 'UserUniswapToolSettingInfo'
  id: string
  chain: EvmChain
  name: string
  description: string
  createdAt?: string | null
  closedAt?: string | null
  updatedAt?: string | null
  poolAddress: string
  token0Address: string
  token1Address: string
  status: UniswapToolStatus
  tickLowerDiff: string
  tickUpperDiff: string
  isOfficial: boolean
  totalUserUsed: number
  performanceFeeRatio?: string | null
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  feeTier: {
    __typename?: 'UniswapToolFeeTier'
    id: string
    feeForDisplay: string
    feeForContract: string
  }
  publicToolInfo?: {
    __typename?: 'UniswapToolInfo'
    id: string
    description: string
    chain: EvmChain
    createdAt: string
    name: string
    numOfApply?: number | null
    expectedAPY: string
    isOfficial: boolean
    riskLevel: RiskLevel
    tickLowerDiff: string
    tickLowerDiffForContract: string
    tickUpperDiff: string
    tickUpperDiffForContract?: string | null
    performanceFee: string
    token0Address: string
    token1Address: string
    creatorId: string
    creatorAddress: string
    maximumLoss: string
    poolAddress: string
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    tags?: Array<{
      __typename?: 'UniswapToolTag'
      id: string
      type: UniswapToolTagType
      name: string
    }> | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForContract: string
      feeForDisplay: string
    }
    poolInfo?: {
      __typename?: 'UniswapPoolInfo'
      id: string
      untrackedVolumeUSD: string
      txCount: string
      totalValueLockedUSDUntracked: string
      totalValueLockedUSD: string
      totalValueLockedToken1: string
      totalValueLockedToken0: string
      totalValueLockedETH: string
      observationIndex: string
      feesUSD: string
      createdAtBlockNumber: string
      createdAtTimestamp: string
      liquidityProviderCount: string
      liquidity: string
      sqrtPrice: string
      feeGrowthGlobal0X128: string
      feeGrowthGlobal1X128: string
      tick?: string | null
      volumeUSD: string
      token0Price: string
      token1Price: string
      averageTradingVolumeUSD1D?: string | null
      averageTradingVolumeUSD7D?: string | null
      token0?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
      token1?: {
        __typename?: 'UniswapToolToken'
        id: string
        symbol: string
        name: string
        volumeUSD: string
        chain: EvmChain
        decimals: string
        volume: string
        untrackedVolumeUSD: string
        feesUSD: string
        txCount: string
        poolCount: string
        totalValueLocked: string
        totalValueLockedUSD: string
        totalValueLockedUSDUntracked: string
        derivedETH: string
        totalSupply: string
      } | null
    } | null
  } | null
  position?: {
    __typename?: 'UserUniswapPositionInfo'
    id: string
    userAddress: string
    chain: EvmChain
    positionIdFromSmartContractPositionManager?: number | null
    toolId: string
    toolName: string
    toolDescription: string
    toolIsOfficial: boolean
    toolPerformanceFeeRatio?: string | null
    token0Address: string
    token1Address: string
    poolAddress: string
    tickLowerDiff: string
    tickUpperDiff: string
    createdAt: string
    closedAt?: string | null
    updatedAt: string
    apy: string
    roi: string
    totalInvestAmountUsdValue: string
    status: UniswapToolPositionStatus
    token0DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    token1DisplayInfo?: {
      __typename?: 'TokenDisplayInfo'
      id: string
      chain: EvmChain
      name: string
      address: string
      imageURL: string
      symbol: string
    } | null
    publicToolInfo?: {
      __typename?: 'UniswapToolInfo'
      id: string
      description: string
      chain: EvmChain
      createdAt: string
      name: string
      numOfApply?: number | null
      expectedAPY: string
      isOfficial: boolean
      riskLevel: RiskLevel
      tickLowerDiff: string
      tickLowerDiffForContract: string
      tickUpperDiff: string
      tickUpperDiffForContract?: string | null
      performanceFee: string
      token0Address: string
      token1Address: string
      creatorId: string
      creatorAddress: string
      maximumLoss: string
      poolAddress: string
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
      } | null
      tags?: Array<{
        __typename?: 'UniswapToolTag'
        id: string
        type: UniswapToolTagType
        name: string
      }> | null
      feeTier: {
        __typename?: 'UniswapToolFeeTier'
        id: string
        feeForContract: string
        feeForDisplay: string
      }
      poolInfo?: {
        __typename?: 'UniswapPoolInfo'
        id: string
        untrackedVolumeUSD: string
        txCount: string
        totalValueLockedUSDUntracked: string
        totalValueLockedUSD: string
        totalValueLockedToken1: string
        totalValueLockedToken0: string
        totalValueLockedETH: string
        observationIndex: string
        feesUSD: string
        createdAtBlockNumber: string
        createdAtTimestamp: string
        liquidityProviderCount: string
        liquidity: string
        sqrtPrice: string
        feeGrowthGlobal0X128: string
        feeGrowthGlobal1X128: string
        tick?: string | null
        volumeUSD: string
        token0Price: string
        token1Price: string
        averageTradingVolumeUSD1D?: string | null
        averageTradingVolumeUSD7D?: string | null
        token0?: {
          __typename?: 'UniswapToolToken'
          id: string
          symbol: string
          name: string
          volumeUSD: string
          chain: EvmChain
          decimals: string
          volume: string
          untrackedVolumeUSD: string
          feesUSD: string
          txCount: string
          poolCount: string
          totalValueLocked: string
          totalValueLockedUSD: string
          totalValueLockedUSDUntracked: string
          derivedETH: string
          totalSupply: string
        } | null
        token1?: {
          __typename?: 'UniswapToolToken'
          id: string
          symbol: string
          name: string
          volumeUSD: string
          chain: EvmChain
          decimals: string
          volume: string
          untrackedVolumeUSD: string
          feesUSD: string
          txCount: string
          poolCount: string
          totalValueLocked: string
          totalValueLockedUSD: string
          totalValueLockedUSDUntracked: string
          derivedETH: string
          totalSupply: string
        } | null
      } | null
    } | null
    feeTier: {
      __typename?: 'UniswapToolFeeTier'
      id: string
      feeForDisplay: string
      feeForContract: string
    }
    runningPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    closedPositionAmount?: {
      __typename?: 'UniswapOnchainPositionAmountInfo'
      token0Amount: string
      token1Amount: string
      token0AmountUsdValue: string
      token1AmountUsdValue: string
    } | null
    onchainPositionInfo?: {
      __typename?: 'UserUniswapOnchainPositionInfo'
      apy: string
      roi: string
      totalInvestAmountUsdValue?: string | null
      token0Address: string
      token1Address: string
      positionIdFromSmartContractPositionManager?: number | null
      positionId: string
      runningPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      closedPositionAmount?: {
        __typename?: 'UniswapOnchainPositionAmountInfo'
        token0Amount: string
        token1Amount: string
        token0AmountUsdValue: string
        token1AmountUsdValue: string
      } | null
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
      } | null
    } | null
    progresses?: Array<{
      __typename?: 'UserUniswapv3PositionProgress'
      id: string
      createdAt: string
      updatedAt: string
      index: number
      status: UserUniswapv3PositionProgressStatus
      type: UserUniswapv3PositionProgressType
      steps: Array<{
        __typename?: 'UserUniswapv3PositionProgressStep'
        status: UserUniswapv3PositionProgressStepStatus
        type: UserUniswapv3PositionProgressStepType
      }>
    }> | null
  } | null
  outOfLowerRange: {
    __typename?: 'UserUniswapToolOutOfRangeInfo'
    maxRebalanceTimes: number
    standbyInterval: number
    standbyMaxInterval: number
    earnSpendRate: string
  }
  outOfUpperRange: {
    __typename?: 'UserUniswapToolOutOfRangeInfo'
    maxRebalanceTimes: number
    standbyInterval: number
    standbyMaxInterval: number
    earnSpendRate: string
  }
}

export type UniswapToolFeeTierFragment = {
  __typename?: 'UniswapToolFeeTier'
  id: string
  feeForDisplay: string
  feeForContract: string
}

export type UserUniswapToolOutOfRangeInfoFragment = {
  __typename?: 'UserUniswapToolOutOfRangeInfo'
  maxRebalanceTimes: number
  standbyInterval: number
  standbyMaxInterval: number
  earnSpendRate: string
}

export type UniswapToolInfoFragment = {
  __typename?: 'UniswapToolInfo'
  id: string
  description: string
  chain: EvmChain
  createdAt: string
  name: string
  numOfApply?: number | null
  expectedAPY: string
  isOfficial: boolean
  riskLevel: RiskLevel
  tickLowerDiff: string
  tickLowerDiffForContract: string
  tickUpperDiff: string
  tickUpperDiffForContract?: string | null
  performanceFee: string
  token0Address: string
  token1Address: string
  creatorId: string
  creatorAddress: string
  maximumLoss: string
  poolAddress: string
  token0DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  token1DisplayInfo?: {
    __typename?: 'TokenDisplayInfo'
    id: string
    chain: EvmChain
    name: string
    address: string
    imageURL: string
    symbol: string
  } | null
  tags?: Array<{
    __typename?: 'UniswapToolTag'
    id: string
    type: UniswapToolTagType
    name: string
  }> | null
  feeTier: {
    __typename?: 'UniswapToolFeeTier'
    id: string
    feeForContract: string
    feeForDisplay: string
  }
  poolInfo?: {
    __typename?: 'UniswapPoolInfo'
    id: string
    untrackedVolumeUSD: string
    txCount: string
    totalValueLockedUSDUntracked: string
    totalValueLockedUSD: string
    totalValueLockedToken1: string
    totalValueLockedToken0: string
    totalValueLockedETH: string
    observationIndex: string
    feesUSD: string
    createdAtBlockNumber: string
    createdAtTimestamp: string
    liquidityProviderCount: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    tick?: string | null
    volumeUSD: string
    token0Price: string
    token1Price: string
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD7D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      volumeUSD: string
      chain: EvmChain
      decimals: string
      volume: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      totalSupply: string
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      volumeUSD: string
      chain: EvmChain
      decimals: string
      volume: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      totalSupply: string
    } | null
  } | null
}

export type ExploreToolCardTokenFragment = {
  __typename?: 'UniswapToolToken'
  id: string
  symbol: string
  name: string
  volumeUSD: string
  chain: EvmChain
  decimals: string
  volume: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  poolCount: string
  totalValueLocked: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  derivedETH: string
  totalSupply: string
}

export type UniswapPoolTokenFragment = {
  __typename?: 'UniswapToolToken'
  id: string
  symbol: string
  name: string
  decimals: string
  totalSupply: string
  volume: string
  volumeUSD: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  poolCount: string
  totalValueLocked: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  derivedETH: string
}

export type PoolWithReyieldPoolValidationFragment = {
  __typename?: 'UniswapPoolInfo'
  isPoolActiveForReyield?: boolean | null
  id: string
  createdAtTimestamp: string
  createdAtBlockNumber: string
  feeTier: string
  liquidity: string
  sqrtPrice: string
  feeGrowthGlobal0X128: string
  feeGrowthGlobal1X128: string
  token0Price: string
  token1Price: string
  tick?: string | null
  observationIndex: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  collectedFeesToken0: string
  collectedFeesToken1: string
  collectedFeesUSD: string
  totalValueLockedToken0: string
  totalValueLockedToken1: string
  totalValueLockedETH: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  liquidityProviderCount: string
  chain: EvmChain
  averageTradingVolumeUSD1D?: string | null
  averageTradingVolumeUSD30D?: string | null
  averageTradingVolumeUSD365D?: string | null
  token0?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
  token1?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
}

export type UniswapToolTokenPriceChartFragment = {
  __typename?: 'UniswapToolTokenPriceChart'
  id?: string | null
  currentPriceUSD?: string | null
  prices?: Array<{
    __typename?: 'UniswapToolTokenPriceChartPrice'
    timestamp: string
    value: string
  }> | null
}

export type PoolFragment = {
  __typename?: 'UniswapPoolInfo'
  id: string
  createdAtTimestamp: string
  createdAtBlockNumber: string
  feeTier: string
  liquidity: string
  sqrtPrice: string
  feeGrowthGlobal0X128: string
  feeGrowthGlobal1X128: string
  token0Price: string
  token1Price: string
  tick?: string | null
  observationIndex: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
  untrackedVolumeUSD: string
  feesUSD: string
  txCount: string
  collectedFeesToken0: string
  collectedFeesToken1: string
  collectedFeesUSD: string
  totalValueLockedToken0: string
  totalValueLockedToken1: string
  totalValueLockedETH: string
  totalValueLockedUSD: string
  totalValueLockedUSDUntracked: string
  liquidityProviderCount: string
  chain: EvmChain
  averageTradingVolumeUSD1D?: string | null
  averageTradingVolumeUSD30D?: string | null
  averageTradingVolumeUSD365D?: string | null
  token0?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
  token1?: {
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
    priceChart?: {
      __typename?: 'UniswapToolTokenPriceChart'
      id?: string | null
      currentPriceUSD?: string | null
      prices?: Array<{
        __typename?: 'UniswapToolTokenPriceChartPrice'
        timestamp: string
        value: string
      }> | null
    } | null
  } | null
}

export type DefiWalletFragment = {
  __typename?: 'DefiWallet'
  id: string
  chain: EvmChain
  balance: string
  balanceComputed?: string | null
  balanceComputedWithUnit?: string | null
  iconifySrc: string
  currencyInfo: { __typename?: 'CurrencyInfo'; name: string; symbol: string; decimals: number }
}

export type CurrencyInfoFragment = {
  __typename?: 'CurrencyInfo'
  name: string
  symbol: string
  decimals: number
}

export type ExternalApiKeyFragment = {
  __typename?: 'ExternalAPIKey'
  id: string
  exchange: Exchange
  key: string
  name: string
  isRevoked: boolean
}

export type PublicStrategyParameterFragment = {
  __typename?: 'StrategyParameters'
  defaultValue?: string | null
  description: string
  key: string
  maxValue?: string | null
  minValue?: string | null
  name: string
  type: StrategyParameterType
  updatable: boolean
}

export type PublicStrategyInfoFragment = {
  __typename?: 'StrategyInfo'
  id: string
  sid: string
  currency: string
  description: string
  earningCurrency: string
  exchanges: Array<Exchange>
  expectedAPY: string
  name: string
  suggestionPeriod: string
  target: string
  type: StrategyType
  riskLevel: RiskLevel
  numOfApply?: number | null
  maximumLoss: string
  stage: StrategyInfoStage
  parameters: Array<{
    __typename?: 'StrategyParameters'
    defaultValue?: string | null
    description: string
    key: string
    maxValue?: string | null
    minValue?: string | null
    name: string
    type: StrategyParameterType
    updatable: boolean
  }>
}

export type UserStrategyParametersFragment = {
  __typename?: 'UserStrategyParameters'
  key: string
  value: string
}

export type UserStrategiesFragment = {
  __typename?: 'UserStrategyInfo'
  id: string
  sid: string
  createdAt: string
  closedAt?: string | null
  updatedAt: string
  ceFiStatus: StrategyStatus
  errMsg?: string | null
  infos?: {
    __typename?: 'StrategyInfos'
    name: string
    currency: string
    earningCurrency: string
    exchange?: Exchange | null
    minEnableAmount: string
  } | null
  parameters?: Array<{ __typename?: 'UserStrategyParameters'; key: string; value: string }> | null
  performance?: {
    __typename?: 'UserStrategyPerformance'
    initialValue?: string | null
    duration?: number | null
    performances?: Array<{
      __typename?: 'StrategyPerformance'
      currency: string
      amount: string
      ror: string
      apy: string
    } | null> | null
  } | null
  profits?: {
    __typename?: 'UserStrategyProfit'
    sid?: string | null
    type?: StrategyType | null
    totalProfit?: Array<{
      __typename?: 'TotalProfits'
      currency?: string | null
      amount?: string | null
    } | null> | null
  } | null
}

export type UserStrategyInfoFragment = {
  __typename?: 'UserStrategyInfo'
  id: string
  sid: string
  createdAt: string
  closedAt?: string | null
  updatedAt: string
  ceFiStatus: StrategyStatus
  errMsg?: string | null
  infos?: {
    __typename?: 'StrategyInfos'
    name: string
    currency: string
    earningCurrency: string
    exchange?: Exchange | null
    minEnableAmount: string
  } | null
  parameters?: Array<{ __typename?: 'UserStrategyParameters'; key: string; value: string }> | null
  performance?: {
    __typename?: 'UserStrategyPerformance'
    initialValue?: string | null
    duration?: number | null
    performances?: Array<{
      __typename?: 'StrategyPerformance'
      currency: string
      amount: string
      ror: string
      apy: string
    } | null> | null
  } | null
  profits?: {
    __typename?: 'UserStrategyProfit'
    sid?: string | null
    type?: StrategyType | null
    totalProfit?: Array<{
      __typename?: 'TotalProfits'
      currency?: string | null
      amount?: string | null
    } | null> | null
  } | null
}

export type CefiWalletFragment = {
  __typename?: 'CefiWallet'
  exchange: string
  walletBalances: Array<{
    __typename?: 'WalletBalances'
    wallet: string
    currencyBalances: Array<{
      __typename?: 'CurrencyBalances'
      currency: string
      balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
    }>
  }>
}

export type WalletBalancesFragment = {
  __typename?: 'WalletBalances'
  wallet: string
  currencyBalances: Array<{
    __typename?: 'CurrencyBalances'
    currency: string
    balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
  }>
}

export type CurrencyBalancesFragment = {
  __typename?: 'CurrencyBalances'
  currency: string
  balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
}

export type StrategyProgressStepFragment = {
  __typename?: 'StrategyProgressStep'
  status: StrategyProgressStepStatus
  type: StrategyProgressStepType
}

export type StrategyProgressFragment = {
  __typename?: 'StrategyProgress'
  id: string
  createdAt: string
  updatedAt: string
  index: number
  status: StrategyProgressStatus
  type: StrategyProgressType
  steps: Array<{
    __typename?: 'StrategyProgressStep'
    status: StrategyProgressStepStatus
    type: StrategyProgressStepType
  } | null>
}

export type StrategyPerformanceFragment = {
  __typename?: 'StrategyPerformance'
  currency: string
  amount: string
  ror: string
  apy: string
}

export type UserStrategyProfitFragment = {
  __typename?: 'UserStrategyProfit'
  sid?: string | null
  type?: StrategyType | null
  totalProfit?: Array<{
    __typename?: 'TotalProfits'
    currency?: string | null
    amount?: string | null
  } | null> | null
}

export type TotalProfitsFragment = {
  __typename?: 'TotalProfits'
  currency?: string | null
  amount?: string | null
}

export type CoinPriceFragment = { __typename?: 'CoinPrice'; symbol: string; usdtPrice: string }

export type RateHistoricalFragment = { __typename?: 'RateHistorical'; time: string; rate: string }

export type UserStrategyRecordFragment = {
  __typename?: 'UserStrategyRecord'
  id?: string | null
  amount?: string | null
  currency?: string | null
  rate?: string | null
  time?: string | null
  type?: RecordsType | null
}

export type AiCompletionRecordFragment = {
  __typename?: 'aICompletionRecords'
  id: string
  promptMutationName?: string | null
  promptMutationInput?: any | null
  platform?: string | null
  meta?: any | null
  createdAt: any
  content?: string | null
  completedAt?: any | null
  updatedAt: any
  userAddress?: string | null
  userId?: string | null
}

export type SingleCompletionRecordForDialogFragment = {
  __typename?: 'aICompletionRecords'
  id: string
  promptMutationName?: string | null
  promptMutationInput?: any | null
  platform?: string | null
  meta?: any | null
  createdAt: any
  content?: string | null
  completedAt?: any | null
  updatedAt: any
  userAddress?: string | null
  userId?: string | null
  suggestions: Array<{
    __typename?: 'suggestStrategyToolWithAllocations'
    id: any
    maximumLoss: string
    name: string
    sid: string
    updatedAt: any
    highestAnnualReturn: string
    description: string
    createdAt: any
    allocation: string
    systemInvestAmountSuggestion?: string | null
    systemInvestCurrencySuggestion?: string | null
  }>
}

export type SuggestStrategyToolWithAllocationFragment = {
  __typename?: 'suggestStrategyToolWithAllocations'
  id: any
  maximumLoss: string
  name: string
  sid: string
  updatedAt: any
  highestAnnualReturn: string
  description: string
  createdAt: any
  allocation: string
  systemInvestAmountSuggestion?: string | null
  systemInvestCurrencySuggestion?: string | null
}

export type NftCollectionFragment = {
  __typename?: 'NFTCollections'
  name: string
  description: string
  tokenId: string
  contract: string
  imageUrl: string
  attributes?: Array<{
    __typename?: 'Attributes'
    traitType?: string | null
    value?: string | null
  } | null> | null
}

export type PositionFragment = {
  __typename?: 'UniswapPoolPosition'
  id: string
  tickLowerTickIdx: string
  tickLowerFeeGrowthOutside0X128: string
  tickLowerFeeGrowthOutside1X128: string
  tickUpperTickIdx: string
  tickUpperFeeGrowthOutside0X128: string
  tickUpperFeeGrowthOutside1X128: string
  depositedToken0: string
  depositedToken1: string
  liquidity: string
  transactionTimestamp: string
  collectedFeesToken0: string
  collectedFeesToken1: string
  feeGrowthInside0LastX128: string
  feeGrowthInside1LastX128: string
}

export type UserExternalApiKeyCreateMutationVariables = Exact<{
  input: ExternalApiKeyCreateInput
}>

export type UserExternalApiKeyCreateMutation = {
  __typename?: 'Mutation'
  externalAPIKeyCreate: {
    __typename?: 'ExternalAPIKey'
    id: string
    exchange: Exchange
    key: string
    name: string
    isRevoked: boolean
    user?: {
      __typename?: 'UserInfo'
      id: string
      externalAPIKeys?: Array<{
        __typename?: 'ExternalAPIKey'
        id: string
        exchange: Exchange
        key: string
        name: string
        isRevoked: boolean
      }> | null
    } | null
  }
}

export type UserExternalApiKeyDeleteMutationVariables = Exact<{
  input: ExternalApiKeyDeleteInput
}>

export type UserExternalApiKeyDeleteMutation = {
  __typename?: 'Mutation'
  externalAPIKeyDelete: {
    __typename?: 'ExternalAPIKey'
    id: string
    exchange: Exchange
    key: string
    name: string
    isRevoked: boolean
    user?: {
      __typename?: 'UserInfo'
      id: string
      externalAPIKeys?: Array<{
        __typename?: 'ExternalAPIKey'
        id: string
        exchange: Exchange
        key: string
        name: string
        isRevoked: boolean
      }> | null
    } | null
  }
}

export type UserStrategyStartMutationVariables = Exact<{
  input: StrategyStartInput
}>

export type UserStrategyStartMutation = {
  __typename?: 'Mutation'
  strategyStart: {
    __typename?: 'StrategyStartResponse'
    strategy: {
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }
  }
}

export type UserStrategyStopMutationVariables = Exact<{
  input: StrategyStopInput
}>

export type UserStrategyStopMutation = {
  __typename?: 'Mutation'
  strategyStop: {
    __typename?: 'StrategyStopResponse'
    strategy: {
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }
  }
}

export type UserStrategyUpdateMutationVariables = Exact<{
  input: StrategyUpdateInput
}>

export type UserStrategyUpdateMutation = {
  __typename?: 'Mutation'
  strategyUpdate: {
    __typename?: 'StrategyUpdateResponse'
    strategy: {
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }
  }
}

export type CreateAiCompletionRecordMutationVariables = Exact<{
  input: CreateAiCompletionRecordInput
}>

export type CreateAiCompletionRecordMutation = {
  __typename?: 'Mutation'
  createAICompletionRecord: {
    __typename?: 'aICompletionRecords'
    id: string
    promptMutationName?: string | null
    promptMutationInput?: any | null
    platform?: string | null
    meta?: any | null
    createdAt: any
    content?: string | null
    completedAt?: any | null
    updatedAt: any
    userAddress?: string | null
    userId?: string | null
  }
}

export type CreateUniswapv3ToolMutationVariables = Exact<{
  input: CreateUniswapv3ToolInput
}>

export type CreateUniswapv3ToolMutation = {
  __typename?: 'Mutation'
  createUniswapv3Tool?: string | null
}

export type CreateUniswapv3PositionMutationVariables = Exact<{
  input: CreateUniswapv3PositionInput
}>

export type CreateUniswapv3PositionMutation = {
  __typename?: 'Mutation'
  createUniswapv3Position?: string | null
}

export type CloseUniswapv3PositionMutationVariables = Exact<{
  input: CloseUniswapv3PositionInput
}>

export type CloseUniswapv3PositionMutation = {
  __typename?: 'Mutation'
  closeUniswapv3Position?: string | null
}

export type FetchMeQueryVariables = Exact<{
  filter?: InputMaybe<DefiWalletsFilterInput>
}>

export type FetchMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    defiWallets?: Array<{
      __typename?: 'DefiWallet'
      id: string
      chain: EvmChain
      balance: string
      balanceComputed?: string | null
      balanceComputedWithUnit?: string | null
      iconifySrc: string
      currencyInfo: { __typename?: 'CurrencyInfo'; name: string; symbol: string; decimals: number }
    }> | null
  }
}

export type FetchMequeryDefiTokensBalanceAmountQueryVariables = Exact<{
  input: DefiTokensBalanceAmountInput
}>

export type FetchMequeryDefiTokensBalanceAmountQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    defiTokensBalanceAmount?: Array<{
      __typename?: 'TokenBalance'
      id: string
      chain: EvmChain
      address: string
      symbol: string
      decimals: number
      amount: string
      amountReadable: string
    } | null> | null
  }
}

export type FetchPublicCoinPricesQueryVariables = Exact<{ [key: string]: never }>

export type FetchPublicCoinPricesQuery = {
  __typename?: 'Query'
  coinPrices?: Array<{ __typename?: 'CoinPrice'; symbol: string; usdtPrice: string }> | null
}

export type FetchMeGimCoolPayPaymentInfoQueryVariables = Exact<{
  input: GimCoolPayPaymentInfoInput
}>

export type FetchMeGimCoolPayPaymentInfoQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    gimCoolPayPaymentInfo?: {
      __typename?: 'GimCoolPayPaymentInfo'
      coinType: GimCoolPayPaymentSupportingCoinType
      fiatType: GimCoolPayPaymentSupportingFiatType
      coinAmount?: string | null
      fiatAmount?: string | null
      blockchain: GimCoolPayPaymentSupportingBlockchain
      walletAddress: string
      walletAddressTag?: string | null
      paymentHref: string
      price?: {
        __typename?: 'GimCoolPayPriceResponse'
        spotPrice: string
        prices?: Array<{
          __typename?: 'GimCoolPayReadyToPurchasePrice'
          paymentMethodId: string
          type: string
          spotPriceFee: string
          spotPriceIncludingFee: string
          coinAmount: string
          coinCode: string
          fiatAmount: string
          fiatCode: string
          feeAmount: string
          networkFee: string
        }> | null
      } | null
    } | null
  }
}

export type FetchMeSettingPageQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeSettingPageQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    isBitfinexAPIKeyBind: boolean
    externalAPIKeys?: Array<{
      __typename?: 'ExternalAPIKey'
      id: string
      exchange: Exchange
      key: string
      name: string
      isRevoked: boolean
    }> | null
  }
}

export type FetchPublicStrategyListQueryVariables = Exact<{
  filter?: InputMaybe<StrategyFilterInput>
}>

export type FetchPublicStrategyListQuery = {
  __typename?: 'Query'
  strategies: Array<{
    __typename?: 'StrategyInfo'
    id: string
    sid: string
    currency: string
    description: string
    earningCurrency: string
    exchanges: Array<Exchange>
    expectedAPY: string
    name: string
    suggestionPeriod: string
    target: string
    type: StrategyType
    riskLevel: RiskLevel
    numOfApply?: number | null
    maximumLoss: string
    stage: StrategyInfoStage
    parameters: Array<{
      __typename?: 'StrategyParameters'
      defaultValue?: string | null
      description: string
      key: string
      maxValue?: string | null
      minValue?: string | null
      name: string
      type: StrategyParameterType
      updatable: boolean
    }>
  }>
}

export type FetchPublicRateHistoricalQueryVariables = Exact<{
  filter: RateHistoricalFilterInput
}>

export type FetchPublicRateHistoricalQuery = {
  __typename?: 'Query'
  rateHistorical?: Array<{ __typename?: 'RateHistorical'; time: string; rate: string }> | null
}

export type FetchMeStrategyListQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyFilterInput>
}>

export type FetchMeStrategyListQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategies?: Array<{
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      createdAt: string
      closedAt?: string | null
      updatedAt: string
      ceFiStatus: StrategyStatus
      errMsg?: string | null
      infos?: {
        __typename?: 'StrategyInfos'
        name: string
        currency: string
        earningCurrency: string
        exchange?: Exchange | null
        minEnableAmount: string
      } | null
      parameters?: Array<{
        __typename?: 'UserStrategyParameters'
        key: string
        value: string
      }> | null
      performance?: {
        __typename?: 'UserStrategyPerformance'
        initialValue?: string | null
        duration?: number | null
        performances?: Array<{
          __typename?: 'StrategyPerformance'
          currency: string
          amount: string
          ror: string
          apy: string
        } | null> | null
      } | null
      profits?: {
        __typename?: 'UserStrategyProfit'
        sid?: string | null
        type?: StrategyType | null
        totalProfit?: Array<{
          __typename?: 'TotalProfits'
          currency?: string | null
          amount?: string | null
        } | null> | null
      } | null
    }> | null
  }
}

export type FetchMeAiRecordsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeAiRecordsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    aICompletionRecords?: Array<{
      __typename?: 'aICompletionRecords'
      id: string
      promptMutationName?: string | null
      promptMutationInput?: any | null
      platform?: string | null
      meta?: any | null
      createdAt: any
      content?: string | null
      completedAt?: any | null
      updatedAt: any
      userAddress?: string | null
      userId?: string | null
      suggestions: Array<{
        __typename?: 'suggestStrategyToolWithAllocations'
        id: any
        maximumLoss: string
        name: string
        sid: string
        updatedAt: any
        highestAnnualReturn: string
        description: string
        createdAt: any
        allocation: string
        systemInvestAmountSuggestion?: string | null
        systemInvestCurrencySuggestion?: string | null
      }>
    }> | null
  }
}

export type FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables = Exact<{
  filter: UserAiCompletionRecordsFilterInput
}>

export type FetchMeAiRecordDataByIdForPortfolioDialogQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    aICompletionRecords?: Array<{
      __typename?: 'aICompletionRecords'
      id: string
      promptMutationName?: string | null
      promptMutationInput?: any | null
      platform?: string | null
      meta?: any | null
      createdAt: any
      content?: string | null
      completedAt?: any | null
      updatedAt: any
      userAddress?: string | null
      userId?: string | null
      suggestions: Array<{
        __typename?: 'suggestStrategyToolWithAllocations'
        id: any
        maximumLoss: string
        name: string
        sid: string
        updatedAt: any
        highestAnnualReturn: string
        description: string
        createdAt: any
        allocation: string
        systemInvestAmountSuggestion?: string | null
        systemInvestCurrencySuggestion?: string | null
      }>
    }> | null
  }
}

export type FetchMeStrategyListForSidQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyFilterInput>
}>

export type FetchMeStrategyListForSidQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategies?: Array<{
      __typename?: 'UserStrategyInfo'
      id: string
      sid: string
      ceFiStatus: StrategyStatus
    }> | null
  }
}

export type FetchMeCexWalletBalancesQueryVariables = Exact<{
  filter?: InputMaybe<CefiWalletFilterInput>
}>

export type FetchMeCexWalletBalancesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    cefiWallets?: Array<{
      __typename?: 'CefiWallet'
      exchange: string
      walletBalances: Array<{
        __typename?: 'WalletBalances'
        wallet: string
        currencyBalances: Array<{
          __typename?: 'CurrencyBalances'
          currency: string
          balance: { __typename?: 'CurrencyBalance'; available: string; total: string }
        }>
      }>
    }> | null
  }
}

export type FetchMeCexDepositAddressQueryVariables = Exact<{
  filter?: InputMaybe<CefiDepositAddressInput>
}>

export type FetchMeCexDepositAddressQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    cefiDepositAddress?: { __typename?: 'CefiDepositAddress'; address: string; chain: Chain } | null
  }
}

export type FetchMeStrategyProgressQueryVariables = Exact<{
  strategyID: Scalars['String']
}>

export type FetchMeStrategyProgressQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategyProgress?: {
      __typename?: 'StrategyProgress'
      id: string
      createdAt: string
      updatedAt: string
      index: number
      status: StrategyProgressStatus
      type: StrategyProgressType
      steps: Array<{
        __typename?: 'StrategyProgressStep'
        status: StrategyProgressStepStatus
        type: StrategyProgressStepType
      } | null>
    } | null
  }
}

export type FetchMeStrategyTotalProfitQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyProfitFilterInput>
}>

export type FetchMeStrategyTotalProfitQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategyProfit?: {
      __typename?: 'UserStrategyProfit'
      sid?: string | null
      type?: StrategyType | null
      totalProfit?: Array<{
        __typename?: 'TotalProfits'
        currency?: string | null
        amount?: string | null
      } | null> | null
    } | null
  }
}

export type FetchMeStrategyRecordsQueryVariables = Exact<{
  filter?: InputMaybe<UserStrategyRecordsFilterInput>
}>

export type FetchMeStrategyRecordsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    strategyRecords?: Array<{
      __typename?: 'UserStrategyRecord'
      id?: string | null
      amount?: string | null
      currency?: string | null
      rate?: string | null
      time?: string | null
      type?: RecordsType | null
    }> | null
  }
}

export type FetchMeNftCollectionsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeNftCollectionsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    nftCollections?: Array<{
      __typename?: 'NFTCollections'
      name: string
      description: string
      tokenId: string
      contract: string
      imageUrl: string
      attributes?: Array<{
        __typename?: 'Attributes'
        traitType?: string | null
        value?: string | null
      } | null> | null
    }> | null
  }
}

export type FetchMeNftWhitelistInfoByPhrasesQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeNftWhitelistInfoByPhrasesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    nftWhitelistInfoByPhrases?: Array<{
      __typename?: 'NFTWhitelistInfoByPhrase'
      id: string
      phrase: Nft_Round
      userAddress: string
      isWhitelisted: boolean
    }> | null
  }
}

export type FetchMeNftMerkleTreeHexProofQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeNftMerkleTreeHexProofQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    nftMerkleTreeHexProof?: {
      __typename?: 'NFTMerkleTreeHexProof'
      hexProof?: Array<string> | null
    } | null
  }
}

export type UniswapV3BacktestQueryVariables = Exact<{
  poolID?: InputMaybe<Scalars['String']>
  investmentAmount?: InputMaybe<Scalars['Float']>
  days?: InputMaybe<Scalars['Int']>
  compound?: InputMaybe<Scalars['Boolean']>
  protocol?: InputMaybe<EvmChain>
  diff?: InputMaybe<Scalars['Float']>
}>

export type UniswapV3BacktestQuery = {
  __typename?: 'Query'
  uniswapV3Backtest?: {
    __typename?: 'UniswapV3BacktestResult'
    id: string
    days: number
    activeLiquidityPercent: number
    dailyReturn: number
    annualReturn: number
    impermanentLoss: number
    compound: boolean
    dataPointsCount: string
  } | null
}

export type FetchToolsQueryVariables = Exact<{ [key: string]: never }>

export type FetchToolsQuery = {
  __typename?: 'Query'
  tools: Array<
    | {
        __typename?: 'StrategyInfo'
        id: string
        sid: string
        currency: string
        description: string
        earningCurrency: string
        exchanges: Array<Exchange>
        expectedAPY: string
        name: string
        suggestionPeriod: string
        target: string
        type: StrategyType
        riskLevel: RiskLevel
        numOfApply?: number | null
        maximumLoss: string
        stage: StrategyInfoStage
        parameters: Array<{
          __typename?: 'StrategyParameters'
          defaultValue?: string | null
          description: string
          key: string
          maxValue?: string | null
          minValue?: string | null
          name: string
          type: StrategyParameterType
          updatable: boolean
        }>
      }
    | {
        __typename?: 'UniswapToolInfo'
        id: string
        description: string
        chain: EvmChain
        createdAt: string
        name: string
        numOfApply?: number | null
        expectedAPY: string
        isOfficial: boolean
        riskLevel: RiskLevel
        tickLowerDiff: string
        tickLowerDiffForContract: string
        tickUpperDiff: string
        tickUpperDiffForContract?: string | null
        performanceFee: string
        token0Address: string
        token1Address: string
        creatorId: string
        creatorAddress: string
        maximumLoss: string
        poolAddress: string
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
        } | null
        tags?: Array<{
          __typename?: 'UniswapToolTag'
          id: string
          type: UniswapToolTagType
          name: string
        }> | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForContract: string
          feeForDisplay: string
        }
        poolInfo?: {
          __typename?: 'UniswapPoolInfo'
          id: string
          untrackedVolumeUSD: string
          txCount: string
          totalValueLockedUSDUntracked: string
          totalValueLockedUSD: string
          totalValueLockedToken1: string
          totalValueLockedToken0: string
          totalValueLockedETH: string
          observationIndex: string
          feesUSD: string
          createdAtBlockNumber: string
          createdAtTimestamp: string
          liquidityProviderCount: string
          liquidity: string
          sqrtPrice: string
          feeGrowthGlobal0X128: string
          feeGrowthGlobal1X128: string
          tick?: string | null
          volumeUSD: string
          token0Price: string
          token1Price: string
          averageTradingVolumeUSD1D?: string | null
          averageTradingVolumeUSD7D?: string | null
          token0?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
          token1?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
        } | null
      }
  >
}

export type FetchMeToolsQueryVariables = Exact<{ [key: string]: never }>

export type FetchMeToolsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserInfo'
    id: string
    tools?: Array<
      | {
          __typename?: 'UserStrategyInfo'
          id: string
          sid: string
          createdAt: string
          closedAt?: string | null
          updatedAt: string
          ceFiStatus: StrategyStatus
          errMsg?: string | null
          infos?: {
            __typename?: 'StrategyInfos'
            name: string
            currency: string
            earningCurrency: string
            exchange?: Exchange | null
            minEnableAmount: string
          } | null
          parameters?: Array<{
            __typename?: 'UserStrategyParameters'
            key: string
            value: string
          }> | null
          performance?: {
            __typename?: 'UserStrategyPerformance'
            initialValue?: string | null
            duration?: number | null
            performances?: Array<{
              __typename?: 'StrategyPerformance'
              currency: string
              amount: string
              ror: string
              apy: string
            } | null> | null
          } | null
          profits?: {
            __typename?: 'UserStrategyProfit'
            sid?: string | null
            type?: StrategyType | null
            totalProfit?: Array<{
              __typename?: 'TotalProfits'
              currency?: string | null
              amount?: string | null
            } | null> | null
          } | null
        }
      | {
          __typename?: 'UserUniswapPositionInfo'
          id: string
          userAddress: string
          chain: EvmChain
          positionIdFromSmartContractPositionManager?: number | null
          toolId: string
          toolName: string
          toolDescription: string
          toolIsOfficial: boolean
          toolPerformanceFeeRatio?: string | null
          token0Address: string
          token1Address: string
          poolAddress: string
          tickLowerDiff: string
          tickUpperDiff: string
          createdAt: string
          closedAt?: string | null
          updatedAt: string
          apy: string
          roi: string
          totalInvestAmountUsdValue: string
          status: UniswapToolPositionStatus
          token0DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
          } | null
          token1DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
          } | null
          publicToolInfo?: {
            __typename?: 'UniswapToolInfo'
            id: string
            description: string
            chain: EvmChain
            createdAt: string
            name: string
            numOfApply?: number | null
            expectedAPY: string
            isOfficial: boolean
            riskLevel: RiskLevel
            tickLowerDiff: string
            tickLowerDiffForContract: string
            tickUpperDiff: string
            tickUpperDiffForContract?: string | null
            performanceFee: string
            token0Address: string
            token1Address: string
            creatorId: string
            creatorAddress: string
            maximumLoss: string
            poolAddress: string
            token0DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
            } | null
            token1DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
            } | null
            tags?: Array<{
              __typename?: 'UniswapToolTag'
              id: string
              type: UniswapToolTagType
              name: string
            }> | null
            feeTier: {
              __typename?: 'UniswapToolFeeTier'
              id: string
              feeForContract: string
              feeForDisplay: string
            }
            poolInfo?: {
              __typename?: 'UniswapPoolInfo'
              id: string
              untrackedVolumeUSD: string
              txCount: string
              totalValueLockedUSDUntracked: string
              totalValueLockedUSD: string
              totalValueLockedToken1: string
              totalValueLockedToken0: string
              totalValueLockedETH: string
              observationIndex: string
              feesUSD: string
              createdAtBlockNumber: string
              createdAtTimestamp: string
              liquidityProviderCount: string
              liquidity: string
              sqrtPrice: string
              feeGrowthGlobal0X128: string
              feeGrowthGlobal1X128: string
              tick?: string | null
              volumeUSD: string
              token0Price: string
              token1Price: string
              averageTradingVolumeUSD1D?: string | null
              averageTradingVolumeUSD7D?: string | null
              token0?: {
                __typename?: 'UniswapToolToken'
                id: string
                symbol: string
                name: string
                volumeUSD: string
                chain: EvmChain
                decimals: string
                volume: string
                untrackedVolumeUSD: string
                feesUSD: string
                txCount: string
                poolCount: string
                totalValueLocked: string
                totalValueLockedUSD: string
                totalValueLockedUSDUntracked: string
                derivedETH: string
                totalSupply: string
              } | null
              token1?: {
                __typename?: 'UniswapToolToken'
                id: string
                symbol: string
                name: string
                volumeUSD: string
                chain: EvmChain
                decimals: string
                volume: string
                untrackedVolumeUSD: string
                feesUSD: string
                txCount: string
                poolCount: string
                totalValueLocked: string
                totalValueLockedUSD: string
                totalValueLockedUSDUntracked: string
                derivedETH: string
                totalSupply: string
              } | null
            } | null
          } | null
          feeTier: {
            __typename?: 'UniswapToolFeeTier'
            id: string
            feeForDisplay: string
            feeForContract: string
          }
          runningPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          closedPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          onchainPositionInfo?: {
            __typename?: 'UserUniswapOnchainPositionInfo'
            apy: string
            roi: string
            totalInvestAmountUsdValue?: string | null
            token0Address: string
            token1Address: string
            positionIdFromSmartContractPositionManager?: number | null
            positionId: string
            runningPositionAmount?: {
              __typename?: 'UniswapOnchainPositionAmountInfo'
              token0Amount: string
              token1Amount: string
              token0AmountUsdValue: string
              token1AmountUsdValue: string
            } | null
            closedPositionAmount?: {
              __typename?: 'UniswapOnchainPositionAmountInfo'
              token0Amount: string
              token1Amount: string
              token0AmountUsdValue: string
              token1AmountUsdValue: string
            } | null
            token0DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
            } | null
            token1DisplayInfo?: {
              __typename?: 'TokenDisplayInfo'
              id: string
              chain: EvmChain
              name: string
              address: string
              imageURL: string
              symbol: string
            } | null
          } | null
          progresses?: Array<{
            __typename?: 'UserUniswapv3PositionProgress'
            id: string
            createdAt: string
            updatedAt: string
            index: number
            status: UserUniswapv3PositionProgressStatus
            type: UserUniswapv3PositionProgressType
            steps: Array<{
              __typename?: 'UserUniswapv3PositionProgressStep'
              status: UserUniswapv3PositionProgressStepStatus
              type: UserUniswapv3PositionProgressStepType
            }>
          }> | null
        }
    > | null
    ownedToolsSetting?: Array<{
      __typename?: 'UserUniswapToolSettingInfo'
      id: string
      chain: EvmChain
      name: string
      description: string
      createdAt?: string | null
      closedAt?: string | null
      updatedAt?: string | null
      poolAddress: string
      token0Address: string
      token1Address: string
      status: UniswapToolStatus
      tickLowerDiff: string
      tickUpperDiff: string
      isOfficial: boolean
      totalUserUsed: number
      performanceFeeRatio?: string | null
      token0DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
      } | null
      token1DisplayInfo?: {
        __typename?: 'TokenDisplayInfo'
        id: string
        chain: EvmChain
        name: string
        address: string
        imageURL: string
        symbol: string
      } | null
      feeTier: {
        __typename?: 'UniswapToolFeeTier'
        id: string
        feeForDisplay: string
        feeForContract: string
      }
      publicToolInfo?: {
        __typename?: 'UniswapToolInfo'
        id: string
        description: string
        chain: EvmChain
        createdAt: string
        name: string
        numOfApply?: number | null
        expectedAPY: string
        isOfficial: boolean
        riskLevel: RiskLevel
        tickLowerDiff: string
        tickLowerDiffForContract: string
        tickUpperDiff: string
        tickUpperDiffForContract?: string | null
        performanceFee: string
        token0Address: string
        token1Address: string
        creatorId: string
        creatorAddress: string
        maximumLoss: string
        poolAddress: string
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
        } | null
        tags?: Array<{
          __typename?: 'UniswapToolTag'
          id: string
          type: UniswapToolTagType
          name: string
        }> | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForContract: string
          feeForDisplay: string
        }
        poolInfo?: {
          __typename?: 'UniswapPoolInfo'
          id: string
          untrackedVolumeUSD: string
          txCount: string
          totalValueLockedUSDUntracked: string
          totalValueLockedUSD: string
          totalValueLockedToken1: string
          totalValueLockedToken0: string
          totalValueLockedETH: string
          observationIndex: string
          feesUSD: string
          createdAtBlockNumber: string
          createdAtTimestamp: string
          liquidityProviderCount: string
          liquidity: string
          sqrtPrice: string
          feeGrowthGlobal0X128: string
          feeGrowthGlobal1X128: string
          tick?: string | null
          volumeUSD: string
          token0Price: string
          token1Price: string
          averageTradingVolumeUSD1D?: string | null
          averageTradingVolumeUSD7D?: string | null
          token0?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
          token1?: {
            __typename?: 'UniswapToolToken'
            id: string
            symbol: string
            name: string
            volumeUSD: string
            chain: EvmChain
            decimals: string
            volume: string
            untrackedVolumeUSD: string
            feesUSD: string
            txCount: string
            poolCount: string
            totalValueLocked: string
            totalValueLockedUSD: string
            totalValueLockedUSDUntracked: string
            derivedETH: string
            totalSupply: string
          } | null
        } | null
      } | null
      position?: {
        __typename?: 'UserUniswapPositionInfo'
        id: string
        userAddress: string
        chain: EvmChain
        positionIdFromSmartContractPositionManager?: number | null
        toolId: string
        toolName: string
        toolDescription: string
        toolIsOfficial: boolean
        toolPerformanceFeeRatio?: string | null
        token0Address: string
        token1Address: string
        poolAddress: string
        tickLowerDiff: string
        tickUpperDiff: string
        createdAt: string
        closedAt?: string | null
        updatedAt: string
        apy: string
        roi: string
        totalInvestAmountUsdValue: string
        status: UniswapToolPositionStatus
        token0DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
        } | null
        token1DisplayInfo?: {
          __typename?: 'TokenDisplayInfo'
          id: string
          chain: EvmChain
          name: string
          address: string
          imageURL: string
          symbol: string
        } | null
        publicToolInfo?: {
          __typename?: 'UniswapToolInfo'
          id: string
          description: string
          chain: EvmChain
          createdAt: string
          name: string
          numOfApply?: number | null
          expectedAPY: string
          isOfficial: boolean
          riskLevel: RiskLevel
          tickLowerDiff: string
          tickLowerDiffForContract: string
          tickUpperDiff: string
          tickUpperDiffForContract?: string | null
          performanceFee: string
          token0Address: string
          token1Address: string
          creatorId: string
          creatorAddress: string
          maximumLoss: string
          poolAddress: string
          token0DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
          } | null
          token1DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
          } | null
          tags?: Array<{
            __typename?: 'UniswapToolTag'
            id: string
            type: UniswapToolTagType
            name: string
          }> | null
          feeTier: {
            __typename?: 'UniswapToolFeeTier'
            id: string
            feeForContract: string
            feeForDisplay: string
          }
          poolInfo?: {
            __typename?: 'UniswapPoolInfo'
            id: string
            untrackedVolumeUSD: string
            txCount: string
            totalValueLockedUSDUntracked: string
            totalValueLockedUSD: string
            totalValueLockedToken1: string
            totalValueLockedToken0: string
            totalValueLockedETH: string
            observationIndex: string
            feesUSD: string
            createdAtBlockNumber: string
            createdAtTimestamp: string
            liquidityProviderCount: string
            liquidity: string
            sqrtPrice: string
            feeGrowthGlobal0X128: string
            feeGrowthGlobal1X128: string
            tick?: string | null
            volumeUSD: string
            token0Price: string
            token1Price: string
            averageTradingVolumeUSD1D?: string | null
            averageTradingVolumeUSD7D?: string | null
            token0?: {
              __typename?: 'UniswapToolToken'
              id: string
              symbol: string
              name: string
              volumeUSD: string
              chain: EvmChain
              decimals: string
              volume: string
              untrackedVolumeUSD: string
              feesUSD: string
              txCount: string
              poolCount: string
              totalValueLocked: string
              totalValueLockedUSD: string
              totalValueLockedUSDUntracked: string
              derivedETH: string
              totalSupply: string
            } | null
            token1?: {
              __typename?: 'UniswapToolToken'
              id: string
              symbol: string
              name: string
              volumeUSD: string
              chain: EvmChain
              decimals: string
              volume: string
              untrackedVolumeUSD: string
              feesUSD: string
              txCount: string
              poolCount: string
              totalValueLocked: string
              totalValueLockedUSD: string
              totalValueLockedUSDUntracked: string
              derivedETH: string
              totalSupply: string
            } | null
          } | null
        } | null
        feeTier: {
          __typename?: 'UniswapToolFeeTier'
          id: string
          feeForDisplay: string
          feeForContract: string
        }
        runningPositionAmount?: {
          __typename?: 'UniswapOnchainPositionAmountInfo'
          token0Amount: string
          token1Amount: string
          token0AmountUsdValue: string
          token1AmountUsdValue: string
        } | null
        closedPositionAmount?: {
          __typename?: 'UniswapOnchainPositionAmountInfo'
          token0Amount: string
          token1Amount: string
          token0AmountUsdValue: string
          token1AmountUsdValue: string
        } | null
        onchainPositionInfo?: {
          __typename?: 'UserUniswapOnchainPositionInfo'
          apy: string
          roi: string
          totalInvestAmountUsdValue?: string | null
          token0Address: string
          token1Address: string
          positionIdFromSmartContractPositionManager?: number | null
          positionId: string
          runningPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          closedPositionAmount?: {
            __typename?: 'UniswapOnchainPositionAmountInfo'
            token0Amount: string
            token1Amount: string
            token0AmountUsdValue: string
            token1AmountUsdValue: string
          } | null
          token0DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
          } | null
          token1DisplayInfo?: {
            __typename?: 'TokenDisplayInfo'
            id: string
            chain: EvmChain
            name: string
            address: string
            imageURL: string
            symbol: string
          } | null
        } | null
        progresses?: Array<{
          __typename?: 'UserUniswapv3PositionProgress'
          id: string
          createdAt: string
          updatedAt: string
          index: number
          status: UserUniswapv3PositionProgressStatus
          type: UserUniswapv3PositionProgressType
          steps: Array<{
            __typename?: 'UserUniswapv3PositionProgressStep'
            status: UserUniswapv3PositionProgressStepStatus
            type: UserUniswapv3PositionProgressStepType
          }>
        }> | null
      } | null
      outOfLowerRange: {
        __typename?: 'UserUniswapToolOutOfRangeInfo'
        maxRebalanceTimes: number
        standbyInterval: number
        standbyMaxInterval: number
        earnSpendRate: string
      }
      outOfUpperRange: {
        __typename?: 'UserUniswapToolOutOfRangeInfo'
        maxRebalanceTimes: number
        standbyInterval: number
        standbyMaxInterval: number
        earnSpendRate: string
      }
    }> | null
  }
}

export type FetchPoolsWithArgumentsQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  orderDirection?: InputMaybe<Scalars['String']>
}>

export type FetchPoolsWithArgumentsQuery = {
  __typename?: 'Query'
  genericPoolsEthereum?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  genericPoolsPolygon?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  stablesPoolsEthereum?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  stablesPoolsPolygon?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  poolsEthereum?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
  poolsPolygon?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
}

export type FetchPoolsQueryVariables = Exact<{
  token0s?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  token1s?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  chain: EvmChain
}>

export type FetchPoolsQuery = {
  __typename?: 'Query'
  poolsWithArguments?: Array<{
    __typename?: 'UniswapPoolInfo'
    isPoolActiveForReyield?: boolean | null
    id: string
    createdAtTimestamp: string
    createdAtBlockNumber: string
    feeTier: string
    liquidity: string
    sqrtPrice: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    token0Price: string
    token1Price: string
    tick?: string | null
    observationIndex: string
    volumeToken0: string
    volumeToken1: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    collectedFeesToken0: string
    collectedFeesToken1: string
    collectedFeesUSD: string
    totalValueLockedToken0: string
    totalValueLockedToken1: string
    totalValueLockedETH: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    liquidityProviderCount: string
    chain: EvmChain
    averageTradingVolumeUSD1D?: string | null
    averageTradingVolumeUSD30D?: string | null
    averageTradingVolumeUSD365D?: string | null
    token0?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
    token1?: {
      __typename?: 'UniswapToolToken'
      id: string
      symbol: string
      name: string
      decimals: string
      totalSupply: string
      volume: string
      volumeUSD: string
      untrackedVolumeUSD: string
      feesUSD: string
      txCount: string
      poolCount: string
      totalValueLocked: string
      totalValueLockedUSD: string
      totalValueLockedUSDUntracked: string
      derivedETH: string
      priceChart?: {
        __typename?: 'UniswapToolTokenPriceChart'
        id?: string | null
        currentPriceUSD?: string | null
        prices?: Array<{
          __typename?: 'UniswapToolTokenPriceChartPrice'
          timestamp: string
          value: string
        }> | null
      } | null
    } | null
  }> | null
}

export type FetchPoolsTicksByPoolIdsQueryVariables = Exact<{
  poolIds: Array<Scalars['String']> | Scalars['String']
  chain: EvmChain
}>

export type FetchPoolsTicksByPoolIdsQuery = {
  __typename?: 'Query'
  poolsWithArguments?: Array<{
    __typename?: 'UniswapPoolInfo'
    id: string
    ticks?: Array<{
      __typename?: 'UniswapPoolTick'
      id: string
      tickIdx: string
      liquidityNet: string
      price0: string
      price1: string
    }> | null
  }> | null
}

export type FetchSupportingTokensQueryVariables = Exact<{
  chain: EvmChain
}>

export type FetchSupportingTokensQuery = {
  __typename?: 'Query'
  uniswapTokens?: Array<{
    __typename?: 'UniswapToolToken'
    id: string
    symbol: string
    name: string
    decimals: string
    totalSupply: string
    volume: string
    volumeUSD: string
    untrackedVolumeUSD: string
    feesUSD: string
    txCount: string
    poolCount: string
    totalValueLocked: string
    totalValueLockedUSD: string
    totalValueLockedUSDUntracked: string
    derivedETH: string
  }> | null
}

export type FetchMetricQueryVariables = Exact<{ [key: string]: never }>

export type FetchMetricQuery = {
  __typename?: 'Query'
  metric?: Array<{
    __typename?: 'Metric'
    id: string
    createdAt: string
    positions?: Array<{
      __typename?: 'MetricPosition'
      id: string
      positionManagerContractAddress: string
      positionOwnerAddress: string
      positionId: number
      positionIdTotalCount: number
      positionIsRunning: boolean
      amountUsd: string
      sumUsd: string
      feeUsd: string
      strategyId: string
      strategyProvider: string
      chain: EvmChain
      createdAt: string
    }> | null
    positionManagersInfo?: Array<{
      __typename?: 'PositionManagerInfo'
      id: string
      chain: EvmChain
      count: number
    }> | null
  }> | null
}

export const TokenDisplayInfoFragmentDoc = gql`
  fragment TokenDisplayInfo on TokenDisplayInfo {
    id
    chain
    name
    address
    imageURL
    symbol
  }
`
export const UniswapToolFeeTierFragmentDoc = gql`
  fragment UniswapToolFeeTier on UniswapToolFeeTier {
    id
    feeForDisplay
    feeForContract
  }
`
export const ExploreToolCardTokenFragmentDoc = gql`
  fragment ExploreToolCardToken on UniswapToolToken {
    id
    symbol
    name
    volumeUSD
    chain
    decimals
    volume
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    poolCount
    totalValueLocked
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    derivedETH
    totalSupply
  }
`
export const UniswapToolInfoFragmentDoc = gql`
  fragment UniswapToolInfo on UniswapToolInfo {
    id
    description
    chain
    createdAt
    name
    numOfApply
    expectedAPY
    isOfficial
    riskLevel
    tickLowerDiff
    tickLowerDiffForContract
    tickUpperDiff
    tickUpperDiffForContract
    performanceFee
    token0Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1Address
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    creatorId
    creatorAddress
    maximumLoss
    poolAddress
    tags {
      id
      type
      name
    }
    feeTier {
      id
      feeForContract
      feeForDisplay
    }
    poolInfo {
      id
      averageTradingVolumeUSD1D: averageTradingVolumeUSD(numberOfDays: 1)
      averageTradingVolumeUSD7D: averageTradingVolumeUSD(numberOfDays: 7)
      untrackedVolumeUSD
      txCount
      totalValueLockedUSDUntracked
      totalValueLockedUSD
      totalValueLockedToken1
      totalValueLockedToken0
      totalValueLockedETH
      observationIndex
      feesUSD
      createdAtBlockNumber
      createdAtTimestamp
      liquidityProviderCount
      liquidity
      sqrtPrice
      feeGrowthGlobal0X128
      feeGrowthGlobal1X128
      tick
      volumeUSD
      token0Price
      token1Price
      token0 {
        ...ExploreToolCardToken
      }
      token1 {
        ...ExploreToolCardToken
      }
    }
  }
  ${TokenDisplayInfoFragmentDoc}
  ${ExploreToolCardTokenFragmentDoc}
`
export const UniswapOnchainPositionAmountInfoFragmentDoc = gql`
  fragment UniswapOnchainPositionAmountInfo on UniswapOnchainPositionAmountInfo {
    token0Amount
    token1Amount
    token0AmountUsdValue
    token1AmountUsdValue
  }
`
export const UserUniswapOnchainPositionInfoFragmentDoc = gql`
  fragment UserUniswapOnchainPositionInfo on UserUniswapOnchainPositionInfo {
    apy
    roi
    totalInvestAmountUsdValue
    runningPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    closedPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    token0Address
    token1Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    positionIdFromSmartContractPositionManager
    positionId
  }
  ${UniswapOnchainPositionAmountInfoFragmentDoc}
  ${TokenDisplayInfoFragmentDoc}
`
export const UserUniswapv3PositionProgressFragmentDoc = gql`
  fragment UserUniswapv3PositionProgress on UserUniswapv3PositionProgress {
    id
    createdAt
    updatedAt
    index
    status
    steps {
      status
      type
    }
    type
  }
`
export const UserUniswapPositionInfoFragmentDoc = gql`
  fragment UserUniswapPositionInfo on UserUniswapPositionInfo {
    id
    userAddress
    chain
    positionIdFromSmartContractPositionManager
    toolId
    toolName
    toolDescription
    toolIsOfficial
    toolPerformanceFeeRatio
    token0Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1Address
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    poolAddress
    publicToolInfo {
      ...UniswapToolInfo
    }
    feeTier {
      ...UniswapToolFeeTier
    }
    tickLowerDiff
    tickUpperDiff
    createdAt
    closedAt
    updatedAt
    apy
    roi
    totalInvestAmountUsdValue
    runningPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    closedPositionAmount {
      ...UniswapOnchainPositionAmountInfo
    }
    onchainPositionInfo {
      ...UserUniswapOnchainPositionInfo
    }
    status
    progresses {
      ...UserUniswapv3PositionProgress
    }
  }
  ${TokenDisplayInfoFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
  ${UniswapToolFeeTierFragmentDoc}
  ${UniswapOnchainPositionAmountInfoFragmentDoc}
  ${UserUniswapOnchainPositionInfoFragmentDoc}
  ${UserUniswapv3PositionProgressFragmentDoc}
`
export const UserUniswapToolOutOfRangeInfoFragmentDoc = gql`
  fragment UserUniswapToolOutOfRangeInfo on UserUniswapToolOutOfRangeInfo {
    maxRebalanceTimes
    standbyInterval
    standbyMaxInterval
    earnSpendRate
  }
`
export const UserUniswapToolSettingInfoFragmentDoc = gql`
  fragment UserUniswapToolSettingInfo on UserUniswapToolSettingInfo {
    id
    chain
    name
    description
    createdAt
    closedAt
    updatedAt
    poolAddress
    token0Address
    token0DisplayInfo {
      ...TokenDisplayInfo
    }
    token1Address
    token1DisplayInfo {
      ...TokenDisplayInfo
    }
    feeTier {
      ...UniswapToolFeeTier
    }
    status
    tickLowerDiff
    tickUpperDiff
    isOfficial
    totalUserUsed
    performanceFeeRatio
    publicToolInfo {
      ...UniswapToolInfo
    }
    position {
      ...UserUniswapPositionInfo
    }
    outOfLowerRange {
      ...UserUniswapToolOutOfRangeInfo
    }
    outOfUpperRange {
      ...UserUniswapToolOutOfRangeInfo
    }
  }
  ${TokenDisplayInfoFragmentDoc}
  ${UniswapToolFeeTierFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
  ${UserUniswapPositionInfoFragmentDoc}
  ${UserUniswapToolOutOfRangeInfoFragmentDoc}
`
export const UniswapPoolTokenFragmentDoc = gql`
  fragment UniswapPoolToken on UniswapToolToken {
    id
    symbol
    name
    decimals
    totalSupply
    volume
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    poolCount
    totalValueLocked
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    derivedETH
  }
`
export const UniswapToolTokenPriceChartFragmentDoc = gql`
  fragment UniswapToolTokenPriceChart on UniswapToolTokenPriceChart {
    id
    currentPriceUSD
    prices {
      timestamp
      value
    }
  }
`
export const PoolFragmentDoc = gql`
  fragment Pool on UniswapPoolInfo {
    id
    createdAtTimestamp
    createdAtBlockNumber
    token0 {
      ...UniswapPoolToken
      priceChart {
        ...UniswapToolTokenPriceChart
      }
    }
    token1 {
      ...UniswapPoolToken
      priceChart {
        ...UniswapToolTokenPriceChart
      }
    }
    feeTier
    liquidity
    sqrtPrice
    feeGrowthGlobal0X128
    feeGrowthGlobal1X128
    token0Price
    token1Price
    tick
    observationIndex
    volumeToken0
    volumeToken1
    volumeUSD
    untrackedVolumeUSD
    feesUSD
    txCount
    collectedFeesToken0
    collectedFeesToken1
    collectedFeesUSD
    totalValueLockedToken0
    totalValueLockedToken1
    totalValueLockedETH
    totalValueLockedUSD
    totalValueLockedUSDUntracked
    liquidityProviderCount
    chain
    averageTradingVolumeUSD1D: averageTradingVolumeUSD(numberOfDays: 1)
    averageTradingVolumeUSD30D: averageTradingVolumeUSD(numberOfDays: 30)
    averageTradingVolumeUSD365D: averageTradingVolumeUSD(numberOfDays: 365)
  }
  ${UniswapPoolTokenFragmentDoc}
  ${UniswapToolTokenPriceChartFragmentDoc}
`
export const PoolWithReyieldPoolValidationFragmentDoc = gql`
  fragment PoolWithReyieldPoolValidation on UniswapPoolInfo {
    ...Pool
    isPoolActiveForReyield
  }
  ${PoolFragmentDoc}
`
export const CurrencyInfoFragmentDoc = gql`
  fragment CurrencyInfo on CurrencyInfo {
    name
    symbol
    decimals
  }
`
export const DefiWalletFragmentDoc = gql`
  fragment DefiWallet on DefiWallet {
    id
    chain
    balance
    balanceComputed
    balanceComputedWithUnit
    currencyInfo {
      ...CurrencyInfo
    }
    iconifySrc @client
  }
  ${CurrencyInfoFragmentDoc}
`
export const ExternalApiKeyFragmentDoc = gql`
  fragment ExternalAPIKey on ExternalAPIKey {
    id
    exchange
    key
    name
    isRevoked
  }
`
export const PublicStrategyParameterFragmentDoc = gql`
  fragment PublicStrategyParameter on StrategyParameters {
    defaultValue
    description
    key
    maxValue
    minValue
    name
    type
    updatable
  }
`
export const PublicStrategyInfoFragmentDoc = gql`
  fragment PublicStrategyInfo on StrategyInfo {
    id
    sid
    currency
    description
    earningCurrency
    exchanges
    expectedAPY
    name
    parameters {
      ...PublicStrategyParameter
    }
    suggestionPeriod
    target
    type
    riskLevel
    numOfApply
    maximumLoss
    stage
  }
  ${PublicStrategyParameterFragmentDoc}
`
export const UserStrategyParametersFragmentDoc = gql`
  fragment UserStrategyParameters on UserStrategyParameters {
    key
    value
  }
`
export const StrategyPerformanceFragmentDoc = gql`
  fragment StrategyPerformance on StrategyPerformance {
    currency
    amount
    ror
    apy
  }
`
export const TotalProfitsFragmentDoc = gql`
  fragment TotalProfits on TotalProfits {
    currency
    amount
  }
`
export const UserStrategyProfitFragmentDoc = gql`
  fragment UserStrategyProfit on UserStrategyProfit {
    sid
    type
    totalProfit {
      ...TotalProfits
    }
  }
  ${TotalProfitsFragmentDoc}
`
export const UserStrategiesFragmentDoc = gql`
  fragment UserStrategies on UserStrategyInfo {
    id
    sid
    createdAt
    closedAt
    updatedAt
    infos {
      name
      currency
      earningCurrency
      exchange
      minEnableAmount
    }
    parameters {
      ...UserStrategyParameters
    }
    performance {
      initialValue
      duration
      performances {
        ...StrategyPerformance
      }
    }
    ceFiStatus
    errMsg
    profits {
      ...UserStrategyProfit
    }
  }
  ${UserStrategyParametersFragmentDoc}
  ${StrategyPerformanceFragmentDoc}
  ${UserStrategyProfitFragmentDoc}
`
export const UserStrategyInfoFragmentDoc = gql`
  fragment UserStrategyInfo on UserStrategyInfo {
    id
    sid
    createdAt
    closedAt
    updatedAt
    createdAt
    closedAt
    updatedAt
    sid
    updatedAt
    infos {
      name
      currency
      earningCurrency
      exchange
      minEnableAmount
    }
    parameters {
      ...UserStrategyParameters
    }
    performance {
      initialValue
      duration
      performances {
        ...StrategyPerformance
      }
    }
    ceFiStatus
    errMsg
    profits {
      ...UserStrategyProfit
    }
  }
  ${UserStrategyParametersFragmentDoc}
  ${StrategyPerformanceFragmentDoc}
  ${UserStrategyProfitFragmentDoc}
`
export const CurrencyBalancesFragmentDoc = gql`
  fragment CurrencyBalances on CurrencyBalances {
    currency
    balance {
      available
      total
    }
  }
`
export const WalletBalancesFragmentDoc = gql`
  fragment WalletBalances on WalletBalances {
    currencyBalances {
      ...CurrencyBalances
    }
    wallet
  }
  ${CurrencyBalancesFragmentDoc}
`
export const CefiWalletFragmentDoc = gql`
  fragment CefiWallet on CefiWallet {
    exchange
    walletBalances {
      ...WalletBalances
    }
  }
  ${WalletBalancesFragmentDoc}
`
export const StrategyProgressStepFragmentDoc = gql`
  fragment StrategyProgressStep on StrategyProgressStep {
    status
    type
  }
`
export const StrategyProgressFragmentDoc = gql`
  fragment StrategyProgress on StrategyProgress {
    id
    createdAt
    updatedAt
    index
    status
    steps {
      ...StrategyProgressStep
    }
    type
  }
  ${StrategyProgressStepFragmentDoc}
`
export const CoinPriceFragmentDoc = gql`
  fragment CoinPrice on CoinPrice {
    symbol
    usdtPrice
  }
`
export const RateHistoricalFragmentDoc = gql`
  fragment RateHistorical on RateHistorical {
    time
    rate
  }
`
export const UserStrategyRecordFragmentDoc = gql`
  fragment UserStrategyRecord on UserStrategyRecord {
    id
    amount
    currency
    rate
    time
    type
  }
`
export const AiCompletionRecordFragmentDoc = gql`
  fragment AICompletionRecord on aICompletionRecords {
    id
    promptMutationName
    promptMutationInput
    platform
    meta
    createdAt
    content
    completedAt
    updatedAt
    userAddress
    userId
  }
`
export const SuggestStrategyToolWithAllocationFragmentDoc = gql`
  fragment SuggestStrategyToolWithAllocation on suggestStrategyToolWithAllocations {
    id
    maximumLoss
    name
    sid
    updatedAt
    highestAnnualReturn
    description
    createdAt
    allocation
    systemInvestAmountSuggestion
    systemInvestCurrencySuggestion
  }
`
export const SingleCompletionRecordForDialogFragmentDoc = gql`
  fragment SingleCompletionRecordForDialog on aICompletionRecords {
    id
    promptMutationName
    promptMutationInput
    platform
    meta
    createdAt
    content
    completedAt
    updatedAt
    userAddress
    userId
    suggestions {
      ...SuggestStrategyToolWithAllocation
    }
  }
  ${SuggestStrategyToolWithAllocationFragmentDoc}
`
export const NftCollectionFragmentDoc = gql`
  fragment NFTCollection on NFTCollections {
    name
    description
    tokenId
    contract
    imageUrl
    attributes {
      traitType
      value
    }
  }
`
export const PositionFragmentDoc = gql`
  fragment Position on UniswapPoolPosition {
    id
    tickLowerTickIdx
    tickLowerFeeGrowthOutside0X128
    tickLowerFeeGrowthOutside1X128
    tickUpperTickIdx
    tickUpperFeeGrowthOutside0X128
    tickUpperFeeGrowthOutside1X128
    depositedToken0
    depositedToken1
    liquidity
    transactionTimestamp
    collectedFeesToken0
    collectedFeesToken1
    feeGrowthInside0LastX128
    feeGrowthInside1LastX128
  }
`
export const UserExternalApiKeyCreateDocument = gql`
  mutation userExternalAPIKeyCreate($input: ExternalAPIKeyCreateInput!) {
    externalAPIKeyCreate(input: $input) {
      ...ExternalAPIKey
      user {
        id
        externalAPIKeys(filter: { isRevoked: false }) {
          ...ExternalAPIKey
        }
      }
    }
  }
  ${ExternalApiKeyFragmentDoc}
`
export type UserExternalApiKeyCreateMutationFn = Apollo.MutationFunction<
  UserExternalApiKeyCreateMutation,
  UserExternalApiKeyCreateMutationVariables
>

/**
 * __useUserExternalApiKeyCreateMutation__
 *
 * To run a mutation, you first call `useUserExternalApiKeyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserExternalApiKeyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userExternalApiKeyCreateMutation, { data, loading, error }] = useUserExternalApiKeyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserExternalApiKeyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserExternalApiKeyCreateMutation,
    UserExternalApiKeyCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserExternalApiKeyCreateMutation,
    UserExternalApiKeyCreateMutationVariables
  >(UserExternalApiKeyCreateDocument, options)
}
export type UserExternalApiKeyCreateMutationHookResult = ReturnType<
  typeof useUserExternalApiKeyCreateMutation
>
export type UserExternalApiKeyCreateMutationResult =
  Apollo.MutationResult<UserExternalApiKeyCreateMutation>
export type UserExternalApiKeyCreateMutationOptions = Apollo.BaseMutationOptions<
  UserExternalApiKeyCreateMutation,
  UserExternalApiKeyCreateMutationVariables
>
export const UserExternalApiKeyDeleteDocument = gql`
  mutation userExternalAPIKeyDelete($input: ExternalAPIKeyDeleteInput!) {
    externalAPIKeyDelete(input: $input) {
      ...ExternalAPIKey
      user {
        id
        externalAPIKeys(filter: { isRevoked: false }) {
          ...ExternalAPIKey
        }
      }
    }
  }
  ${ExternalApiKeyFragmentDoc}
`
export type UserExternalApiKeyDeleteMutationFn = Apollo.MutationFunction<
  UserExternalApiKeyDeleteMutation,
  UserExternalApiKeyDeleteMutationVariables
>

/**
 * __useUserExternalApiKeyDeleteMutation__
 *
 * To run a mutation, you first call `useUserExternalApiKeyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserExternalApiKeyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userExternalApiKeyDeleteMutation, { data, loading, error }] = useUserExternalApiKeyDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserExternalApiKeyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserExternalApiKeyDeleteMutation,
    UserExternalApiKeyDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserExternalApiKeyDeleteMutation,
    UserExternalApiKeyDeleteMutationVariables
  >(UserExternalApiKeyDeleteDocument, options)
}
export type UserExternalApiKeyDeleteMutationHookResult = ReturnType<
  typeof useUserExternalApiKeyDeleteMutation
>
export type UserExternalApiKeyDeleteMutationResult =
  Apollo.MutationResult<UserExternalApiKeyDeleteMutation>
export type UserExternalApiKeyDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserExternalApiKeyDeleteMutation,
  UserExternalApiKeyDeleteMutationVariables
>
export const UserStrategyStartDocument = gql`
  mutation userStrategyStart($input: StrategyStartInput!) {
    strategyStart(input: $input) {
      strategy {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`
export type UserStrategyStartMutationFn = Apollo.MutationFunction<
  UserStrategyStartMutation,
  UserStrategyStartMutationVariables
>

/**
 * __useUserStrategyStartMutation__
 *
 * To run a mutation, you first call `useUserStrategyStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStrategyStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStrategyStartMutation, { data, loading, error }] = useUserStrategyStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStrategyStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStrategyStartMutation,
    UserStrategyStartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserStrategyStartMutation, UserStrategyStartMutationVariables>(
    UserStrategyStartDocument,
    options
  )
}
export type UserStrategyStartMutationHookResult = ReturnType<typeof useUserStrategyStartMutation>
export type UserStrategyStartMutationResult = Apollo.MutationResult<UserStrategyStartMutation>
export type UserStrategyStartMutationOptions = Apollo.BaseMutationOptions<
  UserStrategyStartMutation,
  UserStrategyStartMutationVariables
>
export const UserStrategyStopDocument = gql`
  mutation userStrategyStop($input: StrategyStopInput!) {
    strategyStop(input: $input) {
      strategy {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`
export type UserStrategyStopMutationFn = Apollo.MutationFunction<
  UserStrategyStopMutation,
  UserStrategyStopMutationVariables
>

/**
 * __useUserStrategyStopMutation__
 *
 * To run a mutation, you first call `useUserStrategyStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStrategyStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStrategyStopMutation, { data, loading, error }] = useUserStrategyStopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStrategyStopMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStrategyStopMutation,
    UserStrategyStopMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserStrategyStopMutation, UserStrategyStopMutationVariables>(
    UserStrategyStopDocument,
    options
  )
}
export type UserStrategyStopMutationHookResult = ReturnType<typeof useUserStrategyStopMutation>
export type UserStrategyStopMutationResult = Apollo.MutationResult<UserStrategyStopMutation>
export type UserStrategyStopMutationOptions = Apollo.BaseMutationOptions<
  UserStrategyStopMutation,
  UserStrategyStopMutationVariables
>
export const UserStrategyUpdateDocument = gql`
  mutation userStrategyUpdate($input: StrategyUpdateInput!) {
    strategyUpdate(input: $input) {
      strategy {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`
export type UserStrategyUpdateMutationFn = Apollo.MutationFunction<
  UserStrategyUpdateMutation,
  UserStrategyUpdateMutationVariables
>

/**
 * __useUserStrategyUpdateMutation__
 *
 * To run a mutation, you first call `useUserStrategyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStrategyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStrategyUpdateMutation, { data, loading, error }] = useUserStrategyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStrategyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStrategyUpdateMutation,
    UserStrategyUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserStrategyUpdateMutation, UserStrategyUpdateMutationVariables>(
    UserStrategyUpdateDocument,
    options
  )
}
export type UserStrategyUpdateMutationHookResult = ReturnType<typeof useUserStrategyUpdateMutation>
export type UserStrategyUpdateMutationResult = Apollo.MutationResult<UserStrategyUpdateMutation>
export type UserStrategyUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserStrategyUpdateMutation,
  UserStrategyUpdateMutationVariables
>
export const CreateAiCompletionRecordDocument = gql`
  mutation createAICompletionRecord($input: CreateAICompletionRecordInput!) {
    createAICompletionRecord(input: $input) {
      ...AICompletionRecord
    }
  }
  ${AiCompletionRecordFragmentDoc}
`
export type CreateAiCompletionRecordMutationFn = Apollo.MutationFunction<
  CreateAiCompletionRecordMutation,
  CreateAiCompletionRecordMutationVariables
>

/**
 * __useCreateAiCompletionRecordMutation__
 *
 * To run a mutation, you first call `useCreateAiCompletionRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiCompletionRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiCompletionRecordMutation, { data, loading, error }] = useCreateAiCompletionRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiCompletionRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAiCompletionRecordMutation,
    CreateAiCompletionRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateAiCompletionRecordMutation,
    CreateAiCompletionRecordMutationVariables
  >(CreateAiCompletionRecordDocument, options)
}
export type CreateAiCompletionRecordMutationHookResult = ReturnType<
  typeof useCreateAiCompletionRecordMutation
>
export type CreateAiCompletionRecordMutationResult =
  Apollo.MutationResult<CreateAiCompletionRecordMutation>
export type CreateAiCompletionRecordMutationOptions = Apollo.BaseMutationOptions<
  CreateAiCompletionRecordMutation,
  CreateAiCompletionRecordMutationVariables
>
export const CreateUniswapv3ToolDocument = gql`
  mutation createUniswapv3Tool($input: CreateUniswapv3ToolInput!) {
    createUniswapv3Tool(input: $input)
  }
`
export type CreateUniswapv3ToolMutationFn = Apollo.MutationFunction<
  CreateUniswapv3ToolMutation,
  CreateUniswapv3ToolMutationVariables
>

/**
 * __useCreateUniswapv3ToolMutation__
 *
 * To run a mutation, you first call `useCreateUniswapv3ToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniswapv3ToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniswapv3ToolMutation, { data, loading, error }] = useCreateUniswapv3ToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUniswapv3ToolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUniswapv3ToolMutation,
    CreateUniswapv3ToolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUniswapv3ToolMutation, CreateUniswapv3ToolMutationVariables>(
    CreateUniswapv3ToolDocument,
    options
  )
}
export type CreateUniswapv3ToolMutationHookResult = ReturnType<
  typeof useCreateUniswapv3ToolMutation
>
export type CreateUniswapv3ToolMutationResult = Apollo.MutationResult<CreateUniswapv3ToolMutation>
export type CreateUniswapv3ToolMutationOptions = Apollo.BaseMutationOptions<
  CreateUniswapv3ToolMutation,
  CreateUniswapv3ToolMutationVariables
>
export const CreateUniswapv3PositionDocument = gql`
  mutation createUniswapv3Position($input: CreateUniswapv3PositionInput!) {
    createUniswapv3Position(input: $input)
  }
`
export type CreateUniswapv3PositionMutationFn = Apollo.MutationFunction<
  CreateUniswapv3PositionMutation,
  CreateUniswapv3PositionMutationVariables
>

/**
 * __useCreateUniswapv3PositionMutation__
 *
 * To run a mutation, you first call `useCreateUniswapv3PositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUniswapv3PositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUniswapv3PositionMutation, { data, loading, error }] = useCreateUniswapv3PositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUniswapv3PositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUniswapv3PositionMutation,
    CreateUniswapv3PositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUniswapv3PositionMutation,
    CreateUniswapv3PositionMutationVariables
  >(CreateUniswapv3PositionDocument, options)
}
export type CreateUniswapv3PositionMutationHookResult = ReturnType<
  typeof useCreateUniswapv3PositionMutation
>
export type CreateUniswapv3PositionMutationResult =
  Apollo.MutationResult<CreateUniswapv3PositionMutation>
export type CreateUniswapv3PositionMutationOptions = Apollo.BaseMutationOptions<
  CreateUniswapv3PositionMutation,
  CreateUniswapv3PositionMutationVariables
>
export const CloseUniswapv3PositionDocument = gql`
  mutation closeUniswapv3Position($input: CloseUniswapv3PositionInput!) {
    closeUniswapv3Position(input: $input)
  }
`
export type CloseUniswapv3PositionMutationFn = Apollo.MutationFunction<
  CloseUniswapv3PositionMutation,
  CloseUniswapv3PositionMutationVariables
>

/**
 * __useCloseUniswapv3PositionMutation__
 *
 * To run a mutation, you first call `useCloseUniswapv3PositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseUniswapv3PositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeUniswapv3PositionMutation, { data, loading, error }] = useCloseUniswapv3PositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseUniswapv3PositionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseUniswapv3PositionMutation,
    CloseUniswapv3PositionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloseUniswapv3PositionMutation,
    CloseUniswapv3PositionMutationVariables
  >(CloseUniswapv3PositionDocument, options)
}
export type CloseUniswapv3PositionMutationHookResult = ReturnType<
  typeof useCloseUniswapv3PositionMutation
>
export type CloseUniswapv3PositionMutationResult =
  Apollo.MutationResult<CloseUniswapv3PositionMutation>
export type CloseUniswapv3PositionMutationOptions = Apollo.BaseMutationOptions<
  CloseUniswapv3PositionMutation,
  CloseUniswapv3PositionMutationVariables
>
export const FetchMeDocument = gql`
  query fetchMe($filter: DefiWalletsFilterInput) {
    me {
      id
      defiWallets(filter: $filter) {
        ...DefiWallet
      }
    }
  }
  ${DefiWalletFragmentDoc}
`

/**
 * __useFetchMeQuery__
 *
 * To run a query within a React component, call `useFetchMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeQuery, FetchMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeQuery, FetchMeQueryVariables>(FetchMeDocument, options)
}
export function useFetchMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMeQuery, FetchMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeQuery, FetchMeQueryVariables>(FetchMeDocument, options)
}
export type FetchMeQueryHookResult = ReturnType<typeof useFetchMeQuery>
export type FetchMeLazyQueryHookResult = ReturnType<typeof useFetchMeLazyQuery>
export type FetchMeQueryResult = Apollo.QueryResult<FetchMeQuery, FetchMeQueryVariables>
export function refetchFetchMeQuery(variables?: FetchMeQueryVariables) {
  return { query: FetchMeDocument, variables: variables }
}
export const FetchMequeryDefiTokensBalanceAmountDocument = gql`
  query fetchMequeryDefiTokensBalanceAmount($input: DefiTokensBalanceAmountInput!) {
    me {
      id
      defiTokensBalanceAmount(input: $input) {
        id
        chain
        address
        symbol
        decimals
        amount
        amountReadable
      }
    }
  }
`

/**
 * __useFetchMequeryDefiTokensBalanceAmountQuery__
 *
 * To run a query within a React component, call `useFetchMequeryDefiTokensBalanceAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMequeryDefiTokensBalanceAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMequeryDefiTokensBalanceAmountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMequeryDefiTokensBalanceAmountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >(FetchMequeryDefiTokensBalanceAmountDocument, options)
}
export function useFetchMequeryDefiTokensBalanceAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMequeryDefiTokensBalanceAmountQuery,
    FetchMequeryDefiTokensBalanceAmountQueryVariables
  >(FetchMequeryDefiTokensBalanceAmountDocument, options)
}
export type FetchMequeryDefiTokensBalanceAmountQueryHookResult = ReturnType<
  typeof useFetchMequeryDefiTokensBalanceAmountQuery
>
export type FetchMequeryDefiTokensBalanceAmountLazyQueryHookResult = ReturnType<
  typeof useFetchMequeryDefiTokensBalanceAmountLazyQuery
>
export type FetchMequeryDefiTokensBalanceAmountQueryResult = Apollo.QueryResult<
  FetchMequeryDefiTokensBalanceAmountQuery,
  FetchMequeryDefiTokensBalanceAmountQueryVariables
>
export function refetchFetchMequeryDefiTokensBalanceAmountQuery(
  variables: FetchMequeryDefiTokensBalanceAmountQueryVariables
) {
  return { query: FetchMequeryDefiTokensBalanceAmountDocument, variables: variables }
}
export const FetchPublicCoinPricesDocument = gql`
  query fetchPublicCoinPrices {
    coinPrices {
      ...CoinPrice
    }
  }
  ${CoinPriceFragmentDoc}
`

/**
 * __useFetchPublicCoinPricesQuery__
 *
 * To run a query within a React component, call `useFetchPublicCoinPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicCoinPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicCoinPricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPublicCoinPricesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPublicCoinPricesQuery,
    FetchPublicCoinPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPublicCoinPricesQuery, FetchPublicCoinPricesQueryVariables>(
    FetchPublicCoinPricesDocument,
    options
  )
}
export function useFetchPublicCoinPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicCoinPricesQuery,
    FetchPublicCoinPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPublicCoinPricesQuery, FetchPublicCoinPricesQueryVariables>(
    FetchPublicCoinPricesDocument,
    options
  )
}
export type FetchPublicCoinPricesQueryHookResult = ReturnType<typeof useFetchPublicCoinPricesQuery>
export type FetchPublicCoinPricesLazyQueryHookResult = ReturnType<
  typeof useFetchPublicCoinPricesLazyQuery
>
export type FetchPublicCoinPricesQueryResult = Apollo.QueryResult<
  FetchPublicCoinPricesQuery,
  FetchPublicCoinPricesQueryVariables
>
export function refetchFetchPublicCoinPricesQuery(variables?: FetchPublicCoinPricesQueryVariables) {
  return { query: FetchPublicCoinPricesDocument, variables: variables }
}
export const FetchMeGimCoolPayPaymentInfoDocument = gql`
  query fetchMeGimCoolPayPaymentInfo($input: GimCoolPayPaymentInfoInput!) {
    me {
      id
      gimCoolPayPaymentInfo(input: $input) {
        coinType
        fiatType
        coinAmount
        fiatAmount
        blockchain
        walletAddress
        walletAddressTag
        paymentHref
        price {
          spotPrice
          prices {
            paymentMethodId
            type
            spotPriceFee
            spotPriceIncludingFee
            coinAmount
            coinCode
            fiatAmount
            fiatCode
            feeAmount
            networkFee
          }
        }
      }
    }
  }
`

/**
 * __useFetchMeGimCoolPayPaymentInfoQuery__
 *
 * To run a query within a React component, call `useFetchMeGimCoolPayPaymentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeGimCoolPayPaymentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeGimCoolPayPaymentInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMeGimCoolPayPaymentInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >(FetchMeGimCoolPayPaymentInfoDocument, options)
}
export function useFetchMeGimCoolPayPaymentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeGimCoolPayPaymentInfoQuery,
    FetchMeGimCoolPayPaymentInfoQueryVariables
  >(FetchMeGimCoolPayPaymentInfoDocument, options)
}
export type FetchMeGimCoolPayPaymentInfoQueryHookResult = ReturnType<
  typeof useFetchMeGimCoolPayPaymentInfoQuery
>
export type FetchMeGimCoolPayPaymentInfoLazyQueryHookResult = ReturnType<
  typeof useFetchMeGimCoolPayPaymentInfoLazyQuery
>
export type FetchMeGimCoolPayPaymentInfoQueryResult = Apollo.QueryResult<
  FetchMeGimCoolPayPaymentInfoQuery,
  FetchMeGimCoolPayPaymentInfoQueryVariables
>
export function refetchFetchMeGimCoolPayPaymentInfoQuery(
  variables: FetchMeGimCoolPayPaymentInfoQueryVariables
) {
  return { query: FetchMeGimCoolPayPaymentInfoDocument, variables: variables }
}
export const FetchMeSettingPageDocument = gql`
  query fetchMeSettingPage {
    me {
      id
      externalAPIKeys(filter: { isRevoked: false }) {
        ...ExternalAPIKey
      }
      isBitfinexAPIKeyBind @client
    }
  }
  ${ExternalApiKeyFragmentDoc}
`

/**
 * __useFetchMeSettingPageQuery__
 *
 * To run a query within a React component, call `useFetchMeSettingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeSettingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeSettingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeSettingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeSettingPageQuery, FetchMeSettingPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeSettingPageQuery, FetchMeSettingPageQueryVariables>(
    FetchMeSettingPageDocument,
    options
  )
}
export function useFetchMeSettingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeSettingPageQuery,
    FetchMeSettingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeSettingPageQuery, FetchMeSettingPageQueryVariables>(
    FetchMeSettingPageDocument,
    options
  )
}
export type FetchMeSettingPageQueryHookResult = ReturnType<typeof useFetchMeSettingPageQuery>
export type FetchMeSettingPageLazyQueryHookResult = ReturnType<
  typeof useFetchMeSettingPageLazyQuery
>
export type FetchMeSettingPageQueryResult = Apollo.QueryResult<
  FetchMeSettingPageQuery,
  FetchMeSettingPageQueryVariables
>
export function refetchFetchMeSettingPageQuery(variables?: FetchMeSettingPageQueryVariables) {
  return { query: FetchMeSettingPageDocument, variables: variables }
}
export const FetchPublicStrategyListDocument = gql`
  query fetchPublicStrategyList($filter: StrategyFilterInput) {
    strategies(filter: $filter) {
      ...PublicStrategyInfo
    }
  }
  ${PublicStrategyInfoFragmentDoc}
`

/**
 * __useFetchPublicStrategyListQuery__
 *
 * To run a query within a React component, call `useFetchPublicStrategyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicStrategyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicStrategyListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchPublicStrategyListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPublicStrategyListQuery,
    FetchPublicStrategyListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPublicStrategyListQuery, FetchPublicStrategyListQueryVariables>(
    FetchPublicStrategyListDocument,
    options
  )
}
export function useFetchPublicStrategyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicStrategyListQuery,
    FetchPublicStrategyListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPublicStrategyListQuery, FetchPublicStrategyListQueryVariables>(
    FetchPublicStrategyListDocument,
    options
  )
}
export type FetchPublicStrategyListQueryHookResult = ReturnType<
  typeof useFetchPublicStrategyListQuery
>
export type FetchPublicStrategyListLazyQueryHookResult = ReturnType<
  typeof useFetchPublicStrategyListLazyQuery
>
export type FetchPublicStrategyListQueryResult = Apollo.QueryResult<
  FetchPublicStrategyListQuery,
  FetchPublicStrategyListQueryVariables
>
export function refetchFetchPublicStrategyListQuery(
  variables?: FetchPublicStrategyListQueryVariables
) {
  return { query: FetchPublicStrategyListDocument, variables: variables }
}
export const FetchPublicRateHistoricalDocument = gql`
  query fetchPublicRateHistorical($filter: RateHistoricalFilterInput!) {
    rateHistorical(filter: $filter) {
      ...RateHistorical
    }
  }
  ${RateHistoricalFragmentDoc}
`

/**
 * __useFetchPublicRateHistoricalQuery__
 *
 * To run a query within a React component, call `useFetchPublicRateHistoricalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicRateHistoricalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicRateHistoricalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchPublicRateHistoricalQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPublicRateHistoricalQuery,
    FetchPublicRateHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPublicRateHistoricalQuery, FetchPublicRateHistoricalQueryVariables>(
    FetchPublicRateHistoricalDocument,
    options
  )
}
export function useFetchPublicRateHistoricalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicRateHistoricalQuery,
    FetchPublicRateHistoricalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchPublicRateHistoricalQuery,
    FetchPublicRateHistoricalQueryVariables
  >(FetchPublicRateHistoricalDocument, options)
}
export type FetchPublicRateHistoricalQueryHookResult = ReturnType<
  typeof useFetchPublicRateHistoricalQuery
>
export type FetchPublicRateHistoricalLazyQueryHookResult = ReturnType<
  typeof useFetchPublicRateHistoricalLazyQuery
>
export type FetchPublicRateHistoricalQueryResult = Apollo.QueryResult<
  FetchPublicRateHistoricalQuery,
  FetchPublicRateHistoricalQueryVariables
>
export function refetchFetchPublicRateHistoricalQuery(
  variables: FetchPublicRateHistoricalQueryVariables
) {
  return { query: FetchPublicRateHistoricalDocument, variables: variables }
}
export const FetchMeStrategyListDocument = gql`
  query fetchMeStrategyList($filter: UserStrategyFilterInput) {
    me {
      id
      strategies(filter: $filter) {
        ...UserStrategies
      }
    }
  }
  ${UserStrategiesFragmentDoc}
`

/**
 * __useFetchMeStrategyListQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyListQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeStrategyListQuery, FetchMeStrategyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyListQuery, FetchMeStrategyListQueryVariables>(
    FetchMeStrategyListDocument,
    options
  )
}
export function useFetchMeStrategyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyListQuery,
    FetchMeStrategyListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeStrategyListQuery, FetchMeStrategyListQueryVariables>(
    FetchMeStrategyListDocument,
    options
  )
}
export type FetchMeStrategyListQueryHookResult = ReturnType<typeof useFetchMeStrategyListQuery>
export type FetchMeStrategyListLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyListLazyQuery
>
export type FetchMeStrategyListQueryResult = Apollo.QueryResult<
  FetchMeStrategyListQuery,
  FetchMeStrategyListQueryVariables
>
export function refetchFetchMeStrategyListQuery(variables?: FetchMeStrategyListQueryVariables) {
  return { query: FetchMeStrategyListDocument, variables: variables }
}
export const FetchMeAiRecordsDocument = gql`
  query fetchMeAiRecords {
    me {
      id
      aICompletionRecords {
        ...AICompletionRecord
        suggestions {
          ...SuggestStrategyToolWithAllocation
        }
      }
    }
  }
  ${AiCompletionRecordFragmentDoc}
  ${SuggestStrategyToolWithAllocationFragmentDoc}
`

/**
 * __useFetchMeAiRecordsQuery__
 *
 * To run a query within a React component, call `useFetchMeAiRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeAiRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeAiRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeAiRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>(
    FetchMeAiRecordsDocument,
    options
  )
}
export function useFetchMeAiRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeAiRecordsQuery, FetchMeAiRecordsQueryVariables>(
    FetchMeAiRecordsDocument,
    options
  )
}
export type FetchMeAiRecordsQueryHookResult = ReturnType<typeof useFetchMeAiRecordsQuery>
export type FetchMeAiRecordsLazyQueryHookResult = ReturnType<typeof useFetchMeAiRecordsLazyQuery>
export type FetchMeAiRecordsQueryResult = Apollo.QueryResult<
  FetchMeAiRecordsQuery,
  FetchMeAiRecordsQueryVariables
>
export function refetchFetchMeAiRecordsQuery(variables?: FetchMeAiRecordsQueryVariables) {
  return { query: FetchMeAiRecordsDocument, variables: variables }
}
export const FetchMeAiRecordDataByIdForPortfolioDialogDocument = gql`
  query fetchMeAiRecordDataByIdForPortfolioDialog($filter: UserAICompletionRecordsFilterInput!) {
    me {
      id
      aICompletionRecords(filter: $filter) {
        ...SingleCompletionRecordForDialog
      }
    }
  }
  ${SingleCompletionRecordForDialogFragmentDoc}
`

/**
 * __useFetchMeAiRecordDataByIdForPortfolioDialogQuery__
 *
 * To run a query within a React component, call `useFetchMeAiRecordDataByIdForPortfolioDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeAiRecordDataByIdForPortfolioDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeAiRecordDataByIdForPortfolioDialogQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeAiRecordDataByIdForPortfolioDialogQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >(FetchMeAiRecordDataByIdForPortfolioDialogDocument, options)
}
export function useFetchMeAiRecordDataByIdForPortfolioDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeAiRecordDataByIdForPortfolioDialogQuery,
    FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
  >(FetchMeAiRecordDataByIdForPortfolioDialogDocument, options)
}
export type FetchMeAiRecordDataByIdForPortfolioDialogQueryHookResult = ReturnType<
  typeof useFetchMeAiRecordDataByIdForPortfolioDialogQuery
>
export type FetchMeAiRecordDataByIdForPortfolioDialogLazyQueryHookResult = ReturnType<
  typeof useFetchMeAiRecordDataByIdForPortfolioDialogLazyQuery
>
export type FetchMeAiRecordDataByIdForPortfolioDialogQueryResult = Apollo.QueryResult<
  FetchMeAiRecordDataByIdForPortfolioDialogQuery,
  FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
>
export function refetchFetchMeAiRecordDataByIdForPortfolioDialogQuery(
  variables: FetchMeAiRecordDataByIdForPortfolioDialogQueryVariables
) {
  return { query: FetchMeAiRecordDataByIdForPortfolioDialogDocument, variables: variables }
}
export const FetchMeStrategyListForSidDocument = gql`
  query fetchMeStrategyListForSid($filter: UserStrategyFilterInput) {
    me {
      id
      strategies(filter: $filter) {
        id
        sid
        ceFiStatus
      }
    }
  }
`

/**
 * __useFetchMeStrategyListForSidQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyListForSidQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyListForSidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyListForSidQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyListForSidQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeStrategyListForSidQuery,
    FetchMeStrategyListForSidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyListForSidQuery, FetchMeStrategyListForSidQueryVariables>(
    FetchMeStrategyListForSidDocument,
    options
  )
}
export function useFetchMeStrategyListForSidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyListForSidQuery,
    FetchMeStrategyListForSidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeStrategyListForSidQuery,
    FetchMeStrategyListForSidQueryVariables
  >(FetchMeStrategyListForSidDocument, options)
}
export type FetchMeStrategyListForSidQueryHookResult = ReturnType<
  typeof useFetchMeStrategyListForSidQuery
>
export type FetchMeStrategyListForSidLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyListForSidLazyQuery
>
export type FetchMeStrategyListForSidQueryResult = Apollo.QueryResult<
  FetchMeStrategyListForSidQuery,
  FetchMeStrategyListForSidQueryVariables
>
export function refetchFetchMeStrategyListForSidQuery(
  variables?: FetchMeStrategyListForSidQueryVariables
) {
  return { query: FetchMeStrategyListForSidDocument, variables: variables }
}
export const FetchMeCexWalletBalancesDocument = gql`
  query fetchMeCexWalletBalances($filter: CefiWalletFilterInput) {
    me {
      id
      cefiWallets(filter: $filter) {
        ...CefiWallet
      }
    }
  }
  ${CefiWalletFragmentDoc}
`

/**
 * __useFetchMeCexWalletBalancesQuery__
 *
 * To run a query within a React component, call `useFetchMeCexWalletBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeCexWalletBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeCexWalletBalancesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeCexWalletBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeCexWalletBalancesQuery,
    FetchMeCexWalletBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeCexWalletBalancesQuery, FetchMeCexWalletBalancesQueryVariables>(
    FetchMeCexWalletBalancesDocument,
    options
  )
}
export function useFetchMeCexWalletBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeCexWalletBalancesQuery,
    FetchMeCexWalletBalancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeCexWalletBalancesQuery, FetchMeCexWalletBalancesQueryVariables>(
    FetchMeCexWalletBalancesDocument,
    options
  )
}
export type FetchMeCexWalletBalancesQueryHookResult = ReturnType<
  typeof useFetchMeCexWalletBalancesQuery
>
export type FetchMeCexWalletBalancesLazyQueryHookResult = ReturnType<
  typeof useFetchMeCexWalletBalancesLazyQuery
>
export type FetchMeCexWalletBalancesQueryResult = Apollo.QueryResult<
  FetchMeCexWalletBalancesQuery,
  FetchMeCexWalletBalancesQueryVariables
>
export function refetchFetchMeCexWalletBalancesQuery(
  variables?: FetchMeCexWalletBalancesQueryVariables
) {
  return { query: FetchMeCexWalletBalancesDocument, variables: variables }
}
export const FetchMeCexDepositAddressDocument = gql`
  query fetchMeCexDepositAddress($filter: CefiDepositAddressInput) {
    me {
      id
      cefiDepositAddress(filter: $filter) {
        address
        chain
      }
    }
  }
`

/**
 * __useFetchMeCexDepositAddressQuery__
 *
 * To run a query within a React component, call `useFetchMeCexDepositAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeCexDepositAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeCexDepositAddressQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeCexDepositAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeCexDepositAddressQuery,
    FetchMeCexDepositAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeCexDepositAddressQuery, FetchMeCexDepositAddressQueryVariables>(
    FetchMeCexDepositAddressDocument,
    options
  )
}
export function useFetchMeCexDepositAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeCexDepositAddressQuery,
    FetchMeCexDepositAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeCexDepositAddressQuery, FetchMeCexDepositAddressQueryVariables>(
    FetchMeCexDepositAddressDocument,
    options
  )
}
export type FetchMeCexDepositAddressQueryHookResult = ReturnType<
  typeof useFetchMeCexDepositAddressQuery
>
export type FetchMeCexDepositAddressLazyQueryHookResult = ReturnType<
  typeof useFetchMeCexDepositAddressLazyQuery
>
export type FetchMeCexDepositAddressQueryResult = Apollo.QueryResult<
  FetchMeCexDepositAddressQuery,
  FetchMeCexDepositAddressQueryVariables
>
export function refetchFetchMeCexDepositAddressQuery(
  variables?: FetchMeCexDepositAddressQueryVariables
) {
  return { query: FetchMeCexDepositAddressDocument, variables: variables }
}
export const FetchMeStrategyProgressDocument = gql`
  query fetchMeStrategyProgress($strategyID: String!) {
    me {
      id
      strategyProgress(strategyID: $strategyID) {
        ...StrategyProgress
      }
    }
  }
  ${StrategyProgressFragmentDoc}
`

/**
 * __useFetchMeStrategyProgressQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyProgressQuery({
 *   variables: {
 *      strategyID: // value for 'strategyID'
 *   },
 * });
 */
export function useFetchMeStrategyProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMeStrategyProgressQuery,
    FetchMeStrategyProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyProgressQuery, FetchMeStrategyProgressQueryVariables>(
    FetchMeStrategyProgressDocument,
    options
  )
}
export function useFetchMeStrategyProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyProgressQuery,
    FetchMeStrategyProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeStrategyProgressQuery, FetchMeStrategyProgressQueryVariables>(
    FetchMeStrategyProgressDocument,
    options
  )
}
export type FetchMeStrategyProgressQueryHookResult = ReturnType<
  typeof useFetchMeStrategyProgressQuery
>
export type FetchMeStrategyProgressLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyProgressLazyQuery
>
export type FetchMeStrategyProgressQueryResult = Apollo.QueryResult<
  FetchMeStrategyProgressQuery,
  FetchMeStrategyProgressQueryVariables
>
export function refetchFetchMeStrategyProgressQuery(
  variables: FetchMeStrategyProgressQueryVariables
) {
  return { query: FetchMeStrategyProgressDocument, variables: variables }
}
export const FetchMeStrategyTotalProfitDocument = gql`
  query fetchMeStrategyTotalProfit($filter: UserStrategyProfitFilterInput) {
    me {
      id
      strategyProfit(filter: $filter) {
        ...UserStrategyProfit
      }
    }
  }
  ${UserStrategyProfitFragmentDoc}
`

/**
 * __useFetchMeStrategyTotalProfitQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyTotalProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyTotalProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyTotalProfitQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyTotalProfitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeStrategyTotalProfitQuery,
    FetchMeStrategyTotalProfitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyTotalProfitQuery, FetchMeStrategyTotalProfitQueryVariables>(
    FetchMeStrategyTotalProfitDocument,
    options
  )
}
export function useFetchMeStrategyTotalProfitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyTotalProfitQuery,
    FetchMeStrategyTotalProfitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeStrategyTotalProfitQuery,
    FetchMeStrategyTotalProfitQueryVariables
  >(FetchMeStrategyTotalProfitDocument, options)
}
export type FetchMeStrategyTotalProfitQueryHookResult = ReturnType<
  typeof useFetchMeStrategyTotalProfitQuery
>
export type FetchMeStrategyTotalProfitLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyTotalProfitLazyQuery
>
export type FetchMeStrategyTotalProfitQueryResult = Apollo.QueryResult<
  FetchMeStrategyTotalProfitQuery,
  FetchMeStrategyTotalProfitQueryVariables
>
export function refetchFetchMeStrategyTotalProfitQuery(
  variables?: FetchMeStrategyTotalProfitQueryVariables
) {
  return { query: FetchMeStrategyTotalProfitDocument, variables: variables }
}
export const FetchMeStrategyRecordsDocument = gql`
  query fetchMeStrategyRecords($filter: UserStrategyRecordsFilterInput) {
    me {
      id
      strategyRecords(filter: $filter) {
        ...UserStrategyRecord
      }
    }
  }
  ${UserStrategyRecordFragmentDoc}
`

/**
 * __useFetchMeStrategyRecordsQuery__
 *
 * To run a query within a React component, call `useFetchMeStrategyRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeStrategyRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeStrategyRecordsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchMeStrategyRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeStrategyRecordsQuery,
    FetchMeStrategyRecordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeStrategyRecordsQuery, FetchMeStrategyRecordsQueryVariables>(
    FetchMeStrategyRecordsDocument,
    options
  )
}
export function useFetchMeStrategyRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeStrategyRecordsQuery,
    FetchMeStrategyRecordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeStrategyRecordsQuery, FetchMeStrategyRecordsQueryVariables>(
    FetchMeStrategyRecordsDocument,
    options
  )
}
export type FetchMeStrategyRecordsQueryHookResult = ReturnType<
  typeof useFetchMeStrategyRecordsQuery
>
export type FetchMeStrategyRecordsLazyQueryHookResult = ReturnType<
  typeof useFetchMeStrategyRecordsLazyQuery
>
export type FetchMeStrategyRecordsQueryResult = Apollo.QueryResult<
  FetchMeStrategyRecordsQuery,
  FetchMeStrategyRecordsQueryVariables
>
export function refetchFetchMeStrategyRecordsQuery(
  variables?: FetchMeStrategyRecordsQueryVariables
) {
  return { query: FetchMeStrategyRecordsDocument, variables: variables }
}
export const FetchMeNftCollectionsDocument = gql`
  query fetchMeNftCollections {
    me {
      id
      nftCollections {
        ...NFTCollection
      }
    }
  }
  ${NftCollectionFragmentDoc}
`

/**
 * __useFetchMeNftCollectionsQuery__
 *
 * To run a query within a React component, call `useFetchMeNftCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeNftCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeNftCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeNftCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeNftCollectionsQuery,
    FetchMeNftCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeNftCollectionsQuery, FetchMeNftCollectionsQueryVariables>(
    FetchMeNftCollectionsDocument,
    options
  )
}
export function useFetchMeNftCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeNftCollectionsQuery,
    FetchMeNftCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeNftCollectionsQuery, FetchMeNftCollectionsQueryVariables>(
    FetchMeNftCollectionsDocument,
    options
  )
}
export type FetchMeNftCollectionsQueryHookResult = ReturnType<typeof useFetchMeNftCollectionsQuery>
export type FetchMeNftCollectionsLazyQueryHookResult = ReturnType<
  typeof useFetchMeNftCollectionsLazyQuery
>
export type FetchMeNftCollectionsQueryResult = Apollo.QueryResult<
  FetchMeNftCollectionsQuery,
  FetchMeNftCollectionsQueryVariables
>
export function refetchFetchMeNftCollectionsQuery(variables?: FetchMeNftCollectionsQueryVariables) {
  return { query: FetchMeNftCollectionsDocument, variables: variables }
}
export const FetchMeNftWhitelistInfoByPhrasesDocument = gql`
  query fetchMeNftWhitelistInfoByPhrases {
    me {
      id
      nftWhitelistInfoByPhrases {
        id
        phrase
        userAddress
        isWhitelisted
      }
    }
  }
`

/**
 * __useFetchMeNftWhitelistInfoByPhrasesQuery__
 *
 * To run a query within a React component, call `useFetchMeNftWhitelistInfoByPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeNftWhitelistInfoByPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeNftWhitelistInfoByPhrasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeNftWhitelistInfoByPhrasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >(FetchMeNftWhitelistInfoByPhrasesDocument, options)
}
export function useFetchMeNftWhitelistInfoByPhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeNftWhitelistInfoByPhrasesQuery,
    FetchMeNftWhitelistInfoByPhrasesQueryVariables
  >(FetchMeNftWhitelistInfoByPhrasesDocument, options)
}
export type FetchMeNftWhitelistInfoByPhrasesQueryHookResult = ReturnType<
  typeof useFetchMeNftWhitelistInfoByPhrasesQuery
>
export type FetchMeNftWhitelistInfoByPhrasesLazyQueryHookResult = ReturnType<
  typeof useFetchMeNftWhitelistInfoByPhrasesLazyQuery
>
export type FetchMeNftWhitelistInfoByPhrasesQueryResult = Apollo.QueryResult<
  FetchMeNftWhitelistInfoByPhrasesQuery,
  FetchMeNftWhitelistInfoByPhrasesQueryVariables
>
export function refetchFetchMeNftWhitelistInfoByPhrasesQuery(
  variables?: FetchMeNftWhitelistInfoByPhrasesQueryVariables
) {
  return { query: FetchMeNftWhitelistInfoByPhrasesDocument, variables: variables }
}
export const FetchMeNftMerkleTreeHexProofDocument = gql`
  query fetchMeNftMerkleTreeHexProof {
    me {
      id
      nftMerkleTreeHexProof {
        hexProof
      }
    }
  }
`

/**
 * __useFetchMeNftMerkleTreeHexProofQuery__
 *
 * To run a query within a React component, call `useFetchMeNftMerkleTreeHexProofQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeNftMerkleTreeHexProofQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeNftMerkleTreeHexProofQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeNftMerkleTreeHexProofQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >(FetchMeNftMerkleTreeHexProofDocument, options)
}
export function useFetchMeNftMerkleTreeHexProofLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchMeNftMerkleTreeHexProofQuery,
    FetchMeNftMerkleTreeHexProofQueryVariables
  >(FetchMeNftMerkleTreeHexProofDocument, options)
}
export type FetchMeNftMerkleTreeHexProofQueryHookResult = ReturnType<
  typeof useFetchMeNftMerkleTreeHexProofQuery
>
export type FetchMeNftMerkleTreeHexProofLazyQueryHookResult = ReturnType<
  typeof useFetchMeNftMerkleTreeHexProofLazyQuery
>
export type FetchMeNftMerkleTreeHexProofQueryResult = Apollo.QueryResult<
  FetchMeNftMerkleTreeHexProofQuery,
  FetchMeNftMerkleTreeHexProofQueryVariables
>
export function refetchFetchMeNftMerkleTreeHexProofQuery(
  variables?: FetchMeNftMerkleTreeHexProofQueryVariables
) {
  return { query: FetchMeNftMerkleTreeHexProofDocument, variables: variables }
}
export const UniswapV3BacktestDocument = gql`
  query uniswapV3Backtest(
    $poolID: String = "0xdac8a8e6dbf8c690ec6815e0ff03491b2770255d"
    $investmentAmount: Float = 100000
    $days: Int = 365
    $compound: Boolean = true
    $protocol: EvmChain = Polygon
    $diff: Float = 0.0001
  ) {
    uniswapV3Backtest(
      poolID: $poolID
      investmentAmount: $investmentAmount
      days: $days
      period: DAILY
      protocol: $protocol
      compound: $compound
      diff: $diff
    ) {
      id
      days
      activeLiquidityPercent
      dailyReturn
      annualReturn
      impermanentLoss
      compound
      dataPointsCount
    }
  }
`

/**
 * __useUniswapV3BacktestQuery__
 *
 * To run a query within a React component, call `useUniswapV3BacktestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniswapV3BacktestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniswapV3BacktestQuery({
 *   variables: {
 *      poolID: // value for 'poolID'
 *      investmentAmount: // value for 'investmentAmount'
 *      days: // value for 'days'
 *      compound: // value for 'compound'
 *      protocol: // value for 'protocol'
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useUniswapV3BacktestQuery(
  baseOptions?: Apollo.QueryHookOptions<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>(
    UniswapV3BacktestDocument,
    options
  )
}
export function useUniswapV3BacktestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UniswapV3BacktestQuery, UniswapV3BacktestQueryVariables>(
    UniswapV3BacktestDocument,
    options
  )
}
export type UniswapV3BacktestQueryHookResult = ReturnType<typeof useUniswapV3BacktestQuery>
export type UniswapV3BacktestLazyQueryHookResult = ReturnType<typeof useUniswapV3BacktestLazyQuery>
export type UniswapV3BacktestQueryResult = Apollo.QueryResult<
  UniswapV3BacktestQuery,
  UniswapV3BacktestQueryVariables
>
export function refetchUniswapV3BacktestQuery(variables?: UniswapV3BacktestQueryVariables) {
  return { query: UniswapV3BacktestDocument, variables: variables }
}
export const FetchToolsDocument = gql`
  query fetchTools {
    tools {
      ... on StrategyInfo {
        ...PublicStrategyInfo
      }
      ... on UniswapToolInfo {
        ...UniswapToolInfo
      }
    }
  }
  ${PublicStrategyInfoFragmentDoc}
  ${UniswapToolInfoFragmentDoc}
`

/**
 * __useFetchToolsQuery__
 *
 * To run a query within a React component, call `useFetchToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchToolsQuery, FetchToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchToolsQuery, FetchToolsQueryVariables>(FetchToolsDocument, options)
}
export function useFetchToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchToolsQuery, FetchToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchToolsQuery, FetchToolsQueryVariables>(FetchToolsDocument, options)
}
export type FetchToolsQueryHookResult = ReturnType<typeof useFetchToolsQuery>
export type FetchToolsLazyQueryHookResult = ReturnType<typeof useFetchToolsLazyQuery>
export type FetchToolsQueryResult = Apollo.QueryResult<FetchToolsQuery, FetchToolsQueryVariables>
export function refetchFetchToolsQuery(variables?: FetchToolsQueryVariables) {
  return { query: FetchToolsDocument, variables: variables }
}
export const FetchMeToolsDocument = gql`
  query fetchMeTools {
    me {
      id
      tools {
        ... on UserStrategyInfo {
          ...UserStrategyInfo
        }
        ... on UserUniswapPositionInfo {
          ...UserUniswapPositionInfo
        }
      }
      ownedToolsSetting {
        ...UserUniswapToolSettingInfo
      }
    }
  }
  ${UserStrategyInfoFragmentDoc}
  ${UserUniswapPositionInfoFragmentDoc}
  ${UserUniswapToolSettingInfoFragmentDoc}
`

/**
 * __useFetchMeToolsQuery__
 *
 * To run a query within a React component, call `useFetchMeToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMeToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMeToolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMeToolsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMeToolsQuery, FetchMeToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMeToolsQuery, FetchMeToolsQueryVariables>(
    FetchMeToolsDocument,
    options
  )
}
export function useFetchMeToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMeToolsQuery, FetchMeToolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMeToolsQuery, FetchMeToolsQueryVariables>(
    FetchMeToolsDocument,
    options
  )
}
export type FetchMeToolsQueryHookResult = ReturnType<typeof useFetchMeToolsQuery>
export type FetchMeToolsLazyQueryHookResult = ReturnType<typeof useFetchMeToolsLazyQuery>
export type FetchMeToolsQueryResult = Apollo.QueryResult<
  FetchMeToolsQuery,
  FetchMeToolsQueryVariables
>
export function refetchFetchMeToolsQuery(variables?: FetchMeToolsQueryVariables) {
  return { query: FetchMeToolsDocument, variables: variables }
}
export const FetchPoolsWithArgumentsDocument = gql`
  query fetchPoolsWithArguments(
    $orderBy: String
    $first: Int = 10
    $skip: Int = 0
    $orderDirection: String
  ) {
    genericPoolsEthereum: poolsWithArguments(
      chain: Ethereum
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Generic
    ) {
      ...Pool
    }
    genericPoolsPolygon: poolsWithArguments(
      chain: Polygon
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Generic
    ) {
      ...Pool
    }
    stablesPoolsEthereum: poolsWithArguments(
      chain: Ethereum
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Stables
    ) {
      ...Pool
    }
    stablesPoolsPolygon: poolsWithArguments(
      chain: Polygon
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
      flag: Stables
    ) {
      ...Pool
    }
    poolsEthereum: poolsWithArguments(
      chain: Ethereum
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
    ) {
      ...Pool
    }
    poolsPolygon: poolsWithArguments(
      chain: Polygon
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
    ) {
      ...Pool
    }
  }
  ${PoolFragmentDoc}
`

/**
 * __useFetchPoolsWithArgumentsQuery__
 *
 * To run a query within a React component, call `useFetchPoolsWithArgumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPoolsWithArgumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPoolsWithArgumentsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useFetchPoolsWithArgumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPoolsWithArgumentsQuery,
    FetchPoolsWithArgumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPoolsWithArgumentsQuery, FetchPoolsWithArgumentsQueryVariables>(
    FetchPoolsWithArgumentsDocument,
    options
  )
}
export function useFetchPoolsWithArgumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPoolsWithArgumentsQuery,
    FetchPoolsWithArgumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPoolsWithArgumentsQuery, FetchPoolsWithArgumentsQueryVariables>(
    FetchPoolsWithArgumentsDocument,
    options
  )
}
export type FetchPoolsWithArgumentsQueryHookResult = ReturnType<
  typeof useFetchPoolsWithArgumentsQuery
>
export type FetchPoolsWithArgumentsLazyQueryHookResult = ReturnType<
  typeof useFetchPoolsWithArgumentsLazyQuery
>
export type FetchPoolsWithArgumentsQueryResult = Apollo.QueryResult<
  FetchPoolsWithArgumentsQuery,
  FetchPoolsWithArgumentsQueryVariables
>
export function refetchFetchPoolsWithArgumentsQuery(
  variables?: FetchPoolsWithArgumentsQueryVariables
) {
  return { query: FetchPoolsWithArgumentsDocument, variables: variables }
}
export const FetchPoolsDocument = gql`
  query fetchPools($token0s: [String!], $token1s: [String!], $chain: EvmChain!) {
    poolsWithArguments(chain: $chain, token0s: $token0s, token1s: $token1s) {
      ...PoolWithReyieldPoolValidation
    }
  }
  ${PoolWithReyieldPoolValidationFragmentDoc}
`

/**
 * __useFetchPoolsQuery__
 *
 * To run a query within a React component, call `useFetchPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPoolsQuery({
 *   variables: {
 *      token0s: // value for 'token0s'
 *      token1s: // value for 'token1s'
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useFetchPoolsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPoolsQuery, FetchPoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPoolsQuery, FetchPoolsQueryVariables>(FetchPoolsDocument, options)
}
export function useFetchPoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPoolsQuery, FetchPoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPoolsQuery, FetchPoolsQueryVariables>(FetchPoolsDocument, options)
}
export type FetchPoolsQueryHookResult = ReturnType<typeof useFetchPoolsQuery>
export type FetchPoolsLazyQueryHookResult = ReturnType<typeof useFetchPoolsLazyQuery>
export type FetchPoolsQueryResult = Apollo.QueryResult<FetchPoolsQuery, FetchPoolsQueryVariables>
export function refetchFetchPoolsQuery(variables: FetchPoolsQueryVariables) {
  return { query: FetchPoolsDocument, variables: variables }
}
export const FetchPoolsTicksByPoolIdsDocument = gql`
  query fetchPoolsTicksByPoolIds($poolIds: [String!]!, $chain: EvmChain!) {
    poolsWithArguments(chain: $chain, poolIds: $poolIds) {
      id
      ticks {
        id
        tickIdx
        liquidityNet
        price0
        price1
      }
    }
  }
`

/**
 * __useFetchPoolsTicksByPoolIdsQuery__
 *
 * To run a query within a React component, call `useFetchPoolsTicksByPoolIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPoolsTicksByPoolIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPoolsTicksByPoolIdsQuery({
 *   variables: {
 *      poolIds: // value for 'poolIds'
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useFetchPoolsTicksByPoolIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPoolsTicksByPoolIdsQuery,
    FetchPoolsTicksByPoolIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPoolsTicksByPoolIdsQuery, FetchPoolsTicksByPoolIdsQueryVariables>(
    FetchPoolsTicksByPoolIdsDocument,
    options
  )
}
export function useFetchPoolsTicksByPoolIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPoolsTicksByPoolIdsQuery,
    FetchPoolsTicksByPoolIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPoolsTicksByPoolIdsQuery, FetchPoolsTicksByPoolIdsQueryVariables>(
    FetchPoolsTicksByPoolIdsDocument,
    options
  )
}
export type FetchPoolsTicksByPoolIdsQueryHookResult = ReturnType<
  typeof useFetchPoolsTicksByPoolIdsQuery
>
export type FetchPoolsTicksByPoolIdsLazyQueryHookResult = ReturnType<
  typeof useFetchPoolsTicksByPoolIdsLazyQuery
>
export type FetchPoolsTicksByPoolIdsQueryResult = Apollo.QueryResult<
  FetchPoolsTicksByPoolIdsQuery,
  FetchPoolsTicksByPoolIdsQueryVariables
>
export function refetchFetchPoolsTicksByPoolIdsQuery(
  variables: FetchPoolsTicksByPoolIdsQueryVariables
) {
  return { query: FetchPoolsTicksByPoolIdsDocument, variables: variables }
}
export const FetchSupportingTokensDocument = gql`
  query fetchSupportingTokens($chain: EvmChain!) {
    uniswapTokens(chain: $chain) {
      ...UniswapPoolToken
    }
  }
  ${UniswapPoolTokenFragmentDoc}
`

/**
 * __useFetchSupportingTokensQuery__
 *
 * To run a query within a React component, call `useFetchSupportingTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSupportingTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSupportingTokensQuery({
 *   variables: {
 *      chain: // value for 'chain'
 *   },
 * });
 */
export function useFetchSupportingTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSupportingTokensQuery,
    FetchSupportingTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSupportingTokensQuery, FetchSupportingTokensQueryVariables>(
    FetchSupportingTokensDocument,
    options
  )
}
export function useFetchSupportingTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSupportingTokensQuery,
    FetchSupportingTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSupportingTokensQuery, FetchSupportingTokensQueryVariables>(
    FetchSupportingTokensDocument,
    options
  )
}
export type FetchSupportingTokensQueryHookResult = ReturnType<typeof useFetchSupportingTokensQuery>
export type FetchSupportingTokensLazyQueryHookResult = ReturnType<
  typeof useFetchSupportingTokensLazyQuery
>
export type FetchSupportingTokensQueryResult = Apollo.QueryResult<
  FetchSupportingTokensQuery,
  FetchSupportingTokensQueryVariables
>
export function refetchFetchSupportingTokensQuery(variables: FetchSupportingTokensQueryVariables) {
  return { query: FetchSupportingTokensDocument, variables: variables }
}
export const FetchMetricDocument = gql`
  query fetchMetric {
    metric {
      id
      positions {
        id
        positionManagerContractAddress
        positionOwnerAddress
        positionId
        positionIdTotalCount
        positionIsRunning
        amountUsd
        sumUsd
        feeUsd
        strategyId
        strategyProvider
        chain
        createdAt
      }
      positionManagersInfo {
        id
        chain
        count
      }
      createdAt
    }
  }
`

/**
 * __useFetchMetricQuery__
 *
 * To run a query within a React component, call `useFetchMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMetricQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMetricQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMetricQuery, FetchMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchMetricQuery, FetchMetricQueryVariables>(FetchMetricDocument, options)
}
export function useFetchMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMetricQuery, FetchMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchMetricQuery, FetchMetricQueryVariables>(
    FetchMetricDocument,
    options
  )
}
export type FetchMetricQueryHookResult = ReturnType<typeof useFetchMetricQuery>
export type FetchMetricLazyQueryHookResult = ReturnType<typeof useFetchMetricLazyQuery>
export type FetchMetricQueryResult = Apollo.QueryResult<FetchMetricQuery, FetchMetricQueryVariables>
export function refetchFetchMetricQuery(variables?: FetchMetricQueryVariables) {
  return { query: FetchMetricDocument, variables: variables }
}
export type AMLKeySpecifier = ('edd' | 'ns' | 'ra' | 'raResults' | 'status' | AMLKeySpecifier)[]
export type AMLFieldPolicy = {
  edd?: FieldPolicy<any> | FieldReadFunction<any>
  ns?: FieldPolicy<any> | FieldReadFunction<any>
  ra?: FieldPolicy<any> | FieldReadFunction<any>
  raResults?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLAssessmentItemKeySpecifier = (
  | 'description'
  | 'id'
  | 'itemCategories'
  | 'weights'
  | AMLAssessmentItemKeySpecifier
)[]
export type AMLAssessmentItemFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  itemCategories?: FieldPolicy<any> | FieldReadFunction<any>
  weights?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLEDDRecordKeySpecifier = (
  | 'createdAt'
  | 'firstAuditorID'
  | 'id'
  | 'secondAuditorID'
  | 'status'
  | 'updatedAt'
  | AMLEDDRecordKeySpecifier
)[]
export type AMLEDDRecordFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  firstAuditorID?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  secondAuditorID?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLItemCategoryKeySpecifier = (
  | 'allowMultipleSelection'
  | 'description'
  | 'id'
  | 'riskFactors'
  | AMLItemCategoryKeySpecifier
)[]
export type AMLItemCategoryFieldPolicy = {
  allowMultipleSelection?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  riskFactors?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLNSRecordKeySpecifier = (
  | 'createdAt'
  | 'firstAuditorID'
  | 'id'
  | 'isSystemAudited'
  | 'secondAuditorID'
  | 'status'
  | 'updatedAt'
  | AMLNSRecordKeySpecifier
)[]
export type AMLNSRecordFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  firstAuditorID?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isSystemAudited?: FieldPolicy<any> | FieldReadFunction<any>
  secondAuditorID?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLRARecordKeySpecifier = (
  | 'createdAt'
  | 'firstAuditorID'
  | 'id'
  | 'isSystemAudited'
  | 'secondAuditorID'
  | 'status'
  | 'updatedAt'
  | 'userAssessmentResultID'
  | AMLRARecordKeySpecifier
)[]
export type AMLRARecordFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  firstAuditorID?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isSystemAudited?: FieldPolicy<any> | FieldReadFunction<any>
  secondAuditorID?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAssessmentResultID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLRatingCriteriaKeySpecifier = ('high' | 'medium' | AMLRatingCriteriaKeySpecifier)[]
export type AMLRatingCriteriaFieldPolicy = {
  high?: FieldPolicy<any> | FieldReadFunction<any>
  medium?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLRiskAssessmentFormKeySpecifier = (
  | 'assessmentItems'
  | 'createdAt'
  | 'criteria'
  | 'id'
  | 'updatedAt'
  | 'version'
  | AMLRiskAssessmentFormKeySpecifier
)[]
export type AMLRiskAssessmentFormFieldPolicy = {
  assessmentItems?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  criteria?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLRiskFactorKeySpecifier = (
  | 'description'
  | 'id'
  | 'isSelected'
  | 'score'
  | 'severity'
  | AMLRiskFactorKeySpecifier
)[]
export type AMLRiskFactorFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isSelected?: FieldPolicy<any> | FieldReadFunction<any>
  score?: FieldPolicy<any> | FieldReadFunction<any>
  severity?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLUserAssessmentResultKeySpecifier = (
  | 'assessorID'
  | 'createdAt'
  | 'form'
  | 'id'
  | 'score'
  | 'status'
  | 'updatedAt'
  | 'userID'
  | AMLUserAssessmentResultKeySpecifier
)[]
export type AMLUserAssessmentResultFieldPolicy = {
  assessorID?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  form?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  score?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AMLVerificationKeySpecifier = (
  | 'createdAt'
  | 'eddStatus'
  | 'id'
  | 'nsStatus'
  | 'raStatus'
  | 'status'
  | 'updatedAt'
  | AMLVerificationKeySpecifier
)[]
export type AMLVerificationFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  eddStatus?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  nsStatus?: FieldPolicy<any> | FieldReadFunction<any>
  raStatus?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type APIKeyKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'key'
  | 'name'
  | 'permissions'
  | 'secret'
  | APIKeyKeySpecifier
)[]
export type APIKeyFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  permissions?: FieldPolicy<any> | FieldReadFunction<any>
  secret?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AcceptFiatWithdrawalResponseKeySpecifier = (
  | 'result'
  | AcceptFiatWithdrawalResponseKeySpecifier
)[]
export type AcceptFiatWithdrawalResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ActivityNotificationKeySpecifier = (
  | 'contentfulMetadata'
  | 'desc'
  | 'link'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | ActivityNotificationKeySpecifier
)[]
export type ActivityNotificationFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  desc?: FieldPolicy<any> | FieldReadFunction<any>
  link?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ActivityNotificationCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ActivityNotificationCollectionKeySpecifier
)[]
export type ActivityNotificationCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ActivityNotificationLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | ActivityNotificationLinkingCollectionsKeySpecifier
)[]
export type ActivityNotificationLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AddressKeySpecifier = ('chain' | 'currency' | 'value' | AddressKeySpecifier)[]
export type AddressFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminKeySpecifier = (
  | 'apiKeys'
  | 'depositReport'
  | 'deposits'
  | 'holiday'
  | 'ipUsers'
  | 'kgiReadyUsers'
  | 'ledgers'
  | 'onHoldFiatWithdrawals'
  | 'onHoldKGIVerification'
  | 'theLatestAMLRiskAssessmentForm'
  | 'tradeReport'
  | 'trades'
  | 'users'
  | 'verifiers'
  | 'voucherRecords'
  | 'withdrawalReport'
  | 'withdrawals'
  | AdminKeySpecifier
)[]
export type AdminFieldPolicy = {
  apiKeys?: FieldPolicy<any> | FieldReadFunction<any>
  depositReport?: FieldPolicy<any> | FieldReadFunction<any>
  deposits?: FieldPolicy<any> | FieldReadFunction<any>
  holiday?: FieldPolicy<any> | FieldReadFunction<any>
  ipUsers?: FieldPolicy<any> | FieldReadFunction<any>
  kgiReadyUsers?: FieldPolicy<any> | FieldReadFunction<any>
  ledgers?: FieldPolicy<any> | FieldReadFunction<any>
  onHoldFiatWithdrawals?: FieldPolicy<any> | FieldReadFunction<any>
  onHoldKGIVerification?: FieldPolicy<any> | FieldReadFunction<any>
  theLatestAMLRiskAssessmentForm?: FieldPolicy<any> | FieldReadFunction<any>
  tradeReport?: FieldPolicy<any> | FieldReadFunction<any>
  trades?: FieldPolicy<any> | FieldReadFunction<any>
  users?: FieldPolicy<any> | FieldReadFunction<any>
  verifiers?: FieldPolicy<any> | FieldReadFunction<any>
  voucherRecords?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawalReport?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawals?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminAPIKeyResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminAPIKeyResponseKeySpecifier
)[]
export type AdminAPIKeyResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminActivateMerchantResponseKeySpecifier = (
  | 'result'
  | AdminActivateMerchantResponseKeySpecifier
)[]
export type AdminActivateMerchantResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminAddAuditNoteResponseKeySpecifier = (
  | 'result'
  | AdminAddAuditNoteResponseKeySpecifier
)[]
export type AdminAddAuditNoteResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminApproveVirtualAccountVerificationResponseKeySpecifier = (
  | 'result'
  | AdminApproveVirtualAccountVerificationResponseKeySpecifier
)[]
export type AdminApproveVirtualAccountVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminAssignKYCVerifierResponseKeySpecifier = (
  | 'result'
  | 'user'
  | AdminAssignKYCVerifierResponseKeySpecifier
)[]
export type AdminAssignKYCVerifierResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminAuditWithdrawalResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminAuditWithdrawalResponseKeySpecifier
)[]
export type AdminAuditWithdrawalResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminChangeVirtualAccountLimitResponseKeySpecifier = (
  | 'result'
  | AdminChangeVirtualAccountLimitResponseKeySpecifier
)[]
export type AdminChangeVirtualAccountLimitResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckDepositInfoResponseKeySpecifier = (
  | 'result'
  | AdminCheckDepositInfoResponseKeySpecifier
)[]
export type AdminCheckDepositInfoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckUploadResultMResponseKeySpecifier = (
  | 'response'
  | 'result'
  | AdminCheckUploadResultMResponseKeySpecifier
)[]
export type AdminCheckUploadResultMResponseFieldPolicy = {
  response?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckUploadResultP01ResponseKeySpecifier = (
  | 'response'
  | 'result'
  | AdminCheckUploadResultP01ResponseKeySpecifier
)[]
export type AdminCheckUploadResultP01ResponseFieldPolicy = {
  response?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckUploadResultP02ResponseKeySpecifier = (
  | 'response'
  | 'result'
  | AdminCheckUploadResultP02ResponseKeySpecifier
)[]
export type AdminCheckUploadResultP02ResponseFieldPolicy = {
  response?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckUploadResultP05ResponseKeySpecifier = (
  | 'response'
  | 'result'
  | AdminCheckUploadResultP05ResponseKeySpecifier
)[]
export type AdminCheckUploadResultP05ResponseFieldPolicy = {
  response?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckUploadResultPG1ResponseKeySpecifier = (
  | 'response'
  | 'result'
  | AdminCheckUploadResultPG1ResponseKeySpecifier
)[]
export type AdminCheckUploadResultPG1ResponseFieldPolicy = {
  response?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCheckUploadResultResponseKeySpecifier = (
  | 'result'
  | AdminCheckUploadResultResponseKeySpecifier
)[]
export type AdminCheckUploadResultResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCreateAPIKeyResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminCreateAPIKeyResponseKeySpecifier
)[]
export type AdminCreateAPIKeyResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCreateOAuthClientResponseKeySpecifier = (
  | 'clientID'
  | 'result'
  | AdminCreateOAuthClientResponseKeySpecifier
)[]
export type AdminCreateOAuthClientResponseFieldPolicy = {
  clientID?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCreateRolesResponseKeySpecifier = (
  | 'result'
  | AdminCreateRolesResponseKeySpecifier
)[]
export type AdminCreateRolesResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminCreateUserResponseKeySpecifier = (
  | 'result'
  | 'userID'
  | AdminCreateUserResponseKeySpecifier
)[]
export type AdminCreateUserResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDVBanResponseKeySpecifier = ('result' | AdminDVBanResponseKeySpecifier)[]
export type AdminDVBanResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDVUpdateResponseKeySpecifier = ('result' | AdminDVUpdateResponseKeySpecifier)[]
export type AdminDVUpdateResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDeleteAPIKeyResponseKeySpecifier = (
  | 'result'
  | AdminDeleteAPIKeyResponseKeySpecifier
)[]
export type AdminDeleteAPIKeyResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDeleteRolesResponseKeySpecifier = (
  | 'result'
  | AdminDeleteRolesResponseKeySpecifier
)[]
export type AdminDeleteRolesResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDepositReportResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminDepositReportResponseKeySpecifier
)[]
export type AdminDepositReportResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDepositResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | AdminDepositResponseKeySpecifier
)[]
export type AdminDepositResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDisable2FAResponseKeySpecifier = ('result' | AdminDisable2FAResponseKeySpecifier)[]
export type AdminDisable2FAResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminDisableUserResponseKeySpecifier = (
  | 'result'
  | AdminDisableUserResponseKeySpecifier
)[]
export type AdminDisableUserResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminEDDAuditResponseKeySpecifier = (
  | 'record'
  | 'result'
  | AdminEDDAuditResponseKeySpecifier
)[]
export type AdminEDDAuditResponseFieldPolicy = {
  record?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminEnableUserResponseKeySpecifier = ('result' | AdminEnableUserResponseKeySpecifier)[]
export type AdminEnableUserResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminHolidayAddResponseKeySpecifier = ('result' | AdminHolidayAddResponseKeySpecifier)[]
export type AdminHolidayAddResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminHolidayResponseKeySpecifier = (
  | 'meta'
  | 'records'
  | 'result'
  | AdminHolidayResponseKeySpecifier
)[]
export type AdminHolidayResponseFieldPolicy = {
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  records?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminIPUserResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminIPUserResponseKeySpecifier
)[]
export type AdminIPUserResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminLedgerResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | AdminLedgerResponseKeySpecifier
)[]
export type AdminLedgerResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminMutationKeySpecifier = (
  | 'adminActivateMerchant'
  | 'adminAddAuditNote'
  | 'adminApproveEDDAudit'
  | 'adminApproveNSAudit'
  | 'adminApproveRAAudit'
  | 'adminApproveVirtualAccountVerification'
  | 'adminAssignKYCVerifier'
  | 'adminAuditWithdrawal'
  | 'adminChangeVirtualAccountLimit'
  | 'adminCheckDepositInfo'
  | 'adminCheckUploadResult'
  | 'adminCheckUploadResultM'
  | 'adminCheckUploadResultP01'
  | 'adminCheckUploadResultP02'
  | 'adminCheckUploadResultP05'
  | 'adminCheckUploadResultPG1'
  | 'adminCreateAPIKey'
  | 'adminCreateOAuthClient'
  | 'adminCreateRoles'
  | 'adminCreateUser'
  | 'adminDVBan'
  | 'adminDVUpdate'
  | 'adminDeleteAPIKey'
  | 'adminDeleteRoles'
  | 'adminDisable2FA'
  | 'adminDisableUser'
  | 'adminEnableUser'
  | 'adminHolidayAdd'
  | 'adminReauditKYC'
  | 'adminRecoverKGIStatus'
  | 'adminRefreshMerchant'
  | 'adminRejectEDDAudit'
  | 'adminRejectFiatWithdrawal'
  | 'adminRejectKGIVerification'
  | 'adminRejectNSAudit'
  | 'adminRejectRAAudit'
  | 'adminRejectVirtualAccountVerication'
  | 'adminResendAMLWithdrawInfoP01'
  | 'adminResendAMLWithdrawInfoP02'
  | 'adminResendFiatWithdrawal'
  | 'adminResendKGIVerification'
  | 'adminResendWithdrawInfoP01'
  | 'adminResendWithdrawInfoP02'
  | 'adminResetEDDAudit'
  | 'adminResetKYCVerification'
  | 'adminResetNSAudit'
  | 'adminResetPhone'
  | 'adminResetRAAudit'
  | 'adminSendBGWithdrawInfo'
  | 'adminSendInvestorsSettlement'
  | 'adminSendMemberInfo'
  | 'adminSendWithdrawInfoP01'
  | 'adminSendWithdrawInfoP02'
  | 'adminSetOldUser'
  | 'adminSetTradePermission'
  | 'adminSubmitAMLVerification'
  | 'adminSubmitEDDAudit'
  | 'adminSubmitKYCOneVerification'
  | 'adminSubmitKYCTwoVerification'
  | 'adminSubmitNSAudit'
  | 'adminSubmitRAAudit'
  | 'adminSystemAuditAML'
  | 'adminUpdateCoinInfo'
  | 'adminUpdateMarketInfo'
  | 'adminUpdateOAuthClient'
  | 'adminUpdateQuotationInfo'
  | 'adminUploadSuspiciousTransaction'
  | 'adminUploadVerificationFile'
  | 'adminVIPUpgrade'
  | 'adminVerifyKYCIdentity'
  | 'adminVoucherCreate'
  | 'adminVoucherEdit'
  | AdminMutationKeySpecifier
)[]
export type AdminMutationFieldPolicy = {
  adminActivateMerchant?: FieldPolicy<any> | FieldReadFunction<any>
  adminAddAuditNote?: FieldPolicy<any> | FieldReadFunction<any>
  adminApproveEDDAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminApproveNSAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminApproveRAAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminApproveVirtualAccountVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminAssignKYCVerifier?: FieldPolicy<any> | FieldReadFunction<any>
  adminAuditWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
  adminChangeVirtualAccountLimit?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckDepositInfo?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckUploadResult?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckUploadResultM?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckUploadResultP01?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckUploadResultP02?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckUploadResultP05?: FieldPolicy<any> | FieldReadFunction<any>
  adminCheckUploadResultPG1?: FieldPolicy<any> | FieldReadFunction<any>
  adminCreateAPIKey?: FieldPolicy<any> | FieldReadFunction<any>
  adminCreateOAuthClient?: FieldPolicy<any> | FieldReadFunction<any>
  adminCreateRoles?: FieldPolicy<any> | FieldReadFunction<any>
  adminCreateUser?: FieldPolicy<any> | FieldReadFunction<any>
  adminDVBan?: FieldPolicy<any> | FieldReadFunction<any>
  adminDVUpdate?: FieldPolicy<any> | FieldReadFunction<any>
  adminDeleteAPIKey?: FieldPolicy<any> | FieldReadFunction<any>
  adminDeleteRoles?: FieldPolicy<any> | FieldReadFunction<any>
  adminDisable2FA?: FieldPolicy<any> | FieldReadFunction<any>
  adminDisableUser?: FieldPolicy<any> | FieldReadFunction<any>
  adminEnableUser?: FieldPolicy<any> | FieldReadFunction<any>
  adminHolidayAdd?: FieldPolicy<any> | FieldReadFunction<any>
  adminReauditKYC?: FieldPolicy<any> | FieldReadFunction<any>
  adminRecoverKGIStatus?: FieldPolicy<any> | FieldReadFunction<any>
  adminRefreshMerchant?: FieldPolicy<any> | FieldReadFunction<any>
  adminRejectEDDAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminRejectFiatWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
  adminRejectKGIVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminRejectNSAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminRejectRAAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminRejectVirtualAccountVerication?: FieldPolicy<any> | FieldReadFunction<any>
  adminResendAMLWithdrawInfoP01?: FieldPolicy<any> | FieldReadFunction<any>
  adminResendAMLWithdrawInfoP02?: FieldPolicy<any> | FieldReadFunction<any>
  adminResendFiatWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
  adminResendKGIVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminResendWithdrawInfoP01?: FieldPolicy<any> | FieldReadFunction<any>
  adminResendWithdrawInfoP02?: FieldPolicy<any> | FieldReadFunction<any>
  adminResetEDDAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminResetKYCVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminResetNSAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminResetPhone?: FieldPolicy<any> | FieldReadFunction<any>
  adminResetRAAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminSendBGWithdrawInfo?: FieldPolicy<any> | FieldReadFunction<any>
  adminSendInvestorsSettlement?: FieldPolicy<any> | FieldReadFunction<any>
  adminSendMemberInfo?: FieldPolicy<any> | FieldReadFunction<any>
  adminSendWithdrawInfoP01?: FieldPolicy<any> | FieldReadFunction<any>
  adminSendWithdrawInfoP02?: FieldPolicy<any> | FieldReadFunction<any>
  adminSetOldUser?: FieldPolicy<any> | FieldReadFunction<any>
  adminSetTradePermission?: FieldPolicy<any> | FieldReadFunction<any>
  adminSubmitAMLVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminSubmitEDDAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminSubmitKYCOneVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminSubmitKYCTwoVerification?: FieldPolicy<any> | FieldReadFunction<any>
  adminSubmitNSAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminSubmitRAAudit?: FieldPolicy<any> | FieldReadFunction<any>
  adminSystemAuditAML?: FieldPolicy<any> | FieldReadFunction<any>
  adminUpdateCoinInfo?: FieldPolicy<any> | FieldReadFunction<any>
  adminUpdateMarketInfo?: FieldPolicy<any> | FieldReadFunction<any>
  adminUpdateOAuthClient?: FieldPolicy<any> | FieldReadFunction<any>
  adminUpdateQuotationInfo?: FieldPolicy<any> | FieldReadFunction<any>
  adminUploadSuspiciousTransaction?: FieldPolicy<any> | FieldReadFunction<any>
  adminUploadVerificationFile?: FieldPolicy<any> | FieldReadFunction<any>
  adminVIPUpgrade?: FieldPolicy<any> | FieldReadFunction<any>
  adminVerifyKYCIdentity?: FieldPolicy<any> | FieldReadFunction<any>
  adminVoucherCreate?: FieldPolicy<any> | FieldReadFunction<any>
  adminVoucherEdit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminNSAuditResponseKeySpecifier = (
  | 'record'
  | 'result'
  | AdminNSAuditResponseKeySpecifier
)[]
export type AdminNSAuditResponseFieldPolicy = {
  record?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminOnHoldFiatWithdrawalResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminOnHoldFiatWithdrawalResponseKeySpecifier
)[]
export type AdminOnHoldFiatWithdrawalResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminOnHoldKGIVerificationResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminOnHoldKGIVerificationResponseKeySpecifier
)[]
export type AdminOnHoldKGIVerificationResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminRAAuditResponseKeySpecifier = (
  | 'record'
  | 'result'
  | AdminRAAuditResponseKeySpecifier
)[]
export type AdminRAAuditResponseFieldPolicy = {
  record?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminReauditKYCResponseKeySpecifier = ('result' | AdminReauditKYCResponseKeySpecifier)[]
export type AdminReauditKYCResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminRecoverKGIStatusResponseKeySpecifier = (
  | 'result'
  | AdminRecoverKGIStatusResponseKeySpecifier
)[]
export type AdminRecoverKGIStatusResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminRefreshMerchantResponseKeySpecifier = (
  | 'result'
  | AdminRefreshMerchantResponseKeySpecifier
)[]
export type AdminRefreshMerchantResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminRejectFiatWithdrawalResponseKeySpecifier = (
  | 'result'
  | AdminRejectFiatWithdrawalResponseKeySpecifier
)[]
export type AdminRejectFiatWithdrawalResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminRejectKGIVerificationResponseKeySpecifier = (
  | 'result'
  | AdminRejectKGIVerificationResponseKeySpecifier
)[]
export type AdminRejectKGIVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminRejectVirtualAccountVerificationResponseKeySpecifier = (
  | 'result'
  | AdminRejectVirtualAccountVerificationResponseKeySpecifier
)[]
export type AdminRejectVirtualAccountVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResendAMLWithdrawInfoP01ResponseKeySpecifier = (
  | 'result'
  | AdminResendAMLWithdrawInfoP01ResponseKeySpecifier
)[]
export type AdminResendAMLWithdrawInfoP01ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResendAMLWithdrawInfoP02ResponseKeySpecifier = (
  | 'result'
  | AdminResendAMLWithdrawInfoP02ResponseKeySpecifier
)[]
export type AdminResendAMLWithdrawInfoP02ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResendFiatWithdrawalResponseKeySpecifier = (
  | 'result'
  | AdminResendFiatWithdrawalResponseKeySpecifier
)[]
export type AdminResendFiatWithdrawalResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResendKGIVerificationResponseKeySpecifier = (
  | 'result'
  | AdminResendKGIVerificationResponseKeySpecifier
)[]
export type AdminResendKGIVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResendWithdrawInfoP01ResponseKeySpecifier = (
  | 'result'
  | AdminResendWithdrawInfoP01ResponseKeySpecifier
)[]
export type AdminResendWithdrawInfoP01ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResendWithdrawInfoP02ResponseKeySpecifier = (
  | 'result'
  | AdminResendWithdrawInfoP02ResponseKeySpecifier
)[]
export type AdminResendWithdrawInfoP02ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResetKYCVerificationResponseKeySpecifier = (
  | 'result'
  | 'user'
  | AdminResetKYCVerificationResponseKeySpecifier
)[]
export type AdminResetKYCVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminResetPhoneResponseKeySpecifier = (
  | 'result'
  | 'user'
  | AdminResetPhoneResponseKeySpecifier
)[]
export type AdminResetPhoneResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSendBGWithdrawInfoResponseKeySpecifier = (
  | 'result'
  | AdminSendBGWithdrawInfoResponseKeySpecifier
)[]
export type AdminSendBGWithdrawInfoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSendInvestorsSettlementResponseKeySpecifier = (
  | 'result'
  | AdminSendInvestorsSettlementResponseKeySpecifier
)[]
export type AdminSendInvestorsSettlementResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSendMemberInfoResponseKeySpecifier = (
  | 'result'
  | AdminSendMemberInfoResponseKeySpecifier
)[]
export type AdminSendMemberInfoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSendWithdrawInfoP01ResponseKeySpecifier = (
  | 'result'
  | AdminSendWithdrawInfoP01ResponseKeySpecifier
)[]
export type AdminSendWithdrawInfoP01ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSendWithdrawInfoP02ResponseKeySpecifier = (
  | 'result'
  | AdminSendWithdrawInfoP02ResponseKeySpecifier
)[]
export type AdminSendWithdrawInfoP02ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSetOlderUserResponseKeySpecifier = (
  | 'result'
  | AdminSetOlderUserResponseKeySpecifier
)[]
export type AdminSetOlderUserResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSetTradePermissionResponseKeySpecifier = (
  | 'result'
  | AdminSetTradePermissionResponseKeySpecifier
)[]
export type AdminSetTradePermissionResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSubmitAMLVerificationResponseKeySpecifier = (
  | 'result'
  | 'user'
  | AdminSubmitAMLVerificationResponseKeySpecifier
)[]
export type AdminSubmitAMLVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSubmitKYCOneVerificationResponseKeySpecifier = (
  | 'result'
  | 'user'
  | AdminSubmitKYCOneVerificationResponseKeySpecifier
)[]
export type AdminSubmitKYCOneVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSubmitKYCTwoVerificationResponseKeySpecifier = (
  | 'result'
  | 'user'
  | AdminSubmitKYCTwoVerificationResponseKeySpecifier
)[]
export type AdminSubmitKYCTwoVerificationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSubmitRAAuditResponseKeySpecifier = (
  | 'record'
  | 'result'
  | 'userAssessmentResult'
  | AdminSubmitRAAuditResponseKeySpecifier
)[]
export type AdminSubmitRAAuditResponseFieldPolicy = {
  record?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
  userAssessmentResult?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminSystemAuditAMLResponseKeySpecifier = (
  | 'result'
  | AdminSystemAuditAMLResponseKeySpecifier
)[]
export type AdminSystemAuditAMLResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminTradeResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | AdminTradeResponseKeySpecifier
)[]
export type AdminTradeResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminTradingReportResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminTradingReportResponseKeySpecifier
)[]
export type AdminTradingReportResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUpdateCoinInfoResponseKeySpecifier = (
  | 'result'
  | AdminUpdateCoinInfoResponseKeySpecifier
)[]
export type AdminUpdateCoinInfoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUpdateMarketInfoResponseKeySpecifier = (
  | 'result'
  | AdminUpdateMarketInfoResponseKeySpecifier
)[]
export type AdminUpdateMarketInfoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUpdateOAuthClientResponseKeySpecifier = (
  | 'result'
  | AdminUpdateOAuthClientResponseKeySpecifier
)[]
export type AdminUpdateOAuthClientResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUpdateQuotationInfoResponseKeySpecifier = (
  | 'result'
  | AdminUpdateQuotationInfoResponseKeySpecifier
)[]
export type AdminUpdateQuotationInfoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUploadSuspiciousTransactionResponseKeySpecifier = (
  | 'result'
  | AdminUploadSuspiciousTransactionResponseKeySpecifier
)[]
export type AdminUploadSuspiciousTransactionResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUploadVerificationFileResponseKeySpecifier = (
  | 'result'
  | AdminUploadVerificationFileResponseKeySpecifier
)[]
export type AdminUploadVerificationFileResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminUserResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | AdminUserResponseKeySpecifier
)[]
export type AdminUserResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminVIPUpdateResponseKeySpecifier = ('result' | AdminVIPUpdateResponseKeySpecifier)[]
export type AdminVIPUpdateResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminVerifyKYCIdentityResponseKeySpecifier = (
  | 'result'
  | AdminVerifyKYCIdentityResponseKeySpecifier
)[]
export type AdminVerifyKYCIdentityResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminVoucherRecordsResponseKeySpecifier = (
  | 'meta'
  | 'records'
  | 'result'
  | AdminVoucherRecordsResponseKeySpecifier
)[]
export type AdminVoucherRecordsResponseFieldPolicy = {
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  records?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminWithdrawalReportResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AdminWithdrawalReportResponseKeySpecifier
)[]
export type AdminWithdrawalReportResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AdminWithdrawalResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | AdminWithdrawalResponseKeySpecifier
)[]
export type AdminWithdrawalResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ApproveKYCLv2ResponseKeySpecifier = ('result' | ApproveKYCLv2ResponseKeySpecifier)[]
export type ApproveKYCLv2ResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AssetKeySpecifier = (
  | 'contentType'
  | 'contentfulMetadata'
  | 'description'
  | 'fileName'
  | 'height'
  | 'linkedFrom'
  | 'size'
  | 'sys'
  | 'title'
  | 'url'
  | 'width'
  | AssetKeySpecifier
)[]
export type AssetFieldPolicy = {
  contentType?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  fileName?: FieldPolicy<any> | FieldReadFunction<any>
  height?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AssetCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | AssetCollectionKeySpecifier
)[]
export type AssetCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AssetLinkingCollectionsKeySpecifier = (
  | 'displayItemCollection'
  | 'entryCollection'
  | 'heroSliderCollection'
  | 'popUpCollection'
  | AssetLinkingCollectionsKeySpecifier
)[]
export type AssetLinkingCollectionsFieldPolicy = {
  displayItemCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  heroSliderCollection?: FieldPolicy<any> | FieldReadFunction<any>
  popUpCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AttributesKeySpecifier = ('traitType' | 'value' | AttributesKeySpecifier)[]
export type AttributesFieldPolicy = {
  traitType?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Auth2FAKeySpecifier = ('qrCode' | 'secret' | 'token2FA' | Auth2FAKeySpecifier)[]
export type Auth2FAFieldPolicy = {
  qrCode?: FieldPolicy<any> | FieldReadFunction<any>
  secret?: FieldPolicy<any> | FieldReadFunction<any>
  token2FA?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthBind2FAResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AuthBind2FAResponseKeySpecifier
)[]
export type AuthBind2FAResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthConfirm2FAResponseKeySpecifier = (
  | 'data'
  | 'result'
  | AuthConfirm2FAResponseKeySpecifier
)[]
export type AuthConfirm2FAResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthMutationKeySpecifier = (
  | 'authBind2FA'
  | 'authConfirm2FA'
  | 'authUnbind2FA'
  | AuthMutationKeySpecifier
)[]
export type AuthMutationFieldPolicy = {
  authBind2FA?: FieldPolicy<any> | FieldReadFunction<any>
  authConfirm2FA?: FieldPolicy<any> | FieldReadFunction<any>
  authUnbind2FA?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthUnbind2FAResponseKeySpecifier = ('result' | AuthUnbind2FAResponseKeySpecifier)[]
export type AuthUnbind2FAResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BalanceKeySpecifier = (
  | 'available'
  | 'currency'
  | 'frozen'
  | 'total'
  | 'type'
  | BalanceKeySpecifier
)[]
export type BalanceFieldPolicy = {
  available?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  frozen?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BankKeySpecifier = ('branches' | 'code' | 'name' | BankKeySpecifier)[]
export type BankFieldPolicy = {
  branches?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BankAccountKeySpecifier = (
  | 'account'
  | 'accountHolder'
  | 'bankCode'
  | 'bankName'
  | 'branchCode'
  | 'branchName'
  | 'swiftCode'
  | BankAccountKeySpecifier
)[]
export type BankAccountFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>
  accountHolder?: FieldPolicy<any> | FieldReadFunction<any>
  bankCode?: FieldPolicy<any> | FieldReadFunction<any>
  bankName?: FieldPolicy<any> | FieldReadFunction<any>
  branchCode?: FieldPolicy<any> | FieldReadFunction<any>
  branchName?: FieldPolicy<any> | FieldReadFunction<any>
  swiftCode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BankBranchKeySpecifier = ('code' | 'name' | BankBranchKeySpecifier)[]
export type BankBranchFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BankInfoKeySpecifier = (
  | 'account'
  | 'accountHolder'
  | 'bankCode'
  | 'branchCode'
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | BankInfoKeySpecifier
)[]
export type BankInfoFieldPolicy = {
  account?: FieldPolicy<any> | FieldReadFunction<any>
  accountHolder?: FieldPolicy<any> | FieldReadFunction<any>
  bankCode?: FieldPolicy<any> | FieldReadFunction<any>
  branchCode?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BaseDepositKeySpecifier = (
  | 'amount'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'id'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | BaseDepositKeySpecifier
)[]
export type BaseDepositFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BaseResponseKeySpecifier = ('result' | BaseResponseKeySpecifier)[]
export type BaseResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BaseWithdrawalKeySpecifier = (
  | 'amount'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'fee'
  | 'feeCurrency'
  | 'id'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | BaseWithdrawalKeySpecifier
)[]
export type BaseWithdrawalFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  feeCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BasicInfoKeySpecifier = (
  | 'address'
  | 'birthday'
  | 'createdAt'
  | 'gender'
  | 'id'
  | 'industry'
  | 'jobTitle'
  | 'name'
  | 'updatedAt'
  | BasicInfoKeySpecifier
)[]
export type BasicInfoFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  birthday?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  gender?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  industry?: FieldPolicy<any> | FieldReadFunction<any>
  jobTitle?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CefiDepositAddressKeySpecifier = (
  | 'address'
  | 'chain'
  | CefiDepositAddressKeySpecifier
)[]
export type CefiDepositAddressFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CefiWalletKeySpecifier = ('exchange' | 'walletBalances' | CefiWalletKeySpecifier)[]
export type CefiWalletFieldPolicy = {
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  walletBalances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChartResponseKeySpecifier = ('data' | 'result' | ChartResponseKeySpecifier)[]
export type ChartResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CoinPriceKeySpecifier = ('symbol' | 'usdtPrice' | CoinPriceKeySpecifier)[]
export type CoinPriceFieldPolicy = {
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  usdtPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CommonAddressKeySpecifier = ('address' | 'id' | 'name' | CommonAddressKeySpecifier)[]
export type CommonAddressFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CommonAddressResponseKeySpecifier = (
  | 'data'
  | 'result'
  | CommonAddressResponseKeySpecifier
)[]
export type CommonAddressResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ContentfulMetadataKeySpecifier = ('tags' | ContentfulMetadataKeySpecifier)[]
export type ContentfulMetadataFieldPolicy = {
  tags?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ContentfulTagKeySpecifier = ('id' | 'name' | ContentfulTagKeySpecifier)[]
export type ContentfulTagFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CountryKeySpecifier = ('callingCode' | 'code' | CountryKeySpecifier)[]
export type CountryFieldPolicy = {
  callingCode?: FieldPolicy<any> | FieldReadFunction<any>
  code?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreatorKeySpecifier = ('address' | 'id' | CreatorKeySpecifier)[]
export type CreatorFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CryptoAssetKeySpecifier = ('asset' | CryptoAssetKeySpecifier)[]
export type CryptoAssetFieldPolicy = {
  asset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CryptoDepositKeySpecifier = (
  | 'amount'
  | 'chain'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'fromAddress'
  | 'id'
  | 'status'
  | 'toAddress'
  | 'txID'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | CryptoDepositKeySpecifier
)[]
export type CryptoDepositFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  fromAddress?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  toAddress?: FieldPolicy<any> | FieldReadFunction<any>
  txID?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CryptoWithdrawalKeySpecifier = (
  | 'amount'
  | 'chain'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'fee'
  | 'feeCurrency'
  | 'fromAddress'
  | 'id'
  | 'isDeduction'
  | 'status'
  | 'toAddress'
  | 'txID'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | CryptoWithdrawalKeySpecifier
)[]
export type CryptoWithdrawalFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  feeCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  fromAddress?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isDeduction?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  toAddress?: FieldPolicy<any> | FieldReadFunction<any>
  txID?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyAmountKeySpecifier = ('amount' | 'currency' | CurrencyAmountKeySpecifier)[]
export type CurrencyAmountFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyBalanceKeySpecifier = ('available' | 'total' | CurrencyBalanceKeySpecifier)[]
export type CurrencyBalanceFieldPolicy = {
  available?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyBalancesKeySpecifier = ('balance' | 'currency' | CurrencyBalancesKeySpecifier)[]
export type CurrencyBalancesFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CurrencyInfoKeySpecifier = ('decimals' | 'name' | 'symbol' | CurrencyInfoKeySpecifier)[]
export type CurrencyInfoFieldPolicy = {
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DefiWalletKeySpecifier = (
  | 'balance'
  | 'balanceComputed'
  | 'balanceComputedWithUnit'
  | 'chain'
  | 'currencyInfo'
  | 'iconifySrc'
  | 'id'
  | DefiWalletKeySpecifier
)[]
export type DefiWalletFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  balanceComputed?: FieldPolicy<any> | FieldReadFunction<any>
  balanceComputedWithUnit?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  currencyInfo?: FieldPolicy<any> | FieldReadFunction<any>
  iconifySrc?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DepositResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | DepositResponseKeySpecifier
)[]
export type DepositResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DevMutationKeySpecifier = (
  | 'acceptFiatWithdrawal'
  | 'approveKYCLv2'
  | 'rejectFiatWithdrawal'
  | DevMutationKeySpecifier
)[]
export type DevMutationFieldPolicy = {
  acceptFiatWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
  approveKYCLv2?: FieldPolicy<any> | FieldReadFunction<any>
  rejectFiatWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DisplayItemKeySpecifier = (
  | 'contentfulMetadata'
  | 'defaultSharingBackground'
  | 'enableSpecialSharingBackgroundsMode'
  | 'forUsersCollection'
  | 'linkedFrom'
  | 'purpose'
  | 'specialSharingBackgroundsCollection'
  | 'sys'
  | 'title'
  | 'userProfileImage'
  | DisplayItemKeySpecifier
)[]
export type DisplayItemFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  defaultSharingBackground?: FieldPolicy<any> | FieldReadFunction<any>
  enableSpecialSharingBackgroundsMode?: FieldPolicy<any> | FieldReadFunction<any>
  forUsersCollection?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  purpose?: FieldPolicy<any> | FieldReadFunction<any>
  specialSharingBackgroundsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  userProfileImage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DisplayItemCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | DisplayItemCollectionKeySpecifier
)[]
export type DisplayItemCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DisplayItemForUsersCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | DisplayItemForUsersCollectionKeySpecifier
)[]
export type DisplayItemForUsersCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DisplayItemLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | DisplayItemLinkingCollectionsKeySpecifier
)[]
export type DisplayItemLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EntryKeySpecifier = ('contentfulMetadata' | 'sys' | EntryKeySpecifier)[]
export type EntryFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EntryCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | EntryCollectionKeySpecifier
)[]
export type EntryCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAPIKeyKeySpecifier = (
  | 'createdAt'
  | 'exchange'
  | 'id'
  | 'isRevoked'
  | 'key'
  | 'name'
  | 'user'
  | 'userId'
  | ExternalAPIKeyKeySpecifier
)[]
export type ExternalAPIKeyFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isRevoked?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAPIKeyWithdrawalKeySpecifier = ('id' | ExternalAPIKeyWithdrawalKeySpecifier)[]
export type ExternalAPIKeyWithdrawalFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FAASPaymentKeySpecifier = (
  | 'createdAt'
  | 'cryptoWithdrawal'
  | 'id'
  | 'internalTransferOut'
  | 'merchantID'
  | 'updatedAt'
  | FAASPaymentKeySpecifier
)[]
export type FAASPaymentFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  cryptoWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  internalTransferOut?: FieldPolicy<any> | FieldReadFunction<any>
  merchantID?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FAASPaymentResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | FAASPaymentResponseKeySpecifier
)[]
export type FAASPaymentResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FiatDepositKeySpecifier = (
  | 'amount'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'id'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | FiatDepositKeySpecifier
)[]
export type FiatDepositFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FiatDepositInfoKeySpecifier = (
  | 'dailyAccumulated'
  | 'monthlyAccumulated'
  | 'userBindBank'
  | 'virtualBank'
  | FiatDepositInfoKeySpecifier
)[]
export type FiatDepositInfoFieldPolicy = {
  dailyAccumulated?: FieldPolicy<any> | FieldReadFunction<any>
  monthlyAccumulated?: FieldPolicy<any> | FieldReadFunction<any>
  userBindBank?: FieldPolicy<any> | FieldReadFunction<any>
  virtualBank?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FiatWithdrawalKeySpecifier = (
  | 'amount'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'fee'
  | 'feeCurrency'
  | 'id'
  | 'status'
  | 'toBankAccount'
  | 'toBankAccountID'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | FiatWithdrawalKeySpecifier
)[]
export type FiatWithdrawalFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  feeCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  toBankAccount?: FieldPolicy<any> | FieldReadFunction<any>
  toBankAccountID?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FrontendVersionKeySpecifier = (
  | 'commitHash'
  | 'smartContracts'
  | 'version'
  | FrontendVersionKeySpecifier
)[]
export type FrontendVersionFieldPolicy = {
  commitHash?: FieldPolicy<any> | FieldReadFunction<any>
  smartContracts?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GimCoolPayPaymentInfoKeySpecifier = (
  | 'blockchain'
  | 'coinAmount'
  | 'coinType'
  | 'fiatAmount'
  | 'fiatType'
  | 'paymentHref'
  | 'price'
  | 'walletAddress'
  | 'walletAddressTag'
  | GimCoolPayPaymentInfoKeySpecifier
)[]
export type GimCoolPayPaymentInfoFieldPolicy = {
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>
  coinAmount?: FieldPolicy<any> | FieldReadFunction<any>
  coinType?: FieldPolicy<any> | FieldReadFunction<any>
  fiatAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatType?: FieldPolicy<any> | FieldReadFunction<any>
  paymentHref?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  walletAddress?: FieldPolicy<any> | FieldReadFunction<any>
  walletAddressTag?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GimCoolPayPriceResponseKeySpecifier = (
  | 'prices'
  | 'spotPrice'
  | GimCoolPayPriceResponseKeySpecifier
)[]
export type GimCoolPayPriceResponseFieldPolicy = {
  prices?: FieldPolicy<any> | FieldReadFunction<any>
  spotPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GimCoolPayReadyToPurchasePriceKeySpecifier = (
  | 'coinAmount'
  | 'coinCode'
  | 'feeAmount'
  | 'fiatAmount'
  | 'fiatCode'
  | 'networkFee'
  | 'paymentMethodId'
  | 'spotPriceFee'
  | 'spotPriceIncludingFee'
  | 'type'
  | GimCoolPayReadyToPurchasePriceKeySpecifier
)[]
export type GimCoolPayReadyToPurchasePriceFieldPolicy = {
  coinAmount?: FieldPolicy<any> | FieldReadFunction<any>
  coinCode?: FieldPolicy<any> | FieldReadFunction<any>
  feeAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatCode?: FieldPolicy<any> | FieldReadFunction<any>
  networkFee?: FieldPolicy<any> | FieldReadFunction<any>
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>
  spotPriceFee?: FieldPolicy<any> | FieldReadFunction<any>
  spotPriceIncludingFee?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HeroSliderKeySpecifier = (
  | 'contentfulMetadata'
  | 'image'
  | 'link'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | HeroSliderKeySpecifier
)[]
export type HeroSliderFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  image?: FieldPolicy<any> | FieldReadFunction<any>
  link?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HeroSliderCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | HeroSliderCollectionKeySpecifier
)[]
export type HeroSliderCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HeroSliderLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | HeroSliderLinkingCollectionsKeySpecifier
)[]
export type HeroSliderLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HolidayKeySpecifier = ('category' | 'date' | 'name' | HolidayKeySpecifier)[]
export type HolidayFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>
  date?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IdentityInfoKeySpecifier = (
  | 'barcodeNumber'
  | 'createdAt'
  | 'expirationDate'
  | 'id'
  | 'idNumber'
  | 'idType'
  | 'issuanceDate'
  | 'issuanceLocation'
  | 'issuanceType'
  | 'nationality'
  | 'updatedAt'
  | 'verifyStatus'
  | IdentityInfoKeySpecifier
)[]
export type IdentityInfoFieldPolicy = {
  barcodeNumber?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  expirationDate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  idNumber?: FieldPolicy<any> | FieldReadFunction<any>
  idType?: FieldPolicy<any> | FieldReadFunction<any>
  issuanceDate?: FieldPolicy<any> | FieldReadFunction<any>
  issuanceLocation?: FieldPolicy<any> | FieldReadFunction<any>
  issuanceType?: FieldPolicy<any> | FieldReadFunction<any>
  nationality?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  verifyStatus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InternalTransferInKeySpecifier = (
  | 'amount'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'fromUserID'
  | 'id'
  | 'status'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | InternalTransferInKeySpecifier
)[]
export type InternalTransferInFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  fromUserID?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InternalTransferOutKeySpecifier = (
  | 'amount'
  | 'completedAt'
  | 'createdAt'
  | 'currency'
  | 'fee'
  | 'feeCurrency'
  | 'id'
  | 'status'
  | 'toAddress'
  | 'toUserID'
  | 'type'
  | 'updatedAt'
  | 'userID'
  | InternalTransferOutKeySpecifier
)[]
export type InternalTransferOutFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  feeCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  toAddress?: FieldPolicy<any> | FieldReadFunction<any>
  toUserID?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCKeySpecifier = ('aml' | 'lv1' | 'lv2' | KYCKeySpecifier)[]
export type KYCFieldPolicy = {
  aml?: FieldPolicy<any> | FieldReadFunction<any>
  lv1?: FieldPolicy<any> | FieldReadFunction<any>
  lv2?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCConfirmSubmissionResponseKeySpecifier = (
  | 'result'
  | KYCConfirmSubmissionResponseKeySpecifier
)[]
export type KYCConfirmSubmissionResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCLevelOneKeySpecifier = (
  | 'addressPhoto'
  | 'basicInfo'
  | 'createdAt'
  | 'id'
  | 'idBackPhoto'
  | 'idFrontPhoto'
  | 'identityInfo'
  | 'isReSubmitted'
  | 'questions'
  | 'secondIDPhoto'
  | 'selfiePhoto'
  | 'status'
  | 'updatedAt'
  | 'verifier'
  | KYCLevelOneKeySpecifier
)[]
export type KYCLevelOneFieldPolicy = {
  addressPhoto?: FieldPolicy<any> | FieldReadFunction<any>
  basicInfo?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  idBackPhoto?: FieldPolicy<any> | FieldReadFunction<any>
  idFrontPhoto?: FieldPolicy<any> | FieldReadFunction<any>
  identityInfo?: FieldPolicy<any> | FieldReadFunction<any>
  isReSubmitted?: FieldPolicy<any> | FieldReadFunction<any>
  questions?: FieldPolicy<any> | FieldReadFunction<any>
  secondIDPhoto?: FieldPolicy<any> | FieldReadFunction<any>
  selfiePhoto?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  verifier?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCLevelTwoKeySpecifier = (
  | 'bankInfo'
  | 'bankPhoto'
  | 'createdAt'
  | 'id'
  | 'isReSubmitted'
  | 'status'
  | 'updatedAt'
  | 'verifier'
  | KYCLevelTwoKeySpecifier
)[]
export type KYCLevelTwoFieldPolicy = {
  bankInfo?: FieldPolicy<any> | FieldReadFunction<any>
  bankPhoto?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isReSubmitted?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  verifier?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCMutationKeySpecifier = (
  | 'kycConfirmSubmission'
  | 'kycSubmitLevelOne'
  | 'kycSubmitLevelTwo'
  | KYCMutationKeySpecifier
)[]
export type KYCMutationFieldPolicy = {
  kycConfirmSubmission?: FieldPolicy<any> | FieldReadFunction<any>
  kycSubmitLevelOne?: FieldPolicy<any> | FieldReadFunction<any>
  kycSubmitLevelTwo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCPhotoKeySpecifier = (
  | 'createdAt'
  | 'firstVerification'
  | 'id'
  | 'rejectedReason'
  | 'resourceURL'
  | 'secondVerification'
  | 'status'
  | 'updatedAt'
  | KYCPhotoKeySpecifier
)[]
export type KYCPhotoFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  firstVerification?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  rejectedReason?: FieldPolicy<any> | FieldReadFunction<any>
  resourceURL?: FieldPolicy<any> | FieldReadFunction<any>
  secondVerification?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCPhotoVerificationKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'isVerified'
  | 'rejectedReason'
  | 'updatedAt'
  | 'verifier'
  | KYCPhotoVerificationKeySpecifier
)[]
export type KYCPhotoVerificationFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isVerified?: FieldPolicy<any> | FieldReadFunction<any>
  rejectedReason?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  verifier?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCSubmitLevelOneResponseKeySpecifier = (
  | 'result'
  | KYCSubmitLevelOneResponseKeySpecifier
)[]
export type KYCSubmitLevelOneResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KYCSubmitLevelTwoResponseKeySpecifier = (
  | 'result'
  | KYCSubmitLevelTwoResponseKeySpecifier
)[]
export type KYCSubmitLevelTwoResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KgiUploadResponseKeySpecifier = (
  | 'Code'
  | 'Desc'
  | 'Filename'
  | 'Status'
  | KgiUploadResponseKeySpecifier
)[]
export type KgiUploadResponseFieldPolicy = {
  Code?: FieldPolicy<any> | FieldReadFunction<any>
  Desc?: FieldPolicy<any> | FieldReadFunction<any>
  Filename?: FieldPolicy<any> | FieldReadFunction<any>
  Status?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageKeySpecifier = (
  | 'contentfulMetadata'
  | 'customMessage'
  | 'displayEnvironment'
  | 'email'
  | 'endTime'
  | 'linkedFrom'
  | 'startTime'
  | 'sys'
  | LandingPageCustomMessageKeySpecifier
)[]
export type LandingPageCustomMessageFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  customMessage?: FieldPolicy<any> | FieldReadFunction<any>
  displayEnvironment?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  endTime?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  startTime?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | LandingPageCustomMessageCollectionKeySpecifier
)[]
export type LandingPageCustomMessageCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageCustomMessageKeySpecifier = (
  | 'json'
  | 'links'
  | LandingPageCustomMessageCustomMessageKeySpecifier
)[]
export type LandingPageCustomMessageCustomMessageFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageCustomMessageAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | LandingPageCustomMessageCustomMessageAssetsKeySpecifier
)[]
export type LandingPageCustomMessageCustomMessageAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageCustomMessageEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | LandingPageCustomMessageCustomMessageEntriesKeySpecifier
)[]
export type LandingPageCustomMessageCustomMessageEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageCustomMessageLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | LandingPageCustomMessageCustomMessageLinksKeySpecifier
)[]
export type LandingPageCustomMessageCustomMessageLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageCustomMessageResourcesKeySpecifier = (
  | 'block'
  | LandingPageCustomMessageCustomMessageResourcesKeySpecifier
)[]
export type LandingPageCustomMessageCustomMessageResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LandingPageCustomMessageLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | LandingPageCustomMessageLinkingCollectionsKeySpecifier
)[]
export type LandingPageCustomMessageLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LedgerKeySpecifier = (
  | 'amount'
  | 'balance'
  | 'createdAt'
  | 'currency'
  | 'id'
  | 'ledgerEntry'
  | 'updatedAt'
  | 'userID'
  | LedgerKeySpecifier
)[]
export type LedgerFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  ledgerEntry?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LedgerResponseKeySpecifier = ('data' | 'meta' | 'result' | LedgerResponseKeySpecifier)[]
export type LedgerResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LoginInfoKeySpecifier = (
  | 'accessToken'
  | 'expiresIn'
  | 'refreshToken'
  | LoginInfoKeySpecifier
)[]
export type LoginInfoFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>
  expiresIn?: FieldPolicy<any> | FieldReadFunction<any>
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MarketInfoKeySpecifier = (
  | 'feeRate'
  | 'isTradable'
  | 'market'
  | 'maxSize'
  | 'minSize'
  | 'priceIncrement'
  | 'sizeIncrement'
  | MarketInfoKeySpecifier
)[]
export type MarketInfoFieldPolicy = {
  feeRate?: FieldPolicy<any> | FieldReadFunction<any>
  isTradable?: FieldPolicy<any> | FieldReadFunction<any>
  market?: FieldPolicy<any> | FieldReadFunction<any>
  maxSize?: FieldPolicy<any> | FieldReadFunction<any>
  minSize?: FieldPolicy<any> | FieldReadFunction<any>
  priceIncrement?: FieldPolicy<any> | FieldReadFunction<any>
  sizeIncrement?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MarketPriceKeySpecifier = (
  | 'ask'
  | 'bid'
  | 'market'
  | 'time'
  | 'type'
  | MarketPriceKeySpecifier
)[]
export type MarketPriceFieldPolicy = {
  ask?: FieldPolicy<any> | FieldReadFunction<any>
  bid?: FieldPolicy<any> | FieldReadFunction<any>
  market?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MeKeySpecifier = ('id' | 'information' | MeKeySpecifier)[]
export type MeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  information?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetaPaginationKeySpecifier = ('total' | MetaPaginationKeySpecifier)[]
export type MetaPaginationFieldPolicy = {
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetricKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'positionManagersInfo'
  | 'positions'
  | MetricKeySpecifier
)[]
export type MetricFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  positionManagersInfo?: FieldPolicy<any> | FieldReadFunction<any>
  positions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetricPositionKeySpecifier = (
  | 'amountUsd'
  | 'chain'
  | 'createdAt'
  | 'feeUsd'
  | 'id'
  | 'positionId'
  | 'positionIdTotalCount'
  | 'positionIsRunning'
  | 'positionManagerContractAddress'
  | 'positionOwnerAddress'
  | 'strategyId'
  | 'strategyProvider'
  | 'sumUsd'
  | MetricPositionKeySpecifier
)[]
export type MetricPositionFieldPolicy = {
  amountUsd?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  feeUsd?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdTotalCount?: FieldPolicy<any> | FieldReadFunction<any>
  positionIsRunning?: FieldPolicy<any> | FieldReadFunction<any>
  positionManagerContractAddress?: FieldPolicy<any> | FieldReadFunction<any>
  positionOwnerAddress?: FieldPolicy<any> | FieldReadFunction<any>
  strategyId?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProvider?: FieldPolicy<any> | FieldReadFunction<any>
  sumUsd?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MutationKeySpecifier = (
  | 'closeUniswapv3Position'
  | 'createAICompletionRecord'
  | 'createUniswapv3Position'
  | 'createUniswapv3Tool'
  | 'externalAPIKeyCreate'
  | 'externalAPIKeyDelete'
  | 'generateMetrics'
  | 'strategyStart'
  | 'strategyStop'
  | 'strategyUpdate'
  | MutationKeySpecifier
)[]
export type MutationFieldPolicy = {
  closeUniswapv3Position?: FieldPolicy<any> | FieldReadFunction<any>
  createAICompletionRecord?: FieldPolicy<any> | FieldReadFunction<any>
  createUniswapv3Position?: FieldPolicy<any> | FieldReadFunction<any>
  createUniswapv3Tool?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyCreate?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyDelete?: FieldPolicy<any> | FieldReadFunction<any>
  generateMetrics?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStart?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStop?: FieldPolicy<any> | FieldReadFunction<any>
  strategyUpdate?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NFTCollectionsKeySpecifier = (
  | 'attributes'
  | 'contract'
  | 'description'
  | 'imageUrl'
  | 'name'
  | 'tokenId'
  | NFTCollectionsKeySpecifier
)[]
export type NFTCollectionsFieldPolicy = {
  attributes?: FieldPolicy<any> | FieldReadFunction<any>
  contract?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NFTMerkleTreeHexProofKeySpecifier = ('hexProof' | NFTMerkleTreeHexProofKeySpecifier)[]
export type NFTMerkleTreeHexProofFieldPolicy = {
  hexProof?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NFTWhitelistInfoByPhraseKeySpecifier = (
  | 'id'
  | 'isWhitelisted'
  | 'phrase'
  | 'userAddress'
  | NFTWhitelistInfoByPhraseKeySpecifier
)[]
export type NFTWhitelistInfoByPhraseFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isWhitelisted?: FieldPolicy<any> | FieldReadFunction<any>
  phrase?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationKeySpecifier = (
  | 'notificationCount'
  | 'notificationList'
  | NotificationKeySpecifier
)[]
export type NotificationFieldPolicy = {
  notificationCount?: FieldPolicy<any> | FieldReadFunction<any>
  notificationList?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationCountKeySpecifier = ('num' | NotificationCountKeySpecifier)[]
export type NotificationCountFieldPolicy = {
  num?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationCountResponseKeySpecifier = (
  | 'data'
  | 'result'
  | NotificationCountResponseKeySpecifier
)[]
export type NotificationCountResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationInfoKeySpecifier = (
  | 'id'
  | 'isRead'
  | 'link'
  | 'message'
  | 'productName'
  | 'timestamp'
  | 'title'
  | NotificationInfoKeySpecifier
)[]
export type NotificationInfoFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isRead?: FieldPolicy<any> | FieldReadFunction<any>
  link?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  productName?: FieldPolicy<any> | FieldReadFunction<any>
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationListResponseKeySpecifier = (
  | 'data'
  | 'result'
  | NotificationListResponseKeySpecifier
)[]
export type NotificationListResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationMutationKeySpecifier = (
  | 'notificationRead'
  | NotificationMutationKeySpecifier
)[]
export type NotificationMutationFieldPolicy = {
  notificationRead?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotificationReadResponseKeySpecifier = (
  | 'result'
  | NotificationReadResponseKeySpecifier
)[]
export type NotificationReadResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OnHoldKGIVerificationKeySpecifier = (
  | 'filename'
  | 'id'
  | 'status'
  | 'txID'
  | 'userID'
  | OnHoldKGIVerificationKeySpecifier
)[]
export type OnHoldKGIVerificationFieldPolicy = {
  filename?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  txID?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PointKeySpecifier = ('ledgers' | 'quantity' | PointKeySpecifier)[]
export type PointFieldPolicy = {
  ledgers?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PointLedgerRecordKeySpecifier = (
  | 'description'
  | 'id'
  | 'points'
  | 'productID'
  | 'time'
  | 'total'
  | 'type'
  | PointLedgerRecordKeySpecifier
)[]
export type PointLedgerRecordFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  points?: FieldPolicy<any> | FieldReadFunction<any>
  productID?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PointLedgerRecordResponseKeySpecifier = (
  | 'data'
  | 'result'
  | PointLedgerRecordResponseKeySpecifier
)[]
export type PointLedgerRecordResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PointMutationKeySpecifier = ('pointPurchase' | PointMutationKeySpecifier)[]
export type PointMutationFieldPolicy = {
  pointPurchase?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PointPurchaseResponseKeySpecifier = (
  | 'number'
  | 'price'
  | 'result'
  | 'total'
  | PointPurchaseResponseKeySpecifier
)[]
export type PointPurchaseResponseFieldPolicy = {
  number?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpKeySpecifier = (
  | 'backgroundColor'
  | 'callToActionButtonLink'
  | 'callToActionButtonText'
  | 'callToActionButtonVariant'
  | 'closeButtonText'
  | 'closeButtonVariant'
  | 'contentArea'
  | 'contentfulMetadata'
  | 'displayEnvironment'
  | 'displayMode'
  | 'endTime'
  | 'imageDesktop'
  | 'imageModile'
  | 'linkedFrom'
  | 'priority'
  | 'startTime'
  | 'sys'
  | 'title'
  | PopUpKeySpecifier
)[]
export type PopUpFieldPolicy = {
  backgroundColor?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionButtonLink?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionButtonText?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionButtonVariant?: FieldPolicy<any> | FieldReadFunction<any>
  closeButtonText?: FieldPolicy<any> | FieldReadFunction<any>
  closeButtonVariant?: FieldPolicy<any> | FieldReadFunction<any>
  contentArea?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  displayEnvironment?: FieldPolicy<any> | FieldReadFunction<any>
  displayMode?: FieldPolicy<any> | FieldReadFunction<any>
  endTime?: FieldPolicy<any> | FieldReadFunction<any>
  imageDesktop?: FieldPolicy<any> | FieldReadFunction<any>
  imageModile?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  priority?: FieldPolicy<any> | FieldReadFunction<any>
  startTime?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | PopUpCollectionKeySpecifier
)[]
export type PopUpCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpContentAreaKeySpecifier = ('json' | 'links' | PopUpContentAreaKeySpecifier)[]
export type PopUpContentAreaFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpContentAreaAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | PopUpContentAreaAssetsKeySpecifier
)[]
export type PopUpContentAreaAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpContentAreaEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | PopUpContentAreaEntriesKeySpecifier
)[]
export type PopUpContentAreaEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpContentAreaLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | PopUpContentAreaLinksKeySpecifier
)[]
export type PopUpContentAreaLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpContentAreaResourcesKeySpecifier = (
  | 'block'
  | PopUpContentAreaResourcesKeySpecifier
)[]
export type PopUpContentAreaResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PopUpLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | PopUpLinkingCollectionsKeySpecifier
)[]
export type PopUpLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PositionManagerInfoKeySpecifier = (
  | 'chain'
  | 'count'
  | 'id'
  | PositionManagerInfoKeySpecifier
)[]
export type PositionManagerInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PublicKeySpecifier = (
  | 'banks'
  | 'chart'
  | 'countries'
  | 'cryptoAsset'
  | 'keyOwnerID'
  | 'marketInfo'
  | 'marketPrice'
  | 'questionnaire'
  | 'version'
  | PublicKeySpecifier
)[]
export type PublicFieldPolicy = {
  banks?: FieldPolicy<any> | FieldReadFunction<any>
  chart?: FieldPolicy<any> | FieldReadFunction<any>
  countries?: FieldPolicy<any> | FieldReadFunction<any>
  cryptoAsset?: FieldPolicy<any> | FieldReadFunction<any>
  keyOwnerID?: FieldPolicy<any> | FieldReadFunction<any>
  marketInfo?: FieldPolicy<any> | FieldReadFunction<any>
  marketPrice?: FieldPolicy<any> | FieldReadFunction<any>
  questionnaire?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
  | 'coinPrices'
  | 'me'
  | 'metric'
  | 'poolsWithArguments'
  | 'rateHistorical'
  | 'strategies'
  | 'tools'
  | 'uniswapQuote'
  | 'uniswapTokens'
  | 'uniswapV3Backtest'
  | 'version'
  | 'wallet'
  | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
  coinPrices?: FieldPolicy<any> | FieldReadFunction<any>
  me?: FieldPolicy<any> | FieldReadFunction<any>
  metric?: FieldPolicy<any> | FieldReadFunction<any>
  poolsWithArguments?: FieldPolicy<any> | FieldReadFunction<any>
  rateHistorical?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  tools?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapQuote?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapTokens?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapV3Backtest?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QuestionKeySpecifier = (
  | 'description'
  | 'id'
  | 'options'
  | 'type'
  | QuestionKeySpecifier
)[]
export type QuestionFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  options?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QuestionInfoKeySpecifier = (
  | 'annualIncome'
  | 'anotherNationality'
  | 'createdAt'
  | 'id'
  | 'period'
  | 'purpose'
  | 'updatedAt'
  | QuestionInfoKeySpecifier
)[]
export type QuestionInfoFieldPolicy = {
  annualIncome?: FieldPolicy<any> | FieldReadFunction<any>
  anotherNationality?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  purpose?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QuestionOptionKeySpecifier = (
  | 'description'
  | 'hasRemark'
  | 'id'
  | 'isSelected'
  | 'remark'
  | QuestionOptionKeySpecifier
)[]
export type QuestionOptionFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  hasRemark?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isSelected?: FieldPolicy<any> | FieldReadFunction<any>
  remark?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QuestionnaireKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'purpose'
  | 'questions'
  | 'updatedAt'
  | 'version'
  | QuestionnaireKeySpecifier
)[]
export type QuestionnaireFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  purpose?: FieldPolicy<any> | FieldReadFunction<any>
  questions?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QuotationKeySpecifier = (
  | 'baseAmount'
  | 'createdAt'
  | 'expiredAt'
  | 'fee'
  | 'feeCurrency'
  | 'feeRate'
  | 'id'
  | 'market'
  | 'price'
  | 'quoteAmount'
  | 'side'
  | QuotationKeySpecifier
)[]
export type QuotationFieldPolicy = {
  baseAmount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  expiredAt?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  feeCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  feeRate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  market?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  quoteAmount?: FieldPolicy<any> | FieldReadFunction<any>
  side?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RateHistoricalKeySpecifier = ('rate' | 'time' | RateHistoricalKeySpecifier)[]
export type RateHistoricalFieldPolicy = {
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RejectFiatWithdrawalResponseKeySpecifier = (
  | 'result'
  | RejectFiatWithdrawalResponseKeySpecifier
)[]
export type RejectFiatWithdrawalResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseKeySpecifier = (
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'notesCollection'
  | 'releaseDate'
  | 'sys'
  | 'title'
  | ReleaseKeySpecifier
)[]
export type ReleaseFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  notesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  releaseDate?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ReleaseCollectionKeySpecifier
)[]
export type ReleaseCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | ReleaseLinkingCollectionsKeySpecifier
)[]
export type ReleaseLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteKeySpecifier = (
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'platform'
  | 'releaseNote'
  | 'sys'
  | 'version'
  | ReleaseNoteKeySpecifier
)[]
export type ReleaseNoteFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  platform?: FieldPolicy<any> | FieldReadFunction<any>
  releaseNote?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ReleaseNoteCollectionKeySpecifier
)[]
export type ReleaseNoteCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'releaseCollection'
  | ReleaseNoteLinkingCollectionsKeySpecifier
)[]
export type ReleaseNoteLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  releaseCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteReleaseNoteKeySpecifier = (
  | 'json'
  | 'links'
  | ReleaseNoteReleaseNoteKeySpecifier
)[]
export type ReleaseNoteReleaseNoteFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteReleaseNoteAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | ReleaseNoteReleaseNoteAssetsKeySpecifier
)[]
export type ReleaseNoteReleaseNoteAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteReleaseNoteEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | ReleaseNoteReleaseNoteEntriesKeySpecifier
)[]
export type ReleaseNoteReleaseNoteEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteReleaseNoteLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | ReleaseNoteReleaseNoteLinksKeySpecifier
)[]
export type ReleaseNoteReleaseNoteLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNoteReleaseNoteResourcesKeySpecifier = (
  | 'block'
  | ReleaseNoteReleaseNoteResourcesKeySpecifier
)[]
export type ReleaseNoteReleaseNoteResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReleaseNotesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ReleaseNotesCollectionKeySpecifier
)[]
export type ReleaseNotesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceConfirmUploadResponseKeySpecifier = (
  | 'id'
  | 'result'
  | ResourceConfirmUploadResponseKeySpecifier
)[]
export type ResourceConfirmUploadResponseFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceLinkKeySpecifier = ('sys' | ResourceLinkKeySpecifier)[]
export type ResourceLinkFieldPolicy = {
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceMutationKeySpecifier = (
  | 'resourceConfirmUpload'
  | 'resourceRequestUploadURL'
  | ResourceMutationKeySpecifier
)[]
export type ResourceMutationFieldPolicy = {
  resourceConfirmUpload?: FieldPolicy<any> | FieldReadFunction<any>
  resourceRequestUploadURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceRequestUploadURLResponseKeySpecifier = (
  | 'result'
  | 'url'
  | ResourceRequestUploadURLResponseKeySpecifier
)[]
export type ResourceRequestUploadURLResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceSysKeySpecifier = ('linkType' | 'type' | 'urn' | ResourceSysKeySpecifier)[]
export type ResourceSysFieldPolicy = {
  linkType?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  urn?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Response2FAKeySpecifier = ('token2FA' | Response2FAKeySpecifier)[]
export type Response2FAFieldPolicy = {
  token2FA?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldAttributesKeySpecifier = (
  | 'traitType'
  | 'value'
  | ReyieldAttributesKeySpecifier
)[]
export type ReyieldAttributesFieldPolicy = {
  traitType?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldBalanceKeySpecifier = ('available' | 'total' | ReyieldBalanceKeySpecifier)[]
export type ReyieldBalanceFieldPolicy = {
  available?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldCefiDepositAddressKeySpecifier = (
  | 'address'
  | 'chain'
  | ReyieldCefiDepositAddressKeySpecifier
)[]
export type ReyieldCefiDepositAddressFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldCefiWalletKeySpecifier = (
  | 'exchange'
  | 'walletBalances'
  | ReyieldCefiWalletKeySpecifier
)[]
export type ReyieldCefiWalletFieldPolicy = {
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  walletBalances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldCoinPriceKeySpecifier = ('symbol' | 'usdtPrice' | ReyieldCoinPriceKeySpecifier)[]
export type ReyieldCoinPriceFieldPolicy = {
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  usdtPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldCreatorKeySpecifier = ('address' | 'id' | ReyieldCreatorKeySpecifier)[]
export type ReyieldCreatorFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldCurrencyBalancesKeySpecifier = (
  | 'balance'
  | 'currency'
  | ReyieldCurrencyBalancesKeySpecifier
)[]
export type ReyieldCurrencyBalancesFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldCurrencyInfoKeySpecifier = (
  | 'decimals'
  | 'name'
  | 'symbol'
  | ReyieldCurrencyInfoKeySpecifier
)[]
export type ReyieldCurrencyInfoFieldPolicy = {
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldDefiWalletKeySpecifier = (
  | 'balance'
  | 'balanceComputed'
  | 'balanceComputedWithUnit'
  | 'chain'
  | 'currencyInfo'
  | 'id'
  | ReyieldDefiWalletKeySpecifier
)[]
export type ReyieldDefiWalletFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>
  balanceComputed?: FieldPolicy<any> | FieldReadFunction<any>
  balanceComputedWithUnit?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  currencyInfo?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldExternalAPIKeyKeySpecifier = (
  | 'createdAt'
  | 'exchange'
  | 'id'
  | 'isRevoked'
  | 'key'
  | 'name'
  | 'user'
  | 'userId'
  | ReyieldExternalAPIKeyKeySpecifier
)[]
export type ReyieldExternalAPIKeyFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isRevoked?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldFrontendVersionKeySpecifier = (
  | 'commitHash'
  | 'smartContracts'
  | 'version'
  | ReyieldFrontendVersionKeySpecifier
)[]
export type ReyieldFrontendVersionFieldPolicy = {
  commitHash?: FieldPolicy<any> | FieldReadFunction<any>
  smartContracts?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldGimCoolPayPaymentInfoKeySpecifier = (
  | 'blockchain'
  | 'coinAmount'
  | 'coinType'
  | 'fiatAmount'
  | 'fiatType'
  | 'paymentHref'
  | 'price'
  | 'walletAddress'
  | 'walletAddressTag'
  | ReyieldGimCoolPayPaymentInfoKeySpecifier
)[]
export type ReyieldGimCoolPayPaymentInfoFieldPolicy = {
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>
  coinAmount?: FieldPolicy<any> | FieldReadFunction<any>
  coinType?: FieldPolicy<any> | FieldReadFunction<any>
  fiatAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatType?: FieldPolicy<any> | FieldReadFunction<any>
  paymentHref?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  walletAddress?: FieldPolicy<any> | FieldReadFunction<any>
  walletAddressTag?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldGimCoolPayPriceResponseKeySpecifier = (
  | 'prices'
  | 'spotPrice'
  | ReyieldGimCoolPayPriceResponseKeySpecifier
)[]
export type ReyieldGimCoolPayPriceResponseFieldPolicy = {
  prices?: FieldPolicy<any> | FieldReadFunction<any>
  spotPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldGimCoolPayReadyToPurchasePriceKeySpecifier = (
  | 'coinAmount'
  | 'coinCode'
  | 'feeAmount'
  | 'fiatAmount'
  | 'fiatCode'
  | 'networkFee'
  | 'paymentMethodId'
  | 'spotPriceFee'
  | 'spotPriceIncludingFee'
  | 'type'
  | ReyieldGimCoolPayReadyToPurchasePriceKeySpecifier
)[]
export type ReyieldGimCoolPayReadyToPurchasePriceFieldPolicy = {
  coinAmount?: FieldPolicy<any> | FieldReadFunction<any>
  coinCode?: FieldPolicy<any> | FieldReadFunction<any>
  feeAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatAmount?: FieldPolicy<any> | FieldReadFunction<any>
  fiatCode?: FieldPolicy<any> | FieldReadFunction<any>
  networkFee?: FieldPolicy<any> | FieldReadFunction<any>
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>
  spotPriceFee?: FieldPolicy<any> | FieldReadFunction<any>
  spotPriceIncludingFee?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldLicenseKeySpecifier = (
  | 'numberOfLicense'
  | 'numberOfUsedLicense'
  | ReyieldLicenseKeySpecifier
)[]
export type ReyieldLicenseFieldPolicy = {
  numberOfLicense?: FieldPolicy<any> | FieldReadFunction<any>
  numberOfUsedLicense?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldNFTCollectionsKeySpecifier = (
  | 'attributes'
  | 'contract'
  | 'description'
  | 'imageUrl'
  | 'name'
  | 'tokenId'
  | ReyieldNFTCollectionsKeySpecifier
)[]
export type ReyieldNFTCollectionsFieldPolicy = {
  attributes?: FieldPolicy<any> | FieldReadFunction<any>
  contract?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldNFTMerkleTreeHexProofKeySpecifier = (
  | 'hexProof'
  | ReyieldNFTMerkleTreeHexProofKeySpecifier
)[]
export type ReyieldNFTMerkleTreeHexProofFieldPolicy = {
  hexProof?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldNFTWhitelistInfoByPhraseKeySpecifier = (
  | 'id'
  | 'isWhitelisted'
  | 'phrase'
  | 'userAddress'
  | ReyieldNFTWhitelistInfoByPhraseKeySpecifier
)[]
export type ReyieldNFTWhitelistInfoByPhraseFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isWhitelisted?: FieldPolicy<any> | FieldReadFunction<any>
  phrase?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldRateHistoricalKeySpecifier = (
  | 'rate'
  | 'time'
  | ReyieldRateHistoricalKeySpecifier
)[]
export type ReyieldRateHistoricalFieldPolicy = {
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldReyieldLicenseKeySpecifier = (
  | 'numberOfLicense'
  | 'numberOfUsedLicense'
  | ReyieldReyieldLicenseKeySpecifier
)[]
export type ReyieldReyieldLicenseFieldPolicy = {
  numberOfLicense?: FieldPolicy<any> | FieldReadFunction<any>
  numberOfUsedLicense?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldSmartContractKeySpecifier = (
  | 'address'
  | 'chain'
  | 'name'
  | ReyieldSmartContractKeySpecifier
)[]
export type ReyieldSmartContractFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyInfoKeySpecifier = (
  | 'currency'
  | 'description'
  | 'earningCurrency'
  | 'exchanges'
  | 'expectedAPY'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'numOfApply'
  | 'parameters'
  | 'riskLevel'
  | 'sid'
  | 'stage'
  | 'suggestionPeriod'
  | 'target'
  | 'type'
  | ReyieldStrategyInfoKeySpecifier
)[]
export type ReyieldStrategyInfoFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchanges?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numOfApply?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  stage?: FieldPolicy<any> | FieldReadFunction<any>
  suggestionPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  target?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyInfosKeySpecifier = (
  | 'currency'
  | 'earningCurrency'
  | 'exchange'
  | 'minEnableAmount'
  | 'name'
  | ReyieldStrategyInfosKeySpecifier
)[]
export type ReyieldStrategyInfosFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  minEnableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyParametersKeySpecifier = (
  | 'defaultValue'
  | 'description'
  | 'key'
  | 'maxValue'
  | 'minValue'
  | 'name'
  | 'type'
  | 'updatable'
  | ReyieldStrategyParametersKeySpecifier
)[]
export type ReyieldStrategyParametersFieldPolicy = {
  defaultValue?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  maxValue?: FieldPolicy<any> | FieldReadFunction<any>
  minValue?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyPerformanceKeySpecifier = (
  | 'amount'
  | 'apy'
  | 'currency'
  | 'ror'
  | ReyieldStrategyPerformanceKeySpecifier
)[]
export type ReyieldStrategyPerformanceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  ror?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyProgressKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'steps'
  | 'type'
  | 'updatedAt'
  | ReyieldStrategyProgressKeySpecifier
)[]
export type ReyieldStrategyProgressFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  steps?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyProgressStepKeySpecifier = (
  | 'status'
  | 'type'
  | ReyieldStrategyProgressStepKeySpecifier
)[]
export type ReyieldStrategyProgressStepFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyStartResponseKeySpecifier = (
  | 'strategy'
  | ReyieldStrategyStartResponseKeySpecifier
)[]
export type ReyieldStrategyStartResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyStopResponseKeySpecifier = (
  | 'strategy'
  | ReyieldStrategyStopResponseKeySpecifier
)[]
export type ReyieldStrategyStopResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldStrategyUpdateResponseKeySpecifier = (
  | 'strategy'
  | ReyieldStrategyUpdateResponseKeySpecifier
)[]
export type ReyieldStrategyUpdateResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldTokenBalanceKeySpecifier = (
  | 'address'
  | 'amount'
  | 'amountReadable'
  | 'chain'
  | 'decimals'
  | 'id'
  | 'symbol'
  | ReyieldTokenBalanceKeySpecifier
)[]
export type ReyieldTokenBalanceFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  amountReadable?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldTokenDisplayInfoKeySpecifier = (
  | 'address'
  | 'chain'
  | 'id'
  | 'imageURL'
  | 'name'
  | 'symbol'
  | ReyieldTokenDisplayInfoKeySpecifier
)[]
export type ReyieldTokenDisplayInfoFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imageURL?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldTotalProfitsKeySpecifier = (
  | 'amount'
  | 'currency'
  | ReyieldTotalProfitsKeySpecifier
)[]
export type ReyieldTotalProfitsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapOnchainPositionAmountInfoKeySpecifier = (
  | 'token0'
  | 'token0Amount'
  | 'token0AmountUsdValue'
  | 'token1'
  | 'token1Amount'
  | 'token1AmountUsdValue'
  | ReyieldUniswapOnchainPositionAmountInfoKeySpecifier
)[]
export type ReyieldUniswapOnchainPositionAmountInfoFieldPolicy = {
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Amount?: FieldPolicy<any> | FieldReadFunction<any>
  token0AmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Amount?: FieldPolicy<any> | FieldReadFunction<any>
  token1AmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapPoolInfoKeySpecifier = (
  | 'averageTradingVolumeUSD'
  | 'chain'
  | 'collectedFeesToken0'
  | 'collectedFeesToken1'
  | 'collectedFeesUSD'
  | 'createdAtBlockNumber'
  | 'createdAtTimestamp'
  | 'feeGrowthGlobal0X128'
  | 'feeGrowthGlobal1X128'
  | 'feeTier'
  | 'feesUSD'
  | 'id'
  | 'isPoolActiveForReyield'
  | 'liquidity'
  | 'liquidityProviderCount'
  | 'observationIndex'
  | 'positions'
  | 'sqrtPrice'
  | 'tick'
  | 'ticks'
  | 'token0'
  | 'token0Price'
  | 'token1'
  | 'token1Price'
  | 'totalValueLockedETH'
  | 'totalValueLockedToken0'
  | 'totalValueLockedToken1'
  | 'totalValueLockedUSD'
  | 'totalValueLockedUSDUntracked'
  | 'txCount'
  | 'untrackedVolumeUSD'
  | 'volumeToken0'
  | 'volumeToken1'
  | 'volumeUSD'
  | ReyieldUniswapPoolInfoKeySpecifier
)[]
export type ReyieldUniswapPoolInfoFieldPolicy = {
  averageTradingVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken0?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken1?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  createdAtBlockNumber?: FieldPolicy<any> | FieldReadFunction<any>
  createdAtTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthGlobal0X128?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthGlobal1X128?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  feesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isPoolActiveForReyield?: FieldPolicy<any> | FieldReadFunction<any>
  liquidity?: FieldPolicy<any> | FieldReadFunction<any>
  liquidityProviderCount?: FieldPolicy<any> | FieldReadFunction<any>
  observationIndex?: FieldPolicy<any> | FieldReadFunction<any>
  positions?: FieldPolicy<any> | FieldReadFunction<any>
  sqrtPrice?: FieldPolicy<any> | FieldReadFunction<any>
  tick?: FieldPolicy<any> | FieldReadFunction<any>
  ticks?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Price?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Price?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedETH?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedToken0?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedToken1?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSD?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSDUntracked?: FieldPolicy<any> | FieldReadFunction<any>
  txCount?: FieldPolicy<any> | FieldReadFunction<any>
  untrackedVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  volumeToken0?: FieldPolicy<any> | FieldReadFunction<any>
  volumeToken1?: FieldPolicy<any> | FieldReadFunction<any>
  volumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapPoolPositionKeySpecifier = (
  | 'collectedFeesToken0'
  | 'collectedFeesToken1'
  | 'depositedToken0'
  | 'depositedToken1'
  | 'feeGrowthInside0LastX128'
  | 'feeGrowthInside1LastX128'
  | 'id'
  | 'liquidity'
  | 'tickLowerFeeGrowthOutside0X128'
  | 'tickLowerFeeGrowthOutside1X128'
  | 'tickLowerTickIdx'
  | 'tickUpperFeeGrowthOutside0X128'
  | 'tickUpperFeeGrowthOutside1X128'
  | 'tickUpperTickIdx'
  | 'transactionTimestamp'
  | ReyieldUniswapPoolPositionKeySpecifier
)[]
export type ReyieldUniswapPoolPositionFieldPolicy = {
  collectedFeesToken0?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken1?: FieldPolicy<any> | FieldReadFunction<any>
  depositedToken0?: FieldPolicy<any> | FieldReadFunction<any>
  depositedToken1?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthInside0LastX128?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthInside1LastX128?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  liquidity?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerFeeGrowthOutside0X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerFeeGrowthOutside1X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerTickIdx?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperFeeGrowthOutside0X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperFeeGrowthOutside1X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperTickIdx?: FieldPolicy<any> | FieldReadFunction<any>
  transactionTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapPoolTickKeySpecifier = (
  | 'id'
  | 'liquidityNet'
  | 'price0'
  | 'price1'
  | 'tickIdx'
  | ReyieldUniswapPoolTickKeySpecifier
)[]
export type ReyieldUniswapPoolTickFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  liquidityNet?: FieldPolicy<any> | FieldReadFunction<any>
  price0?: FieldPolicy<any> | FieldReadFunction<any>
  price1?: FieldPolicy<any> | FieldReadFunction<any>
  tickIdx?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapQuoteKeySpecifier = (
  | 'chain'
  | 'fromToken'
  | 'fromTokenAddress'
  | 'id'
  | 'inputAmount'
  | 'inputReadableAmount'
  | 'outputAmount'
  | 'outputReadableAmount'
  | 'toToken'
  | 'toTokenAddress'
  | ReyieldUniswapQuoteKeySpecifier
)[]
export type ReyieldUniswapQuoteFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  fromToken?: FieldPolicy<any> | FieldReadFunction<any>
  fromTokenAddress?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  inputAmount?: FieldPolicy<any> | FieldReadFunction<any>
  inputReadableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  outputAmount?: FieldPolicy<any> | FieldReadFunction<any>
  outputReadableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  toToken?: FieldPolicy<any> | FieldReadFunction<any>
  toTokenAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapToolFeeTierKeySpecifier = (
  | 'feeForContract'
  | 'feeForDisplay'
  | 'id'
  | ReyieldUniswapToolFeeTierKeySpecifier
)[]
export type ReyieldUniswapToolFeeTierFieldPolicy = {
  feeForContract?: FieldPolicy<any> | FieldReadFunction<any>
  feeForDisplay?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapToolInfoKeySpecifier = (
  | 'chain'
  | 'createdAt'
  | 'creator'
  | 'creatorAddress'
  | 'creatorId'
  | 'description'
  | 'expectedAPY'
  | 'feeTier'
  | 'id'
  | 'isOfficial'
  | 'maximumLoss'
  | 'name'
  | 'numOfApply'
  | 'performanceFee'
  | 'poolAddress'
  | 'poolInfo'
  | 'riskLevel'
  | 'tags'
  | 'tickLowerDiff'
  | 'tickLowerDiffForContract'
  | 'tickUpperDiff'
  | 'tickUpperDiffForContract'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1Address'
  | 'token1DisplayInfo'
  | ReyieldUniswapToolInfoKeySpecifier
)[]
export type ReyieldUniswapToolInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  creator?: FieldPolicy<any> | FieldReadFunction<any>
  creatorAddress?: FieldPolicy<any> | FieldReadFunction<any>
  creatorId?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numOfApply?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFee?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  poolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiffForContract?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiffForContract?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapToolTagKeySpecifier = (
  | 'id'
  | 'name'
  | 'type'
  | ReyieldUniswapToolTagKeySpecifier
)[]
export type ReyieldUniswapToolTagFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapToolTokenKeySpecifier = (
  | 'chain'
  | 'decimals'
  | 'derivedETH'
  | 'feesUSD'
  | 'id'
  | 'name'
  | 'poolCount'
  | 'priceChart'
  | 'symbol'
  | 'totalSupply'
  | 'totalValueLocked'
  | 'totalValueLockedUSD'
  | 'totalValueLockedUSDUntracked'
  | 'txCount'
  | 'untrackedVolumeUSD'
  | 'volume'
  | 'volumeUSD'
  | ReyieldUniswapToolTokenKeySpecifier
)[]
export type ReyieldUniswapToolTokenFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  derivedETH?: FieldPolicy<any> | FieldReadFunction<any>
  feesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  poolCount?: FieldPolicy<any> | FieldReadFunction<any>
  priceChart?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  totalSupply?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLocked?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSD?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSDUntracked?: FieldPolicy<any> | FieldReadFunction<any>
  txCount?: FieldPolicy<any> | FieldReadFunction<any>
  untrackedVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  volume?: FieldPolicy<any> | FieldReadFunction<any>
  volumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapToolTokenPriceChartKeySpecifier = (
  | 'currentPriceUSD'
  | 'id'
  | 'prices'
  | ReyieldUniswapToolTokenPriceChartKeySpecifier
)[]
export type ReyieldUniswapToolTokenPriceChartFieldPolicy = {
  currentPriceUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  prices?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapToolTokenPriceChartPriceKeySpecifier = (
  | 'timestamp'
  | 'value'
  | ReyieldUniswapToolTokenPriceChartPriceKeySpecifier
)[]
export type ReyieldUniswapToolTokenPriceChartPriceFieldPolicy = {
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUniswapV3BacktestResultKeySpecifier = (
  | 'activeLiquidityPercent'
  | 'annualReturn'
  | 'compound'
  | 'createdAt'
  | 'dailyReturn'
  | 'dataPointsCount'
  | 'days'
  | 'diff'
  | 'fees'
  | 'id'
  | 'impermanentLoss'
  | 'investmentAmount'
  | 'maxDrawdown'
  | 'period'
  | 'poolID'
  | 'protocol'
  | 'totalReturn'
  | 'volatility'
  | ReyieldUniswapV3BacktestResultKeySpecifier
)[]
export type ReyieldUniswapV3BacktestResultFieldPolicy = {
  activeLiquidityPercent?: FieldPolicy<any> | FieldReadFunction<any>
  annualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  compound?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  dailyReturn?: FieldPolicy<any> | FieldReadFunction<any>
  dataPointsCount?: FieldPolicy<any> | FieldReadFunction<any>
  days?: FieldPolicy<any> | FieldReadFunction<any>
  diff?: FieldPolicy<any> | FieldReadFunction<any>
  fees?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  impermanentLoss?: FieldPolicy<any> | FieldReadFunction<any>
  investmentAmount?: FieldPolicy<any> | FieldReadFunction<any>
  maxDrawdown?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  poolID?: FieldPolicy<any> | FieldReadFunction<any>
  protocol?: FieldPolicy<any> | FieldReadFunction<any>
  totalReturn?: FieldPolicy<any> | FieldReadFunction<any>
  volatility?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserInfoKeySpecifier = (
  | 'aICompletionRecords'
  | 'cefiDepositAddress'
  | 'cefiWallets'
  | 'defiTokensBalanceAmount'
  | 'defiWallets'
  | 'externalAPIKeys'
  | 'gimCoolPayPaymentInfo'
  | 'id'
  | 'nftCollections'
  | 'nftMerkleTreeHexProof'
  | 'nftWhitelistInfoByPhrases'
  | 'ownedToolsSetting'
  | 'reyieldLicense'
  | 'strategies'
  | 'strategyPerformance'
  | 'strategyProfit'
  | 'strategyProgress'
  | 'strategyRecords'
  | 'tools'
  | ReyieldUserInfoKeySpecifier
)[]
export type ReyieldUserInfoFieldPolicy = {
  aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  cefiDepositAddress?: FieldPolicy<any> | FieldReadFunction<any>
  cefiWallets?: FieldPolicy<any> | FieldReadFunction<any>
  defiTokensBalanceAmount?: FieldPolicy<any> | FieldReadFunction<any>
  defiWallets?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeys?: FieldPolicy<any> | FieldReadFunction<any>
  gimCoolPayPaymentInfo?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  nftCollections?: FieldPolicy<any> | FieldReadFunction<any>
  nftMerkleTreeHexProof?: FieldPolicy<any> | FieldReadFunction<any>
  nftWhitelistInfoByPhrases?: FieldPolicy<any> | FieldReadFunction<any>
  ownedToolsSetting?: FieldPolicy<any> | FieldReadFunction<any>
  reyieldLicense?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  strategyPerformance?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProfit?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProgress?: FieldPolicy<any> | FieldReadFunction<any>
  strategyRecords?: FieldPolicy<any> | FieldReadFunction<any>
  tools?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserStrategyInfoKeySpecifier = (
  | 'ceFiStatus'
  | 'closedAt'
  | 'createdAt'
  | 'errMsg'
  | 'id'
  | 'infos'
  | 'parameters'
  | 'performance'
  | 'profits'
  | 'sid'
  | 'updatedAt'
  | ReyieldUserStrategyInfoKeySpecifier
)[]
export type ReyieldUserStrategyInfoFieldPolicy = {
  ceFiStatus?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  errMsg?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  infos?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  performance?: FieldPolicy<any> | FieldReadFunction<any>
  profits?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserStrategyParametersKeySpecifier = (
  | 'key'
  | 'value'
  | ReyieldUserStrategyParametersKeySpecifier
)[]
export type ReyieldUserStrategyParametersFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserStrategyPerformanceKeySpecifier = (
  | 'duration'
  | 'initialValue'
  | 'performances'
  | ReyieldUserStrategyPerformanceKeySpecifier
)[]
export type ReyieldUserStrategyPerformanceFieldPolicy = {
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  initialValue?: FieldPolicy<any> | FieldReadFunction<any>
  performances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserStrategyProfitKeySpecifier = (
  | 'sid'
  | 'totalProfit'
  | 'type'
  | ReyieldUserStrategyProfitKeySpecifier
)[]
export type ReyieldUserStrategyProfitFieldPolicy = {
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  totalProfit?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserStrategyRecordKeySpecifier = (
  | 'amount'
  | 'currency'
  | 'id'
  | 'rate'
  | 'time'
  | 'type'
  | ReyieldUserStrategyRecordKeySpecifier
)[]
export type ReyieldUserStrategyRecordFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserUniswapOnchainPositionInfoKeySpecifier = (
  | 'apy'
  | 'chain'
  | 'closedPositionAmount'
  | 'positionId'
  | 'positionIdFromSmartContractPositionManager'
  | 'roi'
  | 'runningPositionAmount'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'totalInvestAmountUsdValue'
  | ReyieldUserUniswapOnchainPositionInfoKeySpecifier
)[]
export type ReyieldUserUniswapOnchainPositionInfoFieldPolicy = {
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdFromSmartContractPositionManager?: FieldPolicy<any> | FieldReadFunction<any>
  roi?: FieldPolicy<any> | FieldReadFunction<any>
  runningPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  totalInvestAmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserUniswapPositionInfoKeySpecifier = (
  | 'apy'
  | 'chain'
  | 'closedAt'
  | 'closedPositionAmount'
  | 'createdAt'
  | 'feeTier'
  | 'id'
  | 'onchainPositionInfo'
  | 'poolAddress'
  | 'positionId'
  | 'positionIdFromSmartContractPositionManager'
  | 'progresses'
  | 'publicToolInfo'
  | 'roi'
  | 'runningPositionAmount'
  | 'status'
  | 'tickLowerDiff'
  | 'tickUpperDiff'
  | 'token0'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'toolDescription'
  | 'toolId'
  | 'toolIsOfficial'
  | 'toolName'
  | 'toolPerformanceFeeRatio'
  | 'toolSetting'
  | 'totalInvestAmountUsdValue'
  | 'updatedAt'
  | 'userAddress'
  | ReyieldUserUniswapPositionInfoKeySpecifier
)[]
export type ReyieldUserUniswapPositionInfoFieldPolicy = {
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  closedPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  onchainPositionInfo?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdFromSmartContractPositionManager?: FieldPolicy<any> | FieldReadFunction<any>
  progresses?: FieldPolicy<any> | FieldReadFunction<any>
  publicToolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  roi?: FieldPolicy<any> | FieldReadFunction<any>
  runningPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  toolDescription?: FieldPolicy<any> | FieldReadFunction<any>
  toolId?: FieldPolicy<any> | FieldReadFunction<any>
  toolIsOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  toolName?: FieldPolicy<any> | FieldReadFunction<any>
  toolPerformanceFeeRatio?: FieldPolicy<any> | FieldReadFunction<any>
  toolSetting?: FieldPolicy<any> | FieldReadFunction<any>
  totalInvestAmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserUniswapToolOutOfRangeInfoKeySpecifier = (
  | 'earnSpendRate'
  | 'maxRebalanceTimes'
  | 'standbyInterval'
  | 'standbyMaxInterval'
  | ReyieldUserUniswapToolOutOfRangeInfoKeySpecifier
)[]
export type ReyieldUserUniswapToolOutOfRangeInfoFieldPolicy = {
  earnSpendRate?: FieldPolicy<any> | FieldReadFunction<any>
  maxRebalanceTimes?: FieldPolicy<any> | FieldReadFunction<any>
  standbyInterval?: FieldPolicy<any> | FieldReadFunction<any>
  standbyMaxInterval?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserUniswapToolSettingInfoKeySpecifier = (
  | 'chain'
  | 'closedAt'
  | 'createdAt'
  | 'description'
  | 'feeTier'
  | 'id'
  | 'isOfficial'
  | 'name'
  | 'outOfLowerRange'
  | 'outOfUpperRange'
  | 'performanceFeeRatio'
  | 'poolAddress'
  | 'position'
  | 'publicToolInfo'
  | 'status'
  | 'tickLowerDiff'
  | 'tickUpperDiff'
  | 'token0'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'totalUserUsed'
  | 'updatedAt'
  | ReyieldUserUniswapToolSettingInfoKeySpecifier
)[]
export type ReyieldUserUniswapToolSettingInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  outOfLowerRange?: FieldPolicy<any> | FieldReadFunction<any>
  outOfUpperRange?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFeeRatio?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  position?: FieldPolicy<any> | FieldReadFunction<any>
  publicToolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  totalUserUsed?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserUniswapv3PositionProgressKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'steps'
  | 'type'
  | 'updatedAt'
  | ReyieldUserUniswapv3PositionProgressKeySpecifier
)[]
export type ReyieldUserUniswapv3PositionProgressFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  steps?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldUserUniswapv3PositionProgressStepKeySpecifier = (
  | 'status'
  | 'type'
  | ReyieldUserUniswapv3PositionProgressStepKeySpecifier
)[]
export type ReyieldUserUniswapv3PositionProgressStepFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldWalletBalancesKeySpecifier = (
  | 'currencyBalances'
  | 'wallet'
  | ReyieldWalletBalancesKeySpecifier
)[]
export type ReyieldWalletBalancesFieldPolicy = {
  currencyBalances?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldaICompletionRecordsKeySpecifier = (
  | 'completedAt'
  | 'content'
  | 'createdAt'
  | 'id'
  | 'meta'
  | 'platform'
  | 'promptMutationInput'
  | 'promptMutationName'
  | 'suggestions'
  | 'suggestions_aggregate'
  | 'updatedAt'
  | 'userAddress'
  | 'userId'
  | ReyieldaICompletionRecordsKeySpecifier
)[]
export type ReyieldaICompletionRecordsFieldPolicy = {
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  platform?: FieldPolicy<any> | FieldReadFunction<any>
  promptMutationInput?: FieldPolicy<any> | FieldReadFunction<any>
  promptMutationName?: FieldPolicy<any> | FieldReadFunction<any>
  suggestions?: FieldPolicy<any> | FieldReadFunction<any>
  suggestions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldsuggestStrategyToolWithAllocationsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | ReyieldsuggestStrategyToolWithAllocationsKeySpecifier
)[]
export type ReyieldsuggestStrategyToolWithAllocationsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldsuggestStrategyToolWithAllocations_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | ReyieldsuggestStrategyToolWithAllocations_aggregateKeySpecifier
)[]
export type ReyieldsuggestStrategyToolWithAllocations_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldsuggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | ReyieldsuggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier
)[]
export type ReyieldsuggestStrategyToolWithAllocations_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldsuggestStrategyToolWithAllocations_max_fieldsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | ReyieldsuggestStrategyToolWithAllocations_max_fieldsKeySpecifier
)[]
export type ReyieldsuggestStrategyToolWithAllocations_max_fieldsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ReyieldsuggestStrategyToolWithAllocations_min_fieldsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | ReyieldsuggestStrategyToolWithAllocations_min_fieldsKeySpecifier
)[]
export type ReyieldsuggestStrategyToolWithAllocations_min_fieldsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionKeySpecifier = (
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'sectionContentsCollection'
  | 'sys'
  | 'title'
  | SectionKeySpecifier
)[]
export type SectionFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sectionContentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionCollectionKeySpecifier
)[]
export type SectionCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'externalLink'
  | 'externalLinkDisplayText'
  | 'linkedFrom'
  | 'subTitle'
  | 'sys'
  | 'title'
  | SectionContentKeySpecifier
)[]
export type SectionContentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  externalLink?: FieldPolicy<any> | FieldReadFunction<any>
  externalLinkDisplayText?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  subTitle?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionContentCollectionKeySpecifier
)[]
export type SectionContentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentDescriptionKeySpecifier = (
  | 'json'
  | 'links'
  | SectionContentDescriptionKeySpecifier
)[]
export type SectionContentDescriptionFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentDescriptionAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | SectionContentDescriptionAssetsKeySpecifier
)[]
export type SectionContentDescriptionAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentDescriptionEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | SectionContentDescriptionEntriesKeySpecifier
)[]
export type SectionContentDescriptionEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentDescriptionLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | SectionContentDescriptionLinksKeySpecifier
)[]
export type SectionContentDescriptionLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentDescriptionResourcesKeySpecifier = (
  | 'block'
  | SectionContentDescriptionResourcesKeySpecifier
)[]
export type SectionContentDescriptionResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionContentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionCollection'
  | SectionContentLinkingCollectionsKeySpecifier
)[]
export type SectionContentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'strategyMetaCollection'
  | SectionLinkingCollectionsKeySpecifier
)[]
export type SectionLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  strategyMetaCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionSectionContentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionSectionContentsCollectionKeySpecifier
)[]
export type SectionSectionContentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractKeySpecifier = ('address' | 'chain' | 'name' | SmartContractKeySpecifier)[]
export type SmartContractFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SpecialUserKeySpecifier = (
  | 'contentfulMetadata'
  | 'email'
  | 'linkedFrom'
  | 'sys'
  | SpecialUserKeySpecifier
)[]
export type SpecialUserFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SpecialUserCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SpecialUserCollectionKeySpecifier
)[]
export type SpecialUserCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SpecialUserLinkingCollectionsKeySpecifier = (
  | 'displayItemCollection'
  | 'entryCollection'
  | SpecialUserLinkingCollectionsKeySpecifier
)[]
export type SpecialUserLinkingCollectionsFieldPolicy = {
  displayItemCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyInfoKeySpecifier = (
  | 'currency'
  | 'description'
  | 'earningCurrency'
  | 'exchanges'
  | 'expectedAPY'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'numOfApply'
  | 'parameters'
  | 'riskLevel'
  | 'sid'
  | 'stage'
  | 'suggestionPeriod'
  | 'target'
  | 'type'
  | StrategyInfoKeySpecifier
)[]
export type StrategyInfoFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchanges?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numOfApply?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  stage?: FieldPolicy<any> | FieldReadFunction<any>
  suggestionPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  target?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyInfosKeySpecifier = (
  | 'currency'
  | 'earningCurrency'
  | 'exchange'
  | 'minEnableAmount'
  | 'name'
  | StrategyInfosKeySpecifier
)[]
export type StrategyInfosFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  minEnableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyMetaKeySpecifier = (
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'sectionsCollection'
  | 'sid'
  | 'sys'
  | StrategyMetaKeySpecifier
)[]
export type StrategyMetaFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyMetaCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | StrategyMetaCollectionKeySpecifier
)[]
export type StrategyMetaCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyMetaLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | StrategyMetaLinkingCollectionsKeySpecifier
)[]
export type StrategyMetaLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyMetaSectionsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | StrategyMetaSectionsCollectionKeySpecifier
)[]
export type StrategyMetaSectionsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyParametersKeySpecifier = (
  | 'defaultValue'
  | 'description'
  | 'key'
  | 'maxValue'
  | 'minValue'
  | 'name'
  | 'type'
  | 'updatable'
  | StrategyParametersKeySpecifier
)[]
export type StrategyParametersFieldPolicy = {
  defaultValue?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  maxValue?: FieldPolicy<any> | FieldReadFunction<any>
  minValue?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyPerformanceKeySpecifier = (
  | 'amount'
  | 'apy'
  | 'currency'
  | 'ror'
  | StrategyPerformanceKeySpecifier
)[]
export type StrategyPerformanceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  ror?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyProgressKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'steps'
  | 'type'
  | 'updatedAt'
  | StrategyProgressKeySpecifier
)[]
export type StrategyProgressFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  steps?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyProgressStepKeySpecifier = (
  | 'status'
  | 'type'
  | StrategyProgressStepKeySpecifier
)[]
export type StrategyProgressStepFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyStartResponseKeySpecifier = ('strategy' | StrategyStartResponseKeySpecifier)[]
export type StrategyStartResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyStopResponseKeySpecifier = ('strategy' | StrategyStopResponseKeySpecifier)[]
export type StrategyStopResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrategyUpdateResponseKeySpecifier = ('strategy' | StrategyUpdateResponseKeySpecifier)[]
export type StrategyUpdateResponseFieldPolicy = {
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionKeySpecifier
)[]
export type Strategy_ActionFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIApproveSwapRewardKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIApproveSwapRewardKeySpecifier
)[]
export type Strategy_ActionDEFIApproveSwapRewardFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIDeductTxnFeeKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIDeductTxnFeeKeySpecifier
)[]
export type Strategy_ActionDEFIDeductTxnFeeFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIDispatchUSDTRewardKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIDispatchUSDTRewardKeySpecifier
)[]
export type Strategy_ActionDEFIDispatchUSDTRewardFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVAddLiquidityKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVAddLiquidityKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVAddLiquidityFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVApproveConvexBoosterKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVApproveConvexBoosterKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVApproveConvexBoosterFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVApproveCurve3PoolKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVApproveCurve3PoolKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVApproveCurve3PoolFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVClaimRewardKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVClaimRewardKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVClaimRewardFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVDepositAllKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVDepositAllKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVDepositAllFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVRemoveLiquidityKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVRemoveLiquidityKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVRemoveLiquidityFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrapKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrapKeySpecifier
)[]
export type Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrapFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIMIMUSDTApproveCurve3PoolKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIMIMUSDTApproveCurve3PoolKeySpecifier
)[]
export type Strategy_ActionDEFIMIMUSDTApproveCurve3PoolFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIRebalanceTxnFeeEstimationKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIRebalanceTxnFeeEstimationKeySpecifier
)[]
export type Strategy_ActionDEFIRebalanceTxnFeeEstimationFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIStakePoolKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIStakePoolKeySpecifier
)[]
export type Strategy_ActionDEFIStakePoolFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFISwapETHToUSDTKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFISwapETHToUSDTKeySpecifier
)[]
export type Strategy_ActionDEFISwapETHToUSDTFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFISwapRewardKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFISwapRewardKeySpecifier
)[]
export type Strategy_ActionDEFISwapRewardFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFITransferUserLPBalanceToPoolKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFITransferUserLPBalanceToPoolKeySpecifier
)[]
export type Strategy_ActionDEFITransferUserLPBalanceToPoolFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUnStakePoolKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUnStakePoolKeySpecifier
)[]
export type Strategy_ActionDEFIUnStakePoolFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidityKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidityKeySpecifier
)[]
export type Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidityFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUpdatePoolBalanceAfterClaimRewardKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUpdatePoolBalanceAfterClaimRewardKeySpecifier
)[]
export type Strategy_ActionDEFIUpdatePoolBalanceAfterClaimRewardFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidityKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidityKeySpecifier
)[]
export type Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidityFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDTKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDTKeySpecifier
)[]
export type Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDTFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUpdatePoolBalanceAfterSwapRewardKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUpdatePoolBalanceAfterSwapRewardKeySpecifier
)[]
export type Strategy_ActionDEFIUpdatePoolBalanceAfterSwapRewardFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionDEFIUpdateUserRecordStatusKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionDEFIUpdateUserRecordStatusKeySpecifier
)[]
export type Strategy_ActionDEFIUpdateUserRecordStatusFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionExternalDepositCheckKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionExternalDepositCheckKeySpecifier
)[]
export type Strategy_ActionExternalDepositCheckFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionExternalSwapKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionExternalSwapKeySpecifier
)[]
export type Strategy_ActionExternalSwapFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionExternalWalletTransferKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionExternalWalletTransferKeySpecifier
)[]
export type Strategy_ActionExternalWalletTransferFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionExternalWithdrawalKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionExternalWithdrawalKeySpecifier
)[]
export type Strategy_ActionExternalWithdrawalFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionInternalDepositCheckKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionInternalDepositCheckKeySpecifier
)[]
export type Strategy_ActionInternalDepositCheckFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionInternalTradeKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionInternalTradeKeySpecifier
)[]
export type Strategy_ActionInternalTradeFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionInternalWithdrawalKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionInternalWithdrawalKeySpecifier
)[]
export type Strategy_ActionInternalWithdrawalFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionLogKeySpecifier = (
  | 'action'
  | 'createdAt'
  | 'description'
  | 'id'
  | Strategy_ActionLogKeySpecifier
)[]
export type Strategy_ActionLogFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionStrategyBatchStartKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionStrategyBatchStartKeySpecifier
)[]
export type Strategy_ActionStrategyBatchStartFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionStrategyBatchStopKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionStrategyBatchStopKeySpecifier
)[]
export type Strategy_ActionStrategyBatchStopFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionStrategyBatchUpdateKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionStrategyBatchUpdateKeySpecifier
)[]
export type Strategy_ActionStrategyBatchUpdateFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionStrategyStartKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionStrategyStartKeySpecifier
)[]
export type Strategy_ActionStrategyStartFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionStrategyStopKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionStrategyStopKeySpecifier
)[]
export type Strategy_ActionStrategyStopFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionStrategyUpdateKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionStrategyUpdateKeySpecifier
)[]
export type Strategy_ActionStrategyUpdateFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionTransferFromDEFIWalletPostProcessingKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionTransferFromDEFIWalletPostProcessingKeySpecifier
)[]
export type Strategy_ActionTransferFromDEFIWalletPostProcessingFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionTransferFromDEFIWalletPreProcessingKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionTransferFromDEFIWalletPreProcessingKeySpecifier
)[]
export type Strategy_ActionTransferFromDEFIWalletPreProcessingFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionTransferFromDEFIWalletProcessingKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionTransferFromDEFIWalletProcessingKeySpecifier
)[]
export type Strategy_ActionTransferFromDEFIWalletProcessingFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionTransferToDEFIWalletPostProcessingKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionTransferToDEFIWalletPostProcessingKeySpecifier
)[]
export type Strategy_ActionTransferToDEFIWalletPostProcessingFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionTransferToDEFIWalletPreProcessingKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionTransferToDEFIWalletPreProcessingKeySpecifier
)[]
export type Strategy_ActionTransferToDEFIWalletPreProcessingFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionTransferToDEFIWalletProcessingKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionTransferToDEFIWalletProcessingKeySpecifier
)[]
export type Strategy_ActionTransferToDEFIWalletProcessingFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ActionWatchTxnStatusKeySpecifier = (
  | 'error'
  | 'params'
  | 'status'
  | 'type'
  | Strategy_ActionWatchTxnStatusKeySpecifier
)[]
export type Strategy_ActionWatchTxnStatusFieldPolicy = {
  error?: FieldPolicy<any> | FieldReadFunction<any>
  params?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_AdminKeySpecifier = (
  | 'externalAPIKeys'
  | 'workflows'
  | Strategy_AdminKeySpecifier
)[]
export type Strategy_AdminFieldPolicy = {
  externalAPIKeys?: FieldPolicy<any> | FieldReadFunction<any>
  workflows?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_AdminMutationKeySpecifier = (
  | 'defiNormalFlow'
  | 'defiRebalanceFlow'
  | 'defiWithdrawExpressFlow'
  | 'workflowRetry'
  | Strategy_AdminMutationKeySpecifier
)[]
export type Strategy_AdminMutationFieldPolicy = {
  defiNormalFlow?: FieldPolicy<any> | FieldReadFunction<any>
  defiRebalanceFlow?: FieldPolicy<any> | FieldReadFunction<any>
  defiWithdrawExpressFlow?: FieldPolicy<any> | FieldReadFunction<any>
  workflowRetry?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ArbitrageStateKeySpecifier = (
  | 'executedAmount'
  | 'totalAmount'
  | 'updatedAt'
  | Strategy_ArbitrageStateKeySpecifier
)[]
export type Strategy_ArbitrageStateFieldPolicy = {
  executedAmount?: FieldPolicy<any> | FieldReadFunction<any>
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_BalanceKeySpecifier = (
  | 'availTWDValue'
  | 'availUSDValue'
  | 'available'
  | 'currency'
  | 'total'
  | 'totalTWDValue'
  | 'totalUSDValue'
  | 'walletType'
  | Strategy_BalanceKeySpecifier
)[]
export type Strategy_BalanceFieldPolicy = {
  availTWDValue?: FieldPolicy<any> | FieldReadFunction<any>
  availUSDValue?: FieldPolicy<any> | FieldReadFunction<any>
  available?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
  totalTWDValue?: FieldPolicy<any> | FieldReadFunction<any>
  totalUSDValue?: FieldPolicy<any> | FieldReadFunction<any>
  walletType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_CurrencyAmountKeySpecifier = (
  | 'amount'
  | 'currency'
  | Strategy_CurrencyAmountKeySpecifier
)[]
export type Strategy_CurrencyAmountFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_DailyProfitKeySpecifier = (
  | 'amount'
  | 'currency'
  | 'time'
  | Strategy_DailyProfitKeySpecifier
)[]
export type Strategy_DailyProfitFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_DebtRecordKeySpecifier = (
  | 'amount'
  | 'id'
  | 'time'
  | Strategy_DebtRecordKeySpecifier
)[]
export type Strategy_DebtRecordFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_EstimatePointsConsumingResultKeySpecifier = (
  | 'points'
  | 'sid'
  | Strategy_EstimatePointsConsumingResultKeySpecifier
)[]
export type Strategy_EstimatePointsConsumingResultFieldPolicy = {
  points?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_EstimateStrategyRequiredAmountResponseKeySpecifier = (
  | 'currency'
  | 'lackingAmount'
  | Strategy_EstimateStrategyRequiredAmountResponseKeySpecifier
)[]
export type Strategy_EstimateStrategyRequiredAmountResponseFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  lackingAmount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExchangeAssetKeySpecifier = (
  | 'balances'
  | 'exchange'
  | 'netAvailBalanceInTWD'
  | 'netAvailBalanceInUSD'
  | 'netBalanceInTWD'
  | 'netBalanceInUSD'
  | Strategy_ExchangeAssetKeySpecifier
)[]
export type Strategy_ExchangeAssetFieldPolicy = {
  balances?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  netAvailBalanceInTWD?: FieldPolicy<any> | FieldReadFunction<any>
  netAvailBalanceInUSD?: FieldPolicy<any> | FieldReadFunction<any>
  netBalanceInTWD?: FieldPolicy<any> | FieldReadFunction<any>
  netBalanceInUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExchangeWithdrawalAuthorizationResponseKeySpecifier = (
  | 'exchange'
  | 'is2FARequired'
  | 'isWithdrawalPasswordRequired'
  | Strategy_ExchangeWithdrawalAuthorizationResponseKeySpecifier
)[]
export type Strategy_ExchangeWithdrawalAuthorizationResponseFieldPolicy = {
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  is2FARequired?: FieldPolicy<any> | FieldReadFunction<any>
  isWithdrawalPasswordRequired?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExchangesNetAssetChartKeySpecifier = (
  | 'data'
  | 'exchange'
  | Strategy_ExchangesNetAssetChartKeySpecifier
)[]
export type Strategy_ExchangesNetAssetChartFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExchangesNetAssetChartDataKeySpecifier = (
  | 'netAssetTWDValue'
  | 'netAssetUSDValue'
  | 'time'
  | Strategy_ExchangesNetAssetChartDataKeySpecifier
)[]
export type Strategy_ExchangesNetAssetChartDataFieldPolicy = {
  netAssetTWDValue?: FieldPolicy<any> | FieldReadFunction<any>
  netAssetUSDValue?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExternalAPIKeyKeySpecifier = (
  | 'createdAt'
  | 'exchange'
  | 'id'
  | 'isRevoked'
  | 'key'
  | 'name'
  | 'secret'
  | 'subaccount'
  | 'userID'
  | Strategy_ExternalAPIKeyKeySpecifier
)[]
export type Strategy_ExternalAPIKeyFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isRevoked?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  secret?: FieldPolicy<any> | FieldReadFunction<any>
  subaccount?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExternalAPIKeyMutationKeySpecifier = (
  | 'externalAPIKeyCreate'
  | 'externalAPIKeyDelete'
  | 'externalAPIKeyDeposit'
  | 'externalAPIKeySwap'
  | 'externalAPIKeyTransferBetweenWallets'
  | 'externalAPIKeyWithdrawal'
  | 'externalAPIKeyWithdrawalConfirm'
  | Strategy_ExternalAPIKeyMutationKeySpecifier
)[]
export type Strategy_ExternalAPIKeyMutationFieldPolicy = {
  externalAPIKeyCreate?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyDelete?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyDeposit?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeySwap?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyTransferBetweenWallets?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyWithdrawal?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyWithdrawalConfirm?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_ExternalAPIKeyWithdrawalKeySpecifier = (
  | 'amount'
  | 'createdAt'
  | 'currency'
  | 'exchange'
  | 'id'
  | 'toAddress'
  | 'userID'
  | 'withdrawalID'
  | Strategy_ExternalAPIKeyWithdrawalKeySpecifier
)[]
export type Strategy_ExternalAPIKeyWithdrawalFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  exchange?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  toAddress?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawalID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_GenericFlowMutationKeySpecifier = (
  | 'withdrawalCryptoStart'
  | 'withdrawalFiatStart'
  | 'withdrawalRetry'
  | Strategy_GenericFlowMutationKeySpecifier
)[]
export type Strategy_GenericFlowMutationFieldPolicy = {
  withdrawalCryptoStart?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawalFiatStart?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawalRetry?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_LendingOfferKeySpecifier = (
  | 'amount'
  | 'createdAt'
  | 'id'
  | 'period'
  | 'positionPair'
  | 'rate'
  | Strategy_LendingOfferKeySpecifier
)[]
export type Strategy_LendingOfferFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  positionPair?: FieldPolicy<any> | FieldReadFunction<any>
  rate?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_LendingStateKeySpecifier = (
  | 'activeOffers'
  | 'openOffers'
  | 'updatedAt'
  | Strategy_LendingStateKeySpecifier
)[]
export type Strategy_LendingStateFieldPolicy = {
  activeOffers?: FieldPolicy<any> | FieldReadFunction<any>
  openOffers?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_PerformanceKeySpecifier = (
  | 'amount'
  | 'apy'
  | 'currency'
  | 'ror'
  | Strategy_PerformanceKeySpecifier
)[]
export type Strategy_PerformanceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  ror?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_PublicKeySpecifier = ('strategies' | Strategy_PublicKeySpecifier)[]
export type Strategy_PublicFieldPolicy = {
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyKeySpecifier = (
  | 'closedAt'
  | 'createdAt'
  | 'debt'
  | 'displayInfo'
  | 'errMsg'
  | 'id'
  | 'info'
  | 'parameters'
  | 'performance'
  | 'principal'
  | 'sid'
  | 'state'
  | 'status'
  | 'updatedAt'
  | 'workflows'
  | 'yieldRecords'
  | Strategy_StrategyKeySpecifier
)[]
export type Strategy_StrategyFieldPolicy = {
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  debt?: FieldPolicy<any> | FieldReadFunction<any>
  displayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  errMsg?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  info?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  performance?: FieldPolicy<any> | FieldReadFunction<any>
  principal?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  state?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  workflows?: FieldPolicy<any> | FieldReadFunction<any>
  yieldRecords?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyDebtKeySpecifier = (
  | 'quantity'
  | 'records'
  | Strategy_StrategyDebtKeySpecifier
)[]
export type Strategy_StrategyDebtFieldPolicy = {
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  records?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyDescriptionKeySpecifier = (
  | 'key'
  | 'value'
  | Strategy_StrategyDescriptionKeySpecifier
)[]
export type Strategy_StrategyDescriptionFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyInfoKeySpecifier = (
  | 'closeGasFee'
  | 'currency'
  | 'descriptionDetail'
  | 'descriptionList'
  | 'displayInfo'
  | 'earningCurrency'
  | 'exchanges'
  | 'expectedAPY'
  | 'feePerHourAmount'
  | 'name'
  | 'numberOfApplication'
  | 'openGasFee'
  | 'parameterDescriptions'
  | 'pointPerHourAmount'
  | 'profits'
  | 'riskLevel'
  | 'sid'
  | 'suggestionPeriod'
  | 'target'
  | 'type'
  | Strategy_StrategyInfoKeySpecifier
)[]
export type Strategy_StrategyInfoFieldPolicy = {
  closeGasFee?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  descriptionDetail?: FieldPolicy<any> | FieldReadFunction<any>
  descriptionList?: FieldPolicy<any> | FieldReadFunction<any>
  displayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  earningCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  exchanges?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  feePerHourAmount?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numberOfApplication?: FieldPolicy<any> | FieldReadFunction<any>
  openGasFee?: FieldPolicy<any> | FieldReadFunction<any>
  parameterDescriptions?: FieldPolicy<any> | FieldReadFunction<any>
  pointPerHourAmount?: FieldPolicy<any> | FieldReadFunction<any>
  profits?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  suggestionPeriod?: FieldPolicy<any> | FieldReadFunction<any>
  target?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyMutationKeySpecifier = (
  | 'strategyClose'
  | 'strategyDeFiStart'
  | 'strategyRetry'
  | 'strategyStart'
  | 'strategyStop'
  | 'strategyUpdate'
  | Strategy_StrategyMutationKeySpecifier
)[]
export type Strategy_StrategyMutationFieldPolicy = {
  strategyClose?: FieldPolicy<any> | FieldReadFunction<any>
  strategyDeFiStart?: FieldPolicy<any> | FieldReadFunction<any>
  strategyRetry?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStart?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStop?: FieldPolicy<any> | FieldReadFunction<any>
  strategyUpdate?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyParameterKeySpecifier = (
  | 'key'
  | 'value'
  | Strategy_StrategyParameterKeySpecifier
)[]
export type Strategy_StrategyParameterFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyParameterDescKeySpecifier = (
  | 'defaultValue'
  | 'description'
  | 'key'
  | 'maxValue'
  | 'minValue'
  | 'name'
  | 'type'
  | 'updatable'
  | Strategy_StrategyParameterDescKeySpecifier
)[]
export type Strategy_StrategyParameterDescFieldPolicy = {
  defaultValue?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  maxValue?: FieldPolicy<any> | FieldReadFunction<any>
  minValue?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_StrategyPerformanceKeySpecifier = (
  | 'duration'
  | 'initialValue'
  | 'performances'
  | Strategy_StrategyPerformanceKeySpecifier
)[]
export type Strategy_StrategyPerformanceFieldPolicy = {
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  initialValue?: FieldPolicy<any> | FieldReadFunction<any>
  performances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_UserKeySpecifier = (
  | 'actionLogs'
  | 'estimatePointIsEnough'
  | 'estimatePointsConsuming'
  | 'estimateStrategyRequiredAmount'
  | 'exchangeBalances'
  | 'exchangeWithdrawalAuthorization'
  | 'exchangesAssets'
  | 'exchangesNetAssetChart'
  | 'externalAPIKeys'
  | 'profits'
  | 'strategies'
  | 'workflows'
  | Strategy_UserKeySpecifier
)[]
export type Strategy_UserFieldPolicy = {
  actionLogs?: FieldPolicy<any> | FieldReadFunction<any>
  estimatePointIsEnough?: FieldPolicy<any> | FieldReadFunction<any>
  estimatePointsConsuming?: FieldPolicy<any> | FieldReadFunction<any>
  estimateStrategyRequiredAmount?: FieldPolicy<any> | FieldReadFunction<any>
  exchangeBalances?: FieldPolicy<any> | FieldReadFunction<any>
  exchangeWithdrawalAuthorization?: FieldPolicy<any> | FieldReadFunction<any>
  exchangesAssets?: FieldPolicy<any> | FieldReadFunction<any>
  exchangesNetAssetChart?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeys?: FieldPolicy<any> | FieldReadFunction<any>
  profits?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  workflows?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_UserMutationKeySpecifier = (
  | 'externalAPIKey'
  | 'genericFlow'
  | 'repayStrategyDebt'
  | 'strategy'
  | Strategy_UserMutationKeySpecifier
)[]
export type Strategy_UserMutationFieldPolicy = {
  externalAPIKey?: FieldPolicy<any> | FieldReadFunction<any>
  genericFlow?: FieldPolicy<any> | FieldReadFunction<any>
  repayStrategyDebt?: FieldPolicy<any> | FieldReadFunction<any>
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_UserProfitKeySpecifier = (
  | 'dailyProfits'
  | 'sid'
  | 'totalProfits'
  | 'type'
  | Strategy_UserProfitKeySpecifier
)[]
export type Strategy_UserProfitFieldPolicy = {
  dailyProfits?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  totalProfits?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_WorkflowKeySpecifier = (
  | 'actions'
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'type'
  | 'updatedAt'
  | Strategy_WorkflowKeySpecifier
)[]
export type Strategy_WorkflowFieldPolicy = {
  actions?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Strategy_YieldRecordKeySpecifier = (
  | 'amount'
  | 'currency'
  | 'id'
  | 'rate'
  | 'time'
  | 'type'
  | Strategy_YieldRecordKeySpecifier
)[]
export type Strategy_YieldRecordFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SysKeySpecifier = (
  | 'environmentId'
  | 'firstPublishedAt'
  | 'id'
  | 'publishedAt'
  | 'publishedVersion'
  | 'spaceId'
  | SysKeySpecifier
)[]
export type SysFieldPolicy = {
  environmentId?: FieldPolicy<any> | FieldReadFunction<any>
  firstPublishedAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  publishedAt?: FieldPolicy<any> | FieldReadFunction<any>
  publishedVersion?: FieldPolicy<any> | FieldReadFunction<any>
  spaceId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TokenBalanceKeySpecifier = (
  | 'address'
  | 'amount'
  | 'amountReadable'
  | 'chain'
  | 'decimals'
  | 'id'
  | 'symbol'
  | TokenBalanceKeySpecifier
)[]
export type TokenBalanceFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  amountReadable?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TokenDisplayInfoKeySpecifier = (
  | 'address'
  | 'chain'
  | 'id'
  | 'imageURL'
  | 'name'
  | 'symbol'
  | TokenDisplayInfoKeySpecifier
)[]
export type TokenDisplayInfoFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  imageURL?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TotalProfitsKeySpecifier = ('amount' | 'currency' | TotalProfitsKeySpecifier)[]
export type TotalProfitsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradeKeySpecifier = (
  | 'createdAt'
  | 'fee'
  | 'feeCurrency'
  | 'feeRate'
  | 'id'
  | 'market'
  | 'price'
  | 'quoteBaseCurrency'
  | 'quoteOrderCurrency'
  | 'side'
  | 'size'
  | 'updatedAt'
  | 'userID'
  | TradeKeySpecifier
)[]
export type TradeFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  feeCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  feeRate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  market?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  quoteBaseCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  quoteOrderCurrency?: FieldPolicy<any> | FieldReadFunction<any>
  side?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradeResponseKeySpecifier = ('data' | 'meta' | 'result' | TradeResponseKeySpecifier)[]
export type TradeResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradingKeySpecifier = ('history' | 'report' | TradingKeySpecifier)[]
export type TradingFieldPolicy = {
  history?: FieldPolicy<any> | FieldReadFunction<any>
  report?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradingConfirmQuotationResponseKeySpecifier = (
  | 'result'
  | TradingConfirmQuotationResponseKeySpecifier
)[]
export type TradingConfirmQuotationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradingMutationKeySpecifier = (
  | 'confirmQuotation'
  | 'getQuotation'
  | TradingMutationKeySpecifier
)[]
export type TradingMutationFieldPolicy = {
  confirmQuotation?: FieldPolicy<any> | FieldReadFunction<any>
  getQuotation?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradingQuotationResponseKeySpecifier = (
  | 'data'
  | 'result'
  | TradingQuotationResponseKeySpecifier
)[]
export type TradingQuotationResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TradingReportResponseKeySpecifier = (
  | 'data'
  | 'result'
  | TradingReportResponseKeySpecifier
)[]
export type TradingReportResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapOnchainPositionAmountInfoKeySpecifier = (
  | 'token0'
  | 'token0Amount'
  | 'token0AmountUsdValue'
  | 'token1'
  | 'token1Amount'
  | 'token1AmountUsdValue'
  | UniswapOnchainPositionAmountInfoKeySpecifier
)[]
export type UniswapOnchainPositionAmountInfoFieldPolicy = {
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Amount?: FieldPolicy<any> | FieldReadFunction<any>
  token0AmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Amount?: FieldPolicy<any> | FieldReadFunction<any>
  token1AmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapPoolInfoKeySpecifier = (
  | 'averageTradingVolumeUSD'
  | 'chain'
  | 'collectedFeesToken0'
  | 'collectedFeesToken1'
  | 'collectedFeesUSD'
  | 'createdAtBlockNumber'
  | 'createdAtTimestamp'
  | 'feeGrowthGlobal0X128'
  | 'feeGrowthGlobal1X128'
  | 'feeTier'
  | 'feesUSD'
  | 'id'
  | 'isPoolActiveForReyield'
  | 'liquidity'
  | 'liquidityProviderCount'
  | 'observationIndex'
  | 'positions'
  | 'sqrtPrice'
  | 'tick'
  | 'ticks'
  | 'token0'
  | 'token0Price'
  | 'token1'
  | 'token1Price'
  | 'totalValueLockedETH'
  | 'totalValueLockedToken0'
  | 'totalValueLockedToken1'
  | 'totalValueLockedUSD'
  | 'totalValueLockedUSDUntracked'
  | 'txCount'
  | 'untrackedVolumeUSD'
  | 'volumeToken0'
  | 'volumeToken1'
  | 'volumeUSD'
  | UniswapPoolInfoKeySpecifier
)[]
export type UniswapPoolInfoFieldPolicy = {
  averageTradingVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken0?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken1?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  createdAtBlockNumber?: FieldPolicy<any> | FieldReadFunction<any>
  createdAtTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthGlobal0X128?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthGlobal1X128?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  feesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isPoolActiveForReyield?: FieldPolicy<any> | FieldReadFunction<any>
  liquidity?: FieldPolicy<any> | FieldReadFunction<any>
  liquidityProviderCount?: FieldPolicy<any> | FieldReadFunction<any>
  observationIndex?: FieldPolicy<any> | FieldReadFunction<any>
  positions?: FieldPolicy<any> | FieldReadFunction<any>
  sqrtPrice?: FieldPolicy<any> | FieldReadFunction<any>
  tick?: FieldPolicy<any> | FieldReadFunction<any>
  ticks?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Price?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Price?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedETH?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedToken0?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedToken1?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSD?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSDUntracked?: FieldPolicy<any> | FieldReadFunction<any>
  txCount?: FieldPolicy<any> | FieldReadFunction<any>
  untrackedVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  volumeToken0?: FieldPolicy<any> | FieldReadFunction<any>
  volumeToken1?: FieldPolicy<any> | FieldReadFunction<any>
  volumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapPoolPositionKeySpecifier = (
  | 'collectedFeesToken0'
  | 'collectedFeesToken1'
  | 'depositedToken0'
  | 'depositedToken1'
  | 'feeGrowthInside0LastX128'
  | 'feeGrowthInside1LastX128'
  | 'id'
  | 'liquidity'
  | 'tickLowerFeeGrowthOutside0X128'
  | 'tickLowerFeeGrowthOutside1X128'
  | 'tickLowerTickIdx'
  | 'tickUpperFeeGrowthOutside0X128'
  | 'tickUpperFeeGrowthOutside1X128'
  | 'tickUpperTickIdx'
  | 'transactionTimestamp'
  | UniswapPoolPositionKeySpecifier
)[]
export type UniswapPoolPositionFieldPolicy = {
  collectedFeesToken0?: FieldPolicy<any> | FieldReadFunction<any>
  collectedFeesToken1?: FieldPolicy<any> | FieldReadFunction<any>
  depositedToken0?: FieldPolicy<any> | FieldReadFunction<any>
  depositedToken1?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthInside0LastX128?: FieldPolicy<any> | FieldReadFunction<any>
  feeGrowthInside1LastX128?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  liquidity?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerFeeGrowthOutside0X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerFeeGrowthOutside1X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerTickIdx?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperFeeGrowthOutside0X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperFeeGrowthOutside1X128?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperTickIdx?: FieldPolicy<any> | FieldReadFunction<any>
  transactionTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapPoolTickKeySpecifier = (
  | 'id'
  | 'liquidityNet'
  | 'price0'
  | 'price1'
  | 'tickIdx'
  | UniswapPoolTickKeySpecifier
)[]
export type UniswapPoolTickFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  liquidityNet?: FieldPolicy<any> | FieldReadFunction<any>
  price0?: FieldPolicy<any> | FieldReadFunction<any>
  price1?: FieldPolicy<any> | FieldReadFunction<any>
  tickIdx?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapQuoteKeySpecifier = (
  | 'chain'
  | 'fromToken'
  | 'fromTokenAddress'
  | 'id'
  | 'inputAmount'
  | 'inputReadableAmount'
  | 'outputAmount'
  | 'outputReadableAmount'
  | 'toToken'
  | 'toTokenAddress'
  | UniswapQuoteKeySpecifier
)[]
export type UniswapQuoteFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  fromToken?: FieldPolicy<any> | FieldReadFunction<any>
  fromTokenAddress?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  inputAmount?: FieldPolicy<any> | FieldReadFunction<any>
  inputReadableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  outputAmount?: FieldPolicy<any> | FieldReadFunction<any>
  outputReadableAmount?: FieldPolicy<any> | FieldReadFunction<any>
  toToken?: FieldPolicy<any> | FieldReadFunction<any>
  toTokenAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolFeeTierKeySpecifier = (
  | 'feeForContract'
  | 'feeForDisplay'
  | 'id'
  | UniswapToolFeeTierKeySpecifier
)[]
export type UniswapToolFeeTierFieldPolicy = {
  feeForContract?: FieldPolicy<any> | FieldReadFunction<any>
  feeForDisplay?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolInfoKeySpecifier = (
  | 'chain'
  | 'createdAt'
  | 'creator'
  | 'creatorAddress'
  | 'creatorId'
  | 'description'
  | 'expectedAPY'
  | 'feeTier'
  | 'id'
  | 'isOfficial'
  | 'maximumLoss'
  | 'name'
  | 'numOfApply'
  | 'performanceFee'
  | 'poolAddress'
  | 'poolInfo'
  | 'riskLevel'
  | 'tags'
  | 'tickLowerDiff'
  | 'tickLowerDiffForContract'
  | 'tickUpperDiff'
  | 'tickUpperDiffForContract'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1Address'
  | 'token1DisplayInfo'
  | UniswapToolInfoKeySpecifier
)[]
export type UniswapToolInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  creator?: FieldPolicy<any> | FieldReadFunction<any>
  creatorAddress?: FieldPolicy<any> | FieldReadFunction<any>
  creatorId?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  expectedAPY?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  numOfApply?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFee?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  poolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  riskLevel?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiffForContract?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiffForContract?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTagKeySpecifier = ('id' | 'name' | 'type' | UniswapToolTagKeySpecifier)[]
export type UniswapToolTagFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTokenKeySpecifier = (
  | 'chain'
  | 'decimals'
  | 'derivedETH'
  | 'feesUSD'
  | 'id'
  | 'name'
  | 'poolCount'
  | 'priceChart'
  | 'symbol'
  | 'totalSupply'
  | 'totalValueLocked'
  | 'totalValueLockedUSD'
  | 'totalValueLockedUSDUntracked'
  | 'txCount'
  | 'untrackedVolumeUSD'
  | 'volume'
  | 'volumeUSD'
  | UniswapToolTokenKeySpecifier
)[]
export type UniswapToolTokenFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  decimals?: FieldPolicy<any> | FieldReadFunction<any>
  derivedETH?: FieldPolicy<any> | FieldReadFunction<any>
  feesUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  poolCount?: FieldPolicy<any> | FieldReadFunction<any>
  priceChart?: FieldPolicy<any> | FieldReadFunction<any>
  symbol?: FieldPolicy<any> | FieldReadFunction<any>
  totalSupply?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLocked?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSD?: FieldPolicy<any> | FieldReadFunction<any>
  totalValueLockedUSDUntracked?: FieldPolicy<any> | FieldReadFunction<any>
  txCount?: FieldPolicy<any> | FieldReadFunction<any>
  untrackedVolumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
  volume?: FieldPolicy<any> | FieldReadFunction<any>
  volumeUSD?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTokenPriceChartKeySpecifier = (
  | 'currentPriceUSD'
  | 'id'
  | 'prices'
  | UniswapToolTokenPriceChartKeySpecifier
)[]
export type UniswapToolTokenPriceChartFieldPolicy = {
  currentPriceUSD?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  prices?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapToolTokenPriceChartPriceKeySpecifier = (
  | 'timestamp'
  | 'value'
  | UniswapToolTokenPriceChartPriceKeySpecifier
)[]
export type UniswapToolTokenPriceChartPriceFieldPolicy = {
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UniswapV3BacktestResultKeySpecifier = (
  | 'activeLiquidityPercent'
  | 'annualReturn'
  | 'compound'
  | 'createdAt'
  | 'dailyReturn'
  | 'dataPointsCount'
  | 'days'
  | 'diff'
  | 'fees'
  | 'id'
  | 'impermanentLoss'
  | 'investmentAmount'
  | 'maxDrawdown'
  | 'period'
  | 'poolID'
  | 'protocol'
  | 'totalReturn'
  | 'volatility'
  | UniswapV3BacktestResultKeySpecifier
)[]
export type UniswapV3BacktestResultFieldPolicy = {
  activeLiquidityPercent?: FieldPolicy<any> | FieldReadFunction<any>
  annualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  compound?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  dailyReturn?: FieldPolicy<any> | FieldReadFunction<any>
  dataPointsCount?: FieldPolicy<any> | FieldReadFunction<any>
  days?: FieldPolicy<any> | FieldReadFunction<any>
  diff?: FieldPolicy<any> | FieldReadFunction<any>
  fees?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  impermanentLoss?: FieldPolicy<any> | FieldReadFunction<any>
  investmentAmount?: FieldPolicy<any> | FieldReadFunction<any>
  maxDrawdown?: FieldPolicy<any> | FieldReadFunction<any>
  period?: FieldPolicy<any> | FieldReadFunction<any>
  poolID?: FieldPolicy<any> | FieldReadFunction<any>
  protocol?: FieldPolicy<any> | FieldReadFunction<any>
  totalReturn?: FieldPolicy<any> | FieldReadFunction<any>
  volatility?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserKeySpecifier = (
  | 'actionLogs'
  | 'agreements'
  | 'amlInfo'
  | 'createdAt'
  | 'email'
  | 'id'
  | 'is2FAEnabled'
  | 'isDisabled'
  | 'isOldUser'
  | 'kycInfo'
  | 'loginRecords'
  | 'notifications'
  | 'oauthClients'
  | 'phone'
  | 'point'
  | 'preference'
  | 'questionnaires'
  | 'referralCodes'
  | 'referralCount'
  | 'referrer'
  | 'roles'
  | 'scopes'
  | 'special'
  | 'trading'
  | 'updatedAt'
  | 'vipLevel'
  | 'voucher'
  | 'wallet'
  | UserKeySpecifier
)[]
export type UserFieldPolicy = {
  actionLogs?: FieldPolicy<any> | FieldReadFunction<any>
  agreements?: FieldPolicy<any> | FieldReadFunction<any>
  amlInfo?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  is2FAEnabled?: FieldPolicy<any> | FieldReadFunction<any>
  isDisabled?: FieldPolicy<any> | FieldReadFunction<any>
  isOldUser?: FieldPolicy<any> | FieldReadFunction<any>
  kycInfo?: FieldPolicy<any> | FieldReadFunction<any>
  loginRecords?: FieldPolicy<any> | FieldReadFunction<any>
  notifications?: FieldPolicy<any> | FieldReadFunction<any>
  oauthClients?: FieldPolicy<any> | FieldReadFunction<any>
  phone?: FieldPolicy<any> | FieldReadFunction<any>
  point?: FieldPolicy<any> | FieldReadFunction<any>
  preference?: FieldPolicy<any> | FieldReadFunction<any>
  questionnaires?: FieldPolicy<any> | FieldReadFunction<any>
  referralCodes?: FieldPolicy<any> | FieldReadFunction<any>
  referralCount?: FieldPolicy<any> | FieldReadFunction<any>
  referrer?: FieldPolicy<any> | FieldReadFunction<any>
  roles?: FieldPolicy<any> | FieldReadFunction<any>
  scopes?: FieldPolicy<any> | FieldReadFunction<any>
  special?: FieldPolicy<any> | FieldReadFunction<any>
  trading?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  vipLevel?: FieldPolicy<any> | FieldReadFunction<any>
  voucher?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserActionLogKeySpecifier = (
  | 'action'
  | 'description'
  | 'id'
  | 'operator'
  | 'timestamp'
  | UserActionLogKeySpecifier
)[]
export type UserActionLogFieldPolicy = {
  action?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  operator?: FieldPolicy<any> | FieldReadFunction<any>
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserActionLogResponseKeySpecifier = (
  | 'logs'
  | 'meta'
  | 'result'
  | UserActionLogResponseKeySpecifier
)[]
export type UserActionLogResponseFieldPolicy = {
  logs?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserAgreeResponseKeySpecifier = ('result' | UserAgreeResponseKeySpecifier)[]
export type UserAgreeResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBindPhoneConfirmationResponseKeySpecifier = (
  | 'result'
  | UserBindPhoneConfirmationResponseKeySpecifier
)[]
export type UserBindPhoneConfirmationResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserBindPhoneResponseKeySpecifier = (
  | 'id'
  | 'result'
  | UserBindPhoneResponseKeySpecifier
)[]
export type UserBindPhoneResponseFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserCancelOAuthResponseKeySpecifier = ('result' | UserCancelOAuthResponseKeySpecifier)[]
export type UserCancelOAuthResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserChangePasswordResponseKeySpecifier = (
  | 'result'
  | UserChangePasswordResponseKeySpecifier
)[]
export type UserChangePasswordResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserCustomizeReferralResponseKeySpecifier = (
  | 'data'
  | 'result'
  | UserCustomizeReferralResponseKeySpecifier
)[]
export type UserCustomizeReferralResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserDeleteBankAccountResponseKeySpecifier = (
  | 'result'
  | UserDeleteBankAccountResponseKeySpecifier
)[]
export type UserDeleteBankAccountResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserDeviceTokenResponseKeySpecifier = ('result' | UserDeviceTokenResponseKeySpecifier)[]
export type UserDeviceTokenResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserFillReferrerCodeResponseKeySpecifier = (
  | 'result'
  | UserFillReferrerCodeResponseKeySpecifier
)[]
export type UserFillReferrerCodeResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserForgotPasswordConfirmResponseKeySpecifier = (
  | 'result'
  | UserForgotPasswordConfirmResponseKeySpecifier
)[]
export type UserForgotPasswordConfirmResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserForgotPasswordResponseKeySpecifier = (
  | 'result'
  | UserForgotPasswordResponseKeySpecifier
)[]
export type UserForgotPasswordResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserInfoKeySpecifier = (
  | 'aICompletionRecords'
  | 'cefiDepositAddress'
  | 'cefiWallets'
  | 'defiTokensBalanceAmount'
  | 'defiWallets'
  | 'externalAPIKeys'
  | 'gimCoolPayPaymentInfo'
  | 'id'
  | 'isBitfinexAPIKeyBind'
  | 'nftCollections'
  | 'nftMerkleTreeHexProof'
  | 'nftWhitelistInfoByPhrases'
  | 'ownedToolsSetting'
  | 'reyieldLicense'
  | 'strategies'
  | 'strategyPerformance'
  | 'strategyProfit'
  | 'strategyProgress'
  | 'strategyRecords'
  | 'tools'
  | UserInfoKeySpecifier
)[]
export type UserInfoFieldPolicy = {
  aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  cefiDepositAddress?: FieldPolicy<any> | FieldReadFunction<any>
  cefiWallets?: FieldPolicy<any> | FieldReadFunction<any>
  defiTokensBalanceAmount?: FieldPolicy<any> | FieldReadFunction<any>
  defiWallets?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeys?: FieldPolicy<any> | FieldReadFunction<any>
  gimCoolPayPaymentInfo?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isBitfinexAPIKeyBind?: FieldPolicy<any> | FieldReadFunction<any>
  nftCollections?: FieldPolicy<any> | FieldReadFunction<any>
  nftMerkleTreeHexProof?: FieldPolicy<any> | FieldReadFunction<any>
  nftWhitelistInfoByPhrases?: FieldPolicy<any> | FieldReadFunction<any>
  ownedToolsSetting?: FieldPolicy<any> | FieldReadFunction<any>
  reyieldLicense?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  strategyPerformance?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProfit?: FieldPolicy<any> | FieldReadFunction<any>
  strategyProgress?: FieldPolicy<any> | FieldReadFunction<any>
  strategyRecords?: FieldPolicy<any> | FieldReadFunction<any>
  tools?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserLoginRecordKeySpecifier = (
  | 'browser'
  | 'device'
  | 'ipAddress'
  | 'ipCountry'
  | 'os'
  | 'timestamp'
  | 'userAgent'
  | UserLoginRecordKeySpecifier
)[]
export type UserLoginRecordFieldPolicy = {
  browser?: FieldPolicy<any> | FieldReadFunction<any>
  device?: FieldPolicy<any> | FieldReadFunction<any>
  ipAddress?: FieldPolicy<any> | FieldReadFunction<any>
  ipCountry?: FieldPolicy<any> | FieldReadFunction<any>
  os?: FieldPolicy<any> | FieldReadFunction<any>
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>
  userAgent?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserLoginRecordsResponseKeySpecifier = (
  | 'meta'
  | 'records'
  | 'result'
  | UserLoginRecordsResponseKeySpecifier
)[]
export type UserLoginRecordsResponseFieldPolicy = {
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  records?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserLoginResponseKeySpecifier = ('data' | 'result' | UserLoginResponseKeySpecifier)[]
export type UserLoginResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserMutationKeySpecifier = (
  | 'userAgree'
  | 'userBindPhone'
  | 'userBindPhoneConfirmation'
  | 'userCancelOAuth'
  | 'userChangePassword'
  | 'userCustomizeReferral'
  | 'userDeleteBankAccount'
  | 'userFillReferrerCode'
  | 'userForgotPassword'
  | 'userForgotPasswordConfirm'
  | 'userLogin'
  | 'userRefreshToken'
  | 'userRegister'
  | 'userRegisterConfirm'
  | 'userRegisterDeviceToken'
  | 'userRequestAccountDisable'
  | 'userResendForgotPassword'
  | 'userResendRegister'
  | 'userSetPreference'
  | 'userSubmitQuestionnaire'
  | UserMutationKeySpecifier
)[]
export type UserMutationFieldPolicy = {
  userAgree?: FieldPolicy<any> | FieldReadFunction<any>
  userBindPhone?: FieldPolicy<any> | FieldReadFunction<any>
  userBindPhoneConfirmation?: FieldPolicy<any> | FieldReadFunction<any>
  userCancelOAuth?: FieldPolicy<any> | FieldReadFunction<any>
  userChangePassword?: FieldPolicy<any> | FieldReadFunction<any>
  userCustomizeReferral?: FieldPolicy<any> | FieldReadFunction<any>
  userDeleteBankAccount?: FieldPolicy<any> | FieldReadFunction<any>
  userFillReferrerCode?: FieldPolicy<any> | FieldReadFunction<any>
  userForgotPassword?: FieldPolicy<any> | FieldReadFunction<any>
  userForgotPasswordConfirm?: FieldPolicy<any> | FieldReadFunction<any>
  userLogin?: FieldPolicy<any> | FieldReadFunction<any>
  userRefreshToken?: FieldPolicy<any> | FieldReadFunction<any>
  userRegister?: FieldPolicy<any> | FieldReadFunction<any>
  userRegisterConfirm?: FieldPolicy<any> | FieldReadFunction<any>
  userRegisterDeviceToken?: FieldPolicy<any> | FieldReadFunction<any>
  userRequestAccountDisable?: FieldPolicy<any> | FieldReadFunction<any>
  userResendForgotPassword?: FieldPolicy<any> | FieldReadFunction<any>
  userResendRegister?: FieldPolicy<any> | FieldReadFunction<any>
  userSetPreference?: FieldPolicy<any> | FieldReadFunction<any>
  userSubmitQuestionnaire?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserOAuthClientKeySpecifier = (
  | 'clientID'
  | 'isAuthorized'
  | 'name'
  | 'updatedAt'
  | UserOAuthClientKeySpecifier
)[]
export type UserOAuthClientFieldPolicy = {
  clientID?: FieldPolicy<any> | FieldReadFunction<any>
  isAuthorized?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserOAuthClientResponseKeySpecifier = (
  | 'clients'
  | 'meta'
  | 'result'
  | UserOAuthClientResponseKeySpecifier
)[]
export type UserOAuthClientResponseFieldPolicy = {
  clients?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPreferenceKeySpecifier = ('currency' | 'language' | UserPreferenceKeySpecifier)[]
export type UserPreferenceFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPreferenceResponseKeySpecifier = (
  | 'data'
  | 'result'
  | UserPreferenceResponseKeySpecifier
)[]
export type UserPreferenceResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserReferralKeySpecifier = ('code' | UserReferralKeySpecifier)[]
export type UserReferralFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserRefreshTokenResponseKeySpecifier = (
  | 'data'
  | 'result'
  | UserRefreshTokenResponseKeySpecifier
)[]
export type UserRefreshTokenResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserRegisterConfirmResponseKeySpecifier = (
  | 'result'
  | UserRegisterConfirmResponseKeySpecifier
)[]
export type UserRegisterConfirmResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserRegisterResponseKeySpecifier = ('result' | UserRegisterResponseKeySpecifier)[]
export type UserRegisterResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserRequestAccountDisableResponseKeySpecifier = (
  | 'result'
  | UserRequestAccountDisableResponseKeySpecifier
)[]
export type UserRequestAccountDisableResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserResendForgotPasswordResponseKeySpecifier = (
  | 'result'
  | UserResendForgotPasswordResponseKeySpecifier
)[]
export type UserResendForgotPasswordResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserResendRegisterResponseKeySpecifier = (
  | 'result'
  | UserResendRegisterResponseKeySpecifier
)[]
export type UserResendRegisterResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyInfoKeySpecifier = (
  | 'ceFiStatus'
  | 'closedAt'
  | 'createdAt'
  | 'errMsg'
  | 'id'
  | 'infos'
  | 'parameters'
  | 'performance'
  | 'profits'
  | 'sid'
  | 'updatedAt'
  | UserStrategyInfoKeySpecifier
)[]
export type UserStrategyInfoFieldPolicy = {
  ceFiStatus?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  errMsg?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  infos?: FieldPolicy<any> | FieldReadFunction<any>
  parameters?: FieldPolicy<any> | FieldReadFunction<any>
  performance?: FieldPolicy<any> | FieldReadFunction<any>
  profits?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyParametersKeySpecifier = (
  | 'key'
  | 'value'
  | UserStrategyParametersKeySpecifier
)[]
export type UserStrategyParametersFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyPerformanceKeySpecifier = (
  | 'duration'
  | 'initialValue'
  | 'performances'
  | UserStrategyPerformanceKeySpecifier
)[]
export type UserStrategyPerformanceFieldPolicy = {
  duration?: FieldPolicy<any> | FieldReadFunction<any>
  initialValue?: FieldPolicy<any> | FieldReadFunction<any>
  performances?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyProfitKeySpecifier = (
  | 'sid'
  | 'totalProfit'
  | 'type'
  | UserStrategyProfitKeySpecifier
)[]
export type UserStrategyProfitFieldPolicy = {
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  totalProfit?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserStrategyRecordKeySpecifier = (
  | 'amount'
  | 'currency'
  | 'id'
  | 'rate'
  | 'time'
  | 'type'
  | UserStrategyRecordKeySpecifier
)[]
export type UserStrategyRecordFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>
  currency?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  rate?: FieldPolicy<any> | FieldReadFunction<any>
  time?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapOnchainPositionInfoKeySpecifier = (
  | 'apy'
  | 'chain'
  | 'closedPositionAmount'
  | 'positionId'
  | 'positionIdFromSmartContractPositionManager'
  | 'roi'
  | 'runningPositionAmount'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'totalInvestAmountUsdValue'
  | UserUniswapOnchainPositionInfoKeySpecifier
)[]
export type UserUniswapOnchainPositionInfoFieldPolicy = {
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdFromSmartContractPositionManager?: FieldPolicy<any> | FieldReadFunction<any>
  roi?: FieldPolicy<any> | FieldReadFunction<any>
  runningPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  totalInvestAmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapPositionInfoKeySpecifier = (
  | 'apy'
  | 'chain'
  | 'closedAt'
  | 'closedPositionAmount'
  | 'createdAt'
  | 'feeTier'
  | 'id'
  | 'onchainPositionInfo'
  | 'poolAddress'
  | 'positionId'
  | 'positionIdFromSmartContractPositionManager'
  | 'progresses'
  | 'publicToolInfo'
  | 'roi'
  | 'runningPositionAmount'
  | 'status'
  | 'tickLowerDiff'
  | 'tickUpperDiff'
  | 'token0'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'toolDescription'
  | 'toolId'
  | 'toolIsOfficial'
  | 'toolName'
  | 'toolPerformanceFeeRatio'
  | 'toolSetting'
  | 'totalInvestAmountUsdValue'
  | 'updatedAt'
  | 'userAddress'
  | UserUniswapPositionInfoKeySpecifier
)[]
export type UserUniswapPositionInfoFieldPolicy = {
  apy?: FieldPolicy<any> | FieldReadFunction<any>
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  closedPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  onchainPositionInfo?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  positionId?: FieldPolicy<any> | FieldReadFunction<any>
  positionIdFromSmartContractPositionManager?: FieldPolicy<any> | FieldReadFunction<any>
  progresses?: FieldPolicy<any> | FieldReadFunction<any>
  publicToolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  roi?: FieldPolicy<any> | FieldReadFunction<any>
  runningPositionAmount?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  toolDescription?: FieldPolicy<any> | FieldReadFunction<any>
  toolId?: FieldPolicy<any> | FieldReadFunction<any>
  toolIsOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  toolName?: FieldPolicy<any> | FieldReadFunction<any>
  toolPerformanceFeeRatio?: FieldPolicy<any> | FieldReadFunction<any>
  toolSetting?: FieldPolicy<any> | FieldReadFunction<any>
  totalInvestAmountUsdValue?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapToolOutOfRangeInfoKeySpecifier = (
  | 'earnSpendRate'
  | 'maxRebalanceTimes'
  | 'standbyInterval'
  | 'standbyMaxInterval'
  | UserUniswapToolOutOfRangeInfoKeySpecifier
)[]
export type UserUniswapToolOutOfRangeInfoFieldPolicy = {
  earnSpendRate?: FieldPolicy<any> | FieldReadFunction<any>
  maxRebalanceTimes?: FieldPolicy<any> | FieldReadFunction<any>
  standbyInterval?: FieldPolicy<any> | FieldReadFunction<any>
  standbyMaxInterval?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapToolSettingInfoKeySpecifier = (
  | 'chain'
  | 'closedAt'
  | 'createdAt'
  | 'description'
  | 'feeTier'
  | 'id'
  | 'isOfficial'
  | 'name'
  | 'outOfLowerRange'
  | 'outOfUpperRange'
  | 'performanceFeeRatio'
  | 'poolAddress'
  | 'position'
  | 'publicToolInfo'
  | 'status'
  | 'tickLowerDiff'
  | 'tickUpperDiff'
  | 'token0'
  | 'token0Address'
  | 'token0DisplayInfo'
  | 'token1'
  | 'token1Address'
  | 'token1DisplayInfo'
  | 'totalUserUsed'
  | 'updatedAt'
  | UserUniswapToolSettingInfoKeySpecifier
)[]
export type UserUniswapToolSettingInfoFieldPolicy = {
  chain?: FieldPolicy<any> | FieldReadFunction<any>
  closedAt?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  feeTier?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isOfficial?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  outOfLowerRange?: FieldPolicy<any> | FieldReadFunction<any>
  outOfUpperRange?: FieldPolicy<any> | FieldReadFunction<any>
  performanceFeeRatio?: FieldPolicy<any> | FieldReadFunction<any>
  poolAddress?: FieldPolicy<any> | FieldReadFunction<any>
  position?: FieldPolicy<any> | FieldReadFunction<any>
  publicToolInfo?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tickLowerDiff?: FieldPolicy<any> | FieldReadFunction<any>
  tickUpperDiff?: FieldPolicy<any> | FieldReadFunction<any>
  token0?: FieldPolicy<any> | FieldReadFunction<any>
  token0Address?: FieldPolicy<any> | FieldReadFunction<any>
  token0DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  token1?: FieldPolicy<any> | FieldReadFunction<any>
  token1Address?: FieldPolicy<any> | FieldReadFunction<any>
  token1DisplayInfo?: FieldPolicy<any> | FieldReadFunction<any>
  totalUserUsed?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapv3PositionProgressKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'index'
  | 'status'
  | 'steps'
  | 'type'
  | 'updatedAt'
  | UserUniswapv3PositionProgressKeySpecifier
)[]
export type UserUniswapv3PositionProgressFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  steps?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUniswapv3PositionProgressStepKeySpecifier = (
  | 'status'
  | 'type'
  | UserUniswapv3PositionProgressStepKeySpecifier
)[]
export type UserUniswapv3PositionProgressStepFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VersionInfoKeySpecifier = (
  | 'buildTime'
  | 'goVersion'
  | 'pbVersion'
  | 'revision'
  | 'version'
  | VersionInfoKeySpecifier
)[]
export type VersionInfoFieldPolicy = {
  buildTime?: FieldPolicy<any> | FieldReadFunction<any>
  goVersion?: FieldPolicy<any> | FieldReadFunction<any>
  pbVersion?: FieldPolicy<any> | FieldReadFunction<any>
  revision?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VoucherKeySpecifier = ('isVoucherUsed' | VoucherKeySpecifier)[]
export type VoucherFieldPolicy = {
  isVoucherUsed?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VoucherMutationKeySpecifier = ('voucherUse' | VoucherMutationKeySpecifier)[]
export type VoucherMutationFieldPolicy = {
  voucherUse?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VoucherRecordKeySpecifier = (
  | 'code'
  | 'description'
  | 'expiredDate'
  | 'isDisabled'
  | 'maxUsageTimes'
  | 'name'
  | 'point'
  | 'type'
  | 'usedTimes'
  | VoucherRecordKeySpecifier
)[]
export type VoucherRecordFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  expiredDate?: FieldPolicy<any> | FieldReadFunction<any>
  isDisabled?: FieldPolicy<any> | FieldReadFunction<any>
  maxUsageTimes?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  point?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  usedTimes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VoucherUseResponseKeySpecifier = (
  | 'point'
  | 'result'
  | 'type'
  | VoucherUseResponseKeySpecifier
)[]
export type VoucherUseResponseFieldPolicy = {
  point?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletKeySpecifier = (
  | 'balances'
  | 'commonAddresses'
  | 'depositCryptoInfo'
  | 'depositFiatInfo'
  | 'deposits'
  | 'faasPayments'
  | 'isInternalTransfer'
  | 'ledgers'
  | 'userID'
  | 'withdrawInfo'
  | 'withdrawals'
  | WalletKeySpecifier
)[]
export type WalletFieldPolicy = {
  balances?: FieldPolicy<any> | FieldReadFunction<any>
  commonAddresses?: FieldPolicy<any> | FieldReadFunction<any>
  depositCryptoInfo?: FieldPolicy<any> | FieldReadFunction<any>
  depositFiatInfo?: FieldPolicy<any> | FieldReadFunction<any>
  deposits?: FieldPolicy<any> | FieldReadFunction<any>
  faasPayments?: FieldPolicy<any> | FieldReadFunction<any>
  isInternalTransfer?: FieldPolicy<any> | FieldReadFunction<any>
  ledgers?: FieldPolicy<any> | FieldReadFunction<any>
  userID?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawInfo?: FieldPolicy<any> | FieldReadFunction<any>
  withdrawals?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletBalancesKeySpecifier = (
  | 'currencyBalances'
  | 'wallet'
  | WalletBalancesKeySpecifier
)[]
export type WalletBalancesFieldPolicy = {
  currencyBalances?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletCreateCommonAddressResponseKeySpecifier = (
  | 'result'
  | WalletCreateCommonAddressResponseKeySpecifier
)[]
export type WalletCreateCommonAddressResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletCreateCryptoAddressResponseKeySpecifier = (
  | 'data'
  | 'result'
  | WalletCreateCryptoAddressResponseKeySpecifier
)[]
export type WalletCreateCryptoAddressResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletCryptoWithdrawResponseKeySpecifier = (
  | 'data'
  | 'result'
  | WalletCryptoWithdrawResponseKeySpecifier
)[]
export type WalletCryptoWithdrawResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletDeleteCommonAddressResponseKeySpecifier = (
  | 'result'
  | WalletDeleteCommonAddressResponseKeySpecifier
)[]
export type WalletDeleteCommonAddressResponseFieldPolicy = {
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletDepositResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | WalletDepositResponseKeySpecifier
)[]
export type WalletDepositResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletFAASPaymentConfirmResponseKeySpecifier = (
  | 'data'
  | 'result'
  | WalletFAASPaymentConfirmResponseKeySpecifier
)[]
export type WalletFAASPaymentConfirmResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletFiatWithdrawResponseKeySpecifier = (
  | 'data'
  | 'result'
  | WalletFiatWithdrawResponseKeySpecifier
)[]
export type WalletFiatWithdrawResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletMutationKeySpecifier = (
  | 'walletCreateCommonAddress'
  | 'walletCreateCryptoAddress'
  | 'walletCryptoWithdraw'
  | 'walletDeleteCommonAddress'
  | 'walletFAASPaymentConfirm'
  | 'walletFiatWithdraw'
  | 'walletWithdrawConfirm'
  | WalletMutationKeySpecifier
)[]
export type WalletMutationFieldPolicy = {
  walletCreateCommonAddress?: FieldPolicy<any> | FieldReadFunction<any>
  walletCreateCryptoAddress?: FieldPolicy<any> | FieldReadFunction<any>
  walletCryptoWithdraw?: FieldPolicy<any> | FieldReadFunction<any>
  walletDeleteCommonAddress?: FieldPolicy<any> | FieldReadFunction<any>
  walletFAASPaymentConfirm?: FieldPolicy<any> | FieldReadFunction<any>
  walletFiatWithdraw?: FieldPolicy<any> | FieldReadFunction<any>
  walletWithdrawConfirm?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletWithdrawConfirmResponseKeySpecifier = (
  | 'data'
  | 'result'
  | WalletWithdrawConfirmResponseKeySpecifier
)[]
export type WalletWithdrawConfirmResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WalletWithdrawalResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | WalletWithdrawalResponseKeySpecifier
)[]
export type WalletWithdrawalResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WithdrawInfoKeySpecifier = (
  | 'dailyAccumulated'
  | 'dailyMaxLimit'
  | 'fee'
  | 'maxLimit'
  | 'minLimit'
  | 'monthlyAccumulated'
  | 'monthlyMaxLimit'
  | WithdrawInfoKeySpecifier
)[]
export type WithdrawInfoFieldPolicy = {
  dailyAccumulated?: FieldPolicy<any> | FieldReadFunction<any>
  dailyMaxLimit?: FieldPolicy<any> | FieldReadFunction<any>
  fee?: FieldPolicy<any> | FieldReadFunction<any>
  maxLimit?: FieldPolicy<any> | FieldReadFunction<any>
  minLimit?: FieldPolicy<any> | FieldReadFunction<any>
  monthlyAccumulated?: FieldPolicy<any> | FieldReadFunction<any>
  monthlyMaxLimit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WithdrawalResponseKeySpecifier = (
  | 'data'
  | 'meta'
  | 'result'
  | WithdrawalResponseKeySpecifier
)[]
export type WithdrawalResponseFieldPolicy = {
  data?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  result?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecordsKeySpecifier = (
  | 'completedAt'
  | 'content'
  | 'createdAt'
  | 'id'
  | 'meta'
  | 'platform'
  | 'promptMutationInput'
  | 'promptMutationName'
  | 'suggestions'
  | 'suggestions_aggregate'
  | 'updatedAt'
  | 'userAddress'
  | 'userId'
  | aICompletionRecordsKeySpecifier
)[]
export type aICompletionRecordsFieldPolicy = {
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  platform?: FieldPolicy<any> | FieldReadFunction<any>
  promptMutationInput?: FieldPolicy<any> | FieldReadFunction<any>
  promptMutationName?: FieldPolicy<any> | FieldReadFunction<any>
  suggestions?: FieldPolicy<any> | FieldReadFunction<any>
  suggestions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userAddress?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type frontendQueryKeySpecifier = ('version' | frontendQueryKeySpecifier)[]
export type frontendQueryFieldPolicy = {
  version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type mutation_rootKeySpecifier = (
  | 'admin'
  | 'auth'
  | 'dev'
  | 'kyc'
  | 'notification'
  | 'point'
  | 'resource'
  | 'reyield'
  | 'strategy'
  | 'trading'
  | 'user'
  | 'voucher'
  | 'wallet'
  | mutation_rootKeySpecifier
)[]
export type mutation_rootFieldPolicy = {
  admin?: FieldPolicy<any> | FieldReadFunction<any>
  auth?: FieldPolicy<any> | FieldReadFunction<any>
  dev?: FieldPolicy<any> | FieldReadFunction<any>
  kyc?: FieldPolicy<any> | FieldReadFunction<any>
  notification?: FieldPolicy<any> | FieldReadFunction<any>
  point?: FieldPolicy<any> | FieldReadFunction<any>
  resource?: FieldPolicy<any> | FieldReadFunction<any>
  reyield?: FieldPolicy<any> | FieldReadFunction<any>
  strategy?: FieldPolicy<any> | FieldReadFunction<any>
  trading?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  voucher?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type reyieldMutationKeySpecifier = (
  | 'closeUniswapv3Position'
  | 'createAICompletionRecord'
  | 'createUniswapv3Position'
  | 'createUniswapv3Tool'
  | 'externalAPIKeyCreate'
  | 'externalAPIKeyDelete'
  | 'strategyStart'
  | 'strategyStop'
  | 'strategyUpdate'
  | reyieldMutationKeySpecifier
)[]
export type reyieldMutationFieldPolicy = {
  closeUniswapv3Position?: FieldPolicy<any> | FieldReadFunction<any>
  createAICompletionRecord?: FieldPolicy<any> | FieldReadFunction<any>
  createUniswapv3Position?: FieldPolicy<any> | FieldReadFunction<any>
  createUniswapv3Tool?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyCreate?: FieldPolicy<any> | FieldReadFunction<any>
  externalAPIKeyDelete?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStart?: FieldPolicy<any> | FieldReadFunction<any>
  strategyStop?: FieldPolicy<any> | FieldReadFunction<any>
  strategyUpdate?: FieldPolicy<any> | FieldReadFunction<any>
}
export type reyieldQueryKeySpecifier = (
  | 'coinPrices'
  | 'me'
  | 'poolsWithArguments'
  | 'rateHistorical'
  | 'strategies'
  | 'tools'
  | 'uniswapQuote'
  | 'uniswapTokens'
  | 'uniswapV3Backtest'
  | 'version'
  | 'wallet'
  | reyieldQueryKeySpecifier
)[]
export type reyieldQueryFieldPolicy = {
  coinPrices?: FieldPolicy<any> | FieldReadFunction<any>
  me?: FieldPolicy<any> | FieldReadFunction<any>
  poolsWithArguments?: FieldPolicy<any> | FieldReadFunction<any>
  rateHistorical?: FieldPolicy<any> | FieldReadFunction<any>
  strategies?: FieldPolicy<any> | FieldReadFunction<any>
  tools?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapQuote?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapTokens?: FieldPolicy<any> | FieldReadFunction<any>
  uniswapV3Backtest?: FieldPolicy<any> | FieldReadFunction<any>
  version?: FieldPolicy<any> | FieldReadFunction<any>
  wallet?: FieldPolicy<any> | FieldReadFunction<any>
}
export type strategyMutationKeySpecifier = ('admin' | 'user' | strategyMutationKeySpecifier)[]
export type strategyMutationFieldPolicy = {
  admin?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type strategyQueryKeySpecifier = ('admin' | 'public' | 'user' | strategyQueryKeySpecifier)[]
export type strategyQueryFieldPolicy = {
  admin?: FieldPolicy<any> | FieldReadFunction<any>
  public?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocationsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | suggestStrategyToolWithAllocationsKeySpecifier
)[]
export type suggestStrategyToolWithAllocationsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | suggestStrategyToolWithAllocations_aggregateKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_max_fieldsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | suggestStrategyToolWithAllocations_max_fieldsKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_max_fieldsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type suggestStrategyToolWithAllocations_min_fieldsKeySpecifier = (
  | 'aICompletionRecordId'
  | 'allocation'
  | 'createdAt'
  | 'description'
  | 'highestAnnualReturn'
  | 'id'
  | 'maximumLoss'
  | 'name'
  | 'sid'
  | 'systemInvestAmountSuggestion'
  | 'systemInvestCurrencySuggestion'
  | 'updatedAt'
  | suggestStrategyToolWithAllocations_min_fieldsKeySpecifier
)[]
export type suggestStrategyToolWithAllocations_min_fieldsFieldPolicy = {
  aICompletionRecordId?: FieldPolicy<any> | FieldReadFunction<any>
  allocation?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  highestAnnualReturn?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  maximumLoss?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sid?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestAmountSuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  systemInvestCurrencySuggestion?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrictTypedTypePolicies = {
  AML?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AMLKeySpecifier | (() => undefined | AMLKeySpecifier)
    fields?: AMLFieldPolicy
  }
  AMLAssessmentItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AMLAssessmentItemKeySpecifier
      | (() => undefined | AMLAssessmentItemKeySpecifier)
    fields?: AMLAssessmentItemFieldPolicy
  }
  AMLEDDRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AMLEDDRecordKeySpecifier | (() => undefined | AMLEDDRecordKeySpecifier)
    fields?: AMLEDDRecordFieldPolicy
  }
  AMLItemCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AMLItemCategoryKeySpecifier
      | (() => undefined | AMLItemCategoryKeySpecifier)
    fields?: AMLItemCategoryFieldPolicy
  }
  AMLNSRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AMLNSRecordKeySpecifier | (() => undefined | AMLNSRecordKeySpecifier)
    fields?: AMLNSRecordFieldPolicy
  }
  AMLRARecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AMLRARecordKeySpecifier | (() => undefined | AMLRARecordKeySpecifier)
    fields?: AMLRARecordFieldPolicy
  }
  AMLRatingCriteria?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AMLRatingCriteriaKeySpecifier
      | (() => undefined | AMLRatingCriteriaKeySpecifier)
    fields?: AMLRatingCriteriaFieldPolicy
  }
  AMLRiskAssessmentForm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AMLRiskAssessmentFormKeySpecifier
      | (() => undefined | AMLRiskAssessmentFormKeySpecifier)
    fields?: AMLRiskAssessmentFormFieldPolicy
  }
  AMLRiskFactor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AMLRiskFactorKeySpecifier | (() => undefined | AMLRiskFactorKeySpecifier)
    fields?: AMLRiskFactorFieldPolicy
  }
  AMLUserAssessmentResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AMLUserAssessmentResultKeySpecifier
      | (() => undefined | AMLUserAssessmentResultKeySpecifier)
    fields?: AMLUserAssessmentResultFieldPolicy
  }
  AMLVerification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AMLVerificationKeySpecifier
      | (() => undefined | AMLVerificationKeySpecifier)
    fields?: AMLVerificationFieldPolicy
  }
  APIKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | APIKeyKeySpecifier | (() => undefined | APIKeyKeySpecifier)
    fields?: APIKeyFieldPolicy
  }
  AcceptFiatWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AcceptFiatWithdrawalResponseKeySpecifier
      | (() => undefined | AcceptFiatWithdrawalResponseKeySpecifier)
    fields?: AcceptFiatWithdrawalResponseFieldPolicy
  }
  ActivityNotification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivityNotificationKeySpecifier
      | (() => undefined | ActivityNotificationKeySpecifier)
    fields?: ActivityNotificationFieldPolicy
  }
  ActivityNotificationCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivityNotificationCollectionKeySpecifier
      | (() => undefined | ActivityNotificationCollectionKeySpecifier)
    fields?: ActivityNotificationCollectionFieldPolicy
  }
  ActivityNotificationLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ActivityNotificationLinkingCollectionsKeySpecifier
      | (() => undefined | ActivityNotificationLinkingCollectionsKeySpecifier)
    fields?: ActivityNotificationLinkingCollectionsFieldPolicy
  }
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AddressKeySpecifier | (() => undefined | AddressKeySpecifier)
    fields?: AddressFieldPolicy
  }
  Admin?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AdminKeySpecifier | (() => undefined | AdminKeySpecifier)
    fields?: AdminFieldPolicy
  }
  AdminAPIKeyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminAPIKeyResponseKeySpecifier
      | (() => undefined | AdminAPIKeyResponseKeySpecifier)
    fields?: AdminAPIKeyResponseFieldPolicy
  }
  AdminActivateMerchantResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminActivateMerchantResponseKeySpecifier
      | (() => undefined | AdminActivateMerchantResponseKeySpecifier)
    fields?: AdminActivateMerchantResponseFieldPolicy
  }
  AdminAddAuditNoteResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminAddAuditNoteResponseKeySpecifier
      | (() => undefined | AdminAddAuditNoteResponseKeySpecifier)
    fields?: AdminAddAuditNoteResponseFieldPolicy
  }
  AdminApproveVirtualAccountVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminApproveVirtualAccountVerificationResponseKeySpecifier
      | (() => undefined | AdminApproveVirtualAccountVerificationResponseKeySpecifier)
    fields?: AdminApproveVirtualAccountVerificationResponseFieldPolicy
  }
  AdminAssignKYCVerifierResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminAssignKYCVerifierResponseKeySpecifier
      | (() => undefined | AdminAssignKYCVerifierResponseKeySpecifier)
    fields?: AdminAssignKYCVerifierResponseFieldPolicy
  }
  AdminAuditWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminAuditWithdrawalResponseKeySpecifier
      | (() => undefined | AdminAuditWithdrawalResponseKeySpecifier)
    fields?: AdminAuditWithdrawalResponseFieldPolicy
  }
  AdminChangeVirtualAccountLimitResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminChangeVirtualAccountLimitResponseKeySpecifier
      | (() => undefined | AdminChangeVirtualAccountLimitResponseKeySpecifier)
    fields?: AdminChangeVirtualAccountLimitResponseFieldPolicy
  }
  AdminCheckDepositInfoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckDepositInfoResponseKeySpecifier
      | (() => undefined | AdminCheckDepositInfoResponseKeySpecifier)
    fields?: AdminCheckDepositInfoResponseFieldPolicy
  }
  AdminCheckUploadResultMResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckUploadResultMResponseKeySpecifier
      | (() => undefined | AdminCheckUploadResultMResponseKeySpecifier)
    fields?: AdminCheckUploadResultMResponseFieldPolicy
  }
  AdminCheckUploadResultP01Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckUploadResultP01ResponseKeySpecifier
      | (() => undefined | AdminCheckUploadResultP01ResponseKeySpecifier)
    fields?: AdminCheckUploadResultP01ResponseFieldPolicy
  }
  AdminCheckUploadResultP02Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckUploadResultP02ResponseKeySpecifier
      | (() => undefined | AdminCheckUploadResultP02ResponseKeySpecifier)
    fields?: AdminCheckUploadResultP02ResponseFieldPolicy
  }
  AdminCheckUploadResultP05Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckUploadResultP05ResponseKeySpecifier
      | (() => undefined | AdminCheckUploadResultP05ResponseKeySpecifier)
    fields?: AdminCheckUploadResultP05ResponseFieldPolicy
  }
  AdminCheckUploadResultPG1Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckUploadResultPG1ResponseKeySpecifier
      | (() => undefined | AdminCheckUploadResultPG1ResponseKeySpecifier)
    fields?: AdminCheckUploadResultPG1ResponseFieldPolicy
  }
  AdminCheckUploadResultResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCheckUploadResultResponseKeySpecifier
      | (() => undefined | AdminCheckUploadResultResponseKeySpecifier)
    fields?: AdminCheckUploadResultResponseFieldPolicy
  }
  AdminCreateAPIKeyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCreateAPIKeyResponseKeySpecifier
      | (() => undefined | AdminCreateAPIKeyResponseKeySpecifier)
    fields?: AdminCreateAPIKeyResponseFieldPolicy
  }
  AdminCreateOAuthClientResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCreateOAuthClientResponseKeySpecifier
      | (() => undefined | AdminCreateOAuthClientResponseKeySpecifier)
    fields?: AdminCreateOAuthClientResponseFieldPolicy
  }
  AdminCreateRolesResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCreateRolesResponseKeySpecifier
      | (() => undefined | AdminCreateRolesResponseKeySpecifier)
    fields?: AdminCreateRolesResponseFieldPolicy
  }
  AdminCreateUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminCreateUserResponseKeySpecifier
      | (() => undefined | AdminCreateUserResponseKeySpecifier)
    fields?: AdminCreateUserResponseFieldPolicy
  }
  AdminDVBanResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDVBanResponseKeySpecifier
      | (() => undefined | AdminDVBanResponseKeySpecifier)
    fields?: AdminDVBanResponseFieldPolicy
  }
  AdminDVUpdateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDVUpdateResponseKeySpecifier
      | (() => undefined | AdminDVUpdateResponseKeySpecifier)
    fields?: AdminDVUpdateResponseFieldPolicy
  }
  AdminDeleteAPIKeyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDeleteAPIKeyResponseKeySpecifier
      | (() => undefined | AdminDeleteAPIKeyResponseKeySpecifier)
    fields?: AdminDeleteAPIKeyResponseFieldPolicy
  }
  AdminDeleteRolesResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDeleteRolesResponseKeySpecifier
      | (() => undefined | AdminDeleteRolesResponseKeySpecifier)
    fields?: AdminDeleteRolesResponseFieldPolicy
  }
  AdminDepositReportResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDepositReportResponseKeySpecifier
      | (() => undefined | AdminDepositReportResponseKeySpecifier)
    fields?: AdminDepositReportResponseFieldPolicy
  }
  AdminDepositResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDepositResponseKeySpecifier
      | (() => undefined | AdminDepositResponseKeySpecifier)
    fields?: AdminDepositResponseFieldPolicy
  }
  AdminDisable2FAResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDisable2FAResponseKeySpecifier
      | (() => undefined | AdminDisable2FAResponseKeySpecifier)
    fields?: AdminDisable2FAResponseFieldPolicy
  }
  AdminDisableUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminDisableUserResponseKeySpecifier
      | (() => undefined | AdminDisableUserResponseKeySpecifier)
    fields?: AdminDisableUserResponseFieldPolicy
  }
  AdminEDDAuditResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminEDDAuditResponseKeySpecifier
      | (() => undefined | AdminEDDAuditResponseKeySpecifier)
    fields?: AdminEDDAuditResponseFieldPolicy
  }
  AdminEnableUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminEnableUserResponseKeySpecifier
      | (() => undefined | AdminEnableUserResponseKeySpecifier)
    fields?: AdminEnableUserResponseFieldPolicy
  }
  AdminHolidayAddResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminHolidayAddResponseKeySpecifier
      | (() => undefined | AdminHolidayAddResponseKeySpecifier)
    fields?: AdminHolidayAddResponseFieldPolicy
  }
  AdminHolidayResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminHolidayResponseKeySpecifier
      | (() => undefined | AdminHolidayResponseKeySpecifier)
    fields?: AdminHolidayResponseFieldPolicy
  }
  AdminIPUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminIPUserResponseKeySpecifier
      | (() => undefined | AdminIPUserResponseKeySpecifier)
    fields?: AdminIPUserResponseFieldPolicy
  }
  AdminLedgerResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminLedgerResponseKeySpecifier
      | (() => undefined | AdminLedgerResponseKeySpecifier)
    fields?: AdminLedgerResponseFieldPolicy
  }
  AdminMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AdminMutationKeySpecifier | (() => undefined | AdminMutationKeySpecifier)
    fields?: AdminMutationFieldPolicy
  }
  AdminNSAuditResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminNSAuditResponseKeySpecifier
      | (() => undefined | AdminNSAuditResponseKeySpecifier)
    fields?: AdminNSAuditResponseFieldPolicy
  }
  AdminOnHoldFiatWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminOnHoldFiatWithdrawalResponseKeySpecifier
      | (() => undefined | AdminOnHoldFiatWithdrawalResponseKeySpecifier)
    fields?: AdminOnHoldFiatWithdrawalResponseFieldPolicy
  }
  AdminOnHoldKGIVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminOnHoldKGIVerificationResponseKeySpecifier
      | (() => undefined | AdminOnHoldKGIVerificationResponseKeySpecifier)
    fields?: AdminOnHoldKGIVerificationResponseFieldPolicy
  }
  AdminRAAuditResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminRAAuditResponseKeySpecifier
      | (() => undefined | AdminRAAuditResponseKeySpecifier)
    fields?: AdminRAAuditResponseFieldPolicy
  }
  AdminReauditKYCResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminReauditKYCResponseKeySpecifier
      | (() => undefined | AdminReauditKYCResponseKeySpecifier)
    fields?: AdminReauditKYCResponseFieldPolicy
  }
  AdminRecoverKGIStatusResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminRecoverKGIStatusResponseKeySpecifier
      | (() => undefined | AdminRecoverKGIStatusResponseKeySpecifier)
    fields?: AdminRecoverKGIStatusResponseFieldPolicy
  }
  AdminRefreshMerchantResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminRefreshMerchantResponseKeySpecifier
      | (() => undefined | AdminRefreshMerchantResponseKeySpecifier)
    fields?: AdminRefreshMerchantResponseFieldPolicy
  }
  AdminRejectFiatWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminRejectFiatWithdrawalResponseKeySpecifier
      | (() => undefined | AdminRejectFiatWithdrawalResponseKeySpecifier)
    fields?: AdminRejectFiatWithdrawalResponseFieldPolicy
  }
  AdminRejectKGIVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminRejectKGIVerificationResponseKeySpecifier
      | (() => undefined | AdminRejectKGIVerificationResponseKeySpecifier)
    fields?: AdminRejectKGIVerificationResponseFieldPolicy
  }
  AdminRejectVirtualAccountVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminRejectVirtualAccountVerificationResponseKeySpecifier
      | (() => undefined | AdminRejectVirtualAccountVerificationResponseKeySpecifier)
    fields?: AdminRejectVirtualAccountVerificationResponseFieldPolicy
  }
  AdminResendAMLWithdrawInfoP01Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResendAMLWithdrawInfoP01ResponseKeySpecifier
      | (() => undefined | AdminResendAMLWithdrawInfoP01ResponseKeySpecifier)
    fields?: AdminResendAMLWithdrawInfoP01ResponseFieldPolicy
  }
  AdminResendAMLWithdrawInfoP02Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResendAMLWithdrawInfoP02ResponseKeySpecifier
      | (() => undefined | AdminResendAMLWithdrawInfoP02ResponseKeySpecifier)
    fields?: AdminResendAMLWithdrawInfoP02ResponseFieldPolicy
  }
  AdminResendFiatWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResendFiatWithdrawalResponseKeySpecifier
      | (() => undefined | AdminResendFiatWithdrawalResponseKeySpecifier)
    fields?: AdminResendFiatWithdrawalResponseFieldPolicy
  }
  AdminResendKGIVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResendKGIVerificationResponseKeySpecifier
      | (() => undefined | AdminResendKGIVerificationResponseKeySpecifier)
    fields?: AdminResendKGIVerificationResponseFieldPolicy
  }
  AdminResendWithdrawInfoP01Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResendWithdrawInfoP01ResponseKeySpecifier
      | (() => undefined | AdminResendWithdrawInfoP01ResponseKeySpecifier)
    fields?: AdminResendWithdrawInfoP01ResponseFieldPolicy
  }
  AdminResendWithdrawInfoP02Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResendWithdrawInfoP02ResponseKeySpecifier
      | (() => undefined | AdminResendWithdrawInfoP02ResponseKeySpecifier)
    fields?: AdminResendWithdrawInfoP02ResponseFieldPolicy
  }
  AdminResetKYCVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResetKYCVerificationResponseKeySpecifier
      | (() => undefined | AdminResetKYCVerificationResponseKeySpecifier)
    fields?: AdminResetKYCVerificationResponseFieldPolicy
  }
  AdminResetPhoneResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminResetPhoneResponseKeySpecifier
      | (() => undefined | AdminResetPhoneResponseKeySpecifier)
    fields?: AdminResetPhoneResponseFieldPolicy
  }
  AdminSendBGWithdrawInfoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSendBGWithdrawInfoResponseKeySpecifier
      | (() => undefined | AdminSendBGWithdrawInfoResponseKeySpecifier)
    fields?: AdminSendBGWithdrawInfoResponseFieldPolicy
  }
  AdminSendInvestorsSettlementResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSendInvestorsSettlementResponseKeySpecifier
      | (() => undefined | AdminSendInvestorsSettlementResponseKeySpecifier)
    fields?: AdminSendInvestorsSettlementResponseFieldPolicy
  }
  AdminSendMemberInfoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSendMemberInfoResponseKeySpecifier
      | (() => undefined | AdminSendMemberInfoResponseKeySpecifier)
    fields?: AdminSendMemberInfoResponseFieldPolicy
  }
  AdminSendWithdrawInfoP01Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSendWithdrawInfoP01ResponseKeySpecifier
      | (() => undefined | AdminSendWithdrawInfoP01ResponseKeySpecifier)
    fields?: AdminSendWithdrawInfoP01ResponseFieldPolicy
  }
  AdminSendWithdrawInfoP02Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSendWithdrawInfoP02ResponseKeySpecifier
      | (() => undefined | AdminSendWithdrawInfoP02ResponseKeySpecifier)
    fields?: AdminSendWithdrawInfoP02ResponseFieldPolicy
  }
  AdminSetOlderUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSetOlderUserResponseKeySpecifier
      | (() => undefined | AdminSetOlderUserResponseKeySpecifier)
    fields?: AdminSetOlderUserResponseFieldPolicy
  }
  AdminSetTradePermissionResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSetTradePermissionResponseKeySpecifier
      | (() => undefined | AdminSetTradePermissionResponseKeySpecifier)
    fields?: AdminSetTradePermissionResponseFieldPolicy
  }
  AdminSubmitAMLVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSubmitAMLVerificationResponseKeySpecifier
      | (() => undefined | AdminSubmitAMLVerificationResponseKeySpecifier)
    fields?: AdminSubmitAMLVerificationResponseFieldPolicy
  }
  AdminSubmitKYCOneVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSubmitKYCOneVerificationResponseKeySpecifier
      | (() => undefined | AdminSubmitKYCOneVerificationResponseKeySpecifier)
    fields?: AdminSubmitKYCOneVerificationResponseFieldPolicy
  }
  AdminSubmitKYCTwoVerificationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSubmitKYCTwoVerificationResponseKeySpecifier
      | (() => undefined | AdminSubmitKYCTwoVerificationResponseKeySpecifier)
    fields?: AdminSubmitKYCTwoVerificationResponseFieldPolicy
  }
  AdminSubmitRAAuditResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSubmitRAAuditResponseKeySpecifier
      | (() => undefined | AdminSubmitRAAuditResponseKeySpecifier)
    fields?: AdminSubmitRAAuditResponseFieldPolicy
  }
  AdminSystemAuditAMLResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminSystemAuditAMLResponseKeySpecifier
      | (() => undefined | AdminSystemAuditAMLResponseKeySpecifier)
    fields?: AdminSystemAuditAMLResponseFieldPolicy
  }
  AdminTradeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminTradeResponseKeySpecifier
      | (() => undefined | AdminTradeResponseKeySpecifier)
    fields?: AdminTradeResponseFieldPolicy
  }
  AdminTradingReportResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminTradingReportResponseKeySpecifier
      | (() => undefined | AdminTradingReportResponseKeySpecifier)
    fields?: AdminTradingReportResponseFieldPolicy
  }
  AdminUpdateCoinInfoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUpdateCoinInfoResponseKeySpecifier
      | (() => undefined | AdminUpdateCoinInfoResponseKeySpecifier)
    fields?: AdminUpdateCoinInfoResponseFieldPolicy
  }
  AdminUpdateMarketInfoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUpdateMarketInfoResponseKeySpecifier
      | (() => undefined | AdminUpdateMarketInfoResponseKeySpecifier)
    fields?: AdminUpdateMarketInfoResponseFieldPolicy
  }
  AdminUpdateOAuthClientResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUpdateOAuthClientResponseKeySpecifier
      | (() => undefined | AdminUpdateOAuthClientResponseKeySpecifier)
    fields?: AdminUpdateOAuthClientResponseFieldPolicy
  }
  AdminUpdateQuotationInfoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUpdateQuotationInfoResponseKeySpecifier
      | (() => undefined | AdminUpdateQuotationInfoResponseKeySpecifier)
    fields?: AdminUpdateQuotationInfoResponseFieldPolicy
  }
  AdminUploadSuspiciousTransactionResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUploadSuspiciousTransactionResponseKeySpecifier
      | (() => undefined | AdminUploadSuspiciousTransactionResponseKeySpecifier)
    fields?: AdminUploadSuspiciousTransactionResponseFieldPolicy
  }
  AdminUploadVerificationFileResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUploadVerificationFileResponseKeySpecifier
      | (() => undefined | AdminUploadVerificationFileResponseKeySpecifier)
    fields?: AdminUploadVerificationFileResponseFieldPolicy
  }
  AdminUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUserResponseKeySpecifier
      | (() => undefined | AdminUserResponseKeySpecifier)
    fields?: AdminUserResponseFieldPolicy
  }
  AdminVIPUpdateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminVIPUpdateResponseKeySpecifier
      | (() => undefined | AdminVIPUpdateResponseKeySpecifier)
    fields?: AdminVIPUpdateResponseFieldPolicy
  }
  AdminVerifyKYCIdentityResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminVerifyKYCIdentityResponseKeySpecifier
      | (() => undefined | AdminVerifyKYCIdentityResponseKeySpecifier)
    fields?: AdminVerifyKYCIdentityResponseFieldPolicy
  }
  AdminVoucherRecordsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminVoucherRecordsResponseKeySpecifier
      | (() => undefined | AdminVoucherRecordsResponseKeySpecifier)
    fields?: AdminVoucherRecordsResponseFieldPolicy
  }
  AdminWithdrawalReportResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminWithdrawalReportResponseKeySpecifier
      | (() => undefined | AdminWithdrawalReportResponseKeySpecifier)
    fields?: AdminWithdrawalReportResponseFieldPolicy
  }
  AdminWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminWithdrawalResponseKeySpecifier
      | (() => undefined | AdminWithdrawalResponseKeySpecifier)
    fields?: AdminWithdrawalResponseFieldPolicy
  }
  ApproveKYCLv2Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ApproveKYCLv2ResponseKeySpecifier
      | (() => undefined | ApproveKYCLv2ResponseKeySpecifier)
    fields?: ApproveKYCLv2ResponseFieldPolicy
  }
  Asset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AssetKeySpecifier | (() => undefined | AssetKeySpecifier)
    fields?: AssetFieldPolicy
  }
  AssetCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssetCollectionKeySpecifier
      | (() => undefined | AssetCollectionKeySpecifier)
    fields?: AssetCollectionFieldPolicy
  }
  AssetLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssetLinkingCollectionsKeySpecifier
      | (() => undefined | AssetLinkingCollectionsKeySpecifier)
    fields?: AssetLinkingCollectionsFieldPolicy
  }
  Attributes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AttributesKeySpecifier | (() => undefined | AttributesKeySpecifier)
    fields?: AttributesFieldPolicy
  }
  Auth2FA?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | Auth2FAKeySpecifier | (() => undefined | Auth2FAKeySpecifier)
    fields?: Auth2FAFieldPolicy
  }
  AuthBind2FAResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthBind2FAResponseKeySpecifier
      | (() => undefined | AuthBind2FAResponseKeySpecifier)
    fields?: AuthBind2FAResponseFieldPolicy
  }
  AuthConfirm2FAResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthConfirm2FAResponseKeySpecifier
      | (() => undefined | AuthConfirm2FAResponseKeySpecifier)
    fields?: AuthConfirm2FAResponseFieldPolicy
  }
  AuthMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AuthMutationKeySpecifier | (() => undefined | AuthMutationKeySpecifier)
    fields?: AuthMutationFieldPolicy
  }
  AuthUnbind2FAResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthUnbind2FAResponseKeySpecifier
      | (() => undefined | AuthUnbind2FAResponseKeySpecifier)
    fields?: AuthUnbind2FAResponseFieldPolicy
  }
  Balance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BalanceKeySpecifier | (() => undefined | BalanceKeySpecifier)
    fields?: BalanceFieldPolicy
  }
  Bank?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BankKeySpecifier | (() => undefined | BankKeySpecifier)
    fields?: BankFieldPolicy
  }
  BankAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BankAccountKeySpecifier | (() => undefined | BankAccountKeySpecifier)
    fields?: BankAccountFieldPolicy
  }
  BankBranch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BankBranchKeySpecifier | (() => undefined | BankBranchKeySpecifier)
    fields?: BankBranchFieldPolicy
  }
  BankInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BankInfoKeySpecifier | (() => undefined | BankInfoKeySpecifier)
    fields?: BankInfoFieldPolicy
  }
  BaseDeposit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BaseDepositKeySpecifier | (() => undefined | BaseDepositKeySpecifier)
    fields?: BaseDepositFieldPolicy
  }
  BaseResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BaseResponseKeySpecifier | (() => undefined | BaseResponseKeySpecifier)
    fields?: BaseResponseFieldPolicy
  }
  BaseWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BaseWithdrawalKeySpecifier | (() => undefined | BaseWithdrawalKeySpecifier)
    fields?: BaseWithdrawalFieldPolicy
  }
  BasicInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BasicInfoKeySpecifier | (() => undefined | BasicInfoKeySpecifier)
    fields?: BasicInfoFieldPolicy
  }
  CefiDepositAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CefiDepositAddressKeySpecifier
      | (() => undefined | CefiDepositAddressKeySpecifier)
    fields?: CefiDepositAddressFieldPolicy
  }
  CefiWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CefiWalletKeySpecifier | (() => undefined | CefiWalletKeySpecifier)
    fields?: CefiWalletFieldPolicy
  }
  ChartResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChartResponseKeySpecifier | (() => undefined | ChartResponseKeySpecifier)
    fields?: ChartResponseFieldPolicy
  }
  CoinPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CoinPriceKeySpecifier | (() => undefined | CoinPriceKeySpecifier)
    fields?: CoinPriceFieldPolicy
  }
  CommonAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CommonAddressKeySpecifier | (() => undefined | CommonAddressKeySpecifier)
    fields?: CommonAddressFieldPolicy
  }
  CommonAddressResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CommonAddressResponseKeySpecifier
      | (() => undefined | CommonAddressResponseKeySpecifier)
    fields?: CommonAddressResponseFieldPolicy
  }
  ContentfulMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContentfulMetadataKeySpecifier
      | (() => undefined | ContentfulMetadataKeySpecifier)
    fields?: ContentfulMetadataFieldPolicy
  }
  ContentfulTag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContentfulTagKeySpecifier | (() => undefined | ContentfulTagKeySpecifier)
    fields?: ContentfulTagFieldPolicy
  }
  Country?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CountryKeySpecifier | (() => undefined | CountryKeySpecifier)
    fields?: CountryFieldPolicy
  }
  Creator?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CreatorKeySpecifier | (() => undefined | CreatorKeySpecifier)
    fields?: CreatorFieldPolicy
  }
  CryptoAsset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CryptoAssetKeySpecifier | (() => undefined | CryptoAssetKeySpecifier)
    fields?: CryptoAssetFieldPolicy
  }
  CryptoDeposit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CryptoDepositKeySpecifier | (() => undefined | CryptoDepositKeySpecifier)
    fields?: CryptoDepositFieldPolicy
  }
  CryptoWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CryptoWithdrawalKeySpecifier
      | (() => undefined | CryptoWithdrawalKeySpecifier)
    fields?: CryptoWithdrawalFieldPolicy
  }
  CurrencyAmount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CurrencyAmountKeySpecifier | (() => undefined | CurrencyAmountKeySpecifier)
    fields?: CurrencyAmountFieldPolicy
  }
  CurrencyBalance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrencyBalanceKeySpecifier
      | (() => undefined | CurrencyBalanceKeySpecifier)
    fields?: CurrencyBalanceFieldPolicy
  }
  CurrencyBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrencyBalancesKeySpecifier
      | (() => undefined | CurrencyBalancesKeySpecifier)
    fields?: CurrencyBalancesFieldPolicy
  }
  CurrencyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CurrencyInfoKeySpecifier | (() => undefined | CurrencyInfoKeySpecifier)
    fields?: CurrencyInfoFieldPolicy
  }
  DefiWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DefiWalletKeySpecifier | (() => undefined | DefiWalletKeySpecifier)
    fields?: DefiWalletFieldPolicy
  }
  DepositResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DepositResponseKeySpecifier
      | (() => undefined | DepositResponseKeySpecifier)
    fields?: DepositResponseFieldPolicy
  }
  DevMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DevMutationKeySpecifier | (() => undefined | DevMutationKeySpecifier)
    fields?: DevMutationFieldPolicy
  }
  DisplayItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DisplayItemKeySpecifier | (() => undefined | DisplayItemKeySpecifier)
    fields?: DisplayItemFieldPolicy
  }
  DisplayItemCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DisplayItemCollectionKeySpecifier
      | (() => undefined | DisplayItemCollectionKeySpecifier)
    fields?: DisplayItemCollectionFieldPolicy
  }
  DisplayItemForUsersCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DisplayItemForUsersCollectionKeySpecifier
      | (() => undefined | DisplayItemForUsersCollectionKeySpecifier)
    fields?: DisplayItemForUsersCollectionFieldPolicy
  }
  DisplayItemLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DisplayItemLinkingCollectionsKeySpecifier
      | (() => undefined | DisplayItemLinkingCollectionsKeySpecifier)
    fields?: DisplayItemLinkingCollectionsFieldPolicy
  }
  Entry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EntryKeySpecifier | (() => undefined | EntryKeySpecifier)
    fields?: EntryFieldPolicy
  }
  EntryCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EntryCollectionKeySpecifier
      | (() => undefined | EntryCollectionKeySpecifier)
    fields?: EntryCollectionFieldPolicy
  }
  ExternalAPIKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExternalAPIKeyKeySpecifier | (() => undefined | ExternalAPIKeyKeySpecifier)
    fields?: ExternalAPIKeyFieldPolicy
  }
  ExternalAPIKeyWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ExternalAPIKeyWithdrawalKeySpecifier
      | (() => undefined | ExternalAPIKeyWithdrawalKeySpecifier)
    fields?: ExternalAPIKeyWithdrawalFieldPolicy
  }
  FAASPayment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FAASPaymentKeySpecifier | (() => undefined | FAASPaymentKeySpecifier)
    fields?: FAASPaymentFieldPolicy
  }
  FAASPaymentResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FAASPaymentResponseKeySpecifier
      | (() => undefined | FAASPaymentResponseKeySpecifier)
    fields?: FAASPaymentResponseFieldPolicy
  }
  FiatDeposit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FiatDepositKeySpecifier | (() => undefined | FiatDepositKeySpecifier)
    fields?: FiatDepositFieldPolicy
  }
  FiatDepositInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FiatDepositInfoKeySpecifier
      | (() => undefined | FiatDepositInfoKeySpecifier)
    fields?: FiatDepositInfoFieldPolicy
  }
  FiatWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FiatWithdrawalKeySpecifier | (() => undefined | FiatWithdrawalKeySpecifier)
    fields?: FiatWithdrawalFieldPolicy
  }
  FrontendVersion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FrontendVersionKeySpecifier
      | (() => undefined | FrontendVersionKeySpecifier)
    fields?: FrontendVersionFieldPolicy
  }
  GimCoolPayPaymentInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GimCoolPayPaymentInfoKeySpecifier
      | (() => undefined | GimCoolPayPaymentInfoKeySpecifier)
    fields?: GimCoolPayPaymentInfoFieldPolicy
  }
  GimCoolPayPriceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GimCoolPayPriceResponseKeySpecifier
      | (() => undefined | GimCoolPayPriceResponseKeySpecifier)
    fields?: GimCoolPayPriceResponseFieldPolicy
  }
  GimCoolPayReadyToPurchasePrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GimCoolPayReadyToPurchasePriceKeySpecifier
      | (() => undefined | GimCoolPayReadyToPurchasePriceKeySpecifier)
    fields?: GimCoolPayReadyToPurchasePriceFieldPolicy
  }
  HeroSlider?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HeroSliderKeySpecifier | (() => undefined | HeroSliderKeySpecifier)
    fields?: HeroSliderFieldPolicy
  }
  HeroSliderCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | HeroSliderCollectionKeySpecifier
      | (() => undefined | HeroSliderCollectionKeySpecifier)
    fields?: HeroSliderCollectionFieldPolicy
  }
  HeroSliderLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | HeroSliderLinkingCollectionsKeySpecifier
      | (() => undefined | HeroSliderLinkingCollectionsKeySpecifier)
    fields?: HeroSliderLinkingCollectionsFieldPolicy
  }
  Holiday?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HolidayKeySpecifier | (() => undefined | HolidayKeySpecifier)
    fields?: HolidayFieldPolicy
  }
  IdentityInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IdentityInfoKeySpecifier | (() => undefined | IdentityInfoKeySpecifier)
    fields?: IdentityInfoFieldPolicy
  }
  InternalTransferIn?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InternalTransferInKeySpecifier
      | (() => undefined | InternalTransferInKeySpecifier)
    fields?: InternalTransferInFieldPolicy
  }
  InternalTransferOut?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InternalTransferOutKeySpecifier
      | (() => undefined | InternalTransferOutKeySpecifier)
    fields?: InternalTransferOutFieldPolicy
  }
  KYC?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KYCKeySpecifier | (() => undefined | KYCKeySpecifier)
    fields?: KYCFieldPolicy
  }
  KYCConfirmSubmissionResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KYCConfirmSubmissionResponseKeySpecifier
      | (() => undefined | KYCConfirmSubmissionResponseKeySpecifier)
    fields?: KYCConfirmSubmissionResponseFieldPolicy
  }
  KYCLevelOne?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KYCLevelOneKeySpecifier | (() => undefined | KYCLevelOneKeySpecifier)
    fields?: KYCLevelOneFieldPolicy
  }
  KYCLevelTwo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KYCLevelTwoKeySpecifier | (() => undefined | KYCLevelTwoKeySpecifier)
    fields?: KYCLevelTwoFieldPolicy
  }
  KYCMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KYCMutationKeySpecifier | (() => undefined | KYCMutationKeySpecifier)
    fields?: KYCMutationFieldPolicy
  }
  KYCPhoto?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | KYCPhotoKeySpecifier | (() => undefined | KYCPhotoKeySpecifier)
    fields?: KYCPhotoFieldPolicy
  }
  KYCPhotoVerification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KYCPhotoVerificationKeySpecifier
      | (() => undefined | KYCPhotoVerificationKeySpecifier)
    fields?: KYCPhotoVerificationFieldPolicy
  }
  KYCSubmitLevelOneResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KYCSubmitLevelOneResponseKeySpecifier
      | (() => undefined | KYCSubmitLevelOneResponseKeySpecifier)
    fields?: KYCSubmitLevelOneResponseFieldPolicy
  }
  KYCSubmitLevelTwoResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KYCSubmitLevelTwoResponseKeySpecifier
      | (() => undefined | KYCSubmitLevelTwoResponseKeySpecifier)
    fields?: KYCSubmitLevelTwoResponseFieldPolicy
  }
  KgiUploadResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | KgiUploadResponseKeySpecifier
      | (() => undefined | KgiUploadResponseKeySpecifier)
    fields?: KgiUploadResponseFieldPolicy
  }
  LandingPageCustomMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageKeySpecifier
      | (() => undefined | LandingPageCustomMessageKeySpecifier)
    fields?: LandingPageCustomMessageFieldPolicy
  }
  LandingPageCustomMessageCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageCollectionKeySpecifier
      | (() => undefined | LandingPageCustomMessageCollectionKeySpecifier)
    fields?: LandingPageCustomMessageCollectionFieldPolicy
  }
  LandingPageCustomMessageCustomMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageCustomMessageKeySpecifier
      | (() => undefined | LandingPageCustomMessageCustomMessageKeySpecifier)
    fields?: LandingPageCustomMessageCustomMessageFieldPolicy
  }
  LandingPageCustomMessageCustomMessageAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageCustomMessageAssetsKeySpecifier
      | (() => undefined | LandingPageCustomMessageCustomMessageAssetsKeySpecifier)
    fields?: LandingPageCustomMessageCustomMessageAssetsFieldPolicy
  }
  LandingPageCustomMessageCustomMessageEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageCustomMessageEntriesKeySpecifier
      | (() => undefined | LandingPageCustomMessageCustomMessageEntriesKeySpecifier)
    fields?: LandingPageCustomMessageCustomMessageEntriesFieldPolicy
  }
  LandingPageCustomMessageCustomMessageLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageCustomMessageLinksKeySpecifier
      | (() => undefined | LandingPageCustomMessageCustomMessageLinksKeySpecifier)
    fields?: LandingPageCustomMessageCustomMessageLinksFieldPolicy
  }
  LandingPageCustomMessageCustomMessageResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageCustomMessageResourcesKeySpecifier
      | (() => undefined | LandingPageCustomMessageCustomMessageResourcesKeySpecifier)
    fields?: LandingPageCustomMessageCustomMessageResourcesFieldPolicy
  }
  LandingPageCustomMessageLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LandingPageCustomMessageLinkingCollectionsKeySpecifier
      | (() => undefined | LandingPageCustomMessageLinkingCollectionsKeySpecifier)
    fields?: LandingPageCustomMessageLinkingCollectionsFieldPolicy
  }
  Ledger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LedgerKeySpecifier | (() => undefined | LedgerKeySpecifier)
    fields?: LedgerFieldPolicy
  }
  LedgerResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LedgerResponseKeySpecifier | (() => undefined | LedgerResponseKeySpecifier)
    fields?: LedgerResponseFieldPolicy
  }
  LoginInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LoginInfoKeySpecifier | (() => undefined | LoginInfoKeySpecifier)
    fields?: LoginInfoFieldPolicy
  }
  MarketInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MarketInfoKeySpecifier | (() => undefined | MarketInfoKeySpecifier)
    fields?: MarketInfoFieldPolicy
  }
  MarketPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MarketPriceKeySpecifier | (() => undefined | MarketPriceKeySpecifier)
    fields?: MarketPriceFieldPolicy
  }
  Me?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MeKeySpecifier | (() => undefined | MeKeySpecifier)
    fields?: MeFieldPolicy
  }
  MetaPagination?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetaPaginationKeySpecifier | (() => undefined | MetaPaginationKeySpecifier)
    fields?: MetaPaginationFieldPolicy
  }
  Metric?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetricKeySpecifier | (() => undefined | MetricKeySpecifier)
    fields?: MetricFieldPolicy
  }
  MetricPosition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetricPositionKeySpecifier | (() => undefined | MetricPositionKeySpecifier)
    fields?: MetricPositionFieldPolicy
  }
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier)
    fields?: MutationFieldPolicy
  }
  NFTCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NFTCollectionsKeySpecifier | (() => undefined | NFTCollectionsKeySpecifier)
    fields?: NFTCollectionsFieldPolicy
  }
  NFTMerkleTreeHexProof?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTMerkleTreeHexProofKeySpecifier
      | (() => undefined | NFTMerkleTreeHexProofKeySpecifier)
    fields?: NFTMerkleTreeHexProofFieldPolicy
  }
  NFTWhitelistInfoByPhrase?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NFTWhitelistInfoByPhraseKeySpecifier
      | (() => undefined | NFTWhitelistInfoByPhraseKeySpecifier)
    fields?: NFTWhitelistInfoByPhraseFieldPolicy
  }
  Notification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NotificationKeySpecifier | (() => undefined | NotificationKeySpecifier)
    fields?: NotificationFieldPolicy
  }
  NotificationCount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationCountKeySpecifier
      | (() => undefined | NotificationCountKeySpecifier)
    fields?: NotificationCountFieldPolicy
  }
  NotificationCountResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationCountResponseKeySpecifier
      | (() => undefined | NotificationCountResponseKeySpecifier)
    fields?: NotificationCountResponseFieldPolicy
  }
  NotificationInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationInfoKeySpecifier
      | (() => undefined | NotificationInfoKeySpecifier)
    fields?: NotificationInfoFieldPolicy
  }
  NotificationListResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationListResponseKeySpecifier
      | (() => undefined | NotificationListResponseKeySpecifier)
    fields?: NotificationListResponseFieldPolicy
  }
  NotificationMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationMutationKeySpecifier
      | (() => undefined | NotificationMutationKeySpecifier)
    fields?: NotificationMutationFieldPolicy
  }
  NotificationReadResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NotificationReadResponseKeySpecifier
      | (() => undefined | NotificationReadResponseKeySpecifier)
    fields?: NotificationReadResponseFieldPolicy
  }
  OnHoldKGIVerification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OnHoldKGIVerificationKeySpecifier
      | (() => undefined | OnHoldKGIVerificationKeySpecifier)
    fields?: OnHoldKGIVerificationFieldPolicy
  }
  Point?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PointKeySpecifier | (() => undefined | PointKeySpecifier)
    fields?: PointFieldPolicy
  }
  PointLedgerRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PointLedgerRecordKeySpecifier
      | (() => undefined | PointLedgerRecordKeySpecifier)
    fields?: PointLedgerRecordFieldPolicy
  }
  PointLedgerRecordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PointLedgerRecordResponseKeySpecifier
      | (() => undefined | PointLedgerRecordResponseKeySpecifier)
    fields?: PointLedgerRecordResponseFieldPolicy
  }
  PointMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PointMutationKeySpecifier | (() => undefined | PointMutationKeySpecifier)
    fields?: PointMutationFieldPolicy
  }
  PointPurchaseResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PointPurchaseResponseKeySpecifier
      | (() => undefined | PointPurchaseResponseKeySpecifier)
    fields?: PointPurchaseResponseFieldPolicy
  }
  PopUp?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PopUpKeySpecifier | (() => undefined | PopUpKeySpecifier)
    fields?: PopUpFieldPolicy
  }
  PopUpCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpCollectionKeySpecifier
      | (() => undefined | PopUpCollectionKeySpecifier)
    fields?: PopUpCollectionFieldPolicy
  }
  PopUpContentArea?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpContentAreaKeySpecifier
      | (() => undefined | PopUpContentAreaKeySpecifier)
    fields?: PopUpContentAreaFieldPolicy
  }
  PopUpContentAreaAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpContentAreaAssetsKeySpecifier
      | (() => undefined | PopUpContentAreaAssetsKeySpecifier)
    fields?: PopUpContentAreaAssetsFieldPolicy
  }
  PopUpContentAreaEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpContentAreaEntriesKeySpecifier
      | (() => undefined | PopUpContentAreaEntriesKeySpecifier)
    fields?: PopUpContentAreaEntriesFieldPolicy
  }
  PopUpContentAreaLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpContentAreaLinksKeySpecifier
      | (() => undefined | PopUpContentAreaLinksKeySpecifier)
    fields?: PopUpContentAreaLinksFieldPolicy
  }
  PopUpContentAreaResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpContentAreaResourcesKeySpecifier
      | (() => undefined | PopUpContentAreaResourcesKeySpecifier)
    fields?: PopUpContentAreaResourcesFieldPolicy
  }
  PopUpLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PopUpLinkingCollectionsKeySpecifier
      | (() => undefined | PopUpLinkingCollectionsKeySpecifier)
    fields?: PopUpLinkingCollectionsFieldPolicy
  }
  PositionManagerInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PositionManagerInfoKeySpecifier
      | (() => undefined | PositionManagerInfoKeySpecifier)
    fields?: PositionManagerInfoFieldPolicy
  }
  Public?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PublicKeySpecifier | (() => undefined | PublicKeySpecifier)
    fields?: PublicFieldPolicy
  }
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier)
    fields?: QueryFieldPolicy
  }
  Question?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QuestionKeySpecifier | (() => undefined | QuestionKeySpecifier)
    fields?: QuestionFieldPolicy
  }
  QuestionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QuestionInfoKeySpecifier | (() => undefined | QuestionInfoKeySpecifier)
    fields?: QuestionInfoFieldPolicy
  }
  QuestionOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QuestionOptionKeySpecifier | (() => undefined | QuestionOptionKeySpecifier)
    fields?: QuestionOptionFieldPolicy
  }
  Questionnaire?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QuestionnaireKeySpecifier | (() => undefined | QuestionnaireKeySpecifier)
    fields?: QuestionnaireFieldPolicy
  }
  Quotation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QuotationKeySpecifier | (() => undefined | QuotationKeySpecifier)
    fields?: QuotationFieldPolicy
  }
  RateHistorical?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RateHistoricalKeySpecifier | (() => undefined | RateHistoricalKeySpecifier)
    fields?: RateHistoricalFieldPolicy
  }
  RejectFiatWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RejectFiatWithdrawalResponseKeySpecifier
      | (() => undefined | RejectFiatWithdrawalResponseKeySpecifier)
    fields?: RejectFiatWithdrawalResponseFieldPolicy
  }
  Release?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReleaseKeySpecifier | (() => undefined | ReleaseKeySpecifier)
    fields?: ReleaseFieldPolicy
  }
  ReleaseCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseCollectionKeySpecifier
      | (() => undefined | ReleaseCollectionKeySpecifier)
    fields?: ReleaseCollectionFieldPolicy
  }
  ReleaseLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseLinkingCollectionsKeySpecifier
      | (() => undefined | ReleaseLinkingCollectionsKeySpecifier)
    fields?: ReleaseLinkingCollectionsFieldPolicy
  }
  ReleaseNote?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReleaseNoteKeySpecifier | (() => undefined | ReleaseNoteKeySpecifier)
    fields?: ReleaseNoteFieldPolicy
  }
  ReleaseNoteCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteCollectionKeySpecifier
      | (() => undefined | ReleaseNoteCollectionKeySpecifier)
    fields?: ReleaseNoteCollectionFieldPolicy
  }
  ReleaseNoteLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteLinkingCollectionsKeySpecifier
      | (() => undefined | ReleaseNoteLinkingCollectionsKeySpecifier)
    fields?: ReleaseNoteLinkingCollectionsFieldPolicy
  }
  ReleaseNoteReleaseNote?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteReleaseNoteKeySpecifier
      | (() => undefined | ReleaseNoteReleaseNoteKeySpecifier)
    fields?: ReleaseNoteReleaseNoteFieldPolicy
  }
  ReleaseNoteReleaseNoteAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteReleaseNoteAssetsKeySpecifier
      | (() => undefined | ReleaseNoteReleaseNoteAssetsKeySpecifier)
    fields?: ReleaseNoteReleaseNoteAssetsFieldPolicy
  }
  ReleaseNoteReleaseNoteEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteReleaseNoteEntriesKeySpecifier
      | (() => undefined | ReleaseNoteReleaseNoteEntriesKeySpecifier)
    fields?: ReleaseNoteReleaseNoteEntriesFieldPolicy
  }
  ReleaseNoteReleaseNoteLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteReleaseNoteLinksKeySpecifier
      | (() => undefined | ReleaseNoteReleaseNoteLinksKeySpecifier)
    fields?: ReleaseNoteReleaseNoteLinksFieldPolicy
  }
  ReleaseNoteReleaseNoteResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNoteReleaseNoteResourcesKeySpecifier
      | (() => undefined | ReleaseNoteReleaseNoteResourcesKeySpecifier)
    fields?: ReleaseNoteReleaseNoteResourcesFieldPolicy
  }
  ReleaseNotesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReleaseNotesCollectionKeySpecifier
      | (() => undefined | ReleaseNotesCollectionKeySpecifier)
    fields?: ReleaseNotesCollectionFieldPolicy
  }
  ResourceConfirmUploadResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResourceConfirmUploadResponseKeySpecifier
      | (() => undefined | ResourceConfirmUploadResponseKeySpecifier)
    fields?: ResourceConfirmUploadResponseFieldPolicy
  }
  ResourceLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResourceLinkKeySpecifier | (() => undefined | ResourceLinkKeySpecifier)
    fields?: ResourceLinkFieldPolicy
  }
  ResourceMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResourceMutationKeySpecifier
      | (() => undefined | ResourceMutationKeySpecifier)
    fields?: ResourceMutationFieldPolicy
  }
  ResourceRequestUploadURLResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResourceRequestUploadURLResponseKeySpecifier
      | (() => undefined | ResourceRequestUploadURLResponseKeySpecifier)
    fields?: ResourceRequestUploadURLResponseFieldPolicy
  }
  ResourceSys?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResourceSysKeySpecifier | (() => undefined | ResourceSysKeySpecifier)
    fields?: ResourceSysFieldPolicy
  }
  Response2FA?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | Response2FAKeySpecifier | (() => undefined | Response2FAKeySpecifier)
    fields?: Response2FAFieldPolicy
  }
  ReyieldAttributes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldAttributesKeySpecifier
      | (() => undefined | ReyieldAttributesKeySpecifier)
    fields?: ReyieldAttributesFieldPolicy
  }
  ReyieldBalance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReyieldBalanceKeySpecifier | (() => undefined | ReyieldBalanceKeySpecifier)
    fields?: ReyieldBalanceFieldPolicy
  }
  ReyieldCefiDepositAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldCefiDepositAddressKeySpecifier
      | (() => undefined | ReyieldCefiDepositAddressKeySpecifier)
    fields?: ReyieldCefiDepositAddressFieldPolicy
  }
  ReyieldCefiWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldCefiWalletKeySpecifier
      | (() => undefined | ReyieldCefiWalletKeySpecifier)
    fields?: ReyieldCefiWalletFieldPolicy
  }
  ReyieldCoinPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldCoinPriceKeySpecifier
      | (() => undefined | ReyieldCoinPriceKeySpecifier)
    fields?: ReyieldCoinPriceFieldPolicy
  }
  ReyieldCreator?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReyieldCreatorKeySpecifier | (() => undefined | ReyieldCreatorKeySpecifier)
    fields?: ReyieldCreatorFieldPolicy
  }
  ReyieldCurrencyBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldCurrencyBalancesKeySpecifier
      | (() => undefined | ReyieldCurrencyBalancesKeySpecifier)
    fields?: ReyieldCurrencyBalancesFieldPolicy
  }
  ReyieldCurrencyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldCurrencyInfoKeySpecifier
      | (() => undefined | ReyieldCurrencyInfoKeySpecifier)
    fields?: ReyieldCurrencyInfoFieldPolicy
  }
  ReyieldDefiWallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldDefiWalletKeySpecifier
      | (() => undefined | ReyieldDefiWalletKeySpecifier)
    fields?: ReyieldDefiWalletFieldPolicy
  }
  ReyieldExternalAPIKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldExternalAPIKeyKeySpecifier
      | (() => undefined | ReyieldExternalAPIKeyKeySpecifier)
    fields?: ReyieldExternalAPIKeyFieldPolicy
  }
  ReyieldFrontendVersion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldFrontendVersionKeySpecifier
      | (() => undefined | ReyieldFrontendVersionKeySpecifier)
    fields?: ReyieldFrontendVersionFieldPolicy
  }
  ReyieldGimCoolPayPaymentInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldGimCoolPayPaymentInfoKeySpecifier
      | (() => undefined | ReyieldGimCoolPayPaymentInfoKeySpecifier)
    fields?: ReyieldGimCoolPayPaymentInfoFieldPolicy
  }
  ReyieldGimCoolPayPriceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldGimCoolPayPriceResponseKeySpecifier
      | (() => undefined | ReyieldGimCoolPayPriceResponseKeySpecifier)
    fields?: ReyieldGimCoolPayPriceResponseFieldPolicy
  }
  ReyieldGimCoolPayReadyToPurchasePrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldGimCoolPayReadyToPurchasePriceKeySpecifier
      | (() => undefined | ReyieldGimCoolPayReadyToPurchasePriceKeySpecifier)
    fields?: ReyieldGimCoolPayReadyToPurchasePriceFieldPolicy
  }
  ReyieldLicense?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReyieldLicenseKeySpecifier | (() => undefined | ReyieldLicenseKeySpecifier)
    fields?: ReyieldLicenseFieldPolicy
  }
  ReyieldNFTCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldNFTCollectionsKeySpecifier
      | (() => undefined | ReyieldNFTCollectionsKeySpecifier)
    fields?: ReyieldNFTCollectionsFieldPolicy
  }
  ReyieldNFTMerkleTreeHexProof?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldNFTMerkleTreeHexProofKeySpecifier
      | (() => undefined | ReyieldNFTMerkleTreeHexProofKeySpecifier)
    fields?: ReyieldNFTMerkleTreeHexProofFieldPolicy
  }
  ReyieldNFTWhitelistInfoByPhrase?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldNFTWhitelistInfoByPhraseKeySpecifier
      | (() => undefined | ReyieldNFTWhitelistInfoByPhraseKeySpecifier)
    fields?: ReyieldNFTWhitelistInfoByPhraseFieldPolicy
  }
  ReyieldRateHistorical?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldRateHistoricalKeySpecifier
      | (() => undefined | ReyieldRateHistoricalKeySpecifier)
    fields?: ReyieldRateHistoricalFieldPolicy
  }
  ReyieldReyieldLicense?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldReyieldLicenseKeySpecifier
      | (() => undefined | ReyieldReyieldLicenseKeySpecifier)
    fields?: ReyieldReyieldLicenseFieldPolicy
  }
  ReyieldSmartContract?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldSmartContractKeySpecifier
      | (() => undefined | ReyieldSmartContractKeySpecifier)
    fields?: ReyieldSmartContractFieldPolicy
  }
  ReyieldStrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyInfoKeySpecifier
      | (() => undefined | ReyieldStrategyInfoKeySpecifier)
    fields?: ReyieldStrategyInfoFieldPolicy
  }
  ReyieldStrategyInfos?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyInfosKeySpecifier
      | (() => undefined | ReyieldStrategyInfosKeySpecifier)
    fields?: ReyieldStrategyInfosFieldPolicy
  }
  ReyieldStrategyParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyParametersKeySpecifier
      | (() => undefined | ReyieldStrategyParametersKeySpecifier)
    fields?: ReyieldStrategyParametersFieldPolicy
  }
  ReyieldStrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyPerformanceKeySpecifier
      | (() => undefined | ReyieldStrategyPerformanceKeySpecifier)
    fields?: ReyieldStrategyPerformanceFieldPolicy
  }
  ReyieldStrategyProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyProgressKeySpecifier
      | (() => undefined | ReyieldStrategyProgressKeySpecifier)
    fields?: ReyieldStrategyProgressFieldPolicy
  }
  ReyieldStrategyProgressStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyProgressStepKeySpecifier
      | (() => undefined | ReyieldStrategyProgressStepKeySpecifier)
    fields?: ReyieldStrategyProgressStepFieldPolicy
  }
  ReyieldStrategyStartResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyStartResponseKeySpecifier
      | (() => undefined | ReyieldStrategyStartResponseKeySpecifier)
    fields?: ReyieldStrategyStartResponseFieldPolicy
  }
  ReyieldStrategyStopResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyStopResponseKeySpecifier
      | (() => undefined | ReyieldStrategyStopResponseKeySpecifier)
    fields?: ReyieldStrategyStopResponseFieldPolicy
  }
  ReyieldStrategyUpdateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldStrategyUpdateResponseKeySpecifier
      | (() => undefined | ReyieldStrategyUpdateResponseKeySpecifier)
    fields?: ReyieldStrategyUpdateResponseFieldPolicy
  }
  ReyieldTokenBalance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldTokenBalanceKeySpecifier
      | (() => undefined | ReyieldTokenBalanceKeySpecifier)
    fields?: ReyieldTokenBalanceFieldPolicy
  }
  ReyieldTokenDisplayInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldTokenDisplayInfoKeySpecifier
      | (() => undefined | ReyieldTokenDisplayInfoKeySpecifier)
    fields?: ReyieldTokenDisplayInfoFieldPolicy
  }
  ReyieldTotalProfits?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldTotalProfitsKeySpecifier
      | (() => undefined | ReyieldTotalProfitsKeySpecifier)
    fields?: ReyieldTotalProfitsFieldPolicy
  }
  ReyieldUniswapOnchainPositionAmountInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapOnchainPositionAmountInfoKeySpecifier
      | (() => undefined | ReyieldUniswapOnchainPositionAmountInfoKeySpecifier)
    fields?: ReyieldUniswapOnchainPositionAmountInfoFieldPolicy
  }
  ReyieldUniswapPoolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapPoolInfoKeySpecifier
      | (() => undefined | ReyieldUniswapPoolInfoKeySpecifier)
    fields?: ReyieldUniswapPoolInfoFieldPolicy
  }
  ReyieldUniswapPoolPosition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapPoolPositionKeySpecifier
      | (() => undefined | ReyieldUniswapPoolPositionKeySpecifier)
    fields?: ReyieldUniswapPoolPositionFieldPolicy
  }
  ReyieldUniswapPoolTick?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapPoolTickKeySpecifier
      | (() => undefined | ReyieldUniswapPoolTickKeySpecifier)
    fields?: ReyieldUniswapPoolTickFieldPolicy
  }
  ReyieldUniswapQuote?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapQuoteKeySpecifier
      | (() => undefined | ReyieldUniswapQuoteKeySpecifier)
    fields?: ReyieldUniswapQuoteFieldPolicy
  }
  ReyieldUniswapToolFeeTier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapToolFeeTierKeySpecifier
      | (() => undefined | ReyieldUniswapToolFeeTierKeySpecifier)
    fields?: ReyieldUniswapToolFeeTierFieldPolicy
  }
  ReyieldUniswapToolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapToolInfoKeySpecifier
      | (() => undefined | ReyieldUniswapToolInfoKeySpecifier)
    fields?: ReyieldUniswapToolInfoFieldPolicy
  }
  ReyieldUniswapToolTag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapToolTagKeySpecifier
      | (() => undefined | ReyieldUniswapToolTagKeySpecifier)
    fields?: ReyieldUniswapToolTagFieldPolicy
  }
  ReyieldUniswapToolToken?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapToolTokenKeySpecifier
      | (() => undefined | ReyieldUniswapToolTokenKeySpecifier)
    fields?: ReyieldUniswapToolTokenFieldPolicy
  }
  ReyieldUniswapToolTokenPriceChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapToolTokenPriceChartKeySpecifier
      | (() => undefined | ReyieldUniswapToolTokenPriceChartKeySpecifier)
    fields?: ReyieldUniswapToolTokenPriceChartFieldPolicy
  }
  ReyieldUniswapToolTokenPriceChartPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapToolTokenPriceChartPriceKeySpecifier
      | (() => undefined | ReyieldUniswapToolTokenPriceChartPriceKeySpecifier)
    fields?: ReyieldUniswapToolTokenPriceChartPriceFieldPolicy
  }
  ReyieldUniswapV3BacktestResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUniswapV3BacktestResultKeySpecifier
      | (() => undefined | ReyieldUniswapV3BacktestResultKeySpecifier)
    fields?: ReyieldUniswapV3BacktestResultFieldPolicy
  }
  ReyieldUserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserInfoKeySpecifier
      | (() => undefined | ReyieldUserInfoKeySpecifier)
    fields?: ReyieldUserInfoFieldPolicy
  }
  ReyieldUserStrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserStrategyInfoKeySpecifier
      | (() => undefined | ReyieldUserStrategyInfoKeySpecifier)
    fields?: ReyieldUserStrategyInfoFieldPolicy
  }
  ReyieldUserStrategyParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserStrategyParametersKeySpecifier
      | (() => undefined | ReyieldUserStrategyParametersKeySpecifier)
    fields?: ReyieldUserStrategyParametersFieldPolicy
  }
  ReyieldUserStrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserStrategyPerformanceKeySpecifier
      | (() => undefined | ReyieldUserStrategyPerformanceKeySpecifier)
    fields?: ReyieldUserStrategyPerformanceFieldPolicy
  }
  ReyieldUserStrategyProfit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserStrategyProfitKeySpecifier
      | (() => undefined | ReyieldUserStrategyProfitKeySpecifier)
    fields?: ReyieldUserStrategyProfitFieldPolicy
  }
  ReyieldUserStrategyRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserStrategyRecordKeySpecifier
      | (() => undefined | ReyieldUserStrategyRecordKeySpecifier)
    fields?: ReyieldUserStrategyRecordFieldPolicy
  }
  ReyieldUserUniswapOnchainPositionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserUniswapOnchainPositionInfoKeySpecifier
      | (() => undefined | ReyieldUserUniswapOnchainPositionInfoKeySpecifier)
    fields?: ReyieldUserUniswapOnchainPositionInfoFieldPolicy
  }
  ReyieldUserUniswapPositionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserUniswapPositionInfoKeySpecifier
      | (() => undefined | ReyieldUserUniswapPositionInfoKeySpecifier)
    fields?: ReyieldUserUniswapPositionInfoFieldPolicy
  }
  ReyieldUserUniswapToolOutOfRangeInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserUniswapToolOutOfRangeInfoKeySpecifier
      | (() => undefined | ReyieldUserUniswapToolOutOfRangeInfoKeySpecifier)
    fields?: ReyieldUserUniswapToolOutOfRangeInfoFieldPolicy
  }
  ReyieldUserUniswapToolSettingInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserUniswapToolSettingInfoKeySpecifier
      | (() => undefined | ReyieldUserUniswapToolSettingInfoKeySpecifier)
    fields?: ReyieldUserUniswapToolSettingInfoFieldPolicy
  }
  ReyieldUserUniswapv3PositionProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserUniswapv3PositionProgressKeySpecifier
      | (() => undefined | ReyieldUserUniswapv3PositionProgressKeySpecifier)
    fields?: ReyieldUserUniswapv3PositionProgressFieldPolicy
  }
  ReyieldUserUniswapv3PositionProgressStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldUserUniswapv3PositionProgressStepKeySpecifier
      | (() => undefined | ReyieldUserUniswapv3PositionProgressStepKeySpecifier)
    fields?: ReyieldUserUniswapv3PositionProgressStepFieldPolicy
  }
  ReyieldWalletBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldWalletBalancesKeySpecifier
      | (() => undefined | ReyieldWalletBalancesKeySpecifier)
    fields?: ReyieldWalletBalancesFieldPolicy
  }
  ReyieldaICompletionRecords?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldaICompletionRecordsKeySpecifier
      | (() => undefined | ReyieldaICompletionRecordsKeySpecifier)
    fields?: ReyieldaICompletionRecordsFieldPolicy
  }
  ReyieldsuggestStrategyToolWithAllocations?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldsuggestStrategyToolWithAllocationsKeySpecifier
      | (() => undefined | ReyieldsuggestStrategyToolWithAllocationsKeySpecifier)
    fields?: ReyieldsuggestStrategyToolWithAllocationsFieldPolicy
  }
  ReyieldsuggestStrategyToolWithAllocations_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReyieldsuggestStrategyToolWithAllocations_aggregateKeySpecifier
      | (() => undefined | ReyieldsuggestStrategyToolWithAllocations_aggregateKeySpecifier)
    fields?: ReyieldsuggestStrategyToolWithAllocations_aggregateFieldPolicy
  }
  ReyieldsuggestStrategyToolWithAllocations_aggregate_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | ReyieldsuggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier
      | (() => undefined | ReyieldsuggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier)
    fields?: ReyieldsuggestStrategyToolWithAllocations_aggregate_fieldsFieldPolicy
  }
  ReyieldsuggestStrategyToolWithAllocations_max_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | ReyieldsuggestStrategyToolWithAllocations_max_fieldsKeySpecifier
      | (() => undefined | ReyieldsuggestStrategyToolWithAllocations_max_fieldsKeySpecifier)
    fields?: ReyieldsuggestStrategyToolWithAllocations_max_fieldsFieldPolicy
  }
  ReyieldsuggestStrategyToolWithAllocations_min_fields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | ReyieldsuggestStrategyToolWithAllocations_min_fieldsKeySpecifier
      | (() => undefined | ReyieldsuggestStrategyToolWithAllocations_min_fieldsKeySpecifier)
    fields?: ReyieldsuggestStrategyToolWithAllocations_min_fieldsFieldPolicy
  }
  Section?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SectionKeySpecifier | (() => undefined | SectionKeySpecifier)
    fields?: SectionFieldPolicy
  }
  SectionCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionCollectionKeySpecifier
      | (() => undefined | SectionCollectionKeySpecifier)
    fields?: SectionCollectionFieldPolicy
  }
  SectionContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SectionContentKeySpecifier | (() => undefined | SectionContentKeySpecifier)
    fields?: SectionContentFieldPolicy
  }
  SectionContentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentCollectionKeySpecifier
      | (() => undefined | SectionContentCollectionKeySpecifier)
    fields?: SectionContentCollectionFieldPolicy
  }
  SectionContentDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentDescriptionKeySpecifier
      | (() => undefined | SectionContentDescriptionKeySpecifier)
    fields?: SectionContentDescriptionFieldPolicy
  }
  SectionContentDescriptionAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentDescriptionAssetsKeySpecifier
      | (() => undefined | SectionContentDescriptionAssetsKeySpecifier)
    fields?: SectionContentDescriptionAssetsFieldPolicy
  }
  SectionContentDescriptionEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentDescriptionEntriesKeySpecifier
      | (() => undefined | SectionContentDescriptionEntriesKeySpecifier)
    fields?: SectionContentDescriptionEntriesFieldPolicy
  }
  SectionContentDescriptionLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentDescriptionLinksKeySpecifier
      | (() => undefined | SectionContentDescriptionLinksKeySpecifier)
    fields?: SectionContentDescriptionLinksFieldPolicy
  }
  SectionContentDescriptionResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentDescriptionResourcesKeySpecifier
      | (() => undefined | SectionContentDescriptionResourcesKeySpecifier)
    fields?: SectionContentDescriptionResourcesFieldPolicy
  }
  SectionContentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionContentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionContentLinkingCollectionsKeySpecifier)
    fields?: SectionContentLinkingCollectionsFieldPolicy
  }
  SectionLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionLinkingCollectionsKeySpecifier
      | (() => undefined | SectionLinkingCollectionsKeySpecifier)
    fields?: SectionLinkingCollectionsFieldPolicy
  }
  SectionSectionContentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionSectionContentsCollectionKeySpecifier
      | (() => undefined | SectionSectionContentsCollectionKeySpecifier)
    fields?: SectionSectionContentsCollectionFieldPolicy
  }
  SmartContract?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SmartContractKeySpecifier | (() => undefined | SmartContractKeySpecifier)
    fields?: SmartContractFieldPolicy
  }
  SpecialUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SpecialUserKeySpecifier | (() => undefined | SpecialUserKeySpecifier)
    fields?: SpecialUserFieldPolicy
  }
  SpecialUserCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SpecialUserCollectionKeySpecifier
      | (() => undefined | SpecialUserCollectionKeySpecifier)
    fields?: SpecialUserCollectionFieldPolicy
  }
  SpecialUserLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SpecialUserLinkingCollectionsKeySpecifier
      | (() => undefined | SpecialUserLinkingCollectionsKeySpecifier)
    fields?: SpecialUserLinkingCollectionsFieldPolicy
  }
  StrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StrategyInfoKeySpecifier | (() => undefined | StrategyInfoKeySpecifier)
    fields?: StrategyInfoFieldPolicy
  }
  StrategyInfos?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StrategyInfosKeySpecifier | (() => undefined | StrategyInfosKeySpecifier)
    fields?: StrategyInfosFieldPolicy
  }
  StrategyMeta?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StrategyMetaKeySpecifier | (() => undefined | StrategyMetaKeySpecifier)
    fields?: StrategyMetaFieldPolicy
  }
  StrategyMetaCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyMetaCollectionKeySpecifier
      | (() => undefined | StrategyMetaCollectionKeySpecifier)
    fields?: StrategyMetaCollectionFieldPolicy
  }
  StrategyMetaLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyMetaLinkingCollectionsKeySpecifier
      | (() => undefined | StrategyMetaLinkingCollectionsKeySpecifier)
    fields?: StrategyMetaLinkingCollectionsFieldPolicy
  }
  StrategyMetaSectionsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyMetaSectionsCollectionKeySpecifier
      | (() => undefined | StrategyMetaSectionsCollectionKeySpecifier)
    fields?: StrategyMetaSectionsCollectionFieldPolicy
  }
  StrategyParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyParametersKeySpecifier
      | (() => undefined | StrategyParametersKeySpecifier)
    fields?: StrategyParametersFieldPolicy
  }
  StrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyPerformanceKeySpecifier
      | (() => undefined | StrategyPerformanceKeySpecifier)
    fields?: StrategyPerformanceFieldPolicy
  }
  StrategyProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyProgressKeySpecifier
      | (() => undefined | StrategyProgressKeySpecifier)
    fields?: StrategyProgressFieldPolicy
  }
  StrategyProgressStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyProgressStepKeySpecifier
      | (() => undefined | StrategyProgressStepKeySpecifier)
    fields?: StrategyProgressStepFieldPolicy
  }
  StrategyStartResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyStartResponseKeySpecifier
      | (() => undefined | StrategyStartResponseKeySpecifier)
    fields?: StrategyStartResponseFieldPolicy
  }
  StrategyStopResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyStopResponseKeySpecifier
      | (() => undefined | StrategyStopResponseKeySpecifier)
    fields?: StrategyStopResponseFieldPolicy
  }
  StrategyUpdateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StrategyUpdateResponseKeySpecifier
      | (() => undefined | StrategyUpdateResponseKeySpecifier)
    fields?: StrategyUpdateResponseFieldPolicy
  }
  Strategy_Action?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionKeySpecifier
      | (() => undefined | Strategy_ActionKeySpecifier)
    fields?: Strategy_ActionFieldPolicy
  }
  Strategy_ActionDEFIApproveSwapReward?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIApproveSwapRewardKeySpecifier
      | (() => undefined | Strategy_ActionDEFIApproveSwapRewardKeySpecifier)
    fields?: Strategy_ActionDEFIApproveSwapRewardFieldPolicy
  }
  Strategy_ActionDEFIDeductTxnFee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIDeductTxnFeeKeySpecifier
      | (() => undefined | Strategy_ActionDEFIDeductTxnFeeKeySpecifier)
    fields?: Strategy_ActionDEFIDeductTxnFeeFieldPolicy
  }
  Strategy_ActionDEFIDispatchUSDTReward?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIDispatchUSDTRewardKeySpecifier
      | (() => undefined | Strategy_ActionDEFIDispatchUSDTRewardKeySpecifier)
    fields?: Strategy_ActionDEFIDispatchUSDTRewardFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVAddLiquidity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVAddLiquidityKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVAddLiquidityKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVAddLiquidityFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVApproveConvexBooster?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVApproveConvexBoosterKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVApproveConvexBoosterKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVApproveConvexBoosterFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVApproveCurve3Pool?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVApproveCurve3PoolKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVApproveCurve3PoolKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVApproveCurve3PoolFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVClaimReward?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVClaimRewardKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVClaimRewardKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVClaimRewardFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVDepositAll?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVDepositAllKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVDepositAllKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVDepositAllFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVRemoveLiquidity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVRemoveLiquidityKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVRemoveLiquidityKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVRemoveLiquidityFieldPolicy
  }
  Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrap?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrapKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrapKeySpecifier)
    fields?: Strategy_ActionDEFIMIM3CRVWithdrawAndUnwrapFieldPolicy
  }
  Strategy_ActionDEFIMIMUSDTApproveCurve3Pool?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIMIMUSDTApproveCurve3PoolKeySpecifier
      | (() => undefined | Strategy_ActionDEFIMIMUSDTApproveCurve3PoolKeySpecifier)
    fields?: Strategy_ActionDEFIMIMUSDTApproveCurve3PoolFieldPolicy
  }
  Strategy_ActionDEFIRebalanceTxnFeeEstimation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIRebalanceTxnFeeEstimationKeySpecifier
      | (() => undefined | Strategy_ActionDEFIRebalanceTxnFeeEstimationKeySpecifier)
    fields?: Strategy_ActionDEFIRebalanceTxnFeeEstimationFieldPolicy
  }
  Strategy_ActionDEFIStakePool?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIStakePoolKeySpecifier
      | (() => undefined | Strategy_ActionDEFIStakePoolKeySpecifier)
    fields?: Strategy_ActionDEFIStakePoolFieldPolicy
  }
  Strategy_ActionDEFISwapETHToUSDT?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFISwapETHToUSDTKeySpecifier
      | (() => undefined | Strategy_ActionDEFISwapETHToUSDTKeySpecifier)
    fields?: Strategy_ActionDEFISwapETHToUSDTFieldPolicy
  }
  Strategy_ActionDEFISwapReward?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFISwapRewardKeySpecifier
      | (() => undefined | Strategy_ActionDEFISwapRewardKeySpecifier)
    fields?: Strategy_ActionDEFISwapRewardFieldPolicy
  }
  Strategy_ActionDEFITransferUserLPBalanceToPool?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFITransferUserLPBalanceToPoolKeySpecifier
      | (() => undefined | Strategy_ActionDEFITransferUserLPBalanceToPoolKeySpecifier)
    fields?: Strategy_ActionDEFITransferUserLPBalanceToPoolFieldPolicy
  }
  Strategy_ActionDEFIUnStakePool?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUnStakePoolKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUnStakePoolKeySpecifier)
    fields?: Strategy_ActionDEFIUnStakePoolFieldPolicy
  }
  Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidity?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidityKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidityKeySpecifier)
    fields?: Strategy_ActionDEFIUpdatePoolBalanceAfterAddLiquidityFieldPolicy
  }
  Strategy_ActionDEFIUpdatePoolBalanceAfterClaimReward?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUpdatePoolBalanceAfterClaimRewardKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUpdatePoolBalanceAfterClaimRewardKeySpecifier)
    fields?: Strategy_ActionDEFIUpdatePoolBalanceAfterClaimRewardFieldPolicy
  }
  Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidity?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidityKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidityKeySpecifier)
    fields?: Strategy_ActionDEFIUpdatePoolBalanceAfterRmLiquidityFieldPolicy
  }
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDT?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDTKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDTKeySpecifier)
    fields?: Strategy_ActionDEFIUpdatePoolBalanceAfterSwapETHToUSDTFieldPolicy
  }
  Strategy_ActionDEFIUpdatePoolBalanceAfterSwapReward?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUpdatePoolBalanceAfterSwapRewardKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUpdatePoolBalanceAfterSwapRewardKeySpecifier)
    fields?: Strategy_ActionDEFIUpdatePoolBalanceAfterSwapRewardFieldPolicy
  }
  Strategy_ActionDEFIUpdateUserRecordStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionDEFIUpdateUserRecordStatusKeySpecifier
      | (() => undefined | Strategy_ActionDEFIUpdateUserRecordStatusKeySpecifier)
    fields?: Strategy_ActionDEFIUpdateUserRecordStatusFieldPolicy
  }
  Strategy_ActionExternalDepositCheck?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionExternalDepositCheckKeySpecifier
      | (() => undefined | Strategy_ActionExternalDepositCheckKeySpecifier)
    fields?: Strategy_ActionExternalDepositCheckFieldPolicy
  }
  Strategy_ActionExternalSwap?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionExternalSwapKeySpecifier
      | (() => undefined | Strategy_ActionExternalSwapKeySpecifier)
    fields?: Strategy_ActionExternalSwapFieldPolicy
  }
  Strategy_ActionExternalWalletTransfer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionExternalWalletTransferKeySpecifier
      | (() => undefined | Strategy_ActionExternalWalletTransferKeySpecifier)
    fields?: Strategy_ActionExternalWalletTransferFieldPolicy
  }
  Strategy_ActionExternalWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionExternalWithdrawalKeySpecifier
      | (() => undefined | Strategy_ActionExternalWithdrawalKeySpecifier)
    fields?: Strategy_ActionExternalWithdrawalFieldPolicy
  }
  Strategy_ActionInternalDepositCheck?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionInternalDepositCheckKeySpecifier
      | (() => undefined | Strategy_ActionInternalDepositCheckKeySpecifier)
    fields?: Strategy_ActionInternalDepositCheckFieldPolicy
  }
  Strategy_ActionInternalTrade?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionInternalTradeKeySpecifier
      | (() => undefined | Strategy_ActionInternalTradeKeySpecifier)
    fields?: Strategy_ActionInternalTradeFieldPolicy
  }
  Strategy_ActionInternalWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionInternalWithdrawalKeySpecifier
      | (() => undefined | Strategy_ActionInternalWithdrawalKeySpecifier)
    fields?: Strategy_ActionInternalWithdrawalFieldPolicy
  }
  Strategy_ActionLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionLogKeySpecifier
      | (() => undefined | Strategy_ActionLogKeySpecifier)
    fields?: Strategy_ActionLogFieldPolicy
  }
  Strategy_ActionStrategyBatchStart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionStrategyBatchStartKeySpecifier
      | (() => undefined | Strategy_ActionStrategyBatchStartKeySpecifier)
    fields?: Strategy_ActionStrategyBatchStartFieldPolicy
  }
  Strategy_ActionStrategyBatchStop?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionStrategyBatchStopKeySpecifier
      | (() => undefined | Strategy_ActionStrategyBatchStopKeySpecifier)
    fields?: Strategy_ActionStrategyBatchStopFieldPolicy
  }
  Strategy_ActionStrategyBatchUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionStrategyBatchUpdateKeySpecifier
      | (() => undefined | Strategy_ActionStrategyBatchUpdateKeySpecifier)
    fields?: Strategy_ActionStrategyBatchUpdateFieldPolicy
  }
  Strategy_ActionStrategyStart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionStrategyStartKeySpecifier
      | (() => undefined | Strategy_ActionStrategyStartKeySpecifier)
    fields?: Strategy_ActionStrategyStartFieldPolicy
  }
  Strategy_ActionStrategyStop?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionStrategyStopKeySpecifier
      | (() => undefined | Strategy_ActionStrategyStopKeySpecifier)
    fields?: Strategy_ActionStrategyStopFieldPolicy
  }
  Strategy_ActionStrategyUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionStrategyUpdateKeySpecifier
      | (() => undefined | Strategy_ActionStrategyUpdateKeySpecifier)
    fields?: Strategy_ActionStrategyUpdateFieldPolicy
  }
  Strategy_ActionTransferFromDEFIWalletPostProcessing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionTransferFromDEFIWalletPostProcessingKeySpecifier
      | (() => undefined | Strategy_ActionTransferFromDEFIWalletPostProcessingKeySpecifier)
    fields?: Strategy_ActionTransferFromDEFIWalletPostProcessingFieldPolicy
  }
  Strategy_ActionTransferFromDEFIWalletPreProcessing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionTransferFromDEFIWalletPreProcessingKeySpecifier
      | (() => undefined | Strategy_ActionTransferFromDEFIWalletPreProcessingKeySpecifier)
    fields?: Strategy_ActionTransferFromDEFIWalletPreProcessingFieldPolicy
  }
  Strategy_ActionTransferFromDEFIWalletProcessing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionTransferFromDEFIWalletProcessingKeySpecifier
      | (() => undefined | Strategy_ActionTransferFromDEFIWalletProcessingKeySpecifier)
    fields?: Strategy_ActionTransferFromDEFIWalletProcessingFieldPolicy
  }
  Strategy_ActionTransferToDEFIWalletPostProcessing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionTransferToDEFIWalletPostProcessingKeySpecifier
      | (() => undefined | Strategy_ActionTransferToDEFIWalletPostProcessingKeySpecifier)
    fields?: Strategy_ActionTransferToDEFIWalletPostProcessingFieldPolicy
  }
  Strategy_ActionTransferToDEFIWalletPreProcessing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionTransferToDEFIWalletPreProcessingKeySpecifier
      | (() => undefined | Strategy_ActionTransferToDEFIWalletPreProcessingKeySpecifier)
    fields?: Strategy_ActionTransferToDEFIWalletPreProcessingFieldPolicy
  }
  Strategy_ActionTransferToDEFIWalletProcessing?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionTransferToDEFIWalletProcessingKeySpecifier
      | (() => undefined | Strategy_ActionTransferToDEFIWalletProcessingKeySpecifier)
    fields?: Strategy_ActionTransferToDEFIWalletProcessingFieldPolicy
  }
  Strategy_ActionWatchTxnStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ActionWatchTxnStatusKeySpecifier
      | (() => undefined | Strategy_ActionWatchTxnStatusKeySpecifier)
    fields?: Strategy_ActionWatchTxnStatusFieldPolicy
  }
  Strategy_Admin?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | Strategy_AdminKeySpecifier | (() => undefined | Strategy_AdminKeySpecifier)
    fields?: Strategy_AdminFieldPolicy
  }
  Strategy_AdminMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_AdminMutationKeySpecifier
      | (() => undefined | Strategy_AdminMutationKeySpecifier)
    fields?: Strategy_AdminMutationFieldPolicy
  }
  Strategy_ArbitrageState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ArbitrageStateKeySpecifier
      | (() => undefined | Strategy_ArbitrageStateKeySpecifier)
    fields?: Strategy_ArbitrageStateFieldPolicy
  }
  Strategy_Balance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_BalanceKeySpecifier
      | (() => undefined | Strategy_BalanceKeySpecifier)
    fields?: Strategy_BalanceFieldPolicy
  }
  Strategy_CurrencyAmount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_CurrencyAmountKeySpecifier
      | (() => undefined | Strategy_CurrencyAmountKeySpecifier)
    fields?: Strategy_CurrencyAmountFieldPolicy
  }
  Strategy_DailyProfit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_DailyProfitKeySpecifier
      | (() => undefined | Strategy_DailyProfitKeySpecifier)
    fields?: Strategy_DailyProfitFieldPolicy
  }
  Strategy_DebtRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_DebtRecordKeySpecifier
      | (() => undefined | Strategy_DebtRecordKeySpecifier)
    fields?: Strategy_DebtRecordFieldPolicy
  }
  Strategy_EstimatePointsConsumingResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_EstimatePointsConsumingResultKeySpecifier
      | (() => undefined | Strategy_EstimatePointsConsumingResultKeySpecifier)
    fields?: Strategy_EstimatePointsConsumingResultFieldPolicy
  }
  Strategy_EstimateStrategyRequiredAmountResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_EstimateStrategyRequiredAmountResponseKeySpecifier
      | (() => undefined | Strategy_EstimateStrategyRequiredAmountResponseKeySpecifier)
    fields?: Strategy_EstimateStrategyRequiredAmountResponseFieldPolicy
  }
  Strategy_ExchangeAsset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExchangeAssetKeySpecifier
      | (() => undefined | Strategy_ExchangeAssetKeySpecifier)
    fields?: Strategy_ExchangeAssetFieldPolicy
  }
  Strategy_ExchangeWithdrawalAuthorizationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExchangeWithdrawalAuthorizationResponseKeySpecifier
      | (() => undefined | Strategy_ExchangeWithdrawalAuthorizationResponseKeySpecifier)
    fields?: Strategy_ExchangeWithdrawalAuthorizationResponseFieldPolicy
  }
  Strategy_ExchangesNetAssetChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExchangesNetAssetChartKeySpecifier
      | (() => undefined | Strategy_ExchangesNetAssetChartKeySpecifier)
    fields?: Strategy_ExchangesNetAssetChartFieldPolicy
  }
  Strategy_ExchangesNetAssetChartData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExchangesNetAssetChartDataKeySpecifier
      | (() => undefined | Strategy_ExchangesNetAssetChartDataKeySpecifier)
    fields?: Strategy_ExchangesNetAssetChartDataFieldPolicy
  }
  Strategy_ExternalAPIKey?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExternalAPIKeyKeySpecifier
      | (() => undefined | Strategy_ExternalAPIKeyKeySpecifier)
    fields?: Strategy_ExternalAPIKeyFieldPolicy
  }
  Strategy_ExternalAPIKeyMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExternalAPIKeyMutationKeySpecifier
      | (() => undefined | Strategy_ExternalAPIKeyMutationKeySpecifier)
    fields?: Strategy_ExternalAPIKeyMutationFieldPolicy
  }
  Strategy_ExternalAPIKeyWithdrawal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_ExternalAPIKeyWithdrawalKeySpecifier
      | (() => undefined | Strategy_ExternalAPIKeyWithdrawalKeySpecifier)
    fields?: Strategy_ExternalAPIKeyWithdrawalFieldPolicy
  }
  Strategy_GenericFlowMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_GenericFlowMutationKeySpecifier
      | (() => undefined | Strategy_GenericFlowMutationKeySpecifier)
    fields?: Strategy_GenericFlowMutationFieldPolicy
  }
  Strategy_LendingOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_LendingOfferKeySpecifier
      | (() => undefined | Strategy_LendingOfferKeySpecifier)
    fields?: Strategy_LendingOfferFieldPolicy
  }
  Strategy_LendingState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_LendingStateKeySpecifier
      | (() => undefined | Strategy_LendingStateKeySpecifier)
    fields?: Strategy_LendingStateFieldPolicy
  }
  Strategy_Performance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_PerformanceKeySpecifier
      | (() => undefined | Strategy_PerformanceKeySpecifier)
    fields?: Strategy_PerformanceFieldPolicy
  }
  Strategy_Public?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_PublicKeySpecifier
      | (() => undefined | Strategy_PublicKeySpecifier)
    fields?: Strategy_PublicFieldPolicy
  }
  Strategy_Strategy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyKeySpecifier
      | (() => undefined | Strategy_StrategyKeySpecifier)
    fields?: Strategy_StrategyFieldPolicy
  }
  Strategy_StrategyDebt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyDebtKeySpecifier
      | (() => undefined | Strategy_StrategyDebtKeySpecifier)
    fields?: Strategy_StrategyDebtFieldPolicy
  }
  Strategy_StrategyDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyDescriptionKeySpecifier
      | (() => undefined | Strategy_StrategyDescriptionKeySpecifier)
    fields?: Strategy_StrategyDescriptionFieldPolicy
  }
  Strategy_StrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyInfoKeySpecifier
      | (() => undefined | Strategy_StrategyInfoKeySpecifier)
    fields?: Strategy_StrategyInfoFieldPolicy
  }
  Strategy_StrategyMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyMutationKeySpecifier
      | (() => undefined | Strategy_StrategyMutationKeySpecifier)
    fields?: Strategy_StrategyMutationFieldPolicy
  }
  Strategy_StrategyParameter?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyParameterKeySpecifier
      | (() => undefined | Strategy_StrategyParameterKeySpecifier)
    fields?: Strategy_StrategyParameterFieldPolicy
  }
  Strategy_StrategyParameterDesc?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyParameterDescKeySpecifier
      | (() => undefined | Strategy_StrategyParameterDescKeySpecifier)
    fields?: Strategy_StrategyParameterDescFieldPolicy
  }
  Strategy_StrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_StrategyPerformanceKeySpecifier
      | (() => undefined | Strategy_StrategyPerformanceKeySpecifier)
    fields?: Strategy_StrategyPerformanceFieldPolicy
  }
  Strategy_User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | Strategy_UserKeySpecifier | (() => undefined | Strategy_UserKeySpecifier)
    fields?: Strategy_UserFieldPolicy
  }
  Strategy_UserMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_UserMutationKeySpecifier
      | (() => undefined | Strategy_UserMutationKeySpecifier)
    fields?: Strategy_UserMutationFieldPolicy
  }
  Strategy_UserProfit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_UserProfitKeySpecifier
      | (() => undefined | Strategy_UserProfitKeySpecifier)
    fields?: Strategy_UserProfitFieldPolicy
  }
  Strategy_Workflow?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_WorkflowKeySpecifier
      | (() => undefined | Strategy_WorkflowKeySpecifier)
    fields?: Strategy_WorkflowFieldPolicy
  }
  Strategy_YieldRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Strategy_YieldRecordKeySpecifier
      | (() => undefined | Strategy_YieldRecordKeySpecifier)
    fields?: Strategy_YieldRecordFieldPolicy
  }
  Sys?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SysKeySpecifier | (() => undefined | SysKeySpecifier)
    fields?: SysFieldPolicy
  }
  TokenBalance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TokenBalanceKeySpecifier | (() => undefined | TokenBalanceKeySpecifier)
    fields?: TokenBalanceFieldPolicy
  }
  TokenDisplayInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TokenDisplayInfoKeySpecifier
      | (() => undefined | TokenDisplayInfoKeySpecifier)
    fields?: TokenDisplayInfoFieldPolicy
  }
  TotalProfits?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TotalProfitsKeySpecifier | (() => undefined | TotalProfitsKeySpecifier)
    fields?: TotalProfitsFieldPolicy
  }
  Trade?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TradeKeySpecifier | (() => undefined | TradeKeySpecifier)
    fields?: TradeFieldPolicy
  }
  TradeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TradeResponseKeySpecifier | (() => undefined | TradeResponseKeySpecifier)
    fields?: TradeResponseFieldPolicy
  }
  Trading?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TradingKeySpecifier | (() => undefined | TradingKeySpecifier)
    fields?: TradingFieldPolicy
  }
  TradingConfirmQuotationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TradingConfirmQuotationResponseKeySpecifier
      | (() => undefined | TradingConfirmQuotationResponseKeySpecifier)
    fields?: TradingConfirmQuotationResponseFieldPolicy
  }
  TradingMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TradingMutationKeySpecifier
      | (() => undefined | TradingMutationKeySpecifier)
    fields?: TradingMutationFieldPolicy
  }
  TradingQuotationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TradingQuotationResponseKeySpecifier
      | (() => undefined | TradingQuotationResponseKeySpecifier)
    fields?: TradingQuotationResponseFieldPolicy
  }
  TradingReportResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TradingReportResponseKeySpecifier
      | (() => undefined | TradingReportResponseKeySpecifier)
    fields?: TradingReportResponseFieldPolicy
  }
  UniswapOnchainPositionAmountInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapOnchainPositionAmountInfoKeySpecifier
      | (() => undefined | UniswapOnchainPositionAmountInfoKeySpecifier)
    fields?: UniswapOnchainPositionAmountInfoFieldPolicy
  }
  UniswapPoolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapPoolInfoKeySpecifier
      | (() => undefined | UniswapPoolInfoKeySpecifier)
    fields?: UniswapPoolInfoFieldPolicy
  }
  UniswapPoolPosition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapPoolPositionKeySpecifier
      | (() => undefined | UniswapPoolPositionKeySpecifier)
    fields?: UniswapPoolPositionFieldPolicy
  }
  UniswapPoolTick?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapPoolTickKeySpecifier
      | (() => undefined | UniswapPoolTickKeySpecifier)
    fields?: UniswapPoolTickFieldPolicy
  }
  UniswapQuote?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UniswapQuoteKeySpecifier | (() => undefined | UniswapQuoteKeySpecifier)
    fields?: UniswapQuoteFieldPolicy
  }
  UniswapToolFeeTier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolFeeTierKeySpecifier
      | (() => undefined | UniswapToolFeeTierKeySpecifier)
    fields?: UniswapToolFeeTierFieldPolicy
  }
  UniswapToolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolInfoKeySpecifier
      | (() => undefined | UniswapToolInfoKeySpecifier)
    fields?: UniswapToolInfoFieldPolicy
  }
  UniswapToolTag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UniswapToolTagKeySpecifier | (() => undefined | UniswapToolTagKeySpecifier)
    fields?: UniswapToolTagFieldPolicy
  }
  UniswapToolToken?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolTokenKeySpecifier
      | (() => undefined | UniswapToolTokenKeySpecifier)
    fields?: UniswapToolTokenFieldPolicy
  }
  UniswapToolTokenPriceChart?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolTokenPriceChartKeySpecifier
      | (() => undefined | UniswapToolTokenPriceChartKeySpecifier)
    fields?: UniswapToolTokenPriceChartFieldPolicy
  }
  UniswapToolTokenPriceChartPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapToolTokenPriceChartPriceKeySpecifier
      | (() => undefined | UniswapToolTokenPriceChartPriceKeySpecifier)
    fields?: UniswapToolTokenPriceChartPriceFieldPolicy
  }
  UniswapV3BacktestResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UniswapV3BacktestResultKeySpecifier
      | (() => undefined | UniswapV3BacktestResultKeySpecifier)
    fields?: UniswapV3BacktestResultFieldPolicy
  }
  User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier)
    fields?: UserFieldPolicy
  }
  UserActionLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserActionLogKeySpecifier | (() => undefined | UserActionLogKeySpecifier)
    fields?: UserActionLogFieldPolicy
  }
  UserActionLogResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserActionLogResponseKeySpecifier
      | (() => undefined | UserActionLogResponseKeySpecifier)
    fields?: UserActionLogResponseFieldPolicy
  }
  UserAgreeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserAgreeResponseKeySpecifier
      | (() => undefined | UserAgreeResponseKeySpecifier)
    fields?: UserAgreeResponseFieldPolicy
  }
  UserBindPhoneConfirmationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBindPhoneConfirmationResponseKeySpecifier
      | (() => undefined | UserBindPhoneConfirmationResponseKeySpecifier)
    fields?: UserBindPhoneConfirmationResponseFieldPolicy
  }
  UserBindPhoneResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBindPhoneResponseKeySpecifier
      | (() => undefined | UserBindPhoneResponseKeySpecifier)
    fields?: UserBindPhoneResponseFieldPolicy
  }
  UserCancelOAuthResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserCancelOAuthResponseKeySpecifier
      | (() => undefined | UserCancelOAuthResponseKeySpecifier)
    fields?: UserCancelOAuthResponseFieldPolicy
  }
  UserChangePasswordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserChangePasswordResponseKeySpecifier
      | (() => undefined | UserChangePasswordResponseKeySpecifier)
    fields?: UserChangePasswordResponseFieldPolicy
  }
  UserCustomizeReferralResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserCustomizeReferralResponseKeySpecifier
      | (() => undefined | UserCustomizeReferralResponseKeySpecifier)
    fields?: UserCustomizeReferralResponseFieldPolicy
  }
  UserDeleteBankAccountResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserDeleteBankAccountResponseKeySpecifier
      | (() => undefined | UserDeleteBankAccountResponseKeySpecifier)
    fields?: UserDeleteBankAccountResponseFieldPolicy
  }
  UserDeviceTokenResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserDeviceTokenResponseKeySpecifier
      | (() => undefined | UserDeviceTokenResponseKeySpecifier)
    fields?: UserDeviceTokenResponseFieldPolicy
  }
  UserFillReferrerCodeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserFillReferrerCodeResponseKeySpecifier
      | (() => undefined | UserFillReferrerCodeResponseKeySpecifier)
    fields?: UserFillReferrerCodeResponseFieldPolicy
  }
  UserForgotPasswordConfirmResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserForgotPasswordConfirmResponseKeySpecifier
      | (() => undefined | UserForgotPasswordConfirmResponseKeySpecifier)
    fields?: UserForgotPasswordConfirmResponseFieldPolicy
  }
  UserForgotPasswordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserForgotPasswordResponseKeySpecifier
      | (() => undefined | UserForgotPasswordResponseKeySpecifier)
    fields?: UserForgotPasswordResponseFieldPolicy
  }
  UserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserInfoKeySpecifier | (() => undefined | UserInfoKeySpecifier)
    fields?: UserInfoFieldPolicy
  }
  UserLoginRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserLoginRecordKeySpecifier
      | (() => undefined | UserLoginRecordKeySpecifier)
    fields?: UserLoginRecordFieldPolicy
  }
  UserLoginRecordsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserLoginRecordsResponseKeySpecifier
      | (() => undefined | UserLoginRecordsResponseKeySpecifier)
    fields?: UserLoginRecordsResponseFieldPolicy
  }
  UserLoginResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserLoginResponseKeySpecifier
      | (() => undefined | UserLoginResponseKeySpecifier)
    fields?: UserLoginResponseFieldPolicy
  }
  UserMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserMutationKeySpecifier | (() => undefined | UserMutationKeySpecifier)
    fields?: UserMutationFieldPolicy
  }
  UserOAuthClient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserOAuthClientKeySpecifier
      | (() => undefined | UserOAuthClientKeySpecifier)
    fields?: UserOAuthClientFieldPolicy
  }
  UserOAuthClientResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserOAuthClientResponseKeySpecifier
      | (() => undefined | UserOAuthClientResponseKeySpecifier)
    fields?: UserOAuthClientResponseFieldPolicy
  }
  UserPreference?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserPreferenceKeySpecifier | (() => undefined | UserPreferenceKeySpecifier)
    fields?: UserPreferenceFieldPolicy
  }
  UserPreferenceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserPreferenceResponseKeySpecifier
      | (() => undefined | UserPreferenceResponseKeySpecifier)
    fields?: UserPreferenceResponseFieldPolicy
  }
  UserReferral?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserReferralKeySpecifier | (() => undefined | UserReferralKeySpecifier)
    fields?: UserReferralFieldPolicy
  }
  UserRefreshTokenResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserRefreshTokenResponseKeySpecifier
      | (() => undefined | UserRefreshTokenResponseKeySpecifier)
    fields?: UserRefreshTokenResponseFieldPolicy
  }
  UserRegisterConfirmResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserRegisterConfirmResponseKeySpecifier
      | (() => undefined | UserRegisterConfirmResponseKeySpecifier)
    fields?: UserRegisterConfirmResponseFieldPolicy
  }
  UserRegisterResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserRegisterResponseKeySpecifier
      | (() => undefined | UserRegisterResponseKeySpecifier)
    fields?: UserRegisterResponseFieldPolicy
  }
  UserRequestAccountDisableResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserRequestAccountDisableResponseKeySpecifier
      | (() => undefined | UserRequestAccountDisableResponseKeySpecifier)
    fields?: UserRequestAccountDisableResponseFieldPolicy
  }
  UserResendForgotPasswordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserResendForgotPasswordResponseKeySpecifier
      | (() => undefined | UserResendForgotPasswordResponseKeySpecifier)
    fields?: UserResendForgotPasswordResponseFieldPolicy
  }
  UserResendRegisterResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserResendRegisterResponseKeySpecifier
      | (() => undefined | UserResendRegisterResponseKeySpecifier)
    fields?: UserResendRegisterResponseFieldPolicy
  }
  UserStrategyInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyInfoKeySpecifier
      | (() => undefined | UserStrategyInfoKeySpecifier)
    fields?: UserStrategyInfoFieldPolicy
  }
  UserStrategyParameters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyParametersKeySpecifier
      | (() => undefined | UserStrategyParametersKeySpecifier)
    fields?: UserStrategyParametersFieldPolicy
  }
  UserStrategyPerformance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyPerformanceKeySpecifier
      | (() => undefined | UserStrategyPerformanceKeySpecifier)
    fields?: UserStrategyPerformanceFieldPolicy
  }
  UserStrategyProfit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyProfitKeySpecifier
      | (() => undefined | UserStrategyProfitKeySpecifier)
    fields?: UserStrategyProfitFieldPolicy
  }
  UserStrategyRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserStrategyRecordKeySpecifier
      | (() => undefined | UserStrategyRecordKeySpecifier)
    fields?: UserStrategyRecordFieldPolicy
  }
  UserUniswapOnchainPositionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapOnchainPositionInfoKeySpecifier
      | (() => undefined | UserUniswapOnchainPositionInfoKeySpecifier)
    fields?: UserUniswapOnchainPositionInfoFieldPolicy
  }
  UserUniswapPositionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapPositionInfoKeySpecifier
      | (() => undefined | UserUniswapPositionInfoKeySpecifier)
    fields?: UserUniswapPositionInfoFieldPolicy
  }
  UserUniswapToolOutOfRangeInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapToolOutOfRangeInfoKeySpecifier
      | (() => undefined | UserUniswapToolOutOfRangeInfoKeySpecifier)
    fields?: UserUniswapToolOutOfRangeInfoFieldPolicy
  }
  UserUniswapToolSettingInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapToolSettingInfoKeySpecifier
      | (() => undefined | UserUniswapToolSettingInfoKeySpecifier)
    fields?: UserUniswapToolSettingInfoFieldPolicy
  }
  UserUniswapv3PositionProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapv3PositionProgressKeySpecifier
      | (() => undefined | UserUniswapv3PositionProgressKeySpecifier)
    fields?: UserUniswapv3PositionProgressFieldPolicy
  }
  UserUniswapv3PositionProgressStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserUniswapv3PositionProgressStepKeySpecifier
      | (() => undefined | UserUniswapv3PositionProgressStepKeySpecifier)
    fields?: UserUniswapv3PositionProgressStepFieldPolicy
  }
  VersionInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VersionInfoKeySpecifier | (() => undefined | VersionInfoKeySpecifier)
    fields?: VersionInfoFieldPolicy
  }
  Voucher?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VoucherKeySpecifier | (() => undefined | VoucherKeySpecifier)
    fields?: VoucherFieldPolicy
  }
  VoucherMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VoucherMutationKeySpecifier
      | (() => undefined | VoucherMutationKeySpecifier)
    fields?: VoucherMutationFieldPolicy
  }
  VoucherRecord?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VoucherRecordKeySpecifier | (() => undefined | VoucherRecordKeySpecifier)
    fields?: VoucherRecordFieldPolicy
  }
  VoucherUseResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VoucherUseResponseKeySpecifier
      | (() => undefined | VoucherUseResponseKeySpecifier)
    fields?: VoucherUseResponseFieldPolicy
  }
  Wallet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WalletKeySpecifier | (() => undefined | WalletKeySpecifier)
    fields?: WalletFieldPolicy
  }
  WalletBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WalletBalancesKeySpecifier | (() => undefined | WalletBalancesKeySpecifier)
    fields?: WalletBalancesFieldPolicy
  }
  WalletCreateCommonAddressResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletCreateCommonAddressResponseKeySpecifier
      | (() => undefined | WalletCreateCommonAddressResponseKeySpecifier)
    fields?: WalletCreateCommonAddressResponseFieldPolicy
  }
  WalletCreateCryptoAddressResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletCreateCryptoAddressResponseKeySpecifier
      | (() => undefined | WalletCreateCryptoAddressResponseKeySpecifier)
    fields?: WalletCreateCryptoAddressResponseFieldPolicy
  }
  WalletCryptoWithdrawResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletCryptoWithdrawResponseKeySpecifier
      | (() => undefined | WalletCryptoWithdrawResponseKeySpecifier)
    fields?: WalletCryptoWithdrawResponseFieldPolicy
  }
  WalletDeleteCommonAddressResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletDeleteCommonAddressResponseKeySpecifier
      | (() => undefined | WalletDeleteCommonAddressResponseKeySpecifier)
    fields?: WalletDeleteCommonAddressResponseFieldPolicy
  }
  WalletDepositResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletDepositResponseKeySpecifier
      | (() => undefined | WalletDepositResponseKeySpecifier)
    fields?: WalletDepositResponseFieldPolicy
  }
  WalletFAASPaymentConfirmResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletFAASPaymentConfirmResponseKeySpecifier
      | (() => undefined | WalletFAASPaymentConfirmResponseKeySpecifier)
    fields?: WalletFAASPaymentConfirmResponseFieldPolicy
  }
  WalletFiatWithdrawResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletFiatWithdrawResponseKeySpecifier
      | (() => undefined | WalletFiatWithdrawResponseKeySpecifier)
    fields?: WalletFiatWithdrawResponseFieldPolicy
  }
  WalletMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WalletMutationKeySpecifier | (() => undefined | WalletMutationKeySpecifier)
    fields?: WalletMutationFieldPolicy
  }
  WalletWithdrawConfirmResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletWithdrawConfirmResponseKeySpecifier
      | (() => undefined | WalletWithdrawConfirmResponseKeySpecifier)
    fields?: WalletWithdrawConfirmResponseFieldPolicy
  }
  WalletWithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WalletWithdrawalResponseKeySpecifier
      | (() => undefined | WalletWithdrawalResponseKeySpecifier)
    fields?: WalletWithdrawalResponseFieldPolicy
  }
  WithdrawInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WithdrawInfoKeySpecifier | (() => undefined | WithdrawInfoKeySpecifier)
    fields?: WithdrawInfoFieldPolicy
  }
  WithdrawalResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WithdrawalResponseKeySpecifier
      | (() => undefined | WithdrawalResponseKeySpecifier)
    fields?: WithdrawalResponseFieldPolicy
  }
  aICompletionRecords?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecordsKeySpecifier
      | (() => undefined | aICompletionRecordsKeySpecifier)
    fields?: aICompletionRecordsFieldPolicy
  }
  frontendQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | frontendQueryKeySpecifier | (() => undefined | frontendQueryKeySpecifier)
    fields?: frontendQueryFieldPolicy
  }
  mutation_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | mutation_rootKeySpecifier | (() => undefined | mutation_rootKeySpecifier)
    fields?: mutation_rootFieldPolicy
  }
  reyieldMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | reyieldMutationKeySpecifier
      | (() => undefined | reyieldMutationKeySpecifier)
    fields?: reyieldMutationFieldPolicy
  }
  reyieldQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | reyieldQueryKeySpecifier | (() => undefined | reyieldQueryKeySpecifier)
    fields?: reyieldQueryFieldPolicy
  }
  strategyMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | strategyMutationKeySpecifier
      | (() => undefined | strategyMutationKeySpecifier)
    fields?: strategyMutationFieldPolicy
  }
  strategyQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | strategyQueryKeySpecifier | (() => undefined | strategyQueryKeySpecifier)
    fields?: strategyQueryFieldPolicy
  }
  suggestStrategyToolWithAllocations?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocationsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocationsKeySpecifier)
    fields?: suggestStrategyToolWithAllocationsFieldPolicy
  }
  suggestStrategyToolWithAllocations_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_aggregateKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_aggregateKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_aggregateFieldPolicy
  }
  suggestStrategyToolWithAllocations_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_aggregate_fieldsKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_aggregate_fieldsFieldPolicy
  }
  suggestStrategyToolWithAllocations_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_max_fieldsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_max_fieldsKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_max_fieldsFieldPolicy
  }
  suggestStrategyToolWithAllocations_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | suggestStrategyToolWithAllocations_min_fieldsKeySpecifier
      | (() => undefined | suggestStrategyToolWithAllocations_min_fieldsKeySpecifier)
    fields?: suggestStrategyToolWithAllocations_min_fieldsFieldPolicy
  }
}
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies
