import { gql, isReference, makeVar, Reference, StoreObject } from '@apollo/client'
import { FeeAmount } from '@uniswap/v3-sdk'
import uniqBy from 'lodash/uniqBy'
import { ReactNode } from 'react'
import { $enum } from 'ts-enum-util'

import { getFeeTierPercentage } from '@/apis/backendReyield/uniswap/utils/helper'
import { AllowanceState } from '@/components/shared/ApproveToken'
import {
  Exchange,
  PoolWithReyieldPoolValidationFragment,
  RiskToleranceLevelName,
  StrategyInfo,
  TypedTypePolicies,
  UniswapPoolTokenFragment,
  UserStrategyInfo,
} from '@/generated/graphql'
import { RebalanceDetailFormValuesProps } from '@/utils/strategy/formik'

import { GPT_SWITCH_TYPE } from './types'

export const typeDefs = gql`
  extend type DefiWallet {
    iconifySrc: String!
  }
  extend type UserInfo {
    isBitfinexAPIKeyBind: Boolean!
  }
`

// Type guard function to ensure that passing obj is a StoreObject
function isStoreObject(obj: any): obj is StoreObject {
  return obj && typeof obj === 'object' && '__typename' in obj
}

// Type guard function to ensure that passing obj is a References
function isReferences(obj: any): obj is Reference[] {
  if (!Array.isArray(obj)) {
    return false
  }

  return obj.every(isReference)
}

export const typePolicies: TypedTypePolicies = {
  UserInfo: {
    fields: {
      isBitfinexAPIKeyBind: {
        read: (_, { readField, cache }) => {
          // Access the object from externalAPIKeys
          const externalAPIKeys = readField({
            fieldName: 'externalAPIKeys',
            args: { filter: { isRevoked: false } },
          })

          if (isReferences(externalAPIKeys)) {
            const results = externalAPIKeys.filter((externalAPIKeyRef) => {
              if (
                readField('isRevoked', externalAPIKeyRef) === false &&
                readField('exchange', externalAPIKeyRef) === Exchange.Bitfinex
              ) {
                return true
              }
              return false
            })

            return results.length > 0
          }

          return false
        },
      },
      strategyRecords: {
        keyArgs: false,
        merge(existing, incoming) {
          return uniqBy([...(existing ?? []), ...(incoming ?? [])], (x) => x.__ref)
        },
      },
    },
  },
  DefiWallet: {
    fields: {
      iconifySrc: {
        read: (_, { readField }) => {
          // Access the currencyInfo object from DefiWallet
          const currencyInfo = readField('currencyInfo')

          // Ensure the currencyInfo is a StoreObject before accessing its fields
          let icon: string | undefined
          if (isStoreObject(currencyInfo)) {
            icon = readField('symbol', currencyInfo)
          }

          // If the icon is not available, return an empty string or a default icon URL
          if (!icon) {
            return ''
          }

          // Compute the iconifySrc value based on the other field
          const iconifySrc = `cryptocurrency-color:${icon.toLocaleLowerCase()}`

          return iconifySrc
        },
      },
    },
  },
}

export const stakingTokenDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const cashoutTokenDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const buyCryptoDialogVar = makeVar<{
  isDialogOpen: boolean
  currency?: string
  amount?: number
}>({
  isDialogOpen: false,
  currency: '',
  amount: 0,
})

export const supportingChainsDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const depositDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const depositViaBlockchainDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const depositCrossChainDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const depositByCreditCardDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const payAmountDialogVar = makeVar<{
  isDialogOpen: boolean
  currency?: string
  amount?: number
}>({
  isDialogOpen: false,
  currency: '',
  amount: 0,
})

export const remitCryptoDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const progressLoadingDialogVar = makeVar<{
  isDialogOpen: boolean
  title?: string
  subTitle?: string
  btnConfirm?: ReactNode
  btnCancel?: ReactNode
  closeIconClickEvent?: () => void
}>({
  isDialogOpen: false,
  title: '',
  subTitle: '',
  btnConfirm: null,
  btnCancel: null,
})

export const unStakingTokenConfirmDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})
export const viewPortfoliosDialogVar = makeVar<{
  isDialogOpen: boolean
  aICompletionRecordId: string | null
}>({
  isDialogOpen: false,
  aICompletionRecordId: null,
})

export const createBribeDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const createVestLockDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const manageVestPairDialogVar = makeVar<{
  isDialogOpen: boolean
  params?: {
    amount: string
    expireDate: string
  }
}>({
  isDialogOpen: false,
  params: {
    amount: '',
    expireDate: '',
  },
})

export const stakingTokenStatusVar = makeVar<{
  isStaking: boolean
}>({
  isStaking: false,
})

export const strategyEnableDialogVar = makeVar<{
  isDialogOpen: boolean
  readOnly: boolean
}>({
  isDialogOpen: false,
  readOnly: false,
})

export const strategyStartInfosVar = makeVar<{ strategyInfoData?: StrategyInfo }>({
  strategyInfoData: undefined,
})

export const strategyCloseDialogVar = makeVar<{
  isDialogOpen: boolean
  strategyInfoData?: UserStrategyInfo
}>({
  isDialogOpen: false,
})

export const strategyEditDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const strategyEditInfosVar = makeVar<{ strategyInfoData?: UserStrategyInfo }>({
  strategyInfoData: undefined,
})

export const strategyIncreaseFundsDialogVar = makeVar<{
  isDialogOpen: boolean
  increaseAmount?: number
  defiWalletAssets?: string
}>({
  isDialogOpen: false,
  increaseAmount: 0,
})

export const strategyInformationDialogVar = makeVar<{
  isDialogOpen: boolean
  isFinished?: boolean
  strategyInfoData?: UserStrategyInfo
}>({
  isDialogOpen: false,
  isFinished: false,
})

export const strategyEnableAmountDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const strategyEnableProcessingDialogVar = makeVar<{
  isDialogOpen: boolean
  strategyID?: string
}>({
  isDialogOpen: false,
})

export const strategyStartContractWriteStatusVar = makeVar<{
  isLoading: boolean
  isSuccess: boolean
  txHash: string
}>({
  isLoading: false,
  isSuccess: false,
  txHash: '',
})

export const apiKeyBindingTutorialDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const apiKeyCreatingTutorialDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const apiKeyRegisterTutorialDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const apiKeyBindingDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const switchNetworkDialogVar = makeVar<{
  isDialogOpen: boolean
  switchFromChain?: string
  switchToChain?: string
}>({
  isDialogOpen: false,
})

export const switchWalletAddressDialogInitState = {
  isDialogOpen: false,
  prevAddress: '',
  currentAddress: '',
}

export const switchWalletAddressDialogVar = makeVar<{
  isDialogOpen: boolean
  prevAddress: string
  currentAddress: string
}>(switchWalletAddressDialogInitState)

export const walletConnectDialogVar = makeVar<{
  isDialogOpen: boolean
  canCloseDialog?: boolean
  setPreviousAddress?: (address: string) => void
  redirect?: () => void
}>({
  isDialogOpen: false,
  canCloseDialog: false,
  setPreviousAddress: undefined,
})

export const welcomeReyieldDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export interface FeeTierItemType {
  name: string
  feeTier: string
  value: FeeAmount
  select: string
  enabled: boolean
  selected: boolean
  loading: boolean
}

export const initialFeeTierItems: FeeTierItemType[] = $enum(FeeAmount)
  .getEntries()
  .map(([name, value]) => ({
    name,
    feeTier: getFeeTierPercentage(value.toString()) * 100 + '%',
    value: value,
    select: '-',
    enabled: false,
    selected: false,
    loading: false,
  }))

export interface ICreateSettingValidations {
  isRebalanceDetailsValid: boolean
  token0AmountRequired: boolean
  token0DepositRequired: boolean
  token0AmountAllowanceState: AllowanceState
  token1AmountRequired: boolean
  token1DepositRequired: boolean
  token1AmountAllowanceState: AllowanceState
  singleTokenAmountRequired: boolean
  singleTokenDepositRequired: boolean
  singleTokenAmountAllowanceState: AllowanceState
  isDoubleToken: boolean
}

export const initialCreateSettingValidations: ICreateSettingValidations = {
  isRebalanceDetailsValid: false,
  token0AmountRequired: false,
  token0DepositRequired: false,
  token0AmountAllowanceState: AllowanceState.Initial,
  token1AmountRequired: false,
  token1DepositRequired: false,
  token1AmountAllowanceState: AllowanceState.Initial,
  singleTokenAmountRequired: false,
  singleTokenDepositRequired: false,
  singleTokenAmountAllowanceState: AllowanceState.Initial,
  isDoubleToken: true,
}

export const initialFeeTierSelected: {
  tickLowerDiffForContract: string
  tickUpperDiffForContract: string
} = {
  tickLowerDiffForContract: '',
  tickUpperDiffForContract: '',
}

export const createSettingDialogVar = makeVar<{
  // isDialogOpen: boolean
  token0: UniswapPoolTokenFragment | null
  token1: UniswapPoolTokenFragment | null
  feeTierItems: FeeTierItemType[]
  feeTierTick: {
    tickLower: number
    tickUpper: number
  } | null
  priceRange: [number, number]
  depositAmountUSDValue: number
  priceAssumptionValue: number
  selectedPool: PoolWithReyieldPoolValidationFragment | null
  rebalanceDetailValues: RebalanceDetailFormValuesProps | null
  enableAmount: {
    token0Amount: number
    token0USDValue: number
    token1Amount: number
    token1USDValue: number
    singleTokenAmount: number
    singleTokenUSDValue: number
    singleTokenSelected: string
  }
  feeTierSelected: {
    tickLowerDiffForContract: string
    tickUpperDiffForContract: string
  }
  validations: ICreateSettingValidations
  calculatorLiquidityAndAmountsData: [bigint, bigint, bigint] | undefined
  toolName: string

  currentPrice: number | null
  futurePrice: number | null
  daysInPosition: number
}>({
  // isDialogOpen: false,
  token0: null,
  token1: null,
  feeTierItems: initialFeeTierItems,
  feeTierTick: null,
  priceRange: [0, 0],
  depositAmountUSDValue: 0,
  priceAssumptionValue: 0,
  selectedPool: null,
  rebalanceDetailValues: null,
  enableAmount: {
    token0Amount: 0,
    token0USDValue: 0,
    token1Amount: 0,
    token1USDValue: 0,
    singleTokenAmount: 0,
    singleTokenUSDValue: 0,
    singleTokenSelected: '',
  },
  feeTierSelected: initialFeeTierSelected,
  validations: initialCreateSettingValidations,
  calculatorLiquidityAndAmountsData: undefined,
  toolName: '',

  // Impermanent Loss Calculator (TODO in the future)
  currentPrice: null,
  futurePrice: null,
  daysInPosition: 0,
})

export const reyieldGPTDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const reyieldGPTNoticeLoginDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const gptUsageCountInTodayVar = makeVar<{
  isLoading: boolean
  count: number
}>({
  isLoading: true,
  count: -1,
})

export const reyieldNFTUtilitiesDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const reyieldNFTAmountDialogVar = makeVar<{
  isDialogOpen: boolean
}>({
  isDialogOpen: false,
})

export const reyieldNFTFaqDialogVar = makeVar<{ isDialogOpen: boolean }>({
  isDialogOpen: false,
})

export const reyieldNFTBrunDialogVar = makeVar<{
  isDialogOpen: boolean
  imageUrl: string
  traitType: string
  tokenId: string
}>({
  isDialogOpen: false,
  imageUrl: '',
  traitType: '',
  tokenId: '',
})

export const gptSwitchTabVar = makeVar(GPT_SWITCH_TYPE.REYIELD_GPT)

export const isNFTMintStartedVar = makeVar(false)

export const gptConversationDefaultInfoVar = makeVar<{
  questionsIndex: number
  isGptWriting: boolean
  isUserWriting: boolean
  isConversationFinished: boolean
  isSubscribedDataReceived: boolean
  completionRecord: {
    expectedReturn: string
    goal: string
    investmentAmount: string
    investmentPeriod: string
    riskToleranceLevel: RiskToleranceLevelName
    riskToleranceStyle: string
    aICompletionRecordId: string
  }
}>({
  questionsIndex: 0,
  isGptWriting: true, // default for GPT to start writing
  isUserWriting: false,
  isConversationFinished: false,
  isSubscribedDataReceived: false,
  completionRecord: {
    goal: '',
    expectedReturn: '',
    investmentAmount: '',
    investmentPeriod: '',
    riskToleranceLevel: '' as RiskToleranceLevelName,
    riskToleranceStyle: '',
    aICompletionRecordId: '',
  },
})

export const gptConversationInfoVar = makeVar<{
  questionsIndex: number
  isGptWriting: boolean
  isUserWriting: boolean
  isConversationFinished: boolean
  isSubscribedDataReceived: boolean
  completionRecord: {
    expectedReturn: string
    goal: string
    investmentAmount: string
    investmentPeriod: string
    riskToleranceLevel: RiskToleranceLevelName
    riskToleranceStyle: string
    aICompletionRecordId: string
  }
}>({
  questionsIndex: 0,
  isGptWriting: true, // default for GPT to start writing
  isUserWriting: false,
  isConversationFinished: false,
  isSubscribedDataReceived: false,
  completionRecord: {
    goal: '',
    expectedReturn: '',
    investmentAmount: '',
    investmentPeriod: '',
    riskToleranceLevel: '' as RiskToleranceLevelName,
    riskToleranceStyle: '',
    aICompletionRecordId: '',
  },
})

// GPT conversation is needed to be scrolled into view
export const isScrollToViewRequiredVar = makeVar<boolean>(true)
