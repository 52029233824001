import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  Address,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import { ReadContractResult, WriteContractMode, PrepareWriteContractResult } from 'wagmi/actions'

import {
  getContract,
  GetContractArgs,
  readContract,
  ReadContractConfig,
  writeContract,
  WriteContractArgs,
  WriteContractPreparedArgs,
  WriteContractUnpreparedArgs,
  prepareWriteContract,
  PrepareWriteContractConfig,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DepositRecipesOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export const depositRecipesOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: false },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'strategyId', internalType: 'bytes16', type: 'bytes16', indexed: false },
    ],
    name: 'PositionDeposited',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: false },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'strategyId', internalType: 'bytes16', type: 'bytes16', indexed: false },
      { name: 'strategyProvider', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'PositionDepositedListedStrategy',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.DepositInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'amount0Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
        ],
      },
    ],
    name: 'deposit',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.DepositListedStrategyInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'amount0Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
        ],
      },
    ],
    name: 'depositListedStrategy',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.SingleTokenDepositInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'isToken0In', internalType: 'bool', type: 'bool' },
          { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
        ],
      },
    ],
    name: 'singleTokenDeposit',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.SingleTokenDepositListedStrategyInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'isToken0In', internalType: 'bool', type: 'bool' },
          { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
        ],
      },
    ],
    name: 'singleTokenDepositListedStrategy',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export const depositRecipesOptimismAddress = {
  10: '0xc5d57eDB3e79854DDdc59388A784B716506BDA52',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export const depositRecipesOptimismConfig = {
  address: depositRecipesOptimismAddress,
  abi: depositRecipesOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DepositRecipesPolygon
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export const depositRecipesPolygonABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: false },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'strategyId', internalType: 'bytes16', type: 'bytes16', indexed: false },
    ],
    name: 'PositionDeposited',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: false },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'strategyId', internalType: 'bytes16', type: 'bytes16', indexed: false },
      { name: 'strategyProvider', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'PositionDepositedListedStrategy',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.DepositInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'amount0Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
        ],
      },
    ],
    name: 'deposit',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.DepositListedStrategyInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'amount0Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Desired', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
        ],
      },
    ],
    name: 'depositListedStrategy',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.SingleTokenDepositInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'isToken0In', internalType: 'bool', type: 'bool' },
          { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
        ],
      },
    ],
    name: 'singleTokenDeposit',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IDepositRecipes.SingleTokenDepositListedStrategyInput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'isToken0In', internalType: 'bool', type: 'bool' },
          { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
        ],
      },
    ],
    name: 'singleTokenDepositListedStrategy',
    outputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
] as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export const depositRecipesPolygonAddress = {
  137: '0xc5d57eDB3e79854DDdc59388A784B716506BDA52',
} as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export const depositRecipesPolygonConfig = {
  address: depositRecipesPolygonAddress,
  abi: depositRecipesPolygonABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    type: 'event',
    inputs: [
      { name: 'owner', type: 'address', indexed: true },
      { name: 'spender', type: 'address', indexed: true },
      { name: 'value', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    inputs: [
      { name: 'from', type: 'address', indexed: true },
      { name: 'to', type: 'address', indexed: true },
      { name: 'value', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'sender', type: 'address' },
      { name: 'recipient', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PositionHelperOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export const positionHelperOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'estimateWithdrawPosition',
    outputs: [
      {
        name: 'output',
        internalType: 'struct PositionHelper.EstimateWithdrawPositionOutput',
        type: 'tuple',
        components: [
          { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedToken1Value', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedToken0Value', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0PerformanceFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1PerformanceFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0PerformanceFeeUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1PerformanceFeeUsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getAmounts',
    outputs: [
      {
        name: 'output',
        internalType: 'struct PositionHelper.GetAmountsOutput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'amount0', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0UsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1UsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionInfo',
    outputs: [
      {
        name: 'pInfo',
        internalType: 'struct IPositionManager.PositionInfo',
        type: 'tuple',
        components: [
          { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionSettlement',
    outputs: [
      {
        name: 'settlement',
        internalType: 'struct IPositionManager.PositionSettlement',
        type: 'tuple',
        components: [
          { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionTokenInfo',
    outputs: [
      {
        name: 'ptInfo',
        internalType: 'struct PositionHelper.PositionTokenInfo',
        type: 'tuple',
        components: [
          { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'tickLower', internalType: 'int24', type: 'int24' },
          { name: 'tickUpper', internalType: 'int24', type: 'int24' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getTickInfo',
    outputs: [
      { name: 'currentTick', internalType: 'int24', type: 'int24' },
      { name: 'tickLower', internalType: 'int24', type: 'int24' },
      { name: 'tickUpper', internalType: 'int24', type: 'int24' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getUncollectedFees',
    outputs: [
      {
        name: 'output',
        internalType: 'struct PositionHelper.GetUncollectedFeesOutput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'amount0', internalType: 'uint128', type: 'uint128' },
          { name: 'amount1', internalType: 'uint128', type: 'uint128' },
          { name: 'amount0UsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1UsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'tokenIn', internalType: 'address', type: 'address' },
      { name: 'tokenOut', internalType: 'address', type: 'address' },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'quote',
    outputs: [{ name: 'amountOut', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'quoteUsdValue',
    outputs: [{ name: 'usdValue', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export const positionHelperOptimismAddress = {
  10: '0x356cE468C2Be9ed1732699FBAea94DAb906749a0',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export const positionHelperOptimismConfig = {
  address: positionHelperOptimismAddress,
  abi: positionHelperOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PositionHelperPolygon
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export const positionHelperPolygonABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'estimateWithdrawPosition',
    outputs: [
      {
        name: 'output',
        internalType: 'struct PositionHelper.EstimateWithdrawPositionOutput',
        type: 'tuple',
        components: [
          { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedToken1Value', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedToken0Value', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0PerformanceFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1PerformanceFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0PerformanceFeeUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1PerformanceFeeUsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getAmounts',
    outputs: [
      {
        name: 'output',
        internalType: 'struct PositionHelper.GetAmountsOutput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'amount0', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0UsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1UsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionInfo',
    outputs: [
      {
        name: 'pInfo',
        internalType: 'struct IPositionManager.PositionInfo',
        type: 'tuple',
        components: [
          { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionSettlement',
    outputs: [
      {
        name: 'settlement',
        internalType: 'struct IPositionManager.PositionSettlement',
        type: 'tuple',
        components: [
          { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionTokenInfo',
    outputs: [
      {
        name: 'ptInfo',
        internalType: 'struct PositionHelper.PositionTokenInfo',
        type: 'tuple',
        components: [
          { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'fee', internalType: 'uint24', type: 'uint24' },
          { name: 'tickLower', internalType: 'int24', type: 'int24' },
          { name: 'tickUpper', internalType: 'int24', type: 'int24' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getTickInfo',
    outputs: [
      { name: 'currentTick', internalType: 'int24', type: 'int24' },
      { name: 'tickLower', internalType: 'int24', type: 'int24' },
      { name: 'tickUpper', internalType: 'int24', type: 'int24' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'userAddress', internalType: 'address', type: 'address' },
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getUncollectedFees',
    outputs: [
      {
        name: 'output',
        internalType: 'struct PositionHelper.GetUncollectedFeesOutput',
        type: 'tuple',
        components: [
          { name: 'token0', internalType: 'address', type: 'address' },
          { name: 'token1', internalType: 'address', type: 'address' },
          { name: 'amount0', internalType: 'uint128', type: 'uint128' },
          { name: 'amount1', internalType: 'uint128', type: 'uint128' },
          { name: 'amount0UsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1UsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'tokenIn', internalType: 'address', type: 'address' },
      { name: 'tokenOut', internalType: 'address', type: 'address' },
      { name: 'amountIn', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'quote',
    outputs: [{ name: 'amountOut', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'quoteUsdValue',
    outputs: [{ name: 'usdValue', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
] as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export const positionHelperPolygonAddress = {
  137: '0x356cE468C2Be9ed1732699FBAea94DAb906749a0',
} as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export const positionHelperPolygonConfig = {
  address: positionHelperPolygonAddress,
  abi: positionHelperPolygonABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PositionManager
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const positionManagerABI = [
  {
    stateMutability: 'payable',
    type: 'constructor',
    inputs: [
      { name: '_owner', internalType: 'address', type: 'address' },
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
      { name: '_diamondCutFacet', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address', indexed: false },
      { name: 'to', internalType: 'address', type: 'address', indexed: false },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'ERC20Withdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'PositionClosed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'strategyProvider', internalType: 'address', type: 'address', indexed: false },
      { name: 'strategyId', internalType: 'bytes16', type: 'bytes16', indexed: false },
      { name: 'token0Deposited', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'token1Deposited', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'tickLowerDiff', internalType: 'int24', type: 'int24', indexed: false },
      { name: 'tickUpperDiff', internalType: 'int24', type: 'int24', indexed: false },
    ],
    name: 'PositionCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: true },
      {
        name: 'token0DepositedAfterIncreased',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'token1DepositedAfterIncreased',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'amount0LeftoverAfterIncreased',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'amount1LeftoverAfterIncreased',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'PositionIncreasedLiquidity',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: true },
      { name: 'newTokenId', internalType: 'uint256', type: 'uint256', indexed: false },
      {
        name: 'tickLowerDiffAfterRebalanced',
        internalType: 'int24',
        type: 'int24',
        indexed: false,
      },
      {
        name: 'tickUpperDiffAfterRebalanced',
        internalType: 'int24',
        type: 'int24',
        indexed: false,
      },
      {
        name: 'amount0CollectedFeeAfterRebalanced',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'amount1CollectedFeeAfterRebalanced',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'amount0LeftoverAfterRebalanced',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'amount1LeftoverAfterRebalanced',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'PositionRebalanced',
  },
  { stateMutability: 'payable', type: 'fallback' },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'inputs',
        internalType: 'struct IPositionManager.CreatePositionInput',
        type: 'tuple',
        components: [
          { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
          { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    name: 'createPosition',
    outputs: [{ name: 'positionId', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getOwner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getPositionIdFromTokenId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'positionId', internalType: 'uint256', type: 'uint256' }],
    name: 'getPositionInfo',
    outputs: [
      {
        name: 'positionInfo',
        internalType: 'struct IPositionManager.PositionInfo',
        type: 'tuple',
        components: [
          { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
          { name: 'strategyProvider', internalType: 'address', type: 'address' },
          { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
          { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
          { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
          { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_positionId', internalType: 'uint256', type: 'uint256' },
      { name: '_moduleAddress', internalType: 'address', type: 'address' },
    ],
    name: 'getPositionModuleData',
    outputs: [{ name: 'data', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'positionId', internalType: 'uint256', type: 'uint256' }],
    name: 'getPositionSettlement',
    outputs: [
      {
        name: 'positionSettlementInfo',
        internalType: 'struct IPositionManager.PositionSettlement',
        type: 'tuple',
        components: [
          { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'howMany', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getUniswapNFTs',
    outputs: [
      { name: 'nfts', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'newCursor', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getUniswapNFTsLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'positionId', internalType: 'uint256', type: 'uint256' }],
    name: 'isPositionRunning',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'middlewareIncreaseLiquidity',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
      { name: 'newTokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
      { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
      { name: 'amount0CollectedFee', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1CollectedFee', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'middlewareRebalance',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'input',
        internalType: 'struct IPositionManager.MiddlewareWithdrawInput',
        type: 'tuple',
        components: [
          { name: 'positionId', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1CollectedFee', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
          { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
          { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    name: 'middlewareWithdraw',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'positionIdCounter',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'positionSettlement',
    outputs: [
      { name: 'amount0Returned', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Returned', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1ReturnedUsdValue', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'positionStatus',
    outputs: [{ name: '', internalType: 'enum IPositionManager.PositionStatus', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    name: 'positionToModuleData',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'positions',
    outputs: [
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'strategyProvider', internalType: 'address', type: 'address' },
      { name: 'strategyId', internalType: 'bytes16', type: 'bytes16' },
      { name: 'amount0Deposited', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Deposited', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1DepositedUsdValue', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0CollectedFee', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1CollectedFee', internalType: 'uint256', type: 'uint256' },
      { name: 'amount0Leftover', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Leftover', internalType: 'uint256', type: 'uint256' },
      { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
      { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
      { name: 'moduleAddress', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'setModuleData',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawERC20ToOwner',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PositionManagerFactoryOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export const positionManagerFactoryOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
      { name: '_diamondCutFacet', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'user', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'PositionManagerCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'actions',
    outputs: [
      { name: 'facetAddress', internalType: 'address', type: 'address' },
      { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'create',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'diamondCutFacet',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'howMany', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionManagers',
    outputs: [
      { name: 'managers', internalType: 'address[]', type: 'address[]' },
      { name: 'newCursor', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getPositionManagersLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'positionManagers',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'facetAction',
        internalType: 'struct IDiamondCut.FacetCut',
        type: 'tuple',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
    name: 'updateActionData',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address' },
      {
        name: 'actionsToUpdate',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
    name: 'updateDiamond',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'userToPositionManager',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export const positionManagerFactoryOptimismAddress = {
  10: '0x9ff67463BDb860e51b8992ae35dee1896acDDd5b',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export const positionManagerFactoryOptimismConfig = {
  address: positionManagerFactoryOptimismAddress,
  abi: positionManagerFactoryOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PositionManagerFactoryPolygon
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export const positionManagerFactoryPolygonABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
      { name: '_diamondCutFacet', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'user', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'PositionManagerCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'actions',
    outputs: [
      { name: 'facetAddress', internalType: 'address', type: 'address' },
      { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'create',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'diamondCutFacet',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'howMany', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPositionManagers',
    outputs: [
      { name: 'managers', internalType: 'address[]', type: 'address[]' },
      { name: 'newCursor', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getPositionManagersLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'positionManagers',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'facetAction',
        internalType: 'struct IDiamondCut.FacetCut',
        type: 'tuple',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
    name: 'updateActionData',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address' },
      {
        name: 'actionsToUpdate',
        internalType: 'struct IDiamondCut.FacetCut[]',
        type: 'tuple[]',
        components: [
          { name: 'facetAddress', internalType: 'address', type: 'address' },
          { name: 'action', internalType: 'enum IDiamondCut.FacetCutAction', type: 'uint8' },
          { name: 'functionSelectors', internalType: 'bytes4[]', type: 'bytes4[]' },
        ],
      },
    ],
    name: 'updateDiamond',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'userToPositionManager',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export const positionManagerFactoryPolygonAddress = {
  137: '0x9ff67463BDb860e51b8992ae35dee1896acDDd5b',
} as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export const positionManagerFactoryPolygonConfig = {
  address: positionManagerFactoryPolygonAddress,
  abi: positionManagerFactoryPolygonABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// REYLDTokenOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export const reyldTokenOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: 'initial', internalType: 'address', type: 'address' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_executor', internalType: 'address', type: 'address' }],
    name: 'addExecutor',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'executors',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_executor', internalType: 'address', type: 'address' }],
    name: 'removeExecutor',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export const reyldTokenOptimismAddress = {
  10: '0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export const reyldTokenOptimismConfig = {
  address: reyldTokenOptimismAddress,
  abi: reyldTokenOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ReyieldNFTOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export const reyieldNftOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_initBaseURI', internalType: 'string', type: 'string' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'operator', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: '_fromTokenId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: '_toTokenId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'BatchMetadataUpdate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: '_tokenId', internalType: 'uint256', type: 'uint256', indexed: false }],
    name: 'MetadataUpdate',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_addresses', internalType: 'address[]', type: 'address[]' }],
    name: 'airdrop',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseExtension',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'baseURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'cost',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'isMintedWhitelist',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxMintAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'merkleRoot',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  { stateMutability: 'payable', type: 'function', inputs: [], name: 'mint', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_mintAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'mintOwner',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: '_merkleProof', internalType: 'bytes32[]', type: 'bytes32[]' }],
    name: 'mintWhitelist',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'nftPerAddressLimit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'nowSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'onlyWhitelisted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'presaleAddressMintedBalance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'saleIsActive',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newBaseExtension', internalType: 'string', type: 'string' }],
    name: 'setBaseExtension',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newBaseURI', internalType: 'string', type: 'string' }],
    name: 'setBaseURI',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newCost', internalType: 'uint256', type: 'uint256' }],
    name: 'setCost',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_merkleRoot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'setMerkleRoot',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_limit', internalType: 'uint256', type: 'uint256' }],
    name: 'setNftPerAddressLimit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_state', internalType: 'bool', type: 'bool' }],
    name: 'setOnlyWhitelisted',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_state', internalType: 'bool', type: 'bool' }],
    name: 'setSaleState',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_startTimestamp', internalType: 'uint256', type: 'uint256' }],
    name: 'setStartTimestamp',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newmaxMintAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'setmaxMintAmount',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'startTimestamp',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_owner', internalType: 'address', type: 'address' }],
    name: 'walletOfOwner',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  { stateMutability: 'payable', type: 'function', inputs: [], name: 'withdraw', outputs: [] },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export const reyieldNftOptimismAddress = {
  10: '0x711059DeCDA94200CECdC128Ad38e498225AdC6f',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export const reyieldNftOptimismConfig = {
  address: reyieldNftOptimismAddress,
  abi: reyieldNftOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ReyieldPermissionOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export const reyieldPermissionOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_governance', internalType: 'address', type: 'address' },
      { name: '_officialAccount', internalType: 'address', type: 'address' },
      { name: '_governanceToken', internalType: 'address', type: 'address' },
      { name: '_reyieldNFT', internalType: 'address', type: 'address' },
      { name: '_daysTimeLimitedPrivilege', internalType: 'uint256', type: 'uint256' },
      { name: '_licenseAmountForPermanentPrivilegeNFT', internalType: 'uint32', type: 'uint32' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'burnedAmount', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'licenseAmount', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'GovernanceTokenBurned',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'stakedAmount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'GovernanceTokenStaked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'unstakedAmount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'GovernanceTokenUnstaked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'licenseAmount', internalType: 'uint32', type: 'uint32', indexed: false },
    ],
    name: 'PermanentPrivilegeNFTBurned',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'expiredAt', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'TimeLimitedPrivilegeNFTBurned',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'licenseAmount', internalType: 'uint32', type: 'uint32' }],
    name: 'burnERC20ForLicense',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'burnERC721',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_governance', internalType: 'address', type: 'address' }],
    name: 'changeGovernance',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_newOfficialAccount', internalType: 'address', type: 'address' }],
    name: 'changeOfficialAccount',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'daysTimeLimitedPrivilege',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'user', internalType: 'address', type: 'address' }],
    name: 'getPermissionInfo',
    outputs: [
      {
        name: '',
        internalType: 'struct IReyieldPermission.PermissionInfo',
        type: 'tuple',
        components: [
          { name: 'isPermanentPrivilege', internalType: 'bool', type: 'bool' },
          { name: 'isStakedPrivilege', internalType: 'bool', type: 'bool' },
          { name: 'licenseAmount', internalType: 'uint32', type: 'uint32' },
          { name: 'privilegeExpiredAt', internalType: 'uint256', type: 'uint256' },
          { name: 'stakedAmount', internalType: 'uint256', type: 'uint256' },
          { name: 'lastStakedAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governance',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'governanceToken',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'licenseAmountForPermanentPrivilegeNFT',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
    name: 'licenseNoToBurnedTokenAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'officialAccount',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'user', internalType: 'address', type: 'address' }],
    name: 'privilege',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'privilegeStakeAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'reyieldNFT',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'stakeERC20ForPrivilege',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenIdToIsPermanent',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'user', internalType: 'address', type: 'address' },
      { name: 'licenseAmount', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'tryBurnERC20ForLicense',
    outputs: [
      { name: 'origLicenseAmount', internalType: 'uint32', type: 'uint32' },
      { name: 'currentlicenseAmount', internalType: 'uint32', type: 'uint32' },
      { name: 'burnedTokenAmount', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'user', internalType: 'address', type: 'address' }],
    name: 'tryBurnERC721ForPermanentPrivilegeAndLicenses',
    outputs: [
      { name: 'origLicenseAmount', internalType: 'uint32', type: 'uint32' },
      { name: 'currentlicenseAmount', internalType: 'uint32', type: 'uint32' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'user', internalType: 'address', type: 'address' }],
    name: 'tryBurnERC721ForTimeLimitedPrivilege',
    outputs: [
      { name: 'expiredAt', internalType: 'uint256', type: 'uint256' },
      { name: 'isPermanentPrivilege', internalType: 'bool', type: 'bool' },
      { name: 'isStakedPrivilege', internalType: 'bool', type: 'bool' },
    ],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'unstakeERC20',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'tokenIds', internalType: 'uint256[]', type: 'uint256[]' }],
    name: 'updatePermanentNFTWhitelist',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'userToPermissionInfo',
    outputs: [
      { name: 'isPermanentPrivilege', internalType: 'bool', type: 'bool' },
      { name: 'isStakedPrivilege', internalType: 'bool', type: 'bool' },
      { name: 'licenseAmount', internalType: 'uint32', type: 'uint32' },
      { name: 'privilegeExpiredAt', internalType: 'uint256', type: 'uint256' },
      { name: 'stakedAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'lastStakedAt', internalType: 'uint256', type: 'uint256' },
    ],
  },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export const reyieldPermissionOptimismAddress = {
  10: '0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export const reyieldPermissionOptimismConfig = {
  address: reyieldPermissionOptimismAddress,
  abi: reyieldPermissionOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// StrategyProviderWalletFactoryOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export const strategyProviderWalletFactoryOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'creator', internalType: 'address', type: 'address', indexed: true }],
    name: 'CreatorWhitelistAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'strategyProviderWallet', internalType: 'address', type: 'address', indexed: true },
      { name: 'creator', internalType: 'address', type: 'address', indexed: false },
      { name: 'provider', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'StrategyProviderWalletCreated',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_creator', internalType: 'address', type: 'address' }],
    name: 'addCreatorWhitelist',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'provider', internalType: 'address', type: 'address' }],
    name: 'create',
    outputs: [{ name: 'walletAddress', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'creatorWhitelist',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'howMany', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getStrategyProviderWallets',
    outputs: [
      { name: 'wallets', internalType: 'address[]', type: 'address[]' },
      { name: 'newCursor', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getStrategyProviderWalletsLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'isIncreatorWhitelist',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'providerToWallet',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'strategyProviderWallets',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export const strategyProviderWalletFactoryOptimismAddress = {
  10: '0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export const strategyProviderWalletFactoryOptimismConfig = {
  address: strategyProviderWalletFactoryOptimismAddress,
  abi: strategyProviderWalletFactoryOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// StrategyProviderWalletFactoryPolygon
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export const strategyProviderWalletFactoryPolygonABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'creator', internalType: 'address', type: 'address', indexed: true }],
    name: 'CreatorWhitelistAdded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'strategyProviderWallet', internalType: 'address', type: 'address', indexed: true },
      { name: 'creator', internalType: 'address', type: 'address', indexed: false },
      { name: 'provider', internalType: 'address', type: 'address', indexed: false },
    ],
    name: 'StrategyProviderWalletCreated',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_creator', internalType: 'address', type: 'address' }],
    name: 'addCreatorWhitelist',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'provider', internalType: 'address', type: 'address' }],
    name: 'create',
    outputs: [{ name: 'walletAddress', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'creatorWhitelist',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'cursor', internalType: 'uint256', type: 'uint256' },
      { name: 'howMany', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getStrategyProviderWallets',
    outputs: [
      { name: 'wallets', internalType: 'address[]', type: 'address[]' },
      { name: 'newCursor', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getStrategyProviderWalletsLength',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'isIncreatorWhitelist',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'providerToWallet',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'strategyProviderWallets',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export const strategyProviderWalletFactoryPolygonAddress = {
  137: '0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A',
} as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export const strategyProviderWalletFactoryPolygonConfig = {
  address: strategyProviderWalletFactoryPolygonAddress,
  abi: strategyProviderWalletFactoryPolygonABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UniswapCalculatorOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export const uniswapCalculatorOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
      { name: 'fee', internalType: 'uint24', type: 'uint24' },
      { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
      { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
      { name: 'amount0Desired', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Desired', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getLiquidityAndAmounts',
    outputs: [
      { name: 'liquidity', internalType: 'uint128', type: 'uint128' },
      { name: 'amount0', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
      { name: 'fee', internalType: 'uint24', type: 'uint24' },
    ],
    name: 'getPool',
    outputs: [{ name: 'pool', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
    ],
    name: 'reorderTokens',
    outputs: [
      { name: 'token0Reordered', internalType: 'address', type: 'address' },
      { name: 'token1Reordered', internalType: 'address', type: 'address' },
      { name: 'isOrderChanged', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
      { name: 'fee', internalType: 'uint24', type: 'uint24' },
    ],
    name: 'validatePool',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export const uniswapCalculatorOptimismAddress = {
  10: '0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export const uniswapCalculatorOptimismConfig = {
  address: uniswapCalculatorOptimismAddress,
  abi: uniswapCalculatorOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UniswapCalculatorPolygon
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export const uniswapCalculatorPolygonABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
      { name: 'fee', internalType: 'uint24', type: 'uint24' },
      { name: 'tickLowerDiff', internalType: 'int24', type: 'int24' },
      { name: 'tickUpperDiff', internalType: 'int24', type: 'int24' },
      { name: 'amount0Desired', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1Desired', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getLiquidityAndAmounts',
    outputs: [
      { name: 'liquidity', internalType: 'uint128', type: 'uint128' },
      { name: 'amount0', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
      { name: 'fee', internalType: 'uint24', type: 'uint24' },
    ],
    name: 'getPool',
    outputs: [{ name: 'pool', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
    ],
    name: 'reorderTokens',
    outputs: [
      { name: 'token0Reordered', internalType: 'address', type: 'address' },
      { name: 'token1Reordered', internalType: 'address', type: 'address' },
      { name: 'isOrderChanged', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'token0', internalType: 'address', type: 'address' },
      { name: 'token1', internalType: 'address', type: 'address' },
      { name: 'fee', internalType: 'uint24', type: 'uint24' },
    ],
    name: 'validatePool',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export const uniswapCalculatorPolygonAddress = {
  137: '0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0',
} as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export const uniswapCalculatorPolygonConfig = {
  address: uniswapCalculatorPolygonAddress,
  abi: uniswapCalculatorPolygonABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UniswapV3Pool
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const uniswapV3PoolABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'tickLower', internalType: 'int24', type: 'int24', indexed: true },
      { name: 'tickUpper', internalType: 'int24', type: 'int24', indexed: true },
      { name: 'amount', internalType: 'uint128', type: 'uint128', indexed: false },
      { name: 'amount0', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Burn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'recipient', internalType: 'address', type: 'address', indexed: false },
      { name: 'tickLower', internalType: 'int24', type: 'int24', indexed: true },
      { name: 'tickUpper', internalType: 'int24', type: 'int24', indexed: true },
      { name: 'amount0', internalType: 'uint128', type: 'uint128', indexed: false },
      { name: 'amount1', internalType: 'uint128', type: 'uint128', indexed: false },
    ],
    name: 'Collect',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'recipient', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount0', internalType: 'uint128', type: 'uint128', indexed: false },
      { name: 'amount1', internalType: 'uint128', type: 'uint128', indexed: false },
    ],
    name: 'CollectProtocol',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'recipient', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount0', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'paid0', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'paid1', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Flash',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'observationCardinalityNextOld',
        internalType: 'uint16',
        type: 'uint16',
        indexed: false,
      },
      {
        name: 'observationCardinalityNextNew',
        internalType: 'uint16',
        type: 'uint16',
        indexed: false,
      },
    ],
    name: 'IncreaseObservationCardinalityNext',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sqrtPriceX96', internalType: 'uint160', type: 'uint160', indexed: false },
      { name: 'tick', internalType: 'int24', type: 'int24', indexed: false },
    ],
    name: 'Initialize',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: false },
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'tickLower', internalType: 'int24', type: 'int24', indexed: true },
      { name: 'tickUpper', internalType: 'int24', type: 'int24', indexed: true },
      { name: 'amount', internalType: 'uint128', type: 'uint128', indexed: false },
      { name: 'amount0', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amount1', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Mint',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'feeProtocol0Old', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'feeProtocol1Old', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'feeProtocol0New', internalType: 'uint8', type: 'uint8', indexed: false },
      { name: 'feeProtocol1New', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'SetFeeProtocol',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
      { name: 'recipient', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount0', internalType: 'int256', type: 'int256', indexed: false },
      { name: 'amount1', internalType: 'int256', type: 'int256', indexed: false },
      { name: 'sqrtPriceX96', internalType: 'uint160', type: 'uint160', indexed: false },
      { name: 'liquidity', internalType: 'uint128', type: 'uint128', indexed: false },
      { name: 'tick', internalType: 'int24', type: 'int24', indexed: false },
    ],
    name: 'Swap',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tickLower', internalType: 'int24', type: 'int24' },
      { name: 'tickUpper', internalType: 'int24', type: 'int24' },
      { name: 'amount', internalType: 'uint128', type: 'uint128' },
    ],
    name: 'burn',
    outputs: [
      { name: 'amount0', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'tickLower', internalType: 'int24', type: 'int24' },
      { name: 'tickUpper', internalType: 'int24', type: 'int24' },
      { name: 'amount0Requested', internalType: 'uint128', type: 'uint128' },
      { name: 'amount1Requested', internalType: 'uint128', type: 'uint128' },
    ],
    name: 'collect',
    outputs: [
      { name: 'amount0', internalType: 'uint128', type: 'uint128' },
      { name: 'amount1', internalType: 'uint128', type: 'uint128' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount0Requested', internalType: 'uint128', type: 'uint128' },
      { name: 'amount1Requested', internalType: 'uint128', type: 'uint128' },
    ],
    name: 'collectProtocol',
    outputs: [
      { name: 'amount0', internalType: 'uint128', type: 'uint128' },
      { name: 'amount1', internalType: 'uint128', type: 'uint128' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'factory',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'fee',
    outputs: [{ name: '', internalType: 'uint24', type: 'uint24' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeGrowthGlobal0X128',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'feeGrowthGlobal1X128',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'amount0', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'flash',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'observationCardinalityNext', internalType: 'uint16', type: 'uint16' }],
    name: 'increaseObservationCardinalityNext',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'sqrtPriceX96', internalType: 'uint160', type: 'uint160' }],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'liquidity',
    outputs: [{ name: '', internalType: 'uint128', type: 'uint128' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxLiquidityPerTick',
    outputs: [{ name: '', internalType: 'uint128', type: 'uint128' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'tickLower', internalType: 'int24', type: 'int24' },
      { name: 'tickUpper', internalType: 'int24', type: 'int24' },
      { name: 'amount', internalType: 'uint128', type: 'uint128' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'mint',
    outputs: [
      { name: 'amount0', internalType: 'uint256', type: 'uint256' },
      { name: 'amount1', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'observations',
    outputs: [
      { name: 'blockTimestamp', internalType: 'uint32', type: 'uint32' },
      { name: 'tickCumulative', internalType: 'int56', type: 'int56' },
      { name: 'secondsPerLiquidityCumulativeX128', internalType: 'uint160', type: 'uint160' },
      { name: 'initialized', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'secondsAgos', internalType: 'uint32[]', type: 'uint32[]' }],
    name: 'observe',
    outputs: [
      { name: 'tickCumulatives', internalType: 'int56[]', type: 'int56[]' },
      { name: 'secondsPerLiquidityCumulativeX128s', internalType: 'uint160[]', type: 'uint160[]' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'key', internalType: 'bytes32', type: 'bytes32' }],
    name: 'positions',
    outputs: [
      { name: '_liquidity', internalType: 'uint128', type: 'uint128' },
      { name: 'feeGrowthInside0LastX128', internalType: 'uint256', type: 'uint256' },
      { name: 'feeGrowthInside1LastX128', internalType: 'uint256', type: 'uint256' },
      { name: 'tokensOwed0', internalType: 'uint128', type: 'uint128' },
      { name: 'tokensOwed1', internalType: 'uint128', type: 'uint128' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'protocolFees',
    outputs: [
      { name: 'token0', internalType: 'uint128', type: 'uint128' },
      { name: 'token1', internalType: 'uint128', type: 'uint128' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'feeProtocol0', internalType: 'uint8', type: 'uint8' },
      { name: 'feeProtocol1', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'setFeeProtocol',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'slot0',
    outputs: [
      { name: 'sqrtPriceX96', internalType: 'uint160', type: 'uint160' },
      { name: 'tick', internalType: 'int24', type: 'int24' },
      { name: 'observationIndex', internalType: 'uint16', type: 'uint16' },
      { name: 'observationCardinality', internalType: 'uint16', type: 'uint16' },
      { name: 'observationCardinalityNext', internalType: 'uint16', type: 'uint16' },
      { name: 'feeProtocol', internalType: 'uint8', type: 'uint8' },
      { name: 'unlocked', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'tickLower', internalType: 'int24', type: 'int24' },
      { name: 'tickUpper', internalType: 'int24', type: 'int24' },
    ],
    name: 'snapshotCumulativesInside',
    outputs: [
      { name: 'tickCumulativeInside', internalType: 'int56', type: 'int56' },
      { name: 'secondsPerLiquidityInsideX128', internalType: 'uint160', type: 'uint160' },
      { name: 'secondsInside', internalType: 'uint32', type: 'uint32' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      { name: 'zeroForOne', internalType: 'bool', type: 'bool' },
      { name: 'amountSpecified', internalType: 'int256', type: 'int256' },
      { name: 'sqrtPriceLimitX96', internalType: 'uint160', type: 'uint160' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'swap',
    outputs: [
      { name: 'amount0', internalType: 'int256', type: 'int256' },
      { name: 'amount1', internalType: 'int256', type: 'int256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'wordPosition', internalType: 'int16', type: 'int16' }],
    name: 'tickBitmap',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'tickSpacing',
    outputs: [{ name: '', internalType: 'int24', type: 'int24' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tick', internalType: 'int24', type: 'int24' }],
    name: 'ticks',
    outputs: [
      { name: 'liquidityGross', internalType: 'uint128', type: 'uint128' },
      { name: 'liquidityNet', internalType: 'int128', type: 'int128' },
      { name: 'feeGrowthOutside0X128', internalType: 'uint256', type: 'uint256' },
      { name: 'feeGrowthOutside1X128', internalType: 'uint256', type: 'uint256' },
      { name: 'tickCumulativeOutside', internalType: 'int56', type: 'int56' },
      { name: 'secondsPerLiquidityOutsideX128', internalType: 'uint160', type: 'uint160' },
      { name: 'secondsOutside', internalType: 'uint32', type: 'uint32' },
      { name: 'initialized', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token0',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'token1',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WithdrawRecipesOptimism
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export const withdrawRecipesOptimismABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: false },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'PositionWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
      { name: 'isReturnedToken0', internalType: 'bool', type: 'bool' },
    ],
    name: 'singleTokenWithdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'positionId', internalType: 'uint256', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
  },
] as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export const withdrawRecipesOptimismAddress = {
  10: '0xB13677B2f4541224160Bf230606d3b4941BBbD53',
} as const

/**
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export const withdrawRecipesOptimismConfig = {
  address: withdrawRecipesOptimismAddress,
  abi: withdrawRecipesOptimismABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WithdrawRecipesPolygon
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export const withdrawRecipesPolygonABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: '_registryAddressHolder', internalType: 'address', type: 'address' },
      { name: '_uniswapAddressHolder', internalType: 'address', type: 'address' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'positionManager', internalType: 'address', type: 'address', indexed: true },
      { name: 'from', internalType: 'address', type: 'address', indexed: false },
      { name: 'positionId', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'PositionWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'account', internalType: 'address', type: 'address', indexed: false }],
    name: 'Unpaused',
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'pause', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'registryAddressHolder',
    outputs: [{ name: '', internalType: 'contract IRegistryAddressHolder', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'positionId', internalType: 'uint256', type: 'uint256' },
      { name: 'isReturnedToken0', internalType: 'bool', type: 'bool' },
    ],
    name: 'singleTokenWithdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'uniswapAddressHolder',
    outputs: [{ name: '', internalType: 'contract IUniswapAddressHolder', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'unpause', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'positionId', internalType: 'uint256', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
  },
] as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export const withdrawRecipesPolygonAddress = {
  137: '0xB13677B2f4541224160Bf230606d3b4941BBbD53',
} as const

/**
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export const withdrawRecipesPolygonConfig = {
  address: withdrawRecipesPolygonAddress,
  abi: withdrawRecipesPolygonABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// placeholder
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export const placeholderABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'caretaker', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'CaretakerLoved',
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'clean', outputs: [] },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'feed', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getAlive',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getBoredom',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getHunger',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getSleepiness',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getStatus',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getUncleanliness',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'love',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'play', outputs: [] },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'sleep', outputs: [] },
] as const

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export const placeholderAddress = {
  1: '0xeCB504D39723b0be0e3a9Aa33D646642D1051EE1',
} as const

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export const placeholderConfig = { address: placeholderAddress, abi: placeholderABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof depositRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof depositRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof depositRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof depositRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof depositRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesOptimismABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof depositRecipesOptimismABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesOptimismABI, TFunctionName, TMode>({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"deposit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismDeposit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesOptimismABI, 'deposit'>['request']['abi'],
        'deposit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'deposit' }
    : UseContractWriteConfig<typeof depositRecipesOptimismABI, 'deposit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'deposit'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesOptimismABI, 'deposit', TMode>({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'deposit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"depositListedStrategy"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismDepositListedStrategy<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof depositRecipesOptimismABI,
          'depositListedStrategy'
        >['request']['abi'],
        'depositListedStrategy',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'depositListedStrategy' }
    : UseContractWriteConfig<typeof depositRecipesOptimismABI, 'depositListedStrategy', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'depositListedStrategy'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesOptimismABI, 'depositListedStrategy', TMode>({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'depositListedStrategy',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesOptimismABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof depositRecipesOptimismABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesOptimismABI, 'pause', TMode>({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"singleTokenDeposit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismSingleTokenDeposit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof depositRecipesOptimismABI,
          'singleTokenDeposit'
        >['request']['abi'],
        'singleTokenDeposit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'singleTokenDeposit' }
    : UseContractWriteConfig<typeof depositRecipesOptimismABI, 'singleTokenDeposit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'singleTokenDeposit'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesOptimismABI, 'singleTokenDeposit', TMode>({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'singleTokenDeposit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"singleTokenDepositListedStrategy"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismSingleTokenDepositListedStrategy<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof depositRecipesOptimismABI,
          'singleTokenDepositListedStrategy'
        >['request']['abi'],
        'singleTokenDepositListedStrategy',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'singleTokenDepositListedStrategy'
      }
    : UseContractWriteConfig<
        typeof depositRecipesOptimismABI,
        'singleTokenDepositListedStrategy',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'singleTokenDepositListedStrategy'
      } = {} as any
) {
  return useContractWrite<
    typeof depositRecipesOptimismABI,
    'singleTokenDepositListedStrategy',
    TMode
  >({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'singleTokenDepositListedStrategy',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesOptimismABI, 'unpause'>['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof depositRecipesOptimismABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesOptimismABI, 'unpause', TMode>({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"deposit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'deposit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'deposit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'deposit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"depositListedStrategy"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismDepositListedStrategy(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'depositListedStrategy'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'depositListedStrategy',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'depositListedStrategy'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"singleTokenDeposit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismSingleTokenDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'singleTokenDeposit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'singleTokenDeposit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'singleTokenDeposit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"singleTokenDepositListedStrategy"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismSingleTokenDepositListedStrategy(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof depositRecipesOptimismABI,
      'singleTokenDepositListedStrategy'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'singleTokenDepositListedStrategy',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof depositRecipesOptimismABI,
    'singleTokenDepositListedStrategy'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesOptimismUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesOptimismABI, 'unpause'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof depositRecipesOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesOptimismABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesOptimismABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `eventName` set to `"PositionDeposited"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismPositionDepositedEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesOptimismABI, 'PositionDeposited'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    eventName: 'PositionDeposited',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesOptimismABI, 'PositionDeposited'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `eventName` set to `"PositionDepositedListedStrategy"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismPositionDepositedListedStrategyEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesOptimismABI, 'PositionDepositedListedStrategy'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    eventName: 'PositionDepositedListedStrategy',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesOptimismABI, 'PositionDepositedListedStrategy'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesOptimismABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesOptimismUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesOptimismABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesOptimismABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof depositRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof depositRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof depositRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof depositRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof depositRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesPolygonABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof depositRecipesPolygonABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesPolygonABI, TFunctionName, TMode>({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"deposit"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonDeposit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesPolygonABI, 'deposit'>['request']['abi'],
        'deposit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'deposit' }
    : UseContractWriteConfig<typeof depositRecipesPolygonABI, 'deposit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'deposit'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesPolygonABI, 'deposit', TMode>({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'deposit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"depositListedStrategy"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonDepositListedStrategy<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof depositRecipesPolygonABI,
          'depositListedStrategy'
        >['request']['abi'],
        'depositListedStrategy',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'depositListedStrategy' }
    : UseContractWriteConfig<typeof depositRecipesPolygonABI, 'depositListedStrategy', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'depositListedStrategy'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesPolygonABI, 'depositListedStrategy', TMode>({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'depositListedStrategy',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesPolygonABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof depositRecipesPolygonABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesPolygonABI, 'pause', TMode>({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"singleTokenDeposit"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonSingleTokenDeposit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof depositRecipesPolygonABI,
          'singleTokenDeposit'
        >['request']['abi'],
        'singleTokenDeposit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'singleTokenDeposit' }
    : UseContractWriteConfig<typeof depositRecipesPolygonABI, 'singleTokenDeposit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'singleTokenDeposit'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesPolygonABI, 'singleTokenDeposit', TMode>({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'singleTokenDeposit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"singleTokenDepositListedStrategy"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonSingleTokenDepositListedStrategy<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof depositRecipesPolygonABI,
          'singleTokenDepositListedStrategy'
        >['request']['abi'],
        'singleTokenDepositListedStrategy',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'singleTokenDepositListedStrategy'
      }
    : UseContractWriteConfig<
        typeof depositRecipesPolygonABI,
        'singleTokenDepositListedStrategy',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'singleTokenDepositListedStrategy'
      } = {} as any
) {
  return useContractWrite<
    typeof depositRecipesPolygonABI,
    'singleTokenDepositListedStrategy',
    TMode
  >({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'singleTokenDepositListedStrategy',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof depositRecipesPolygonABI, 'unpause'>['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof depositRecipesPolygonABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof depositRecipesPolygonABI, 'unpause', TMode>({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"deposit"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'deposit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'deposit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'deposit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"depositListedStrategy"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonDepositListedStrategy(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'depositListedStrategy'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'depositListedStrategy',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'depositListedStrategy'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"singleTokenDeposit"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonSingleTokenDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'singleTokenDeposit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'singleTokenDeposit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'singleTokenDeposit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"singleTokenDepositListedStrategy"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonSingleTokenDepositListedStrategy(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof depositRecipesPolygonABI,
      'singleTokenDepositListedStrategy'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'singleTokenDepositListedStrategy',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof depositRecipesPolygonABI,
    'singleTokenDepositListedStrategy'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function usePrepareDepositRecipesPolygonUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof depositRecipesPolygonABI, 'unpause'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesPolygonABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as UseContractEventConfig<typeof depositRecipesPolygonABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesPolygonABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesPolygonABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `eventName` set to `"PositionDeposited"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonPositionDepositedEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesPolygonABI, 'PositionDeposited'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    eventName: 'PositionDeposited',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesPolygonABI, 'PositionDeposited'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `eventName` set to `"PositionDepositedListedStrategy"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonPositionDepositedListedStrategyEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesPolygonABI, 'PositionDepositedListedStrategy'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    eventName: 'PositionDepositedListedStrategy',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesPolygonABI, 'PositionDepositedListedStrategy'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link depositRecipesPolygonABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function useDepositRecipesPolygonUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof depositRecipesPolygonABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof depositRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof depositRecipesPolygonABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any
) {
  return useContractRead({ abi: erc20ABI, ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20Decimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"name"`.
 */
export function useErc20Name<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20Symbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20ABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, TFunctionName, TMode>({
    abi: erc20ABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20ABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'approve', TMode>({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20ABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'transfer', TMode>({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20TransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20ABI, 'transferFrom', TMode> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'transferFrom', TMode>({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20IncreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'increaseAllowance', TMode> & {
        abi?: never
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'increaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20DecreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'decreaseAllowance', TMode> & {
        abi?: never
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'decreaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function usePrepareErc20Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: erc20ABI, ...config } as UsePrepareContractWriteConfig<
    typeof erc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20Approve(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20Transfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20IncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20DecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof erc20ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: erc20ABI, ...config } as UseContractEventConfig<
    typeof erc20ABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__ and `eventName` set to `"Approval"`.
 */
export function useErc20ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof erc20ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({
    abi: erc20ABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof erc20ABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useErc20TransferEvent(
  config: Omit<UseContractEventConfig<typeof erc20ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({
    abi: erc20ABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof erc20ABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"estimateWithdrawPosition"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismEstimateWithdrawPosition<
  TFunctionName extends 'estimateWithdrawPosition',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'estimateWithdrawPosition',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"getAmounts"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismGetAmounts<
  TFunctionName extends 'getAmounts',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'getAmounts',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"getPositionInfo"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismGetPositionInfo<
  TFunctionName extends 'getPositionInfo',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'getPositionInfo',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"getPositionSettlement"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismGetPositionSettlement<
  TFunctionName extends 'getPositionSettlement',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'getPositionSettlement',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"getPositionTokenInfo"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismGetPositionTokenInfo<
  TFunctionName extends 'getPositionTokenInfo',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'getPositionTokenInfo',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"getTickInfo"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismGetTickInfo<
  TFunctionName extends 'getTickInfo',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'getTickInfo',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"getUncollectedFees"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismGetUncollectedFees<
  TFunctionName extends 'getUncollectedFees',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'getUncollectedFees',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"quote"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismQuote<
  TFunctionName extends 'quote',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'quote',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"quoteUsdValue"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismQuoteUsdValue<
  TFunctionName extends 'quoteUsdValue',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'quoteUsdValue',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperOptimismABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperOptimismUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof positionHelperOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionHelperOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"estimateWithdrawPosition"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonEstimateWithdrawPosition<
  TFunctionName extends 'estimateWithdrawPosition',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'estimateWithdrawPosition',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"getAmounts"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonGetAmounts<
  TFunctionName extends 'getAmounts',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'getAmounts',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"getPositionInfo"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonGetPositionInfo<
  TFunctionName extends 'getPositionInfo',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'getPositionInfo',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"getPositionSettlement"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonGetPositionSettlement<
  TFunctionName extends 'getPositionSettlement',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'getPositionSettlement',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"getPositionTokenInfo"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonGetPositionTokenInfo<
  TFunctionName extends 'getPositionTokenInfo',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'getPositionTokenInfo',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"getTickInfo"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonGetTickInfo<
  TFunctionName extends 'getTickInfo',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'getTickInfo',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"getUncollectedFees"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonGetUncollectedFees<
  TFunctionName extends 'getUncollectedFees',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'getUncollectedFees',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"quote"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonQuote<
  TFunctionName extends 'quote',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'quote',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"quoteUsdValue"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonQuoteUsdValue<
  TFunctionName extends 'quoteUsdValue',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'quoteUsdValue',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionHelperPolygonABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function usePositionHelperPolygonUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof positionHelperPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionHelperPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionHelperPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function usePositionManagerRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any
) {
  return useContractRead({ abi: positionManagerABI, ...config } as UseContractReadConfig<
    typeof positionManagerABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getOwner"`.
 */
export function usePositionManagerGetOwner<
  TFunctionName extends 'getOwner',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getOwner',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getPositionIdFromTokenId"`.
 */
export function usePositionManagerGetPositionIdFromTokenId<
  TFunctionName extends 'getPositionIdFromTokenId',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getPositionIdFromTokenId',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getPositionInfo"`.
 */
export function usePositionManagerGetPositionInfo<
  TFunctionName extends 'getPositionInfo',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getPositionInfo',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getPositionModuleData"`.
 */
export function usePositionManagerGetPositionModuleData<
  TFunctionName extends 'getPositionModuleData',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getPositionModuleData',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getPositionSettlement"`.
 */
export function usePositionManagerGetPositionSettlement<
  TFunctionName extends 'getPositionSettlement',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getPositionSettlement',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getUniswapNFTs"`.
 */
export function usePositionManagerGetUniswapNfTs<
  TFunctionName extends 'getUniswapNFTs',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getUniswapNFTs',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"getUniswapNFTsLength"`.
 */
export function usePositionManagerGetUniswapNfTsLength<
  TFunctionName extends 'getUniswapNFTsLength',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'getUniswapNFTsLength',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"isPositionRunning"`.
 */
export function usePositionManagerIsPositionRunning<
  TFunctionName extends 'isPositionRunning',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'isPositionRunning',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"positionIdCounter"`.
 */
export function usePositionManagerPositionIdCounter<
  TFunctionName extends 'positionIdCounter',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'positionIdCounter',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"positionSettlement"`.
 */
export function usePositionManagerPositionSettlement<
  TFunctionName extends 'positionSettlement',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'positionSettlement',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"positionStatus"`.
 */
export function usePositionManagerPositionStatus<
  TFunctionName extends 'positionStatus',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'positionStatus',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"positionToModuleData"`.
 */
export function usePositionManagerPositionToModuleData<
  TFunctionName extends 'positionToModuleData',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'positionToModuleData',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"positions"`.
 */
export function usePositionManagerPositions<
  TFunctionName extends 'positions',
  TSelectData = ReadContractResult<typeof positionManagerABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: positionManagerABI,
    functionName: 'positions',
    ...config,
  } as UseContractReadConfig<typeof positionManagerABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function usePositionManagerWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof positionManagerABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof positionManagerABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, TFunctionName, TMode>({
    abi: positionManagerABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"createPosition"`.
 */
export function usePositionManagerCreatePosition<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof positionManagerABI, 'createPosition'>['request']['abi'],
        'createPosition',
        TMode
      > & { functionName?: 'createPosition' }
    : UseContractWriteConfig<typeof positionManagerABI, 'createPosition', TMode> & {
        abi?: never
        functionName?: 'createPosition'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'createPosition', TMode>({
    abi: positionManagerABI,
    functionName: 'createPosition',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"middlewareIncreaseLiquidity"`.
 */
export function usePositionManagerMiddlewareIncreaseLiquidity<
  TMode extends WriteContractMode = undefined
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerABI,
          'middlewareIncreaseLiquidity'
        >['request']['abi'],
        'middlewareIncreaseLiquidity',
        TMode
      > & { functionName?: 'middlewareIncreaseLiquidity' }
    : UseContractWriteConfig<typeof positionManagerABI, 'middlewareIncreaseLiquidity', TMode> & {
        abi?: never
        functionName?: 'middlewareIncreaseLiquidity'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'middlewareIncreaseLiquidity', TMode>({
    abi: positionManagerABI,
    functionName: 'middlewareIncreaseLiquidity',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"middlewareRebalance"`.
 */
export function usePositionManagerMiddlewareRebalance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerABI,
          'middlewareRebalance'
        >['request']['abi'],
        'middlewareRebalance',
        TMode
      > & { functionName?: 'middlewareRebalance' }
    : UseContractWriteConfig<typeof positionManagerABI, 'middlewareRebalance', TMode> & {
        abi?: never
        functionName?: 'middlewareRebalance'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'middlewareRebalance', TMode>({
    abi: positionManagerABI,
    functionName: 'middlewareRebalance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"middlewareWithdraw"`.
 */
export function usePositionManagerMiddlewareWithdraw<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerABI,
          'middlewareWithdraw'
        >['request']['abi'],
        'middlewareWithdraw',
        TMode
      > & { functionName?: 'middlewareWithdraw' }
    : UseContractWriteConfig<typeof positionManagerABI, 'middlewareWithdraw', TMode> & {
        abi?: never
        functionName?: 'middlewareWithdraw'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'middlewareWithdraw', TMode>({
    abi: positionManagerABI,
    functionName: 'middlewareWithdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePositionManagerOnErc721Received<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof positionManagerABI, 'onERC721Received'>['request']['abi'],
        'onERC721Received',
        TMode
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<typeof positionManagerABI, 'onERC721Received', TMode> & {
        abi?: never
        functionName?: 'onERC721Received'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'onERC721Received', TMode>({
    abi: positionManagerABI,
    functionName: 'onERC721Received',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"setModuleData"`.
 */
export function usePositionManagerSetModuleData<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof positionManagerABI, 'setModuleData'>['request']['abi'],
        'setModuleData',
        TMode
      > & { functionName?: 'setModuleData' }
    : UseContractWriteConfig<typeof positionManagerABI, 'setModuleData', TMode> & {
        abi?: never
        functionName?: 'setModuleData'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'setModuleData', TMode>({
    abi: positionManagerABI,
    functionName: 'setModuleData',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"withdrawERC20ToOwner"`.
 */
export function usePositionManagerWithdrawErc20ToOwner<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerABI,
          'withdrawERC20ToOwner'
        >['request']['abi'],
        'withdrawERC20ToOwner',
        TMode
      > & { functionName?: 'withdrawERC20ToOwner' }
    : UseContractWriteConfig<typeof positionManagerABI, 'withdrawERC20ToOwner', TMode> & {
        abi?: never
        functionName?: 'withdrawERC20ToOwner'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerABI, 'withdrawERC20ToOwner', TMode>({
    abi: positionManagerABI,
    functionName: 'withdrawERC20ToOwner',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function usePreparePositionManagerWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, TFunctionName>,
    'abi'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"createPosition"`.
 */
export function usePreparePositionManagerCreatePosition(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'createPosition'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'createPosition',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'createPosition'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"middlewareIncreaseLiquidity"`.
 */
export function usePreparePositionManagerMiddlewareIncreaseLiquidity(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'middlewareIncreaseLiquidity'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'middlewareIncreaseLiquidity',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'middlewareIncreaseLiquidity'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"middlewareRebalance"`.
 */
export function usePreparePositionManagerMiddlewareRebalance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'middlewareRebalance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'middlewareRebalance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'middlewareRebalance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"middlewareWithdraw"`.
 */
export function usePreparePositionManagerMiddlewareWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'middlewareWithdraw'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'middlewareWithdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'middlewareWithdraw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePreparePositionManagerOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'onERC721Received'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"setModuleData"`.
 */
export function usePreparePositionManagerSetModuleData(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'setModuleData'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'setModuleData',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'setModuleData'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerABI}__ and `functionName` set to `"withdrawERC20ToOwner"`.
 */
export function usePreparePositionManagerWithdrawErc20ToOwner(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerABI, 'withdrawERC20ToOwner'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerABI,
    functionName: 'withdrawERC20ToOwner',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerABI, 'withdrawERC20ToOwner'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function usePositionManagerEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof positionManagerABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: positionManagerABI, ...config } as UseContractEventConfig<
    typeof positionManagerABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerABI}__ and `eventName` set to `"ERC20Withdrawn"`.
 */
export function usePositionManagerErc20WithdrawnEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerABI, 'ERC20Withdrawn'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: positionManagerABI,
    eventName: 'ERC20Withdrawn',
    ...config,
  } as UseContractEventConfig<typeof positionManagerABI, 'ERC20Withdrawn'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerABI}__ and `eventName` set to `"PositionClosed"`.
 */
export function usePositionManagerPositionClosedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerABI, 'PositionClosed'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: positionManagerABI,
    eventName: 'PositionClosed',
    ...config,
  } as UseContractEventConfig<typeof positionManagerABI, 'PositionClosed'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerABI}__ and `eventName` set to `"PositionCreated"`.
 */
export function usePositionManagerPositionCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerABI, 'PositionCreated'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: positionManagerABI,
    eventName: 'PositionCreated',
    ...config,
  } as UseContractEventConfig<typeof positionManagerABI, 'PositionCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerABI}__ and `eventName` set to `"PositionIncreasedLiquidity"`.
 */
export function usePositionManagerPositionIncreasedLiquidityEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerABI, 'PositionIncreasedLiquidity'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: positionManagerABI,
    eventName: 'PositionIncreasedLiquidity',
    ...config,
  } as UseContractEventConfig<typeof positionManagerABI, 'PositionIncreasedLiquidity'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerABI}__ and `eventName` set to `"PositionRebalanced"`.
 */
export function usePositionManagerPositionRebalancedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerABI, 'PositionRebalanced'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: positionManagerABI,
    eventName: 'PositionRebalanced',
    ...config,
  } as UseContractEventConfig<typeof positionManagerABI, 'PositionRebalanced'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"actions"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismActions<
  TFunctionName extends 'actions',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'actions',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"diamondCutFacet"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismDiamondCutFacet<
  TFunctionName extends 'diamondCutFacet',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'diamondCutFacet',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"getPositionManagers"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismGetPositionManagers<
  TFunctionName extends 'getPositionManagers',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'getPositionManagers',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"getPositionManagersLength"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismGetPositionManagersLength<
  TFunctionName extends 'getPositionManagersLength',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'getPositionManagersLength',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"positionManagers"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismPositionManagers<
  TFunctionName extends 'positionManagers',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'positionManagers',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"userToPositionManager"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismUserToPositionManager<
  TFunctionName extends 'userToPositionManager',
  TSelectData = ReadContractResult<typeof positionManagerFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'userToPositionManager',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryOptimismABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof positionManagerFactoryOptimismABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryOptimismABI, TFunctionName, TMode>({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismCreate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryOptimismABI,
          'create'
        >['request']['abi'],
        'create',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'create' }
    : UseContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'create', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'create'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryOptimismABI, 'create', TMode>({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'create',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryOptimismABI,
          'pause'
        >['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryOptimismABI, 'pause', TMode>({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryOptimismABI,
          'unpause'
        >['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryOptimismABI, 'unpause', TMode>({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"updateActionData"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismUpdateActionData<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryOptimismABI,
          'updateActionData'
        >['request']['abi'],
        'updateActionData',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updateActionData' }
    : UseContractWriteConfig<
        typeof positionManagerFactoryOptimismABI,
        'updateActionData',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updateActionData'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryOptimismABI, 'updateActionData', TMode>({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'updateActionData',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"updateDiamond"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismUpdateDiamond<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryOptimismABI,
          'updateDiamond'
        >['request']['abi'],
        'updateDiamond',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updateDiamond' }
    : UseContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'updateDiamond', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updateDiamond'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryOptimismABI, 'updateDiamond', TMode>({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'updateDiamond',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryOptimismCreate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'create'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'create',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'create'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryOptimismPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryOptimismUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"updateActionData"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryOptimismUpdateActionData(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'updateActionData'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'updateActionData',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'updateActionData'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `functionName` set to `"updateDiamond"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryOptimismUpdateDiamond(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'updateDiamond'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    functionName: 'updateDiamond',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryOptimismABI, 'updateDiamond'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryOptimismABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryOptimismABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `eventName` set to `"PositionManagerCreated"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismPositionManagerCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryOptimismABI, 'PositionManagerCreated'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    eventName: 'PositionManagerCreated',
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryOptimismABI, 'PositionManagerCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryOptimismUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryOptimismABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof positionManagerFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryOptimismABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"actions"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonActions<
  TFunctionName extends 'actions',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'actions',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"diamondCutFacet"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonDiamondCutFacet<
  TFunctionName extends 'diamondCutFacet',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'diamondCutFacet',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"getPositionManagers"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonGetPositionManagers<
  TFunctionName extends 'getPositionManagers',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'getPositionManagers',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"getPositionManagersLength"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonGetPositionManagersLength<
  TFunctionName extends 'getPositionManagersLength',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'getPositionManagersLength',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"positionManagers"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonPositionManagers<
  TFunctionName extends 'positionManagers',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'positionManagers',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"userToPositionManager"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonUserToPositionManager<
  TFunctionName extends 'userToPositionManager',
  TSelectData = ReadContractResult<typeof positionManagerFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'userToPositionManager',
    ...config,
  } as UseContractReadConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryPolygonABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof positionManagerFactoryPolygonABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryPolygonABI, TFunctionName, TMode>({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonCreate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryPolygonABI,
          'create'
        >['request']['abi'],
        'create',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'create' }
    : UseContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'create', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'create'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryPolygonABI, 'create', TMode>({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'create',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryPolygonABI,
          'pause'
        >['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryPolygonABI, 'pause', TMode>({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryPolygonABI,
          'unpause'
        >['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryPolygonABI, 'unpause', TMode>({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"updateActionData"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonUpdateActionData<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryPolygonABI,
          'updateActionData'
        >['request']['abi'],
        'updateActionData',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updateActionData' }
    : UseContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'updateActionData', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updateActionData'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryPolygonABI, 'updateActionData', TMode>({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'updateActionData',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"updateDiamond"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonUpdateDiamond<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof positionManagerFactoryPolygonABI,
          'updateDiamond'
        >['request']['abi'],
        'updateDiamond',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updateDiamond' }
    : UseContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'updateDiamond', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updateDiamond'
      } = {} as any
) {
  return useContractWrite<typeof positionManagerFactoryPolygonABI, 'updateDiamond', TMode>({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'updateDiamond',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryPolygonWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryPolygonCreate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'create'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'create',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'create'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryPolygonPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryPolygonUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"updateActionData"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryPolygonUpdateActionData(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'updateActionData'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'updateActionData',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'updateActionData'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `functionName` set to `"updateDiamond"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePreparePositionManagerFactoryPolygonUpdateDiamond(
  config: Omit<
    UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'updateDiamond'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    functionName: 'updateDiamond',
    ...config,
  } as UsePrepareContractWriteConfig<typeof positionManagerFactoryPolygonABI, 'updateDiamond'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryPolygonABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryPolygonABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryPolygonABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryPolygonABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `eventName` set to `"PositionManagerCreated"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonPositionManagerCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryPolygonABI, 'PositionManagerCreated'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    eventName: 'PositionManagerCreated',
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryPolygonABI, 'PositionManagerCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function usePositionManagerFactoryPolygonUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof positionManagerFactoryPolygonABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof positionManagerFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof positionManagerFactoryPolygonABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"allowance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"balanceOf"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"decimals"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"executors"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismExecutors<
  TFunctionName extends 'executors',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'executors',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"name"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"owner"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"symbol"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"totalSupply"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof reyldTokenOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof reyldTokenOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, TFunctionName, TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"addExecutor"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismAddExecutor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'addExecutor'>['request']['abi'],
        'addExecutor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'addExecutor' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'addExecutor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'addExecutor'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'addExecutor', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'addExecutor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'approve', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"burn"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismBurn<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'burn' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'burn', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burn'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'burn', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'burn',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyldTokenOptimismABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'decreaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'decreaseAllowance', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyldTokenOptimismABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'increaseAllowance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'increaseAllowance', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'pause', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"removeExecutor"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismRemoveExecutor<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyldTokenOptimismABI,
          'removeExecutor'
        >['request']['abi'],
        'removeExecutor',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'removeExecutor' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'removeExecutor', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'removeExecutor'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'removeExecutor', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'removeExecutor',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyldTokenOptimismABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'renounceOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'renounceOwnership', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"transfer"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismTransfer<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transfer' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'transfer', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'transfer', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'transferFrom', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyldTokenOptimismABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'transferOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'transferOwnership', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyldTokenOptimismABI, 'unpause'>['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof reyldTokenOptimismABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof reyldTokenOptimismABI, 'unpause', TMode>({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"addExecutor"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismAddExecutor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'addExecutor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'addExecutor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'addExecutor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"burn"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'burn'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'burn'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"decreaseAllowance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"increaseAllowance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"removeExecutor"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismRemoveExecutor(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'removeExecutor'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'removeExecutor',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'removeExecutor'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"transfer"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'transfer'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function usePrepareReyldTokenOptimismUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyldTokenOptimismABI, 'unpause'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof reyldTokenOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof reyldTokenOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `eventName` set to `"Approval"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof reyldTokenOptimismABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof reyldTokenOptimismABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof reyldTokenOptimismABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof reyldTokenOptimismABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyldTokenOptimismABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof reyldTokenOptimismABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `eventName` set to `"Transfer"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof reyldTokenOptimismABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof reyldTokenOptimismABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyldTokenOptimismABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function useReyldTokenOptimismUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyldTokenOptimismABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyldTokenOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof reyldTokenOptimismABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"balanceOf"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"baseExtension"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismBaseExtension<
  TFunctionName extends 'baseExtension',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'baseExtension',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"baseURI"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismBaseUri<
  TFunctionName extends 'baseURI',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'baseURI',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"cost"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismCost<
  TFunctionName extends 'cost',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'cost',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"getApproved"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismGetApproved<
  TFunctionName extends 'getApproved',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismIsApprovedForAll<
  TFunctionName extends 'isApprovedForAll',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"isMintedWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismIsMintedWhitelist<
  TFunctionName extends 'isMintedWhitelist',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'isMintedWhitelist',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"maxMintAmount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMaxMintAmount<
  TFunctionName extends 'maxMintAmount',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'maxMintAmount',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"maxSupply"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMaxSupply<
  TFunctionName extends 'maxSupply',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'maxSupply',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"merkleRoot"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMerkleRoot<
  TFunctionName extends 'merkleRoot',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'merkleRoot',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"name"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"nftPerAddressLimit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismNftPerAddressLimit<
  TFunctionName extends 'nftPerAddressLimit',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'nftPerAddressLimit',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"nowSupply"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismNowSupply<
  TFunctionName extends 'nowSupply',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'nowSupply',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"onlyWhitelisted"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismOnlyWhitelisted<
  TFunctionName extends 'onlyWhitelisted',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'onlyWhitelisted',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"owner"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"ownerOf"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismOwnerOf<
  TFunctionName extends 'ownerOf',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"presaleAddressMintedBalance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismPresaleAddressMintedBalance<
  TFunctionName extends 'presaleAddressMintedBalance',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'presaleAddressMintedBalance',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"saleIsActive"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSaleIsActive<
  TFunctionName extends 'saleIsActive',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'saleIsActive',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"startTimestamp"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismStartTimestamp<
  TFunctionName extends 'startTimestamp',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'startTimestamp',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"symbol"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"tokenByIndex"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTokenByIndex<
  TFunctionName extends 'tokenByIndex',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'tokenByIndex',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"tokenOfOwnerByIndex"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTokenOfOwnerByIndex<
  TFunctionName extends 'tokenOfOwnerByIndex',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'tokenOfOwnerByIndex',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"tokenURI"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTokenUri<
  TFunctionName extends 'tokenURI',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"totalSupply"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"walletOfOwner"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismWalletOfOwner<
  TFunctionName extends 'walletOfOwner',
  TSelectData = ReadContractResult<typeof reyieldNftOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'walletOfOwner',
    ...config,
  } as UseContractReadConfig<typeof reyieldNftOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, TFunctionName, TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"airdrop"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismAirdrop<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'airdrop'>['request']['abi'],
        'airdrop',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'airdrop' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'airdrop', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'airdrop'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'airdrop', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'airdrop',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'approve', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"burn"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismBurn<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'burn' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'burn', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burn'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'burn', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'burn',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"mint"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'mint'>['request']['abi'],
        'mint',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'mint' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'mint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mint'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'mint', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"mintOwner"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMintOwner<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'mintOwner'>['request']['abi'],
        'mintOwner',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'mintOwner' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'mintOwner', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintOwner'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'mintOwner', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'mintOwner',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"mintWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMintWhitelist<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'mintWhitelist'>['request']['abi'],
        'mintWhitelist',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'mintWhitelist' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'mintWhitelist', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintWhitelist'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'mintWhitelist', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'mintWhitelist',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'renounceOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'renounceOwnership', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSafeTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'safeTransferFrom'
        >['request']['abi'],
        'safeTransferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'safeTransferFrom' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'safeTransferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'safeTransferFrom', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetApprovalForAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'setApprovalForAll'
        >['request']['abi'],
        'setApprovalForAll',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setApprovalForAll' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setApprovalForAll', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setApprovalForAll', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setBaseExtension"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetBaseExtension<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'setBaseExtension'
        >['request']['abi'],
        'setBaseExtension',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setBaseExtension' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setBaseExtension', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setBaseExtension'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setBaseExtension', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setBaseExtension',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetBaseUri<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'setBaseURI'>['request']['abi'],
        'setBaseURI',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setBaseURI' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setBaseURI', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setBaseURI'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setBaseURI', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setBaseURI',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setCost"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetCost<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'setCost'>['request']['abi'],
        'setCost',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setCost' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setCost', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setCost'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setCost', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setCost',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setMerkleRoot"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetMerkleRoot<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'setMerkleRoot'>['request']['abi'],
        'setMerkleRoot',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setMerkleRoot' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setMerkleRoot', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setMerkleRoot'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setMerkleRoot', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setMerkleRoot',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setNftPerAddressLimit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetNftPerAddressLimit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'setNftPerAddressLimit'
        >['request']['abi'],
        'setNftPerAddressLimit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setNftPerAddressLimit' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setNftPerAddressLimit', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setNftPerAddressLimit'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setNftPerAddressLimit', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setNftPerAddressLimit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setOnlyWhitelisted"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetOnlyWhitelisted<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'setOnlyWhitelisted'
        >['request']['abi'],
        'setOnlyWhitelisted',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setOnlyWhitelisted' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setOnlyWhitelisted', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setOnlyWhitelisted'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setOnlyWhitelisted', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setOnlyWhitelisted',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setSaleState"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetSaleState<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'setSaleState'>['request']['abi'],
        'setSaleState',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setSaleState' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setSaleState', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setSaleState'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setSaleState', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setSaleState',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setStartTimestamp"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetStartTimestamp<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'setStartTimestamp'
        >['request']['abi'],
        'setStartTimestamp',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setStartTimestamp' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setStartTimestamp', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setStartTimestamp'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setStartTimestamp', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setStartTimestamp',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setmaxMintAmount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismSetmaxMintAmount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'setmaxMintAmount'
        >['request']['abi'],
        'setmaxMintAmount',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setmaxMintAmount' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'setmaxMintAmount', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setmaxMintAmount'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'setmaxMintAmount', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setmaxMintAmount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'transferFrom', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldNftOptimismABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'transferOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'transferOwnership', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldNftOptimismABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof reyieldNftOptimismABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any
) {
  return useContractWrite<typeof reyieldNftOptimismABI, 'withdraw', TMode>({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"airdrop"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismAirdrop(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'airdrop'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'airdrop',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'airdrop'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"burn"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'burn'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'burn'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"mint"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'mint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"mintOwner"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismMintOwner(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'mintOwner'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'mintOwner',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'mintOwner'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"mintWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismMintWhitelist(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'mintWhitelist'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'mintWhitelist',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'mintWhitelist'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'safeTransferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setApprovalForAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setBaseExtension"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetBaseExtension(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setBaseExtension'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setBaseExtension',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setBaseExtension'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetBaseUri(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setBaseURI'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setBaseURI',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setBaseURI'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setCost"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetCost(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setCost'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setCost',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setCost'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setMerkleRoot"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetMerkleRoot(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setMerkleRoot'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setMerkleRoot',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setMerkleRoot'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setNftPerAddressLimit"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetNftPerAddressLimit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setNftPerAddressLimit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setNftPerAddressLimit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setNftPerAddressLimit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setOnlyWhitelisted"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetOnlyWhitelisted(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setOnlyWhitelisted'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setOnlyWhitelisted',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setOnlyWhitelisted'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setSaleState"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetSaleState(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setSaleState'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setSaleState',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setSaleState'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setStartTimestamp"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetStartTimestamp(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setStartTimestamp'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setStartTimestamp',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setStartTimestamp'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"setmaxMintAmount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismSetmaxMintAmount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setmaxMintAmount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'setmaxMintAmount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'setmaxMintAmount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function usePrepareReyieldNftOptimismWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldNftOptimismABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `eventName` set to `"Approval"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismApprovalForAllEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, 'ApprovalForAll'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `eventName` set to `"BatchMetadataUpdate"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismBatchMetadataUpdateEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, 'BatchMetadataUpdate'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    eventName: 'BatchMetadataUpdate',
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, 'BatchMetadataUpdate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `eventName` set to `"MetadataUpdate"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismMetadataUpdateEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, 'MetadataUpdate'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    eventName: 'MetadataUpdate',
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, 'MetadataUpdate'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldNftOptimismABI}__ and `eventName` set to `"Transfer"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function useReyieldNftOptimismTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldNftOptimismABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldNftOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof reyieldNftOptimismABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"daysTimeLimitedPrivilege"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismDaysTimeLimitedPrivilege<
  TFunctionName extends 'daysTimeLimitedPrivilege',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'daysTimeLimitedPrivilege',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"getPermissionInfo"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismGetPermissionInfo<
  TFunctionName extends 'getPermissionInfo',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'getPermissionInfo',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"governance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismGovernance<
  TFunctionName extends 'governance',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'governance',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"governanceToken"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismGovernanceToken<
  TFunctionName extends 'governanceToken',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'governanceToken',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"licenseAmountForPermanentPrivilegeNFT"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismLicenseAmountForPermanentPrivilegeNft<
  TFunctionName extends 'licenseAmountForPermanentPrivilegeNFT',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'licenseAmountForPermanentPrivilegeNFT',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"licenseNoToBurnedTokenAmount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismLicenseNoToBurnedTokenAmount<
  TFunctionName extends 'licenseNoToBurnedTokenAmount',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'licenseNoToBurnedTokenAmount',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"officialAccount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismOfficialAccount<
  TFunctionName extends 'officialAccount',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'officialAccount',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"privilege"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismPrivilege<
  TFunctionName extends 'privilege',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'privilege',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"privilegeStakeAmount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismPrivilegeStakeAmount<
  TFunctionName extends 'privilegeStakeAmount',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'privilegeStakeAmount',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"reyieldNFT"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismReyieldNft<
  TFunctionName extends 'reyieldNFT',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'reyieldNFT',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"tokenIdToIsPermanent"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismTokenIdToIsPermanent<
  TFunctionName extends 'tokenIdToIsPermanent',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'tokenIdToIsPermanent',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"tryBurnERC20ForLicense"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismTryBurnErc20ForLicense<
  TFunctionName extends 'tryBurnERC20ForLicense',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'tryBurnERC20ForLicense',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"tryBurnERC721ForPermanentPrivilegeAndLicenses"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismTryBurnErc721ForPermanentPrivilegeAndLicenses<
  TFunctionName extends 'tryBurnERC721ForPermanentPrivilegeAndLicenses',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'tryBurnERC721ForPermanentPrivilegeAndLicenses',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"tryBurnERC721ForTimeLimitedPrivilege"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismTryBurnErc721ForTimeLimitedPrivilege<
  TFunctionName extends 'tryBurnERC721ForTimeLimitedPrivilege',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'tryBurnERC721ForTimeLimitedPrivilege',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"userToPermissionInfo"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismUserToPermissionInfo<
  TFunctionName extends 'userToPermissionInfo',
  TSelectData = ReadContractResult<typeof reyieldPermissionOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'userToPermissionInfo',
    ...config,
  } as UseContractReadConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldPermissionOptimismABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, TFunctionName, TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"burnERC20ForLicense"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismBurnErc20ForLicense<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'burnERC20ForLicense'
        >['request']['abi'],
        'burnERC20ForLicense',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'burnERC20ForLicense' }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, 'burnERC20ForLicense', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burnERC20ForLicense'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'burnERC20ForLicense', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'burnERC20ForLicense',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"burnERC721"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismBurnErc721<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'burnERC721'
        >['request']['abi'],
        'burnERC721',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'burnERC721' }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, 'burnERC721', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burnERC721'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'burnERC721', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'burnERC721',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"changeGovernance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismChangeGovernance<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'changeGovernance'
        >['request']['abi'],
        'changeGovernance',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeGovernance' }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, 'changeGovernance', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeGovernance'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'changeGovernance', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'changeGovernance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"changeOfficialAccount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismChangeOfficialAccount<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'changeOfficialAccount'
        >['request']['abi'],
        'changeOfficialAccount',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'changeOfficialAccount' }
    : UseContractWriteConfig<
        typeof reyieldPermissionOptimismABI,
        'changeOfficialAccount',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'changeOfficialAccount'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'changeOfficialAccount', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'changeOfficialAccount',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof reyieldPermissionOptimismABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'pause', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"stakeERC20ForPrivilege"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismStakeErc20ForPrivilege<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'stakeERC20ForPrivilege'
        >['request']['abi'],
        'stakeERC20ForPrivilege',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'stakeERC20ForPrivilege' }
    : UseContractWriteConfig<
        typeof reyieldPermissionOptimismABI,
        'stakeERC20ForPrivilege',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'stakeERC20ForPrivilege'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'stakeERC20ForPrivilege', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'stakeERC20ForPrivilege',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'unpause'
        >['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'unpause', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"unstakeERC20"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismUnstakeErc20<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'unstakeERC20'
        >['request']['abi'],
        'unstakeERC20',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unstakeERC20' }
    : UseContractWriteConfig<typeof reyieldPermissionOptimismABI, 'unstakeERC20', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unstakeERC20'
      } = {} as any
) {
  return useContractWrite<typeof reyieldPermissionOptimismABI, 'unstakeERC20', TMode>({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'unstakeERC20',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"updatePermanentNFTWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismUpdatePermanentNftWhitelist<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof reyieldPermissionOptimismABI,
          'updatePermanentNFTWhitelist'
        >['request']['abi'],
        'updatePermanentNFTWhitelist',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'updatePermanentNFTWhitelist' }
    : UseContractWriteConfig<
        typeof reyieldPermissionOptimismABI,
        'updatePermanentNFTWhitelist',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'updatePermanentNFTWhitelist'
      } = {} as any
) {
  return useContractWrite<
    typeof reyieldPermissionOptimismABI,
    'updatePermanentNFTWhitelist',
    TMode
  >({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'updatePermanentNFTWhitelist',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"burnERC20ForLicense"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismBurnErc20ForLicense(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'burnERC20ForLicense'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'burnERC20ForLicense',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'burnERC20ForLicense'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"burnERC721"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismBurnErc721(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'burnERC721'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'burnERC721',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'burnERC721'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"changeGovernance"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismChangeGovernance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'changeGovernance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'changeGovernance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'changeGovernance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"changeOfficialAccount"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismChangeOfficialAccount(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'changeOfficialAccount'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'changeOfficialAccount',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'changeOfficialAccount'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"stakeERC20ForPrivilege"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismStakeErc20ForPrivilege(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'stakeERC20ForPrivilege'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'stakeERC20ForPrivilege',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'stakeERC20ForPrivilege'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"unstakeERC20"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismUnstakeErc20(
  config: Omit<
    UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'unstakeERC20'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'unstakeERC20',
    ...config,
  } as UsePrepareContractWriteConfig<typeof reyieldPermissionOptimismABI, 'unstakeERC20'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `functionName` set to `"updatePermanentNFTWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function usePrepareReyieldPermissionOptimismUpdatePermanentNftWhitelist(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof reyieldPermissionOptimismABI,
      'updatePermanentNFTWhitelist'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    functionName: 'updatePermanentNFTWhitelist',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof reyieldPermissionOptimismABI,
    'updatePermanentNFTWhitelist'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"GovernanceTokenBurned"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismGovernanceTokenBurnedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'GovernanceTokenBurned'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'GovernanceTokenBurned',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'GovernanceTokenBurned'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"GovernanceTokenStaked"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismGovernanceTokenStakedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'GovernanceTokenStaked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'GovernanceTokenStaked',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'GovernanceTokenStaked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"GovernanceTokenUnstaked"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismGovernanceTokenUnstakedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'GovernanceTokenUnstaked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'GovernanceTokenUnstaked',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'GovernanceTokenUnstaked'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"PermanentPrivilegeNFTBurned"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismPermanentPrivilegeNftBurnedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'PermanentPrivilegeNFTBurned'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'PermanentPrivilegeNFTBurned',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'PermanentPrivilegeNFTBurned'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"TimeLimitedPrivilegeNFTBurned"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismTimeLimitedPrivilegeNftBurnedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'TimeLimitedPrivilegeNFTBurned'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'TimeLimitedPrivilegeNFTBurned',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'TimeLimitedPrivilegeNFTBurned'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function useReyieldPermissionOptimismUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof reyieldPermissionOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof reyieldPermissionOptimismABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"creatorWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismCreatorWhitelist<
  TFunctionName extends 'creatorWhitelist',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'creatorWhitelist',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"getStrategyProviderWallets"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismGetStrategyProviderWallets<
  TFunctionName extends 'getStrategyProviderWallets',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'getStrategyProviderWallets',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"getStrategyProviderWalletsLength"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismGetStrategyProviderWalletsLength<
  TFunctionName extends 'getStrategyProviderWalletsLength',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'getStrategyProviderWalletsLength',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"isIncreatorWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismIsIncreatorWhitelist<
  TFunctionName extends 'isIncreatorWhitelist',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'isIncreatorWhitelist',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"providerToWallet"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismProviderToWallet<
  TFunctionName extends 'providerToWallet',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'providerToWallet',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"strategyProviderWallets"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismStrategyProviderWallets<
  TFunctionName extends 'strategyProviderWallets',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'strategyProviderWallets',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof strategyProviderWalletFactoryOptimismABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<
        typeof strategyProviderWalletFactoryOptimismABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName, TMode>({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"addCreatorWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismAddCreatorWhitelist<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof strategyProviderWalletFactoryOptimismABI,
          'addCreatorWhitelist'
        >['request']['abi'],
        'addCreatorWhitelist',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'addCreatorWhitelist' }
    : UseContractWriteConfig<
        typeof strategyProviderWalletFactoryOptimismABI,
        'addCreatorWhitelist',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'addCreatorWhitelist'
      } = {} as any
) {
  return useContractWrite<
    typeof strategyProviderWalletFactoryOptimismABI,
    'addCreatorWhitelist',
    TMode
  >({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'addCreatorWhitelist',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismCreate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof strategyProviderWalletFactoryOptimismABI,
          'create'
        >['request']['abi'],
        'create',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'create' }
    : UseContractWriteConfig<typeof strategyProviderWalletFactoryOptimismABI, 'create', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'create'
      } = {} as any
) {
  return useContractWrite<typeof strategyProviderWalletFactoryOptimismABI, 'create', TMode>({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'create',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function usePrepareStrategyProviderWalletFactoryOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"addCreatorWhitelist"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function usePrepareStrategyProviderWalletFactoryOptimismAddCreatorWhitelist(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      'addCreatorWhitelist'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'addCreatorWhitelist',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    'addCreatorWhitelist'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function usePrepareStrategyProviderWalletFactoryOptimismCreate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryOptimismABI, 'create'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    functionName: 'create',
    ...config,
  } as UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryOptimismABI, 'create'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof strategyProviderWalletFactoryOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof strategyProviderWalletFactoryOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `eventName` set to `"CreatorWhitelistAdded"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismCreatorWhitelistAddedEvent(
  config: Omit<
    UseContractEventConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      'CreatorWhitelistAdded'
    >,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    eventName: 'CreatorWhitelistAdded',
    ...config,
  } as UseContractEventConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    'CreatorWhitelistAdded'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__ and `eventName` set to `"StrategyProviderWalletCreated"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryOptimismStrategyProviderWalletCreatedEvent(
  config: Omit<
    UseContractEventConfig<
      typeof strategyProviderWalletFactoryOptimismABI,
      'StrategyProviderWalletCreated'
    >,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    eventName: 'StrategyProviderWalletCreated',
    ...config,
  } as UseContractEventConfig<
    typeof strategyProviderWalletFactoryOptimismABI,
    'StrategyProviderWalletCreated'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"creatorWhitelist"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonCreatorWhitelist<
  TFunctionName extends 'creatorWhitelist',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'creatorWhitelist',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"getStrategyProviderWallets"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonGetStrategyProviderWallets<
  TFunctionName extends 'getStrategyProviderWallets',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'getStrategyProviderWallets',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"getStrategyProviderWalletsLength"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonGetStrategyProviderWalletsLength<
  TFunctionName extends 'getStrategyProviderWalletsLength',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'getStrategyProviderWalletsLength',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"isIncreatorWhitelist"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonIsIncreatorWhitelist<
  TFunctionName extends 'isIncreatorWhitelist',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'isIncreatorWhitelist',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"providerToWallet"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonProviderToWallet<
  TFunctionName extends 'providerToWallet',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'providerToWallet',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"strategyProviderWallets"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonStrategyProviderWallets<
  TFunctionName extends 'strategyProviderWallets',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'strategyProviderWallets',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof strategyProviderWalletFactoryPolygonABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<
        typeof strategyProviderWalletFactoryPolygonABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName, TMode>({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"addCreatorWhitelist"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonAddCreatorWhitelist<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof strategyProviderWalletFactoryPolygonABI,
          'addCreatorWhitelist'
        >['request']['abi'],
        'addCreatorWhitelist',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'addCreatorWhitelist' }
    : UseContractWriteConfig<
        typeof strategyProviderWalletFactoryPolygonABI,
        'addCreatorWhitelist',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'addCreatorWhitelist'
      } = {} as any
) {
  return useContractWrite<
    typeof strategyProviderWalletFactoryPolygonABI,
    'addCreatorWhitelist',
    TMode
  >({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'addCreatorWhitelist',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonCreate<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof strategyProviderWalletFactoryPolygonABI,
          'create'
        >['request']['abi'],
        'create',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'create' }
    : UseContractWriteConfig<typeof strategyProviderWalletFactoryPolygonABI, 'create', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'create'
      } = {} as any
) {
  return useContractWrite<typeof strategyProviderWalletFactoryPolygonABI, 'create', TMode>({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'create',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function usePrepareStrategyProviderWalletFactoryPolygonWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"addCreatorWhitelist"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function usePrepareStrategyProviderWalletFactoryPolygonAddCreatorWhitelist(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      'addCreatorWhitelist'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'addCreatorWhitelist',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    'addCreatorWhitelist'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `functionName` set to `"create"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function usePrepareStrategyProviderWalletFactoryPolygonCreate(
  config: Omit<
    UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryPolygonABI, 'create'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    functionName: 'create',
    ...config,
  } as UsePrepareContractWriteConfig<typeof strategyProviderWalletFactoryPolygonABI, 'create'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof strategyProviderWalletFactoryPolygonABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as UseContractEventConfig<typeof strategyProviderWalletFactoryPolygonABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `eventName` set to `"CreatorWhitelistAdded"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonCreatorWhitelistAddedEvent(
  config: Omit<
    UseContractEventConfig<typeof strategyProviderWalletFactoryPolygonABI, 'CreatorWhitelistAdded'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    eventName: 'CreatorWhitelistAdded',
    ...config,
  } as UseContractEventConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    'CreatorWhitelistAdded'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__ and `eventName` set to `"StrategyProviderWalletCreated"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function useStrategyProviderWalletFactoryPolygonStrategyProviderWalletCreatedEvent(
  config: Omit<
    UseContractEventConfig<
      typeof strategyProviderWalletFactoryPolygonABI,
      'StrategyProviderWalletCreated'
    >,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    eventName: 'StrategyProviderWalletCreated',
    ...config,
  } as UseContractEventConfig<
    typeof strategyProviderWalletFactoryPolygonABI,
    'StrategyProviderWalletCreated'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__ and `functionName` set to `"getLiquidityAndAmounts"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismGetLiquidityAndAmounts<
  TFunctionName extends 'getLiquidityAndAmounts',
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    functionName: 'getLiquidityAndAmounts',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__ and `functionName` set to `"getPool"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismGetPool<
  TFunctionName extends 'getPool',
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    functionName: 'getPool',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__ and `functionName` set to `"reorderTokens"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismReorderTokens<
  TFunctionName extends 'reorderTokens',
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    functionName: 'reorderTokens',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__ and `functionName` set to `"validatePool"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorOptimismValidatePool<
  TFunctionName extends 'validatePool',
  TSelectData = ReadContractResult<typeof uniswapCalculatorOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    functionName: 'validatePool',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__ and `functionName` set to `"getLiquidityAndAmounts"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonGetLiquidityAndAmounts<
  TFunctionName extends 'getLiquidityAndAmounts',
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    functionName: 'getLiquidityAndAmounts',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__ and `functionName` set to `"getPool"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonGetPool<
  TFunctionName extends 'getPool',
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    functionName: 'getPool',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__ and `functionName` set to `"reorderTokens"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonReorderTokens<
  TFunctionName extends 'reorderTokens',
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    functionName: 'reorderTokens',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__ and `functionName` set to `"validatePool"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function useUniswapCalculatorPolygonValidatePool<
  TFunctionName extends 'validatePool',
  TSelectData = ReadContractResult<typeof uniswapCalculatorPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof uniswapCalculatorPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    functionName: 'validatePool',
    ...config,
  } as UseContractReadConfig<typeof uniswapCalculatorPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function useUniswapV3PoolRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any
) {
  return useContractRead({ abi: uniswapV3PoolABI, ...config } as UseContractReadConfig<
    typeof uniswapV3PoolABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"factory"`.
 */
export function useUniswapV3PoolFactory<
  TFunctionName extends 'factory',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'factory',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"fee"`.
 */
export function useUniswapV3PoolFee<
  TFunctionName extends 'fee',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'fee',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"feeGrowthGlobal0X128"`.
 */
export function useUniswapV3PoolFeeGrowthGlobal0X128<
  TFunctionName extends 'feeGrowthGlobal0X128',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'feeGrowthGlobal0X128',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"feeGrowthGlobal1X128"`.
 */
export function useUniswapV3PoolFeeGrowthGlobal1X128<
  TFunctionName extends 'feeGrowthGlobal1X128',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'feeGrowthGlobal1X128',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"liquidity"`.
 */
export function useUniswapV3PoolLiquidity<
  TFunctionName extends 'liquidity',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'liquidity',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"maxLiquidityPerTick"`.
 */
export function useUniswapV3PoolMaxLiquidityPerTick<
  TFunctionName extends 'maxLiquidityPerTick',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'maxLiquidityPerTick',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"observations"`.
 */
export function useUniswapV3PoolObservations<
  TFunctionName extends 'observations',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'observations',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"observe"`.
 */
export function useUniswapV3PoolObserve<
  TFunctionName extends 'observe',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'observe',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"positions"`.
 */
export function useUniswapV3PoolPositions<
  TFunctionName extends 'positions',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'positions',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"protocolFees"`.
 */
export function useUniswapV3PoolProtocolFees<
  TFunctionName extends 'protocolFees',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'protocolFees',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"slot0"`.
 */
export function useUniswapV3PoolSlot0<
  TFunctionName extends 'slot0',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'slot0',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"snapshotCumulativesInside"`.
 */
export function useUniswapV3PoolSnapshotCumulativesInside<
  TFunctionName extends 'snapshotCumulativesInside',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'snapshotCumulativesInside',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"tickBitmap"`.
 */
export function useUniswapV3PoolTickBitmap<
  TFunctionName extends 'tickBitmap',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'tickBitmap',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"tickSpacing"`.
 */
export function useUniswapV3PoolTickSpacing<
  TFunctionName extends 'tickSpacing',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'tickSpacing',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"ticks"`.
 */
export function useUniswapV3PoolTicks<
  TFunctionName extends 'ticks',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'ticks',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"token0"`.
 */
export function useUniswapV3PoolToken0<
  TFunctionName extends 'token0',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'token0',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"token1"`.
 */
export function useUniswapV3PoolToken1<
  TFunctionName extends 'token1',
  TSelectData = ReadContractResult<typeof uniswapV3PoolABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: uniswapV3PoolABI,
    functionName: 'token1',
    ...config,
  } as UseContractReadConfig<typeof uniswapV3PoolABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function useUniswapV3PoolWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof uniswapV3PoolABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, TFunctionName, TMode>({
    abi: uniswapV3PoolABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"burn"`.
 */
export function useUniswapV3PoolBurn<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'burn'>['request']['abi'],
        'burn',
        TMode
      > & { functionName?: 'burn' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'burn', TMode> & {
        abi?: never
        functionName?: 'burn'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'burn', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'burn',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"collect"`.
 */
export function useUniswapV3PoolCollect<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'collect'>['request']['abi'],
        'collect',
        TMode
      > & { functionName?: 'collect' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'collect', TMode> & {
        abi?: never
        functionName?: 'collect'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'collect', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'collect',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"collectProtocol"`.
 */
export function useUniswapV3PoolCollectProtocol<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'collectProtocol'>['request']['abi'],
        'collectProtocol',
        TMode
      > & { functionName?: 'collectProtocol' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'collectProtocol', TMode> & {
        abi?: never
        functionName?: 'collectProtocol'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'collectProtocol', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'collectProtocol',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"flash"`.
 */
export function useUniswapV3PoolFlash<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'flash'>['request']['abi'],
        'flash',
        TMode
      > & { functionName?: 'flash' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'flash', TMode> & {
        abi?: never
        functionName?: 'flash'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'flash', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'flash',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"increaseObservationCardinalityNext"`.
 */
export function useUniswapV3PoolIncreaseObservationCardinalityNext<
  TMode extends WriteContractMode = undefined
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof uniswapV3PoolABI,
          'increaseObservationCardinalityNext'
        >['request']['abi'],
        'increaseObservationCardinalityNext',
        TMode
      > & { functionName?: 'increaseObservationCardinalityNext' }
    : UseContractWriteConfig<
        typeof uniswapV3PoolABI,
        'increaseObservationCardinalityNext',
        TMode
      > & {
        abi?: never
        functionName?: 'increaseObservationCardinalityNext'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'increaseObservationCardinalityNext', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'increaseObservationCardinalityNext',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"initialize"`.
 */
export function useUniswapV3PoolInitialize<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'initialize'>['request']['abi'],
        'initialize',
        TMode
      > & { functionName?: 'initialize' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'initialize', TMode> & {
        abi?: never
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'initialize', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"mint"`.
 */
export function useUniswapV3PoolMint<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'mint'>['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'mint', TMode> & {
        abi?: never
        functionName?: 'mint'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'mint', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"setFeeProtocol"`.
 */
export function useUniswapV3PoolSetFeeProtocol<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'setFeeProtocol'>['request']['abi'],
        'setFeeProtocol',
        TMode
      > & { functionName?: 'setFeeProtocol' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'setFeeProtocol', TMode> & {
        abi?: never
        functionName?: 'setFeeProtocol'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'setFeeProtocol', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'setFeeProtocol',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"swap"`.
 */
export function useUniswapV3PoolSwap<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof uniswapV3PoolABI, 'swap'>['request']['abi'],
        'swap',
        TMode
      > & { functionName?: 'swap' }
    : UseContractWriteConfig<typeof uniswapV3PoolABI, 'swap', TMode> & {
        abi?: never
        functionName?: 'swap'
      } = {} as any
) {
  return useContractWrite<typeof uniswapV3PoolABI, 'swap', TMode>({
    abi: uniswapV3PoolABI,
    functionName: 'swap',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function usePrepareUniswapV3PoolWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, TFunctionName>,
    'abi'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"burn"`.
 */
export function usePrepareUniswapV3PoolBurn(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'burn'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'burn'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"collect"`.
 */
export function usePrepareUniswapV3PoolCollect(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'collect'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'collect',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'collect'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"collectProtocol"`.
 */
export function usePrepareUniswapV3PoolCollectProtocol(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'collectProtocol'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'collectProtocol',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'collectProtocol'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"flash"`.
 */
export function usePrepareUniswapV3PoolFlash(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'flash'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'flash',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'flash'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"increaseObservationCardinalityNext"`.
 */
export function usePrepareUniswapV3PoolIncreaseObservationCardinalityNext(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'increaseObservationCardinalityNext'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'increaseObservationCardinalityNext',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'increaseObservationCardinalityNext'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"initialize"`.
 */
export function usePrepareUniswapV3PoolInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'initialize'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareUniswapV3PoolMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"setFeeProtocol"`.
 */
export function usePrepareUniswapV3PoolSetFeeProtocol(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'setFeeProtocol'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'setFeeProtocol',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'setFeeProtocol'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `functionName` set to `"swap"`.
 */
export function usePrepareUniswapV3PoolSwap(
  config: Omit<
    UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'swap'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: uniswapV3PoolABI,
    functionName: 'swap',
    ...config,
  } as UsePrepareContractWriteConfig<typeof uniswapV3PoolABI, 'swap'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function useUniswapV3PoolEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof uniswapV3PoolABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: uniswapV3PoolABI, ...config } as UseContractEventConfig<
    typeof uniswapV3PoolABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"Burn"`.
 */
export function useUniswapV3PoolBurnEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'Burn'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'Burn',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'Burn'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"Collect"`.
 */
export function useUniswapV3PoolCollectEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'Collect'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'Collect',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'Collect'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"CollectProtocol"`.
 */
export function useUniswapV3PoolCollectProtocolEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'CollectProtocol'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'CollectProtocol',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'CollectProtocol'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"Flash"`.
 */
export function useUniswapV3PoolFlashEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'Flash'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'Flash',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'Flash'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"IncreaseObservationCardinalityNext"`.
 */
export function useUniswapV3PoolIncreaseObservationCardinalityNextEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'IncreaseObservationCardinalityNext'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'IncreaseObservationCardinalityNext',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'IncreaseObservationCardinalityNext'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"Initialize"`.
 */
export function useUniswapV3PoolInitializeEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'Initialize'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'Initialize',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'Initialize'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"Mint"`.
 */
export function useUniswapV3PoolMintEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'Mint'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'Mint',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'Mint'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"SetFeeProtocol"`.
 */
export function useUniswapV3PoolSetFeeProtocolEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'SetFeeProtocol'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'SetFeeProtocol',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'SetFeeProtocol'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link uniswapV3PoolABI}__ and `eventName` set to `"Swap"`.
 */
export function useUniswapV3PoolSwapEvent(
  config: Omit<
    UseContractEventConfig<typeof uniswapV3PoolABI, 'Swap'>,
    'abi' | 'eventName'
  > = {} as any
) {
  return useContractEvent({
    abi: uniswapV3PoolABI,
    eventName: 'Swap',
    ...config,
  } as UseContractEventConfig<typeof uniswapV3PoolABI, 'Swap'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof withdrawRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof withdrawRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof withdrawRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof withdrawRecipesOptimismABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesOptimismABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof withdrawRecipesOptimismABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesOptimismABI, TFunctionName, TMode>({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesOptimismABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof withdrawRecipesOptimismABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesOptimismABI, 'pause', TMode>({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"singleTokenWithdraw"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismSingleTokenWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof withdrawRecipesOptimismABI,
          'singleTokenWithdraw'
        >['request']['abi'],
        'singleTokenWithdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'singleTokenWithdraw' }
    : UseContractWriteConfig<typeof withdrawRecipesOptimismABI, 'singleTokenWithdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'singleTokenWithdraw'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesOptimismABI, 'singleTokenWithdraw', TMode>({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'singleTokenWithdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesOptimismABI, 'unpause'>['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof withdrawRecipesOptimismABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesOptimismABI, 'unpause', TMode>({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesOptimismAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesOptimismABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof withdrawRecipesOptimismABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesOptimismABI, 'withdraw', TMode>({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesOptimismWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesOptimismPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"singleTokenWithdraw"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesOptimismSingleTokenWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'singleTokenWithdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'singleTokenWithdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'singleTokenWithdraw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesOptimismUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesOptimismWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesOptimismABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesOptimismABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesOptimismABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesOptimismABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesOptimismABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `eventName` set to `"PositionWithdrawn"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismPositionWithdrawnEvent(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesOptimismABI, 'PositionWithdrawn'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    eventName: 'PositionWithdrawn',
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesOptimismABI, 'PositionWithdrawn'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesOptimismUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesOptimismABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof withdrawRecipesOptimismAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesOptimismABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof withdrawRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"paused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonPaused<
  TFunctionName extends 'paused',
  TSelectData = ReadContractResult<typeof withdrawRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'paused',
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"registryAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonRegistryAddressHolder<
  TFunctionName extends 'registryAddressHolder',
  TSelectData = ReadContractResult<typeof withdrawRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'registryAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"uniswapAddressHolder"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonUniswapAddressHolder<
  TFunctionName extends 'uniswapAddressHolder',
  TSelectData = ReadContractResult<typeof withdrawRecipesPolygonABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractRead({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'uniswapAddressHolder',
    ...config,
  } as UseContractReadConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesPolygonABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof withdrawRecipesPolygonABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesPolygonABI, TFunctionName, TMode>({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonPause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesPolygonABI, 'pause'>['request']['abi'],
        'pause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pause' }
    : UseContractWriteConfig<typeof withdrawRecipesPolygonABI, 'pause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pause'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesPolygonABI, 'pause', TMode>({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'pause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"singleTokenWithdraw"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonSingleTokenWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof withdrawRecipesPolygonABI,
          'singleTokenWithdraw'
        >['request']['abi'],
        'singleTokenWithdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'singleTokenWithdraw' }
    : UseContractWriteConfig<typeof withdrawRecipesPolygonABI, 'singleTokenWithdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'singleTokenWithdraw'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesPolygonABI, 'singleTokenWithdraw', TMode>({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'singleTokenWithdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonUnpause<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesPolygonABI, 'unpause'>['request']['abi'],
        'unpause',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unpause' }
    : UseContractWriteConfig<typeof withdrawRecipesPolygonABI, 'unpause', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unpause'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesPolygonABI, 'unpause', TMode>({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'unpause',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof withdrawRecipesPolygonAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof withdrawRecipesPolygonABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof withdrawRecipesPolygonABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any
) {
  return useContractWrite<typeof withdrawRecipesPolygonABI, 'withdraw', TMode>({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesPolygonWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"pause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesPolygonPause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'pause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'pause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'pause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"singleTokenWithdraw"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesPolygonSingleTokenWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'singleTokenWithdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'singleTokenWithdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'singleTokenWithdraw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"unpause"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesPolygonUnpause(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'unpause'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'unpause',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'unpause'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function usePrepareWithdrawRecipesPolygonWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof withdrawRecipesPolygonABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesPolygonABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesPolygonABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `eventName` set to `"Paused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonPausedEvent(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesPolygonABI, 'Paused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    eventName: 'Paused',
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesPolygonABI, 'Paused'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `eventName` set to `"PositionWithdrawn"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonPositionWithdrawnEvent(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesPolygonABI, 'PositionWithdrawn'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    eventName: 'PositionWithdrawn',
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesPolygonABI, 'PositionWithdrawn'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__ and `eventName` set to `"Unpaused"`.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function useWithdrawRecipesPolygonUnpausedEvent(
  config: Omit<
    UseContractEventConfig<typeof withdrawRecipesPolygonABI, 'Unpaused'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof withdrawRecipesPolygonAddress } = {} as any
) {
  return useContractEvent({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    eventName: 'Unpaused',
    ...config,
  } as UseContractEventConfig<typeof withdrawRecipesPolygonABI, 'Unpaused'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"getAlive"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderGetAlive<
  TFunctionName extends 'getAlive',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'getAlive',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"getBoredom"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderGetBoredom<
  TFunctionName extends 'getBoredom',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'getBoredom',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"getHunger"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderGetHunger<
  TFunctionName extends 'getHunger',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'getHunger',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"getSleepiness"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderGetSleepiness<
  TFunctionName extends 'getSleepiness',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'getSleepiness',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"getStatus"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderGetStatus<
  TFunctionName extends 'getStatus',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'getStatus',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"getUncleanliness"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderGetUncleanliness<
  TFunctionName extends 'getUncleanliness',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'getUncleanliness',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"love"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderLove<
  TFunctionName extends 'love',
  TSelectData = ReadContractResult<typeof placeholderABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractRead({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'love',
    ...config,
  } as UseContractReadConfig<typeof placeholderABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof placeholderAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof placeholderABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof placeholderABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<typeof placeholderABI, TFunctionName, TMode>({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"clean"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderClean<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof placeholderAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof placeholderABI, 'clean'>['request']['abi'],
        'clean',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'clean' }
    : UseContractWriteConfig<typeof placeholderABI, 'clean', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'clean'
      } = {} as any
) {
  return useContractWrite<typeof placeholderABI, 'clean', TMode>({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'clean',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"feed"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderFeed<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof placeholderAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof placeholderABI, 'feed'>['request']['abi'],
        'feed',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'feed' }
    : UseContractWriteConfig<typeof placeholderABI, 'feed', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'feed'
      } = {} as any
) {
  return useContractWrite<typeof placeholderABI, 'feed', TMode>({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'feed',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"play"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderPlay<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof placeholderAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof placeholderABI, 'play'>['request']['abi'],
        'play',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'play' }
    : UseContractWriteConfig<typeof placeholderABI, 'play', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'play'
      } = {} as any
) {
  return useContractWrite<typeof placeholderABI, 'play', TMode>({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'play',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"sleep"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderSleep<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof placeholderAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof placeholderABI, 'sleep'>['request']['abi'],
        'sleep',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'sleep' }
    : UseContractWriteConfig<typeof placeholderABI, 'sleep', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'sleep'
      } = {} as any
) {
  return useContractWrite<typeof placeholderABI, 'sleep', TMode>({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'sleep',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePreparePlaceholderWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof placeholderABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as UsePrepareContractWriteConfig<typeof placeholderABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"clean"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePreparePlaceholderClean(
  config: Omit<
    UsePrepareContractWriteConfig<typeof placeholderABI, 'clean'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'clean',
    ...config,
  } as UsePrepareContractWriteConfig<typeof placeholderABI, 'clean'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"feed"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePreparePlaceholderFeed(
  config: Omit<
    UsePrepareContractWriteConfig<typeof placeholderABI, 'feed'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'feed',
    ...config,
  } as UsePrepareContractWriteConfig<typeof placeholderABI, 'feed'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"play"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePreparePlaceholderPlay(
  config: Omit<
    UsePrepareContractWriteConfig<typeof placeholderABI, 'play'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'play',
    ...config,
  } as UsePrepareContractWriteConfig<typeof placeholderABI, 'play'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link placeholderABI}__ and `functionName` set to `"sleep"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePreparePlaceholderSleep(
  config: Omit<
    UsePrepareContractWriteConfig<typeof placeholderABI, 'sleep'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: placeholderABI,
    address: placeholderAddress[1],
    functionName: 'sleep',
    ...config,
  } as UsePrepareContractWriteConfig<typeof placeholderABI, 'sleep'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof placeholderABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof placeholderAddress
  } = {} as any
) {
  return useContractEvent({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as UseContractEventConfig<typeof placeholderABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link placeholderABI}__ and `eventName` set to `"CaretakerLoved"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function usePlaceholderCaretakerLovedEvent(
  config: Omit<
    UseContractEventConfig<typeof placeholderABI, 'CaretakerLoved'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof placeholderAddress } = {} as any
) {
  return useContractEvent({
    abi: placeholderABI,
    address: placeholderAddress[1],
    eventName: 'CaretakerLoved',
    ...config,
  } as UseContractEventConfig<typeof placeholderABI, 'CaretakerLoved'>)
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Core
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function getDepositRecipesOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof depositRecipesOptimismAddress
  }
) {
  return getContract({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function readDepositRecipesOptimism<
  TAbi extends readonly unknown[] = typeof depositRecipesOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof depositRecipesOptimismAddress
  }
) {
  return readContract({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function writeDepositRecipesOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof depositRecipesOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof depositRecipesOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof depositRecipesOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof depositRecipesOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof depositRecipesOptimismAddress
      })
) {
  return writeContract({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof depositRecipesOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link depositRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function prepareWriteDepositRecipesOptimism<
  TAbi extends readonly unknown[] = typeof depositRecipesOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof depositRecipesOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: depositRecipesOptimismABI,
    address: depositRecipesOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function getDepositRecipesPolygon(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof depositRecipesPolygonAddress
  }
) {
  return getContract({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function readDepositRecipesPolygon<
  TAbi extends readonly unknown[] = typeof depositRecipesPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof depositRecipesPolygonAddress
  }
) {
  return readContract({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function writeDepositRecipesPolygon<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof depositRecipesPolygonAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof depositRecipesPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof depositRecipesPolygonAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof depositRecipesPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof depositRecipesPolygonAddress
      })
) {
  return writeContract({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as unknown as WriteContractArgs<typeof depositRecipesPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link depositRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xc5d57eDB3e79854DDdc59388A784B716506BDA52)
 */
export function prepareWriteDepositRecipesPolygon<
  TAbi extends readonly unknown[] = typeof depositRecipesPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof depositRecipesPolygonAddress
  }
) {
  return prepareWriteContract({
    abi: depositRecipesPolygonABI,
    address: depositRecipesPolygonAddress[137],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function getErc20(config: Omit<GetContractArgs, 'abi'>) {
  return getContract({ abi: erc20ABI, ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function readErc20<
  TAbi extends readonly unknown[] = typeof erc20ABI,
  TFunctionName extends string = string
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return readContract({ abi: erc20ABI, ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function writeErc20<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof erc20ABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof erc20ABI, TFunctionName>, 'abi'>
) {
  return writeContract({ abi: erc20ABI, ...config } as unknown as WriteContractArgs<
    typeof erc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function prepareWriteErc20<
  TAbi extends readonly unknown[] = typeof erc20ABI,
  TFunctionName extends string = string
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return prepareWriteContract({ abi: erc20ABI, ...config } as unknown as PrepareWriteContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link positionHelperOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function getPositionHelperOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof positionHelperOptimismAddress
  }
) {
  return getContract({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link positionHelperOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function readPositionHelperOptimism<
  TAbi extends readonly unknown[] = typeof positionHelperOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof positionHelperOptimismAddress
  }
) {
  return readContract({
    abi: positionHelperOptimismABI,
    address: positionHelperOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link positionHelperPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function getPositionHelperPolygon(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof positionHelperPolygonAddress
  }
) {
  return getContract({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link positionHelperPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x356cE468C2Be9ed1732699FBAea94DAb906749a0)
 */
export function readPositionHelperPolygon<
  TAbi extends readonly unknown[] = typeof positionHelperPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof positionHelperPolygonAddress
  }
) {
  return readContract({
    abi: positionHelperPolygonABI,
    address: positionHelperPolygonAddress[137],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function getPositionManager(config: Omit<GetContractArgs, 'abi'>) {
  return getContract({ abi: positionManagerABI, ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function readPositionManager<
  TAbi extends readonly unknown[] = typeof positionManagerABI,
  TFunctionName extends string = string
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return readContract({ abi: positionManagerABI, ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function writePositionManager<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof positionManagerABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof positionManagerABI, TFunctionName>, 'abi'>
) {
  return writeContract({ abi: positionManagerABI, ...config } as unknown as WriteContractArgs<
    typeof positionManagerABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link positionManagerABI}__.
 */
export function prepareWritePositionManager<
  TAbi extends readonly unknown[] = typeof positionManagerABI,
  TFunctionName extends string = string
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return prepareWriteContract({
    abi: positionManagerABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function getPositionManagerFactoryOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof positionManagerFactoryOptimismAddress
  }
) {
  return getContract({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function readPositionManagerFactoryOptimism<
  TAbi extends readonly unknown[] = typeof positionManagerFactoryOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof positionManagerFactoryOptimismAddress
  }
) {
  return readContract({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function writePositionManagerFactoryOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof positionManagerFactoryOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof positionManagerFactoryOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof positionManagerFactoryOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof positionManagerFactoryOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof positionManagerFactoryOptimismAddress
      })
) {
  return writeContract({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof positionManagerFactoryOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link positionManagerFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function prepareWritePositionManagerFactoryOptimism<
  TAbi extends readonly unknown[] = typeof positionManagerFactoryOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof positionManagerFactoryOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: positionManagerFactoryOptimismABI,
    address: positionManagerFactoryOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function getPositionManagerFactoryPolygon(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof positionManagerFactoryPolygonAddress
  }
) {
  return getContract({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function readPositionManagerFactoryPolygon<
  TAbi extends readonly unknown[] = typeof positionManagerFactoryPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof positionManagerFactoryPolygonAddress
  }
) {
  return readContract({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function writePositionManagerFactoryPolygon<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof positionManagerFactoryPolygonAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof positionManagerFactoryPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof positionManagerFactoryPolygonAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof positionManagerFactoryPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof positionManagerFactoryPolygonAddress
      })
) {
  return writeContract({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as unknown as WriteContractArgs<typeof positionManagerFactoryPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link positionManagerFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x9ff67463BDb860e51b8992ae35dee1896acDDd5b)
 */
export function prepareWritePositionManagerFactoryPolygon<
  TAbi extends readonly unknown[] = typeof positionManagerFactoryPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof positionManagerFactoryPolygonAddress
  }
) {
  return prepareWriteContract({
    abi: positionManagerFactoryPolygonABI,
    address: positionManagerFactoryPolygonAddress[137],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function getReyldTokenOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof reyldTokenOptimismAddress
  }
) {
  return getContract({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function readReyldTokenOptimism<
  TAbi extends readonly unknown[] = typeof reyldTokenOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof reyldTokenOptimismAddress
  }
) {
  return readContract({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function writeReyldTokenOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof reyldTokenOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof reyldTokenOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof reyldTokenOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof reyldTokenOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof reyldTokenOptimismAddress
      })
) {
  return writeContract({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof reyldTokenOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link reyldTokenOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x3f5AF1473F5d4F73e32F693260AD0e6A645BD0c8)
 */
export function prepareWriteReyldTokenOptimism<
  TAbi extends readonly unknown[] = typeof reyldTokenOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof reyldTokenOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: reyldTokenOptimismABI,
    address: reyldTokenOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function getReyieldNftOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof reyieldNftOptimismAddress
  }
) {
  return getContract({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function readReyieldNftOptimism<
  TAbi extends readonly unknown[] = typeof reyieldNftOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof reyieldNftOptimismAddress
  }
) {
  return readContract({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function writeReyieldNftOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof reyieldNftOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof reyieldNftOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof reyieldNftOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof reyieldNftOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof reyieldNftOptimismAddress
      })
) {
  return writeContract({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof reyieldNftOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link reyieldNftOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x711059DeCDA94200CECdC128Ad38e498225AdC6f)
 */
export function prepareWriteReyieldNftOptimism<
  TAbi extends readonly unknown[] = typeof reyieldNftOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof reyieldNftOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: reyieldNftOptimismABI,
    address: reyieldNftOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function getReyieldPermissionOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof reyieldPermissionOptimismAddress
  }
) {
  return getContract({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function readReyieldPermissionOptimism<
  TAbi extends readonly unknown[] = typeof reyieldPermissionOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof reyieldPermissionOptimismAddress
  }
) {
  return readContract({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function writeReyieldPermissionOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof reyieldPermissionOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof reyieldPermissionOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof reyieldPermissionOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof reyieldPermissionOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof reyieldPermissionOptimismAddress
      })
) {
  return writeContract({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof reyieldPermissionOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link reyieldPermissionOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xe59bea7C6F7640b3723e5aB6359EC6f514B3C5EC)
 */
export function prepareWriteReyieldPermissionOptimism<
  TAbi extends readonly unknown[] = typeof reyieldPermissionOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof reyieldPermissionOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: reyieldPermissionOptimismABI,
    address: reyieldPermissionOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function getStrategyProviderWalletFactoryOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress
  }
) {
  return getContract({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function readStrategyProviderWalletFactoryOptimism<
  TAbi extends readonly unknown[] = typeof strategyProviderWalletFactoryOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress
  }
) {
  return readContract({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function writeStrategyProviderWalletFactoryOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof strategyProviderWalletFactoryOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof strategyProviderWalletFactoryOptimismAddress
      })
) {
  return writeContract({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof strategyProviderWalletFactoryOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link strategyProviderWalletFactoryOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function prepareWriteStrategyProviderWalletFactoryOptimism<
  TAbi extends readonly unknown[] = typeof strategyProviderWalletFactoryOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof strategyProviderWalletFactoryOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: strategyProviderWalletFactoryOptimismABI,
    address: strategyProviderWalletFactoryOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function getStrategyProviderWalletFactoryPolygon(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress
  }
) {
  return getContract({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function readStrategyProviderWalletFactoryPolygon<
  TAbi extends readonly unknown[] = typeof strategyProviderWalletFactoryPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress
  }
) {
  return readContract({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function writeStrategyProviderWalletFactoryPolygon<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof strategyProviderWalletFactoryPolygonAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof strategyProviderWalletFactoryPolygonAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared'
          ? TChainId
          : keyof typeof strategyProviderWalletFactoryPolygonAddress
      })
) {
  return writeContract({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as unknown as WriteContractArgs<typeof strategyProviderWalletFactoryPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link strategyProviderWalletFactoryPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xE2E2b956e4Bd86aA92DAd24DfEeb602814544E3A)
 */
export function prepareWriteStrategyProviderWalletFactoryPolygon<
  TAbi extends readonly unknown[] = typeof strategyProviderWalletFactoryPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof strategyProviderWalletFactoryPolygonAddress
  }
) {
  return prepareWriteContract({
    abi: strategyProviderWalletFactoryPolygonABI,
    address: strategyProviderWalletFactoryPolygonAddress[137],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function getUniswapCalculatorOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof uniswapCalculatorOptimismAddress
  }
) {
  return getContract({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link uniswapCalculatorOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function readUniswapCalculatorOptimism<
  TAbi extends readonly unknown[] = typeof uniswapCalculatorOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof uniswapCalculatorOptimismAddress
  }
) {
  return readContract({
    abi: uniswapCalculatorOptimismABI,
    address: uniswapCalculatorOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function getUniswapCalculatorPolygon(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof uniswapCalculatorPolygonAddress
  }
) {
  return getContract({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link uniswapCalculatorPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0x13E6944F3aADAaD73195BADC5eFA4faAF53E35F0)
 */
export function readUniswapCalculatorPolygon<
  TAbi extends readonly unknown[] = typeof uniswapCalculatorPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof uniswapCalculatorPolygonAddress
  }
) {
  return readContract({
    abi: uniswapCalculatorPolygonABI,
    address: uniswapCalculatorPolygonAddress[137],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function getUniswapV3Pool(config: Omit<GetContractArgs, 'abi'>) {
  return getContract({ abi: uniswapV3PoolABI, ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function readUniswapV3Pool<
  TAbi extends readonly unknown[] = typeof uniswapV3PoolABI,
  TFunctionName extends string = string
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return readContract({ abi: uniswapV3PoolABI, ...config } as unknown as ReadContractConfig<
    TAbi,
    TFunctionName
  >)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function writeUniswapV3Pool<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof uniswapV3PoolABI, TFunctionName>, 'abi'>
    | Omit<WriteContractUnpreparedArgs<typeof uniswapV3PoolABI, TFunctionName>, 'abi'>
) {
  return writeContract({ abi: uniswapV3PoolABI, ...config } as unknown as WriteContractArgs<
    typeof uniswapV3PoolABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link uniswapV3PoolABI}__.
 */
export function prepareWriteUniswapV3Pool<
  TAbi extends readonly unknown[] = typeof uniswapV3PoolABI,
  TFunctionName extends string = string
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi'>) {
  return prepareWriteContract({
    abi: uniswapV3PoolABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function getWithdrawRecipesOptimism(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof withdrawRecipesOptimismAddress
  }
) {
  return getContract({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function readWithdrawRecipesOptimism<
  TAbi extends readonly unknown[] = typeof withdrawRecipesOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof withdrawRecipesOptimismAddress
  }
) {
  return readContract({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function writeWithdrawRecipesOptimism<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof withdrawRecipesOptimismAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof withdrawRecipesOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof withdrawRecipesOptimismAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof withdrawRecipesOptimismABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof withdrawRecipesOptimismAddress
      })
) {
  return writeContract({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as unknown as WriteContractArgs<typeof withdrawRecipesOptimismABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link withdrawRecipesOptimismABI}__.
 *
 * [__View Contract on Op Mainnet Optimism Explorer__](https://explorer.optimism.io/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function prepareWriteWithdrawRecipesOptimism<
  TAbi extends readonly unknown[] = typeof withdrawRecipesOptimismABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof withdrawRecipesOptimismAddress
  }
) {
  return prepareWriteContract({
    abi: withdrawRecipesOptimismABI,
    address: withdrawRecipesOptimismAddress[10],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function getWithdrawRecipesPolygon(
  config: Omit<GetContractArgs, 'abi' | 'address'> & {
    chainId?: keyof typeof withdrawRecipesPolygonAddress
  }
) {
  return getContract({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function readWithdrawRecipesPolygon<
  TAbi extends readonly unknown[] = typeof withdrawRecipesPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof withdrawRecipesPolygonAddress
  }
) {
  return readContract({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function writeWithdrawRecipesPolygon<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof withdrawRecipesPolygonAddress
>(
  config:
    | (Omit<
        WriteContractPreparedArgs<typeof withdrawRecipesPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof withdrawRecipesPolygonAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof withdrawRecipesPolygonABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof withdrawRecipesPolygonAddress
      })
) {
  return writeContract({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as unknown as WriteContractArgs<typeof withdrawRecipesPolygonABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link withdrawRecipesPolygonABI}__.
 *
 * [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xB13677B2f4541224160Bf230606d3b4941BBbD53)
 */
export function prepareWriteWithdrawRecipesPolygon<
  TAbi extends readonly unknown[] = typeof withdrawRecipesPolygonABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof withdrawRecipesPolygonAddress
  }
) {
  return prepareWriteContract({
    abi: withdrawRecipesPolygonABI,
    address: withdrawRecipesPolygonAddress[137],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function getPlaceholder(
  config: Omit<GetContractArgs, 'abi' | 'address'> & { chainId?: keyof typeof placeholderAddress }
) {
  return getContract({ abi: placeholderABI, address: placeholderAddress[1], ...config })
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function readPlaceholder<
  TAbi extends readonly unknown[] = typeof placeholderABI,
  TFunctionName extends string = string
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof placeholderAddress
  }
) {
  return readContract({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function writePlaceholder<
  TFunctionName extends string,
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof placeholderAddress
>(
  config:
    | (Omit<WriteContractPreparedArgs<typeof placeholderABI, TFunctionName>, 'abi' | 'address'> & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof placeholderAddress
      })
    | (Omit<
        WriteContractUnpreparedArgs<typeof placeholderABI, TFunctionName>,
        'abi' | 'address'
      > & {
        mode: TMode
        chainId?: TMode extends 'prepared' ? TChainId : keyof typeof placeholderAddress
      })
) {
  return writeContract({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as unknown as WriteContractArgs<typeof placeholderABI, TFunctionName>)
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link placeholderABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xecb504d39723b0be0e3a9aa33d646642d1051ee1)
 */
export function prepareWritePlaceholder<
  TAbi extends readonly unknown[] = typeof placeholderABI,
  TFunctionName extends string = string
>(
  config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof placeholderAddress
  }
) {
  return prepareWriteContract({
    abi: placeholderABI,
    address: placeholderAddress[1],
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>)
}
